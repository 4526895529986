import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import HoneyPotInput from 'components/GeneralComponents/HoneyPotInput/HoneyPotInput';

class SignupComponenti18n extends React.Component {
  componentDidMount() {
    this.firstNameInput.focus();
  }

  showConditionalText = (condition, text) => {
    if (condition) {
      return text;
    }

    return null;
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      passwordConfirmation,
      _submitForm,
      _nameChange,
      _emailChange,
      _passwordChange,
      _passwordConfirmationChange,
      _handleHoneyPotChange,
      accountCreationErrorText,
      _routeToLogin,
      intl,
    } = this.props;

    const firstNameConditionalText = (
      <FormattedMessage
        id="SignupComponent.firstNameError"
        defaultMessage="Please enter your first name."
      />
    );
    const lastNameConditionalText = (
      <FormattedMessage
        id="SignupComponent.lastNameError"
        defaultMessage="Please enter your last name."
      />
    );
    const emailConditionalText = (
      <FormattedMessage
        id="SignupComponent.emailError"
        defaultMessage="Please enter a valid email address."
      />
    );
    const passwordConditionalText = (
      <FormattedMessage
        id="SignupComponent.passwordError1"
        defaultMessage="Please enter a valid password. Your password must contain 1 uppercase, 1 lowercase and 1 special character and must be at least 8 characters long."
      />
    );
    const passwordConfirmationConditionalText = (
      <FormattedMessage
        id="SignupComponent.passwordError2"
        defaultMessage="Your passwords must match."
      />
    );
    const placeholders = defineMessages({
      firstName: {
        id: 'SignupComponent.firstName',
        defaultMessage: 'First Name',
      },
      lastName: {
        id: 'SignupComponent.lastName',
        defaultMessage: 'Last Name',
      },
      email: {
        id: 'SignupComponent.email',
        defaultMessage: 'Email',
      },
      password: {
        id: 'SignupComponent.password',
        defaultMessage: 'Password',
      },
      confirmPassword: {
        id: 'SignupComponent.confirmPassword',
        defaultMessage: 'Confirm Password',
      },
      firstName: {
        id: 'SignupComponent.firstName',
        defaultMessage: 'First Name',
      },
    });

    return (
      <article className="login">
        <div className="login__wrap">
          <h2 className="login__header">
            <FormattedMessage
              id="SignupComponent.createYourAccount"
              defaultMessage="Create Your Account"
            />
          </h2>

          <form className="login__form" onSubmit={_submitForm}>
            <section className="login__form-group">
              <input
                ref={(input) => {
                  this.firstNameInput = input;
                }}
                onChange={_nameChange}
                name="first_name"
                placeholder={intl.formatMessage(placeholders.firstName)}
                className="login__input-first-name"
              />
              <p className="login__form-error-text show">
                {this.showConditionalText(
                  first_name.showValidity,
                  firstNameConditionalText
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_nameChange}
                name="last_name"
                placeholder={intl.formatMessage(placeholders.lastName)}
                className="login__input-last-name"
              />
              <p className="login__form-error-text show">
                {this.showConditionalText(
                  last_name.showValidity,
                  lastNameConditionalText
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_emailChange}
                name="email"
                placeholder={intl.formatMessage(placeholders.email)}
                className="login__input-email"
              />
              <p className="login__form-error-text show">
                {this.showConditionalText(
                  email.showValidity,
                  emailConditionalText
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_passwordChange}
                name="password"
                type="password"
                placeholder={intl.formatMessage(placeholders.password)}
                className="login__input-password"
              />
              <p className="login__form-error-text show">
                {this.showConditionalText(
                  password.showValidity,
                  passwordConditionalText
                )}
              </p>
            </section>

            <section className="login__form-group">
              <input
                onChange={_passwordConfirmationChange}
                name="passwordConfirmation"
                type="password"
                placeholder={intl.formatMessage(placeholders.confirmPassword)}
                className="login__input-password-confirm"
              />

              <HoneyPotInput handleChange={_handleHoneyPotChange} />
              <p className="login__form-error-text show">
                {this.showConditionalText(
                  passwordConfirmation.showValidity,
                  passwordConfirmationConditionalText
                )}
              </p>
            </section>

            {accountCreationErrorText ? (
              <section className="login__form-group">
                <p className="login__form-error-text show">
                  {accountCreationErrorText}
                </p>
              </section>
            ) : null}

            <section className="login__form-action">
              <button
                className="login__form-submit button button-primary"
                type="submit"
              >
                <FormattedMessage
                  id="SignupComponent.createAccount"
                  defaultMessage="Create an account."
                />
              </button>
            </section>
          </form>

          <footer className="login__footer">
            <p>
              <FormattedMessage
                id="SignupComponent.existingAccount"
                defaultMessage="Already have an account?"
              />
              <a className="login__link" href="" onClick={_routeToLogin}>
                <FormattedMessage
                  id="SignupComponent.login"
                  defaultMessage="Log In"
                />
              </a>
            </p>
          </footer>
        </div>
      </article>
    );
  }
}

const SignupComponent = injectIntl(SignupComponenti18n);

export { SignupComponent };

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
SignupComponenti18n.propTypes = {
  _routeToLogin: PropTypes.func.isRequired,
  _submitForm: PropTypes.func.isRequired,
  _nameChange: PropTypes.func.isRequired,
  _emailChange: PropTypes.func.isRequired,
  _passwordChange: PropTypes.func.isRequired,
  _passwordConfirmationChange: PropTypes.func.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  password: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  first_name: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  last_name: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
  passwordConfirmation: PropTypes.shape({
    value: PropTypes.string,
    showValidity: PropTypes.bool,
  }),
};

SignupComponenti18n.defaultProps = {
  email: {
    value: '',
    showValidity: false,
  },
  password: {
    value: '',
    showValidity: false,
  },
  first_name: {
    value: '',
    showValidity: false,
  },
  last_name: {
    value: '',
    showValidity: false,
  },
  passwordConfirmation: {
    value: '',
    showValidity: false,
  },
};
