import { getChannels } from 'helpers/api-calls/user-calls';
import { getLocationSettings } from 'helpers/api-calls/location-calls';
import uniqBy from 'lodash/uniqBy';
import actions from './actions';
import constants from './constants';
import moment from 'moment';
import teamCalls from 'helpers/api-calls/team-calls';

const { params } = constants;

const fetchChannels = (location_id) => (dispatch) => {
  dispatch(actions.requestChannels());

  return getChannels(params.getChannels(location_id)).then((response) => {
    dispatch(actions.hydrateChannels(response.data.channels));
  });
};

const fetchLocation = (user_privilege_id) => (dispatch) => {
  dispatch(actions.requestLocation());

  return getLocationSettings(user_privilege_id).then((response) => {
    dispatch(
      actions.updateSettings(
        response.data.user_privilege.final_function_setting
      )
    );
  });
};

const fetchAllUsers = (locationId,  pageNo, numPerPage) => (dispatch) => {
  const start = moment();
  const allUserConfig = {
    location_id: locationId,
    page: pageNo,
    per_page: numPerPage,
    with_deleted: true,
  };
  dispatch(actions.requestAllUsers(true));
  dispatch(actions.requestManagers(true));
  dispatch(actions.requestAssociates(true));
  dispatch(actions.requestPendingUsers(true));
  return teamCalls.getTeamMembersUM(allUserConfig).then((response) => {
    const noDuplicated = uniqBy(response.data.user_privileges, 'id');
    const visible = noDuplicated.filter((item) => item.is_invisible === false);
    const managers = visible.filter((item) => item.is_admin === true && item.status === 'active');
    const associates = visible.filter((item) => item.is_admin === false && item.status === 'active');
    const pendingUsers = visible.filter((item) => item.status === 'pending');
    const removedUsers = visible.filter((item) => item.status !== 'active' && item.status !== 'pending');
    const end = moment();
    const diff = moment.duration(end.diff(start))
    const ms = parseInt(diff.asMilliseconds());
    const s = `Fetch ${noDuplicated.length} users take ${ms} ms.`;
    console.log(s);
    const payload = {
      users: noDuplicated,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if(allUserConfig.page === 1) {
      dispatch(actions.receiveAllUsers(payload));
    }else {
      dispatch(actions.appendAllUsers(payload));
    }

    const payloadManagers = {
      users: managers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if(allUserConfig.page === 1) {
      dispatch(actions.receiveManagers(payloadManagers));
    }else {
      dispatch(actions.appendManagers(payloadManagers));
    }

    const payloadAssociates = {
      users: associates,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if(allUserConfig.page === 1) {
      dispatch(actions.receiveAssociates(payloadAssociates));
    }else {
      dispatch(actions.appendAssociates(payloadAssociates));
    }

    const payloadPendingUsers = {
      users: pendingUsers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if(allUserConfig.page === 1) {
      dispatch(actions.receivePendingUsers(payloadPendingUsers));
    }else {
      dispatch(actions.appendPendingUsers(payloadPendingUsers));
    }
    
    const payloadRemovedUsers = {
      users: removedUsers,
      page: response.data.meta.pagination.current_page,
      totalPages: response.data.meta.pagination.total_pages,
    };
    if(allUserConfig.page === 1) {
      dispatch(actions.receiveRemovedUsers(payloadRemovedUsers));
    }else {
      dispatch(actions.appendRemovedUsers(payloadRemovedUsers));
    }
  }).catch((error) => {
    console.log(`fetAllUsers error: ${error}`);
    dispatch(actions.resetAllUsers());
    dispatch(actions.resetManagers());
    dispatch(actions.resetAssociates());
    dispatch(actions.resetPendingUsers());
    dispatch(actions.resetRemovedUsers());
  });

};

export default {
  fetchChannels,
  fetchLocation,
  fetchAllUsers,
};
