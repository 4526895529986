import React from 'react';
import PropTypes from 'prop-types';

import { checkForAuthentication } from 'helpers/cookies';
import Icon from '../Icon';

import './SelectDropdown.scss';

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      currentElement: this.props.options[0] ? this.props.options[0] : undefined,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.addWindowEventListener = this.addWindowEventListener.bind(this);
  }

  componentDidMount() {
    const { apiCalled, options, loadOptions, currentElement } = this.props;
    if (apiCalled && options.length > 0) {
      this.setState({
        value:
          currentElement !== 'false' && currentElement
            ? this.findCurrentElement(currentElement)?.value
            : options[0].value,
        currentElement: currentElement
          ? this.findCurrentElement(currentElement)
          : options[0],
      });
    } else if (checkForAuthentication()) {
      loadOptions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.addWindowEventListener);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.options.length > 0) {
      this.setState((prevState) => ({
        value: prevState.currentElement
          ? prevState.currentElement.value
          : props.options[0].value,
      }));
    }

    if (props.options.length === 1) {
      this.setState({
        currentElement: props.options[0],
      });
    }

    if (props.currentElement) {
      this.setState({
        currentElement: this.findCurrentElement(props.currentElement),
      });
    }
  }

  toggleSelect = (e) => {
    const { toggled, } = this.props;
    const { open, } = this.state;

    e.stopPropagation();
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
      this.addWindowEventListener();
    }
    if(toggled) {
      toggled(open);
    }
  };

  addWindowEventListener = () => {
    window.addEventListener('click', (e) => {
      if (!e.target.classList.contains('select-dropdown--list-item')) {
        this.setState({ open: false });
        window.removeEventListener('click', this.addWindowEventListener);
      }
    });
  };

  handleSelect = (e) => {
    const { handleChange } = this.props;

    this.setState({
      currentElement: this.findCurrentElement(e.target.value),
    });

    handleChange(e.target.value);
  };

  findCurrentElement = value => {
    return this.props.options.filter((element) => {
      if (value == element.value) {
        return element;
      }
    })[0];
  };

  render() {
    const { classes, options, className } = this.props;
    const { value, open, currentElement } = this.state;

    const Subtext = ({ text }) => {
      if (text) {
        return (
          <section className="select-dropdown--subtext-container">
            <span className="select-dropdown--subtext-description">{text}</span>
          </section>
        );
      }

      return null;
    };

    const selectOptions = () => {
      return options.map((element, index) => {
        return (
          <button
            key={index}
            value={element.value}
            onClick={this.handleSelect}
            ref="listItem"
            className="select-dropdown--list-item"
          >
            {element.text}
          </button>
        );
      });
    };

    if (options.length === 0 || !currentElement) {
      return null;
    }
    if (options.length === 1) {
      return (
        <div className={`select-dropdown ${classes}`}>
          <div className="select-dropdown--mask readonly">
            <span className="select-dropdown--text">{options[0].text}</span>
          </div>
          <Subtext text={options[0].subtext} />
        </div>
      );
    }

    return (
      <a
        className={`select-dropdown ${open ? 'open' : ''} ${classes || ''} ${
          className && className
        }`}
        value={value}
        onClick={this.toggleSelect}
      >
        <div className="select-dropdown--mask">
          <span className="select-dropdown--text">{currentElement.text}</span>

          <Icon type={open ? 'upArrow' : 'downArrow'} color="#808080" />
        </div>
        <Subtext text={currentElement.subtext} />
        {open ? (
          <div className="select-dropdown--list">{selectOptions()}</div>
        ) : null}
      </a>
    );
  }
}

SelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      text: PropTypes.string.isRequired,
      subtext: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.string,
  loadOptions: PropTypes.func,
  apiCalled: PropTypes.bool,
  currentElement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectDropdown.defaultProps = {
  className: '',
  classes: '',
  apiCalled: false,
  loadOptions: () => null,
  handleChange: () => null,
};

export default SelectDropdown;
