import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './CheckboxGroup.scss';

const CheckboxGroup = ({ type, items, onChange, extraClass, intl }) => {
  const listItems = items.map((element, index) => {
    return (
      <div className="checkbox-group--list-item" key={index}>
        <label className="checkbox-group--list-item--label">
          {typeof element.label === 'string'
            ? element.label
            : intl.formatMessage(element.label)}
          <input
            type="checkbox"
            onClick={onChange}
            value={element.value}
            name={`checkbox-group--${type}`}
            defaultChecked={element.checked}
          />
          <span className="checkbox-group--list-item--checkbox">
            <div className="checkmark_circle" />
            <div className="checkmark_stem" />
            <div className="checkmark_kick" />
          </span>
        </label>
      </div>
    );
  });

  return (
    <fieldset className={`checkbox-group ${extraClass}`}>{listItems}</fieldset>
  );
};

export default injectIntl(CheckboxGroup);

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
CheckboxGroup.propTypes = {
  extraClass: PropTypes.string,
  intl: intlShape.isRequired,
};

CheckboxGroup.defaultTypes = {
  extraClass: '',
};
