import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  openLocationCalendarFilters,
  closedLocationCalendarFilters,
  CalendarShiftTypes,
} from 'config';

import WeekPickerComponent from '../WeekPickerComponent/WeekPickerComponent';
import CheckboxGroup from '../../GeneralComponents/CheckboxGroup/CheckboxGroup';

import * as Styled from './styled';

import './FilterContainer.scss';

const weekFormat = 'ddd';

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      location_code,
      location_permission,
      vtoEnabled,
      typeFilters,
      dayFilters,
      stateFilters,
      currentDateRangeStart,
      currentDateRangeEnd,
    } = props;

    const initialStateFilters = location_permission
      ? closedLocationCalendarFilters
      : openLocationCalendarFilters;
    const mappedStateFilters = initialStateFilters.map((element) => {
      return {
        ...element,
        checked: stateFilters.indexOf(element.value) > -1,
      };
    });

    this.state = {
      currentDateRangeStart: currentDateRangeStart
        ? currentDateRangeStart
        : moment().startOf('week'),
      currentDateRangeEnd: currentDateRangeEnd
        ? currentDateRangeEnd
        : moment().endOf('week'),
      location_id: location_code,
      locationPermission: location_permission,
      stateFilters: mappedStateFilters,
      typeFilters: [
        {
          label: CalendarShiftTypes.teamShift,
          value: 'shift',
          checked: typeFilters.indexOf('shift') > -1,
        },
        {
          label: CalendarShiftTypes.openShift,
          value: 'open_shift',
          checked: typeFilters.indexOf('open_shift') > -1,
        },
        ...(vtoEnabled
          ? [
              {
                label: CalendarShiftTypes.vto,
                value: 'shift_vto',
                checked: typeFilters.indexOf('shift_vto') > -1,
              },
            ]
          : []),
      ],
      dayFilters: [
        {
          label: moment('2019-01-20').format(weekFormat),
          value: 0,
          checked: dayFilters.indexOf(0) > -1,
        },
        {
          label: moment('2019-01-21').format(weekFormat),
          value: 1,
          checked: dayFilters.indexOf(1) > -1,
        },
        {
          label: moment('2019-01-22').format(weekFormat),
          value: 2,
          checked: dayFilters.indexOf(2) > -1,
        },
        {
          label: moment('2019-01-23').format(weekFormat),
          value: 3,
          checked: dayFilters.indexOf(3) > -1,
        },
        {
          label: moment('2019-01-24').format(weekFormat),
          value: 4,
          checked: dayFilters.indexOf(4) > -1,
        },
        {
          label: moment('2019-01-25').format(weekFormat),
          value: 5,
          checked: dayFilters.indexOf(5) > -1,
        },
        {
          label: moment('2019-01-26').format(weekFormat),
          value: 6,
          checked: dayFilters.indexOf(6) > -1,
        },
      ],
    };
  }

  componentDidMount() {
    const { currentDateRangeStart, currentDateRangeEnd } = this.state;
    const { changeLocation } = this.props;

    changeLocation(currentDateRangeStart, currentDateRangeEnd);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { changeLocation } = this.props;
    const {
      currentDateRangeStart,
      currentDateRangeEnd,
      location_id,
      locationPermission,
    } = this.state;
    if (props.location_id !== location_id) {
      this.setState({ location_id: props.location_id });
      //the line below cause a lot of problems. Not necessary because "componentDidMout" called when location changes
      //changeLocation(currentDateRangeStart, currentDateRangeEnd);
    }

    if (props.location_permission !== locationPermission) {
      this.setState({
        locationPermission: props.location_permission,
        stateFilters: props.location_permission
          ? closedLocationCalendarFilters
          : openLocationCalendarFilters,
      });
    }
  }

  moveCurrentWeekBackward = () => {
    const { changeWeek, dateRangeChanged, } = this.props;
    const { currentDateRangeStart, currentDateRangeEnd } = this.state;

    const newDateRangeStart = currentDateRangeStart.subtract(1, 'week');
    const newDateRangeEnd = currentDateRangeEnd.subtract(1, 'week');

    this.setState({
      currentDateRangeStart: newDateRangeStart,
      currentDateRangeEnd: newDateRangeEnd,
    });

    changeWeek(newDateRangeStart, newDateRangeEnd);
    dateRangeChanged(newDateRangeStart, newDateRangeEnd);
  };

  moveCurrentWeekForward = () => {
    const { changeWeek, dateRangeChanged, } = this.props;
    const { currentDateRangeStart, currentDateRangeEnd } = this.state;

    const newDateRangeStart = currentDateRangeStart.add(1, 'week');
    const newDateRangeEnd = currentDateRangeEnd.add(1, 'week');

    this.setState({
      currentDateRangeStart: newDateRangeStart,
      currentDateRangeEnd: newDateRangeEnd,
    });

    changeWeek(newDateRangeStart, newDateRangeEnd);
    dateRangeChanged(newDateRangeStart, newDateRangeEnd);
  };

  /**
   * Description - this encapsulates all of the checkbox arrays (all three of them),
   * and maps through them, changing the correct one
   * @param  { String } checkedValue - the value that originates from the checkbox that is clicked
   * @param  { Array } array - the array of filters
   * @param  { Func } cb - the redux action with the correct action performed on the checkbox
   *
   * @return { Array } - newly generated array to update state with
   */
  _updateCheckboxArray = (checkedValue, array, cb) => {
    const filterObject = {};
    return array.map((element) => {
      if (element.value == checkedValue) {
        // create filter object
        filterObject.value = element.value;
        filterObject.action = element.checked ? 'delete' : 'add';
        // callback once element is found and action is determined
        cb(filterObject);
        return Object.assign({}, element, {
          checked: !element.checked,
        });
      }
      return element;
    });
  };

  stateCheckboxChanged = (e) => {
    e.stopPropagation();
    const { stateFilters } = this.state;
    const { stateFilterUpdate } = this.props;
    if (e.target.value) {
      const newStateFilters = this._updateCheckboxArray(
        e.target.value,
        stateFilters,
        stateFilterUpdate
      );
      this.setState({ stateFilters: newStateFilters });
    }
  };

  dayCheckboxChanged = (e) => {
    e.stopPropagation();
    const { dayFilters } = this.state;
    const { dayFilterUpdate } = this.props;
    if (e.target.value) {
      const dayStateFilters = this._updateCheckboxArray(
        e.target.value,
        dayFilters,
        dayFilterUpdate
      );
      this.setState({ dayFilters: dayStateFilters });
    }
  };

  typeCheckboxChanged = (e) => {
    e.stopPropagation();
    const { typeFilters } = this.state;
    const { typeFilterUpdate } = this.props;
    if (e.target.value) {
      const typeStateFilters = this._updateCheckboxArray(
        e.target.value,
        typeFilters,
        typeFilterUpdate
      );
      this.setState({ typeFilters: typeStateFilters });
    }
  };

  handleExportClick = (e) => {
    const { exportData } = this.props;
    const { currentDateRangeStart, currentDateRangeEnd } = this.state;
    e.preventDefault();
    exportData(currentDateRangeStart, currentDateRangeEnd);
  };

  _showPostShiftButton = () => {
    const { vtoEnabled, openShiftEnabled } = this.props;

    if (vtoEnabled || openShiftEnabled) return true;

    return false;
  };

  render() {
    const { postOpenShiftEnabled, vtoEnabled, actionMenuEnabled, } = this.props;
    const {
      currentDateRangeEnd,
      currentDateRangeStart,
      dayFilters,
      typeFilters,
      stateFilters,
    } = this.state;

    return (
      <Styled.FilterContainer>
        <Styled.UpperSection>
          <Styled.LeftSection>
            <WeekPickerComponent
              onChange={this.changeWeek}
              back={this.moveCurrentWeekBackward}
              forward={this.moveCurrentWeekForward}
              currentDateRangeEnd={currentDateRangeEnd}
              currentDateRangeStart={currentDateRangeStart}
            />
            <button
              type="button"
              onClick={this.handleExportClick}
              className="btn btn-primary btn-export"
            >
              <FormattedMessage
                id="FilterContainer.exportBtn"
                defaultMessage="Export"
              />
            </button>
          </Styled.LeftSection>

          {/*<Styled.RightSection>
            {vtoEnabled || postOpenShiftEnabled !== 0 ? (
              <Styled.PostShiftLink to="/calendar/post">
                <FormattedMessage
                  id="FilterContainer.postShift"
                  defaultMessage="Post Shift"
                />
              </Styled.PostShiftLink>
            ) : null}
          </Styled.RightSection>*/}
          <Styled.RightSection>
            {actionMenuEnabled === 1 && (vtoEnabled || postOpenShiftEnabled !== 0)? (
              <Styled.PostShiftLink to="/calendar/post">
                <FormattedMessage
                  id="FilterContainer.postShift"
                  defaultMessage="Post Shift"
                />
              </Styled.PostShiftLink>
            ) : null}
          </Styled.RightSection>
        </Styled.UpperSection>

        <Styled.LowerSection>
          <Styled.LowerSectionColumn>
            <CheckboxGroup
              key={1}
              extraClass="type-filters"
              type="typeTypes"
              items={typeFilters}
              onChange={this.typeCheckboxChanged}
            />
            <CheckboxGroup
              key={2}
              extraClass="day-filters"
              type="dayTypes"
              items={dayFilters}
              onChange={this.dayCheckboxChanged}
            />
          </Styled.LowerSectionColumn>
          <Styled.LowerSectionColumn>
            <CheckboxGroup
              key={3}
              extraClass="state-filters"
              type="stateTypes"
              items={stateFilters}
              onChange={this.stateCheckboxChanged}
            />
          </Styled.LowerSectionColumn>
        </Styled.LowerSection>
      </Styled.FilterContainer>
    );
  }
}

export default FilterContainer;

FilterContainer.propTypes = {
  exportData: PropTypes.func.isRequired,
  postOpenShiftEnabled: PropTypes.number,
  vtoEnabled: PropTypes.bool,
};

FilterContainer.defaultProps = {
  postOpenShiftEnabled: 0,
  vtoEnabled: true,
};
