import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';

import { Button } from '@shyft/acorns';
import blackCross from './assets/Shape.svg';
//import upArrow from './assets/up-arrow.svg';
//import downArrow from './assets/down-arrow.svg';

const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 92px;
  margin-bottom: 0.5rem;
`;

const ViewMoreButton = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['plain'].concat(modifiers),
}))`
  font-size: ${rem('12px')};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text};
  display: inline;
  background-color: transparent;
  margin-left: 0.25rem;
`;

const ViewLessButton = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['plain'].concat(modifiers),
}))`
  font-size: ${rem('12px')};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 3px;
  margin-top: 1rem;
  padding: 0.75rem;

  &:hover {
    background-color: white;
  }
`;


const DetailedWinContainer = styled.section`
  
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 3; //prevent the first avatar on RecentRequest from showing here
  //overflow: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
  /*
  @media screen and (max-width: 640px) {
    left: 50px;
    width: 600px;
  }
  
  @media screen and (min-width: 1280px) {
    //margin-left: 10px;
  }
  @media screen and (min-width: 1440px) {
    //margin-left: 90px;
  }
  @media screen and (min-width: 1680px) {
    //margin-left: 215px;
  }
  @media screen and (min-width: 1920px) {
    //margin-left: 250px;
  }
  */
`;
const DetailedWinCloseButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  position: relative;
  margin-right: 20px;
  color: black;
  background-color: white; //rgba(246,222,196,255);
  background-image: url("${blackCross}");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  boxShadow: 0 5px 10px 2px rgba(195,192,192,.5);
  //outline: none;

  @media (min-width: 600px) {
    width: 40px;
  }
`;

const ShiftRequestsLoadingLayer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  top: 45%;
  margin: auto;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
`;
const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  //border: 1px solid green;
`;
const Caption = styled.label`
  width: 400px;
  height: 40px;
  text-align:center;
  font-size: 30px;
  font-weight: 600;
  margin-left: 200px;
  margin-right: 100px;

  /*
  @media screen and (min-width: 640px) {
    margin-left: 160px;
    margin-right: 80px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 130px;
    margin-right: 90px;
  }
  @media screen and (min-width: 1440px) {
    margin-left: 220px;
    margin-right: 80px;
  }
  @media screen and (min-width: 1680px) {
    margin-left: 230px;
    margin-right: 150px;
  }
  @media screen and (min-width: 1920px) {
    margin-left: 290px;
    margin-right: 210px;
  }
  */
`;

const IconTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  justify-content: center;
  //border: 1px solid blue;

`;

const Icon = styled.img`
  width: 13px;
  height: auto;
  margin-right: 10px;
  //margin-top: 4px;
`;

const Text = styled.p`
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 350;
  color: black;
  margin-bottom: 0;
  //flex: 1;
`;
const Text1 = styled.p`
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 350;
  color: black;
  margin-right: 20px;
  margin-bottom: 0;
`;
const InfoItemsWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  //border: 1px solid red;
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;
const ColumnButton = styled.button`
  width: ${(props) => props.width};
  height: 40px;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  border-bottom: 3px solid rgba(242,242,248,255);
  margin-left: ${(props) => props.marginLeft};
  

  &:hover {
    color: rgba(0,147,255,255);
    border-bottom: 3px solid rgba(0,147,255,255);
    //background-image: url("${downArrow}");
    background-image: url("${(props) => props.url}");
    //background-size: 7%;
    background-repeat: no-repeat;
    background-position: 90% 50%; //x%, y%
  }

  &:focus {
    outline: none;
    
  }
`;

const ApplicantTable = styled.table`
  width: 100%;
`;
const TableBody = styled.tbody`
  display: block;
  height: 280px;
  overflow-y: auto;
`;

const LastApplicantWinContainer = styled.section`
  
  top: ${(props) => props.top || '260px'};
  left: ${(props) => props.left || '30px'};
  width: ${(props) => props.width || '730px'};
  height: ${(props) => props.height || '300px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 3; //prevent the first avatar on RecentRequest from showing here
  //overflow: scroll;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
`;
const LastApplicantCaption = styled.label`
  width: 100%; //100% makes text-align work
  height: 40px;
  text-align:center;
  font-size: 22px;
  font-weight: 600;
`;
const LastApplicantText = styled.label`
  width: 100%; //100% make text-align work
  height: 40px;
  text-align:center;
  font-size: 14px;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
`;
const LastApplicantButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  //border: 1px solid red;

`;

const LastApplicantButton = styled.button`
  width: ${(props) => props.width || '100px'};
  //width: 100px;
  height: 30px;
  border-radius: 15px;
  color: rgba(116,122,128,255);
  background-color: white;
  border: 1px solid rgba(116,122,128,255);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: black;
  }

`;

const LastApplicantButtonConfirm = styled.button`
  width: ${(props) => props.width || '300px'};
  //width: 300px;
  height: 30px;
  border-radius: 15px;
  color: rgba(116,122,128,255);
  background-color: white;
  border: 1px solid rgba(116,122,128,255);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: black;
  }

`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
  //border: 1px solid red;
`;

export default {
  DetailedWinContainer,
  DetailedWinCloseButton,
  ShiftRequestsLoadingLayer,
  HeaderWrap,
  Caption,
  IconTextWrap,
  Icon,
  TextWrap,
  Text,
  Text1,
  InfoItemsWrap,
  ButtonWrap,
  ColumnButton,
  ApplicantTable,
  TableBody,
  LastApplicantWinContainer,
  LastApplicantCaption,
  LastApplicantText,
  LastApplicantButtonWrap,
  LastApplicantButton,
  LastApplicantButtonConfirm,
};
