import styled from 'styled-components/macro';

const DetailsWrap = styled.div`
  padding: 1.5rem 3rem;
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const DetailsHeader = styled.h2`
  font-weight: 700;
  color: #6c757d;
`;

const Detail = styled.p`
  padding-left: 1rem;
`;

export default {
  Detail,
  DetailsWrap,
  DetailsRow,
  DetailsHeader,
};
