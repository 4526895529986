import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const ShiftRequestsLoadingLayer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  top: 45%;
  margin: auto;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45%;
  left: 33%;
  width: 400px;
  //border: 1px solid black;
`;

const PleaseWait = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`;
const Processing = styled.label`
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const Percent = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  color: rgba(3,135,255,255);

`;

const ProgressBar = styled.div`
  position: fixed;
  height: 16px;
  border-radius: 8px;
  background-color: rgba(3,135,255,255);
`;

const Frame = styled.div`
  display: inline-block;
  height: 16px;
  width: 100%;
  border-radius: 8px;
  //border: 1px solid rgba(3,135,255,255);
  background-color: white;
`;



export default {
  ShiftRequestsLoadingLayer,
  Wrap,
  PleaseWait,
  Processing,
  Percent,
  ProgressBar,
  Frame,
};
