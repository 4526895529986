import { connect } from 'react-redux';

import { appActions } from 'App/duck';
import { userManagementOperations } from '../../duck';
import { userManagementActions } from '../../duck';
import Header from './Header';

const mapStateToProps = ({ setupReducer }) => {
  let currentUserPrivilegeId = null;

  if (
    setupReducer.setup_actions &&
    setupReducer.setup_actions.meta &&
    setupReducer.setup_actions.meta.current_user_privilege
  ) {
    currentUserPrivilegeId =
      setupReducer.setup_actions.meta.current_user_privilege.id;
  }

  return {
    currentUserPrivilegeId,
  };
};

const mapDispatchToProps = (dispatch) => {
  const handlePromote = (userPrivilegeId) => {
    dispatch(userManagementOperations.updateAdminStatus(userPrivilegeId, true));
  };

  const handleWithdraw = (userPrivilegeId) => {
    dispatch(userManagementOperations.updateAdminStatus(userPrivilegeId, false));
  };

  const handleApprove = (userPrivilegeId, locationId) => {
    dispatch(userManagementOperations.approveUser(userPrivilegeId, locationId));
  };

  const handleDeny = (userPrivilegeId, locationId) => {
    dispatch(userManagementOperations.denyUser(userPrivilegeId, locationId));
  };

  const handleBlock = (user_privilege_id) => {
    dispatch(userManagementOperations.blockUser(user_privilege_id));
  };


  return {
    handlePromote,
    handleWithdraw,
    handleApprove,
    handleDeny,
    handleBlock,
  };
};

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default HeaderContainer;
