import { shape, string, number, arrayOf, bool, oneOfType } from 'prop-types';

const postsDataShape = shape({
  id: number.isRequired,
  content: string.isRequired,
  comments_count: number.isRequired,
  created_at: string.isRequired,
  attachment: shape({
    object_instances: arrayOf(
      shape({
        thumb_url: string,
        gallery_url: string,
        original_url: string,
        full_url: string,
        image_width: number,
        image_height: number,
      })
    ),
  }),
  final_allow_delete: bool.isRequired,
  final_allow_comment: oneOfType([number, bool]).isRequired,
  owner_id: number.isRequired,
  url_meta: shape({
    //author: string.isRequired,
    //date: string.isRequired,
    description: string.isRequired,
    image: shape({
      gallery_url: string,
    }),
    logo: shape({
      gallery_url: string,
    }),
    publisher: string.isRequired,
    title: string.isRequired,
    url: string.isRequired,
  }),
  attached_files_blobs: arrayOf(
    shape({
      blob_url: string,
      byte_size: number,
      content_type: string,
      file_name: string,
      id: number,
      preview_blob_rul: string,
  })),
});

const posts = shape({
  data: arrayOf(postsDataShape).isRequired,
  loading: bool.isRequired,
  page: number.isRequired,
  totalPages: number.isRequired,
  addedComments: arrayOf(number),
  deletedComments: arrayOf(number),
});

const active = shape({
  //post: number.isRequired,
  post: shape ({
    id: number.isRequired,
    channel_name: string.isRequired,
  }),
  channel: number.isRequired,
  comment: number.isRequired,
  delete: shape({
    id: number.isRequired,
    type: string.isRequired,
  }),
  userIsAdmin: bool.isRequired,
  subscriptions: bool.isRequired,
});

export default {
  posts,
  active,
  postsDataShape,
};
