import { createSlice } from '@reduxjs/toolkit';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    data: [],
    loading: true,
    page: 1,
    totalPages: 1,
  },
  reducers: {
    hydrateComments: (state, action) => {
      state.data = action.payload.data;
      state.totalPages = action.payload.total_pages;
      state.loading = false;
    },
    updateComments: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
    },
    createComment: (state, action) => {
      //state.data = state.data.push(action.payload);
      const newData = [...state.data];
      newData.push(action.payload);
      state.data = newData;
    },
    deleteComment: (state, action) => {
      state.data = state.data.filter((post) => post.id !== action.payload);
    },
    requestComments: (state, action) => {
      state.loading = true;
      state.page = action.payload;

      if (action.payload === 1) {
        state.data = [];
      }
    },
    clearComments: (state) => {
      state.data = [];
    },
  },
});

export default commentsSlice;
