import axios from 'axios';
import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';
import { BASE_URL } from '../../constants';
import { getLocationCode, getLocaleCookie } from '../cookies';


/**
 * Gets all usable skills for current location.
 *
 * @param  { String | Int } {location_id}
 */
export const GetLocationUsableSkills = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/usable_skills`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * add a user to the department
 * @param  {Number} {user privilege id
 * @param  {Number} department id
 * @param  {Bool} is main}
*/
export const addUsersToSkill = (userIds, location_id, skill) =>
  protectedAxiosInstance.put(
    `api/customer/user_role/locations/${location_id}/assign_skill_users/${skill.id}?`,
    { 
      add_user_ids: userIds,
    },
  );

/**
 * remove a user from the department
 * @param  {Number} {user privilege id
 * @param  {Number} department id
 * @param  {Bool} is main}
*/
export const removeSkillUsers = (userIds, location_id, skill) =>
  protectedAxiosInstance.put(
    `api/customer/user_role/locations/${location_id}/assign_skill_users/${skill.id}?`,
    {
      remove_user_ids: userIds,
    },
  );






