import React from 'react';
import { checkForAuthentication } from 'helpers/cookies';
import { includes } from 'helpers/validation';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import ReactDOM from 'react-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import Logo from '../logo';
//import { default as PanelStyled } from './styled';
import './NavPanel.scss';
import { getUserId, purgeAuthenticationCookie } from 'helpers/cookies';
import Modal from './Modal';
//import LocationPicker from '../DashboardComponents/Header/LocationPicker/LocationPickerContainer';
import {GetAllEmployees, GetAllEmployeesWithCallback} from 'helpers/api-calls/employee-interaction-calls';

import styled from './styled';



class NavPanelComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
                  height: props.height,
                  dashActive: true,
                  calendarActive: false,
                  requestsActive: false,
                  teamActive: false,
                  feedActive: false,
                  scheduleActive: false,
                  logoutActive: false,
                  settingsActive: false,
                  locationActive: false,
                  profileActive: false,
                  openLocationMenu: false,
                  locationInfo: [],
                  currentElement: this.props.options[0] ? this.props.options[0] : undefined,
                  currentLocation: null,
                  allEmployees: [],
                  locationEmployees: [],
                  lastLocationIndex: 0,
                  selectedPage: '',
                  selectedSettings: false,
                  selectedLocation: false,
                  selectedProfile: false,
                  dashPos: null,
                  calendarPos: null,
                  requestPos: null,
                  feedPos: null,
                  teamPos: null,
                  schedulePos: null,
                  settingsPos: null,
                  locationPos: null,
                  profilePos: null,
                  };

    //this.navigateToHomePage = this.navigateToHomePage.bind(this);
    //this.navigateToCalendarPage = this.navigateToCalendarPage.bind(this);
    //this.navigateToTeamPage = this.navigateToTeamPage.bind(this);
    //this.navigateToTeamFeedPage = this.navigateToTeamFeedPage.bind(this);
    //this.navigateToSchedulePage = this.navigateToSchedulePage.bind(this);
    //this.handleLogOut = this.handleLogOut.bind(this);
    //this.dashMouseEnter = this.dashMouseEnter.bind(this);
    //this.dashMouseLeave = this.dashMouseLeave.bind(this);
    //this.calendarMouseEnter = this.calendarMouseEnter.bind(this);
    //this.calendarMouseLeave = this.calendarMouseLeave.bind(this);
    //this.requestsMouseEnter = this.requestsMouseEnter.bind(this);
    //this.requestsMouseLeave = this.requestsMouseLeave.bind(this);
    //this.teamMouseEnter = this.teamMouseEnter.bind(this);
    //this.teamMouseLeave = this.teamMouseLeave.bind(this);
    //this.feedMouseEnter = this.feedMouseEnter.bind(this);
    //this.feedMouseLeave = this.feedMouseLeave.bind(this);
    //this.logoutMouseEnter = this.logoutMouseEnter.bind(this);
    //this.logoutMouseLeave = this.logoutMouseLeave.bind(this);
    //this.handleToggle = this.handleToggle.bind(this);


    this._isMounted = false;
    this.locationArrayIdx = 0;

    this.home = "/";
    this.calendar = "/calendar";
    this.feed = "/team-feed";
    this.management = "/usermanagement";
    this.scheduler = "/scheduler";

    this.dashRef = React.createRef();
    this.calendarRef = React.createRef();
    this.requestRef = React.createRef();
    this.feedRef = React.createRef();
    this.teamRef = React.createRef();
    this.scheduleRef = React.createRef();
    this.settingsRef = React.createRef();
    this.locationRef = React.createRef();
    this.profileRef = React.createRef();

    this.mouseTipW = 50;
    this.mouseTipH = 30;

  }

  messages = defineMessages({
    employees: {
      id: 'NavPanelComponent.employees',
      defaultMessage: "employees",
    },
    
  });

  componentDidMount() {
    const {options, apiCalled, loadOptions, currentElement, user, locationId, locations, } = this.props;

    this._isMounted = true;
    /*
    this.setState({height: window.innerHeight + 'px'});

    if (apiCalled && options.length > 0) {
      this.setState({
        value:
          currentElement !== 'false' && currentElement
            ? this.findCurrentElement(currentElement)?.value
            : options[0].value,
        currentElement: currentElement
          ? this.findCurrentElement(currentElement)
          : options[0],
      });

    }else if (checkForAuthentication()){
      loadOptions();
    }

    if(this.state.locationInfo.length === 0){
      for(let i=0; i<options.length; i++){
        let obj = {value: 0, text: '', subText: '', employees: 0};
        obj.value = options[i].value;
        obj.text = options[i].text;
        obj.subText = options[i].subText;

        //let {locationInfo} = this.state;
        let tempInfo = this.state.locationInfo;
        tempInfo.push(obj);
        this.setState({locationInfo: tempInfo});
      }
      
      if(options.length > 0) {
        GetAllEmployeesWithCallback(options[this.locationArrayIdx].value, 
          this.succeededCallback, 
          this.failedCallback).then((data) => {
            if(data){
              this.succeededCallback(data);
            }
          }).catch((error) =>{
            if(error){
              this.failedCallback(error);
            }
        })
      }
      
    }
    */

    if(locations && locations.length > 0) {
      //console.log('got locations');
    }
  }

  componentDidUpdate(prevProps) {
    const {options, apiCalled, loadOptions, currentElement, userInfo, locationId, locations, user, } = this.props;
    /*
    if(prevProps.options !== options){
      if(this.state.locationInfo.length === 0){
        for(let i=0; i<options.length; i++){
          let obj = {value: 0, text: '', subText: '', employees: 0};
          obj.value = options[i].value;
          obj.text = options[i].text;
          obj.subText = options[i].subText;
          let {locationInfo} = this.state;
          locationInfo.push(obj);
          this.setState({locationInfo: locationInfo});
        }
        
        if(options.length > 0) {
          GetAllEmployeesWithCallback(options[this.locationArrayIdx].value, 
            this.succeededCallback, 
            this.failedCallback).then((data) => {
              if(data){
                this.succeededCallback(data);
              }
            }).catch((error) =>{
              if(error){
                this.failedCallback(error);
              }
          })
        }
        
        if(options.length > 0) {
          if(currentElement) {
            const returnObj = this.findCurrentElement(currentElement);
            if(returnObj){
              this.setState({currentLocation: returnObj});
              console.log('pos4');
            }
          }else {
            if(userInfo){
              const returnObj = this.findCurrentElement(userInfo.current_user_privilege.location_id);
              if(returnObj){
                this.setState({currentLocation: returnObj});
                console.log('pos4');
              }
            }else{
              this.setState({currentLocation: options[0]});
              console.log('pos5');
            }
          }
        }
      }
    }
    */

    if(prevProps.locations !== locations) {
      if(locations && locations.length > 0) {
        const current = locations.filter((item) => item.id.toString() === locationId);
        if(current && current.length > 0) {
          this.setState({currentLocation: current[0]});
        }
      }
    }

    if(prevProps.user !== user) {
      console.log('user updated');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  findCurrentElement = value => {
    return this.props.options.filter((element) => {
      if (value == element.value) {
        return element;
      }
    })[0];
  };

  findCurrentLocation = (locationCode) => {
    const {options} = this.props;
    var obj = null;

    if(options.length === 0){
      return null;
    }
    
    const idx = options.findIndex(
      (option) => (option.value === locationCode)
    );

    if(idx >= 0){
      obj = options[idx];
    }
    return obj;
  }

  succeededCallback = (data) => {
    const {options,} = this.props;
    let {locationInfo} = this.state;
    if(!this._isMounted){
      return;
    }
    
    locationInfo[this.locationArrayIdx].employees = data.user_privileges ? data.user_privileges.length : 0;
    this.setState({locationInfo: locationInfo});
    if(this.locationArrayIdx < locationInfo.length -1) {
      this.locationArrayIdx +=1;
      //console.log('index =  ' + this.locationArrayIdx);
      if(options.length > 0) {
        GetAllEmployeesWithCallback(options[this.locationArrayIdx].value, 
          this.succeededCallback, 
          this.failedCallback).then((data) => {
            if(data){
              this.succeededCallback(data);
            }
        }).catch((error) =>{
            if(error){
              this.failedCallback(error);
            }
        });
      }
    }

  }

  failedCallback = (error) => {
    const {options} = this.props;
    const {locationInfo} = this.state;
    if(!this._isMounted){
      return;
    }
    // skip the failed location
    console.log('failedCallback idx = ' + this.locationArrayIdx + '   error = ' + error);
    if(this.locationArrayIdx < locationInfo.length -1) {
      this.locationArrayIdx +=1;
      if(options.length > 0) {
        GetAllEmployeesWithCallback(options[this.locationArrayIdx].value, 
          this.succeededCallback, 
          this.failedCallback).then((data) => {
            if(data){
              this.succeededCallback(data);
            }
        }).catch((error) =>{
            if(error){
              this.failedCallback(error);
            }
        });
      }
    }
  }
  */

  navigateToHomePage = (event) => {
    this.setState({selectedPage: this.home});
  }

  navigateToCalendarPage = (event) => {
    this.setState({selectedPage: this.calendar});
  }

  navigateToTeamPage = (event) => {
    //this.setState({selectedPage: this.home});
  }

  navigateToTeamFeedPage = (event) => {
    this.setState({selectedPage: this.feed});
  }

  navigateToSchedulePage = (event) => {
    this.setState({selectedPage: this.scheduler});
  }

  navigateToUserManagementPage = (event) => {
    this.setState({selectedPage: this.management});
  }

  handleLogOut = (event) => {
    purgeAuthenticationCookie();
    window.location.pathname = '/';
    event.preventDefault();
  }

  dashMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.dashRef && this.dashRef.current) {
      const domNode = ReactDOM.findDOMNode(this.dashRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({dashPos: winData});
    this.setState({dashActive: true});
  }

  dashMouseLeave = (event) => {
    this.setState({dashActive: false});
  }

  calendarMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.calendarRef && this.calendarRef.current) {
      const domNode = ReactDOM.findDOMNode(this.calendarRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({calendarPos: winData});
    this.setState({calendarActive: true});
  }

  calendarMouseLeave = (event) => {
    this.setState({calendarActive: false});
  }

  requestsMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.requestRef && this.requestRef.current) {
      const domNode = ReactDOM.findDOMNode(this.requestRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({requestPos: winData});
    this.setState({requestsActive: true});
  }

  requestsMouseLeave = (event) => {
    this.setState({requestsActive: false});
  }

  teamMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.teamRef && this.teamRef.current) {
      const domNode = ReactDOM.findDOMNode(this.teamRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({teamPos: winData});
    this.setState({teamActive: true});
  }

  teamMouseLeave = (event) => {
    this.setState({teamActive: false});
  }

  scheduleMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.scheduleRef && this.scheduleRef.current) {
      const domNode = ReactDOM.findDOMNode(this.scheduleRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({schedulePos: winData});
    this.setState({scheduleActive: true});
  }

  scheduleMouseLeave = (event) => {
    this.setState({scheduleActive: false});
  }

  feedMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.feedRef && this.feedRef.current) {
      const domNode = ReactDOM.findDOMNode(this.feedRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({feedPos: winData});
    this.setState({feedActive: true});
  }

  feedMouseLeave = (event) => {
    this.setState({feedActive: false});
  }

  logoutMouseEnter = (event) => {
    this.setState({logoutActive: true});
  }

  logoutMouseLeave = (event) => {
    this.setState({logoutActive: false});
  }

  settingsMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.settingsRef && this.settingsRef.current) {
      const domNode = ReactDOM.findDOMNode(this.settingsRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({settingsPos: winData});
    this.setState({settingsActive: true});
  }

  settingsMouseLeave = (event) => {
    this.setState({settingsActive: false});
  }

  locationMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.locationRef && this.locationRef.current) {
      const domNode = ReactDOM.findDOMNode(this.locationRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({locationPos: winData});
    this.setState({locationActive: true});
  }

  locationMouseLeave = (event) => {
    this.setState({locationActive: false});
  }

  profileMouseEnter = (event) => {
    let winTop = '';
    let winLeft = '';
    const winW = `${this.mouseTipW}px`;
    const winH = `${this.mouseTipH}px`;
    if(this.profileRef && this.profileRef.current) {
      const domNode = ReactDOM.findDOMNode(this.profileRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + (output.height - this.mouseTipH)/2}px`;
        winLeft = `${output.x + output.width}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({profilePos: winData});
    this.setState({profileActive: true});
  }

  profileMouseLeave = (event) => {
    this.setState({profileActive: false});
  }

  handleSettingsClicked = (event) => {
    this.setState({selectedSettings: true, selectedLocation: false, selectedProfile: false});
  }

  handleLocationClicked = (event) => {
    this.setState({selectedSettings: false, selectedLocation: true, selectedProfile: false});
  }

  handleProfileClicked = (event) => {
    this.setState({selectedSettings: false, selectedLocation: false, selectedProfile: true});
  }

  handleToggle = (event) => {
    //this.setState({fullSize: !this.state.fullSize});
    event.preventDefault();
  }

  handleLocationClick = (event) => {
    this.setState({openLocationMenu : !this.state.openLocationMenu});
    event.preventDefault();
  }

  handleLocationSelected = (location) => {
    const {currentElement, getCostCenterData} = this.props;

    const msg = `name = ${location.text}, code = ${location.value}, employees = ${location.employees}`;
    console.log(msg);
    this.setState({currentLocation: location});
    this.setState({currentElement: location.value});
    this.setState({openLocationMenu: false});

    if(location && location.value){
      getCostCenterData(location.value);
      const msg = `NavPanelComponents called getCostCenterData for location ${location.value}`;
      console.log(msg);
    }
  }

  handleLocationEmployees = (index, code, employees) => {
    const {allEmployees, updating } = this.state;
    /*
    const msg = `index = ${index}, code = ${code}, employees = ${employees.length}`;
    console.log(msg);
    */
    let tempEmployees = allEmployees;
    for(let i=0; i<employees.length; i++) {
      const idx = allEmployees.findIndex((item) => (item.id === employees[i].id));
      if(idx<0) {
        tempEmployees.push(employees[i]);
      }
    }
    this.setState({allEmployees: tempEmployees});

    let obj = {index: 0, code: 0, numEmployees: 0};
    obj.index = index;
    obj.code = code;
    obj.numEmployees = employees.length;
    let tempInfo = this.state.locationEmployees;
    tempInfo.push(obj);
    this.setState({locationEmployees: tempInfo});
    this.setState({lastLocationIndex: index});
  }

  renderMouseTip = (pos, content) => {

    if(pos) {
      const containerStyle = {
        top: pos.top,
        left: pos.left,
        minWidth: pos.width,
        minHeight: pos.height,
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
      };

      const spanStyle = {
        width: '2px',
        height: '10px',
        marginRight: '0px',
        backgroundColor: 'black',
        //border: '1px solid red',
      };

      const textStyle = {
        minWidth: pos.width,
        height: pos.height,
        backgroundColor: 'black',
        color: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginLeft: '0px',
        marginRight: '5px',
        fontFamily: 'AvertaLight',
        src: "url('/fonts/averta/Averta-Light.ttf')",
        fontSize: '10px',
        fontWeight: 600,
        //border: '1px solid red',
      }
      return (
        <div style={containerStyle}>
          <span style={spanStyle}></span>
          <div style={textStyle}>{content}</div>
        </div>
      );
    }
  }


  render(){
    const path = window.location.pathname;
    const {allowTeamFeed, userInfo, intl, currentElement, user, } = this.props;
    const {dashActive, 
          calendarActive, 
          requestsActive,
          teamActive, 
          feedActive, 
          scheduleActive,
          logoutActive,
          settingsActive,
          locationActive,
          profileActive,
          currentLocation,
          locationInfo,
          selectedPage,
          selectedSettings,
          selectedLocation,
          selectedProfile,
          dashPos,
          calendarPos,
          requestPos,
          feedPos,
          teamPos,
          schedulePos,
          settingsPos,
          locationPos,
          profilePos,
        } = this.state;


    const userName = `${userInfo ? userInfo.first_name: ''} ${userInfo ? userInfo.last_name: ''}`;
    /*
    let codeAndEmployees = '';
    if(currentLocation){
      codeAndEmployees = `${currentLocation ? currentLocation.value : ''} . 
      ${currentLocation.employees ? currentLocation.employees : '--'} 
      ${intl.formatMessage(this.messages.employees)}`;
    }
    */

    let locationAvatarUrl = '';
    if(currentLocation && currentLocation.avatar_blob) {
      locationAvatarUrl = currentLocation.avatar_blob.blob_url;
    }

    let userAvatarUrl = '';
    if(user && user.profile_image) {
      userAvatarUrl = user.profile_image.thumb_url;
    }

    const settingsClassName = selectedSettings ? 'settings-button-wrap-selected' : 'settings-button-wrap';
    const locationClassName = selectedLocation ? 'button-wrap-selected' : 'button-wrap';
    const profileClassName = selectedProfile ? 'button-wrap-selected' : 'button-wrap';

    const showSchedulerTab = user && (user.id === 15408||user.id === 7709 || user.id === 12799 || user.id === 14815 || user.id === 14415 || user.id === 12227) ? true : false;
    
    const dashText = (<FormattedMessage
      id="NavPanelComponent.dash"
      defaultMessage="Home"
    />);

    const calendarText = (<FormattedMessage
      id="NavPanelComponent.calendar"
      defaultMessage="Calendar"
    />);

    const requestsText = (<FormattedMessage
      id="NavPanelComponent.requests"
      defaultMessage="Requests"
    />);

    const feedText = (<FormattedMessage
      id="NavPanelComponent.feed"
      defaultMessage="Community"
    />);

    const teamText = (<FormattedMessage
      id="NavPanelComponent.team"
      defaultMessage="Team"
    />);

    const scheduleText = (<FormattedMessage
      id="NavPanelComponent.schedule"
      defaultMessage="Import Schedule"
    />);

    const settingsText = (<FormattedMessage
      id="NavPanelComponent.settings"
      defaultMessage="Settings"
    />);

    const locationText = (<FormattedMessage
      id="NavPanelComponent.location"
      defaultMessage="Location"
    />);

    const profileText = (<FormattedMessage
      id="NavPanelComponent.profile"
      defaultMessage="Profile"
    />);

    if(
      checkForAuthentication() &&
      !includes(path, 'login') &&
      !includes(path, 'sign-up') &&
      !includes(path, 'password')
    ) {
      return(
        <header className="header-panel">
          <section className="section">
            <img 
              className="shyft-logo"
              src="/icons/favicon.ico">
            </img>
              <ul className="nav-bar-list">
                <li>
                  <NavLink
                    ref={this.dashRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    strict
                    exact
                    onClick={this.navigateToHomePage}
                    onMouseEnter={this.dashMouseEnter}
                    onMouseLeave={this.dashMouseLeave}
                    to={this.home}
                  >
                    <img
                      className="btn-nav--img"
                      src={dashActive ? '/icons/home-icon-active.svg': '/icons/home-icon-inactive.svg'}
                      width="15"
                      alt="Dashboard Icon"
                    />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    ref={this.calendarRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.calendar}
                    onClick={this.navigateToCalendarPage}
                    onMouseEnter={this.calendarMouseEnter}
                    onMouseLeave={this.calendarMouseLeave}
                  >
                    <img
                    className="btn-nav--img"
                    src={calendarActive ? '/icons/schedule-icon-active.svg' : '/icons/schedule-icon-inactive.svg'}
                    width="15"
                    alt="Calendar Icon"
                    />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    ref={this.requestRef}
                    //activeClassName="active-page" // will be uncommeted when the page is ready
                    className="button btn-nav"
                    to={this.home}
                    //onClick={this.navigateToCalendarPage}
                    onMouseEnter={this.requestsMouseEnter}
                    onMouseLeave={this.requestsMouseLeave}
                  >
                    <img
                    className="btn-nav--img"
                    src={requestsActive ? '/icons/requests-icon-active.svg' : '/icons/requests-icon-inactive.svg'}
                    width="15"
                    alt="Requests Icon"
                    />
                  </NavLink>
                </li>
                {allowTeamFeed && (
                <li>
                  <NavLink
                    ref={this.feedRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.feed}
                    onClick={this.navigateToHomePage}
                    onMouseEnter={this.feedMouseEnter}
                    onMouseLeave={this.feedMouseLeave}
                  >
                    <img
                      className="btn-nav--img"
                      src={feedActive ? '/icons/community-icon-active.svg': '/icons/community-icon-inactive.svg'}
                      width="15"
                      alt="Team Feed Icon"
                    />
                  </NavLink>
                </li>
                )}
                <li>
                  <NavLink
                    ref={this.teamRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.management}
                    onClick={this.navigateToUserManagementPage}
                    onMouseEnter={this.teamMouseEnter}
                    onMouseLeave={this.teamMouseLeave}
                  >
                    <img 
                    className="btn-nav--img"
                    src={teamActive ? '/icons/team-icon-active.svg' : '/icons/team-icon-inactive.svg'} 
                    width="15" 
                    alt="Team Icon"
                    />
                  </NavLink>
                </li>
                {showSchedulerTab && <li>
                  <NavLink
                    ref={this.scheduleRef}
                    activeClassName="active-page"
                    className="button btn-nav"
                    to={this.scheduler}
                    onClick={this.navigateToSchedulePage}
                    onMouseEnter={this.scheduleMouseEnter}
                    onMouseLeave={this.scheduleMouseLeave}
                  >
                    <img 
                    className="btn-nav--img"
                    src={scheduleActive ? '/icons/schedule-upload-icon-active.svg' : '/icons/schedule-upload-icon-inactive.svg'} 
                    width="15" 
                    alt="Schedule Icon"
                    />
                  </NavLink>
                </li>}
              </ul>
              <ul className="button-list">
                <div 
                  className={settingsClassName} 
                  ref={this.settingsRef} 
                  onClick={this.handleSettingsClicked}
                  onMouseEnter={this.settingsMouseEnter}
                  onMouseLeave={this.settingsMouseLeave}
                >
                  <img 
                    className="settings-button-image"
                    src={settingsActive ? '/icons/settings-active.svg' : '/icons/settings-inactive.svg'} 
                    width="15" 
                    alt="Settings Icon"
                    />
                </div>
                <div 
                  className={locationClassName}
                  ref={this.locationRef}
                  onClick={this.handleLocationClicked}
                  onMouseEnter={this.locationMouseEnter}
                  onMouseLeave={this.locationMouseLeave}
                >
                  <img 
                    className="button-image"
                    src={locationAvatarUrl ? locationAvatarUrl : '/icons/channel-placeholder-avatar.svg'} 
                    width="15" 
                    alt="Location Icon"
                    />
                </div>
                <div 
                  className={profileClassName}
                  ref={this.profileRef}
                  onClick={this.handleProfileClicked}
                  onMouseEnter={this.profileMouseEnter}
                  onMouseLeave={this.profileMouseLeave}
                >
                <img 
                  className="button-image"
                  src={userAvatarUrl ? userAvatarUrl : '/icons/default-profile-thumb.svg'} 
                  width="15" 
                  alt="Profile Icon"
                  />
                </div>
              </ul>
              {this.state.openLocationMenu && <Modal>
                  <LocationPicker top="60px" left="30px"
                    className="location-menu"
                    closeWin={this.closeModalWindow}
                    currentElement={currentElement}
                    parentLocationInfo={this.state.locationInfo} 
                    handleLocationSelected={this.handleLocationSelected}
                    handleLocationEmployees={this.handleLocationEmployees}
                    startLocationIndex={this.state.lastLocationIndex}>
                  </LocationPicker>
              </Modal>}
              {dashActive && <Modal>
                {this.renderMouseTip(dashPos, dashText)}
              </Modal>}
              {calendarActive && <Modal>
                {this.renderMouseTip(calendarPos, calendarText)}
              </Modal>}
              {requestsActive && <Modal>
                {this.renderMouseTip(requestPos, requestsText)}
              </Modal>}
              {feedActive && <Modal>
                {this.renderMouseTip(feedPos, feedText)}
              </Modal>}
              {teamActive && <Modal>
                {this.renderMouseTip(teamPos, teamText)}
              </Modal>}
              {scheduleActive && <Modal>
                {this.renderMouseTip(schedulePos, scheduleText)}
              </Modal>}
              {settingsActive && <Modal>
                {this.renderMouseTip(settingsPos, settingsText)}
              </Modal>}
              {locationActive && <Modal>
                {this.renderMouseTip(locationPos, locationText)}
              </Modal>}
              {profileActive && <Modal>
                {this.renderMouseTip(profilePos, profileText)}
              </Modal>}
          </section>
        </header>
      )
    }
    if(
      !checkForAuthentication() &&
      (includes(path, 'login') ||
      includes(path, 'sign-up') ||
      includes(path, 'add-location'))
    ) {
      return <PageHeader authenticated={false} />;
    }

    return <PageHeader authenticated={false} />;
  }
}

NavPanelComponent.propTypes={
  
}

export default injectIntl(NavPanelComponent);
