import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './ButtonStyles';


class LastToApprove extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
        currentAction: '',
    };
	}

	componentDidMount () {
		const {shift, applicant} = this.props;

	}

	componentDidUpdate (prevProps) {

	}

	handleCancel = (event) => {
		const {closePopup} = this.props;
		closePopup();
	}

	handleConfirm = (event) => {
		const {shift, applicant, closePopup, handleUpdateApplicantStatusForAutoDeny, handleConfirm} = this.props;
		handleUpdateApplicantStatusForAutoDeny(shift.id, applicant.id, true);
		if(shift.applicants && shift.applicants.length > 0) {
			for(let i=0; i<shift.applicants.length; i++) {
				if(shift.applicants[i].status === 'applied' && shift.applicants[i].id !== applicant.id) {
					handleUpdateApplicantStatusForAutoDeny(shift.id, shift.applicants[i].id, false);
				}
			}
		}
		closePopup();
	}

	render () {
		const {shift, applicant, locationId} = this.props;

		const fullName = `${applicant.user.first_name} ${applicant.user.last_name}`;
		const shiftTitle = (
											<FormattedMessage id="LastToApprove.shiftTitle"
	        						defaultMessage="Approve last applicant and close shift?"/>);
		const vtoTitle = (
											<FormattedMessage id="LastToApprove.vtoTitle"
	        						defaultMessage="Approve last applicant and close VTO?"/>);
		const common = (
											<FormattedMessage id="LastToApprove.common"
	        						defaultMessage="Make sure you have considered regulations related to overtime, minor work rules, scheduling legislation, etc."/>);
		const shiftText = (
											<FormattedMessage id="LastToApprove.shiftText"
	        						defaultMessage="Once you approve this applicant, the shift will be closed. Any pending applicants will be auto-denied and notified."/>);
		const vtoText = (<FormattedMessage id="LastToApprove.vtoText"
	        						defaultMessage="Once you approve this applicant, the VTO will be closed. Any pending applicants will be auto-denied and notified."/>);
		const gapCommon = (<FormattedMessage id="LastToApprove.gapCommon"
	        						defaultMessage="You are about to approve this schedule change for this employee, who has voluntarily agreed to cover this shift."/>);
		const gapShiftText = (<FormattedMessage id="LastToApprove.gapShiftText"
	        						defaultMessage="Once you approve this applicant, the shift will be closed. Any pending applicants will be auto-denied and notified."/>);
		
		const gapLocation = locationId === '5477' || locationId === '5466' || locationId === '2331' || locationId === '5668' ||
												locationId === '8666' || locationId === '5667' || locationId === '2014' || locationId === '2232' ||
												locationId === '1714';

		const msg = `LastToApprove: LocationId = ${locationId} `;
		console.log(msg);

		let text = '';
		let title = '';
		switch(shift.name) {
			case 'open_shift':
				text = shiftText;
				title = shiftTitle;
				break;
			case 'shift':
				text = shiftText;
				title = shiftTitle
				break;
			case 'shift_vto':
				text = vtoText;
				title = vtoTitle;
				break;
		}

		let defaultConfirmButtonText = (<FormattedMessage
			        id="LastToApprove.confirm1"
			        defaultMessage="Confirm {name}"
			        values={{name: applicant.user.first_name}}/>);
		let gapConfirmButtonText = (<FormattedMessage
			        id="LastToApprove.gapConfirm"
			        defaultMessage="I approve this schedule change"/>);
		
		
		return (
			<styled.LastApplicantWinContainer>
   			<styled.LastApplicantCaption>{title}</styled.LastApplicantCaption>
	      {gapLocation ? <styled.LastApplicantText>{gapCommon}</styled.LastApplicantText> : <styled.LastApplicantText>{common}</styled.LastApplicantText>}
	      {gapLocation ? <styled.LastApplicantText>{gapShiftText}</styled.LastApplicantText> : <styled.LastApplicantText>{text}</styled.LastApplicantText>}
				<styled.LastApplicantButtonWrap>
					<styled.LastApplicantButton onClick={this.handleCancel}>
						 <FormattedMessage
			        id="LastToApprove.cancel"
			        defaultMessage="Cancel"
			      />
					</styled.LastApplicantButton>
					{gapLocation ? <styled.LastApplicantButtonConfirm onClick={this.handleConfirm}>
			      {gapConfirmButtonText}
					</styled.LastApplicantButtonConfirm> : 
					<styled.LastApplicantButton  width="200px" onClick={this.handleConfirm}>
			      {defaultConfirmButtonText}
					</styled.LastApplicantButton>}
				</styled.LastApplicantButtonWrap>
			</styled.LastApplicantWinContainer>
		);
	}

}

LastToApprove.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(LastToApprove);