import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';


import styled from './styled';

class OwnerCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
      owner: null,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule, notifyScheduleOwner, } = this.props;

  	this._isMounted = true;
    if(schedule && schedule.user_id) {
      if(schedule && schedule.user_id && schedule.location_id) {
        getUserInfoNonAdmin(schedule.user_id, schedule.location_id).then((response) => {
          const skill_ids = response.data.user.skill_ids;
          this.setState({owner: response.data.user});
          notifyScheduleOwner(schedule.schedule_id, schedule.user_id, response.data.user);
        }).catch((error) =>{
           const msg = `UserRow.componentDidMount: call getUserInfoNonAdmin failed. error: ${error}`;
           console.log(msg);
        });
      }
    }
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { schedule } = this.props;
    const { owner } = this.state;
    

    const ownerImageUrl = owner && owner.profile_image ? 
            owner.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
    const ownerName = owner ? `${owner.first_name} ${owner.last_name}` : '';
    let submittedDate = '';
    const submit = moment(schedule.created_at);
    submittedDate = `${submit.format('MMM DD, YYYY')}`;
    const importedDate = (<FormattedMessage
                    id="OwnerCell.importedTDate"
                    defaultMessage="Imported schedule on {date}" 
                    values={{date: submittedDate}}/>);
    const approvedBadge = '/icons/verified-badge-icon.svg';
    
  	return (
  		<styled.TopWrap>
        <styled.OwnerAvatar src={ownerImageUrl}/>
        <styled.OwnerInfoWrap>
          <styled.OwnerInfoTopLine>
          {ownerName}
          {owner && owner.current_is_admin && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.OwnerInfoTopLine>
          <styled.OwnerInfoBottomLine>{importedDate}</styled.OwnerInfoBottomLine>
        </styled.OwnerInfoWrap>
      </styled.TopWrap>
  	);
  }
}


OwnerCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(OwnerCell);