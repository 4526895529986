import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import TableComponent from '../TableComponent';

const CellWrap = styled(TableComponent.Cell)`
  flex: 0;
  padding-right: 0;
`;

 const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border-color: rgb(37,150,90);
    //transition: all 150ms;
    position: relative;
    margin-left: 3px;
    //margin-right: 0.4rem;
    //margin-bottom: 0;
    order: 0;
`;



export default { CellWrap, CheckBox };
