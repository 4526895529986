import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const PageWrap = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  width: 100%;
  max-width: ${(props) => props.full || '1462px'};
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  overflow: auto;
  background-color: ${({ opaque }) => (opaque ? 'white' : 'transparent')};

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }
`;

PageWrap.propTypes = {
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
  full: PropTypes.bool,
};

export default PageWrap;
