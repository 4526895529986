import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

import Modal from 'components/GeneralComponents/Modal';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';
import UserCell from './Cell/UserCell';
import StatusCell from './Cell/StatusCell';
import Confirm from './Confirm';
import ConfirmStep2 from './ConfirmStep2';
import constants from '../../constants';

import './UserTable.scss';
import styled from './styled';

class UserRow extends React.Component {
	constructor(props) {
    super(props);

    this.state ={
      debug: false,
      userDepartments: null,
      userSkills: null,
      userGroups: [],
      userSelected: false,
      userInfo: null,
      mouseOverPhone: false,
      mouseOverEmail: false,
      mouseOverDept: false,
      mouseOverGroup: false,
      dropdownPos: null,
      openMenu: false,
      showConfirm: false,
      showConfirmStep2: false,
      selectedAction: null,
      phonePos: null,
      emailPos: null,
      departmentPos: null,
      groupPos: null,
      skillPos: null,
    };

    this._isMounted = false;
    this.apiCalled = false;
    this.calledUsers = [];
    this.phoneRef = React.createRef();
    this.emailRef = React.createRef();
    this.openMenuRef = React.createRef();
    this.menuWinRef = React.createRef();
    this.deptRef = React.createRef();
    this.deptRef2 = React.createRef();
    this.groupRef = React.createRef();
    this.groupRef2 = React.createRef();
    this.skillRef = React.createRef();
    this.skillRef2 = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    const { user, departments, skills, channels, locationId, channelData, selectedDepartment, selectedAllUsers, handleGetUserInformation, } = this.props;
  	this._isMounted = true;
    window.addEventListener('scroll', this.handleScrolled, true);
    document.addEventListener("mousedown", this.handleClickOutside);

    const depts = this.getUserDepartments();
    this.setState({userDepartments: depts});

    if(user && user.owner_id && locationId) {
      const results = this.getUserGroups1(user);
      this.setState({userGroups: results});
      if((user.user && user.user.skill_ids === null) || (user.user && user.user.skill_ids === undefined)) {
        if(user.status === 'active') {
          handleGetUserInformation(user, locationId);
          this.calledUsers.push(user.owner_id);
          this.apiCalled = true;
          //const msg = `componentDidMount: Location ${locationId}, User ${user.owner_id} api for skill called`;
          //console.log(msg);
        }
      }else {
        if(user.user) {
          const output = this.getUserSkills(user.user.skill_ids);
          if(this._isMounted) {
            this.setState({userSkills: output});
          }
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { user, departments, skills, selectedDepartment,  channelData, selectedAllUsers, filteredUsers, selectedType, selectedIndex,
          selectedUsers, lastAction, processedUsers, actionDoneOrCancelled, locationId, handleGetUserInformation, 
          rowMenuOpened, processingUserId, channels, } = this.props;
    const { userInfo, userSkills, userDepartments, mouseOverPhone, mouseOverEmail, openMenu, 
            mouseOverDept, mouseOverGroup, mouseOverSkill, phonePos, userSelected, userGroups, } = this.state;
    const { userActionTypes, } = constants;


    if(prevProps.locationId !== locationId) {
      this.calledUsers = [];
      this.apiCalled = false;
    }

    if(prevProps.channels !== channels) {
      const results = this.getUserGroups1(user);
      this.setState({userGroups: results}); 
    }

    if(prevProps.selectedAllUsers !== selectedAllUsers) {
      if(selectedAllUsers) {
        if(selectedType === 'LOCATION') {  
          if(selectedIndex === 1) {
            if(user && user.status === 'pending') {
              this.setState({ userSelected: selectedAllUsers});
            }
          }else {
            if(user && user.status === 'active') {
              this.setState({ userSelected: selectedAllUsers});
            }
          }
        }else {
          if(user && user.status === 'active') {
            this.setState({ userSelected: selectedAllUsers});
          }
        }
      }else {
        this.setState({ userSelected: selectedAllUsers});
      }
    }

    if(prevProps.processedUsers !== processedUsers) {
      if(processedUsers && processedUsers.length > 0) {
        const found = processedUsers.filter((id) => id === user.owner_id);
        if(found && found.length > 0) {
          this.setState({userSelected: false});
        }
      }
    }

    if(prevProps.actionDoneOrCancelled !== actionDoneOrCancelled) {
      if(actionDoneOrCancelled) {
        this.setState({userSelected: false});
      }
    }

    if(prevProps.user !== user) {
      const results = this.getUserGroups1(user);
      this.setState({userGroups: results}); 
      
      if(user.user && user.user.skill_ids) {
        const output = this.getUserSkills(user.user.skill_ids);
        if(this._isMounted) {
          this.setState({userSkills: output});
        }
      }else if(user.user && (user.user.skill_ids === null || user.user.skill_ids === undefined)) {
        if(user.status === 'active') {
          if(this._isMounted) {
            handleGetUserInformation(user, locationId);
            this.calledUsers.push(user.owner_id);
            this.apiCalled = true;
            //const msg = `User changed: Location ${locationId}, User ${user.owner_id} api for skill called`;
            //console.log(msg);
          }
        }
      }

      if(departments) {
        const depts2 = this.getUserDepartments();
        this.setState({userDepartments: depts2});
      }

      if(channelData && channelData.length > 0) {
        const results = this.getUserGroups1(user);
        this.setState({userGroups: results}); 
      }
    }

    if(prevProps.departments !== departments) {
      if(departments) {
        const depts = this.getUserDepartments();
        this.setState({userDepartments: depts});
      }
    }

    if(prevProps.selectedType !== selectedType) {
      if(departments) {
        const depts1 = this.getUserDepartments();
        this.setState({userDepartments: depts1});
      }
    }

    if(prevProps.filteredUsers !== filteredUsers) {
      this.setState({openMenu: false});
      if(filteredUsers.length === 0) {
        this.setState({userSelected: false});
      }
    }

    if(prevProps.selectedIndex !== selectedIndex) {
      if(user) {
        const results = this.getUserGroups1(user);
        this.setState({userGroups: results}); 
      }
    }

    if(prevProps.skills !== skills) {
      if(user.user && user.user.skill_ids) {
        const output = this.getUserSkills(user.user.skill_ids);
        if(this._isMounted) {
          this.setState({userSkills: output});
        }
      }else if(user.user && (user.user.skill_ids === null || user.user.skill_ids === undefined)) {
        if(user.status === 'active') {
          if(this._isMounted) {
            handleGetUserInformation(user, locationId);
            this.calledUsers.push(user.owner_id);
            this.apiCalled = true;
            //const msg = `User changed: Location ${locationId}, User ${user.owner_id} api for skill called`;
            //console.log(msg);
          }
        }
      }
    }

    if(prevProps.selectedType !== selectedType) {
      if(channelData && channelData.length > 0) {
        const results = this.getUserGroups1(user);
        this.setState({userGroups: results}); 
      }
    }

    let winTop = '';
    let winLeft = '';

    if(prevState.openMenu !== openMenu) {
      if(openMenu) {
        const winW2 = '200px';//`50%`;
        const winH2 = `64px`; //94.9% viewport height
        const winRight = `40px`;
        if(this.openMenuRef && this.openMenuRef.current) {
          const domNode = ReactDOM.findDOMNode(this.openMenuRef.current);
          const output2 =domNode.getBoundingClientRect();
          if(output2) {
            //winTop = `${output2.y + output2.height}px`;
            winTop = `${output2.y - output2.height - 40}px`;
          }
        }
        const winData2 = {
          top: winTop,
          right: winRight,
          width: winW2,
          height: winH2,
        }
        this.setState({dropdownPos: winData2});
      }
    }

    if(prevProps.processingUserId !== processingUserId) {
      if(processingUserId === user.id) {
        this.setState({userSelected: false});
        //console.log(`Unchecked: ${user.id}`);
      }
    }
  }

  componentWillUnmount () {

  	this._isMounted = false;
    window.removeEventListener('scroll', this.handleScrolled);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleScrolled = (event) => {
   
  }

  handleClickOutside = (event) => {
    const { openMenu, } = this.state;
    const { notifyMenuOpened, } = this.props;

    if (this.openMenuRef && this.openMenuRef.current && !this.openMenuRef.current.contains(event.target)) {
      if(this.menuWinRef && this.menuWinRef.current && !this.menuWinRef.current.contains(event.target)) {
        if(openMenu) {
          this.setState({openMenu: false});
          notifyMenuOpened(false);
        }
      }
    }
  }

  getUserDepartments = () => {
    const { user, departments, } = this.props;
    let userDepartments = [];
    if(user && user.department_ids && user.department_ids.length > 0) {
      if(departments && departments.length > 0) {
        for(let i=0; i<user.department_ids.length; i++) {
          const foundDept = departments.filter((dept) => dept.id === user.department_ids[i]);
          if(foundDept && foundDept.length > 0) {
            userDepartments.push(foundDept[0]);
          }
        }
      }
    }else {
      if(user &&  user.owner_id && departments && departments.length > 0) {
        for(let j=0; j<departments.length; j++) {
          if(departments[j].user_ids && departments[j].user_ids.length > 0) {
            const foundUser = departments[j].user_ids.filter((id) => id === user.owner_id);
            if(foundUser && foundUser.length > 0) {
              userDepartments.push(departments[j]);
            }
          }
        }
      }
    }
    return userDepartments;
  };

  getUserSkills = (skill_ids) => {
    const { skills, } = this.props;
    let userSkills = [];
    if(skill_ids && skill_ids.length > 0 && skills && skills.length > 0) {
      for(let i=0; i<skill_ids.length; i++) {
        const foundSkill = skills.filter((skill) => skill.id === skill_ids[i]);
        if(foundSkill && foundSkill.length > 0) {
          userSkills.push(foundSkill[0]);
        }
      }
    }
    return userSkills;
  }

  getUserGroups = (userId) => {
    const { channelData, } = this.props;
    let userGroups = [];
    for(let i=0; i<channelData.length; i++) {
      for(let j=0; j<channelData[i].members.length; j++) {
        if(channelData[i].members[j].id === userId) {
          userGroups.push(channelData[i]);
        }
      }
    }
    return userGroups;
  }

  getUserGroups1 = (user) => {
    const { channels, } = this.props;
    let userGroups = [];
    if(!channels || channels.length === 0) {
      return userGroups;
    }
    if(user.user && user.user.channel_ids) {
      for(let i=0; i<user.user.channel_ids.length; i++) {
        const foundChannels = channels.filter((channel) => channel.id === user.user.channel_ids[i]);
        if(foundChannels && foundChannels.length > 0) {
          userGroups.push(foundChannels[0]);
        }
      }
    }
    return userGroups;
  }

  handleSelectUser = (event) => {
    const { user, notifyUserSelected, } = this.props;
    const { userSelected, } = this.state;

    this.setState({userSelected: !userSelected})
    notifyUserSelected(user, event.target.checked);
  }

  mouseEnterPhone = (event) => {
    const { user, } = this.props;
    const { phonePos, } = this.state;
    
    let winTop = '';
    let winLeft = '';
    const winW = '160px';//`50%`;
    const winH = `30px`; //94.9% viewport height
    if(this.phoneRef && this.phoneRef.current) {
      const domNode = ReactDOM.findDOMNode(this.phoneRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height - 15}px`;
        winLeft = `${output.x - 100}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({phonePos: winData});

    if(user && user.phone_number && user.phone_number.length > 0) {
      this.setState({mouseOverPhone: true});
    }
  }

  mouseLeavePhone = (event) => {
    const { user, } = this.props;
    if(user && user.phone_number && user.phone_number.length > 0) {
      this.setState({mouseOverPhone: false});
    }
  }

  handlePhoneClicked = (event) => {
    const { user, } = this.props;
    if(user && user.phone_number && user.phone_number.length > 0) {
      const url = `tel:${user.phone_number}`;
      window.open(url);
    }
  }

  mouseEnterEmail = (event) => {
    const { user, } = this.props;
    const { emailPos, } = this.state;
    let winTop = '';
    let winLeft = '';
    const winW = '160px';//`50%`;
    const winH = `30px`; //94.9% viewport height
    
    if(this.emailRef && this.emailRef.current) {
      const domNode = ReactDOM.findDOMNode(this.emailRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y - output.height - 15}px`;
        winLeft = `${output.x - 100}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({emailPos: winData});
    
    if(user && user.email && user.email.length > 0) {
      this.setState({mouseOverEmail: true});
    }
  }

  mouseLeaveEmail = (event) => {
    const { user, } = this.props;
    if(user && user.email && user.email.length > 0) {
      this.setState({mouseOverEmail: false});
    }
  }

  handleEmailClicked = (event) => {
    const { user, } = this.props;
    if(user && user.email && user.email.length > 0) {
      const url = `mailto:${user.email}`;
      window.open(url);
    }
  }

  handleOpenMenuClick = (event) => {
    const { user,  notifyMenuOpened, rowMenuOpened, } = this.props;
    const { openMenu, } = this.state;
    //If one row menu opened, other rows cannot open
    if(rowMenuOpened) {
      if(openMenu) {
        this.setState({openMenu: !openMenu});
        notifyMenuOpened(!openMenu);
      }
      return;
    }
    if(user && user.status === 'active') {
      this.setState({openMenu: !openMenu});
      notifyMenuOpened(!openMenu);
    }
  }

  handleActionChange = (action) => {
    const { notifyActionRequested, user, } = this.props;
    const { userActionTypes } = constants;
    if(user && user.status === 'active') {
      this.setState({openMenu: false, showConfirm: true, selectedAction: action});
    }
  }

  notifyConfirm = (request) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, user, notifyUserSelected, notifyMenuOpened, } = this.props;
    const { userActionTypes } = constants;

    this.setState({showConfirm: false});
    notifyMenuOpened(false);
    if(selectedAction.name === userActionTypes.REMOVE) {
      if(request) {
        this.setState({showConfirmStep2: true});
      }else {
        notifyActionRequested(request, selectedAction.name, user);
      }
    }else {
      notifyActionRequested(request, selectedAction.name, user);
    }
  }

  notifyConfirmStep2 = (confirmed) => {
    const { selectedAction, } = this.state;
    const { notifyActionRequested, user, } = this.props;
    this.setState({showConfirmStep2: false});
    if(confirmed) {
      notifyActionRequested(true, selectedAction.name, user);
    }else {
      notifyActionRequested(false, selectedAction.name, user);
    }
  }

  mouseEnterDept = (event) => {
    const { user, } = this.props;
    const { mouseOverDept, userDepartments, departmentPos, } = this.state;
    
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.deptRef && this.deptRef.current) {
      const domNode = ReactDOM.findDOMNode(this.deptRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
        if(userDepartments && userDepartments.length > 10) {
          winTop = `${output.y - 170}px`;
        }
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({departmentPos: winData});

    if(userDepartments && userDepartments.length > 0) {
      this.setState({mouseOverDept: true});
    }
  }

  mouseEnterDept2 = (event) => {
    const { user, } = this.props;
    const { mouseOverDept, userDepartments, departmentPos, } = this.state;
    
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.deptRef2 && this.deptRef2.current) {
      const domNode = ReactDOM.findDOMNode(this.deptRef2.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
        if(userDepartments && userDepartments.length > 10) {
          winTop = `${output.y - 170}px`;
        }
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({departmentPos: winData});

    if(userDepartments && userDepartments.length > 0) {
      this.setState({mouseOverDept: true});
    }
  }

  mouseLeaveDept = (event) => {
    const { user, } = this.props;
    const { mouseOverDept, } = this.state;
    this.setState({mouseOverDept: false});
  }

  mouseEnterGroup = (event) => {
    const { user, } = this.props;
    const { mouseOverGroup, userGroups, groupPos, } = this.state;
    
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.groupRef && this.groupRef.current) {
      const domNode = ReactDOM.findDOMNode(this.groupRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
        if(userGroups && userGroups.length > 20) {
          winTop = `${output.y - 170}px`;
        }
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({groupPos: winData});

    if(userGroups && userGroups.length > 0) {
      this.setState({mouseOverGroup: true});
    }
  }

  mouseEnterGroup2 = (event) => {
    const { user, } = this.props;
    const { mouseOverGroup, userGroups, groupPos, } = this.state;
    
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.groupRef2 && this.groupRef2.current) {
      const domNode = ReactDOM.findDOMNode(this.groupRef2.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({groupPos: winData});

    if(userGroups && userGroups.length > 0) {
      this.setState({mouseOverGroup: true});
    }
  }

  mouseLeaveGroup = (event) => {
    const { user, } = this.props;
    const { mouseOverGroup, userGroups, } = this.state;
    if(userGroups && userGroups.length > 0) {
      this.setState({mouseOverGroup: false});
    }
  }

  mouseEnterSkill = (event) => {
    const { user, } = this.props;
    const { mouseOverSkill, userSkills, skillPos, } = this.state;
  
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.skillRef && this.skillRef.current) {
      const domNode = ReactDOM.findDOMNode(this.skillRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({skillPos: winData});

    if(userSkills && userSkills.length > 0) {
      this.setState({mouseOverSkill: true});
    }
  }

  mouseEnterSkill2 = (event) => {
    const { user, } = this.props;
    const { mouseOverSkill, userSkills, skillPos, } = this.state;
  
    let winTop = '';
    let winLeft = '';
    const winW = '200px';//`50%`;
    const winH = `60px`; //94.9% viewport height
    if(this.skillRef2 && this.skillRef2.current) {
      const domNode = ReactDOM.findDOMNode(this.skillRef2.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        //winTop = `${output.y + output.height}px`;
        winTop = `${output.y - output.height - 100}px`;
        winLeft = `${output.x - (200 - output.width)/2}px`;
      }
    }
    const winData = {
      top: winTop,
      left: winLeft,
      width: winW,
      height: winH,
    }
    this.setState({skillPos: winData});

    if(userSkills && userSkills.length > 0) {
      this.setState({mouseOverSkill: true});
    }
  }

  mouseLeaveSkill = (event) => {
    const { user, } = this.props;
    const { mouseOverSkill, userSkills, } = this.state;
    if(userSkills && userSkills.length > 0) {
      this.setState({mouseOverSkill: false});
    }
  }


  renderPhoneEmailMouseTip = (param) => {
    const { user, } = this.props;
    const { phonePos, emailPos, } = this.state;
    let displayText = '';
    if(param.includes('@')) {
      displayText = param;
    }else {
      let text = user && user.phone_number ? user.phone_number.toString() : '';
      let countryCode = '';
      let areaCode = '';
      let remaining = '';
      if(text.length > 0) {
        if(text.length === 11) {
          countryCode = text.substring(0, 1);
          areaCode = text.substring(1, 4);
          remaining = text.substring(4);
          displayText = `${countryCode} (${areaCode}) ${remaining}`;
        }else if(text.length === 10) {
          displayText = text;
        }else {
          displayText = param;
        }
      }
    }
    if(param.includes('@')) {
      if(emailPos) {
        const emailContainerStyle = {
          top: emailPos.top,
          left: emailPos.left,
          minWidth: emailPos.width,
          height: emailPos.height,
          position: 'absolute',
          backgroundColor: 'white',
          boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
          zIndex: 2,
          //overflow: 'scroll',
          borderRadius: '5px',
          paddingTop: '5px',
          //border: '1px solid red',
        };
        /*
        return (
          <styled.WinContainerPhoneEmail top={emailPos.top} left={emailPos.left} width={emailPos.width} height={emailPos.height}>
            <div className="mouse-tip__top-wrap">{displayText}</div>
          </styled.WinContainerPhoneEmail>
        );
        */
        return (
          <div style={emailContainerStyle}>
            <div className="mouse-tip__top-wrap">{displayText}</div>
          </div>
        );
      }
    }else {
      if(phonePos) {
        const phoneContainerStyle = {
          top: phonePos.top,
          left: phonePos.left,
          width: phonePos.width,
          height: phonePos.height,
          position: 'absolute',
          backgroundColor: 'white',
          boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
          zIndex: 2,
          //overflow: 'scroll',
          borderRadius: '5px',
          paddingTop: '5px',
          //border: '1px solid red',
        };
        /*
        return (
          <styled.WinContainerPhoneEmail top={phonePos.top} left={phonePos.left} width={phonePos.width} height={phonePos.height}>
            <div className="mouse-tip__top-wrap">{displayText}</div>
          </styled.WinContainerPhoneEmail>
        );
        */
        return (
          <div style={phoneContainerStyle}>
            <div className="mouse-tip__top-wrap">{displayText}</div>
          </div>
        );
      }
    }
  }

  renderUserActionMenu = () => {
    const { user, } = this.props;
    const { dropdownPos, } = this.state;
    const { userActionTypes } = constants;

    const promoteText = (<FormattedMessage
      id="UserRow.promote"
      defaultMessage="Grant Admin Privileges"
    />);

    const withdrawText = (<FormattedMessage
      id="UserRow.withdraw"
      defaultMessage="Withdraw Admin Privileges"
    />);

    const removeText = (<FormattedMessage
      id="UserRow.remove"
      defaultMessage="Remove User from Location"
    />);

    const promote = { name: userActionTypes.PROMOTE, body: promoteText};
    const withdraw = { name: userActionTypes.WITHDRAW, body: withdrawText};
    const remove = { name: userActionTypes.REMOVE, body: removeText};
    let items = [];
    if(user && user.is_admin) {
      items = [withdraw, remove];
    }else {
      items = [promote, remove];
    }
    
    const actionItems = items.map((item, index) => (
      <div 
        key={index} 
        className="employee-page-header__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleActionChange(item)}}
      >
        <label className="employee-page-header__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));

    if(dropdownPos) {
      return (
        <styled.WinContainerRight ref={this.menuWinRef} top={dropdownPos.top} right={dropdownPos.right} width={dropdownPos.width} height={dropdownPos.height}>
          {actionItems}
        </styled.WinContainerRight>
      );
    }
  }

  renderDepartmentMouseTip = () => {
    const { user, } = this.props;
    const { departmentPos,  userDepartments, } = this.state;

    const numDepts = userDepartments ? userDepartments.length : 0;

    const userDepartmentText = (<FormattedMessage
                id="UserRow.departments"
                defaultMessage="{value, plural, one {Department ({value})} other {Departments ({value})}}"
                values={{ value: numDepts}}
              />);
    let deptNames = '';
    if(userDepartments && userDepartments.length > 0) {
      for(let i=0; i<userDepartments.length; i++) {
        if(i !== userDepartments.length - 1) {
          deptNames += userDepartments[i].name + ', '
        }else {
          deptNames += userDepartments[i].name;
        }
      }
    }

    const deptInfo = (
      <div className="mouse-tip__wrap">
        <label className="mouse-tip__title">{userDepartmentText}</label>
        <p className="mouse-tip__content">{deptNames}</p>
      </div>
    );
    
    if(departmentPos) {
      const containerStyle = {
        top: departmentPos.top,
        left: departmentPos.left,
        minWidth: departmentPos.width,
        minHeight: departmentPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '10px',
        //border: '1px solid red',
      };
      /*
      return (
        <styled.WinContainer top={departmentPos.top} left={departmentPos.left} width={departmentPos.width} height={departmentPos.height}>
          <div className="mouse-tip__top-wrap">{deptInfo}</div>
        </styled.WinContainer>
      );
      */
      return (
        <div style={containerStyle}>
          <div className="mouse-tip__top-wrap">{deptInfo}</div>
        </div>
      );
    }
  }

  renderGroupMouseTip = () => {
    const { user, } = this.props;
    const { groupPos,  userGroups, } = this.state;

    const numGroups = userGroups ? userGroups.length : 0;

    const userGroupText = (<FormattedMessage
                id="UserRow.groups"
                defaultMessage="{value, plural, one {Group ({value})} other {Groups ({value})}}"
                values={{ value: numGroups}}
              />);
    let groupNames = '';
    if(userGroups && userGroups.length > 0) {
      for(let i=0; i<userGroups.length; i++) {
        if(i !== userGroups.length - 1) {
          groupNames += userGroups[i].channel_name + ', '
        }else {
          groupNames += userGroups[i].channel_name;
        }
      }
    }

    const groupInfo = (
      <div className="mouse-tip__wrap">
        <label className="mouse-tip__title">{userGroupText}</label>
        <p className="mouse-tip__content">{groupNames}</p>
      </div>
    );
    
    if(groupPos) {
      const containerStyle = {
        top: groupPos.top,
        left: groupPos.left,
        minWidth: groupPos.width,
        minHeight: groupPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '10px',
        //border: '1px solid red',
      };
      /*
      return (
        <styled.WinContainer top={groupPos.top} left={groupPos.left} width={groupPos.width} height={groupPos.height}>
          <div className="mouse-tip__top-wrap">{groupInfo}</div>
        </styled.WinContainer>
      );
      */
      return (
        <div style={containerStyle}>
          <div className="mouse-tip__top-wrap">{groupInfo}</div>
        </div>
      );
    }
  }

  renderSkillMouseTip = () => {
    const { user, locationSettings, } = this.props;
    const { skillPos,  userSkills, } = this.state;

    if(locationSettings && locationSettings.cfg__skill !== 1) {
      return null;
    }

    const numSkills = userSkills ? userSkills.length : 0;

    const userSkillText = (<FormattedMessage
                id="UserRow.skills"
                defaultMessage="{value, plural, one {Skill ({value})} other {Skills ({value})}}"
                values={{ value: numSkills}}
              />);
    let skillNames = '';
    if(userSkills && userSkills.length > 0) {
      for(let i=0; i<userSkills.length; i++) {
        if(i !== userSkills.length - 1) {
          skillNames += userSkills[i].skill_content + ', '
        }else {
          skillNames += userSkills[i].skill_content;
        }
      }
    }

    const skillInfo = (
      <div className="mouse-tip__wrap">
        <label className="mouse-tip__title">{userSkillText}</label>
        <p className="mouse-tip__content">{skillNames}</p>
      </div>
    );
    
    if(skillPos) {
      const containerStyle = {
        top: skillPos.top,
        left: skillPos.left,
        width: skillPos.width,
        minHeight: skillPos.height,
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 2px rgba(195,192,192,.5)',
        zIndex: 2,
        //overflow: 'scroll',
        borderRadius: '5px',
        paddingTop: '10px',
      };
      /*
      return (
        <styled.WinContainer top={skillPos.top} left={skillPos.left} width={skillPos.width} height={skillPos.height}>
          <div className="mouse-tip__top-wrap">{skillInfo}</div>
        </styled.WinContainer>
      );
      */
      return (
        <div style={containerStyle}>
          <div className="mouse-tip__top-wrap">{skillInfo}</div>
        </div>
      );

    }
  }


  render() {
    const { user, selectedDeptId, selectedType, selectedItem, selectedSkillId, selectedAllUsers, notifyUserSelected, 
            selectedUsers, locationSettings, popupOpened, } = this.props;
    const { userDepartments, userSkills, userGroups, userSelected, mouseOverPhone, mouseOverEmail, dropdownPos, 
            openMenu, showConfirm, selectedAction, showConfirmStep2, mouseOverDept, mouseOverGroup, mouseOverSkill, } = this.state;

    const numSkills = userSkills && userSkills.length ? userSkills.length : 0;
    const numDepts = userDepartments && userDepartments.length ? userDepartments.length : 0;

    const admin = (<FormattedMessage
                id="UserRow.admin"
                defaultMessage="Admin" />);
    const teamMember = (<FormattedMessage
                id="UserRow.teamMember"
                defaultMessage="Team Member" />);
    const none = (<FormattedMessage
                id="UserRow.none"
                defaultMessage="None" />);
    const userDepartmentText = (<FormattedMessage
                id="UserRow.numDepts"
                defaultMessage="{value, plural, one {{value} department} other {{value} departments}}"
                values={{ value: numDepts}}
              />);
    const userSkillsText = (<FormattedMessage
                id="UserRow.numSkills"
                defaultMessage="{value, plural, one {{value} skill} other {{value} skills}}"
                values={{ value: numSkills}}
              />);
    const userGroupsText = (<FormattedMessage
                id="UserRow.numGroups"
                defaultMessage="{value, plural, one {{value} group} other {{value} groups}}"
                values={{ value: userGroups.length}}
              />);

    let userDept = userDepartments && userDepartments.length > 0 ? userDepartments[0].name : none;
    if(userDepartments && userDepartments.length > 0) {
      if(userDepartments.length > 1 && selectedDeptId >= 0) {
        for(let i=0; i<userDepartments.length; i++) {
          if(userDepartments[i].id === selectedDeptId) {
            userDept = userDepartments[i].name;
            break;
          }
        }
      }
    }else {
      userDept = none;
    }
    /*
    if(selectedType === 'LOCATION') {
      userDept = userDepartments && userDepartments.length > 0  ? userDepartmentText: none;
    }
    */
    if(selectedType !== 'DEPARTMENTS') {
      userDept = userDepartments && userDepartments.length > 0  ? userDepartmentText: none;
    }

    let userGrps = userGroups && userGroups.length > 0 ? userGroupsText : none;
    if(selectedType === 'GROUPS') {
      userGrps = selectedItem ? selectedItem.name : none;
    }


    //let userSkls = user && user.skill_ids && user.skill_ids.length > 0 ? userSkillsText : none;
    let userSkls = userSkills && userSkills.length > 0 ? userSkillsText : none;
    if(selectedType === 'SKILLS') {
      if(user && user.status === 'active') {
        userSkls = selectedItem ? selectedItem.name : none;
      }
    }

    if(user && user.status !== 'active' && user.status !== 'pending') {
      userDept = none;
      userGrps = none;
      userSkls = none;
    }

    if(locationSettings && locationSettings.cfg__departments !== 1) {
      userDept = none;
    }

    if(locationSettings && locationSettings.cfg__skill !== 1) {
      userSkls = none;
    }

    //const groupMemberCount = selectedItem && selectedItem.value ? `+${selectedItem.value}`: '';
    //const skillMemberCount = selectedItem && selectedItem.value ? `+${selectedItem.value}` : '';
    const userDeptCount = userDepartments && userDepartments.length > 1 ? `+${userDepartments.length - 1}` : '';
    const userGroupCount = userGroups && userGroups.length > 1 ? `+${userGroups.length - 1}` : '';
    const userSkillCount = userSkills && userSkills.length > 1 ? `+${userSkills.length - 1}` : '';
    const userRole = user && user.is_admin ? admin : teamMember;
    //const departmentsClassName = userDepartments && userDepartments.length > 0 ? 'user-row__departments' : 'user-row__departments-none';
    const departmentsClassName = userDept === none ? 'user-row__departments-none' : 'user-row__departments';
    //const groupsClassName = userGroups && userGroups.length > 0 ? 'user-row__skills' : 'user-row__skills-none';
    const groupsClassName = userGrps === none ? 'user-row__skills-none' : 'user-row__skills';
    const skillsClassName = userSkls === none ? 'user-row__skills-none' : 'user-row__skills';
    const phoneButtonClassName = user && user.phone_number ? 'user-row__contact-button-phone' : 'user-row__contact-button-phone-disabled';
    const emailButtonClassName = user && user.email ? 'user-row__contact-button-email' : 'user-row__contact-button-email-disabled';
    const notApplicable = '--'

    let userRowClassName = '';
    //const userRowClassName = selectedUsers && selectedUsers.length > 0  && !userSelected ? 'user-row__row-blurred' : 'user-row__row';
    if(selectedUsers && selectedUsers.length > 0) {
      if(selectedUsers[0].status === user.status) {
        userRowClassName = "user-row__row";
      }else {
        userRowClassName = "user-row__row-blurred";
      }
    }else {
      userRowClassName = "user-row__row";
    }
  	return (
  		<tr className={userRowClassName}>
        <td className="user-row__checkbox">
          {(user && user.status === 'active' || user.status === 'pending') &&
          <input 
            type="checkbox" 
            value={user ? user.owner_id : ''}
            checked={userSelected}  
            onClick={this.handleSelectUser}
          />}
        </td>
        <td className="user-row__owner-cell">
          <UserCell 
            user={user}
          />
        </td>
        <td className="user-row__user-role">
        {user && user.status !== 'pending' ? userRole : notApplicable}
        </td>
        <td className="user-row__status-cell">
          <StatusCell 
            user={user}
          />
        </td>
        <td >
          {user && user.status !== 'pending' ? <div className="user-row__departments-wrap">
          <label 
            ref={this.deptRef}
            className={departmentsClassName}
            onMouseEnter={this.mouseEnterDept}
            onMouseLeave={this.mouseLeaveDept}
          >{userDept}</label>
          {selectedType === 'DEPARTMENTS' && <label 
            ref={this.deptRef2}
            className="user-row__group-member-count"
            onMouseEnter={this.mouseEnterDept2}
            onMouseLeave={this.mouseLeaveDept}
          >{userDeptCount}</label>}
          </div> : <div className="user-row__departments-wrap"><label className="user-row__departments-none">{notApplicable}</label></div>}
        </td>
        <td>
          {user && user.status !== 'pending' ? <div className="user-row__departments-wrap">
          <label 
            ref={this.groupRef}
            className={groupsClassName}
            onMouseEnter={this.mouseEnterGroup}
            onMouseLeave={this.mouseLeaveGroup}
          >{userGrps}</label>
          {selectedType === 'GROUPS' && <label 
            ref={this.groupRef2}
            className="user-row__group-member-count"
            onMouseEnter={this.mouseEnterGroup2}
            onMouseLeave={this.mouseLeaveGroup}
          >{userGroupCount}</label>}
          </div> : <div className="user-row__departments-wrap"><label className="user-row__skills-none">{notApplicable}</label></div>}
        </td>
        <td>
          {user && user.status !== 'pending' ? <div className="user-row__departments-wrap">
            <label
              ref={this.skillRef} 
              className={skillsClassName}
              onMouseEnter={this.mouseEnterSkill}
              onMouseLeave={this.mouseLeaveSkill}
            >{userSkls}</label>
            {(selectedType === 'SKILLS' && user && user.status === 'active') && <label 
              ref={this.skillRef2} 
              className="user-row__skill-member-count"
              onMouseEnter={this.mouseEnterSkill2}
              onMouseLeave={this.mouseLeaveSkill}
            >{userSkillCount}</label>}
          </div> : <div className="user-row__departments-wrap"><label className="user-row__skills-none">{notApplicable}</label></div>}
        </td>
        <td>
          <div className="user-row__button-wrap">
            <button 
              ref={this.phoneRef}
              className={phoneButtonClassName} 
              onMouseEnter={this.mouseEnterPhone}
              onMouseLeave={this.mouseLeavePhone}
              onClick={this.handlePhoneClicked}
            />
            <button 
              ref={this.emailRef}
              className={emailButtonClassName}
              onMouseEnter={this.mouseEnterEmail}
              onMouseLeave={this.mouseLeaveEmail}
              onClick={this.handleEmailClicked}
            />
          </div>
        </td>
        <td>
          {user && user.status === 'active' && <button className="user-row__button-more"  ref={this.openMenuRef} onClick={this.handleOpenMenuClick}/>}
        </td>
        {mouseOverPhone && <Modal>
          {this.renderPhoneEmailMouseTip(user.phone_number)}
        </Modal>}
        {mouseOverEmail && <Modal>
          {this.renderPhoneEmailMouseTip(user.email)}
        </Modal>}
        {openMenu && <Modal>
          {this.renderUserActionMenu()}
        </Modal>}
        <styled.OverlayTransparent display={openMenu ? 'block' : 'none'} />
        {mouseOverDept && <Modal>
          {this.renderDepartmentMouseTip()}
        </Modal>}
        {mouseOverGroup && <Modal>
          {this.renderGroupMouseTip()}
        </Modal>}
        {mouseOverSkill && <Modal>
          {this.renderSkillMouseTip()}
        </Modal>}
        {showConfirm && <Modal>
          <Confirm
            selectedAction={selectedAction}
            notifyConfirm={this.notifyConfirm}
          />
        </Modal>}
        <styled.Overlay display={showConfirm ? 'block' : 'none'} />
        {showConfirmStep2 && <Modal>
          <ConfirmStep2
            selectedAction={selectedAction}
            notifyConfirmStep2={this.notifyConfirmStep2}
          />
        </Modal>}
        <styled.OverlayTransparent display={popupOpened ? 'block' : 'none'} />
      </tr>
  	);
  }

}

UserRow.propTypes = {
  notifyUserSelected: PropTypes.func.isRequired,
};

export default injectIntl(UserRow);

