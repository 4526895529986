import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Button } from '@shyft/acorns';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';

import successStatus from '../assets/success.svg';
import exclamationMark from '../assets/exclamation-mark.svg';

const dangerColor = '#FF6C6C';

const Row = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid #dedede;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #dedede;
  margin-left: 12px;
  margin-right: 12px;
  @media (max-width: 680px) {
    display: block;
  }

`;

const Column = styled.div`
  max-width: 320px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
    //width: 10%;
  }

  &:not(:first-child) {
    //width: 30%;
  }

  @media (max-width: 680px) {
    margin-right: 0px;
    margin-bottom: 0.75rem;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ErrorStatusIcon = styled.img.attrs({
  src: '/icons/exclamation-point.png',
})`
  height: 15px;
`;

const ErrorStatusContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  background-color: ${dangerColor};
  border-radius: 50%;
  margin-right: 1rem;
`;

const RetryBtn = styled(Button).attrs(({ modifiers, onClick }) => ({
  onClick,
  modifiers: ['small'].concat(modifiers),
}))`
  color: ${dangerColor};
  border-color: ${dangerColor};

  &:hover {
    background-color: ${dangerColor};
    color: white;
  }
`;

const SuccessStatusIcon = styled.img.attrs(({ img }) => ({
  src: img,
}))`
  margin-right: 1rem;
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

const StatusLoadingSpinner = styled(LoadingSpinner).attrs(
  ({ size, color }) => ({
    size,
    color,
  })
)`
  margin-right: 1rem;
`;

const DateCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  margin-left: 20px;
`;

const ReasonCell = styled.p.attrs((props) => ({
  'aria-label': props.ariaLabel,
  title: props.title,
}))`
  //max-width: 260px;
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  margin-left: 20px;
`;

const PostCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  margin-left: 20px;
`;

const SkillCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  margin-left: 20px;
`;
const MarkCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  margin-left: 10px;
`;

const messages = defineMessages({
  open_shift: {
    id: 'PostingRow.openShift',
    defaultMessage: 'Open Shift',
  },
  shift_vto: {
    id: 'PostingRow.vto',
    defaultMessage: 'VTO',
  },
});

let savedSkillNames = [];
let savedShiftContent = [];

const PostingStatus = ({ posting, error, success, retry }) => {
  if (posting) {
    return (
      <StatusContainer>
        <StatusLoadingSpinner size="small" color="blue" />

        <FormattedMessage id="PostingStatus.posting" defaultMessage="Posting" />
      </StatusContainer>
    );
  }

  if (error && error > 0) {
    return (
      <StatusContainer>
        <ErrorStatusContainer>
          <ErrorStatusIcon img={exclamationMark} />
        </ErrorStatusContainer>

        <RetryBtn onClick={retry}>
          <FormattedMessage id="PostingStatus.retry" defaultMessage="Retry" />
        </RetryBtn>
      </StatusContainer>
    );
  }

  if (success) {
    return (
      <StatusContainer>
        <SuccessStatusIcon img={successStatus} />
        <FormattedMessage id="PostingStatus.success" defaultMessage="Success" />
      </StatusContainer>
    );
  }

  return null;
};

const PostingRowDescription = ({
  intl,
  type,
  totalShifts,
  posting,
  error,
  success,
  currentChannel,
}) => {
  if (posting || success) {
    return (
      <FormattedMessage
        id="PostingRow.description"
        defaultMessage="Posting a notice with {numberOfShifts} {type} offers to team {teamName}"
        values={{
          type: intl.formatMessage(messages[type]),
          numberOfShifts: <strong>{totalShifts}</strong>,
          teamName: currentChannel,
        }}
      />
    );
  }

  if (error && error > 0) {
    return (
      <FormattedMessage
        id="PostingRow.errorDescription"
        defaultMessage="{numberSuccessfullyPosted} {type} notifications successfully posted. {lineBreak} {numberFailedToPost} {type} notifications failed to post. {lineBreak}Do you want to retry the failed ones?"
        values={{
          lineBreak: <br />,
          type: intl.formatMessage(messages[type]),
          numberFailedToPost: <strong>{error}</strong>,
          numberSuccessfullyPosted: <strong>{totalShifts - error}</strong>,
        }}
      />
    );
  }

  return null;
};

const createSkillInfo = (shift, skills ) => {
  let skillInfo = '';
  let skillNames = '';
  if(shift && shift.skill_ids && shift.skill_ids.length > 0 && skills && skills.length > 0) {
    for(let i=0; i<shift.skill_ids.length; i++) {
      for(let j=0; j<skills.length; j++) {
        if(shift.skill_ids[i] === skills[j].id) {
          skillNames += `${skills[j].skill_content}, `
        }
      }
    }
  }
  if(skillNames.length > 2) {
    skillNames = skillNames.substring(0, skillNames.length -2); //remove last ','
  }

  if(shift.skill_ids && shift.skill_ids.length > 0) {
      skillInfo = (<FormattedMessage
          id="PostingRow.skills"
          defaultMessage="Skills: {skills}"
          values={{ skills: skillNames }}
        />);
    }else {
      skillInfo = (<FormattedMessage
          id="PostingRow.noSkills1"
          defaultMessage="Skills: None Added"
        />);
    }
  
  return skillInfo;
};

const PostingRow = ({ intl, index, type, shift, currentChannel, retry, skills, skillInfo, locationSettings, }) => {
  if(shift.posting.posting) {
    savedSkillNames[index] = createSkillInfo(shift, skills);
    savedShiftContent[index] = shift.reason;
  }
  

  return (
    <TableRow>
      <td>
      <DateCell>
        <FormattedMessage
          id="PostingRow.dateRange"
          defaultMessage="{startDate} to {endDate}"
          values={{
            startDate: moment(shift.start_at).format('MM/DD/YYYY hh:mm a'),
            endDate: moment(shift.end_at).format('hh:mm a'),
          }}
        />
      </DateCell>
      </td>
      <td>
      <ReasonCell>
        <span>{shift.reason ? shift.reason : savedShiftContent[index]}</span>
      </ReasonCell>
      </td>
      <td>
      <PostCell>
        <PostingRowDescription
          intl={intl}
          type={type}
          totalShifts={shift.number_of_shifts}
          posting={shift.posting.posting}
          error={shift.posting.error}
          success={shift.posting.success}
          currentChannel={currentChannel}
        />
      </PostCell>
      </td>
      {locationSettings.cfg__skill === 1 && 
        <td>
          <SkillCell>
            {/*skillNames ? skillNames : savedInfo[index].skillNames*/}
            {savedSkillNames[index]}
            
          </SkillCell>
        </td>}
      <td>
        <MarkCell>
          <PostingStatus
            posting={shift.posting.posting}
            error={shift.posting.error}
            success={shift.posting.success}
            retry={() => retry(index)}
          />
        </MarkCell>
      </td>
    </TableRow>
  );
};

export default injectIntl(PostingRow);
