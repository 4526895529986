import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import { DataNames } from '../constants';
import './RadioButton.scss';

const RadioButton = (props) => {
  const { checked, label, id, children, onChange, members, } = props;
  const circleClass = classNames({
    'radio-button__btn__circle': true,
    'radio-button__btn__circle--active': checked,
  });
  const checkClass = classNames({
    'radio-button__btn__check': true,
    'radio-button__btn__check--active': checked,
  });
  const reachData = {
    reachValue: label,
    skill_id: id,
    members: members,
  };

  const numMembers = (<FormattedMessage
                    id="RadioButton.members"
                    defaultMessage="+{number, plural, one {{number} member} other {{number} members}}."
                    values={{number: members.length,}}/>);
  

  return (
    <div>
      <div
        className="radio-button"
        onClick={() => {
          onChange(DataNames.REACH, reachData);
        }}
        onKeyPress={() => {}}
        value={label}
        role="radio"
        aria-checked={checked}
        tabIndex={0}
      >
        <div className="radio-button__btn">
          <div className={circleClass} />
          <div className={checkClass} />
        </div>    
          <div className="radio-button__label">{children || label}</div>
          {/*<div className="radio-button__label">{children || 'this is test text long long long looooooong'}</div>*/}
      </div>
      <label className="radio-button__label1">{numMembers}</label>
    </div>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  label: '',
};

export default RadioButton;
