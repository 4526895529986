import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

//import {useCallback} from 'react';
//import {useDropzone} from 'react-dropzone';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
//import ProgressBar from './ProgressBar';
import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import Confirmation from './Confirmation';
import './UploadComponent.scss';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class UploadComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      filesToUpload: [],
      goNext: false,
      statusInfo: '',
      wrongFileType: false,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const {
      locationId,
      getLocationEmployeesNew,
    } = this.props;

    getLocationEmployeesNew(locationId);

  }

  componentDidUpdate(prevProps, prevState) {
    const {
      locationId,
    } = this.props;
    
  }

  handleFileChange (event) {
    event.preventDefault();
    const MAX_NUM_FILES = 3;

    const maxFilesExceeded = (<FormattedMessage
      id="UploadComponent.maxFilesExceeded"
      defaultMessage="Only {maxFiles} are allowed to upload at one time. Please select less files."
      values={{maxFiles: MAX_NUM_FILES}}
    />);
    
    if(event.target.files.length === 0){
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }
    if(event.target.files[0].type !== 'text/csv' && event.target.files[0].type !== 'xlsx') {
      const fileType = event.target.files[0].type ? event.target.files[0].type : '';
      const wrongType = (<FormattedMessage
        id="UploadComponent.wrongType"
        defaultMessage="File type {type} is not accepted. Only 'csv/xlsx' can be uploaded. "
        values={{type: fileType}}
      />);
      this.setState({statusInfo: wrongType, wrongFileType: true});
      this.setState({filesToUpload: []});
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }
    if(event.target.files.length > MAX_NUM_FILES){
      alert(maxFilesExceeded);
      //to ensure file input onChange is trigged if the same file is selected
      this.fileInput.current.value = '';
      return;
    }

    let localCopy = [...this.state.filesToUpload];
    localCopy.push(event.target.files[0]);
    this.setState({filesToUpload: localCopy});
    //to ensure file input onChange is trigged if the same file is selected
    this.fileInput.current.value = '';

  }

  handleButtonClick (event) {
    this.fileInput.current.click();
    this.setState({filesToUpload: []});
    this.setState({statusInfo: ''});
  }

  handleSubmit(event) {
    const { filesToUpload, } = this.state;

    this.setState({goNext: true});
    event.preventDefault();
  }

  closeModalWindow = () =>{
    this.setState({goNext: false});
  }

  cancelUpload = () => {
    const { filesToUpload, } = this.state;
    const { handleFileUploaded } = this.props;

    this.setState({filesToUpload: []});
    this.setState({statusInfo: ''});
    handleFileUploaded(false);
    this.closeModalWindow();
  }

  confirmUpload = () => {
    const { filesToUpload, } = this.state;
    const { locationId, handleFileUploaded, } = this.props;

    const uploadOK = (
      <FormattedMessage
        id="UploadComponent.uploadOK"
        defaultMessage="Uploading file {name} succeeded."
        values={{name: filesToUpload[0].name}}
      />
    );
    const uploadfailed = (
      <FormattedMessage
        id="UploadComponent.uploadFailed"
        defaultMessage="Uploading file {name} failed."
        values={{name: filesToUpload[0].name}}
      />
    );

    UploadSchedulerFile(filesToUpload[0], locationId).then((data) =>{
      if(data.ok && data.status === 201) {
        this.setState({statusInfo: uploadOK});
        handleFileUploaded(true, uploadOK);
      }else {
        this.setState({statusInfo: uploadfailed});
        handleFileUploaded(false, uploadfailed);
      }
    }).catch((error) =>{
      this.setState({statusInfo: uploadfailed});
      handleFileUploaded(false, uploadfailed);
    });
    this.setState({filesToUpload: []});
    this.closeModalWindow();
  }

  handleDrop = (event) => {
    console.log('dropping');
  }
  handleDropEnd = (event) => {
    console.log('drop end');
  }


  render() {
    const { filesToUpload, goNext, statusInfo, wrongFileType, } = this.state;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;
    const uploadScheduler = (<FormattedMessage
      id="UploadComponent.uploadScheduler"
      defaultMessage="Step 1: Upload Scheduler" 
    />);
    const buttonUpload = (<FormattedMessage
      id="UploadComponent.addFile"
      defaultMessage="Upload"
    />);

    const buttonPublish = (<FormattedMessage
      id="UploadComponent.submit"
      defaultMessage="Publish"
    />);

    const dragDrop = (<FormattedMessage
      id="UploadComponent.dragDrop"
      defaultMessage="Drap & Drop File"
    />);

    const template = (<FormattedMessage
      id="UploadComponent.template"
      defaultMessage="Download, complete, and upload the Shyft Scheduler Import template"
    />);

    const supportedFileType = (<FormattedMessage
      id="UploadComponent.supportedFileType"
      defaultMessage="Supported file types: csv, xlsx"
    />);

    const importTemplate = (<FormattedMessage
      id="UploadComponent.importTemplate"
      defaultMessage="Shyft Scheduler Import Template.xlsx"
    />);

    const publishSchedule = (<FormattedMessage
      id="UploadComponent.publishSchedule"
      defaultMessage="Step 2: Publish Schedule"
    />);

    const enabledButtonClassName = "upload__button-submit";
    const disabledButtonClassName = "upload__button-submit-disabled";
    
    return (
      <div className="upload__wrap">
        <div className="upload__header-wrap" onDrop={this.handleDrop} onDropEnd={this.handleDropEnd}>
          <p className="upload__step-1">{uploadScheduler}</p>
        </div>
        <div className="upload__main-body">
          <img className="upload__drag-drop-image" src="/icons/add-file-icon.svg"/>
          <label className="upload__drag-drop">{dragDrop}</label>
          <label className="upload__download">{template}</label>
          <label className="upload__supported">{supportedFileType}</label>
          {wrongFileType && <label className="upload__wrong-file-type">{statusInfo}</label>}
          <div>
            <input className="upload__file-input"
              type="file"
              id="file-choose"
              multiple={false}
              ref={this.fileInput}
              onChange={this.handleFileChange}
            />
            <button className="upload__button-input" onClick={this.handleButtonClick}>
              {buttonUpload}
            </button>
          </div>
        </div>
        <div className="upload__file-link-wrap">
          <img className="upload__file-link-image" src="/icons/excel-icon.png" />
          <a className="upload__file-link-text" href="https://miniobackingstore.blob.core.windows.net/public-files/import_schedule_template_min.csv" >{importTemplate}</a>
        </div>
        <p className="upload__step-2">{publishSchedule}</p>
        {filesToUpload.length === 1 ? <button className={enabledButtonClassName} onClick={this.handleSubmit}>{buttonPublish}</button>:
          <button className={disabledButtonClassName} onClick={this.handleSubmit}>{buttonPublish}</button>}
        {goNext && <Modal>
          <Confirmation
           closeWin={this.closeModalWindow}
           cancelUpload={this.cancelUpload}
           confirmUpload={this.confirmUpload}
          />
        </Modal>}
      </div>
    );
  }
}

UploadComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default UploadComponent;
