/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Prompt, Redirect } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  PostBulkShifts,
  CheckBulkShiftStatus,
  postShiftGroup,
} from 'helpers/api-calls';

import PageWrap from 'components/GeneralComponents/PageWrap';
import RadioButtonGroup from './components/RadioButtonGroup';

import * as styled from './styled';
import * as blocks from './blocks';
import * as formatting from './formatting';

import { shiftTypes } from './duck/constants';

import PostShiftHeader from './components/PostShiftHeader';
import ShiftRow from './components/ShiftRow';
import AddShiftRow from './components/AddShiftRow';
import ConfirmationRow from './components/PreviewRow';
import ActionFooter from './components/ActionFooter';

import messages from './messages';

const shiftLimit = 5000;
const rowLimit = 34;
const shiftsPerRow = 500;

function Shift({
  reason = null,
  start_at = null,
  end_at = null,
  number_of_shifts = null,
  channel = null,
  jobTitle = null,
  cost_work_center = null,
  skill_ids = null,
}) {
  this.datePickerOpen = false;
  this.reason = reason;
  this.start_at = start_at;
  this.end_at = end_at;
  this.number_of_shifts = number_of_shifts;
  this.channel = channel;
  this.jobTitle = jobTitle;
  this.cost_work_center = cost_work_center;
  this.skill_ids = skill_ids;
  this.posting = {
    id: null,
    posting: false,
    success: null,
    error: null,
  };
}

class PostShiftComponent extends React.Component {
  constructor(props) {
    super(props);

    this.apiCalled = false;
    this._isMounted = false;
  }

  state = {
    type: shiftTypes.open,
    rows: [new Shift({})],
    previewing: false,
    posting: false,
    posted: false,
    batchCheckingInterval: null,
    retryBatchCheckingIntervals: {},
    rowLimitReached: false,
    xpoCostCenters: null,
    skills: null,
    selectedSkillIds: [],
    fullSkilledPersons: [],
  };


  componentDidMount() {
    const { channels, channelsLoading, locationId, costCenterData, getCostCenterData, 
      skills, getLocationUsableSkills, locationSettings, userPrivileges, fetchChannelMembers, actionMenuEnabled, } = this.props;
    const { rows } = this.state;

    this._isMounted = true;
    //If location changed and current location cfg__action_menu is disabled, do not open this page
    if(actionMenuEnabled !== 1) {
      const backToPath = '/calendar';
      window.location.pathname = backToPath;
    }

    if(actionMenuEnabled !== 1) {
      const backToPath = '/calendar';
      window.location.pathname = backToPath;
    }

    if (!channelsLoading && channels !== undefined && channels[0]) {
      this.setState({
        rows: [
          {
            ...rows[0],
            channel: channels[0].value,
          },
        ],
      });
    }



    if(costCenterData) {
      const idx = costCenterData.tag_groups.findIndex(
        (group) => group.group_type === 'xpo_cost_work_centers'
      );
      if(idx >= 0) {
        this.setState({xpoCostCenters: costCenterData.tag_groups[idx]});
      }
    }else {
      if(locationId){
        if(!this.apiCalled){
          const msg = `location = ${locationId},PostShiftComponent calling getCostCenterData`;
          console.log(msg);
          getCostCenterData(locationId);
          this.apiCalled = true;
        }
      }
    }

    if(locationId) {
      getLocationUsableSkills(locationId);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { channels, channelsLoading, costCenterData, skills, actionMenuEnabled, locationId, } = this.props;
    const { rows, type, } = this.state;

    if (prevProps.channelsLoading !== channelsLoading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        rows: [
          {
            ...rows[0],
            //channel: channels[0].value,
            channel: channels !== undefined && channels[0] ? channels[0].value : null,
          },
        ],
      });
    }

    if(prevProps.costCenterData !== costCenterData) {
      if(costCenterData) {
        const idx = costCenterData.tag_groups.findIndex(
          (group) => group.group_type === 'xpo_cost_work_centers'
        );
        if(idx >= 0) {
          this.setState({xpoCostCenters: costCenterData.tag_groups[idx]});
        }
      }
    }

    if(prevProps.skills !== skills) {
      if(this._isMounted) {
        this.setState({skills: skills});
      }
    }

    if(prevState.type !== type) {
      if(this._isMounted) {
        this.setState({selectedSkillIds: []});
        if (!channelsLoading && channels !== undefined && channels[0]) {
          this.setState({
            rows: [
              {...rows[0],
                channel: channels[0].value,},
                ],
              });
        }
      }
    }

  }

  componentWillUnmount () {
    const { actionMenuEnabled, } = this.props;

    this._isMounted = false;
  }

  shiftTypeOptions = () => {
    const { intl, vtoEnabled, postOpenShiftEnabled } = this.props;
    const options = [];

    if (postOpenShiftEnabled !== 0) {
      options.push({
        label: intl.formatMessage(messages.openShift),
        value: shiftTypes.open,
        description: intl.formatMessage(messages.openShiftDescription),
      });
    }

    if (vtoEnabled) {
      options.push({
        label: intl.formatMessage(messages.vto),
        value: shiftTypes.vto,
        description: intl.formatMessage(messages.vtoDescription),
      });
    }

    return options;
  };

  shiftTypeChange = (e) => {
    const { rows } = this.state;
    const { intl, channels } = this.props;
    const rowsWithShifts = rows.filter(
      (element) => element.number_of_shifts && element.number_of_shifts > 0
    );

    // the order here MATTERS A LOT
    if (
      (rows.length > 1 || rowsWithShifts.length > 0) &&
      window.confirm(intl.formatMessage(messages.changeTypeConfirmation))
    ) {
      this.setState({
        type: e.target.value,
        rows: [
          new Shift({
            //channel: channels[0].value,
            channel: channels !== undefined && channels[0] ? channels[0].value : null,
          }),
        ],
      });
    }

    if (rows.length === 1 && rowsWithShifts.length === 0) {
      this.setState({
        type: e.target.value,
        rows: [
          new Shift({
            //channel: channels[0].value,
            channel: channels !== undefined && channels[0] ? channels[0].value : null,
          }),
        ],
      });
    }
  };

  _shiftIsValid = (shift) => {
    const {xpoCostCenters, type} = this.state;

    if (!shift.start_at || !shift.end_at) return false;
    if (shift.number_of_shifts < 1 || shift.number_of_shifts > shiftsPerRow)
      return false;
    if(!shift.jobTitle){
      return false;
    }
    if(type === 'open_shift'){
      if(xpoCostCenters){
        if(!shift.cost_work_center){
          return false;
        }
      }
    }
    
    return true;
  };

  _checkStatusOfPostingShifts = () => {
    const { rows, batchCheckingInterval } = this.state;

    const rowsToCheck = rows.filter((element) => element.posting.posting);
    const ids = rowsToCheck.map((element) => element.posting.id);

    if (ids.length) {
      CheckBulkShiftStatus(ids).then((map) => {
        // update shift status
        // return hashmap with ids
        const updatedRows = rows.map((row) => {
          const mappedResult = map[row.posting.id];
          if (mappedResult) {
            const { success_count, failed_count, total_count } = mappedResult;
            row.posting = {
              id: row.posting.id,
              posting: !(success_count + failed_count === total_count),
              success: success_count === total_count && success_count !== null,
              error: failed_count,
            };
          }

          return row;
        });

        this.setState({
          rows: updatedRows,
        });
      });
    } else {
      clearInterval(batchCheckingInterval);
      this.setState({
        posted: true,
        posting: false,
        batchCheckingInterval: null,
      });
    }
  };

  _checkStatusOfRetriedShifts = (index) => {
    const { rows, retryBatchCheckingIntervals } = this.state;
    const shift = rows[index];

    const uid = shift.posting.id;

    CheckBulkShiftStatus([uid]).then((map) => {
      const status = map[uid];

      const { success_count, failed_count, total_count } = status;

      const posting = {
        id: uid,
        posting: !(success_count + failed_count === total_count),
        success: shift.posting.error - failed_count === 0,
        error: failed_count,
      };

      shift.posting = posting;
      rows[index] = shift;

      if (!shift.posting.posting) {
        clearInterval(retryBatchCheckingIntervals[uid]);
        this.setState({
          retryBatchCheckingIntervals: Object.assign(
            retryBatchCheckingIntervals,
            {
              [uid]: null,
            }
          ),
        });
      }

      this.setState({
        rows,
      });
    });
  };

  _setStatusInterval = () => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      batchCheckingInterval: setInterval(
        this._checkStatusOfPostingShifts,
        3000
      ),
    });
  };

  _setDynamicInterval = (id, index) => {
    const { retryBatchCheckingIntervals } = this.state;

    const functionCall = this._checkStatusOfRetriedShifts.call(this, index);

    this.setState({
      retryBatchCheckingIntervals: Object.assign(retryBatchCheckingIntervals, {
        [id]: setInterval(functionCall, 3000),
      }),
    });
  };

  _setRowsToLoading = () => {
    const { rows } = this.state;

    const posting = {
      id: null,
      posting: true,
      success: null,
      error: null,
    };

    rows.map((shift) => {
      Object.assign(shift, {
        posting,
      });

      return shift;
    });

    this.setState({
      posting: true,
      posted: false,
      rows,
    });
  };

  _setRowsToFinished = () => {
    const { rows } = this.state;

    const posting = {
      id: null,
      success: true,
      posting: false,
      error: null,
    };

    rows.forEach((shift) => {
      Object.assign(shift, {
        posting,
      });
    });

    this.setState({
      posting: false,
      posted: true,
      rows,
    });
  };

  _postShifts = () => {
    const { rows, type, xpoCostCenters } = this.state;
    const shiftsForApi = formatting.shiftsForApi(rows, type, xpoCostCenters);
    this._setRowsToLoading();

    PostBulkShifts(shiftsForApi).then((response) => {
      const shifts = formatting.shiftsForState(response.data.schedule_elements);
      this.setState(
        {
          rows: shifts,
        },
        this._setRowsToFinished
      );
    }).catch((error) => {
      const msg = `PostBulkShifts failed. error = ${error}`;
      console.log(msg);
    });
  };

  _retry = (index) => {
    const { rows, type, xpoCostCenters} = this.state;

    const shift = rows[index];
    const shiftForApi = formatting.shiftsForApi([rows[index]], type, xpoCostCenters)[0];

    // setting shift to loading state
    shift.posting.posting = true;

    rows[index] = shift;

    this.setState({
      rows,
    });

    postShiftGroup(shiftForApi).then((data) => {
      this._setDynamicInterval(data.id, index);

      // set shift posting id
      shift.posting.id = data.id;

      rows[index] = shift;

      this.setState({
        rows,
      });
    });
  };

  _addRow = () => {
    // get previous shift and add one day
    const { rows, selectedSkillIds, } = this.state;
    const { channels } = this.props;
    const lastShift = rows[rows.length - 1];

    if(rows.length > rowLimit){
      this.setState({rowLimitReached: true,});
      return;
    }

    let selectedChannel = '';
    if(lastShift.channel) {
      selectedChannel = lastShift.channel;
    }else {
      if(channles && channels[0]) {
        selectedChannel = channels[0].value;
      }
    }

    /*
    rows.push(
      new Shift({
        start_at: lastShift.start_at
          ? moment(lastShift.start_at).add(1, 'day')
          : null,
        end_at: lastShift.end_at
          ? moment(lastShift.end_at).add(1, 'day')
          : null,
        //channel: channels !== undefined && channels[0] ? channels[0].value : null,
        channel: selectedChannel,
        reason: lastShift.reason,
        number_of_shifts: lastShift.number_of_shifts,
        jobTitle: lastShift.jobTitle,
        cost_work_center: lastShift.cost_work_center,
        skill_ids: lastShift.skill_ids,
      })
    );

    this.setState({
      rows,
    });

    let obj = {index: 0, ids: [],};
    obj.index = rows.length -1;
    obj.ids = lastShift.skill_ids;
    selectedSkillIds.push(obj);
    this.setState({selectedSkillIds,});
    */

    //Bug WS-1630: Adding another row: should create a new/empty entry
    rows.push(
      new Shift({
        start_at: null,
        end_at: null,
        //channel: channels !== undefined && channels[0] ? channels[0].value : null,
        channel: selectedChannel,
        reason: '',
        number_of_shifts: null,
        jobTitle: '',
        cost_work_center: null,
        skill_ids: [],
      })
    );

    this.setState({
      rows,
    });

    let obj = {index: 0, ids: [],};
    obj.index = rows.length -1;
    obj.ids = [];
    selectedSkillIds.push(obj);
    this.setState({selectedSkillIds,});

  };

  _updateStartingDate = (value, index) => {
    const { rows } = this.state;
    const shift = rows[index];
    const difference =
      shift.start_at && value ? value.diff(shift.start_at, 'days') : null;

    if (value) {
      shift.start_at = moment(shift.start_at ? shift.start_at : moment())
        .year(value.year())
        .month(value.month())
        .date(value.date());
      shift.end_at =
        difference && shift.end_at
          ? moment(shift.end_at.add(difference, 'days'))
          : null;
    } else {
      shift.start_at = null;
    }

    rows[index] = shift;

    this.setState({
      rows,
    });
  };

  _updateShiftTimes = (value, index) => {
    const { rows } = this.state;
    const shift = rows[index];

    if (value[0] && value[1]) {
      shift.start_at = moment(shift.start_at)
        .hour(value[0].hour())
        .minute(value[0].minute())
        .second(0);
      shift.end_at = moment(
        shift.end_at ? shift.end_at : moment(shift.start_at)
      )
        .hour(value[1].hour())
        .minute(value[1].minute())
        .second(0);
    } else {
      shift.start_at = null;
      shift.end_at = null;
    }

    rows[index] = shift;

    this.setState({
      rows,
    });
  };

  _updateDate = (type, value, index) => {
    if (type === 'DATE') {
      this._updateStartingDate(value, index);
    }

    if (type === 'TIMES') {
      this._updateShiftTimes(value, index);
    }
  };

  _changeChannel = (index, value) => {
    const { rows } = this.state;
    const { fetchChannelMembers, } = this.props;
    const shift = rows[index];

    shift.channel = value;

    rows[index] = shift;

    this.setState({
      rows,
    });
    fetchChannelMembers(value);
  };

  _updateReason = (index, value) => {
    const { rows } = this.state;
    const shift = rows[index];

    shift.reason = value;

    rows[index] = shift;

    this.setState({
      rows,
    });
  };

  _updateNumberOfShifts = (index, value) => {
    const { rows } = this.state;
    const shift = rows[index];

    // eslint-disable-next-line radix
    shift.number_of_shifts = parseInt(value);

    rows[index] = shift;

    this.setState({
      rows,
    });
  };

  _updateJobTitle = (index, value) => {
    const { rows } = this.state;
    const shift = rows[index];

    shift.jobTitle = value;

    rows[index] = shift;

    this.setState({
      rows,
    });
  };

  _updateCostCenter = (index, value) => {
    const { rows } = this.state;
    const shift = rows[index];

    shift.cost_work_center = value;
    rows[index] = shift;
    this.setState({
      rows,
    });
  };

  _updateSkills = (index, value) => {
    const { rows, selectedSkillIds, } = this.state;
    const shift = rows[index];

    shift.skill_ids = value;
    rows[index] = shift;
    this.setState({
      rows,
    });
    
    let obj = {...selectedSkillIds[index]};
    obj.index = index;
    obj.ids = value;
    selectedSkillIds[index] = obj;
    this.setState({selectedSkillIds,});
  };

  _updateFullSkilledPersons = (index, value) => {
    const { fullSkilledPersons, } = this.state;

    let obj = {...fullSkilledPersons[index]};
    obj.index = index;
    obj.persons = value;
    fullSkilledPersons[index] = obj;
    this.setState({fullSkilledPersons,});
  };

  _openDatePicker = (index) => {
    const { rows } = this.state;

    const newRows = rows.map((element, currentIndex) => {
      if (index === currentIndex) {
        return Object.assign(element, {
          datePickerOpen: true,
        });
      }

      return Object.assign(element, {
        datePickerOpen: false,
      });
    });

    this.setState({
      rows: newRows,
    });
  };

  _closeDatePicker = (index) => {
    const { rows } = this.state;

    const currentRow = rows[index];

    currentRow.datePickerOpen = false;

    rows[index] = currentRow;

    this.setState({
      rows,
    });
  };

  _cloneRow = (index) => {
    // get shift index and insert it after the
    // index of it
    const { rows, selectedSkillIds, } = this.state;
    const shiftToClone = rows[index];

    if(rows.length > rowLimit){
      this.setState({rowLimitReached: true,});
      return;
    }
    /*
    rows.splice(
      index,
      0,
      new Shift({
        start_at: shiftToClone.start_at ? moment(shiftToClone.start_at) : null,
        end_at: shiftToClone.end_at ? moment(shiftToClone.end_at) : null,
        reason: shiftToClone.reason,
        number_of_shifts: shiftToClone.number_of_shifts,
        channel: shiftToClone.channel,
        jobTitle: shiftToClone.jobTitle,
        cost_work_center: shiftToClone.cost_work_center,
        skill_ids: shiftToClone.skill_ids,
      })
    );
    */

    rows.push(
      new Shift({
        start_at: shiftToClone.start_at ? moment(shiftToClone.start_at) : null,
        end_at: shiftToClone.end_at ? moment(shiftToClone.end_at) : null,
        reason: shiftToClone.reason,
        number_of_shifts: shiftToClone.number_of_shifts,
        channel: shiftToClone.channel,
        jobTitle: shiftToClone.jobTitle,
        cost_work_center: shiftToClone.cost_work_center,
        skill_ids: shiftToClone.skill_ids,
      })
    );


    this.setState({
      rows,
    });
    
    let obj = {index: 0, ids: [],};
    obj.index = rows.length -1;
    obj.ids = shiftToClone.skill_ids;
    selectedSkillIds.push(obj);
    this.setState({selectedSkillIds,});
  };

  _deleteRow = (index) => {
    // using the key as an index, it corresponds
    // to the index of the array. Splice
    // that from the data store
    const { rows, selectedSkillIds, } = this.state;

    rows.splice(index, 1);

    this.setState({
      rows,
    });
    if(rows.length <= rowLimit){
      this.setState({rowLimitReached: false});
    }

    selectedSkillIds.splice(index, 1);
    this.setState({selectedSkillIds,});
  };

  _previewBatch = () => {
    this.setState({
      previewing: true,
    });
  };

  _editBatch = () => {
    this.setState({
      previewing: false,
    });
  };

  _renderRows = () => {
    const { rows, type, previewing, xpoCostCenters, selectedSkillIds, } = this.state;
    const { channels, skills, locationSettings, channelMembers, } = this.props;

    if (channels === undefined || !channels[0]) return null;

    return rows.map((element, index) => {
      const currentChannel = channels[0]
        ? channels.filter((channel) => channel.value == element.channel)[0]
        : null;
      if (previewing) {
        return (
          <ConfirmationRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            type={type}
            shift={element}
            retry={this._retry}
            skills={skills}
            locationSettings={locationSettings}
            currentChannel={
              currentChannel ? currentChannel.text : channels[0].text
            }
            //selectedSkillIds={selectedSkillIds[index] ? selectedSkillIds[index]: selectedSkillIds[0]}
          />
        );
      }

      return (
        <ShiftRow
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          index={index}
          shift={element}
          channels={channels}
          previewing={previewing}
          disabled={this.state.rowLimitReached}
          changeChannel={this._changeChannel}
          updateReason={this._updateReason}
          _updateNumberOfShifts={this._updateNumberOfShifts}
          _updateJobTitle={this._updateJobTitle}
          _updateCostCenter={this._updateCostCenter}
          cloneRow={this._cloneRow}
          deleteRow={this._deleteRow}
          _updateDate={this._updateDate}
          _openDatePicker={this._openDatePicker}
          _closeDatePicker={this._closeDatePicker}
          type={type}
          xpoCostCenters={xpoCostCenters}
          skills={skills}
          _updateSkills={this._updateSkills}
          locationSettings={locationSettings}
          selectedSkillIds={selectedSkillIds[index] ? selectedSkillIds[index]: selectedSkillIds[0]}
          _updateFullSkilledPersons={this._updateFullSkilledPersons}
          channelMembers={channelMembers}
        />
      );
    });
  };

  render() {
    const { intl, vtoEnabled, postOpenShiftEnabled } = this.props;
    const { type, rows, posted, posting, previewing, selectedSkillIds, fullSkilledPersons, } = this.state;

    const addNumbers = (accumulator, element) =>
      accumulator + (element.number_of_shifts ? element.number_of_shifts : 0);
    const addErrors = (accumulator, element) =>
      accumulator + (element.posting.error ? element.posting.error : 0);

    const numberOfErrors = rows.reduce(addErrors, 0);
    const currentNumberOfShifts = rows.reduce(addNumbers, 0);
    const numberOfShiftsIsValid = () => currentNumberOfShifts <= shiftLimit;
    const isValid = rows.every(this._shiftIsValid);
    const prompt = (currentNumberOfShifts > 0 || rows.length > 1) && !posted;

    const isSkillSelectionValid = () => {
      if(selectedSkillIds.length > 0) {
        for(let i=0; i<selectedSkillIds.length; i++) {
          if(selectedSkillIds[i] && selectedSkillIds[i].ids && selectedSkillIds[i].ids.length > 0) {
            if(fullSkilledPersons[i] && fullSkilledPersons[i].persons.length === 0) {
              return false;
            }
          }
        }
      }
      return true;
    }
    const isValidSelection = isSkillSelectionValid();

    if (!vtoEnabled && postOpenShiftEnabled === 0) {
      return <Redirect to="/calendar" />;
    }

    return (
      <PageWrap>
        <styled.HeadWrap>
          <styled.BackLink to="/calendar" replace>
            <styled.BackArrow />
            <FormattedMessage
              id="PostShiftComponent.backToCalendar"
              defaultMessage="Back to Calendar"
            />
          </styled.BackLink>
          <blocks.HeaderRow>
            <styled.Header>
              <PostShiftHeader
                type={type}
                posted={posted}
                posting={posting}
                previewing={previewing}
              />
            </styled.Header>
          </blocks.HeaderRow>
        </styled.HeadWrap>
        <styled.RowWrap>
          <blocks.PageBody>
            <Prompt
              when={prompt}
              message={() => intl.formatMessage(messages.leaveConfirmation)}
            />
            {previewing ? null : (
              <blocks.PageRow>
                <RadioButtonGroup
                  options={this.shiftTypeOptions()}
                  onChange={this.shiftTypeChange}
                  name="shiftTypes"
                  currentValue={type}
                />
              </blocks.PageRow>
            )}

            <blocks.Divider />

            {this._renderRows()}

            <AddShiftRow  onClick={this._addRow} 
                          previewing={previewing} 
                          disabled={this.state.rowLimitReached}/>
          </blocks.PageBody>
        </styled.RowWrap>
        <ActionFooter
          isValid={isValid}
          previewing={previewing}
          posting={posting}
          posted={posted}
          errorCount={numberOfErrors}
          numberOfShiftsIsValid={numberOfShiftsIsValid()}
          submitClick={this._postShifts}
          backToEdit={this._editBatch}
          previewClick={this._previewBatch}
          shiftLimit={shiftLimit}
          currentNumberOfShifts={currentNumberOfShifts}
          isValidSelection={isValidSelection}
        />
      </PageWrap>
    );
  }
}

export default injectIntl(PostShiftComponent);
