import moment from 'moment';

import { GetLocationUsableSkills, } from 'helpers/api-calls/skill-calls';
import { loadMoreCalendarShifts, } from 'helpers/api-calls/calendar-shift-calls';
import { moreShiftsLoading, moreShiftsLoaded, cancelShiftsLoading, } from '../../../actions/calendar-actions';

import actions from './actions';
import { appActions } from 'App/duck';

const getLocationUsableSkills = (locationId) => (dispatch) => 
  GetLocationUsableSkills(locationId).then(({data}) =>{
    dispatch(actions.hydrateSkills(data.skills));
    dispatch(appActions.hydrateSkills(data.skills));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationUsableSkills returns error = ${error}`;
    console.log(msg);
  });

const getMoreCalendarShifts = (start, end, pageNum, perPageNum) => (dispatch) => {
    dispatch(moreShiftsLoading());
    return loadMoreCalendarShifts(start, end, pageNum, perPageNum).then(({data}) =>{
      dispatch(moreShiftsLoaded(data.schedule_elements));
    }).catch((error) =>{
      const msg = `getMoreCalendarShifts returns error = ${error}`;
      console.log(msg);
      dispatch(cancelShiftsLoading());
    });
  }


export default {
  getLocationUsableSkills,
  getMoreCalendarShifts
};
