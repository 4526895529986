import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { teamShapes } from '../../../duck';
import strings from '../strings';
import styles from '../styled';

const BodyText = ({ selectedAction, selectedUsers, filters, intl }) => {
  /*
  const userName = selectedUsers.length
    ? `${selectedUsers[0].first_name} ${selectedUsers[0].last_name}`
    : '';
  */
  const messages = defineMessages({
    selected: {
      id: 'BodyText.selectedUsers',
      defaultMessage: 'selected users',
    },
  });
  const selected = `${intl.formatMessage(messages.selected)}`;
  /*
  const userName = selectedUsers.length > 1 ?
    `${selected}`
    :`${selectedUsers[0].first_name} ${selectedUsers[0].last_name}`;
  */
  let userName = '';
  if(selectedUsers) {
    if(selectedUsers.length > 1) {
      userName = `${selected}`;
    }else if(selectedUsers.length === 1) {
      userName = `${selectedUsers[0].first_name} ${selectedUsers[0].last_name}`;
    }else {
      userName = '';
    }
  }
  const count = selectedUsers.length;
  const total = function() {
    switch (selectedAction) {
      case 'SELECT_ACTIVE_BULK':
        return filters.active.count;
      case 'PENDING_BULK':
        return filters.pending.count;
      default:
        return null;
    }
  };
  const string = strings[selectedAction].bodyString({
    userName,
    count,
    total: total(),
  });

  return <styles.BodyText>{string}</styles.BodyText>;
};

BodyText.propTypes = {
  selectedAction: teamShapes.selectedActionTypes,
  selectedUsers: PropTypes.arrayOf(teamShapes.userShape),
  filters: teamShapes.filtersShape,
};

export default injectIntl(BodyText);


