import { connect } from 'react-redux';

import LocationSubscriberComponent from './LocationSubscriberComponent';

const mapStateToProps = (
  { locationReducer: { location_code } },
  { onChangeHandlers }
) => ({
  onChangeHandlers,
  location_code,
});

const LocationSubscriberContainer = connect(mapStateToProps)(
  LocationSubscriberComponent
);

export default LocationSubscriberContainer;
