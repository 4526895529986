import { createSlice } from '@reduxjs/toolkit';

import teamConstants from '../../constants';

const { statusTypes } = teamConstants;

const associatesSlice = createSlice({
  name: 'associates',
  initialState: {
    users: [],
    page: 0,
    totalPages: 0,
    loading: false,
  },
  reducers: {
    requestAssociates: (state, action) => {
      state.loading = true;
    },
    receiveAssociates: (state, action) => {
      state.users = action.payload.users;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendAssociates: (state, action) => {
      state.users = state.users.concat(action.payload.users);
      //state.page = action.payload.page;
      state.loading = false;
    },
    removeAssociate: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload.id
      );
      state.users.splice(memberIndex, 1);
    },
    removeAssociates: (state, action) => {
      action.payload.forEach((item) => {
        const memberIndex = state.users.findIndex(
          (member) => member.id === item
        );
        state.users.splice(memberIndex, 1);
      });
    },
    resetAssociates: (state, action) => {
      state.users = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false;
    },
    addSkillsToAssociate: (state, action) => {
      const privilegeIndex = state.users.findIndex(
        (priv) => priv.id === action.payload.id
      );
      if(privilegeIndex >= 0) {
        const newUsers = [...state.users];
        newUsers[privilegeIndex].skill_ids = action.payload.skill_ids;
        newUsers[privilegeIndex].email = action.payload.email;
        newUsers[privilegeIndex].phone_number = action.payload.phone_number;
        state.users = newUsers;
      }
      else {
        const msg = `User privilege  for user ${action.payload.owner_id}  not find in state`;
        console.log(msg);
      }
    },
    addDeptIdToAssociate: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          const newIds = newData[idx].department_ids.concat(action.payload.id);
          newData[idx].department_ids = newIds;
          state.users = newData;
        }
      }
    },
    removeDeptIdFromAssociate: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          const newIds = newData[idx].department_ids;
          const idx1 = newIds.findIndex((id) => id === action.payload.id);
          if(idx1 >= 0) {
            newIds.splice(idx1, 1);
          }
          newData[idx].department_ids = newIds;
          state.users = newData;
        }
      }
    },
    addSkillIdToAssociate: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          //const newIds = newData[idx].skill_ids.concat(action.payload.id);
          //newData[idx].skill_ids = newIds;
          const newIds = newData[idx].user.skill_ids.concat(action.payload.id);
          newData[idx].user.skill_ids = newIds;
          state.users = newData;
        }
      }
    },
    removeSkillIdFromAssociate: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          //const idx1 = newData[idx].skill_ids.findIndex((id) => id === action.payload.id);
          const idx1 = newData[idx].user.skill_ids.findIndex((id) => id === action.payload.id);
          if(idx1 >= 0) {
            //newData[idx].skill_ids.splice(idx1, 1);
            newData[idx].user.skill_ids.splice(idx1, 1);
          }
          state.users = newData;
        }
      }
    },
  },
});

export default associatesSlice;
