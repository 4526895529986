import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    pendingCount: 0,
    pendingUsers: [],
    loading: true,
  },
  reducers: {
    hydrateUsers: (state, action) => {
      //state.data = state.data.concat(action.payload);
      state.data = action.payload;
      state.loading = false;
    },
    hydrateChannelMembers: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    requestUsers: (state) => {
      state.loading = true;
    },
    updateCurrentUsers: (state, action) => {
      for(let i=0; i<action.payload.length; i++) {
          const userIndex = state.data.findIndex(
          (user) => user.id === action.payload[i].user.id
          );
          //The user is not in the array yet
          if(userIndex < 0){
            state.data = state.data.concat(action.payload[i].user)
          }
      }
    },
    hydratePendingUsers: (state, action) => {
      state.pendingUsers = action.payload;
    },
    setPendingCount: (state, action) => {
      state.pendingCount = action.payload;
    },
    removeMembers: (state, action) => {
      action.payload.forEach((item) => {
        const memberIndex = state.pendingUsers.findIndex(
          (member) => member.id === item
        );
        state.pendingUsers.splice(memberIndex, 1);
      });
    },
    addSkillIdToUser: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.data.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.data];
          const newIds = newData[idx].skill_ids.concat(action.payload.id);
          newData[idx].skill_ids = newIds;
          state.data = newData;
        }
      }
    },
  },
});

export default usersSlice;
