import { createSlice } from '@reduxjs/toolkit';

import teamConstants from '../../constants';

const { statusTypes } = teamConstants;

const channelMembersSlice = createSlice({
  name: 'channelMembers',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    requestChannelMembers: (state, action) => {
      state.loading = true;
      state.data = [];
    },
    recieveChannelMembers: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.id);
      if(idx < 0) {
        state.data.push(action.payload);
      }else {
        const newData = [...state.data];
        newData[idx].members = action.payload.members;
        state.data = newData;
      }
      state.loading = false;
    },
    resetChannelMembers: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData[idx].members = [];
        state.data = newData;
      }
    },
  },
});

export default channelMembersSlice;
