import React from 'react';

import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { teamShapes } from '../../duck';

import UserRow from '../UserRow';
import TableComponent from '../TableComponent';
import Styled from './styled';
import './TableComponent.scss';
/*
const classificationStrings = defineMessages({
  managers: {
    id: 'TableSection.managers',
    defaultMessage: 'Managers',
  },
  associates: {
    id: 'TableSection.associates',
    defaultMessage: 'Associates',
  },
});

const MultipleTables = ({ managers, associates, intl, locationId, skills, locationSettings,}) => {
  const managerRows = managers.users.map((user) => (
    <UserRow key={user.owner_id} user={user} locationId={locationId} skills={skills} locationSettings={locationSettings} />
  ));
  const associateRows = associates.users.map((user) => (
    <UserRow key={user.owner_id} user={user} locationId={locationId} skills={skills} locationSettings={locationSettings}/>
  ));

  return (
    <React.Fragment>
      <TableComponent.Header>
        {intl.formatMessage(classificationStrings.managers)}
      </TableComponent.Header>
      <thead>Hello</thead>
      <Styled.RowGroup>
        {managers.loading && managers.page === 1 ? (
          <LoadingSpinner />
        ) : (
          managerRows
        )}
      </Styled.RowGroup>
      <Styled.Separator />
      <TableComponent.Header>
        {intl.formatMessage(classificationStrings.associates)}
      </TableComponent.Header>
      <Styled.RowGroup>
        {associates.loading && managers.page === 1 ? (
          <LoadingSpinner />
        ) : (
          associateRows
        )}
      </Styled.RowGroup>
    </React.Fragment>
  );
};

MultipleTables.propTypes = {
  managers: teamShapes.usersShape.isRequired,
  associates: teamShapes.usersShape.isRequired,
};

export default injectIntl(MultipleTables);
*/

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`user-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class MultipleTables extends React.Component {
  constructor(props) {
    super(props);

    this.classificationStrings = defineMessages({
      managers: {
        id: 'TableSection.managers',
        defaultMessage: 'Managers',
      },
      associates: {
        id: 'TableSection.associates',
        defaultMessage: 'Associates',
      },
    });

    this.state = {
      managerRows: [],
      associateRows: [],
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { managers, associates, intl, locationId, skills, locationSettings, locationDepartments, } = this.props;

    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { managers, associates, intl, locationId, skills, locationSettings, locationDepartments, } = this.props;
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  

  render () {
    const { managers, associates, intl, locationId, skills, locationSettings, locationDepartments, } = this.props;

    const createHeaders = () => {
      const name = (<FormattedMessage
                id="MultipleTables.name"
                defaultMessage="Name" />);
      const dept = (<FormattedMessage
                id="MultipleTables.dept1"
                defaultMessage="Departments" />);
      const skills = (<FormattedMessage
                id="MultipleTables.skills"
                defaultMessage="Skills" />);

      let managerHeaders = ['', name, dept, skills];
      if(locationSettings.cfg__skill === 0 && locationSettings.cfg__departments === 1) {
        managerHeaders = ['', name, dept, ''];
      }
      if((locationSettings.cfg__departments === 0 || locationSettings.cfg__departments === null) && 
        locationSettings.cfg__skill === 1) {
        managerHeaders = ['', name, '', skills];
      }
      if(locationSettings.cfg__skill === 0 && 
        (locationSettings.cfg__departments === 0 || locationSettings.cfg__departments === null)) {
         managerHeaders = ['', name, '', ''];
      }
      const headers = managerHeaders.map((element, index) => {
        return (
          <Styled.HeaderItem key={index}>
            {element}
          </Styled.HeaderItem>
        );
      });
      return (
        <thead>
          <Styled.HeaderRow>
            {headers}
          </Styled.HeaderRow>
        </thead>
      );
    };
    
    const managerRows = managers.users.map((user) => (
        <UserRow 
        key={user.owner_id} 
        user={user} 
        locationId={locationId} 
        skills={skills} 
        locationSettings={locationSettings}
        locationDepartments={locationDepartments}
        singleTable={false}
        />
    ));
    const associateRows = associates.users.map((user) => (
        <UserRow 
        key={user.owner_id} 
        user={user}
        locationId={locationId} 
        skills={skills} 
        locationSettings={locationSettings}
        locationDepartments={locationDepartments}
        singleTable={false}
        />
    ));

   
    return (
      <React.Fragment>
      <TableComponent.Header>
        {intl.formatMessage(this.classificationStrings.managers)}
      </TableComponent.Header>
      {/*<LoadingLayer loading={managers.loading} />*/}
      <table className="user-table">
        {(!managers.loading && managerRows.length > 0) && createHeaders()}
        <tbody>
           {managers.loading && managers.page === 1 ? <LoadingLayer loading={managers.loading} /> : managerRows}
           {/*managerRows*/}
        </tbody>
      </table>
      <Styled.Separator />
      <TableComponent.Header>
        {intl.formatMessage(this.classificationStrings.associates)}
      </TableComponent.Header>
      <table className="user-table">
        {(!associates.loading && associateRows.length > 0) && createHeaders()}
        <tbody>
           {associates.loading && associates.page === 1 ? <LoadingLayer loading={associates.loading} /> : associateRows}
           {/*associateRows*/}
        </tbody>
      </table>
    </React.Fragment>
    );
  }
}

MultipleTables.propTypes = {
  managers: teamShapes.usersShape.isRequired,
  associates: teamShapes.usersShape.isRequired,
};

export default injectIntl(MultipleTables);

