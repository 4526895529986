import { createSelector } from '@reduxjs/toolkit';

const postsSelector = (state) => state.posts;
const commentsSelector = (state) => state.comments;
const channelsSelector = (state) => state.channels;
const activeSelector = (state) => state.active;
const teamFeedActiveSelector = (state) => state.teamFeedReducer.active;
const appReducerChannelsSelector = (state) => state.appReducer.channels;

const getOrderedPosts = createSelector(postsSelector, (posts) => {
  const sortedPosts = [...posts.data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateA - dateB;
  });

  return {
    ...posts,
    data: sortedPosts,
  };
});

const getPinnedPosts = createSelector(postsSelector, (posts) => {
  const pinnedPosts = [...posts.data]
    .sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateA - dateB;
    })
    .filter((post) => post.pinned);

  return {
    ...posts,
    data: pinnedPosts,
  };
});

const getOrderedComments = createSelector(commentsSelector, (comments) => {
  const sortedComments = [...comments.data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateA - dateB;
  });

  return {
    ...comments,
    data: sortedComments,
  };
});

const getActivePost = createSelector(
  postsSelector,
  activeSelector,
  (posts, active) => posts.data.filter((post) => post.id === active.post.id)[0]
);

const getActiveChannelId = createSelector(
  teamFeedActiveSelector,
  (active) => active.channel
);

const getActiveChannel = createSelector(
  getActiveChannelId,
  appReducerChannelsSelector,
  (activeId, channels) =>
    channels.data.filter((channel) => channel.id === activeId)[0]
);

const getActiveSidePane = createSelector(
  activeSelector,
  (active) => active.sidepane
);

const getDateOrderedChannels = createSelector(channelsSelector, (channels) => {
  const sortedChannels = [...channels.data].sort((a, b) => {
    const { channel_type: typeA, updated_at: updatedA } = a;
    const { channel_type: typeB, updated_at: updatedB } = b;

    if (typeA > typeB || updatedB - updatedA) {
      return -1;
    }

    if (typeA < typeB || updatedA - updatedB) {
      return 1;
    }

    return 0;
  });
  const filteredChannels = sortedChannels.filter(
    (channel) => channel.channel_type !== 'region_feed'
  );

  return {
    ...channels,
    //data: filteredChannels,
    data: sortedChannels,  //put regional feed back 2021.08.16
  };
});

export default {
  getOrderedPosts,
  getOrderedComments,
  getPinnedPosts,
  getActivePost,
  getActiveChannel,
  getActiveChannelId,
  getActiveSidePane,
  getDateOrderedChannels,
};
