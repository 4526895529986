import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import OwnerCell from './Cell/OwnerCell';
import StatusCell from './Cell/StatusCell';

import './TableComponent.scss';
import styled from './styled';

class ScheduleRowComponent extends React.Component {
	constructor(props) {
    super(props);

    this.state ={
      debug: true,
      
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { schedule, actionJob, } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {
    const { schedule, actionJob, } = this.props;

    if(prevProps.schedule !== schedule) {
      //console.log('updated');
    }

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render() {
    const { schedule, notifyScheduleOwner, } = this.props;
    const scheduleName = schedule && schedule.record_meta  ? schedule.record_meta.input_sheet_original_filename : '';
    const scheduleId = schedule && schedule.schedule_id ? schedule.schedule_id : '';
    const total = schedule && schedule.total_count !== null ? schedule.total_count : '-';
    const success = schedule && schedule.success_count !== null ? schedule.success_count : '-';
    const failed = schedule && schedule.failed_count !== null ? schedule.failed_count : '-';

    const scheduleIdText = (<FormattedMessage
                    id="ScheduleRowComponent.id"
                    defaultMessage="Schedule ID: {id}" 
                    values={{id: scheduleId}}/>);
  	return (
  		<tr className="schedule-row__row">
        <td className="schedule-row__file-name-cell">
          <styled.FileNameWrap>
            <styled.FileName>{scheduleName}</styled.FileName>
            <styled.ScheduleId>{scheduleIdText}</styled.ScheduleId>
          </styled.FileNameWrap>
        </td>
        <td className="schedule-row__owner-cell">
          <OwnerCell 
            schedule={schedule}
            notifyScheduleOwner={notifyScheduleOwner}
          />
        </td>
        <td className="schedule-row__status-cell">
          <StatusCell 
            schedule={schedule}
          />
        </td>
        <td className="schedule-row__total-cell">{total}</td>
        <td className="schedule-row__succeeded-cell">{success}</td>
        <td className="schedule-row__failed-cell">{failed}</td>
        <td><button className="schedule-row__button-more"/></td>
      </tr>
  	);
  }

}

ScheduleRowComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(ScheduleRowComponent);