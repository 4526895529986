import styled from 'styled-components/macro';

const Segment = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};

  &:last-child {
    border-bottom: none;
  }

  * {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
      text-align: right;
    }
  }
`;

export default Segment;
