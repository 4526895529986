import styled from 'styled-components/macro';

import Text from './Text';

const Reason = styled(Text)`
  display: inline;
  flex: initial;
  white-space: pre-line;
`;

export default Reason;
