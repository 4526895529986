import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import constants from '../../constants';
import styled from './styled';
import './Header.scss';

class ConfirmStep2 extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state ={
      debug: false,
      inputText: '',
    };

    this._isMounted = false;
  }

   componentDidMount() {
   const { locationData,  locations, } = this.props;

   this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancel = (event) => {
  	const { notifyConfirmStep2, } = this.props;
    notifyConfirmStep2(false);
  }

  handleConfirm = (event) => {
  	const { notifyConfirmStep2, } = this.props;
    notifyConfirmStep2(true);
  }

  handleTextChanged = (event) => {
    this.setState({inputText: event.target.value});
  }

  render () {
  	const { selectedAction, selectedUsers, notifyConfirmStep2, } = this.props;
    const { inputText, } = this.state;
    const { userActionTypes } = constants;

  	let winTop = `400px`; //`${(window.innerHeight - 612)/2}px`;
    let winLeft = `600px`; //`${(window.innerWidth - 1200)/2}px`;
    const winW = '400px';//`50%`;
    const winH = `220px`; //94.9% viewport height
    
    const nonAdmins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === false): [];
    const admins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === true): [];

    const enterText1 = (<FormattedMessage
      id="ConfirmStep2.enterText1"
      defaultMessage="Enter the text"
    />);
    const enterText2 = (<FormattedMessage
      id="ConfirmStep2.enterText2"
      defaultMessage="to remove selected selectedUsers"
    />);
    
    const cancel = (<FormattedMessage
      id="ConfirmStep2.cancel"
      defaultMessage="Cancel"
    />);
    const confirm = (<FormattedMessage
      id="ConfirmStep2.confirm"
      defaultMessage="Confirm"
    />);

    return (
       <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
        <label className="confirm__title">{selectedAction.body}</label>
        <div>
          <label className="confirm__text">{enterText1}</label>
          <label className="confirm__text-bold">REMOVE</label>
          <label className="confirm__text">{enterText2}</label>
        </div>
        <input className="confirm__input-box"
              onChange={this.handleTextChanged}
              type="text"
              value={inputText}
            />
        <div className="confirm__button-wrap">
          <button className="confirm__button" onClick={this.handleCancel}>{cancel}</button>
          {inputText === 'REMOVE' && <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>}
          </div>
      </styled.WinContainerConfirm>
    );
  }
}

ConfirmStep2.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(ConfirmStep2);