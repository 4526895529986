import { createSelector } from '@reduxjs/toolkit';

const usersSelector = (state) => state.users;
const loadingSelector = (state) => state.loading;
const pageSelector = (state) => state.page;
const selectedUsersSelector = (state) => state.selectedUsers;

const managers = createSelector(
  usersSelector,
  loadingSelector,
  pageSelector,
  (users, loading, page) => {
    const filteredUsers = users.filter((user) => user.is_admin);

    return {
      users: filteredUsers,
      loading,
      page,
    };
  }
);

const associates = createSelector(
  usersSelector,
  loadingSelector,
  pageSelector,
  (users, loading, page) => {
    const filteredUsers = users.filter((user) => !user.is_admin);

    return {
      users: filteredUsers,
      loading,
      page,
    };
  }
);

const selectedUsers = createSelector(
  usersSelector,
  selectedUsersSelector,
  (users, selectedUsers) => {
    const mergedUsers = users.filter((user) => selectedUsers.includes(user.id));

    return mergedUsers;
  }
);

export default {
  managers,
  associates,
  selectedUsers,
};
