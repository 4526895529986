import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
//import { GetUserRequestTimeOffBalance, } from 'helpers/api-calls/rto-calls';
//import ApplicantRowContainer from './ApplicantRowContainer';

import calendarIcon from './assets/date.svg';
import clockIcon from './assets/clock.svg';
import jobTitle from './assets/job-title.svg';
import locationIcon from './assets/location icon.svg';
import statsIcon from './assets/stats.svg';
import reasonIcon from './assets/reason.svg';
import typeIcon from './assets/shift-type.svg';
import upArrow from './assets/blue-up-arrow.svg';
import downArrow from './assets/blue-down-arrow.svg';
import skillsIcon from './assets/skills-icon.svg';
//import LastToApproveContainer from './LastToApproveContainer';

import styled from './ButtonStyles';



class ConfirmRemove extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      hide: true,
    };

	  this.messages = defineMessages({
	    to: {
	      id: 'ShiftDetails.to',
	      defaultMessage: 'to',
	    },
	    shifts: {
	    	id: 'ShiftDetails.shifts',
	      defaultMessage: 'Shifts',
	    },
	    applied: {
	    	id: 'ShiftDetails.applied',
	      defaultMessage: 'Applied',
	    },
	    approved: {
	    	id: 'ShiftDetails.approved',
	      defaultMessage: 'Approved',
	    },
	    description: {
	    	id: 'ShiftDetails.description',
	      defaultMessage: 'Description',
	    }
	  });
	  this._isMounted = false;
	  this.isBackButtonClicked = false;

	  //this.handleNameClick = this.handleNameClick.bind(this);
	  //this.handleJobTitleClick = this.handleJobTitleClick.bind(this);
	  //this.handleStatusClick = this.handleStatusClick.bind(this);

  }

  componentDidMount () {
  	//const {shift, skills, locationSettings, fetchShiftApplicants, userPrivileges, } = this.props;

  	this._isMounted = true;
  	//handle browser back button clicked
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);

  }

  componentDidUpdate (prevProps) {
  	const {shift, skills, locationSettings, userPrivileges, addApplicantJobTitle, } = this.props;
  	const { selectedSort, sortNameA, sortTitleA, sortStatusA, applicantsSortByName, applicantsSortByJob, applicantsSortByStatus,} = this.state;

  }

  componentWillUnmount () {
  	this._isMounted = false;
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }


  handleCancelClick = (event) => {
  	const { closeWin, cancelRemove, } = this.props;
  	cancelRemove();
  	closeWin();
  	event.preventDefault();
  }

  handleConfirmClick = (event) => {
  	const { closeWin, confirmRemove, } = this.props;
  	confirmRemove();
  	closeWin();
  	event.preventDefault();
  }
  
  render () {
  	const {shift, skills, intl, locationSettings, } = this.props;

   	let winTop = `200px`; //`${(window.innerHeight - 612)/2}px`;
    let winLeft = `400px`; //`${(window.innerWidth - 1200)/2}px`;
    let winW = '600px';//`50%`;
    let winH = `120px`; //94.9% viewport height
    
   	return (
   		<styled.DetailedWinContainer top={winTop} left={winLeft} width={winW} height={winH}>
   			<styled.HeaderWrap>
	   			<styled.Caption>
	   				<FormattedMessage id="ConfirmRemove.header"
		        	defaultMessage="Remove Users Profile Photo?"/>
		      </styled.Caption>
	      </styled.HeaderWrap>

   			<styled.ButtonWrap>
   				<styled.AButton 
   					onClick={this.handleCancelClick}>
   					<FormattedMessage
              id="ConfirmRemove.cancel"
              defaultMessage="Cancel"
            />
   				</styled.AButton>
   				<styled.AButton 
   					onClick={this.handleConfirmClick}>
   					<FormattedMessage
              id="ConfirmRemove.confirm"
              defaultMessage="Confirm"
            />
   				</styled.AButton>
   			</styled.ButtonWrap>
   		</styled.DetailedWinContainer>
   	);
   }

}


ConfirmRemove.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(ConfirmRemove);
