import React from 'react';
import { FormattedMessage } from 'react-intl';

import { teamConstants } from '../../duck';

const { userActionTypes, bulkActionTypes } = teamConstants;
const BodyActionStrings = function(strings) {
  this.bodyString = (values) => strings[0](values);
  this.actionStrings = [strings[1], strings[2]];
};
const cancelString = (
  <FormattedMessage id="BottomSheet.cancelString" defaultMessage="Cancel" />
);
const strings = {
  [userActionTypes.REMOVE]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.removeBody"
        defaultMessage="Are you sure you want to remove {userName} from this location? They will be able to join again at a later point."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage
      id="BottomSheet.removeAction"
      defaultMessage="Remove User"
    />,
  ]),
  [userActionTypes.PROMOTE]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.promoteBody"
        defaultMessage="Are you sure you want to promote {userName} to be a manager of this location? They will be given all admin privileges you currently have."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage
      id="BottomSheet.promoteAction"
      defaultMessage="Promote User"
    />,
  ]),
  [userActionTypes.WITHDRAW]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.withdrawBody"
        defaultMessage="Are you sure you want to withdraw admin privileges for {userName}? They will not be able to perform administrative actions like approving shifts."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage
      id="BottomSheet.withdrawAction"
      defaultMessage="Withdraw Privileges"
    />,
  ]),
  [userActionTypes.BLOCK]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.blockBody"
        defaultMessage="Are you sure you want to block {userName}? They will not be able to join the location again unless you contact the Shyft support team."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage
      id="BottomSheet.blockAction"
      defaultMessage="Block User"
    />,
  ]),
  [userActionTypes.SAVE_USER]: new BodyActionStrings([
    <FormattedMessage
      id="BottomSheet.saveUser"
      defaultMessage="Save changes?"
    />,
    cancelString,
    <FormattedMessage id="BottomSheet.confirmUser" defaultMessage="Yes" />,
  ]),
  [bulkActionTypes.SELECT_ACTIVE_BULK]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.selectBulk"
        defaultMessage="You have selected {count} out of {total} people. Please scroll down to select more."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage id="BottomSheet.removeAll" defaultMessage="Remove All" />,
  ]),
  [bulkActionTypes.PENDING_BULK]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.pendingBulk"
        defaultMessage="You have selected {count} out of {total} people. Please scroll down to select more."
        values={values}
      />
    ),
    <FormattedMessage id="BottomSheet.denyAll" defaultMessage="Deny All" />,
    <FormattedMessage
      id="BottomSheet.approveAll"
      defaultMessage="Approve All"
    />,
  ]),
  [bulkActionTypes.ACTIVE_BULK]: new BodyActionStrings([
    (values) => (
      <FormattedMessage
        id="BottomSheet.activeBulk"
        defaultMessage="Are you sure you want to remove {count} users from this location? They will be able to join again at a later point."
        values={values}
      />
    ),
    cancelString,
    <FormattedMessage id="BottomSheet.removeAll" defaultMessage="Remove All" />,
  ]),
};

export default strings;
