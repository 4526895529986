import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import Modal from 'components/GeneralComponents/Modal';
import constants from '../../constants';
import './Category.scss';
import styled from './styled';

class Category extends React.PureComponent {
  constructor(props) {
    super(props);
    const { categoryTypes } = constants;

    this.state ={
      debug: true,
      selected: categoryTypes.LOCATION,
      selectedIndex: 0,
      openMenu: false,
      selectedOperation: null,
      dropdownPos: null,
    };
  
    this._isMounted = false;
    this.buttonRef = React.createRef();
    this.winRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { locationSettings, selectedType, } = this.props;
    const { categoryTypes } = constants;

    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside);

    if(selectedType !== undefined) {
      switch(selectedType) {
        case categoryTypes.LOCATION:
          this.setState({selected: categoryTypes.LOCATION, selectedIndex: 0});
          break;
        case categoryTypes.DEPARTMENTS:
          this.setState({selected: categoryTypes.DEPARTMENTS, selectedIndex: 1});
          break;
        case categoryTypes.GROUPS:
          this.setState({selected: categoryTypes.GROUPS, selectedIndex: 2});
          break;
        case categoryTypes.SKILLS:
          this.setState({selected: categoryTypes.SKILLS, selectedIndex: 3});
          break
      }

    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { openMenu, } = this.state;

    if(prevState.openMenu !== openMenu) {
      if(openMenu) {
        let winTop = `400px`; //`${(window.innerHeight - 612)/2}px`;
        let winLeft = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        let winRight = `20px`; //`${(window.innerWidth - 1200)/2}px`;
        const winW = '100px';//`50%`;
        const winH = `100px`; //94.9% viewport height

        if(this.buttonRef && this.buttonRef.current) {
          const domNode = ReactDOM.findDOMNode(this.buttonRef.current);
          const output =domNode.getBoundingClientRect();
          if(output) {
            winTop = `${output.y + output.height + 15}px`;
            winLeft = `${output.x - output.width}px`;
          }
        }
        const pos = {
        top: winTop,
        left: winLeft,
        right: winRight,
        width: winW,
        height: winH,
      }
      this.setState({dropdownPos: pos});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { openMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;

    if (this.buttonRef && this.buttonRef.current && !this.buttonRef.current.contains(event.target)) {
      if(this.winRef && this.winRef.current && !this.winRef.current.contains(event.target)) {
        if(openMenu) {
          this.setState({openMenu: false});
          notifyDropDownOpened(false);
        }
      }
    }
  }

  handleLocationClicked = (event) => {
    const { categoryTypes } = constants;
    const { categorySelected } = this.props;
    this.setState({selected: categoryTypes.LOCATION, selectedIndex: 0});
    categorySelected(categoryTypes.LOCATION);
  }

  handleDepartmentsClicked = (event) => {
    const { categoryTypes } = constants;
    const { categorySelected } = this.props;
    this.setState({selected: categoryTypes.DEPARTMENTS, selectedIndex: 1});
    categorySelected(categoryTypes.DEPARTMENTS);
  }

  handleGroupsClicked = (event) => {
    const { categoryTypes } = constants;
    const { categorySelected } = this.props;
    this.setState({selected: categoryTypes.GROUPS, selectedIndex: 2});
    categorySelected(categoryTypes.GROUPS);
  }

  handleSkillsClicked = (event) => {
    const { categoryTypes } = constants;
    const { categorySelected } = this.props;
    this.setState({selected: categoryTypes.SKILLS, selectedIndex: 3});
    categorySelected(categoryTypes.SKILLS);
  }

  handleCreateClicked = (event) => {
    const { openMenu, } = this.state;
    const { notifyDropDownOpened, } = this.props;
    this.setState({openMenu: !openMenu});
    notifyDropDownOpened(!openMenu);
  }

  handleOperationChange = (operation) => {
    const { notifyOperationRequested, notifyDropDownOpened, } = this.props;
    const { operationTypes } = constants;

    this.setState({openMenu: false, selectedOperation: operation});
    notifyOperationRequested(operation);
    notifyDropDownOpened(false);
  }

  renderOperationItems = () => {
    const { locations, locationSettings, } = this.props;
    const { dropdownPos, } = this.state;
    const { operationTypes, } = constants;
   
    const departmentText = (<FormattedMessage
      id="Category.department"
      defaultMessage="Department"
    />);
    const groupText = (<FormattedMessage
      id="Category.group"
      defaultMessage="Group"
    />);
    const skillsText = (<FormattedMessage
      id="Category.skills"
      defaultMessage="Skills"
    />);

    

    const department = { name: operationTypes.CREATE_DEPARTMENT, body: departmentText};
    const group = { name: operationTypes.CREATE_GROUP, body: groupText};
    const skills = { name: operationTypes.CREATE_SKILL, body: skillsText};

    //Since api for creating skills is not available, hide "Skills" until it is available
    //let items = [department, group, skills];
    let items = [department, group];
    if(locationSettings && locationSettings.cfg__departments !== 1) {
      //items = [group, skills];
      //Since api for creating skills is not available, hide "Skills" until it is available
      items = [group];
    }

    if(locationSettings && locationSettings.cfg__skill !== 1) {
      items = [department, group];
    }

    if(locationSettings && locationSettings.cfg__departments !== 1 && locationSettings.cfg__skill !== 1) {
      items = [group];
    }

    
    const operationItems = items.map((item, index) => (
      <div 
        key={index} 
        className="category__dropdown-item-wrap" 
        value={item}
        onClick={(event) => {this.handleOperationChange(item)}}
      >
        <label className="category__action-dropdown-text">
          {item.body}
        </label>
      </div>
    ));
    if(dropdownPos) {
      return (
        <styled.WinContainer  ref={this.winRef} top={dropdownPos.top} right={dropdownPos.right} width={dropdownPos.width} height={dropdownPos.height}>
          {operationItems}
        </styled.WinContainer>
      );
    }
  }


  render() {
    const { searchTerm, selected, selectedIndex, openMenu, } = this.state;
    const { intl, userSummary, locationSettings, } = this.props;

    const location = (<FormattedMessage
      id="Category.location"
      defaultMessage="Location"
    />);

    const departments = (<FormattedMessage
      id="Category.departments"
      defaultMessage="Departments"
    />);

    const groups = (<FormattedMessage
      id="Category.groups"
      defaultMessage="Groups"
    />);

    const skills = (<FormattedMessage
      id="Category.skills"
      defaultMessage="Skills"
    />);

     const create = (<FormattedMessage
      id="Category.create"
      defaultMessage="Create"
    />);

    const textWrapClassName = 'category__text-wrap';
    const selectedItemClassName = 'category__text-wrap-selected';

    if(locationSettings && locationSettings.cfg__departments !== 1 && locationSettings.cfg__skill === 1) {
      return (
        <div className="category__wrap">
          {selectedIndex === 0 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div>}
          {selectedIndex === 2 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div>}
          {selectedIndex === 3 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleSkillsClicked}>{skills}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleSkillsClicked}>{skills}</p>
          </div>}
          <button className="category__create-button" onClick={this.handleCreateClicked} ref={this.buttonRef}>{create}</button>
          {openMenu && <Modal>
            {this.renderOperationItems()}
          </Modal>}
        </div>
      );
    }

    if(locationSettings && locationSettings.cfg__skill !== 1 && locationSettings.cfg__departments === 1) {
      return (
        <div className="category__wrap">
          {selectedIndex === 0 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div>}

          {selectedIndex === 1 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleDepartmentsClicked}>{departments}</p>
          </div> : 
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleDepartmentsClicked}>{departments}</p>
          </div>}

          {selectedIndex === 2 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div>}
          <button className="category__create-button" onClick={this.handleCreateClicked} ref={this.buttonRef}>{create}</button>
          {openMenu && <Modal>
            {this.renderOperationItems()}
          </Modal>}
        </div>
      );
    }

    if(locationSettings && locationSettings.cfg__skill !== 1 && locationSettings.cfg__departments !== 1) {
       return (
        <div className="category__wrap">
          {selectedIndex === 0 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
          </div>}
          {selectedIndex === 2 ? 
          <div className={selectedItemClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div> :
          <div className={textWrapClassName}>
            <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
          </div>}
          <button className="category__create-button" onClick={this.handleCreateClicked} ref={this.buttonRef}>{create}</button>
          {openMenu && <Modal>
            {this.renderOperationItems()}
          </Modal>}
        </div>
      );
    }

    return (
      <div className="category__wrap">
        {selectedIndex === 0 ? 
        <div className={selectedItemClassName}>
          <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
        </div> :
        <div className={textWrapClassName}>
          <p className="category__text" onClick={this.handleLocationClicked}>{location}</p>
        </div>}

        {selectedIndex === 1 ? 
        <div className={selectedItemClassName}>
          <p className="category__text" onClick={this.handleDepartmentsClicked}>{departments}</p>
        </div> : 
        <div className={textWrapClassName}>
          <p className="category__text" onClick={this.handleDepartmentsClicked}>{departments}</p>
        </div>}

        {selectedIndex === 2 ? 
        <div className={selectedItemClassName}>
          <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
        </div> :
        <div className={textWrapClassName}>
          <p className="category__text" onClick={this.handleGroupsClicked}>{groups}</p>
        </div>}
        {selectedIndex === 3 ? 
        <div className={selectedItemClassName}>
          <p className="category__text" onClick={this.handleSkillsClicked}>{skills}</p>
        </div> :
        <div className={textWrapClassName}>
          <p className="category__text" onClick={this.handleSkillsClicked}>{skills}</p>
        </div>}
        <button className="category__create-button" onClick={this.handleCreateClicked} ref={this.buttonRef}>{create}</button>
        {openMenu && <Modal>
          {this.renderOperationItems()}
        </Modal>}
      </div>
    );
  }
}

Category.propTypes = {
  categorySelected: PropTypes.func.isRequired,
};

export default injectIntl(Category);

