import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import styled from 'styled-components/macro';
import { ProfileThumb } from '@shyft/acorns';

import { dashboardShapes } from '../../duck';

const Wrap = styled.div`
  width: 65px;
  height: 35px;
  position: relative;
  margin-right: 1rem;
`;

const ThumbWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  z-index: 1;

  &:last-child {
    left: 30px;
    z-index: 0;
  }
`;

const Avatar = styled.img`
  width: 34px;
  height:34px;
  border-radius: 50%;
`;

const ProfileImages = ({ name, urls }) => (
  <Wrap>
    <ThumbWrap>
      {/*<ProfileThumb thumbUrl={urls[0]} />*/}
      <Avatar src={urls[0]} />
    </ThumbWrap>
    <ThumbWrap>
      {/*<ProfileThumb thumbUrl={urls[1]} shiftName={name} />*/}
      <Avatar src={urls[1]} />
    </ThumbWrap>
  </Wrap>
);

ProfileImages.propTypes = {
  name: dashboardShapes.shiftNames.isRequired,
  urls: arrayOf(PropTypes.string).isRequired,
};

export default ProfileImages;
