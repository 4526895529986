import styled from 'styled-components/macro';

import TableComponent from '../TableComponent';

/*
const SkillCell = styled(TableComponent.Cell)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 650px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;
*/
const SkillCell = styled.div `
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  font-weight: 600;
  text-transform: capitalize;

  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 30%;
  
  padding-top: 10px;

  @media (max-width: 650px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

`;

export default SkillCell;
