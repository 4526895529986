import styled from 'styled-components/macro';

import Row from './Row';
import Cell from './Cell';
import Header from './Header';

const TableComponent = styled.div`
  /* padding: 0 3rem; */
  background-color: white;
  flex: 1;
`;

TableComponent.Row = Row;
TableComponent.Cell = Cell;
TableComponent.Header = Header;

export default TableComponent;
