import { defineMessages } from 'react-intl';
import { default as constants } from '../../duck/constants';

const userStatusMessages = defineMessages({
  [constants.statusTypes.ACTIVE]: {
    id: 'UserStatusMessages.active',
    defaultMessage: 'Active',
  },
  [constants.statusTypes.REJECTED]: {
    id: 'UserStatusMessages.rejected',
    defaultMessage: 'Rejected',
  },
  [constants.statusTypes.PENDING]: {
    id: 'UserStatusMessages.pending',
    defaultMessage: 'Pending',
  },
  [constants.statusTypes.REMOVED]: {
    id: 'UserStatusMessages.removed',
    defaultMessage: 'Removed',
  },
  [constants.statusTypes.WITHDRAWN]: {
    id: 'UserStatusMessages.withdrawn',
    defaultMessage: 'Removed',
  },
  [constants.statusTypes.BLOCKED]: {
    id: 'UserStatusMessages.blocked',
    defaultMessage: 'Blocked',
  },
});

export { userStatusMessages };
