import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';

import { breakpoints } from '../styled';

import copyButton from '../assets/copy-button.svg';
import copyButtonGrayedOut from '../assets/copy-button-grayed-out.svg'
import deleteButton from '../assets/delete-button.svg';

const borderColor = '#DEDEDE';
const textColor = '#90959A';
const darkTextColor = '#64676A';
const shiftsPerRow = 500;

//$textColor: #808080;
//$fontWeight: 200;

const skillButtonTextColor = '#808080'


const TextArea = styled.textarea.attrs((props) => ({
  onChange: props.onChange,
  cols: 40,
  rows: 3,
}))`
  border: 1px solid ${borderColor};
  padding: 5px;
  width: 100%;

  @media (max-width: ${breakpoints.med}) {
    height: 3rem;
  }
`;

const OfferInput = styled.input.attrs((props) => ({
  onChange: props.onChange,
  type: 'number',
  max: shiftsPerRow,
  min: 1,
}))`
  color: ${textColor};
  font-size: 14px;
  padding: 0.2rem 0.33rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border};

  &:out-of-range {
    //color: white;
    //background-color: red;
    outline: none; //without this line, there would be a blue outline and it is mixed with border color
    border: 3px solid red;
  }
`;

const DeleteRowButtonWrapper = styled.button`
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const DeleteRowButton = styled.img.attrs({
  src: deleteButton,
})``;

const CopyRowButtonWrapper = styled.button`
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;

`;

const CopyRowButton = styled.img.attrs({
  src: copyButton,
})``;

const CopyRowButtonGrayedOut = styled.img.attrs({
  src: copyButtonGrayedOut,
})``;

const DateTimeWrapper = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 70px;
  z-index: 4;
  background-color: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
`;

const InputLabel = styled.p`
  color: ${darkTextColor};
  font-weight: 700;

  @media (max-width: ${breakpoints.med}) {
    margin-bottom: 0.33rem;
  }
`;
const MaxApplicantsInput = styled.input.attrs((props) => ({
  onChange: props.onChange,
  type: 'number',
  max: 100,
  min: 1,
}))`
  color: ${textColor};
  font-size: 14px;
  padding: 0.2rem 0.33rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border};

  //border: 1px solid red;

  &:out-of-range {
    //color: white;
    //background-color: red;
    outline: none; //without this line, there would be a blue outline and it is mixed with border color
    border: 3px solid red;
  }
`;

const JobTitle = styled.textarea.attrs((props) => ({
  onChange: props.onChange,
  cols: 200,
  rows: 1,
}))`
  color: ${textColor};
  font-size: 14px;
  padding: 0.2rem 0.33rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border};

  //border: 1px solid red;

  &:out-of-range {
    //color: white;
    //background-color: red;
    outline: none; //without this line, there would be a blue outline and it is mixed with border color
    border: 3px solid red;
  }
`;

const Warning = styled.label `
  color: red;
`;

const CostCenter = styled.button.attrs((props) => ({
  onChange: props.onChange,
  imgUrl: props.imgUrl || '/icons/down-arrow.svg'
}))`
  color: ${textColor};
  width: 100%;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.palette.border};

  background-repeat: no-repeat;
  //background-image: url("/icons/down-arrow.svg");
  background-image: url(${props => props.imgUrl});
  background-size: 'cover';//the '' is very important
  background-position: right;
  //background-position: 90% 50%;
  background-position: 95% 50%;

  text-align: left;
  font-size: 14px;
  padding-left: 5px;

   &:hover {
    //background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    outline: 1px solid blue;
  }
`;

const WinContainer = styled.section`
  //display: flex;
  //justify-content: flex-start;
  //flex-direction: column;
  //flex-wrap: wrap;
  //padding: 1.5rem;
  //margin-bottom: 1rem;
  //margin.right: 0px;
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: 270px;
  //height: 500px;
  height: 300px;
  position: absolute;
  background-color: white;
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  border-radius: 10px;
  z-index: 3;
  overflow-y: scroll;
  //border: 1px solid red;
`;

const CostCenterMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid lightgrey;

   &:hover {
    background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    background-image: url('/icons/check-mark.svg');
    background-repeat: no-repeat;
    //background-position: right;
    //background-position: 90% 50%;
    background-position: 95% 50%;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }

  //border: 1px solid green;
`;

const CostCenterTagContent = styled.label `
  color: ${textColor};
  font-size: 14px;
`;

const SkillsButton = styled.button.attrs((props) => ({
  onChange: props.onChange,
  imgUrl: props.imgUrl || '/icons/down-arrow.svg',
  open: props.open,
}))`
  color: ${skillButtonTextColor};
  width: 100%;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.palette.border};

  background-repeat: no-repeat;
  background-image: url(${props => props.imgUrl});
  //background-size: 'cover';//the '' is very important
  background-size: ${props => props.open ? '15px ': '11px'};
  background-position: right;
  background-position: 95% 50%;

  text-align: left; 
  text-overflow: ellipsis;
  //white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  font-weight: 300;
  font-size: 14px;

  &:hover {
    //background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    outline: 1px solid blue;
  }

  &:focus {
    outline: none;
  }
`;

const SkillsWrap = styled.div `
  width: 100%;
  height: 120px;
  margin-right: 20px;
  overflow-y: scroll;
  border: 1px solid rgba(209,209,209,255);
`;

const RadioButtonWrap = styled.div `
  padding-left: 10px;
  border-bottom: 1px solid lightgrey;
  height: 40px;
`;

const ButtonLabel = styled.label `
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  height: 30px;
  padding-top: 5px;
  /*
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  */
  font-size: 14px;
  font-weight: 300;

  @media (max-width: 870px) {
    max-width: 90px;
  }
`;

const MembersWrap = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  //border: 1px solid red;
`;

const Reach = styled.label.attrs((props) => ({
  showRedText: props.showRedText || false,
}))`
  color: ${props => props.showRedText ? 'red' : 'black'};
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  line-height: 100%;
  padding-left: 5px;
  //border: 1px solid blue;
  
`;

const Members = styled.label.attrs((props) => ({
  showRedText: props.showRedText || false,
}))`
  color: ${props => props.showRedText ? 'red' : `${textColor}`};
  white-space: nowrap;
  font-size: 12px;
  font-weight: 300;
  line-height: 100%;
  //border: 1px solid blue;
  
`;

export {
  TextArea,
  InputLabel,
  DeleteRowButton,
  DeleteRowButtonWrapper,
  CopyRowButton,
  CopyRowButtonGrayedOut,
  CopyRowButtonWrapper,
  OfferInput,
  DateTimeWrapper,
  MaxApplicantsInput,
  JobTitle,
  Warning,
  CostCenter,
  WinContainer,
  CostCenterMenuItem,
  CostCenterTagContent,
  SkillsButton,
  SkillsWrap,
  RadioButtonWrap,
  ButtonLabel,
  MembersWrap,
  Reach,
  Members,
};
