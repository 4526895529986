import { createSlice } from '@reduxjs/toolkit';

const departmentsSlice = createSlice({
  name: 'departments',
  initialState: {
    data: [],
    myDepartments: [],
    loading: true,
  },
  reducers: {
    hydrateDepartments: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    hydrateMyDepartments: (state, action) => {
      state.myDepartments = action.payload;
      state.loading = false;
    },
    appendDepartment: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
    },
    addUsersToDepartment: (state, action) => {
      const idx = state.data.findIndex((dept) => dept.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        const newIds = newData[idx].user_ids.concat(action.payload.user_ids);
        newData[idx].user_ids = newIds;
        state.data = newData;
      }
    },
    removeUsersFromDepartment: (state, action) => {
      const idx = state.data.findIndex((dept) => dept.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        let arrIds = newData[idx].user_ids;
        for(let i=0; i< action.payload.user_ids.length; i++) {
          const newIds = arrIds.filter((id) => id !== action.payload.user_ids[i])
          arrIds = newIds;
        }
        newData[idx].user_ids = arrIds;
        state.data = newData;
      }
    },
  },
});

export default departmentsSlice;
