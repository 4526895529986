import { connect } from 'react-redux';
import TeamProfileDetailsComponent from './TeamProfileDetailsComponent';

const mapStateToProps = ({ teamProfileReducer, locationReducer }) => {
  return {
    locationId: locationReducer.location_code,
    user: teamProfileReducer,
  };
};

const TeamProfileDetailsContainer = connect(mapStateToProps)(
  TeamProfileDetailsComponent
);

export default TeamProfileDetailsContainer;
