import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  shifts: slices.shiftsSlice.reducer,
  
};

const reducer = combineReducers({
  shifts: reducers.shifts,
  
});

export default reducer;
