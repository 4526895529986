import { connect } from 'react-redux';
import ProfileComponent from './ProfileComponent';

import { default as operations } from '../../../../duck/operations';

const mapStateToProps = ({ teamProfileReducer, appReducer }) => {
  return {
    user: teamProfileReducer,
    skills: appReducer.skills.data,
    locationSettings: appReducer.location.settings,
    locationDepartments: appReducer.departments.data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    submitUserDetails: (id, ownerId, firstName, lastName, position, badge) => {
      dispatch(
        operations.updateCurrentEmployeePrivilege(id, ownerId, {
          first_name: firstName,
          last_name: lastName,
          position,
          badge_id: badge,
        })
      );
    },
    submitUserAvatar: (id, ownerId, user) => {
      dispatch(
        operations.updateUserAvatar(id, ownerId))
    },

    resetCurrentUser: () => {
      dispatch(operations.resetCurrentUser());
    },
  };
};

const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent);

export default ProfileContainer;
