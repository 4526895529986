import { createSlice } from '@reduxjs/toolkit';

const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    hydrateChannels: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    requestChannels: (state) => {
      state.loading = true;
    },
    updateLatestContent: (state, action) => {
      const indexToUpdate = state.data.findIndex(
        (channel) => channel.id === action.payload.id
      );

      state.data[indexToUpdate].channel_latest_content = action.payload.content;
    },
    appendChannel: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
    },
    removeChannel: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData.splice(idx, 1);
        state.data = newData;
      }
    },
    addUsersToChannel: (state, action) => {
      const idx = state.data.findIndex((dept) => dept.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        //const newIds = newData[idx].user_ids.concat(action.payload.user_ids);
        newData[idx].member_count += 1;
        state.data = newData;
      }
    },
  },
});

export default channelsSlice;
