import React from 'react';
import PropTypes from 'prop-types';

class LocationSubscriberComponent extends React.Component {
  componentDidMount() {
    this.callAllHandlers();
  }

  componentDidUpdate(prevProps) {
    const { location_code } = this.props;
    const { location_code: prev_location_code } = prevProps;

    if (prev_location_code !== location_code) {
      this.callAllHandlers();
    }
  }

  callAllHandlers = () => {
    const { onChangeHandlers } = this.props;

    for (let i = 0; i < onChangeHandlers.length; i += 1) {
      onChangeHandlers[i]();
    }
  };

  render() {
    return <React.Fragment />;
  }
}

LocationSubscriberComponent.propTypes = {
  location_code: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChangeHandlers: PropTypes.arrayOf(PropTypes.func).isRequired,
};

export default LocationSubscriberComponent;
