import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dateFormat: {
    id: 'ShiftRowComponent.dateFormat',
    defaultMessage: 'mm/dd/yyyy;',
  },
  startTimeToEndTime: {
    id: 'ShiftRowComponent.timeFormat',
    defaultMessage: 'start time; end time;',
  },
  vto: {
    id: 'PostShiftComponent.vto',
    defaultMessage: 'Voluntary Time Off',
  },
  vtoDescription: {
    id: 'PostShiftComponent.vtoDescription',
    defaultMessage: 'Post Voluntary Time Off (VTO) offers to your team.',
  },
  openShift: {
    id: 'PostShiftComponent.openShift',
    defaultMessage: 'Open Shift',
  },
  openShiftDescription: {
    id: 'PostShiftComponent.openShiftDescription',
    defaultMessage: 'Post open shifts for your team to fill.',
  },
  shiftCountProgress: {
    id: 'PostShiftComponent.shiftCountProgress',
    defaultMessage:
      'You can post up to {maxNumberOfShifts} offers in a batch. This batch currently contains {currentNumberOfShifts} offers. You may add {numberOfShiftsLeft} more.',
  },
  leaveConfirmation: {
    id: 'PostShiftComponent.areYouSure',
    defaultMessage:
      'Are you sure you want to leave this page? The offers you have created will not be posted or saved.',
  },
  changeTypeConfirmation: {
    id: 'PostShiftComponent.areYouSureAboutType',
    defaultMessage:
      'Are you sure you want to change the type of offer? The offers you have created will not be posted or saved.',
  },
});

export default messages;
