import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

import LocationSubscriber from 'components/GeneralComponents/LocationSubscriber';
import { dashboardShapes } from '../duck';
import { FormattedMessage, injectIntl, defineMessages} from 'react-intl';
import RecentRequest from './RecentRequest';
import RequestTimeOff from './RequestTimeOff';
import { getShiftApplicants,} from 'helpers/api-calls/shift-calls';
import { PENDING_SHIFTS_PER_PAGE, MAX_LOADING_TIMES } from 'constants/index';

import styled from './styled';


class RecentRequestsComponent extends React.Component {
  constructor(props){
    super(props);

    this.state = {
                  validShifts: [],
                  shiftsApplicants: [],
                  currentLocationId: '',
                  loadingDate: false,
                  timer: null,
                  sortedRTOs: [],
                  }

    this._isMounted = false;
    this.removeList = [];
    this.calledList = [];
    this.calledFetchPendingShifts = false;
    this.timeOutCount = 0;
    this.startTime = moment();
    this.endTime = moment();
    this.pageIndex = 1;

  }

  componentDidMount() {
    const {loading, 
      pendingShifts, 
      handleShiftsChange, 
      fetchShiftApplicants, 
      allEmployees,
      fetchPendingShifts, 
      shiftsWithApplicants,
      fetchFailed,
      resetFetchFailedFlag,
      requestTimeOffs,
      totalRTOs,
      skills,
      channels,
      costCenterData,
      departments,
      myDepartments,
      locationSettings,
      userPrivileges,
      deletedGroupId,
      } = this.props;
    const {validShifts, } = this.state;

    this._isMounted = true;

    if(pendingShifts) {
      const noDuplicateShifts = uniqBy(pendingShifts, 'id'); // Remove duplicates\
      this.setState({validShifts: []});
      let shifts = [];
      for(let i=0; i<noDuplicateShifts.length; i++) {
        if(this.isValidShiftToShow(noDuplicateShifts[i])) {
          shifts.push(noDuplicateShifts[i]);
        }
      }
      this.setState({validShifts: shifts});
    }

    if(requestTimeOffs) {
      if(locationSettings.cfg__departments === 1) {
        let rtos = [];
        for(let j=0; j<requestTimeOffs.length; j++) {
          if(this.isTheRTOInMyDepartments(requestTimeOffs[j])) {
            rtos.push(requestTimeOffs[j]);
          }
        }
        this.sortRTOWithNullValues(rtos, true);
        this.setState({sortedRTOs: rtos});
      }else {
        const copyRTOs = [...requestTimeOffs];
        this.sortRTOWithNullValues(copyRTOs, true);
        this.setState({sortedRTOs: copyRTOs});
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {loading, 
      pendingShifts, 
      handleShiftsChange, 
      fetchShiftApplicants, 
      allEmployees,
      fetchPendingShifts,
      fetchMorePendingShifts,
      locationId,
      shiftsWithApplicants,
      fetchFailed,
      resetFetchFailedFlag,
      requestTimeOffs,
      totalRTOs,
      departments,
      myDepartments,
      locationSettings,
      userPrivileges,
      deletedGroupId,
      } = this.props;
    const {validShifts, shiftsApplicants, currentLocationId, } = this.state;

    if(currentLocationId !== locationId){
      this.calledFetchPendingShifts = false;
      this.setState({currentLocationId: locationId});
    }

    if(!this.calledFetchPendingShifts) {
      fetchPendingShifts();
      this.calledFetchPendingShifts = true;
      this.calledList = [];
      this.pageIndex = 1;
    }

    if(prevProps.requestTimeOffs !== requestTimeOffs) {
      if(locationSettings.cfg__departments === 1) {
        let rtos = [];
        for(let j=0; j<requestTimeOffs.length; j++) {
          if(this.isTheRTOInMyDepartments(requestTimeOffs[j])) {
            rtos.push(requestTimeOffs[j]);
          }
        }
        this.sortRTOWithNullValues(rtos, true);
        this.setState({sortedRTOs: rtos});
      }else {
        const copyRTOs = [...requestTimeOffs];
        this.sortRTOWithNullValues(copyRTOs, true);
        this.setState({sortedRTOs: copyRTOs});
      }
    }

    if(prevProps.userPrivileges !== userPrivileges) {
      if(userPrivileges.length > 0) {
        if(locationSettings.cfg__departments === 1) {
          let rtos = [];
          for(let j=0; j<requestTimeOffs.length; j++) {
            if(this.isTheRTOInMyDepartments(requestTimeOffs[j])) {
              rtos.push(requestTimeOffs[j]);
            }
          }
          this.sortRTOWithNullValues(rtos, true);
          this.setState({sortedRTOs: rtos});
        }else {
          const copyRTOs = [...requestTimeOffs];
          this.sortRTOWithNullValues(copyRTOs, true);
          this.setState({sortedRTOs: copyRTOs});
        }
      }
    }

    if(prevProps.pendingShifts !== pendingShifts) {
      const noDuplicateShifts = uniqBy(pendingShifts, 'id'); // Remove duplicates\
      if(noDuplicateShifts) {
        this.setState({validShifts: []});
        let shifts = [];
        for(let i=0; i<noDuplicateShifts.length; i++) {
          if(this.isValidShiftToShow(noDuplicateShifts[i])) {
            shifts.push(noDuplicateShifts[i]);
          }
        }
        this.setState({validShifts: shifts});
        // load more pending shifts if any, per page PENDING_SHIFTS_PER_PAGE, if new coming shifts length = PENDING_SHIFTS_PER_PAGE, means there are more to load
        let msg = '';
        if(pendingShifts.length > prevProps.pendingShifts.length) {
          if(pendingShifts.length - prevProps.pendingShifts.length === PENDING_SHIFTS_PER_PAGE && this.pageIndex < MAX_LOADING_TIMES) {
            msg = `Dash: last page: ${this.pageIndex}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Total ${pendingShifts.length}. There might be more to load.`;
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
          }else {
            if(this.pageIndex === 1) {
              msg = `Dash: last page: ${this.pageIndex}, Total ${pendingShifts.length}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Less than ${PENDING_SHIFTS_PER_PAGE}. Try page 2.`;
              this.pageIndex += 1;
              fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
            }else {
              msg = `Dash: last page: ${this.pageIndex}, Total ${pendingShifts.length}, loaded ${pendingShifts.length - prevProps.pendingShifts.length} shifts this time. Less than ${PENDING_SHIFTS_PER_PAGE}. No more loading.`;
            }
          }
        }else if(pendingShifts.length === prevProps.pendingShifts.length) {
          if(this.pageIndex === 1) {
            msg = `Dash: loaded shifts ${pendingShifts.length} are the same as previous shifts. Try to load page 2.`;
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
          }else {
            msg = `Dash: loaded page ${this.pageIndex}, shifts: ${pendingShifts.length}, no increasing. No more loading.`;
          }
        }else {
          if(this.pageIndex === 1) {
            this.pageIndex += 1;
            fetchMorePendingShifts(this.pageIndex, PENDING_SHIFTS_PER_PAGE);
            msg = `Dash: loaded shifts ${pendingShifts.length} less than previous shifts ${prevProps.pendingShifts.length}. Could be location changed or page changed. Try to load page 2.`;
          }else {
            msg = ``;
          }
        }

        if(this.pageIndex === MAX_LOADING_TIMES) {
          msg = `Dash: max loading time ${MAX_LOADING_TIMES} reached. Stop loading.`;
          this.stopLoading = true;
        }

        console.log(msg);
      }
    }
   
    if(prevProps.deletedGroupId !== deletedGroupId) {
      const { validShifts } = this.state;
      
      let localCopy = [...validShifts];
      if(localCopy && localCopy.length > 0) {
        for(let i=0; i<localCopy.length; i++) {
          if(localCopy[i].channel_id === deletedGroupId) {
            localCopy.splice(i, 1);
            i -= 1;
          }
        }
        console.log(localCopy.length);
        this.setState({validShifts: localCopy});
      }
    }
  }

  componentWillUnmount() {
    const { timer} = this.state;
    this._isMounted = false;
    if(timer) {
      clearInterval(this.state.timer);
    }
  }

  tick = () => {
    const {loadingData} = this.state;
    if(loadingData) {
      this.timeOutCount += 1;
      //if unexpected happens, reset the flag in 30 seconds.
      if(this.timeOutCount > 30) {
        this.timeOutCount = 0;
        this.setState({loadingData: false});
        const msg = `loadingData set to false by timer.`;
        console.log(msg);
        clearInterval(this.state.timer);
      }
    }
  }

  hasSegments = (id) => {
    const {pendingShifts,} = this.props;
    const {validShifts,} = this.state;

    //const shift = pendingShifts.find((item) => item.id === id);
    const shift = validShifts.find((item) => item.id === id);

    return Object.prototype.hasOwnProperty.call(shift, 'segments');
  };

  isTheShiftInMyDepartments = (shift) => {
    const { departments, myDepartments, } = this.props;

    if(myDepartments && myDepartments.length > 0) {
      for(let i=0; i<myDepartments.length; i++) {
        if(myDepartments[i].user_ids && myDepartments[i].user_ids.length > 0) {
          for(let j=0; j<myDepartments[i].user_ids.length; j++) {
            if(shift.taker_id === myDepartments[i].user_ids[j]) {
              //const msg = `Shift id: ${shift.id}, taker Id: ${shift.taker_id} in departments`;
              //console.log(msg);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

   isTheRTOInMyDepartments = (rto) => {
    const { departments, myDepartments, userPrivileges, } = this.props;
    if(myDepartments && myDepartments.length > 0) {
      for(let i=0; i<myDepartments.length; i++) {
        if(myDepartments[i].user_ids && myDepartments[i].user_ids.length > 0) {
          for(let j=0; j<myDepartments[i].user_ids.length; j++) {
            if(rto.user_id === myDepartments[i].user_ids[j]) {
              //admin and RTO requester in the same dept
              if(userPrivileges.length > 0) {
                for(let k=0; k<userPrivileges.length; k++) {
                  if(userPrivileges[k].owner_id === rto.user_id) {
                    if(userPrivileges[k].department_ids && userPrivileges[k].department_ids.length === 0) {
                      // case 4: admin dept yes, RTO requester dept none
                      return false;
                    }else if(userPrivileges[k].department_ids.length > 0) {
                      //case 1:  admin and RTO requester belong to same department and request dept yes
                      return true;
                    }
                  }
                }
              }
            }
          }
          //case 2, 4: admin dept yes, RTO requester is not in admin dept
          //return false;
          continue;
        }
        //case 2, 4: admin dept yes, RTO requester is not in admin dept
        return false;
      }
    }else {
      if(userPrivileges.length > 0) {
        for(let k=0; k<userPrivileges.length; k++) {
          if(userPrivileges[k].owner_id === rto.user_id) {
            if(userPrivileges[k].department_ids && userPrivileges[k].department_ids.length === 0) {
              //case 3: both admin and requester do not belong to any department
              return true;
            }else if(userPrivileges[k].department_ids.length > 0) {
              //case 5: admin dept none, requester dept yes
              //const msg = `Current user no department but RTO requester ${rto.first_name} ${rto.last_name} belongs to department`;
              //console.log(msg);
              return true;
            }
          }
        }
        return false;
      }else {
        return false;
      }
    }
  }

  isValidShiftToShow = (shift) => {
    const { locationSettings, } = this.props;

    //if cover mode = 2 (multi_applicants_picked_by_manager), rendering shifts regardless trade status
    if(shift.cover_mode === 'multi_applicants_picked_by_manager' ||
        (shift.cover_mode !=='multi_applicants_picked_by_manager' && shift.trade_status === 'pending')){
      const startDateTime = moment(shift.start_at);
      let current = moment();
      let duration = moment.duration(startDateTime.diff(current));
      let minutes = parseInt(duration.asMinutes());
      //if starting time is less than 5 min from now, consider it is expired
      if(minutes < 5){
        //const msg = `It is ${current}, shift ${shift.id} start time is ${startDateTime}, ${minutes} mins left.`;
        //console.log(msg);
        return false;
      }
      if(!shift.parent_card){
        //Fixing bug WS-1499
         /* Waiting for decision 2022.01.06
          that function as design, once a shift is approved, which is kind of in locked status, 
          no more applicant actions will be accepted.  
          @Etty Burstein can you verify with design team, is it necessary for manger to deny other applicant,
          when a team shift(single slot) was approved?
          */
        //if(shift.trade_status === 'approved' || shift.trade_status === 'rejected') {
        if(shift.trade_status === 'rejected') {
          //const msg = `B * id: ${shift.id}, parent: ${shift.parent_card}, status: ${shift.trade_status}`;
          //console.log(msg);
          return false;
        }
        if(!shift.coverer){
          if(shift.cover_mode !== 'multi_applicants_picked_by_manager') {
            //const msg = `No coverer. id: ${shift.id}, parent: ${shift.parent_card}, status: ${shift.trade_status}`;
            //console.log(msg);
            return false;
          }
        }
      }
      //const msg = `Pass * id: ${shift.id}, mode: ${shift.cover_mode} parent: ${shift.parent_card}, status: ${shift.trade_status}`;
      //console.log(msg);
      return true;
    }else {
      //const msg = `A * id: ${shift.id}, mode: ${shift.cover_mode}, status: ${shift.trade_status}`;
      //console.log(msg);
      return false;
    }
  };

  getApplicantsForShift = (shiftId) => {
    const {shiftsApplicants} = this.state;
    if(shiftsApplicants && shiftsApplicants.length) {
      const idx = shiftsApplicants.findIndex((element) => element.id === shiftId);
      if(idx < 0) {
        return null;
      }
      return shiftsApplicants[idx];
    }
    return null;
  }

  sortRTOWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.start_date === b.start_date) // identical? return 0
        return 0;
      else if (a.start_date === null)  // a is null? last 
        return 1;
      else if (b.start_date === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        //return a.user.first_name.localeCompare(b.user.first_name) * multiplier;
        return new Date(a.start_date) - new Date(b.start_date)
      }                  
    }
    return arr.sort(sorter);
  }

  render() {
    const {loading, 
          loadLess, 
          pendingShifts,
          approvePendingShift,
          denyPendingShift,
          fetchPendingShifts,
          fetchPendingShiftSegments,
          fetchShiftApplicants,
          toggleConfirmationSheet,
          allEmployees,
          locationConfig,
          requestTimeOffs,
          totalRTOs,
          approveRequestTimeOff,
          denyRequestTimeOff,
          userPrivileges,
          fecthFailed,
          userIsAdmin,
          locationSettings,
          skills,
          channels,
          costCenterData,
        } = this.props;
    const {shiftsApplicants, validShifts, loadingData, sortedRTOs, } = this.state;

    if (loadingData) {
      return (
        <styled.ShiftRequestsLoadingLayer>
          <img src="/loader.gif" height="75" width="75" alt="Loading" />
        </styled.ShiftRequestsLoadingLayer>
      );
    }

    //const shifts = pendingShifts.map((shift) => (
    const shifts = validShifts.map((shift) => (
      <RecentRequest
        key={shift.id}
        locationConfig={locationConfig}
        shift={shift}
        hasSegments={this.hasSegments}
        userIsAdmin={userIsAdmin}
        userPrivileges={userPrivileges}
        skills={skills}
        locationSettings={locationSettings}
        channels={channels}
        fetchShiftApplicants={fetchShiftApplicants}
        util={{
          fetchPendingShiftSegments,
          //hasSegments,
          handleApprove: approvePendingShift,
          handleDeny: denyPendingShift,
          toggleConfirmationSheet,
        }}
      />
    ));


    //const rtos = requestTimeOffs.map((rto) => (
    const noDuplicated = uniqBy(sortedRTOs, 'id');
    const rtos = noDuplicated.map((rto) => (
      <RequestTimeOff
      key={rto.id}
      rto={rto}
      approveRequestTimeOff={approveRequestTimeOff}
      denyRequestTimeOff={denyRequestTimeOff}
      userPrivileges={userPrivileges}
      locationSettings={locationSettings}
      costCenterData={costCenterData}
      totalRTOs={totalRTOs}
      />
    ));
    //}
    
    return (
      <React.Fragment>
        {rtos}
        {shifts}
        {/*<LocationSubscriber onChangeHandlers={[fetchPendingShifts]} />*/}
      </React.Fragment>
    );
    
  }
}

RecentRequestsComponent.propTypes = {
  approvePendingShift: PropTypes.func.isRequired,
  denyPendingShift: PropTypes.func.isRequired,
  fetchPendingShifts: PropTypes.func.isRequired,
  fetchPendingShiftSegments: PropTypes.func.isRequired,
  toggleConfirmationSheet: PropTypes.func.isRequired,
  pendingShifts: PropTypes.arrayOf(dashboardShapes.shiftShape).isRequired,
  locationConfig: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  fetchShiftApplicants: PropTypes.func.isRequired,
};

RecentRequestsComponent.defaultProps = {
  locationConfig: {},
};

export default injectIntl(RecentRequestsComponent);
