import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import ls from 'helpers/localStorage';
import reducer from '../reducers/reducer';

const sagaMiddleware = createSagaMiddleware();
// const persistedState = ls.loadState();
const persistedState = {};

export default function configureStore() {
  return createStore(
    reducer,
    persistedState,
    composeWithDevTools(applyMiddleware(thunk, sagaMiddleware))
  );
}
