import styled from 'styled-components/macro';
import { TextInput } from '@shyft/acorns';

const ProfileWrap = styled.div`
  display: flex;

  p {
    font-size: 0.88rem;
    margin-bottom: 0;
  }
`;

const ThumbWrap = styled.div`
  margin-right: 0.75rem;
`;

const InfoWrap = styled.div`
  flex: 1;
  padding-top: 20px;
`;

const UserName = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  font-weight: 600;
`;

const UserPosition = styled.p`
  padding-left: 14px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
`;

const Input = styled(TextInput)``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 3rem;
  margin-right: 3rem;
`;

const BodyText = styled.p`
  margin-bottom: 0;
`;

const ActionWrap = styled.div`
  margin: 0 1.5rem;
  display: flex;
`;
const SkillInfoWrap = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 12px;
  //border: 1px solid red;
`;
const SkillNameWrap = styled.div `
  display: flex;
  flex-direction: row;
`;
const SkillName = styled.label `
  color: black;
  background-color: rgba(242,242,248,255);
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  margin-right: 8px;
`;

const RemovePhotoButton = styled.button `
  width: 75px;
  font-size: 14px;
  font-weight: 500;
  color: red;
`;

export default {
  Wrapper,
  BodyText,
  ActionWrap,
  ProfileWrap,
  ThumbWrap,
  InfoWrap,
  UserName,
  UserPosition,
  Input,
  SkillInfoWrap,
  SkillNameWrap,
  SkillName,
  RemovePhotoButton,
};
