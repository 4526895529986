import ColumnWrap from './ColumnWrap';
import ScrollWrap from './ScrollWrap';
import Heading from './Heading';
import Loading from './Loading';

export default {
  ColumnWrap,
  Heading,
  ScrollWrap,
  Loading,
};
