import React from 'react';
import { Link } from 'react-router-dom';

import { ProfileThumb } from '@shyft/acorns';
import {
  CellWrap,
  ThumbWrap,
  InfoWrap,
  UserName,
  UserPosition,
  ProfileLink,
} from './styled';
import { teamShapes, teamConstants } from '../../duck';

const UserCell = ({
  user: {
    id,
    first_name,
    last_name,
    position,
    profile_image_thumb_url,
    status,
  },
}) => {
  const { statusTypes } = teamConstants;
  const allowProfile =
    status !== statusTypes.REMOVED &&
    status !== statusTypes.REJECTED &&
    status !== statusTypes.WITHDRAWN &&
    status !== statusTypes.BLOCKED;
  const UserContent = (
    <React.Fragment>
      <ThumbWrap>
        <ProfileThumb thumbUrl={profile_image_thumb_url} width="40px" />
      </ThumbWrap>
      <InfoWrap>
        <UserName>{`${first_name} ${last_name}`}</UserName>
        {position ? <UserPosition>{position}</UserPosition> : null}
      </InfoWrap>
    </React.Fragment>
  );

  return (
    <CellWrap>
      {allowProfile ? (
        <ProfileLink to={`/team/${id}`}>{UserContent}</ProfileLink>
      ) : (
        UserContent
      )}
    </CellWrap>
  );
};

UserCell.propTypes = {
  user: teamShapes.userShape,
};

export default UserCell;
