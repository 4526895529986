import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import CostCenter from './CostCenter';

import * as styled from './styled';

class CostCenterComponent extends React.Component {
	constructor(props){
    super(props);
    this.state = {
                  tags: [],
                 
                  };

   
    
  }

  componentDidMount() {
    const {location, costCenterData} = this.props;

    if(costCenterData) {
      const idx = costCenterData.tag_groups.findIndex(
        (group) => group.group_type === 'xpo_cost_work_centers'
      );
      if(idx >= 0) {
        this.setState({tags: costCenterData.tag_groups[idx].tags});
      }
    }

  }

  componentDidUpdate(prevProps) {
    const {location, costCenterData} = this.props;

    if(prevProps.costCenterData !== costCenterData){
      console.log('changed');
    }

  }

  handleMenuItemClick = (tagKey, tagContent) => {
    const {handleMenuItemClick} = this.props;

    //parent callback
    handleMenuItemClick(tagKey, tagContent);
  }

  renderCostCenterTags = () => {
    const {location} = this.props;
    const {tags} = this.state;

    const menuItems = tags.map((tag, index) =>(
      <CostCenter
          key={tag.id}
          tag={tag}
          handleMenuItemClick={this.handleMenuItemClick}
        />
    ));
    return menuItems;
  }

  render() {
    const {top, left, intl} = this.props;
    const {tags,} = this.state;

    return(
      <styled.WinContainer top={top} left={left}>
        {this.renderCostCenterTags()}
      </styled.WinContainer>
    );
  }

}

CostCenterComponent.propTypes={
  
}

export default injectIntl(CostCenterComponent);