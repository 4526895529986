import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { rem } from 'polished';

const Text = styled.p`
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0;
  flex: 1;

  ${applyStyleModifiers({
    large: () => `
      font-size: ${rem('14px')};
      color: inherit;
    `,
  })}
`;

export default Text;
