import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { teamShapes, teamConstants } from '../../duck';
import { default as Styled } from './styled';

const TeamFiltersComponent = ({ activeFilter, filters, updateFilter, loading }) => {
  const { statusTypes } = teamConstants;
  const handleOnChange = (e) => {
    if(!loading) {
      updateFilter(e.target.value);
    }
  };

  const returnFilterString = (filter) => {
    switch (filter) {
      case statusTypes.ACTIVE:
        return (
          <FormattedMessage
            id="TeamFilters.activeLabel"
            defaultMessage="Active"
          />
        );
      case statusTypes.PENDING:
        return (
          <FormattedMessage
            id="TeamFilters.pendingLabel"
            defaultMessage="Pending"
          />
        );
      case statusTypes.REMOVED:
        return (
          <FormattedMessage
            id="TeamFilters.removedLabel"
            defaultMessage="Removed"
          />
        );
      default:
        break;
    }
  };

  const renderFilters = () =>
    Object.keys(filters).map((filter) => (
      <Styled.FilterWrap key={filter}>
        <Styled.FilterLabel>
          <Styled.FilterRadioBtn
            value={filter}
            checked={activeFilter === filter}
            onChange={handleOnChange}
          />
          {returnFilterString(filter)}
        </Styled.FilterLabel>
        {filters[filter].count > 0 ? (
          <Styled.FilterBadge>{filters[filter].count}</Styled.FilterBadge>
        ) : null}
      </Styled.FilterWrap>
    ));

  return <div>{renderFilters()}</div>;
};

TeamFiltersComponent.propTypes = {
  activeFilter: teamShapes.filterTypes.isRequired,
  filters: teamShapes.filtersShape.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default TeamFiltersComponent;
