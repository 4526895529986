import styled from 'styled-components/macro';

import { Checkbox } from '@shyft/acorns';

const HeaderWrap = styled.div`
  background-color: white;
  padding: 1.5rem 3rem;

  @media (max-width: 380px) {
    padding: 0.5rem;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
`;

const SearchInfo = styled.p`
  font-weight: 400;
  color: ${(props) => props.theme.palette.label};
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};
`;

const SearchDetail = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.palette.text};
`;

const LeaveSearch = styled.button.attrs(() => ({
  type: 'button',
}))`
  /* Reset stuff */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* Custom styles */
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    color: ${(props) => props.theme.palette.primary[0]};
  }
`;

const BackArrow = styled.span`
  display: inline-block;
  width: 10px;
  height: 15px;
  background-image: url(${process.env.PUBLIC_URL}/icons/back-arrow.svg);
  background-size: cover;
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
`;

const SelectAll = styled(Checkbox)`
  > span {
    font-size: 0.5rem;
  }
`;

export default {
  HeaderWrap,
  SearchInfo,
  SearchDetail,
  LeaveSearch,
  BackArrow,
  HeaderRow,
  SelectAll,
};
