import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@mdi/react';
import { mdiPin } from '@mdi/js';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import { appShapes } from 'App/duck';
import { teamFeedShapes } from '../../duck';
import NewPostInput from './new-post-input';
import { default as PostStyled } from './styled';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import blocks from '../../blocks';
import elements from '../../elements';
import {UploadImage, UnfurlURL, UploadPDF, UploadPDF2, UploadPDF3, UploadPDF4} from '../../../../helpers/api-calls/feed-calls';
import { getUserId } from '../../../../helpers/cookies';

import PDFComponent from './PDFComponent';


const sha1 = require('js-sha1');


class PostsComponent extends React.Component {
  constructor(props){
    super(props);

    this.state={userInput: '', 
                enterKeyPressed: false, 
                currentChannel: -1, 
                textAreaHeight: 30,
                checkedAnnouncement: false,
                imageDataUrl: '',
                imageDataUrlList: [],
                imageInfoList: [],
                detectedUrl: '',
                previews: [],
                userInput2: '',
                contentWithUrl: '',
                localPosts: [],
                previewPDFs: [],
                pdfIdList: [],
                finalAllowPost: 0,
              };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this); 
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleReaderLoaded = this.handleReaderLoaded.bind(this);
    this.handlePreviewChange = this.handlePreviewChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.fileInput = React.createRef();
    this.textInput = React.createRef();
    this.imagePreView = React.createRef();
    this.previewFrame = React.createRef();
    this.previewInput = React.createRef();
    this.pdfPreview = React.createRef();

    this.imgUrlListIndex = 0;
    this.isChrome = false; 
    this.urlDetected = false;
    this.detectedUrl = '';
    this.startTime = 0;
    this.endTime = 0;
    this.activePost = null;
    this._isMounted = false;
    this.pdfData = '';
    this.pdfUrlListIndex = 0;
    this.allImagesUploaded = false;
    this.allPDFsUploaded = false;
  
   
    this.pubnub = props.pubnub;
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;
    
  }

  messages = defineMessages({
    pressKeyPrompt: {
      id: 'PostComponent.pressKeysSubmit',
      defaultMessage: "Press CTRL + Enter to submit",
    },
    chooseFile: {
      id: 'PostComponent.chooseFile',
      defaultMessage: "Choose File",
    },
    noFileChosen: {
      id: 'PostComponent.noFileChosen',
      defaultMessage: "No file chosen",
    },
    maxFilesExceeded:{
      id: 'PostComponent.maxFilesExceeded',
      defaultMessage: "Cannot upload more than {maxFiles} files at one time.",
    }
  });

  acceptedTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];

  isValidFileType(fileType){
    return this.acceptedTypes.includes(fileType);
  };


  isAnnouncement(post_type) {
    return [1, 2, 3, 4].includes(post_type);
  }

  containUrl(text){
    var s;
    var index1, index2, index3, index4;
    
    if(!text){
      return false;
    }
    s = text.toLowerCase();
    if(s.length < 5){
      return false;
    }
    index1 = s.indexOf("http");
    if(index1 < 0){
      return false;
    }
    index2 = s.indexOf(":");
    if(index2 <= 0){
      return false;
    }else{
      if(index2 < index1){
        let charToCheck; // "Please visit : https://www......,looking for ":" after "http"
        charToCheck = s.charAt(index1 + 3 + 1); // check char after 'http'
        if(charToCheck === 's'){
          charToCheck = s.charAt(index1+ 3 + 2) // check char after 'https'
          if(charToCheck === ':'){
            index2 = index1 + 3 + 2;
          }
        }else if(charToCheck === ':'){
          index2 = index1 + 3 + 1;
        }else{
          return false;
        }
      }
    }
    index3 = s.indexOf("//");
    if(index3 <= 0){
      return false;
    }
    index4 = s.indexOf(".");
    if(index4 <= 0){
      return false;
    }
    if(index4 <= index3 || index3 <= index2 || index2 <= index1){
      return false;
    }

    return true;
  }

  extractUrl(text) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    let start, end;
    let s;

    if(!text){
      return null;
    }
    s = text.toLowerCase();
    if(!this.containUrl(s)){
      return null;
    }
    var url = s.match(urlRegex)[1];
    if(url){
      const lastChar = url.charAt(url.length-1);
      if(lastChar === ',' || lastChar === '.'){
        url = url.slice(0, -1); // remove last char
      }
      var numberOfLineBreaks = (url.match(/\n/g)||[]).length;
      if(numberOfLineBreaks > 0) {
        var splitted = url.split("\n");
        if(splitted.length > 0) {
          url = splitted[0];
        }
      }
      return url;
    }else{
      return null;
    }
  }

  dropRef = React.createRef();

  componentDidMount() {
    const {
      fetchPosts,
      active: { channel, subscriptions },
      setActivePost,
      activeChannel,
      posts,
      fetchSubscriptions,
      allowCreatePost,
    } = this.props;

    this._isMounted = true;

    //console.log('calling pubnubSubscribe');
    this.pubnubSubscribe();

    if(posts.loading && channel) {
      setActivePost(0, '');
      fetchPosts(channel, 1);
      fetchSubscriptions(channel);
    }

    if(!posts.loading){
      if(posts.data.length > 0 && this.state.localPosts.length === 0){
        this.setState({localPosts: posts.data});   
      }
    }
    this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ? true : false;

    if(subscriptions) {
      this.setState({finalAllowPost: subscriptions.final_allow_post});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.pubnub.removeListener();
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
    //console.log('called unsubscribe');

  }

  componentDidUpdate(prevProps) {
    const {
      active: { channel, subscriptions },
      posts,
    } = this.props;
    const {
      active: { channel: prevChannel, subscriptions: prevSubscriptions },
    } = prevProps;

    if (prevChannel !== channel) {
      const { fetchPosts, fetchSubscriptions } = this.props;
      fetchPosts(channel);
      fetchSubscriptions(channel);

      // reset unsend post/announcement
      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      if(this.fileInput.current){
        this.fileInput.current.value = '';
      }
      this.setState({previews: []});
      this.setState({imageDataUrl: ''});
      this.setState({imageDataUrlList: []});
      this.setState({imageInfoList: []});
      this.setState({checkedAnnouncement: false});
      this.setState({contentWithUrl: ''});
      this.setState({pdfIdList: []});
      this.imgUrlListIndex = 0;
    }

    const height = document.getElementById('columnWrap').clientHeight;

    // the state 'localPosts' is used to prevent re-render all comments when new comment added
    if(prevProps.posts !== posts) {
      //This section of code causes posts for each channel messed up WS-1394
      /* 
      if(posts.data.length > 0){
        if(this._isMounted){
          this.setState({localPosts: posts.data});
        }
      }else{
        if(prevProps.posts.data.length === 1){
          if(this._isMounted){
            this.setState({localPosts: posts.data});
          }
        }
      }
      */
      this.setState({localPosts: posts.data});
    }

    if(prevSubscriptions !== subscriptions) {
      if(subscriptions) {
        this.setState({finalAllowPost: subscriptions.final_allow_post});
      }
    }
  }

   pubnubSubscribe = () => {
    const { userID,
            fetchPosts,
            handleUpdateLikePost, 
            handleUpdateUnlikePost,
            handleUpdatePinPost,
            handleUpdateCommentCount,
            handleUpdateDeletePost,
            handleUpdateDeleteActivePost,
            handleDeleteChannel,
            getLocationChannels,
            locationId,
          } = this.props;

    var usedNewCommentId = -1;
    var usedDeleteCommentId = -1;
    
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'like' &&
          event.message.crud === 'NEW'
        ) {
          handleUpdateLikePost(event.message.node_id);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'like' &&
          event.message.crud === 'DELETE'
        ) {
          handleUpdateUnlikePost(event.message.node_id);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'UPDATE'
        ) {
            handleUpdatePinPost(event.message.node_id, event.message.meta.pinned);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'NEW'
        ) {
            fetchPosts(event.message.tree_id);
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'post' &&
          event.message.crud === 'DELETE'
        ) {
            if(this.activePost && this.activePost.id === event.message.node_id){
              handleUpdateDeleteActivePost(event.message.node_id);
            }else{
              handleUpdateDeletePost(event.message.node_id);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'NEW'
        ) {
            if(event.message.leaf_id !== usedNewCommentId){
              //console.log('+1. current Id = ' + event.message.leaf_id + '  previous Id = ' + usedNewCommentId);
              usedNewCommentId = event.message.leaf_id;
              handleUpdateCommentCount(event.message.node_id, event.message.leaf_id, true);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'comment' && 
          event.message.crud === 'DELETE'
        ) {
            if(event.message.leaf_id !== usedDeleteCommentId){
              usedDeleteCommentId = event.message.leaf_id;
              handleUpdateCommentCount(event.message.node_id, event.message.leaf_id, false);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' && 
          event.message.crud === 'DELETE'
        ) {
            if(event.message.tree_id){
              handleDeleteChannel(event.message.tree_id);
            }
        }

        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' && 
          event.message.crud === 'NEW'
        ) {
            if(event.message.tree_id){
              getLocationChannels(locationId);
            }
        }
      },

      objects: (event) => {
        //alert('recevied objects');
      },
      messageAction: (event) => {
        //alert('recevied message action');
      },
      status: (event) => {
        //alert('received status');
      },
      presence: (event) => {
        //alert('received presence');
      },
      signal: (event) => {
        //alert('received signal');
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
  };


  autoExpand = function (field) {

    // Reset field height
    field.style.height = 'inherit';
    // Get the computed styles for the element
    var computed = window.getComputedStyle(field);
    // Calculate the height
    var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
               + parseInt(computed.getPropertyValue('padding-top'), 10)
               + field.scrollHeight
               + parseInt(computed.getPropertyValue('padding-bottom'), 10)
               + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';
  };


  handleScrollLoad = () => {
    const {
      active: { channel },
      fetchMorePosts,
      posts: { page, totalPages },
    } = this.props;

    if (page !== totalPages) {
      fetchMorePosts(channel, page + 1);
    }
  };

  handleChange(event) {
    this.setState({userInput: event.target.value});
    this.autoExpand(event.target);
    event.preventDefault();
  }

  handlePreviewChange(event) {
    event.preventDefault();
    this.setState({userInput2: event.target.value});
    this.previewInput.current.focus();
  }

  handleImageChange(event){
    const { intl} = this.props;
    const MAX_NUM_FILES = 10;
    
    if(event.target.files.length === 0){
      return;
    }
    if(event.target.files.length + this.state.previews.length > MAX_NUM_FILES){
      const msg=intl.formatMessage(this.messages.maxFilesExceeded,{maxFiles: MAX_NUM_FILES});
      alert(msg);
      this.fileInput.current.value = '';
      return;
    }
    for(let i=0; i<event.target.files.length; i++){
      if (!this.isValidFileType(event.target.files[i].type)) {
          alert('Only images are allowed (png or jpg)');
          this.fileInput.current.value = '';
          return;
      }
      if(event.target.files[i].type === 'application/pdf') {
        let pdfObj = {name: '', size: 0, type: '', data: null, file: null, blob: null, dataUrl: '',};
        pdfObj.name = event.target.files[i].name;
        pdfObj.size = event.target.files[i].size/1000;
        pdfObj.type = event.target.files[i].type;
        pdfObj.file = event.target.files[i];
        pdfObj.blob = URL.createObjectURL(event.target.files[i]);
        
        let {previewPDFs} = this.state;
        previewPDFs.push(pdfObj);
        this.setState({previewPDFs: previewPDFs});
      }else {
        let preview = URL.createObjectURL(event.target.files[i]);
        let { previews } = this.state;
        previews.push(preview); 
        this.setState({previews: previews})
      }
    }
    //this.setState({images: event.target.files});

    for(let i=0; i < event.target.files.length; i++){
      let reader = new FileReader();
      reader.onload = this.handleReaderLoaded;
      reader.readAsDataURL(event.target.files[i]); 
      //readAsDataURL is base64 encoding.Base64 encoded images are ~33% larger than the original file size.
    }
    /*
    if(this.state.previews.length === 0){
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }else if(this.state.previewPDFs.length === 0) {
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }else {
      if(this.previewInput.current) {
        this.previewInput.current.focus();
      }
    }
    */
    if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0) {
      if(this.previewInput.current) {
        this.previewInput.current.focus();
      }
    }else {
      this.textInput.current.focus(); // prevent file selection window from opening again when ctrl+enter pressed
    }
    event.preventDefault();
  }

  handleReaderLoaded(event){
    if(event.target.result.includes('application/pdf')){
      let localCopy = [...this.state.previewPDFs];
      if(localCopy.length > 0){
        for(let i=0; i<localCopy.length; i++){
          if(!localCopy[i].data){
            //localCopy[i].data = event.target.result;
            const idx = event.target.result.indexOf(',') + 1;
            localCopy[i].data = event.target.result.substring(idx);
            localCopy[i].dataUrl = event.target.result;
            break;
          }
        }
        this.setState({previewPDFs: localCopy});
      }
    }
    else{
      this.setState({imageDataUrl: event.target.result});
      this.setState({ imageDataUrlList: this.state.imageDataUrlList.concat(this.state.imageDataUrl)});
    }
    event.preventDefault();
  }

  

  handleSubmit(event) {
    const { activeChannel, createPost} = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;
    
    if(content){
      const url = this.extractUrl(content)
      if(url){
        this.urlDetected = true;
        this.setState({detectedUrl: url});
        this.detectedUrl = url;
        if(this.state.previews.length === 0){
          UnfurlURL(url).then((data) =>{
            console.log(data);
            this.succeedUnfurled(data);
          }).catch((error) =>{
            console.log(error);
            this.urlDetected = false;
            this.detectedUrl = '';
            this.setState({detectedUrl: ''});
            if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
              });
            }else{
              createPost({
                channel_id: activeChannel.id,
                title: 'Title',
                content,
              });
            }
          });
          this.setState({contentWithUrl: content});
        }
      }
    }

    if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0){
      if(this.state.previews.length > 0){
        this.imgUrlListIndex = 0;
        this.allImagesUploaded = false;
        this.startTime = new Date().getTime();
        UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
          this.succeededCallback, 
          this.failedCallback).then((data) => {
            if(data && data.image){
              this.succeededCallback(data);
            }
        }).catch((error)=>{
            console.log(error);
            this.failedCallback(error);
        });
        //this.setState({previews: []});
      }
      if(this.state.previewPDFs.length > 0) {
        this.pdfUrlListIndex = 0;
        this.allPDFsUploaded = false;
        UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
          this.succeededUploadPDF(data);
        }).catch((error) =>{
          console.log(error);
        });
      }
    }else{
      if(content){
        if(!this.urlDetected){
          if(this.state.checkedAnnouncement){
            createPost({
              channel_id: activeChannel.id,
              title: 'Announcement',
              content,
              post_type: 2,
            });
          }else{
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
            });
          }
        }
        this.setState({userInput: '',});
        this.setState({userInput2: '',});
        this.fileInput.current.value = '';
        this.setState({previews: []});
        this.setState({imageDataUrl: ''});
        this.setState({imageDataUrlList: []});
        this.setState({imageInfoList: []});
        this.setState({pdfIdList: []});
        //this.setState({checkedAnnouncement: false});
        this.imgUrlListIndex = 0;
        if(!this.urlDetected || this.state.previews.length > 0){
          this.setState({checkedAnnouncement: false});
        }
      }
    }
    event.preventDefault();
  }

  handleKeyPress(event){
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;
    
    if (event.keyCode === 13 && event.ctrlKey) {
      if(content){
        const url = this.extractUrl(content)
        if(url){
          this.urlDetected = true;
          this.setState({detectedUrl: url});
          this.detectedUrl = url;
          if(this.state.previews.length === 0){
            UnfurlURL(url).then((data) =>{
              console.log(data);
              this.succeedUnfurled(data);
            }).catch((error) =>{
              console.log(error);
              this.urlDetected = false;
              this.detectedUrl = '';
              this.setState({detectedUrl: ''});
              if(this.state.checkedAnnouncement){
                createPost({
                  channel_id: activeChannel.id,
                  title: 'Announcement',
                  content,
                  post_type: 2,
                });
              }else{
                createPost({
                  channel_id: activeChannel.id,
                  title: 'Title',
                  content,
                });
              }
            });
            this.setState({contentWithUrl: content});
          }
        }
      }

      if(this.state.previews.length > 0 || this.state.previewPDFs.length > 0){
        if(this.state.previews.length > 0) {
          this.imgUrlListIndex = 0;
          this.allImagesUploaded = false;
          UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
            this.succeededCallback, 
            this.failedCallback).then((data) => {
              if(data && data.image){
                this.succeededCallback(data);
              }
          }).catch((error)=>{
            console.log(error);
            this.failedCallback(errors);
          });
        }
        if(this.state.previewPDFs.length > 0) {
          this.pdfUrlListIndex = 0;
          this.allPDFsUploaded = false;
          UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
          this.succeededUploadPDF(data);
          }).catch((error) =>{
            console.log(error);
          });
        }
      }else{
        if(content){
          if(!this.urlDetected){
            if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
              });
            }else{
              createPost({
                channel_id: activeChannel.id,
                title: 'Title',
                content,
              });
            }
          }
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.setState({pdfIdList: []});
          this.imgUrlListIndex = 0;
        }
      }
      //this.setState({checkedAnnouncement: false});
      if(!this.urlDetected || this.state.previews.length > 0){
        this.setState({checkedAnnouncement: false});
      }
      event.preventDefault();
    } 
  }

   handleCheckboxChange(event){
    this.setState({ checkedAnnouncement: event.target.checked });
    // The line below will cause the problem that clicking twice to change state!!!
    //event.preventDefault();
   }

  handleDelete(event){
    //alert(event.target.nextSibling.currentSrc);
    event.preventDefault();
    
    if(this.state.previews.length > 0){
      var previewArray = [...this.state.previews]; // make a separate copy of the array
      var urlArray = [...this.state.imageDataUrlList]; // make a separate copy of the array
      var index = previewArray.indexOf(event.target.nextSibling.currentSrc);

      if(this.state.previews.length !== this.state.imageDataUrlList.length){
        console.log('previews.length !== imageDataUrlList.length');
      }

      if (index !== -1) {
        previewArray.splice(index, 1);
        this.setState({previews: previewArray});
        urlArray.splice(index, 1);
        this.setState({imageDataUrlList: urlArray});
      }
      this.previewInput.current.focus(); // prevent next preview icon shown selected
      return;
    }

    if(this.state.previewPDFs.length > 0) {
      let pdfArray = [...this.state.previewPDFs];
      const idx = pdfArray.findIndex((pdf) => pdf.name === event.target.nextSibling.children[1].firstChild.innerText);
      if (idx >= 0) {
        pdfArray.splice(idx, 1);
        this.setState({previewPDFs: pdfArray});
      }
      return;
    }
  }

   succeededCallback(data){
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;

    if(!data){
      return;
    }
    
    let temp = data.image.avatar_meta.slice(0, -1); // remove last char
    temp = temp.substring(1, temp.length);
    let pieces = temp.split(",");
    let w, h;
    if(pieces.length === 2){
      w = parseInt(pieces[0], 10);
      h = parseInt(pieces[1], 10);
    }
    

    let imageInfo = {
        object_instances: 
          {
            class_name: data.image.class_name,
            id: data.image.id,
            image_name: data.image.image_name,
            image_width: w,
            image_height: h,
          }
        };

    let {imageInfoList} = this.state;
    imageInfoList.push(imageInfo);
    this.setState({imageInfoList: imageInfoList});

    this.imgUrlListIndex += 1;
    if(this.imgUrlListIndex < this.state.imageDataUrlList.length){
      UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
         this.succeededCallback, 
         this.failedCallback).then((data) => {
          if(data && data.image){
            this.succeededCallback(data);
          }
      }).catch((errors)=>{
            this.failedCallback(errors);
      });
    }else{
      this.endTime = new Date().getTime();
      const msg = `Uploaded ${this.state.imageDataUrlList.length} images took ${(this.endTime - this.startTime)/1000} seconds.`;
      console.log(msg);
      this.allImagesUploaded = true;

      if(this.state.previewPDFs.length > 0) {
        if(this.allPDFsUploaded) {
          if(this.state.checkedAnnouncement){
            var object_instances = this.state.imageInfoList.map((item) =>{
              return item.object_instances;
            });
            
            createPost({
              channel_id: activeChannel.id,
              title: 'Announcement',
              content,
              post_type: 2,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
          }else{
            var object_instances = this.state.imageInfoList.map((item) =>{
              return item.object_instances;
            });
            
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
          }  
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.setState({pdfIdList: []});
          this.setState({checkedAnnouncement: false});
          this.imgUrlListIndex = 0;

          this.setState({previewPDFs: []});
          this.setState({pdfIdList: []});
          this.pdfUrlListIndex = 0;
        }else {
          console.log('images Uploaded, waiting for PDF uploading...');
        }
        return;
      }
      
      if(this.state.checkedAnnouncement){
        var object_instances = this.state.imageInfoList.map((item) =>{
          //console.log(item);
          return item.object_instances;
        });
        
        createPost({
          channel_id: activeChannel.id,
          title: 'Announcement',
          content,
          post_type: 2,
          attachment_attributes:{
            object_instances,
          }
        });
      }else{
        var object_instances = this.state.imageInfoList.map((item) =>{
          //console.log(item);
          return item.object_instances;
        });
        
        createPost({
          channel_id: activeChannel.id,
          title: 'Title',
          content,
          attachment_attributes:{
            object_instances,
          }
        });
      }  


      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      this.fileInput.current.value = '';
      this.setState({previews: []});
      this.setState({imageDataUrl: ''});
      this.setState({imageDataUrlList: []});
      this.setState({imageInfoList: []});
      this.setState({pdfIdList: []});
      this.setState({checkedAnnouncement: false});
      this.imgUrlListIndex = 0;
    }
   }

   failedCallback(error){
    alert('failed');
    if(this.state.imageDataUrlList.length >0){
      UploadImage(this.state.imageDataUrlList[this.imgUrlListIndex],
         this.succeededCallback, 
         this.failedCallback).then((data) => {
            this.succeededCallback(data);
      }).catch((errors)=>{
            this.failedCallback(errors);
      });
    }
   }

   succeedUnfurled(data){
     const { activeChannel, createPost } = this.props;
     const content=this.state.contentWithUrl;
     this.urlDetected = false;

     if(this.state.checkedAnnouncement){
      createPost({
        channel_id: activeChannel.id,
        title: 'title',
        content,
        post_type: 2,    
        url_meta: {
          author: data.data.author,
          date: data.data.date,
          description: data.data.description,
          image: data.data.image,
          logo: data.data.logo,
          publisher: data.data.publisher,
          title: data.data.title,
          url: data.data.url,
        }
      });
    }else{
      createPost({
        channel_id: activeChannel.id,
        title: 'title',
        content,    
        url_meta: {
          author: data.data.author,
          date: data.data.date,
          description: data.data.description,
          image: data.data.image,
          logo: data.data.logo,
          publisher: data.data.publisher,
          title: data.data.title,
          url: data.data.url,
        }
      });
    }
    this.setState({checkedAnnouncement: false});
   }

  succeededUploadPDF(data) {
    const { activeChannel, createPost } = this.props;
    const content = this.state.userInput2 ? this.state.userInput2 : this.state.userInput;

    if(!data) {
      return;
    }

    let {pdfIdList} = this.state;
    pdfIdList.push(data.blob.id);
    this.setState({pdfIdList: pdfIdList});
    this.pdfUrlListIndex += 1;
    if(this.pdfUrlListIndex < this.state.previewPDFs.length) {
      UploadPDF(this.state.previewPDFs[this.pdfUrlListIndex]).then((data) =>{
        this.succeededUploadPDF(data);
      }).catch((error) =>{
        console.log(error);
      });
    }else {
      this.allPDFsUploaded = true;
      if(this.state.previews.length > 0) {
        if(this.allImagesUploaded) {
          var object_instances = this.state.imageInfoList.map((item) =>{
            return item.object_instances;
          });
          if(this.state.checkedAnnouncement){
              createPost({
                channel_id: activeChannel.id,
                title: 'Announcement',
                content,
                post_type: 2,
                attachment_attributes:{
                    object_instances,
                },
                attached_files_blob_ids: this.state.pdfIdList
              });
          }else{
            createPost({
              channel_id: activeChannel.id,
              title: 'Title',
              content,
              attachment_attributes:{
                object_instances,
              },
              attached_files_blob_ids: this.state.pdfIdList
            });
          }
          this.setState({userInput: '',});
          this.setState({userInput2: '',});
          this.fileInput.current.value = '';
          this.setState({previewPDFs: []});
          this.setState({pdfIdList: []});
          this.setState({checkedAnnouncement: false});
          this.pdfUrlListIndex = 0;

          this.setState({previews: []});
          this.setState({imageDataUrl: ''});
          this.setState({imageDataUrlList: []});
          this.setState({imageInfoList: []});
          this.imgUrlListIndex = 0;
        }else {
          console.log('PDF loaded, waiting for image uploading...');
        }
        return;
      }

      if(this.state.checkedAnnouncement){
        createPost({
          channel_id: activeChannel.id,
          title: 'Announcement',
          content,
          post_type: 2,
          attached_files_blob_ids: this.state.pdfIdList,
        });
      }else{
        createPost({
          channel_id: activeChannel.id,
          title: 'Title',
          content,
          attached_files_blob_ids: this.state.pdfIdList,
        });
      }
      this.setState({userInput: '',});
      this.setState({userInput2: '',});
      this.fileInput.current.value = '';
      this.setState({previewPDFs: []});
      this.setState({pdfIdList: []});
      this.setState({checkedAnnouncement: false});
      this.pdfUrlListIndex = 0;
    }
  }
  

  renderPosts = () => {
    const {
      active,
      posts: { data, page },
      setActivePost,
      allowComments,
      allowLikes,
      setActiveDelete,
      setActiveSidePane,
      fetchReadReceipts,
      fetchSubscriptions,
      fetchComments,
      likePost,
      unlikePost,
      pinPost,
      unpinPost,
      userIsAdmin,
      activeChannel,
      userId,
    } = this.props;


    //const visiblePosts = data.map((post) => (
    const visiblePosts = this.state.localPosts.map((post) => (
      <blocks.Post
        key={post.id}
        clickComment={() => {
          setActivePost(post.id, activeChannel.channel_name);
          fetchComments(post.id);
          setActiveSidePane('comments');
          this.activePost = post;
        }}
        clickDelete={() => {
          setActiveDelete({
            id: post.id,
            type: this.isAnnouncement(post.post_type) ? 'announcement' : 'post',
          });
        }}
        clickLike={() => {
          if (post.liked) {
            unlikePost(post.id);
          } else {
            likePost(post.id);
          }
        }}
        clickReadReceipts={() => {
          fetchReadReceipts(post.id, active.channel);
        }}
        clickPin={() => {
          if (post.pinned) {
            unpinPost(post.id);
          } else {
            pinPost(post.id);
          }
        }}
        active={active.post === post.id || active.delete.id === post.id}
        data={{
          ...post,
          final_allow_comment: allowComments,
          final_allow_like: allowLikes,
          //final_allow_delete: userIsAdmin,
          final_allow_delete: active.userIsAdmin || post.owner_id === userId, // Fixed bug WS-1279, WS-1262
        }}
      />
    ));
    const scrollTrackingIndex = data.length / page;

    visiblePosts.splice(
      scrollTrackingIndex,
      0,
      <div key={`bookmark_${new Date().getTime()}`} id="scroll__bookmark" />
    );

    return visiblePosts;
  };

  getFormDataContent = (formData) => {
    const { file } = formData;
    let keys = Object.keys(formData);
    keys = keys.filter(key => key !== "file");
    const data = {
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type
    };
    const fileData = { file: data };
    keys.forEach(key => {
      fileData[key] = formData[key];
    });
    return fileData;
  }; 

  render() {
    const { 
      active, 
      posts, 
      activeChannel, 
      createPost, 
      setActiveSidePane, 
      active: {channel}, 
      allowCreatePost,
      intl} = this.props;

    const topLevelPinIcon = (
      <button onClick={() => setActiveSidePane('pins')}>
        <Icon path={mdiPin} size={1} color="#FE7B30" />
      </button>
    );

    const msg = intl.formatMessage(this.messages.pressKeyPrompt);
    const placeholder=`Channel: ${activeChannel && activeChannel.channel_name}       ${msg}`;

    let msg1 = '';
    if(this.state.previews.length > 0){
      if(this.fileInput.current && this.fileInput.current.files.length > 0){
        msg1 = this.fileInput.current.files[0].name;
      }
    }else{
      msg1 = intl.formatMessage(this.messages.noFileChosen);
    }
    const fileButtonText=`${intl.formatMessage(this.messages.chooseFile)}:          ${msg1}`;

    let postIsAllowed = false;
    if(allowCreatePost) {
      if(this.state.finalAllowPost) {
        postIsAllowed = true;
      }else {
        postIsAllowed = false;
      }
    }else {
      postIsAllowed = false;
    }
    
    if(!postIsAllowed){
      return(
        <elements.ColumnWrap id="columnWrap">
        <blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>
        {posts.loading || <PostStyled.Warning><FormattedMessage id="PostComponent.notAllowPostNew"
                defaultMessage="Read Only"/></PostStyled.Warning>}
         </elements.ColumnWrap>
      );
    }
    

    if(!this.state.previews.length && !this.state.previewPDFs.length){
    return (
      <elements.ColumnWrap id="columnWrap">
        <blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>
 
        {posts.loading || (<PostStyled.PostInputForm onSubmit={this.handleSubmit} noAttached={!this.state.previews.length} notChrome={!this.isChrome}>
        
          <PostStyled.PostInput 
                      id="drop-area"
                      type="textarea"
                      value={this.state.userInput}
                      placeholder={placeholder}
                      multiline={true}
                      onChange={this.handleChange}
                      onKeyUp={this.handleKeyPress}
                      ref={this.textInput}
                      />

          {posts.loading || <PostStyled.ButtonsDiv>
          {active.userIsAdmin ? <PostStyled.CheckBoxDiv>
            <PostStyled.CheckBox
                checked={this.state.checkedAnnouncement}
                onChange={this.handleCheckboxChange}
              />
            <PostStyled.Label>
                <FormattedMessage id="PostComponent.announcement"
                defaultMessage="Announcement"/>
            </PostStyled.Label>
          </PostStyled.CheckBoxDiv> : null}
          
          <PostStyled.ChooseFileLabel>
            <PostStyled.ChooseFile
              id="file-choose"
              multiple="multiple"
              ref={this.fileInput}
              onChange={this.handleImageChange}
            />
          </PostStyled.ChooseFileLabel>
          <PostStyled.SubmitButton onClick={this.handleSubmit} />
          </PostStyled.ButtonsDiv>}
        </PostStyled.PostInputForm>)}                
      </elements.ColumnWrap>
    );
    }else{
      return(
        <elements.ColumnWrap id="columnWrap">
        <blocks.Header icon={topLevelPinIcon}>
          <span>{!activeChannel || activeChannel.channel_name}</span>
        </blocks.Header>
        <elements.ScrollWrap reverse isLoading={posts.loading}>
          <InfiniteScroll
            isLoading={posts.loading}
            onEnter={this.handleScrollLoad}
          />
          {!posts.loading || <elements.Loading />}
          {this.renderPosts()}
        </elements.ScrollWrap>

          {posts.loading || <PostStyled.PreviewFrame
                                ref={this.previewFrame}
                                >
                                <PostStyled.PreviewInput
                                    multiline={true}
                                    onChange={this.handlePreviewChange}
                                    onKeyUp={this.handleKeyPress}
                                    ref={this.previewInput}
                                    >
                                    {this.state.userInput}</PostStyled.PreviewInput>
                                    <PostStyled.ImagesDiv>
                                  {this.state.previews.map((preview,index) =>(
                                    <div>
                                      <PostStyled.RemoveButton 
                                      onClick={this.handleDelete}/>
                                      <PostStyled.PreviewImage
                                      key={index}
                                      src={preview}
                                      ref={this.imagePreview}/>
                                    </div>
                                  ))}
                                </PostStyled.ImagesDiv>
                                <PostStyled.PDFContainer>
                                {this.state.previewPDFs.map((pdf, index) =>(
                                  <div>
                                    <PostStyled.RemoveButton 
                                    onClick={this.handleDelete}/>
                                    <PDFComponent
                                      key={index}
                                      pdf={pdf}
                                      ref={this.pdfPreview}>
                                    </PDFComponent>
                                  </div>
                                  ))}
                                  </PostStyled.PDFContainer>
                                {posts.loading || <PostStyled.ButtonsDiv>
          {active.userIsAdmin ? <PostStyled.CheckBoxDiv>
            <PostStyled.CheckBox
                checked={this.state.checkedAnnouncement}
                onChange={this.handleCheckboxChange}
              />
            <PostStyled.Label>
                <FormattedMessage id="PostComponent.announcement"
                defaultMessage="Announcement"/>
            </PostStyled.Label>
          </PostStyled.CheckBoxDiv> : null}
          
          <PostStyled.ChooseFileLabel>
            <PostStyled.ChooseFile
              id="file-choose"
              multiple="multiple"
              ref={this.fileInput}
              onChange={this.handleImageChange}
            />
          </PostStyled.ChooseFileLabel>
          <PostStyled.SubmitButton onClick={this.handleSubmit} />
        </PostStyled.ButtonsDiv>}
        </PostStyled.PreviewFrame>}
                  
        </elements.ColumnWrap>
     );
    }
  }
}

PostsComponent.propTypes = {
  fetchPosts: PropTypes.func.isRequired,
  fetchMorePosts: PropTypes.func.isRequired,
  setActivePost: PropTypes.func.isRequired,
  setActiveDelete: PropTypes.func.isRequired,
  setActiveSidePane: PropTypes.func.isRequired,
  likePost: PropTypes.func.isRequired,
  unlikePost: PropTypes.func.isRequired,
  pinPost: PropTypes.func.isRequired,
  unpinPost: PropTypes.func.isRequired,
  active: teamFeedShapes.active.isRequired,
  posts: teamFeedShapes.posts.isRequired,
  //activeChannel: appShapes.channelsDataShape.isRequired,
  allowComments: PropTypes.number.isRequired,
  fetchReadReceipts: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  fetchSubscriptions: PropTypes.func.isRequred,
  pubnub: PropTypes.object.isRequired,
};

export default injectIntl(PostsComponent);
