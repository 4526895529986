import { default as slices } from './slices';

const {
  requestMembers,
  receiveMembers,
  updateMember,
  removeMember,
  selectMember,
  deselectMember,
} = slices.users.actions;

export default {
  requestMembers,
  receiveMembers,
  updateMember,
  removeMember,
  selectMember,
  deselectMember,
};
