import { defineMessages } from 'react-intl';

const messages = defineMessages({
  approved: {
    id: 'FilterContainer.approved',
    defaultMessage: 'Approved',
  },
  denied: {
    id: 'FilterContainer.denied',
    defaultMessage: 'Denied',
  },
  covered: {
    id: 'FilterContainer.covered',
    defaultMessage: 'Covered',
  },
  posted: {
    id: 'FilterContainer.posted',
    defaultMessage: 'Posted',
  },
  pending: {
    id: 'FilterContainer.pending',
    defaultMessage: 'Pending',
  },
  teamShift: {
    id: 'FilterContainer.teamShift',
    defaultMessage: 'Team Shift',
  },
  vto: {
    id: 'FilterContainer.vto',
    defaultMessage: 'VTO',
  },
  openShift: {
    id: 'FilterContainer.openShift',
    defaultMessage: 'Open Shift',
  },
  locVoluntaryTimeOff: {
    id: 'TableNotes.voluntaryTimeOff',
    defaultMessage: 'Voluntary Time Off #{count} of {total}', 
  },
  locOpenShift: {
    id: 'TableNotes.openShift',
    defaultMessage: 'Open Shift #{count} of {total}', 
  },
  locVTO: {
    id: 'TableNotes.vto',
    defaultMessage: 'VTO #{count} of {total}', 
  },
});

export default messages;
