import React from 'react';
import PropTypes from 'prop-types';

import './DateTimeButton.scss';

const DateTimeButton = (props) => {
  const { _toggleDatePicker, dateString, timeRangeString } = props;

  return (
    <button
      type="button"
      className="date-time-button"
      onClick={_toggleDatePicker}
    >
      <div className="date-time-button__period-btn-section date-time-button__period-btn-section--date">
        <span className="date-time-button__date-text">{dateString}</span>
        <span className="date-time-button__date-text">{timeRangeString}</span>
      </div>
    </button>
  );
};

DateTimeButton.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  dateString: PropTypes.string,
  timeRangeString: PropTypes.string,
};

DateTimeButton.defaultProps = {
  dateString: 'mm/dd/yyyy',
  timeRangeString: 'Start time; end time',
};

export default DateTimeButton;
