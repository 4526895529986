import styled from 'styled-components/macro';

import { breakpoints } from './styled';

const borderColor = '#DEDEDE';
const mainTextColor = '#90959A';

const PageWrap = styled.div`
  font-size: 12px;
  color: ${mainTextColor};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1170px;
  padding: 1rem;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  overflow: auto;
  background-color: white;
`;

const PageBody = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 70px;
`;

const PageRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Divider = styled.div`
  width: 98%;
  height: 1px;
  background-color: ${borderColor};
  margin: auto;
`;

const ShiftRow = styled.div.attrs({
  'data-testid': 'shiftRow',
})`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;

  @media (max-width: ${breakpoints.med}) {
    padding: 0.5rem 1rem;
  }
`;

const ShiftRowSection = styled.section`
  display: flex;
  flex-direction: row;

  &:first-of-type {
    flex: 1;
    margin-right: 1.5rem;

    @media (max-width: ${breakpoints.med}) {
      display: block;
    }
  }
`;

const ShiftRowElement = styled.div`
  padding: 0.5rem;
  /* display: flex; */
  /* flex-direction: column; */
  flex: 1;
  position: relative;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  height: 75px;
  background-color: white;
  border-top: 1px solid ${borderColor};

  @media (max-width: 580px) {
    bottom: 47px;
  }
`;

const FooterColumn = styled.section`
  padding: 1rem;

  &:first-child {
    flex: 1;
    /* @media (max-width: ${breakpoints.med}) {
      padding: 0.5rem 1rem; */
    }
  }
`;

export {
  PageWrap,
  PageBody,
  PageRow,
  HeaderRow,
  Divider,
  ShiftRow,
  ShiftRowSection,
  ShiftRowElement,
  Footer,
  FooterColumn,
};
