import { createSlice } from '@reduxjs/toolkit';

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState: {
    //data: [],
    shifts: [],
    scheduleShifts: [],
    loading: true,

  },
  reducers: {
    hydrateShifts: (state, action) => {
      state.shifts = action.payload;
      state.loading = false;
    },
    requestShifts: (state, action) => {
      state.loading = true;
    },

    hydrateScheduleShifts: (state, action) => {
      state.scheduleShifts = action.payload;
      state.loading = false;
    },
   
  },
});

export default shiftsSlice;
