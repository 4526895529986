import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emailTakenError: {
    id: 'SignupPage.emailTakenError',
    defaultMessage:
      'That email has already been taken. Please try another or try logging in instead.',
  },
  wrongPinError: {
    id: 'SignupPage.wrongPinError',
    defaultMessage:
      "Oops, that PIN doesn't match our records. Please try again.",
  },
  wrongPinError2: {
    id: 'SignupPage.wrongPinError2',
    defaultMessage:
      'Something went wrong while processing the information. Please try again.',
  },
});

export default messages;
