import moment from 'moment';
import momentTimezone from 'moment-timezone';
import Papa from 'papaparse';
import { getDefaultTimezone } from './date-time';
import { createIntl, createIntlCache, defineMessages } from 'react-intl'
import localeData from '../../public/locales/data.json';

export const obfuscatePhoneNumber = (phoneNumber) => {
  const newString = phoneNumber.substr(phoneNumber.length - 4, 4);

  return `*******${newString}`;
};

export const thumbnailImageCheck = (profileImage) => {
  const image = profileImage || {};
  return !image.thumb_url
    //? 'https://s3.amazonaws.com/shyftassets/avatar1.png'
    ? '/icons/default-profile-thumb.svg'
    : image.thumb_url;
};

export const createTableRowData = (rows, headers) => {
  if (rows && rows.length > 0) {
    return Papa.parse(
      rows.map((row) => {
        return headers.map((header) => {
          if (Array.isArray(header.key)) {
            return header.key.map((key) => {
              return row[key];
            });
          }
          return row[header.key];
        });
      })
    );
  }
  return [];
};

export const createCsvRowData = (rows, headers, location) => {
  if (rows && rows.length > 0) {
    return rows.map((row, index) => {
      return headers.map((header) => {
        if (Array.isArray(header.key)) {
          return header.key.map((key) => {
            return row[key];
          });
        }
        switch (header.key) {
          case 'location_name':
            return location.location_name;

          case 'formatted_address':
            if (location.formatted_address) {
              return `${location.formatted_address}`;
            }
            return 'N/A';

          case 'time_zone':
            return getDefaultTimezone();

          case 'content':
            return row[header.key] ? `${row[header.key]}` : '';

          default:
            return row[header.key] ? row[header.key] : '';
        }
      });
    });
  }
  return [];
};

export const createCsvRowData2 = (rows, headers, location) => {
  let applicantStatus = '';
  if (rows && rows.length > 0) {
    return rows.map((row, index) => {
      if(row.cover_mode === 'default') {
        applicantStatus = row.trade_status;
      }else {
        if(row.cover_mode === 'multi_applicants_picked_by_manager') {
          if(row.current_applicants_pending_user_ids.length > 0) {
            if(row.coverer && row.coverer.first_name === '') {
              applicantStatus = 'pending';
            }
          }
          if(row.current_applicants_denied_user_ids.length > 0) {
            if(row.coverer && row.coverer.first_name === '') {
              applicantStatus = 'denied';
            }
          }
        }
      }
      return headers.map((header) => {
        if (Array.isArray(header.key)) {
          return header.key.map((key) => {
            return row[key];
          });
        }
        switch (header.key) {
          case 'location_name':
            return location.location_name;

          case 'formatted_address':
            if (location.formatted_address) {
              return `${location.formatted_address}`;
            }
            return 'N/A';

          case 'time_zone':
            return getDefaultTimezone();

          case 'content':
            return row[header.key] ? `${row[header.key]}` : '';

          case 'trade_status':
            let status = '';
            if(row[header.key]) {
              //status = `${row[header.key]}/${applicantStatus}`;
              status = `${applicantStatus}`;
            }else {
              status = row[header.key];
            }
            return status;

          default:
            return row[header.key] ? row[header.key] : '';
        }
      });
    });
  }
  return [];
};

export const isEmptyCookie = (string) =>
  string === 'undefined' || string === 'null';

function checkForTermsUrl(setupActions) {
  // pp_url
  if (
    setupActions &&
    setupActions.meta &&
    setupActions.meta.current_location &&
    setupActions.meta.current_location.tos_url
  ) {
    return setupActions.meta.current_location.tos_url;
  }
}

export function formatTermsLinkWithLocale(setupActions, locale) {
  const url = checkForTermsUrl(setupActions)
    ? checkForTermsUrl(setupActions)
    : undefined;

  return url || `https://myshyft.com/terms.${locale}.html`;
}

export function formatPrivacyLinkWithLocale(setupActions, locale) {
  return `https://myshyft.com/privacy.${locale}.html`;
}

// For formatting response[only] props before sending to api
export const formatOnlyProps = (props) =>
  props.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ''); // Strips string of linebreaks

export const cleanUsersArr = (users) => {
  let scrubbedUsers = [];
  // Set to empty string if server returns null position
  scrubbedUsers = users.map((user) => {
    if (user.position === null) {
      user.position = '';
    }

    return user;
  });

  return scrubbedUsers;
};

export function isNextDay(times) {
  return (
    times[1].isBefore(times[0]) ||
    times[0].isSame(times[1]) ||
    times[1].isAfter(times[0], 'date')
  );
}

export function createDateString(momentDate) {
  if (momentDate) {
    return moment(momentDate).format('MMMM D');
  }

  return 'Date';
}

export function createTimeString(date) {
  if (date) {
    return moment(date).format('LT');
  }

  return 'Time';
}

export function createTimeRangeString(times) {
  if (times && times.length > 0) {
    const startTimeText = moment(times[0]).format('LT');
    const endTimeText = moment(times[1]).format('LT');
    const startBeforeEnd = isNextDay(times);

    return `${startTimeText} to ${endTimeText} ${
      startBeforeEnd ? '(Next Day)' : ''
    }`;
  }

  return 'Time';
}

export const createFullName = ({ first_name, last_name }) =>
  `${first_name} ${last_name}`;

export const truncateString = (string, maxLength, truncateLines) => {
  const lineToTruncate = truncateLines ? string.split('\n')[0] : string;

  return lineToTruncate.length > maxLength
    ? `${lineToTruncate.substring(0, maxLength)}...`
    : lineToTruncate;
};


export class CustomText {
  constructor(locationConfig) {
    
    this.locationConfig = locationConfig;
    this.cover = { confirm: {} };
    this.approve = { confirm: {} };

    this.cover.confirm.title =
      locationConfig?.custom_text.shift_action_cover_confirm_title;
    this.cover.confirm.body =
      locationConfig?.custom_text.shift_action_cover_confirm_body;
    this.cover.confirm.yes =
      locationConfig?.custom_text.shift_action_cover_confirm_yes;

    this.approve.confirm.title =
      locationConfig?.custom_text.shift_action_approve_confirm_title;
    this.approve.confirm.body =
      locationConfig?.custom_text.shift_action_approve_confirm_body;
    this.approve.confirm.yes =
      locationConfig?.custom_text.shift_action_approve_confirm_yes;
  }

  hasCustomText() {
    return Boolean(this.locationConfig);
  }
}

