import { createSlice } from '@reduxjs/toolkit';
import { cleanUsersArr } from 'helpers/formatting';

const userPrivileges = createSlice({
  name: 'userPrivileges',
  initialState: {
    data: [],
    loading: true,
    page: 1,
    totalPages: 1,
  },
  reducers: {
    requestMembers: (state, action) => {
      state.loading = true;
      state.page = action.payload;
    },
    hydrateEmployees: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
  },
});

export default userPrivileges;