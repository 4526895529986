import slices from './slices';

export default {
  ...slices.filtersSlice.actions,
  ...slices.managersSlice.actions,
  ...slices.associatesSlice.actions,
  ...slices.removedUsersSlice.actions,
  ...slices.pendingUsersSlice.actions,
  ...slices.channelMembersSlice.actions,
  ...slices.channelsSlice.actions,
  ...slices.allUsersSlice.actions,
};
