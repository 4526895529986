import { connect } from 'react-redux';
import { changeLocation, loadLocations } from '../actions/dashboard-actions';
import SelectDropdown from '../components/GeneralComponents/SelectDropdown/index';

const mapStateToProps = ({ locationReducer }) => {
  return {
    options: locationReducer.locations.map((element) => {
      return {
        value: element.id,
        text: element.location_name,
        subtext: element.formatted_address,
      };
    }),
    currentElement: locationReducer.location_code,
    apiCalled: locationReducer.locations_called,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (id) => {
      dispatch(changeLocation(id));
    },

    loadOptions: () => {
      dispatch(loadLocations());
    },
  };
};

const LocationDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDropdown);

export default LocationDropdown;
