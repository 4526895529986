import { connect } from 'react-redux';
import ShiftComponent from './ShiftComponent';

//import { teamOperations, teamUtils, teamActions } from './duck';
import { appActions } from 'App/duck';
import { userOperations } from '_data/modules/Users';
import { dashboardOperations } from '../Dashboard/duck';
import { shiftOperations } from './duck';
import { scheduleOperations } from '../Scheduler/duck';

const mapStateToProps = ({
  teamReducer: {
    visibleMembers,
    bottomSheet,
    search: { searchTerm, searching },
    filter: { activeFilter, filters },
  },
  locationReducer,
  appReducer,
  shiftReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    actionJobs: appReducer.actionJobs.data,
    shifts: shiftReducer.shifts.shifts,
    scheduleShifts: shiftReducer.shifts.scheduleShifts,
  };
};

const mapDispatchToProps = (dispatch) => {

  const  getPendingUsers = (locationId) => {
      dispatch(userOperations.fetchPendingUsers(locationId));
   };


  const getLocationEmployeesNew = (locationId) => {
      const params = {
      locationId: locationId,
      page: 1,
  };
    dispatch(dashboardOperations.fetchAllUsers(params));
  };

  const  getLocationShifts = (locationId) => {
    dispatch(shiftOperations.fetchAllShifts(locationId));
  };

  const getLocationActionJobs = (locationId) => {
    dispatch(scheduleOperations.fetchLocationActionJobs(locationId));
  };

  const  getScheduleShifts = (locationId, scheduleId) => {
    dispatch(shiftOperations.fetchScheduleShifts(locationId, scheduleId));
  };
  
  return { 
    getPendingUsers,
    getLocationEmployeesNew,
    getLocationShifts,
    getLocationActionJobs,
    getScheduleShifts,
  };
};

const ShiftContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftComponent);

export default ShiftContainer;
