import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  skills: slices.skills.reducer,
};

const reducer = combineReducers({
  skills: reducers.skills,
});

export default reducer;
