// Loads appReducer from localStorage and returns it as json
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('appReducer');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Saves state of appReducer to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem('appReducer', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

const clearState = () => {
  try {
    localStorage.removeItem('appReducer');
  } catch (err) {
    // Ignore error
  }
};

export default {
  loadState,
  saveState,
  clearState,
};
