const statusTypes = {
  APPROVED: 'approved',
  PENDING: 'pending',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  REMOVED: 'removed',
  WITHDRAWN: 'withdrawn',
  BLOCKED: 'blocked',
  POSTED: 'posted',
};

const userActionTypes = {
  APPROVE: 'APPROVE',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  PROMOTE: 'PROMOTE',
  WITHDRAW: 'WITHDRAW',
  BLOCK: 'BLOCK',
  DENY: 'DENY',
  SAVE_USER: 'SAVE_USER',
  OPEN_MORE: 'OPEN_MORE',
};

const bulkActionTypes = {
  ACTIVE_BULK: 'ACTIVE_BULK',
  PENDING_BULK: 'PENDING_BULK',
  SELECT_ACTIVE_BULK: 'SELECT_ACTIVE_BULK',
};

const categoryTypes = {
  LOCATION: 'LOCATION',
  DEPARTMENTS: 'DEPARTMENTS',
  GROUPS: 'GROUPS',
  SKILLS: 'SKILLS',
};

const operationTypes = {
  CREATE_GROUP: 'CREATE_GROUP',
  ASSIGN_USERS_TO_GROUP: 'ASSIGN_USERS_TO_GROUP',
  REMOVE_USERS_FROM_GROUP: 'REMOVE_USERS_FROM_GROUP',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  ASSIGN_USERS_TO_DEPT: 'ASSIGN_USERS_TO_DEPT',
  REMOVE_USERS_FROM_DEPT: 'REMOVE_USERS_FROM_DEPT',
  CREATE_SKILL: 'CREATE_SKILL',
  ASSIGN_USERS_TO_SKILL: 'ASSIGN_USERS_TO_SKILL',
  REMOVE_USERS_FROM_SKILL: 'REMOVE_USERS_FROM_SKILL',
}

const sortTypes = {
  DATE: 'DATE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  POSITION: 'POSITION',
  STATUS: 'STATUS',
  USER_ROLE: 'USER_ROLE',
}

export const MAX_BULK_USERS = 5;
export const MAX_USERS_REMOVE_FROM_GROUP = 1; //if removing n users, backend removed n-1 users. remove 1 user is ok 
export const USERS_PER_PAGE = 5000;

export default {
  statusTypes,
  userActionTypes,
  bulkActionTypes,
  categoryTypes,
  operationTypes,
  sortTypes,
};
