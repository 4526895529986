import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ConfirmationSheet,Button } from '@shyft/acorns';

import GeneralDataContainer from '../../components/DashboardComponents/GeneralDataSection/GeneralDataContainer';
import ManagementFeedContainer from '../../components/DashboardComponents/RequestsSection/ManagementActionFeed/ManagementFeedContainer';
import PendingUsersContainer from '../../components/DashboardComponents/RequestsSection/PendingUsersSection/PendingUsersContainer';
import RecentRequests from './components/RecentRequestsContainer';

import { getUserId } from '../../helpers/cookies';
import { CustomText } from '../../helpers/formatting';

import styled from './styled';
import BottomSheet from 'pages/Team/components/BottomSheet';
import styledMacro from 'styled-components/macro';
import './DashboardPage.scss';
import BodyText from 'pages/Team/components/TeamConfirmation/components/BodyText';
import {GetCostCenterData} from '../../helpers/api-calls/location-calls';

import { RTOS_PER_PAGE, } from './constants';

const sha1 = require('js-sha1');

const ActionBtn = styledMacro(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-left: 1.25rem;
`;

class DashboardComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pubnub = props.pubnub;

    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;


    this.state = {
      confirmationSheet: false,
      confirmationShift: undefined,
      confirmationConfigId: undefined,
      deletedGroupId: null,
      trigger: false,
    };

    this.rtoPageIndex = 1;
    this.prevPageIndex = 1;
    this.apiCalled = false;
  }
  /*
  state = {
    confirmationSheet: false,
    confirmationShift: undefined,
    confirmationConfigId: undefined,
    deletedGroupId: null,
  };
  */
  componentDidMount() {
    const {locationId, getCostCenterData, 
      getRequestTimeOffs, getLocationEmployees, 
      getLocationUsableSkills, getPendingUsers, 
      getLocationInnerDepartments, departments, 
      getLocationMyDepartments, myDepartments,
      getLocationEmployeesNew, resetTotalRTOs, } = this.props;

    this.pubnubSubscribe();
    console.log('Dash: pubnub subscribed')

    //Ensure componentDidUpdate called at lease once
    this.setState({trigger: true});

    this.rtoPageIndex = 1;
    resetTotalRTOs();
    /*
    if(locationId){
      getCostCenterData(locationId);
      getLocationEmployeesNew(locationId);
      getLocationUsableSkills(locationId);
      getPendingUsers(locationId);
      getLocationInnerDepartments(locationId);
      getLocationMyDepartments(locationId);
      getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
      this.prevPageIndex = this.rtoPageIndex;
      this.rtoPageIndex += 1;
    }
    */
  }

  componentDidUpdate(prevProps, prevState) {
    const {locationId, getCostCenterData, locationConfig,
      getRequestTimeOffs, getLocationEmployees, 
      getLocationUsableSkills, getPendingUsers, 
      getLocationInnerDepartments, departments, 
      getLocationMyDepartments, myDepartments,
      getLocationEmployeesNew, totalRTOs, } = this.props;

    if(!this.apiCalled) {
      if(locationId){
        getCostCenterData(locationId);
        getLocationEmployeesNew(locationId);
        getLocationUsableSkills(locationId);
        getPendingUsers(locationId);
        getLocationInnerDepartments(locationId);
        getLocationMyDepartments(locationId);
        getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
        this.prevPageIndex = this.rtoPageIndex;
        this.rtoPageIndex += 1;
      }
      this.apiCalled = true;
    }

    if(prevProps.totalRTOs !== totalRTOs) {
      console.log(`page: ${this.rtoPageIndex - 1}, rto: ${totalRTOs.length}`);
      if(prevProps.totalRTOs) {
        if(totalRTOs.length - prevProps.totalRTOs.length === RTOS_PER_PAGE) {
          getRequestTimeOffs(locationId, this.rtoPageIndex, RTOS_PER_PAGE);
          this.prevPageIndex = this.rtoPageIndex;
          this.rtoPageIndex += 1;
        }
      }
    }
  }

  componentWillUnmount() {
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
    console.log('Dash: pubnub unsubscribed');
  }

  pubnubSubscribe = () => {
    const { handleShiftUpdate } = this.props;
    /*
    this.pubnub.subscribe({
      channels: [this.sha1],
    });
    */
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'shift' &&
          event.message.crud === 'UPDATE'
        ) {
          handleShiftUpdate(event.message.node_id);
        }
        if(
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' &&
          event.message.crud === 'DELETE'
          ) {
          const msg = `Dash page: Group (channel) ${event.message.tree_id} has been deleted.`;
          console.log(msg);
          this.setState({deletedGroupId: event.message.tree_id});
        }
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
  };

  approveShiftClick = () =>{
    const { approvePendingShift } = this.props;
    const { confirmationShift,confirmationConfigId } = this.state;
    approvePendingShift(confirmationShift,confirmationConfigId)
    this.toggleConfirmationSheet(undefined,undefined);
  }

  toggleConfirmationSheet = (shift,configId) => {
    this.setState((prevState) => ({
      confirmationSheet: !prevState.confirmationSheet,
      confirmationShift: shift,
      confirmationConfigId: configId
    }));
  };

  render() {
    const { confirmationSheet, confirmationShift, confirmationConfigId, deletedGroupId, } = this.state;
    const { locationConfig } = this.props;

    const customText = new CustomText(locationConfig);
    return (
      <article className="dashboard">
        <div className="dashboard__wrap">
          <GeneralDataContainer />
          <section className="shift-activity--container">
            <styled.SectionColumn>
              <styled.ShiftRequestsFeed>
                <styled.ShiftRequestsHeader>
                  <FormattedMessage
                    id="Dashboard.recentRequestsHeader"
                    defaultMessage="Recent Requests"
                  />
                </styled.ShiftRequestsHeader>
                <RecentRequests
                  toggleConfirmationSheet={this.toggleConfirmationSheet}
                  deletedGroupId={deletedGroupId}
                />
              </styled.ShiftRequestsFeed>
            </styled.SectionColumn>
            <styled.SectionColumn>
              <PendingUsersContainer />
              <ManagementFeedContainer />
            </styled.SectionColumn>
          </section>
        </div>
        {/* <ConfirmationSheet
          bodyText={
            <React.Fragment>
              <strong>{customText.approve.confirm.title?customText.approve.confirm.title : <FormattedMessage
                  id="RecentRequest.confirmationTitle"
                  defaultMessage="Confirm Schedule Change Approval?"
                />}</strong>
              <br />
              {customText.approve.confirm.body}
            </React.Fragment>
          }
          buttons={[
            {
              text: customText.approve.confirm.yes?customText.approve.confirm.yes:(
                <FormattedMessage
                  id="RecentRequest.confirm"
                  defaultMessage="Confirm"
                />
              ),
              type: 'primary',
              clickHandler: () => {this.approveShiftClick()},
            },
            {
              text: (
                <FormattedMessage
                  id="Dashboard.customText.cancel"
                  defaultMessage="Cancel"
                />
              ),
              type: 'danger',
              clickHandler: () => {
                this.toggleConfirmationSheet(undefined,undefined);
              },
            },
          ]}
          visible={confirmationSheet}
        /> */}

{confirmationSheet?<BottomSheet visible={confirmationSheet}>
        <styled.Wrapper>
          <styled.BodyText>
          <React.Fragment>
              <strong>{customText.approve.confirm.title?customText.approve.confirm.title : <FormattedMessage
                  id="RecentRequest.confirmationTitle"
                  defaultMessage="Confirm Schedule Change Approval?"
                />}</strong>
              <br />
              {customText.approve.confirm.body}
            </React.Fragment>
          </styled.BodyText>
          <styled.ActionWrap>
          <ActionBtn modifiers={['primary']} onClick={ ()=>{this.approveShiftClick()}}>
           { customText.approve.confirm.yes?customText.approve.confirm.yes:(
                <FormattedMessage
                  id="RecentRequest.confirm1"
                  defaultMessage="Confirm"
                />)}
            </ActionBtn>
            
            <ActionBtn modifiers={['danger']} onClick = {()=>{this.toggleConfirmationSheet(undefined,undefined)}}>
            <FormattedMessage
                  id="Dashboard.customText.cancel"
                  defaultMessage="Cancel"
                />
            </ActionBtn>
          
          </styled.ActionWrap>
        </styled.Wrapper>
      </BottomSheet>:''}
      </article>
    );
  }
}

DashboardComponent.propTypes = {
  handleShiftUpdate: PropTypes.func.isRequired,
  approvePendingShift: PropTypes.func.isRequired,
  locationConfig: PropTypes.shape({}),
  pubnub: PropTypes.object.isRequired,
};

DashboardComponent.defaultProps = {
  locationConfig: {},
};

export default DashboardComponent;
