import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const WinContainer = styled.section `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  //height: ${(props) => props.height || '612px'};
  position: absolute;
  border-radius: 10px;
  background-color: rgba(44,44,46,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  //overflow: scroll;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;


const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45%;
  left: 33%;
  width: 400px;
  //border: 1px solid black;
`;

const PleaseWait = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`;
const Processing = styled.label`
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const Percent = styled.label`
  @font-face {
  font-family: 'AvertaBold';
  src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  color: rgba(3,135,255,255);

`;

const ProgressBar = styled.div`
  position: fixed;
  height: 16px;
  border-radius: 8px;
  background-color: rgba(3,135,255,255);
`;

const Frame = styled.div`
  display: inline-block;
  height: 16px;
  width: 100%;
  border-radius: 8px;
  //border: 1px solid rgba(3,135,255,255);
  background-color: white;
`;


export default {
  WinContainer,
  Overlay,
  Wrap,
  PleaseWait,
  Processing,
  Percent,
  ProgressBar,
  Frame,

};
