import React from 'react';
import { FormattedMessage } from 'react-intl';

const bodyText = (type) => {
  if (type === 'post') {
    return (
      <FormattedMessage
        id="TeamFeed.deletePost"
        defaultMessage="Are you sure you want to delete this post?"
      />
    );
  }
  if (type === 'announcement') {
    return (
      <FormattedMessage
        id="TeamFeed.deleteAnnouncement"
        defaultMessage="Are you sure you want to delete this announcement?"
      />
    );
  }
  if (type === 'comment') {
    return (
      <FormattedMessage
        id="TeamFeed.deleteComment"
        defaultMessage="Are you sure you want to delete this comment?"
      />
    );
  }

  return (
    <FormattedMessage
      id="TeamFeed.error"
      defaultMessage="Oops! There was an error on our side. Please try again."
    />
  );
};

const buttons = (handlers, active) => {
  const buttonArr = [
    {
      text: <FormattedMessage id="TeamFeed.cancel" defaultMessage="Cancel" />,
      type: '',
      clickHandler: () => {
        handlers[0]();
      },
    },
    {
      text: <FormattedMessage id="TeamFeed.delete" defaultMessage="Delete" />,
      type: 'primary',
      clickHandler: () => {
        handlers[1](active.delete.id, active.post);
      },
    },
  ];

  if (active.error.code && active.error.code !== 404) {
    buttonArr.pop();
  }

  return buttonArr;
};

export default {
  bodyText,
  buttons,
};
