import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { signUpConfirmation } from 'helpers/api-calls';
import { getQueryStringParameter } from 'helpers/browser-interactions';
import { purgeAuthenticationCookie } from 'helpers/cookies';

import './SignupConfirmationPage.scss';
import 'pages/Login/LoginPage.scss';

class SignupConfirmationPageNoI18n extends React.Component {
  state = {
    loading: true,
    path: null,
    error: false,
    errorMessage: '',
  };

  messages = defineMessages({
    confirmationCodeError1: {
      id: 'SignupConfirmationPage.confirmationCodeError1',
      defaultMessage:
        'This confirmation code is not authorized to confirm an account. Please try another.',
    },
    confirmationCodeError2: {
      id: 'SignupConfirmationPage.confirmationCodeError2',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
  });

  componentDidMount() {
    purgeAuthenticationCookie();
    this._confirmAccount(getQueryStringParameter('confirmation_token'));
  }

  login = () => {
    this.setState({
      path: {
        pathname: '/login',
      },
    });
  };

  _confirmAccount = () => {
    const { intl } = this.props;
    signUpConfirmation(getQueryStringParameter('confirmation_token')).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
          });
        }
      },
      (error) => {
        if (error.response.status === 422) {
          this.login();
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          this.setState({
            loading: false,
            error: true,
            errorMessage: intl.formatMessage(
              this.messages.confirmationCodeError1
            ),
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMessage: intl.formatMessage(
              this.messages.confirmationCodeError2
            ),
          });
        }
      }
    );
  };

  render() {
    const { loading, error, errorMessage, path } = this.state;
    const CurrentComponent = () => {
      if (loading) {
        return (
          <div className="loading">
            <img
              className="loading-gif"
              src="/loader.gif"
              alt="Loading spinner"
            />
          </div>
        );
      }
      if (error && !loading) {
        return (
          <div className="confirmation-container">
            <h1 className="login__header">Error!</h1>
            <p className="confirmation-description">
              {errorMessage}
              <FormattedMessage
                id="SignupConfirmationPage.or"
                defaultMessage="or"
                description="Placed between confirmation code error and login buton"
              />
              <button
                className="login__link"
                onClick={this.login}
                type="button"
              >
                <FormattedMessage
                  id="SignupConfirmationPage.login"
                  defaultMessage="login"
                />
              </button>
            </p>
          </div>
        );
      }
      return (
        <div className="confirmation-container">
          <h1 className="login__header">
            <FormattedMessage
              id="SignupConfirmationPage.successHeader"
              defaultMessage="Success!"
            />
          </h1>
          <p className="confirmation-description">
            <FormattedMessage
              id="SignupConfirmationPage.accountConfirmed"
              defaultMessage="Your account is confirmed! Now please"
            />
            <button
              href=""
              className="login__link"
              onClick={this.login}
              type="button"
            >
              <FormattedMessage
                id="SignupConfirmationPage.login"
                defaultMessage="login"
              />
            </button>
          </p>
        </div>
      );
    };
    if (path) {
      return <Redirect to={path} />;
    }
    return (
      <React.Fragment>
        <article className="login">
          <div className="login__wrap">
            <CurrentComponent />
          </div>
        </article>
      </React.Fragment>
    );
  }
}

const SignupConfirmationPage = injectIntl(SignupConfirmationPageNoI18n);
export { SignupConfirmationPage, SignupConfirmationPageNoI18n };
