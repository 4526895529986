import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import styled from 'styled-components/macro';
import { injectIntl, defineMessages } from 'react-intl';
import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';

import {
  getLocationCode,
  checkForAuthentication,
  purgeAuthenticationCookie,
} from 'helpers/cookies';
import { ROLES, FindRole } from 'helpers/user-roles';

import SetupContainer from 'containers/SetupContainer';
import LocationDropdown from 'containers/LocationDropdown';
import { USERS_PER_PAGE, } from '../../pages/UserManagement/constants';

const LocationDropdownWrap = styled.div`
  display: none;
  width: 100%;
  position: relative;

  .select-dropdown {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 680px) {
    display: block;
  }
`;

const LoadingWrap = styled.div`
  width: 8rem;
  margin: 8rem auto;
`;

const Loader = styled(LoadingSpinner)``;

class RoleComponent extends React.Component {
  constructor(props) {
    super(props);

    this.allUserPageIndex = 1;

    this.idleTimerRef = React.createRef();

    this.messages = defineMessages({
      idleAlert: {
        id: 'RoleComponent.idleAlert',
        defaultMessage:
          "You've been away for awhile! It's time to log back in and see what your coworkers are up to.",
      },
    });
  }

  componentDidMount() {
    const { loadRequiredApis, 
      pendingUsersLoaded,
      fetchAllUsers,
      allUsers,
      resetManagers,
      resetAssociates,
      resetRemovedUsers,
      resetPendingUsers,
      resetAllUsers, } = this.props;

    const location_id = getLocationCode();

    if (location_id) {
      loadRequiredApis(location_id, pendingUsersLoaded);
      resetManagers();
      resetAssociates();
      resetRemovedUsers();
      resetPendingUsers();
      resetAllUsers();
      this.allUserPageIndex = 1;
      fetchAllUsers(location_id, this.allUserPageIndex, USERS_PER_PAGE);
      this.allUserPageIndex += 1;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      setupActionsLoading: prevSetupActionsLoading,
      locationId: prevLocationId,
    } = prevProps;
    const {
      setupActionsLoading,
      fetchLocation,
      setupActions,
      locationId,
      loadRequiredApis,
      fetchChannels,
      fetchAllUsers,
      allUsers,
      resetManagers,
      resetAssociates,
      resetRemovedUsers,
      resetPendingUsers,
      resetAllUsers,
    } = this.props;

    if (locationId && prevLocationId !== locationId) {
      loadRequiredApis(locationId);
      //this is for location changes. It has been done in compomentDidMount
      if(prevLocationId !== undefined) {
        resetManagers();
        resetAssociates();
        resetRemovedUsers();
        resetPendingUsers();
        resetAllUsers();
        this.allUserPageIndex = 1;
        fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
        this.allUserPageIndex += 1;
      }
    }

    if (
      locationId && prevSetupActionsLoading !== setupActionsLoading &&
      !setupActionsLoading
    ) {
      if(setupActions && setupActions.meta && setupActions.meta.current_user_privilege) {
        fetchLocation(setupActions.meta.current_user_privilege.id);
      }
      fetchChannels(locationId);
    }

    if(prevProps.allUsers !== allUsers) {
      if(prevProps.allUsers) {
        if(allUsers.length - prevProps.allUsers.length === USERS_PER_PAGE) {
          fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
          this.allUserPageIndex += 1;
        }
      }
    }
  }

  _onIdleHandler = () => {
    const { intl } = this.props;

    alert(intl.formatMessage(this.messages.idleAlert));
    // Logout user
    purgeAuthenticationCookie();
    window.location.pathname = '/';
  };

  render() {
    const {
      setupActions,
      routeProps,
      setupActionsLoading,
      locationSettingsLoading,
    } = this.props;
    const SetupContainerWithIdle = (
      <IdleTimer
        ref={this.idleTimerRef}
        onIdle={this._onIdleHandler}
        timeout={1000 * 60 * 15}
      >
        <LocationDropdownWrap>
          <LocationDropdown />
        </LocationDropdownWrap>
        <SetupContainer routeProps={routeProps} />
      </IdleTimer>
    );

    if (!checkForAuthentication()) {
      // User is logged out
      return <Redirect to="/login" />;
    }

    if (!setupActionsLoading && !locationSettingsLoading) {
      // Data from setup actions api call available
      const role = FindRole(setupActions);

      switch (role) {
        case ROLES.LOGGED_OUT.key:
          return <Redirect to="/login" />;

        case ROLES.USER.key:
          return SetupContainerWithIdle;

        case ROLES.ADMIN.key:
          return SetupContainerWithIdle;

        default:
          return null;
      }
    }

    return (
      <LoadingWrap>
        <Loader color="blue" size="large" />
      </LoadingWrap>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });

RoleComponent.propTypes = {
  pendingUsersLoaded: PropTypes.bool.isRequired,
  locationSettingsLoading: PropTypes.bool.isRequired,
  loadRequiredApis: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  setupActions: PropTypes.shape({}).isRequired,
  setupActionsLoading: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(RoleComponent);
