import { arrayOf, shape, oneOf, number, string } from 'prop-types';

import { teamConstants } from 'pages/Team/duck';
import { appConstants } from 'App/duck';

const shiftNames = oneOf(Object.values(appConstants.shiftNames));

const shiftShape = shape({
  id: number,
  name: shiftNames,
  start_at: string,
  end_at: string,
  trade_status: oneOf(Object.values(teamConstants.statusTypes)),
  coverer_time: string,
  manager_time: string,
  post_id: 41726,
  content: string,
  created_at: string,
  segment_count: number,
});

export default {
  shiftNames,
  shiftShape,
};
