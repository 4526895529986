import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { validateEmail, validatePhoneNumber } from 'helpers/validation';
import { EmailPhoneInput } from '@shyft/acorns';
import HoneyPotInput from 'components/GeneralComponents/HoneyPotInput/HoneyPotInput';

import './LoginFirstStage.scss';

const FormElement = styled.div.attrs({
  'data-cy': 'login-form-element',
})`
  margin-bottom: 4rem;
`;

class LoginFirstStagei18n extends React.Component {
  state = {
    emailPhone: {
      dialCode: '1',
      value: '',
      error: '',
    },
    honeyPotValidity: true,
  };

  messages = defineMessages({
    invalidEmailPhone: {
      id: 'LoginFirstStage.invalidEmailPhone',
      defaultMessage: 'Please enter a valid email or phone number',
    },
    emailPhonePlaceholder: {
      id: 'LoginFirstStage.emailPhonePlaceholder',
      defaultMessage: 'Email or phone number',
    },
  });

  _handleChange = (e) => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        value: e.target.value,
      },
    });
  };

  _handleCountryChange = (dialCode) => {
    const { emailPhone } = this.state;

    this.setState({
      emailPhone: {
        ...emailPhone,
        dialCode,
      },
    });
  };

  _handleHoneyPotInputChange = (validity) => {
    this.setState({
      honeyPotValidity: validity,
    });
  };

  _handleFormSubmit = (e) => {
    const { _formSubmit, intl } = this.props;
    const { emailPhone, honeyPotValidity } = this.state;
    e.preventDefault();

    if (!honeyPotValidity) {

      return false;
    }

    if (validatePhoneNumber(emailPhone.value)) {
      // Input is phone number

      _formSubmit({
        type: 'phone',
        value: emailPhone.dialCode + emailPhone.value,
      });

      return null;
    }

    if (validateEmail(emailPhone.value)) {
      // Input is email
      _formSubmit({
        type: 'email',
        value: emailPhone.value,
      });

      return null;
    }

    // Input is invalid

    this.setState({
      emailPhone: {
        ...emailPhone,
        error: intl.formatMessage(this.messages.invalidEmailPhone),
      },
    });
  };

  render() {
    const {
      intl: { formatMessage },
      error,
    } = this.props;
    const { emailPhone } = this.state;

    return (
      <form className="login__form" onSubmit={this._handleFormSubmit}>
        <section className="login__form-action">
          <FormElement>
            <EmailPhoneInput
              onChange={this._handleChange}
              onCountryChange={this._handleCountryChange}
              error={emailPhone.error ? emailPhone.error : error}
              value={emailPhone.value}
              placeholder={formatMessage(this.messages.emailPhonePlaceholder)}
            />
            <HoneyPotInput handleChange={this._handleHoneyPotInputChange} />
          </FormElement>
          <button
            className="login__form-submit button button-primary"
            type="submit"
          >
            <FormattedMessage
              id="LoginFirstStage.login"
              defaultMessage="Log In"
            />
          </button>
        </section>
      </form>
    );
  }
}

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
LoginFirstStagei18n.propTypes = {
  _formSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const LoginFirstStage = injectIntl(LoginFirstStagei18n);

export { LoginFirstStage, LoginFirstStagei18n };
