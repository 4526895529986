import { default as slices } from './slices';

const {
  updateSearchTerm,
  updateSearchReturned,
  updateSearching,
} = slices.search.actions;
const {
  updateActiveFilter,
  recieveMetadata,
  incrementCount,
  decrementCount,
} = slices.filter.actions;
const {
  requestMembers,
  recieveMembers,
  appendMembers,
  updateMember,
  removeMember,
  selectMember,
  deselectMember,
  selectAllMembers,
  deselectAllMembers,
  removeMembers,
} = slices.visibleMembers.actions;
const { 
  updateVisibility, 
  updateSelectedAction, 
  updateCancelled, 
  updateLastUser, 
  updateLastAction, 
  updateLastError,
  updateButtonState,
} = slices.bottomSheet.actions;

export default {
  updateSearchTerm,
  updateActiveFilter,
  updateSearchReturned,
  updateSearching,
  recieveMetadata,
  appendMembers,
  requestMembers,
  recieveMembers,
  updateMember,
  removeMember,
  incrementCount,
  decrementCount,
  updateVisibility,
  updateSelectedAction,
  updateCancelled,
  updateLastUser,
  updateLastAction,
  updateLastError,
  updateButtonState,
  selectMember,
  deselectMember,
  deselectAllMembers,
  selectAllMembers,
  removeMembers,
};
