import { GetPost, GetShift } from 'helpers/api-calls';

export const SHIFT_MANAGED = 'SHIFT_MANAGED';
export const SHIFT_PENDING = 'SHIFT_PENDING';

export const shiftPending = (shift) => {
  return { type: SHIFT_PENDING, shift };
};

export const shiftManaged = (shift) => {
  return { type: SHIFT_MANAGED, shift };
};

export const determineAction = (shift) => {
  return function(dispatch) {
    if (shift.trade_status === 'pending') {
      dispatch(shiftPending(shift));
    }

    if (
      shift.trade_status === 'approved' ||
      shift.trade_status === 'rejected'
    ) {
      dispatch(shiftManaged(shift));
    }
  };
};

export function liveShiftUpdate(id) {
  return function(dispatch) {
    GetPost(id).then((data) => {
      if (data.data && data.data.post && data.data.post.schedule_element) {
        dispatch(determineAction(data.data.post.schedule_element));
      }
    });
  };
}

export function userShiftUpdate(shift) {
  return function(dispatch) {
    GetShift(shift.id).then((data) => {
      if (data.data && data.data.schedule_element) {
        dispatch(determineAction(data.data.schedule_element));
      }
    });
  };
}
