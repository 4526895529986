
const sortTypes = {
  DATE: 'DATE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  POSITION: 'POSITION',
  STATUS: 'STATUS',
  USER_ROLE: 'USER_ROLE',
}

export const RTOS_PER_PAGE = 2000;

export default {
  sortTypes,
};
