import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';


import { createFullName, truncateString } from 'helpers/formatting';
import { fullDate, startEndRange } from 'helpers/date-time';
import { dashboardShapes } from '../duck';

import CoverRequestString from 'components/GeneralComponents/CoverRequestString';

import ShiftRequest from '../blocks/ShiftRequest';
import calendarIcon from './assets/calendar.svg';
import clockIcon from './assets/clock.svg';
import segmentsIcon from './assets/segments.svg';
import speechIcon from './assets/speech-bubble.svg';

import './RecentRequest.scss';

import ShiftDetails from './ShiftDetails';
import Modal from './Modal';

class RecentRequest extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      currentAction: '',
      confirming: false,
      expanded: false, 
      viewDetails: false,
      show3Avatars: false,
      posterIsAdmin: false,
      userDeniedApplicants: [],
      applicantsApproved: 0,
      applicantsDenied: 0,
      applicantsPending: 0,
      channelType: '',

      debug: false,      
    }
    
    this.messages = defineMessages({
      jobTitle: {
        id: 'RecentRequest.jobTitle',
        defaultMessage: 'Job title',
      },
      openShifts: {
        id: 'RecentRequest.openShifts',
        defaultMessage: 'Open Shifts',
      },
      teamShifts: {
        id: 'RecentRequest.teamShifts',
        defaultMessage: 'Team Shifts',
      },
    });

    this.handleViewDetailsClick = this.handleViewDetailsClick.bind(this);

  }
  
  componentDidMount () {
    const {shift, userPrivileges, skills, channels, fetchShiftApplicants, } = this.props;

    if(userPrivileges.length > 0) {
      const idx = userPrivileges.findIndex((user) => user.owner_id === shift.owner.id);
      if(idx >= 0) {
        if(userPrivileges[idx].is_admin) {
          this.setState({posterIsAdmin: true});
        }
      }
    }

    let approved = 0;
    let denied = 0;
    let pending = 0;
    if(shift.applicants) {
      for(let i=0; i<shift.applicants.length; i++) {
        if(shift.applicants[i].status === 'picked') {
          approved += 1;
        }
        if(shift.applicants[i].status === 'applied') {
          pending += 1;
        }
        if(shift.applicants[i].status === 'denied') {
          denied += 1;
        }
      }
      if(shift.name === 'shift' && approved === 1) {
        if(pending > 0) {
          denied = denied + pending; // auto denied
          pending = 0;
        }
      }
      this.setState({applicantsApproved: approved, applicantsPending: pending, applicantsDenied: denied});
    }else {
      approved = shift.current_applicants_approved_user_ids.length;
      pending = shift.current_applicants_pending_user_ids.length;
      denied = shift.current_applicants_denied_user_ids.length;
      if(shift.name === 'shift' && approved === 1) {
        if(pending > 0) {
          denied = denied + pending; // auto denied
          pending = 0;
        }
      }
      this.setState({applicantsApproved: approved, applicantsPending: pending, applicantsDenied: denied});
    }

    if(shift && channels && channels.length > 0) {
      const index = channels.findIndex((channel) => channel.id === shift.channel_id);
      if(index >= 0) {
        this.setState({channelType: channels[index].channel_type});
      }
    }
  }

  componentDidUpdate (prevProps) {
    const {shift, userPrivileges} = this.props;
    
    if(prevProps.shift !== shift) {
      let approved = 0;
      let denied = 0;
      let pending = 0;
      if(shift.applicants) {
        for(let i=0; i<shift.applicants.length; i++) {
          if(shift.applicants[i].status === 'picked') {
            approved += 1;
          }
          if(shift.applicants[i].status === 'applied') {
            pending += 1;
          }
          if(shift.applicants[i].status === 'denied') {
            denied += 1;
          }
        }
        if(shift.name === 'shift' && approved === 1) {
          if(pending > 0) {
            denied = denied + pending; // auto denied
            pending = 0;
          }
        }
        this.setState({applicantsApproved: approved, applicantsPending: pending, applicantsDenied: denied});
      }else {
        approved = shift.current_applicants_approved_user_ids.length;
        pending = shift.current_applicants_pending_user_ids.length;
        denied = shift.current_applicants_denied_user_ids.length;
        if(shift.name === 'shift' && approved === 1) {
          if(pending > 0) {
            denied = denied + pending; // auto denied
            pending = 0;
          }
        }
        this.setState({applicantsApproved: approved, applicantsPending: pending, applicantsDenied: denied});
      }
    }
    if(prevProps.userPrivileges !== userPrivileges) {
      if(userPrivileges.length > 0) {
        const idx = userPrivileges.findIndex((user) => user.owner_id === shift.owner.id);
        if(idx >= 0) {
          if(userPrivileges[idx].is_admin) {
            this.setState({posterIsAdmin: true});
            //const msg = `poster ${userPrivileges[idx].owner_id} is admin.`;
            //console.log(msg);
          }
        }
      }
    }
  }

  componentWillUnmount () {

  }
  

  handleViewLess = () => {
    this.setState({
      expanded: false,
    });
  };

  handleViewMore = (id) => {
    const {
      shift: { segment_count },
      util,
      hasSegments,
    } = this.props;

    if (!hasSegments(id) && segment_count > 0) {
      util.fetchPendingShiftSegments(id);
    }

    this.setState({
      expanded: true,
    });
  };

  handleConfirm = (action) => {
    const { locationConfig, util, shift } = this.props;

    const configId =
      locationConfig && locationConfig.custom_text ? locationConfig.id : null;

    if (action === 'customText') {
      util.toggleConfirmationSheet(shift,configId);
    } else {
      this.setState({
        confirming: true,
        currentAction: action,
      });
    }
  };

  handleAction = () => {
    const { currentAction } = this.state;
    const { locationConfig, shift, util } = this.props;
    const configId =
      locationConfig && locationConfig.custom_text ? locationConfig.id : null;

    switch (currentAction) {
      case 'approve':
        util.handleApprove(shift, configId);
        break;
      case 'deny':
        util.handleDeny(shift);
        break;
      default:
        break;
    }

    // this.handleCancel();
  };

  renderViewMoreButton = () => {
    const { expanded } = this.state;
    const { shift } = this.props;

    if (expanded) {
      return null;
    }

    if (shift.content && shift.content.length > 48 || shift.segment_count !== 0) {
      return (
        <ShiftRequest.ViewMoreButton
          onClick={() => this.handleViewMore(shift.id)}
        >
          <FormattedMessage
            id="RecentRequest.viewMore"
            defaultMessage="View More"
          />
        </ShiftRequest.ViewMoreButton>
      );
    }

    return null;
    // {
    //   (!expanded && shift.content.length < 48 && shift.segment_count === 0) || (

    //   );
    // }
  };

  renderCoverRequestString = () => {
    const {
      shift: { owner, coverer, start_at, end_at, shiftApproveCustomText, name },
    } = this.props;
    const customText = shiftApproveCustomText
      ? shiftApproveCustomText.custom_text
      : null;
    const { confirming, approve } = this.state;

    if (confirming && customText && approve) {
      return (
        <React.Fragment>
          <strong>{customText.shift_action_approve_confirm_title}</strong>
          <br />
          {customText.shift_action_approve_confirm_body}
        </React.Fragment>
      );
    }

    return (
      <CoverRequestString
        type={name}
        owner={owner}
        coverer={coverer}
        startAt={start_at}
        endAt={end_at}
      />
    );
  };

  handleCancel = () => {
    this.setState({
      confirming: false,
      currentAction: '',
    });
  };

  handleViewDetailsClick = (event) => {
    const {shift, handleOpenDetailWin} = this.props; 
    
    this.setState({viewDetails: !this.state.viewDetails});
    //handleOpenDetailWin(shift);
    event.preventDefault();
  }

  closeModalWindow = () =>{
    this.setState({viewDetails: false});
  }

  handleBackButtonClicked = () => {
    console.log('back button clicked');
    this.setState({viewDetails: false});
  }

  handleUserDeny = (applicant) => {
    let {userDeniedApplicants} = this.state;
    userDeniedApplicants.push(applicant);
    this.setState({userDeniedApplicants: userDeniedApplicants});
  }

  render() {
    const { shift, locationConfig, userImgUrl, userIsAdmin, userPrivileges, skills, locationSettings,
          fetchShiftApplicants, addApplicantJobTitle, intl} = this.props;
    const { confirming, expanded, debug, posterIsAdmin, applicantsPending, applicantsDenied, channelType, } = this.state;

    const jobTitle = `${intl.formatMessage(this.messages.jobTitle)}: ${shift.primary_job}`;
    const debugInfo = `id: ${shift.id}, mode: ${shift.cover_mode}, 
                      status: ${shift.trade_status} parent: ${shift.parent_card}, 
                      approver: ${shift.approver ? shift.approver.first_name: ''} 
                      ${shift.approver ? shift.approver.last_name: ''}
                      `;
    const startDate = moment(shift.start_at).format('ddd, MMM DD');
    const openShifts = `${shift.total_shifts_available ? shift.total_shifts_available : ''} ${intl.formatMessage(this.messages.openShifts)}`;
    const teamShifts = `${shift.total_shifts_available ? shift.total_shifts_available : ''} ${intl.formatMessage(this.messages.teamShifts)}`;
    const vtos = `${shift.total_shifts_available ? shift.total_shifts_available : ''} VTOs`;

    let shiftsOrVTOs = '';
    if(shift.name === 'open_shift') {
      shiftsOrVTOs = openShifts;
    }else if(shift.name === 'shift') {
      shiftsOrVTOs = teamShifts;
    }else {
      shiftsOrVTOs = vtos;
    }
    
    let approvedApplicants = 0;
    let pendingApplicants = 0;
    let allShiftsFilled = false;

    if(shift.applicants && shift.applicants.length > 0) {
      for(let i=0; i<shift.applicants.length; i++) {
        if(shift.applicants[i].status === 'applied'){
          pendingApplicants += 1;
        }
        if(shift.applicants[i].status === 'picked'){
          approvedApplicants += 1;
        }
      }
    }else {
      pendingApplicants = shift.current_applicants_pending_user_ids.length;
      approvedApplicants = shift.current_applicants_approved_user_ids.length;
    }
    //For team shift, there is only 1 slot. one applicant is approved, other will be auto denied by system. No pending applicant
    if(shift.name === 'shift') {
      if(approvedApplicants === 1) {
        pendingApplicants = 0;
        allShiftsFilled = true;
      }
    }else {
      pendingApplicants = applicantsPending;
      //when all slots are covered, pending applicants are auto-denied
      if(shift.available_shifts_covered === shift.total_shifts_available) {
        pendingApplicants = 0;
        allShiftsFilled = true;
      }
    }

    const startTime = moment(shift.start_at).format('hh:mm a');
    const endTime = moment(shift.end_at).format('hh:mm a');
    const timeRange = `${startTime} - ${endTime}`;
    //const approvedApplicants = shift.available_shifts_covered;
    //const pendingApplicants = shift.available_shifts_pending;  
    const firstAvatar = shift.owner.profile_image ? 
                        shift.owner.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
    const secondAvatar = shift.applicants && shift.applicants[0] && shift.applicants[0].user ? 
                        shift.applicants[0].user.thumb_url : '/icons/default-profile-thumb.svg' ;
    const thirdAvatar = shift.applicants && shift.applicants.length > 1  && shift.applicants[1] && shift.applicants[1].user ?
                        shift.applicants[1].user.thumb_url : '/icons/default-profile-thumb.svg';
    const totalApplicants = shift.current_applicants_pending_user_ids.length +
                            shift.current_applicants_approved_user_ids.length +
                            shift.current_applicants_denied_user_ids.length;

    let myClassName = '';
    switch(shift.name) {
      case 'open_shift':
        if(channelType == 'region_feed') {
           myClassName = 'request-card__statusbar-regional-shift';
        }else {
           myClassName = 'request-card__statusbar-open-shift';
        }
        break;
      case 'shift':
        if(channelType == 'region_feed') {
           myClassName = 'request-card__statusbar-regional-shift';
        }else {
           myClassName = 'request-card__statusbar-team-shift';
        }
        break;
      case 'shift_inventory':
        myClassName = 'request-card__statusbar-scheduled-shift';
        break;
      case 'shift_vto':
        //myClassName = 'request-card__statusbar-vto';
        if(channelType == 'region_feed') {
           myClassName = 'request-card__statusbar-regional-shift';
        }else {
           myClassName = 'request-card__statusbar-vto';
        }
        break;
      case 'regional_shift':
        myClassName = 'request-card__statusbar-regional-shift';
        break;
    }

    //if(shift.parent_card) {
    if(shift.parent_card || (!shift.parent_card && shift.cover_mode === 'multi_applicants_picked_by_manager')) {
      return (
        <ShiftRequest key={shift.id}>
        <ShiftRequest.Row>
          <div>
            <ShiftRequest.Row marginBottom="0rem">
            <div className={myClassName}>
              <div className="request-card__title-name-date">
                <label className="request-card__title">{shift.primary_job}</label>
                <span className="colored-circle"/>
                {/*<label className="request-card__name">{shift.name === 'open_shift' || shift.name === 'shift' ? openShifts : vtos}</label>*/}
                <label className="request-card__name">{shiftsOrVTOs}</label>
                <span className="colored-circle"/>
                <label className="request-card__date">{startDate}</label>
              </div>
              <label className="request-card__time-wrap">
                {timeRange}
              </label>
            </div>
            </ShiftRequest.Row>
            <ShiftRequest.Row marginBottom="0.5rem">
              {this.state.show3Avatars ? <div className="request-card__avatars-wrap">
                <div className="request-card__thumb-wrap">
                  <img className="request-card__avatar" src={firstAvatar}/>
                </div>
                {posterIsAdmin && <img className="request-card__verified-badge" src='/icons/verified-badge-icon.svg'/>}
                <div className="request-card__thumb-wrap">
                  <img className="request-card__avatar" src={secondAvatar}/>
                </div>
                {totalApplicants > 1 && <div className="request-card__thumb-wrap">
                  <img className="request-card__avatar" src={thirdAvatar}/>
                </div>}
              </div> :
              <div className="request-card__avatars-wrap-single">
                <div className="request-card__thumb-wrap-single">
                  <img className="request-card__avatar" src={firstAvatar}/>
                </div>
                {posterIsAdmin && <img className="request-card__verified-badge" src='/icons/verified-badge-icon.svg'/>}
              </div>}
              <div className="request-card__second-row">
                <label className="request-card__applicants">
                 <FormattedMessage
                    id="RecentRequest.approvedApplicants"
                    defaultMessage="{approvedApplicants, plural, one {{approvedApplicants} applicant approved} other {{approvedApplicants} applicants approved}}"
                    values={{approvedApplicants,}}
                  />
                </label>
                <label className="request-card__applicants">
                  {allShiftsFilled && <FormattedMessage
                    id="RecentRequest.deniedApplicants"
                    defaultMessage="{applicantsDenied, plural, one {{applicantsDenied} applicant denied} other {{applicantsDenied} applicants denied}}"
                    values={{applicantsDenied,}}
                  />}
                  {(!allShiftsFilled && pendingApplicants > 0 ) && <FormattedMessage
                    id="RecentRequest.pendingApplicants"
                    defaultMessage="{pendingApplicants, plural, one {{pendingApplicants} applicant pending} other {{pendingApplicants} applicants pending}}"
                    values={{pendingApplicants,}}
                  />}
                  {(!allShiftsFilled && pendingApplicants === 0 ) && <FormattedMessage
                    id="RecentRequest.deniedApplicants"
                    defaultMessage="{applicantsDenied, plural, one {{applicantsDenied} applicant denied} other {{applicantsDenied} applicants denied}}"
                    values={{applicantsDenied,}}
                  />}
                </label>
              </div>
            </ShiftRequest.Row>
            {debug && <p>{debugInfo}</p>}
          </div>
          <ShiftRequest.ViewDetailsBtnWrap>
          <ShiftRequest.ViewDetailsButton onClick={this.handleViewDetailsClick}>
            <FormattedMessage
              id="RecentRequest.viewDetails"
              defaultMessage="View Details"
            />
          </ShiftRequest.ViewDetailsButton>
          </ShiftRequest.ViewDetailsBtnWrap>
        </ShiftRequest.Row>
        {/* Segments */}
        {expanded && (
          <React.Fragment>
            {shift.segments && (
              <ShiftRequest.Row direction="column">
                {shift.segments.map((segment) => (
                  <ShiftRequest.Segment key={segment.id}>
                    <ShiftRequest.Text>
                      {startEndRange(segment.start_at, segment.end_at)}
                    </ShiftRequest.Text>
                    <ShiftRequest.Text>{segment.department}</ShiftRequest.Text>
                    <ShiftRequest.Text>{segment.job}</ShiftRequest.Text>
                  </ShiftRequest.Segment>
                ))}
              </ShiftRequest.Row>
            )}
            <ShiftRequest.Row>
            {shift.primary_job ? shift.primary_job : null}
            </ShiftRequest.Row>
            {/* View less button */}
            <ShiftRequest.Row>
              <ShiftRequest.ViewLessButton
                onClick={() => this.handleViewLess()}
              >
                <FormattedMessage
                  id="RecentRequest.viewLess"
                  defaultMessage="View Less"
                />
              </ShiftRequest.ViewLessButton>
            </ShiftRequest.Row>
          </React.Fragment>
        )}
        {/*<div className="overlay"></div>*/}
        {(this.state.viewDetails) && <Modal>
            <ShiftDetails
              closeWin={this.closeModalWindow}
              shift={shift}
              closeWin2={this.handleBackButtonClicked}
              handleUserDeny={this.handleUserDeny}
              userDeniedApplicants={this.state.userDeniedApplicants}
              skills={skills}
              locationSettings={locationSettings}
              fetchShiftApplicants={fetchShiftApplicants}
              userPrivileges={userPrivileges}
              addApplicantJobTitle={addApplicantJobTitle}
            >
            </ShiftDetails>
          </Modal>}
         <ShiftRequest.Overlay display={this.state.viewDetails ? 'block' : 'none'} />
      </ShiftRequest>
      );
    }

    return (
      <ShiftRequest key={shift.id}>
        <ShiftRequest.Row>
          <div>
            {/* Avatars and cover text */}
            <ShiftRequest.Row marginBottom="1rem">
              <ShiftRequest.ProfileImages
                name={shift.name}
                urls={[
                  shift.coverer && shift.coverer.profile_image
                    ? shift.coverer.profile_image.thumb_url
                    : '/icons/channel avatar.svg',
                  shift.owner.profile_image
                    ? shift.owner.profile_image.thumb_url
                    : '/icons/channel avatar.svg',
                ]}
              />
              <ShiftRequest.Text modifiers="large">
                {this.renderCoverRequestString()}
              </ShiftRequest.Text>
            </ShiftRequest.Row>

            {shift.primary_job && <ShiftRequest.Row>
              <ShiftRequest.Text>
                {jobTitle}
              </ShiftRequest.Text>
            </ShiftRequest.Row>}
            {debug && <p>{debugInfo}</p>}

            {/* Date and time */}
            <ShiftRequest.Row marginBottom="0.5rem">
              {/* Date */}
              <ShiftRequest.DateTimeWrap>
                <ShiftRequest.Icon src={calendarIcon} />
                <ShiftRequest.Text>
                  {fullDate(shift.start_at)}
                </ShiftRequest.Text>
              </ShiftRequest.DateTimeWrap>
              {/* Time */}
              <ShiftRequest.DateTimeWrap>
                <ShiftRequest.Icon src={clockIcon} />
                <ShiftRequest.Text>
                  {startEndRange(shift.start_at, shift.end_at)}
                </ShiftRequest.Text>
              </ShiftRequest.DateTimeWrap>
              {/* Segments icon */}
              {shift.segment_count > 0 && (
                <ShiftRequest.Icon src={segmentsIcon} />
              )}
            </ShiftRequest.Row>
            {/* Shift reason */}
            <ShiftRequest.Row marginBottom={expanded && '1rem'}>
              {shift.content && (
                <React.Fragment>
                  <ShiftRequest.Icon src={speechIcon} />
                  <ShiftRequest.Reason>
                    {truncateString(
                      shift.content,
                      expanded ? 1000 : 48,
                      !expanded
                    )}
                  </ShiftRequest.Reason>
                </React.Fragment>
              )}
              {/* View more button */}
              {this.renderViewMoreButton()}
            </ShiftRequest.Row>
          </div>
          {/* Approve/Deny buttons */}
          <ShiftRequest.ActionBtnWrap>
            <ShiftRequest.ActionBtn
              onClick={() => {
                confirming
                  ? this.handleAction()
                  : this.handleConfirm(
                      locationConfig && locationConfig.custom_text
                        ? 'customText'
                        : 'approve'
                    );
              }}
            >
              {confirming ? (
                <FormattedMessage
                  id="RecentRequest.confirm"
                  defaultMessage="Confirm"
                />
              ) : (
                <FormattedMessage
                  id="RecentRequest.approve"
                  defaultMessage="Approve"
                />
              )}
            </ShiftRequest.ActionBtn>
            <ShiftRequest.ActionBtn
              modifiers={['danger']}
              onClick={() => {
                confirming ? this.handleCancel() : this.handleConfirm('deny');
              }}
            >
              {confirming ? (
                <FormattedMessage
                  id="RecentRequest.cancel"
                  defaultMessage="Cancel"
                />
              ) : (
                <FormattedMessage
                  id="RecentRequest.deny"
                  defaultMessage="Deny"
                />
              )}
            </ShiftRequest.ActionBtn>
          </ShiftRequest.ActionBtnWrap>
         
        </ShiftRequest.Row>
        {/* Segments */}
        {expanded && (
          <React.Fragment>
            {shift.segments && (
              <ShiftRequest.Row direction="column">
                {shift.segments.map((segment) => (
                  <ShiftRequest.Segment key={segment.id}>
                    <ShiftRequest.Text>
                      {startEndRange(segment.start_at, segment.end_at)}
                    </ShiftRequest.Text>
                    <ShiftRequest.Text>{segment.department}</ShiftRequest.Text>
                    <ShiftRequest.Text>{segment.job}</ShiftRequest.Text>
                  </ShiftRequest.Segment>
                ))}
              </ShiftRequest.Row>
            )}
            <ShiftRequest.Row>
            {shift.primary_job ? shift.primary_job : null}
            </ShiftRequest.Row>
            {/* View less button */}
            <ShiftRequest.Row>
              <ShiftRequest.ViewLessButton
                onClick={() => this.handleViewLess()}
              >
                <FormattedMessage
                  id="RecentRequest.viewLess"
                  defaultMessage="View Less"
                />
              </ShiftRequest.ViewLessButton>
            </ShiftRequest.Row>
          </React.Fragment>
        )}
      </ShiftRequest>
    );
  }
}

RecentRequest.propTypes = {
  locationConfig: PropTypes.shape({}).isRequired,
  shift: dashboardShapes.shiftShape.isRequired,
  util: PropTypes.shape({
    fetchPendingShiftSegments: PropTypes.func,
    hasSegments: PropTypes.func,
    handleApprove: PropTypes.func,
    handleDeny: PropTypes.func,
    toggleConfirmationSheet: PropTypes.func,
  }).isRequired,
};

//export default RecentRequest;
export default injectIntl(RecentRequest);
