import React from 'react';
import moment from 'moment';

import { injectIntl, FormattedMessage } from 'react-intl';

import DateTimePicker from 'components/GeneralComponents/DateTimePickerComponent/components/DateTimePicker/DateTimePicker';
import DateTimeButton from 'components/GeneralComponents/DateTimePickerComponent/components/DateTimeButton/DateTimeButton';

import { getLocaleCookie } from 'helpers/cookies';

import * as styled from './styled';
import * as blocks from '../blocks';
import messages from '../messages';
import ChannelDropdown from './ChannelDropdown';
import Modal from './Modal';
import CostCenter from './CostCenterContainer';
import RadioButton from './RadioButton/RadioButton';
import { DataNames } from './constants';

class ShiftRow extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      openCostCenterMenu: false,
      showCostCenterField: false,
      selectedItem: null,
      openSkillsMenu: false,
      selectedSkills: [],
      selectedChannel: null,
      channelMemberCount: 0,
      fullSkilledPersons: [],
      showChannelMembers: true,
      usersInCurrentChannel: [],
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.costCenterElement = React.createRef();
    this.winTop = '0px';
    this.winLeft = '0px';
    this.menuItemClicked = false;
  }
  

  componentDidMount() {
    const {xpoCostCenters, shift, selectedSkillIds, skills, channels, channelMembers, _updateSkills, } = this.props;

    document.addEventListener('mousedown', this.handleClickOutside);

    if(xpoCostCenters){
      this.setState({showCostCenterField: true});
    }

    if(shift && shift.cost_work_center) {
      if(xpoCostCenters){
        const idx = xpoCostCenters.tags.findIndex(
          (tag) => tag.tag_key === shift.cost_work_center
        );
        if(idx >= 0) {
          this.setState({selectedItem: xpoCostCenters.tags[idx].tag_content});
        }
      }
    }

    if(channels && channels.length > 0) {
      if(shift && !shift.channel) {
        this.setState({selectedChannel: channels[0]});
        this.setState({channelMemberCount: channels[0].member_count});
      }else {
        if(shift && shift.channel) {
          for(let i=0; i<channels.length; i++) {
            if(channels[i].value === parseInt(shift.channel)) {
              this.setState({selectedChannel: channels[i]});
              this.setState({channelMemberCount: channels[i].member_count});
            }
          }
        }
      }
    }


    if(selectedSkillIds && selectedSkillIds.length > 0 && selectedSkillIds.ids && selectedSkillIds.ids.length > 0) {
      let selected = [];
      if(skills && skills.length > 0) {
        for(let i=0; i<selectedSkillIds.ids.length; i++) {
          for(let j=0; j<skills.length; j++) {
            if(selectedSkillIds.ids[i] === skills[j].id) {
              //selected.push(skills[j])
              let obj = {id: 0, skill_content: '', current_location_user_ids: [], current_channel_user_ids: []};
              obj.id = skills[j].id;
              obj.current_location_user_ids = skills[j].current_location_user_ids;
              obj.skill_content = skills[j].skill_content;
              obj.current_channel_user_ids = this.findSkilledUsersInCurrentChannel(skills[j]);
              selected.push(obj);
            }
          }
        }
      }
      this.setState({selectedSkills: selected});
      let skillIds = [];
      for(let i=0; i<selected.length; i++) {
        skillIds.push(selected[i].id);
      }
      _updateSkills(index, skillIds);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { xpoCostCenters, locationSettings, selectedSkillIds, type, shift, 
          channels, _updateFullSkilledPersons, _updateJobTitle, index,  
          _updateNumberOfShifts, _updateDate,  channelMembers, skills, _updateSkills,} = this.props;
    const { selectedChannel, selectedSkills, } = this.state;
    if(prevProps.xpoCostCenters !== xpoCostCenters) {
      if(xpoCostCenters){
        this.setState({showCostCenterField: true});
      }
    }

    if(prevProps.type !== type) {
      this.setState({
        selectedSkills: [],
        openCostCenterMenu: false,
        showCostCenterField: false,
        selectedItem: null,
        openSkillsMenu: false,
        selectedChannel: null,
      });
      _updateJobTitle(index, '');
      _updateNumberOfShifts(index, '');
      _updateDate('TIMES', '', index);
    }

    if(prevState.selectedChannel !== selectedChannel) {
      let members = 0;
      if(selectedChannel && channels) {
        if(selectedChannel && channels.length > 0) {
          for(let i=0; i<channels.length; i++) {
            if(channels[i].value === selectedChannel.value) {
              members = channels[i].member_count;
              this.setState({channelMemberCount: members});
              break;
            }
          }
        }
      }else if(channels && channels.length > 0 && shift) {
        if(!shift.channel) {
          this.setState({selectedChannel: channels[0]});
          this.setState({channelMemberCount: channels[0].member_count});
        }else {
          for(let i=0; i<channels.length; i++) {
            if(channels[i].value === parseInt(shift.channel)) {
              this.setState({selectedChannel: channels[i]});
              this.setState({channelMemberCount: channels[i].member_count});
            }
          }
        }
      }

      let selected = [];
      if(skills && skills.length > 0 &&  selectedSkillIds && selectedSkillIds.ids && selectedSkillIds.ids.length > 0) {
        for(let i=0; i<selectedSkillIds.ids.length; i++) {
          for(let j=0; j<skills.length; j++) {
            if(selectedSkillIds.ids[i] === skills[j].id) {
              //selected.push(skills[j])
              let obj = {id: 0, skill_content: '', current_location_user_ids: [], current_channel_user_ids: []};
              obj.id = skills[j].id;
              obj.current_location_user_ids = skills[j].current_location_user_ids;
              obj.skill_content = skills[j].skill_content;
              obj.current_channel_user_ids = this.findSkilledUsersInCurrentChannel(skills[j]);
              selected.push(obj);
            }
          }
        }
      }
      this.setState({selectedSkills: selected});
      let skillIds = [];
      for(let i=0; i<selected.length; i++) {
        skillIds.push(selected[i].id);
      }
      _updateSkills(index, skillIds);
    }

    if(prevState.selectedSkills !== selectedSkills) {
      const fullSkilledPersons = this.findFullSkilledPersons();
      this.setState({fullSkilledPersons: fullSkilledPersons});
      _updateFullSkilledPersons(index, fullSkilledPersons);
    }

    if(prevProps.channelMembers !== channelMembers) {
      //this.clearSelectedSkills();
    }
  }

  componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

  UNSAFE_componentWillReceiveProps(newProps) {
    const shiftOpen = newProps.shift.datePickerOpen;

    // if datepicker is closed from outside sources
    if (shiftOpen) {
      this.removeWindowEventListener();
      this.addWindowEventListener();
    }

    // if datepicker is opened from outside sources
    if (!shiftOpen) {
      this.removeWindowEventListener();
    }
  }

  addWindowEventListener = () => {
    window.addEventListener('click', this._handleEventListener, false);
  };

  removeWindowEventListener = () => {
    window.removeEventListener('click', this._handleEventListener, false);
  };

  _handleEventListener = (e) => {
    e.stopPropagation();
    if (this.dateTimeWrapper && !this.dateTimeWrapper.contains(e.target)) {
      this._toggleDatePicker();
    }
  };

  setDateTimeWrapperRef = (node) => {
    this.dateTimeWrapper = node;
  };

  clearSelectedSkills = () => {
    this.setState({
      selectedSkills: [],
      fullSkilledPersons: [],
    });
  }

  _toggleDatePicker = () => {
    const { index, shift, _openDatePicker, _closeDatePicker } = this.props;

    if (!shift.datePickerOpen) {
      _openDatePicker(index);
      this.removeWindowEventListener();
      this.addWindowEventListener();
    } else {
      this.removeWindowEventListener();
      _closeDatePicker(index);
    }
  };

  _updateState = (...args) => {
    const { index, _updateDate, _closeDatePicker } = this.props;

    if (args[0] === 'DATE' && args[1] === null) {
      _closeDatePicker(index);
    }

    _updateDate(args[0], args[1], index);
  };

  _changeChannel = (value) => {
    const { changeChannel, index, _updateSkills, channels, _updateFullSkilledPersons } = this.props;

    if(channels && channels.length > 0) {
      for(let i=0; i<channels.length; i++) {
        if(channels[i].value === parseInt(value)) {
          this.setState({selectedChannel: channels[i]});
          break;
        }
      }
    }

    changeChannel(index, value);
    _updateSkills(index, []);
    _updateFullSkilledPersons(index, []);
    this.clearSelectedSkills();
  };

  _updateReason = (e) => {
    const { updateReason, index } = this.props;

    updateReason(index, e.target.value);
  };

  _updateNumberOfShifts = (e) => {
    const { _updateNumberOfShifts, index } = this.props;

    _updateNumberOfShifts(index, e.target.value);
  };

   _updateMaxApplicants = (e) => {
    const { _updateMaxApplicants, index } = this.props;

    _updateMaxApplicants(index, e.target.value);
  };

   _updateJobTitle = (e) => {
    const { _updateJobTitle, index } = this.props;
    
    _updateJobTitle(index, e.target.value);
  };

  _deleteRow = () => {
    const { index, deleteRow } = this.props;

    deleteRow(index);
  };

  _renderDeleteButton = () => {
    const { index } = this.props;

    if (index !== 0) {
      return (
        <styled.DeleteRowButtonWrapper onClick={this._deleteRow}>
          <styled.DeleteRowButton />
        </styled.DeleteRowButtonWrapper>
      );
    }

    return null;
  };

  _handleCostCenterClick = (event) => {
    if(this.state.openCostCenterMenu){
      this.menuItemClicked = false;
    }
    this.setState({openCostCenterMenu: !this.state.openCostCenterMenu})
    event.preventDefault();
  }

  _handleAddSkillsClick = (event) => {
    event.preventDefault();
    this.setState({openSkillsMenu: !this.state.openSkillsMenu})
  }

  _handleMenuItemClick = (tagKey, tagContent) => {
    const {_updateCostCenter, index} = this.props;
    this.setState({selectedItem: tagContent});

    //parent callback
    _updateCostCenter(index, tagKey);
    this.setState({openCostCenterMenu: false});
    this.menuItemClicked = true;
  }

  handleClickOutside = (event) => {
    //close cost center menu if nothing selected
    if(this.state.openCostCenterMenu && !this.menuItemClicked) {
      //this.setState({openCostCenterMenu: false});
    }
  }

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  sortSkills = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content=== null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  renderSkills = () => {
    const { channels, _updateState, skills, channelMembers, } = this.props;
    const {selectedSkill, selectedChannel, } = this.state;

    const copyArray = [...skills];
    this.sortSkills(copyArray, true);

    if(copyArray) {
      const mySkills = copyArray && copyArray.map((skill) => (
        <styled.RadioButtonWrap
          key={skill.id}
          className="post-shift__radio-item post-shift__radio-item--groups"
        >
          <RadioButton
            checked={this.isSkillSelected(skill)}
            id={skill.id}
            label={skill.skill_content}
            //members={skill.current_location_user_ids.length}
            members={this.findSkilledUsersInCurrentChannel(skill)}
            onChange={this.updateRadioButtonState}
          />
        </styled.RadioButtonWrap>
      ));
      if (mySkills) {
        return mySkills;
      }
    }
  };

  updateRadioButtonState = (action, data) => {
    const { skills, _updateSkills, index, } = this.props;
    const { selectedSkills, } = this.state;

    switch(action) {
      case DataNames.REACH:
        const idx = skills.findIndex(
          (skill) => skill.id === data.skill_id
        );
        if(idx >= 0) {
          const selectedArr = [...selectedSkills];
          const idx1 = selectedSkills.findIndex(
            (item) => item.id === skills[idx].id
          )
          if(idx1 >= 0) {
            selectedArr.splice(idx1, 1);
          }else {
            let obj = {id: 0, skill_content: '', current_location_user_ids: [], current_channel_user_ids: []};
            obj.id = skills[idx].id;
            obj.current_location_user_ids = skills[idx].current_location_user_ids;
            obj.skill_content = skills[idx].skill_content;
            obj.current_channel_user_ids = data.members;
            //selectedArr.push(skills[idx]);
            selectedArr.push(obj);
          }
          this.setState({selectedSkills: selectedArr});
          let skillIds = [];
          for(let i=0; i<selectedArr.length; i++) {
            skillIds.push(selectedArr[i].id);
          }
          _updateSkills(index, skillIds);
        }
        break;
    }
  };

  isSkillSelected = (skill) => {
    const { selectedSkills, } = this.state;
    const idx = selectedSkills.findIndex(
      (item) => item.id === skill.id
    );
    if(idx >= 0) {
      return true;
    }else {
      return false;
    }
  }

  findSkilledUsersInCurrentChannel = (skill) => {
    const { channels, channelMembers, } = this.props;
    const { selectedChannel, } = this.state;
    const ids = skill.current_location_user_ids;
    let membersWithSkill = [];
    if(!channels || channels.length === 0 || !selectedChannel) {
      return [];
    }
    if(selectedChannel.value === channels[0].value) {
      membersWithSkill = ids;
    }else {
      for(let i=0; i<channelMembers.length; i++) {
        for(let j=0; j<ids.length; j++) {
          if(channelMembers[i].id === ids[j]) {
            membersWithSkill.push(channelMembers[i].id);
            //const msg = `Find skilled user: channel member ${i}, id: ${channelMembers[i].id}, skill ${skill.skill_content}, user ${ids[j]}`;
            //console.log(msg);
          }
        }
      }
    }
    return membersWithSkill;
  };

  findFullSkilledPersons = () => {
    const { channelMembers, } = this.props;
    const { selectedSkills, selectedChannel, } = this.state;
    let personsWithAllSkills = [];
    if(selectedSkills && selectedSkills.length > 0 && selectedSkills[0].current_channel_user_ids) {
      for(let x=0; x<selectedSkills[0].current_channel_user_ids.length; x++) {
        personsWithAllSkills.push(selectedSkills[0].current_channel_user_ids[x]);
      }
      if(personsWithAllSkills.length > 0) {
        for(let i=0; i<personsWithAllSkills.length; i++) {
          for(let j=1; j<selectedSkills.length; j++) {
            if(selectedSkills[j]) {
              //find identical elements in two arrays
              //personsWithAllSkills = personsWithAllSkills.filter(person => selectedSkills[j].current_location_user_ids.includes(person));
              personsWithAllSkills = personsWithAllSkills.filter(person => selectedSkills[j].current_channel_user_ids.includes(person));
            }
          }
        }
      }
    }

    return personsWithAllSkills;
  }

  handleChannelToggled = (open) => {
    if(open) {
      this.setState({showChannelMembers: true});
    }else {
      this.setState({showChannelMembers: false});
    }
  }


  render() {
    const { intl, index, shift, cloneRow, channels, disabled, type, xpoCostCenters, skills, locationSettings, } = this.props;
    const {openCostCenterMenu, showCostCenterField, selectedItem, selectedSkill, openSkillsMenu, 
          selectedSkills, channelMemberCount, fullSkilledPersons, showChannelMembers, } = this.state;

    const imgUrl = openCostCenterMenu ? '/icons/up-arrow.svg' : '/icons/down-arrow.svg';
    const imgUrlSkills = openSkillsMenu ? '/icons/up-arrow.svg' : '/icons/down-arrow.svg';

    const dateString = shift.start_at
      ? moment(shift.start_at).format('MM/DD/YYYY')
      : intl.formatMessage(messages.dateFormat);
    const timeRangeString =
      shift.start_at && shift.end_at
        ? `${moment(shift.start_at).format('hh:mm a')} to ${moment(
            shift.end_at
          ).format('hh:mm a')}`
        : intl.formatMessage(messages.startTimeToEndTime);

    const winSize = this.getWindowDimensions();

    if(this.costCenterElement && this.costCenterElement.current) {
      this.winTop = `${this.costCenterElement.current.parentElement.offsetTop
        - this.costCenterElement.current.offsetTop}px`;
      this.winLeft = `${this.costCenterElement.current.parentElement.offsetLeft}px`;

      if(winSize.height - this.costCenterElement.current.parentElement.offsetTop < 50){
        this.winTop = `${winSize.height - 100}px`;
      }
    }


    const txtSelectedSkills = (<FormattedMessage
                  id="ShiftRow.selected"
                  defaultMessage="Selected Skills"
                />);

    const numSelectedSkills = (<FormattedMessage
                    id="ShiftRow.selectedSkills"
                    defaultMessage="{number, plural, one {{number} Skill Selected} other {{number} Skills Selected}}" 
                    values={{number: selectedSkills.length}}/>);  
    const txtReach = (<FormattedMessage
                  id="ShiftRow.reach"
                  defaultMessage="Audience Reach"
                />);
    const numLocationMembers = (<FormattedMessage
                    id="ShiftRow.locationMembers"
                    defaultMessage="{number, plural, one {{number} member} other {{number} members}}" 
                    values={{number: channelMemberCount}}/>);  
    const numSkillMembers = (<FormattedMessage
                    id="ShiftRow.skillMembers"
                    defaultMessage="{number, plural, one {{number} member} other {{number} members}}" 
                    values={{number: fullSkilledPersons.length}}/>);  

    let textToShow = '';
    let skillNames = '';
    if(selectedSkills.length > 0) {
      for(let i=0; i<selectedSkills.length; i++) {
        skillNames += `${selectedSkills[i].skill_content}, `;
      }
    }else {
      if(shift && shift.skill_ids) {
        for(let i=0; i<shift.skill_ids.length; i++) {
          for(let j= 0; j<skills.length; j++) {
            if(shift.skill_ids[i] === skills[j].id) {
              skillNames += `${skills[j].skill_content}, `;
            }
          }
        }
      }
    }
    if(openSkillsMenu) {
      textToShow = numSelectedSkills;
    }else {
      if(shift && shift.skill_ids && shift.skill_ids.length > 0) {
        textToShow = skillNames;
      }else {
        textToShow = txtSelectedSkills;
      }
    }

    let showRedText = false;
    if(selectedSkills.length > 0 && fullSkilledPersons.length === 0) {
      showRedText = true;
    }

    return (
      <blocks.PageRow>
        <blocks.ShiftRow>
          <blocks.ShiftRowSection>
            <blocks.ShiftRowElement ref={this.setDateTimeWrapperRef}>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.timePeriod"
                  defaultMessage="Time Period"
                />
              </styled.InputLabel>
              <DateTimeButton
                _toggleDatePicker={this._toggleDatePicker}
                dateString={dateString}
                timeRangeString={timeRangeString}
              />
              <styled.DateTimeWrapper show={shift.datePickerOpen}>
                <DateTimePicker
                  _toggleDatePicker={this._toggleDatePicker}
                  _closeDatePicker={this._clickOutsideOfDatePicker}
                  _updateState={this._updateState}
                  date={shift.start_at}
                  dates={[shift.start_at, shift.end_at]}
                  locale={getLocaleCookie()}
                />
              </styled.DateTimeWrapper>
            </blocks.ShiftRowElement>

            <blocks.ShiftRowElement>
               <styled.InputLabel>
                  <FormattedMessage
                    id="ShiftRow.jobTitle"
                    defaultMessage="Job Title"
                  />
              </styled.InputLabel>
              <styled.JobTitle
                  value={shift.jobTitle}
                  onChange={this._updateJobTitle}
              />
              {/*<styled.Warning>
                <FormattedMessage
                  id="ShiftRow.jobTitleWarning"
                  defaultMessage="*Required"
                />
              </styled.Warning>*/}
            </blocks.ShiftRowElement>

            <blocks.ShiftRowElement>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.quantity"
                  defaultMessage="Shift Quantity"
                />
              </styled.InputLabel>
              <styled.OfferInput
                value={shift.number_of_shifts}
                onChange={this._updateNumberOfShifts}
              />
            </blocks.ShiftRowElement>
            {((type && type === 'open_shift') && showCostCenterField) && <blocks.ShiftRowElement>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.costCenter"
                  defaultMessage="Cost Center"
                />
              </styled.InputLabel>
              <styled.CostCenter
                onClick={this._handleCostCenterClick}
                imgUrl={imgUrl}
                ref={this.costCenterElement}>
                {selectedItem ? selectedItem : null}
              </styled.CostCenter>
            </blocks.ShiftRowElement>}

            <blocks.ShiftRowElement>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.shiftDescription"
                  defaultMessage="Description"
                />
              </styled.InputLabel>
              <styled.TextArea
                value={shift.reason ? shift.reason : ''}
                onChange={this._updateReason}
              />
            </blocks.ShiftRowElement>
            <blocks.ShiftRowElement>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.postToGroup"
                  defaultMessage="Post to Group"
                />
              </styled.InputLabel>
              <ChannelDropdown
                options={channels}
                currentElement={shift.channel}
                handleChange={this._changeChannel}
                toggled={this.handleChannelToggled}
              />
              {(selectedSkills.length === 0 && showChannelMembers) && <styled.MembersWrap>
                <styled.Reach>{txtReach}</styled.Reach>
                <styled.Members>{numLocationMembers}</styled.Members>
              </styled.MembersWrap>}
            </blocks.ShiftRowElement>

            {locationSettings.cfg__skill === 1 && <blocks.ShiftRowElement>
              <styled.InputLabel>
                <FormattedMessage
                  id="ShiftRow.addSkills"
                  defaultMessage="Add Skills"
                />
              </styled.InputLabel>
              <styled.SkillsButton
                onClick={this._handleAddSkillsClick}
                imgUrl={imgUrlSkills}
                open={openSkillsMenu}>
                <styled.ButtonLabel>{textToShow}</styled.ButtonLabel>
              </styled.SkillsButton>
              {openSkillsMenu && <styled.SkillsWrap>
                {this.renderSkills()}
              </styled.SkillsWrap>}
              {selectedSkills.length > 0 && <styled.MembersWrap>
                <styled.Reach showRedText={showRedText}>{txtReach}</styled.Reach>
                <styled.Members showRedText={showRedText}>{numSkillMembers}</styled.Members>
              </styled.MembersWrap>}
            </blocks.ShiftRowElement>}

          </blocks.ShiftRowSection>

          <blocks.ShiftRowSection>
            <styled.CopyRowButtonWrapper
              onClick={() => {
                cloneRow(index);
              }}
            >
              {disabled ? <styled.CopyRowButtonGrayedOut/> : <styled.CopyRowButton />}
            </styled.CopyRowButtonWrapper>
            {this._renderDeleteButton()}
          </blocks.ShiftRowSection>

          {this.state.openCostCenterMenu && <Modal>
            <CostCenter 
            top={this.winTop} 
            left={this.winLeft}
            handleMenuItemClick={this._handleMenuItemClick}/>
          </Modal>}
        </blocks.ShiftRow>
      </blocks.PageRow>
    );
  }
}

export default injectIntl(ShiftRow);
