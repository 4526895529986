import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import PostingRow from './PostingRow';

const Row = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid #dedede;

  @media (max-width: 680px) {
    display: block;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #dedede;
  margin-left: 12px;
  margin-right: 12px;
  @media (max-width: 680px) {
    display: block;
  }

`;

const Column = styled.div`
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
    //width: 10%;
  }

  &:first-child) {
    //width: 30%;
  }

  &:not(:first-child) {
    //width: 30%;
  }

  @media (max-width: 680px) {
    margin-right: 0px;
    margin-bottom: 0.75rem;
  }
`;

const DateCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  margin-left: 20px;
`;

const ReasonCell = styled.p.attrs((props) => ({
  'aria-label': props.ariaLabel,
  title: props.title,
}))`
  //max-width: 260px;
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  margin-left: 20px;
`;

const PostCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  margin-left: 20px;
`;

const SkillCell = styled.p `
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 14px;
  font-weight: 200;
  display: block;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
  margin-left: 20px;
`;

const messages = defineMessages({
  open_shift: {
    id: 'PreviewRow.openShift',
    defaultMessage: 'Open Shift',
  },
  shift_vto: {
    id: 'PreviewRow.vto',
    defaultMessage: 'VTO',
  },
});

const createSkillInfo = (shift, skills ) => {
  let skillInfo = '';
  let skillNames = '';
  if(shift && shift.skill_ids && shift.skill_ids.length > 0 && skills && skills.length > 0) {
    for(let i=0; i<shift.skill_ids.length; i++) {
      for(let j=0; j<skills.length; j++) {
        if(shift.skill_ids[i] === skills[j].id) {
          skillNames += `${skills[j].skill_content}, `
        }
      }
    }
  }
  if(skillNames.length > 2) {
    skillNames = skillNames.substring(0, skillNames.length -2); //remove last ','
  }

  if(shift.skill_ids && shift.skill_ids.length > 0) {
      skillInfo = (<FormattedMessage
          id="PreviewRow.skills"
          defaultMessage="Skills: {skills}"
          values={{ skills: skillNames }}
        />);
    }else {
      skillInfo = (<FormattedMessage
          id="PreviewRow.noSkills1"
          defaultMessage="Skills: None Added"
        />);
    }
  
  return skillInfo;
};

let skillInfo = [];

const PreviewRow = ({ intl, type, shift, currentChannel, skills, locationSettings, index, }) => {
  const output = createSkillInfo(shift, skills);
  let obj = {index: 0, content: '', skillNames: ''};
  obj.index = index;
  obj.content = shift.reason;
  obj.skillNames = output;
  if(skillInfo[index]) {
    skillInfo.splice(index, 1);
    skillInfo.push(obj);
  }else {
    skillInfo.push(obj);
  }
  
  return (
    <TableRow>
      <td>
        <DateCell>
          <FormattedMessage
            id="PreviewRow.dateRange"
            defaultMessage="{startDate} to {endDate}"
            values={{
              startDate: moment(shift.start_at).format('MM/DD/YYYY hh:mm a'),
              endDate: moment(shift.end_at).format('hh:mm a'),
            }}
          />
        </DateCell>
      </td>

      <td>  
        <ReasonCell title={shift.reason} ariaLabel={shift.reason}>
          {shift.reason ? shift.reason : ''}
        </ReasonCell> 
      </td>
      <td>
        <PostCell>
          <FormattedMessage
            id="PreviewRow.description"
            defaultMessage="Posting a notice with {numberOfShifts} {type} offers to team {teamName}"
            values={{
              type: intl.formatMessage(messages[type]),
              numberOfShifts: <strong>{shift.number_of_shifts}</strong>,
              teamName: <strong>{currentChannel}</strong>,
            }}
          />
        </PostCell>
      </td>
      {locationSettings.cfg__skill === 1 && <td>
        <SkillCell>
        {output}
        </SkillCell>
      </td>}
    </TableRow>
  );
};

const ConfirmationRow = ({
  intl,
  index,
  type,
  shift,
  currentChannel,
  retry,
  skills,
  locationSettings,
}) => {
  if (shift.posting.posting || shift.posting.error || shift.posting.success) {
    return (
      <PostingRow
        type={type}
        index={index}
        shift={shift}
        currentChannel={currentChannel}
        retry={retry}
        skills={skills}
        locationSettings={locationSettings}
        skillInfo={skillInfo}
      />
    );
  }

  return (
    <PreviewRow
      intl={intl}
      // eslint-disable-next-line react/no-array-index-key
      type={type}
      shift={shift}
      currentChannel={currentChannel}
      skills={skills}
      locationSettings={locationSettings}
      index={index}
    />
  );
};

export default injectIntl(ConfirmationRow);
