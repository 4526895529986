import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 3rem;
  margin-right: 3rem;
`;

const BodyText = styled.p`
  margin-bottom: 0;
`;

const ActionWrap = styled.div`
  margin: 0 1.5rem;
  display: flex;
`;

export default {
  Wrapper,
  BodyText,
  ActionWrap,
};
