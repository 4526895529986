import { combineReducers } from 'redux';
import globalReducer from '_data/modules/rootReducer';
import appReducer from 'App/duck';
import teamReducer from 'pages/Team/duck';
import teamFeedReducer from 'pages/TeamFeed/duck';
import teamProfileReducer from 'pages/TeamProfile/duck';
import dashboardDuckReducer from 'pages/Dashboard/duck';
import calendarDuckReducer from 'pages/Calendar/duck';
import scheduleReducer from 'pages/Scheduler/duck';
import shiftReducer from 'pages/Shift/duck';
import userManagementReducer from 'pages/UserManagement/duck';


import { getLocationCode, getLocaleCookie } from 'helpers/cookies';
import { getBrowserLanguage } from 'helpers/localization';
import { calendarReducer } from './calendar-reducer';
import { SHIFT_MANAGED, SHIFT_PENDING } from '../actions/pubnub-actions';
import {
  CHANGE_LOCATION,
  LOCATION_DATA_LOADED,
  LOCATION_LOAD_SUCCESS,
  LOCATION_KPI_DATA_LOADED,
  LOCATION_KPI_DATA_LOADING,
  SHIFT_REQUESTS_DATA_LOADED,
  SHIFT_REQUESTS_DATA_LOADING,
  CHANGE_PENDING_SHIFT_STATUS,
  MANAGED_SHIFTS_LOADED,
  MANAGED_SHIFTS_LOADING,
  SETUP_ACTIONS_LOADING,
  SETUP_ACTIONS_LOADED,
  SETUP_ACTIONS_NEEDED,
  GEOFENCE_CHECK,
  GEOFENCE_SUCCESS,
  GEOFENCE_FAILURE,
  LOCALE_CHANGE,
} from '../actions/dashboard-actions';

const findScheduleElement = (shift, array) =>
  array.findIndex((element) => shift.id == element.id);

const locationReducer = (
  state = {
    locations: [],
    location_data: {},
    location_code: getLocationCode(),
    locations_called: false,
    setup_actions: {},
    setup_actions_loading: true,
  },
  action
) => {
  switch (action.type) {
    case CHANGE_LOCATION:
      return { ...state, location_code: action.location_code };

    case LOCATION_LOAD_SUCCESS:
      return { ...state, locations: action.locations, locations_called: true };

    case LOCATION_DATA_LOADED:
      return { ...state, location_data: action.data };

    default:
      return state;
  }
};

const setupReducer = (
  state = {
    setup_actions: {},
    setup_actions_loading: true,
    setup_needed: [],
  },
  action
) => {
  switch (action.type) {
    case SETUP_ACTIONS_LOADING:
      return { ...state, setup_actions_loading: true };

    case SETUP_ACTIONS_LOADED:
      return {
        ...state,
        setup_actions: action.setupActions,
        setup_actions_loading: false,
      };

    case SETUP_ACTIONS_NEEDED:
      return { ...state, setup_needed: action.setupNeeded };

    default:
      return state;
  }
};

const geofenceReducer = (
  state = {
    is_calculating: false,
    timestamp: '',
    is_inside_fence: false,
    fence_error: {},
  },
  action
) => {
  switch (action.type) {
    case GEOFENCE_CHECK:
      return {
        ...state,
        is_calculating: true,
        timestamp: action.timestamp,
        fence_error: {},
      };

    case GEOFENCE_SUCCESS:
      return {
        ...state,
        is_calculating: false,
        is_inside_fence: true,
        fence_error: {},
      };

    case GEOFENCE_FAILURE:
      return {
        ...state,
        is_calculating: false,
        is_inside_fence: false,
        fence_error: action.error,
      };

    default:
      return state;
  }
};

const dashboardReducer = (
  state = {
    firstSeven: {},
    prevSeven: {},
    pendingShiftsLoading: true,
    pendingShifts: [],
    managedShifts: [],
    managedShiftsLoading: true,
  },
  action
) => {
  switch (action.type) {
    case LOCATION_KPI_DATA_LOADED:
      return {
        ...state,
        firstSeven: action.kpis.firstSeven,
        prevSeven: action.kpis.prevSeven,
        loading: false,
      };

    case LOCATION_KPI_DATA_LOADING:
      return { ...state, firstSeven: {}, prevSeven: {}, loading: true };

    case SHIFT_REQUESTS_DATA_LOADING:
      return { ...state, pendingShifts: [], pendingShiftsLoading: true };

    case SHIFT_REQUESTS_DATA_LOADED:
      return {
        ...state,
        pendingShifts: action.shifts,
        pendingShiftsLoading: false,
      };

    case CHANGE_PENDING_SHIFT_STATUS:
      return {
        ...state,
        pendingShifts: state.shifts.filter((element) => element !== action.id),
      };

    case MANAGED_SHIFTS_LOADED:
      return {
        ...state,
        managedShifts: action.shifts.sort(
          (a, b) => new Date(b.manager_time) - new Date(a.manager_time)
        ),
        managedShiftsLoading: false,
      };

    case MANAGED_SHIFTS_LOADING:
      return { ...state, managedShiftsLoading: true };

    case SHIFT_MANAGED:
      return {
        ...state,
        pendingShifts: state.pendingShifts.filter(
          (element) => element.id !== action.shift.id
        ),
        managedShifts:
          findScheduleElement(action.shift, state.managedShifts) === -1
            ? [...state.managedShifts, action.shift].sort(
                (a, b) => new Date(b.manager_time) - new Date(a.manager_time)
              )
            : [...state.managedShifts],
      };

    case SHIFT_PENDING:
      // @TODO create monad out of this
      return {
        ...state,
        pendingShifts: [...state.pendingShifts]
          .filter((element) => element.id !== action.shift.id)
          .concat([action.shift])
          .sort((a, b) => new Date(a.start_at) - new Date(b.start_at)),
      };

    default:
      return state;
  }
};

const localeReducer = (
  state = {
    supportedLocales: [
      {
        name: 'English',
        lang: 'en',
      },
      {
        name: 'Español',
        lang: 'es',
      },
      {
        name: 'Français',
        lang: 'fr',
      },
    ],
    // Set language to browser setting by default
    locale: getLocaleCookie() || getBrowserLanguage(),
  },
  action
) => {
  switch (action.type) {
    case LOCALE_CHANGE:
      return {
        ...state,
        locale: action.locale,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  calendarReducer,
  calendarDuckReducer,
  locationReducer,
  setupReducer,
  dashboardReducer,
  dashboardDuckReducer,
  geofenceReducer,
  localeReducer,
  teamReducer,
  teamFeedReducer,
  teamProfileReducer,
  globalReducer,
  appReducer,
  scheduleReducer,
  shiftReducer,
  userManagementReducer,
});

export default reducer;
