import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  filters: slices.filtersSlice.reducer,
  managers: slices.managersSlice.reducer,
  associates: slices.associatesSlice.reducer,
  removedUsers: slices.removedUsersSlice.reducer,
  pendingUsers: slices.pendingUsersSlice.reducer,
  channelMembers: slices.channelMembersSlice.reducer,
  channels: slices.channelsSlice.reducer,
  allUsers: slices.allUsersSlice.reducer,
};

const reducer = combineReducers({
  filters: reducers.filters,
  managers: reducers.managers,
  associates: reducers.associates,
  removedUsers: reducers.removedUsers,
  pendingUsers: reducers.pendingUsers,
  channelMembers: reducers.channelMembers,
  channels: reducers.channels,
  allUsers: reducers.allUsers,
});

export default reducer;
