import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import 'helpers/findIndex-shim';

import { validateEmail } from 'helpers/validation';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation';
import PhoneConfirmation from './PhoneConfirmation/PhoneConfirmation';

import './LoginSecondStage.scss';

class LoginSecondStage extends React.Component {
  state = {
    validity: true,
    email: this.props.email,
    phone: this.props.phone,
    password: '',
    mobilePin: '',
  };

  _emailChange = (e) => {
    const [value] = e.target;

    this.setState({
      validity: validateEmail(value),
      value,
    });
  };

  _passwordChange = (e) => {
    const { value } = e.target;

    this.setState({
      password: value,
    });
  };

  _mobilePinChange = (val) => {
    this.setState({
      mobilePin: val,
    });
  };

  _phoneChange = (status, number, country) => {
    this.setState({
      value: `${country.dialCode}${number}`,
      validity: status,
    });
  };

  _submitForm = (e) => {
    e.preventDefault();
    if (this.props.type === 'email') {
      this.props._passwordSubmit(this.state.email, this.state.password);
    } else if (this.props.type === 'phone') {
      this.props._pinSubmit(this.state.mobilePin);
    }
  };

  _loginWithEmail = () => {
    // api call with email/password combination
    this.props._passwordSubmit(this.state.password);
  };

  _loginWithPhone = (pin) => {
    // api call with phone/pin combination
    this.props._pinSubmit(pin);
  };

  _renderConfirmationComponent = () => {
    if (this.props.type === 'email') {
      return (
        <EmailConfirmation
          value={this.state.email}
          validity={this.state.validity}
          inputChange={this._emailChange}
          passwordChange={this._passwordChange}
        />
      );
    }
    if (this.props.type === 'phone') {
      return (
        <PhoneConfirmation
          value={this.state.phone}
          validity={this.state.validity}
          inputChange={this.phoneChange}
          _pinChange={this._mobilePinChange}
          mobilePinError={this.props.mobilePinError}
        />
      );
    }
    return null;
  };

  render() {
    const errorClass = classNames({
      //show: this.props.mobilePinError.length > 0,
      show: String(this.props.mobilePinError).length > 0,
    });

    const passwordErrorClass = classNames({
      show: this.props.emailPasswordError.length > 0,
    });

    return (
      <form className="login__form" onSubmit={this._submitForm}>
        {this._renderConfirmationComponent()}

        <section className="login__form-action">
          <p className="login__back">
            {this.props._backButtonText || (
              <FormattedMessage
                id="LoginSecondStage.changeMethods"
                defaultMessage="Want to change authentication methods?"
              />
            )}
            <br />
            <a className="login__link" onClick={this.props.back} href="#">
              <FormattedMessage
                id="LoginSecondStage.clickHere"
                defaultMessage="Click Here"
              />
            </a>
          </p>
        </section>

        <section className="login__form-errors">
          <p
            className={`login__form-error-text login__form-error-text--center ${errorClass}`}
          >
            {this.props.mobilePinError}
          </p>

          <p
            className={`login__form-error-text login__form-error-text--center ${passwordErrorClass}`}
          >
            {this.props.emailPasswordError}
          </p>
        </section>

        <section className="login__form-action">
          <button
            className="login__form-submit button button-primary"
            type="submit"
          >
            <FormattedMessage
              id="LoginSecondStage.logIn"
              defaultMessage="Log In"
            />
          </button>
        </section>

        <section className="login__form-errors" />
      </form>
    );
  }
}

//Fixed bug WS-1243. Changed "mobilePinError" type from string to object
LoginSecondStage.propTypes = {
  type: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  _pinSubmit: PropTypes.func.isRequired,
  mobilePinError: PropTypes.string,
  emailPasswordError: PropTypes.string,
  _backButtonText: PropTypes.string,
};

LoginSecondStage.defaultProps = {
  email: '',
  phone: '',
  mobilePinError: '',
  emailPasswordError: '',
  _backButtonText: '',
};

export default LoginSecondStage;
