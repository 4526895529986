import Cookies from 'js-cookie';

import { isEmptyCookie } from 'helpers/formatting';
import { COOKIE_DOMAIN } from 'constants/index';
import { API_SERVER_VERSION } from 'constants.js';
import { getBrowserLanguage } from './localization';

export const authHeaders = {
  5: [
    'Auth-Target',
    'Auth-Category',
    'uid',
    'client',
    'expiry',
    'token-type',
    'access-token',
  ],
  6: ['session-uukey'],
};

const nonAuthHeaders = [
  'user_id',
  'location_id',
  'first_name',
  'last_name',
  'profile_image',
  'time_zone',
  'locale',
  'phone_number',
  'email',
  'position',
];

const possibleHeaders = authHeaders[API_SERVER_VERSION].concat(nonAuthHeaders);

function getCookieDomain() {
  return COOKIE_DOMAIN || window.location.hostname;
}

const getDomainName = () => window.location.hostname;
const defaultCookieAttr = {
  expires: 365,
  domain: getCookieDomain(),
  // Comment out the following line when viewing from IP address
  secure: !!COOKIE_DOMAIN,
};

/*
  Internal API
*/

const setCookie = (cookieName, cookieVal) => {
  Cookies.set(cookieName, cookieVal, defaultCookieAttr);
};

const getCookie = (cookieName) => Cookies.get(cookieName);

const getNullCookie = (cookieName) => {
  const cookieVal = getCookie(cookieName);

  return isEmptyCookie(cookieVal) ? null : cookieVal;
};

const removeCookie = (cookieName) => {
  Cookies.remove(cookieName, defaultCookieAttr);
};

export const setLoginURL = () => {
  removeCookie('login_url');
  setCookie('login_url', getDomainName());
};

export const purgeAuthenticationCookie = () => {
  possibleHeaders.forEach((key) => {
    removeCookie(key);
  });
};

export const setAuthenticationCookie = (headers) => {
  purgeAuthenticationCookie();
  Object.keys(headers).forEach((key) => {
    setCookie(key, headers[key]);
  });

  setLoginURL();
};

export const getSwiftCode = () => {
  return getCookie('swift_code');
};

/**
 * @returns { Object } headerObject - returns object containing auth headers for API calls
 */
export const getLocaleCookie = () => getNullCookie('locale');

export const createHeadersForApiCall = () => {
  const headerObject = {
    'Content-Type': 'application/json',
    Locale: getLocaleCookie(),
  };

  authHeaders[API_SERVER_VERSION].forEach((key) => {
    headerObject[key] = getCookie(key);
  });

  return headerObject;
};

export const createHeadersForPDFUploading = () => {
  const headerObject = {
  };
  
  authHeaders[API_SERVER_VERSION].forEach((key) => {
    headerObject[key] = getCookie(key);
  });
    
  return headerObject;
};

export const createHeadersForUploadImage = () => {
  const headerObject = {
    'Content-Type': 'application/json',
    'X-Method': 'pass_verification',
    Accept: 'application/vnd.Expresso.v6',
    Locale: getLocaleCookie(),
  };

  authHeaders[API_SERVER_VERSION].forEach((key) => {
    headerObject[key] = getCookie(key);
  });

  return headerObject;
};

export const checkForAuthentication = () => {
  return authHeaders[API_SERVER_VERSION].every((key) => getCookie(key));
};

export const purgeUserCookie = () => {
  nonAuthHeaders.forEach((element) => {
    removeCookie(element);
  });
};

export const setUserCookie = ({
  id,
  email,
  first_name,
  last_name,
  profile_image,
  recent_user_privilege,
  locale,
}) => {
  purgeUserCookie();

  setCookie('user_id', id);
  setCookie('email', email);
  setCookie('first_name', first_name);
  setCookie('last_name', last_name);
  setCookie('profile_image', profile_image);
  setCookie('locale', locale);

  if (recent_user_privilege) {
    setCookie('phone_number', recent_user_privilege.phone_number);
    setCookie('time_zone', recent_user_privilege.time_zone);
    setCookie('location_id', recent_user_privilege.location_id);
    setCookie('is_admin', recent_user_privilege.is_admin);
    setCookie('position', recent_user_privilege.position);
  }
};
export const getUserId = () => {
  return getCookie('user_id');
};

export const getUserRole = () => {
  return getCookie('is_admin');
};

export const setLocationCookie = (location_code) => {
  removeCookie('location_id');
  setCookie('location_id', location_code);
};

export const setLocaleCookie = (locale) => {
  setCookie('locale', locale === 'undefined' ? getBrowserLanguage() : locale);
};

export const getLocationCode = () => getCookie('location_id');

export const getUidCookie = () => getCookie('user_id');

export const getSessionUukey = () => getCookie('session-uukey');
