import React from 'react';
import PropTypes from 'prop-types';

import { teamShapes, teamUtils, teamConstants } from '../../../duck';
import strings from '../strings';

import ActionBtn from '../../common/ActionButton';
import styles from '../styled';

const ActionButtons = ({
  actionHandlers,
  selectedAction,
  selectedUsers,
  locationId,
  receiveSelectedUsers,
}) => {
  const { bulkActionTypes } = teamConstants;
  const isSelectAction = selectedAction.indexOf('SELECT_') >= 0;
  /*
  const userIds = teamUtils.isBulk(selectedAction)
    ? selectedUsers.map((user) => user.id)
    : selectedUsers[0].id;
  */
  const userIds = selectedUsers.map((user) => user.id); //Fixing bug WS-1740
  const MAX_USERS = 5;
  receiveSelectedUsers(userIds, selectedAction, MAX_USERS);

  const determineHandler = () => {
    if (isSelectAction) {
      const nextAction = selectedAction.replace('SELECT_', '');
      /*
      return [
        actionHandlers.cancel,
        () => {
          actionHandlers[selectedAction](nextAction);
        },
      ];
      */
      return [
        actionHandlers.cancel,
        () => {
          actionHandlers[nextAction](userIds, locationId);
        },
      ];
    } else if (selectedAction === bulkActionTypes.PENDING_BULK) {
      let bulkMax = [];
      if(userIds.length > MAX_USERS) {
        bulkMax = userIds.slice(0, MAX_USERS);
      }else {
        bulkMax = userIds;
      }

      return [
        () => {
          //actionHandlers[selectedAction][0](userIds, locationId);
          actionHandlers[selectedAction][0](bulkMax, locationId);
        },
        () => {
          //actionHandlers[selectedAction][1](userIds, locationId);
          actionHandlers[selectedAction][1](bulkMax, locationId);
        },
      ];
    }

    return [
      actionHandlers.cancel,
      () => {
        actionHandlers[selectedAction](userIds, locationId);
      },
    ];
  };

  const clickhandlers = determineHandler();

  return (
    <styles.ActionWrap>
      <ActionBtn modifiers={['danger']} onClick={clickhandlers[0]}>
        {strings[selectedAction].actionStrings[0]}
      </ActionBtn>
      <ActionBtn modifiers={['success']} onClick={clickhandlers[1]}>
        {strings[selectedAction].actionStrings[1]}
      </ActionBtn>
    </styles.ActionWrap>
  );
};

ActionButtons.propTypes = {
  actionHandlers: teamShapes.actionHandlersShape,
  selectedAction: teamShapes.selectedActionTypes,
  selectedUsers: PropTypes.arrayOf(teamShapes.userShape),
  locationId: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
};

export default ActionButtons;
