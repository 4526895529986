import styled from 'styled-components/macro';

import TableComponent from '../TableComponent';

const Cell = styled(TableComponent.Cell)`
  justify-content: flex-end;
  flex: 6;
  min-width: 320px;

  @media (min-width: 440px) {
    min-width: 370px;
  }

  @media (max-width: 650px) {
    justify-content: start;
  }
`;

export default {
  Cell,
};
