import { connect } from 'react-redux';
import TeamProfileComponent from './TeamProfileComponent';
import { teamProfileOperations } from './duck';

const mapStateToProps = ({ currentUserReducer, locationReducer, appReducer, teamProfileReducer}) => {
  return {
    locationId: locationReducer.location_code,
    //user: currentUserReducer,
    user: teamProfileReducer,
    skills: appReducer.skills.data,
    locationSettings: appReducer.location.settings,
    locationDepartments: appReducer.departments.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  function getCurrentUser(locationId, userId) {
    // dispatch
    dispatch(teamProfileOperations.resetCurrentUser());
    dispatch(teamProfileOperations.getCurrentUser(locationId, userId));
  }

  return { getCurrentUser };
};

const TeamProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamProfileComponent);

export default TeamProfileContainer;
