import React from 'react';
import ReactDOM from 'react-dom';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ProfileThumb } from '@shyft/acorns';

import Icon from '@mdi/react';
import { mdiBullhorn } from '@mdi/js';

import devices from 'helpers/devices';

import { commentFormat } from 'helpers/date-time';
import { teamFeedShapes } from '../../duck';
import ActionButtons from '../ActionButtons';
import styled from './styled';
import blocksStyled from '../styled';

import ImageWin from './ImageWin';
import Modal from './Modal';
import PDFComponent from '../../components/Posts/PDFComponent';





const isAnnouncement = (post_type) => {
  return [1, 2, 3, 4].includes(post_type);
};


class Post extends React.PureComponent {
    constructor(props){
    super(props);
    this.state = {
      hovered: false,
      enlarged: false,
      showPictureView: false,
      selectedPicture: '',
      allPictures: [],
      modalWindow: null,
      show: false,
      currentIdx: -1,
      currentTarget: null,
    };
    this.messageWrapRef=React.createRef();
    this.attachedImageRef = React.createRef();
    this.imageContainerRef = React.createRef();
    this.nwRef = React.createRef();
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);

    this.selectedUrl = '';
    this.metaUrl = '';

  }

  componentDidMount() {
    const {data} = this.props;
    const {attachment} = data;
    if(attachment){
      this.setState({allPictures: attachment.object_instances});
    }
    
  }

  componentDidUpdate() {
    
  }

  componentWillUnmount(){
    if(this.state.modalWindow && !this.state.modalWindow.closed){
      this.state.modalWindow.close();
    }
 }

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleImageClick = (event) => {
    //this.setState({enlarged: !this.state.enlarged});
    this.setState({showPictureView: !this.state.showPictureView});
    this.setState({selectedPicture: event.currentTarget.currentSrc});
    this.selectedUrl = event.currentTarget.currentSrc;
    this.setState({currentTarget: event.currentTarget});
    this.setState({show: true});
    
    /*
    const url = "about:blank";
    const windowName = "Picture View";
    const windowFeatures = "left=100, top=100,width=1200,height=675,menubar=no,location=yes,resizable=no,scrollbars=yes,status=no";
    const windowFeatures1 = "left=100, top=100,width=600,height=450";
    this.state.modalWindow = window.open(url, windowName,windowFeatures);
    this.renderPopOut();
    */

    let currentUrl=event.currentTarget.currentSrc;
    let idx = this.findSelected(currentUrl);
    this.setState({currentIdx: idx});
    
    event.preventDefault();

  };

  handleTitleClick = (event) => {
    window.open(this.metaUrl);
    event.preventDefault();
  };

  handleClickLink = (event) => {
    window.open(this.metaUrl);
    event.preventDefault();
  };

  handleUrlClick = (event) => {
    window.open(event.target);
    event.preventDefault();
  }

  containUrl(text){
    var s;
    var index1, index2, index3, index4;
    
    if(!text){
      return false;
    }
    s = text.toLowerCase();
    if(s.length < 5){
      return false;
    }
    index1 = s.indexOf("http");
    if(index1 < 0){
      return false;
    }
    index2 = s.indexOf(":");
    if(index2 <= 0){
      return false;
    }else{
      if(index2 < index1){
        let charToCheck; // "Please visit : https://www......,looking for ":" after "http"
        charToCheck = s.charAt(index1 + 3 + 1); // check char after 'http'
        if(charToCheck === 's'){
          charToCheck = s.charAt(index1+ 3 + 2) // check char after 'https'
          if(charToCheck === ':'){
            index2 = index1 + 3 + 2;
          }
        }else if(charToCheck === ':'){
          index2 = index1 + 3 + 1;
        }else{
          return false;
        }
      }
    }
    index3 = s.indexOf("//");
    if(index3 <= 0){
      return false;
    }
    index4 = s.indexOf(".");
    if(index4 <= 0){
      return false;
    }
    if(index4 <= index3 || index3 <= index2 || index2 <= index1){
      return false;
    }

    return true;
  }

  extractUrl(text) {
    const urlRegex = /(https?:\/\/[^ ]*)/;
    let start, end;
    let s;

    if(!text){
      return null;
    }
    s = text.toLowerCase();
    if(!this.containUrl(s)){
      return null;
    }
    var url = s.match(urlRegex)[1];
    if(url){
      let beforeUrl, afterUrl;
      const lastChar = url.charAt(url.length-1);
      if(lastChar === ',' || lastChar === '.'){
        url = url.slice(0, -1); // remove last char
      }
      start = s.indexOf(url);
      if(start > 0){
        beforeUrl = text.substring(0, start);
        afterUrl = text.substring(start+url.length, text.length)
        return <a>{beforeUrl} <a href={url} onClick={this.handleUrlClick}>{url}</a>{afterUrl}</a>;
      }else{
        if(s.length > url.length){
          afterUrl = text.substring(url.length, text.length)
          return <a><a href={url} onClick={this.handleUrlClick}>{url}</a>{afterUrl}</a>;
        }
        else{
          return <a href={url} onClick={this.handleUrlClick}>{url}</a>;
        }
      }
    }else{
      return null;
    }
  }

  renderPopOut() {
    if (this.state.modalWindow) {
      let root = this.state.modalWindow.document.body;
      let idx = this.findSelected(this.selectedUrl);
      
      ReactDOM.render(
          
          <ImageWin  selected={event.currentTarget.currentSrc}
                                allPictures={this.state.allPictures}
                                replacedElement={event.currentTarget}
                                enlarged={this.state.enlarged}
                                currentIdx={idx}
                                closeWin={this.closeModalWindow}
                                /> 
           
                                ,root);
    }
  }

  handleSubmit = (event) =>{

  };

  findSelected = (picTofind) =>{
    const {allPictures} = this.state;

    for(var i=0; i<allPictures.length; i++){
        if(allPictures[i].thumb_url===picTofind || 
          allPictures[i].gallery_url===picTofind ||
          allPictures[i].original_url===picTofind ||
          allPictures[i].full_url===picTofind){
          return i;
        }
    }
    return -1
  }

  closeModalWindow = () =>{
    if(this.state.modalWindow){
      this.state.modalWindow.close();
    }

    this.setState({show: false});
  }

  renderPDF = (pdfObjects) => {

    return pdfObjects.map((pdf, index) => {
      const sizeText = `${Math.round(pdf.byte_size/1000)} KB`;
      return (
        <styled.PDFWrap
          key={pdf.id}
          onClick={() => {
            console.log(pdf.blob_url);
            window.open(pdf.service_url);
          }}>
          <styled.PDFIcon 
            src='/icons/pdf-icon.svg'>
          </styled.PDFIcon>
          <styled.PDFProps>
          <styled.PDFName>{pdf.filename}</styled.PDFName>
          <styled.PDFSizeWrap>
            <styled.PDFSize>PDF</styled.PDFSize>
            <styled.Circle/>
            <styled.PDFSize>{sizeText}</styled.PDFSize>
          </styled.PDFSizeWrap>
        </styled.PDFProps>
        </styled.PDFWrap>
      );
    });
  };

  render() {
    const {
      active,
      data,
      clickComment,
      clickDelete,
      clickLike,
      clickPin,
      className,
      clickReadReceipts,
    } = this.props;
    const { hovered, enlarged } = this.state;
    const {
      attachment,
      comments_count,
      content,
      created_at,
      final_allow_comment,
      final_allow_delete,
      final_allow_like,
      post_type,
      pinned,
      liked,
      owner,
      url_meta,
      attached_files_blobs,
    } = data;

    if(url_meta && url_meta.url){
      this.metaUrl = url_meta.url;
    }

    let urlInContent = this.extractUrl(content);
    if(!urlInContent){
      urlInContent = content;
    }

    let sizeText = '';
    if(attached_files_blobs && attached_files_blobs.length > 0) {
      sizeText = `${Math.round(attached_files_blobs[0].byte_size/1000)} KB`;
    }

    return (
      <blocksStyled.MessageWrap
        className={className}
        modifiers={active ? 'active' : null}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => {
          void 0;
        }}
        ref = {this.messageWrapRef}
      >
        <styled.AvatarWrap>
          <ProfileThumb thumbUrl={owner !== undefined ? owner.thumb_url : null} width="44px" /> 
        </styled.AvatarWrap>
        <blocksStyled.Info>
          <styled.Name>
            {owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}
            {isAnnouncement(post_type) ? (
              <Icon path={mdiBullhorn} size={1} color="#acacac" />
            ) : (
              ''
            )}
          </styled.Name>
          <styled.Timestamp>{commentFormat(created_at)}</styled.Timestamp>
          {!url_meta ? <styled.Content>{urlInContent}</styled.Content> :
            <div>
            <styled.Content>{urlInContent}</styled.Content>
            <styled.UrlMeta>
              {url_meta.logo && <styled.MetaLogo src={url_meta.logo} />}
              {url_meta.publisher && <styled.Publisher>{url_meta.publisher}</styled.Publisher>}
              {url_meta.title && <styled.Title onClick={this.handleTitleClick}>{url_meta.title}</styled.Title>}
              <styled.Content>{url_meta.description}</styled.Content>
              {url_meta.image && <styled.MetaImage src={url_meta.image} />}
              {url_meta.author && <styled.Content>{url_meta.author}</styled.Content>}
              {url_meta.date && <styled.Content>{url_meta.date}</styled.Content>}
              <styled.Content>
                <a href={url_meta.url} onClick={this.handleClickLink}>{url_meta.url}</a>
              </styled.Content>
            </styled.UrlMeta>
            </div>}
            <styled.ImageGroupWrap>
              {!attachment || (
                attachment.object_instances.map((instance, index) =>(
                 <div ref={this.imageContainerRef}>
                 <styled.Image 
                                  key={index} 
                                  src={instance.thumb_url} 
                                  onClick={this.handleImageClick}
                                  enlarged={enlarged}
                                  ref={this.attachedImageRef}
                                  />
                  </div>
                ))
              )}
            </styled.ImageGroupWrap>
            <styled.PDFGroupWrap>
              {attached_files_blobs && this.renderPDF(attached_files_blobs)}
            </styled.PDFGroupWrap>
          {comments_count && final_allow_comment ? (
            <styled.CommentCount onClick={clickComment}>
              <FormattedMessage
                id="Post.commentCount"
                defaultMessage="{count, plural, one {# comment} other {# comments}}"
                values={{ count: comments_count }}
              />
            </styled.CommentCount>
          ) : null}
          {hovered &&
          (final_allow_like || isAnnouncement(post_type) || final_allow_delete || final_allow_comment) ? (
            <ActionButtons>
              {final_allow_like && !isAnnouncement(post_type) ? (
                <ActionButtons.Button
                  onClick={clickLike}
                  modifiers={liked ? 'liked' : 'like'}
                />
              ) : null}
              <ActionButtons.Button
                onClick={clickPin}
                modifiers={pinned ? 'pinned' : 'pin'}
              />
              {isAnnouncement(post_type) ? (
                <ActionButtons.Button
                  onClick={clickReadReceipts}
                  modifiers="readReceipts"
                />
              ) : null}
              {final_allow_comment ? (
                <ActionButtons.Button
                  onClick={clickComment}
                  modifiers="comment"
                />
              ) : null}
              {!final_allow_delete || (
                <ActionButtons.Button
                  onClick={clickDelete}
                  modifiers="delete"
                />
              )}
            </ActionButtons>
          ) : null}
        </blocksStyled.Info>
        {this.state.show && <Modal>
           <ImageWin  selected={this.state.selectedPicture}
                                allPictures={this.state.allPictures}
                                replacedElement={this.state.currentTarget}
                                enlarged={this.state.enlarged}
                                currentIdx={this.state.currentIdx}
                                closeWin={this.closeModalWindow}
                                /> 
        </Modal>}
      </blocksStyled.MessageWrap>
    );
  }
}

Post.propTypes = {
  clickComment: PropTypes.func.isRequired,
  clickDelete: PropTypes.func.isRequired,
  clickLike: PropTypes.func.isRequired,
  clickReadReceipts: PropTypes.func.isRequired,
  clickPin: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  data: teamFeedShapes.postsDataShape.isRequired,
  className: PropTypes.string,
};

Post.defaultProps = {
  className: '',
};

export default Post;
