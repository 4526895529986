import actions from './actions';
import constants from './constants';
import operations from './operations';

const recieveMembersCb = (response, dispatch) => {
  const {
    data: {
      user_privileges,
      meta: { pagination },
    },
  } = response;
  const payload = {
    users: user_privileges,
    page: pagination.current_page,
    totalPages: pagination.total_pages,
  };

  dispatch(actions.recieveMembers(payload));
};

const appendMembersCb = (response, dispatch) => {
  const {
    data: {
      user_privileges,
      meta: { pagination },
    },
  } = response;
  const payload = {
    users: user_privileges,
    page: pagination.current_page,
  };

  dispatch(actions.appendMembers(payload));
};

/**
 * Determines fetch call to use based on active filter
 *
 * @param  {string} activeFilter
 * @param  {Object} params
 * @param  {number} params.location_id
 * @param  {number} params.page
 * @param  {string} [params.search_term]
 * @param {boolean} [params.selectedAll]
 */
const determineFetch = (activeFilter, params) => (dispatch) => {
  const { statusTypes } = constants;

  switch (activeFilter) {
    case statusTypes.ACTIVE:
      dispatch(operations.fetchActive(params));
      break;
    case statusTypes.PENDING:
      dispatch(operations.fetchPending(params));
      break;
    case statusTypes.REMOVED:
      dispatch(operations.fetchRemoved(params));
      break;
    default:
      break;
  }

  if (!params.selectedAll) {
    dispatch(actions.updateVisibility(false));
    dispatch(actions.deselectAllMembers());
  }
  dispatch(actions.updateSearching(false));
};

const isBulk = (selectedAction) => selectedAction.includes('_BULK');

export default {
  recieveMembersCb,
  appendMembersCb,
  determineFetch,
  isBulk,
};
