import { connect } from 'react-redux';

import { teamFeedActions, teamFeedSelectors } from '../../duck';

import ChannelsComponent from './ChannelsComponent';

const mapStateToProps = ({ appReducer, teamFeedReducer: { active } }) => ({
  channels: teamFeedSelectors.getDateOrderedChannels(appReducer),
  active: active.channel,
});

const mapDispatchToProps = (dispatch) => {
  const setActiveChannel = (id) => {
    dispatch(teamFeedActions.updateActiveChannel(id));
    dispatch(teamFeedActions.updateActivePost({id: 0, channel_name: ''}));
    dispatch(teamFeedActions.updateActiveComment(0));
    dispatch(teamFeedActions.updateActiveDelete({ type: '', id: 0 }));
    dispatch(teamFeedActions.updateActiveSidePane(''));
    dispatch(teamFeedActions.clearReadReceipts());
  };

  return {
    setActiveChannel,
  };
};

const ChannelsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsComponent);

export default ChannelsContainer;
