import { protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';

/**
 * Set basic user info
 * @param  {String} {user_id
 * @param  {String} email
 * @param  {String} password}
 */
export const updateUserAttributes = ({ user_id, email, password, locale }) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/users/${user_id}`,
    method: 'PUT',
    data: {
      user: {
        email,
        password,
        locale,
      },
    },
  });

/**
 * Get channels
 * @param  {Object} params - Object of query string params
 */
export const getChannels = (params) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params,
  });

export const getUserInfo = (user_id) =>
  protectedAxiosInstance.request({
    url: `/api/organization/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
    },
  });

export const getUserPrivilege = (user_privilege_id) =>
  protectedAxiosInstance.request({
    url: `api/customer/user_role/user_privileges/${user_privilege_id}`,
    method: 'GET',
    params: {
      user_privilege_id,
    },
  });

export const getUserInfoNonAdmin = (user_id, location_id) =>
  protectedAxiosInstance.request({
    url: `api/customer/user_role/users/${user_id}`,
    method: 'GET',
    params: {
      user_id,
      location_id,
    },
  });

export const getChannelMembers = (channelId) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels/${channelId}/member_users`,
    method: 'GET',
    params: {
      per_page: 10000,
    },
  });

export const GetLocationChannels = (location_id) =>
  protectedAxiosInstance.request({
    url: '/api/customer/user_role/channels',
    method: 'GET',
    params: {
      location_id,
      per_page: 100,
      type_is: ['custom_feed', 'location_feed', 'region_feed'],
      'response[only]': formatOnlyProps(`
        id,
        channel_name,
        updated_at,
        channel_type,
        channel_latest_content,
        member_count,
      `),
      'response[nested]': 1,
    }
  });

/**
 * creata a channel
 * @param  {String} {channel_name
 * @param  {String} channel_frequency
 * @param  {String} description}
 */
export const createChannel = ({channel_name, channel_frequency, description }) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/channels`,
    method: 'POST',
    data: {
      channel: {
        channel_name,
        channel_frequency,
        description,
      },
    },
  });
