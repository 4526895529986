import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import TeamProfileHeaderContainer from './components/TeamProfileHeader/TeamProfileHeaderContainer';
import TeamProfileDetailsContainer from './components/TeamProfileDetails/TeamProfileDetailsContainer';
import { getUserInfoNonAdmin, getUserPrivilege, } from 'helpers/api-calls/user-calls';

const PageWrap = styled.div`
  width: 100%;
  background-color: white;
  max-width: 1462px;
  margin-top: 50px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
`;

const PageSectionDivider = styled.div`
  display: block;
  margin: auto;
  width: 95%;
  background-color: #eaecee73;
  height: 2px;
  border-radius: 3px;
`;

class TeamProfileComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      userSkills: [],
      //userDepartmentIds: [],
      userDepartments: [],
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { id } = this.props.match.params; //this is user privilege id
    const { getCurrentUser, locationId, user, skills, locationSettings, locationDepartments, getLocationEmployees, } = this.props;
    this._isMounted = true;
    getCurrentUser(locationId, id);
    /*
    if(id && locationId) {
      getUserPrivilege(id).then((response) => {
        const userId = response.data.user_privilege.owner_id;
        const departmentIds = response.data.user_privilege.department_ids;
        //this.setState({userDepartmentIds: userDepartmentIds});
        const depts = this.createDepartmentInfo(departmentIds);
        this.setState({userDepartments: depts});
        getUserInfoNonAdmin(userId, locationId).then((data) =>{
          const skill_ids = data.data.user.skill_ids;
          const output = this.getUserSkills(skill_ids);
          if(this._isMounted) {
            this.setState({userSkills: output});
          }
        }).catch((error1) =>{
          const msg = `TeamProfileComponent.componentDidMount: call getUserInfoNonAdmin failed. error: ${error1}`;
          console.log(msg);
        });
      }).catch((error) =>{
         const msg = `TeamProfileComponent.componentDidMount: call getUserPrivilege failed. error: ${error}`;
         console.log(msg);
      });

    }
    */
  }

  componentDidUpdate (prevProps, prevState) {
    const { user, skills, locationId, } = this.props;

    if(prevProps.user !== user) {
      if(user && user.attributes.department_ids) {
        const depts = this.createDepartmentInfo(user.attributes.department_ids);
        this.setState({userDepartments: depts});
      }
      if(user && user.attributes.skill_ids) {
        const output = this.getUserSkills(user.attributes.skill_ids);
        this.setState({userSkills: output});
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  getUserSkills = (skill_ids) => {
    const { skills, } = this.props;

    let userSkills = [];
    if(skill_ids && skill_ids.length > 0 && skills && skills.length > 0) {
      for(let i=0; i<skill_ids.length; i++) {
        for(let j=0; j<skills.length; j++) {
          if(skill_ids[i] === skills[j].id) {
            userSkills.push(skills[j]);
          }
        }
      }
    }
    return userSkills;
  };

  createDepartmentInfo = (departmentIds) => {
    const { locationDepartments, } = this.props;
    let userDepartments = [];

    if(departmentIds && departmentIds.length > 0) {
      if(locationDepartments && locationDepartments.length > 0) {
        for(let i=0; i<departmentIds.length; i++) {
          for(let j=0; j<locationDepartments.length; j++) {
            if(departmentIds[i] === locationDepartments[j].id) {
              userDepartments.push(locationDepartments[j]);
            }
          }
        }
      }
    }
    return userDepartments;
  };

  render() {
    const { skillInfo, userSkills, userDepartments, } = this.state;
    const { locationSettings, user, } = this.props;
    return (
      <PageWrap>
        <TeamProfileHeaderContainer  
          userSkills={userSkills} 
          userDepartments={userDepartments} 
          locationSettings={locationSettings}/>
        <PageSectionDivider />
        <TeamProfileDetailsContainer />
      </PageWrap>
    );
  }
}

export default TeamProfileComponent;

TeamProfileComponent.propTypes = {
  locationId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    position: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  getCurrentUser: PropTypes.func.isRequired,
};
