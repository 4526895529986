/* global swiftCode:true */
/* eslint no-undef: "error" */

import axios from 'axios';
import { createHeadersForApiCall, purgeAuthenticationCookie } from './cookies';
import { BASE_URL } from '../constants';
import 'whatwg-fetch';

export * from './api-calls/authentication-calls.js';
export * from './api-calls/user-calls.js';
export * from './api-calls/employee-interaction-calls.js';
export * from './api-calls/shift-calls.js';
export * from './api-calls/location-calls.js';
export * from './api-calls/calendar-shift-calls';
export * from './api-calls/comments.js';

export const publicAxiosInstance = axios.create({
  baseURL: `https://${BASE_URL}`,
  params: {
    'response[put_return_resource]': 'true',
    'response[nested]': 2,
  },
});

export const protectedAxiosInstance = axios.create({
  baseURL: `https://${BASE_URL}`,
  headers: {
    common: createHeadersForApiCall(),
  },
  params: {
    'response[put_return_resource]': 'true',
    'response[nested]': 2,
  },
});

export const axiosInstance = axios.create({
  baseURL: `https://${BASE_URL}`,
  params: {
    'response[put_return_resource]': 'true',
    'response[nested]': 2,
  },
});

// unauthenticated handling
protectedAxiosInstance.interceptors.response.use(
  (response) => {
    // great!
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      purgeAuthenticationCookie();
      window.location.pathname = '/login';
    }

    return Promise.reject(error);
  }
);

export const AddAuthHeaders = () => {
  const headers = createHeadersForApiCall();

  Object.keys(headers).map((key) => {
    protectedAxiosInstance.defaults.headers.common[key] = headers[key];
  });
};

export const GetLocation = (swiftCode) => {
  return fetch(`https://${BASE_URL}/api/organization/locations/${swiftCode}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());
};
