import { createSlice } from '@reduxjs/toolkit';

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    data: [],
    loading: true,
    page: 1,
    totalPages: 1,
    addedComments: [],
    deletedComments: [],
  },
  reducers: {
    hydratePosts: (state, action) => {
      state.data = action.payload.data;
      state.totalPages = action.payload.total_pages;
      state.loading = false;
    },
    updatePosts: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
    },
    updatePost: (state, action) => {
      const postIndex = state.data.findIndex(
        (post) => post.id === action.payload.id
      );
      if(postIndex >= 0) {
        state.data[postIndex] = {
          ...state.data[postIndex],
          ...action.payload,
        };
      }
    },
    incrementLikeCount: (state, action) => {
      const postIndex = state.data.findIndex(
        (post) => post.id === action.payload
      );
      if(postIndex >= 0) {
        const newData = [...state.data];
        newData[postIndex].likes_count += 1;
        newData[postIndex].liked = true;
        state.data = newData;
      }
    },
    decrementLikeCount: (state, action) => {
      const postIndex = state.data.findIndex(
        (post) => post.id === action.payload
      );
      if(postIndex >= 0) {
        const newData = [...state.data];
        if (newData[postIndex].likes_count > 0) {
          newData[postIndex].likes_count -= 1;
        }
        newData[postIndex].liked = false;
        state.data = newData;
      }
    },
    decrementPostCommentCount: (state, action) => {
      const postIndex = state.data.findIndex(
        (post) => post.id === action.payload.postId
      );
      const commentIndex = state.deletedComments.findIndex(
        (comment) => comment === action.payload.commentId
      );
      if(commentIndex < 0){
        const newData = [...state.data];
        if(newData[postIndex] && newData[postIndex].comments_count > 0){
          newData[postIndex].comments_count -= 1;
        }
        state.data = newData;
      }else{
        //console.log('- duplicate comment id = ' + action.payload.commentId);
      }
    },
    incrementPostCommentCount: (state, action) => {
      const postIndex = state.data.findIndex(
        (post) => post.id === action.payload.postId
      );
      if(postIndex < 0) {
        console.log('incrementPostCommentCount: post not found.');
      }else {
        const commentIndex = state.addedComments.findIndex(
          (comment) => comment === action.payload.commentId
        );
        if(commentIndex < 0){
          const newData = [...state.data];
          if(newData[postIndex] && newData[postIndex].comments_count !== null) {
            newData[postIndex].comments_count += 1;
            state.data = newData;
          }else {
            const msg = `post index: ${postIndex}, newData[${postIndex}], its comments_count is null.`;
            console.log(msg);
          }
        }
      }
    },
    createPost: (state, action) => {
      const newData = [...state.data, action.payload];
      state.data = newData;
    },
    deletePost: (state, action) => {
      state.data = state.data.filter((post) => post.id !== action.payload);
    },
    requestPosts: (state, action) => {
      state.loading = true;
      state.page = action.payload;

      if (action.payload === 1) {
        state.data = [];
      }
    },
    updateAddedComments: (state, action) => {
      const commentIndex = state.addedComments.findIndex(
        (comment) => comment === action.payload
      );
      if(commentIndex < 0){
        state.addedComments = state.addedComments.concat(action.payload);
      }
    },
    updateDeletedComments: (state, action) => {
      const commentIndex = state.deletedComments.findIndex(
        (comment) => comment === action.payload
      );
      if(commentIndex < 0){
        state.deletedComments = state.deletedComments.concat(action.payload);
      }
    },
  },
});

export default postsSlice;
