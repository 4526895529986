import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@shyft/acorns'; //This is the root cause of bug WS-1296

import { teamShapes, teamConstants } from '../../duck';
import styled from './styled';
import checkbox from './checkbox.scss';

const SelectCellComponent = ({
  selected,
  handleSelect,
  userPrivilegeId,
  activeFilter,
  selectedMembersCount,
  cancelBulk,
}) => {
  const { bulkActionTypes, statusTypes } = teamConstants;
  const handleOnChange = () => {
    if (selectedMembersCount === 1 && selected) {
      cancelBulk();
    } else {
      switch (activeFilter) {
        case statusTypes.ACTIVE:
          handleSelect(
            userPrivilegeId,
            selected,
            bulkActionTypes.SELECT_ACTIVE_BULK
          );
          break;
        case statusTypes.PENDING:
          handleSelect(userPrivilegeId, selected, bulkActionTypes.PENDING_BULK);
          break;
        default:
          break;
      }
    }
  };

  if (activeFilter === statusTypes.REMOVED) {
    return null;
  }

  return (
    <styled.CellWrap>
      {/*<Checkbox checked={selected} onChange={handleOnChange} />*/}
      {/*<styled.CheckBox checked={selected} onChange={handleOnChange} />*/}
      <label class="checkbox" >
        <input type="checkbox" checked={selected} onChange={handleOnChange}/>
        <span></span>
      </label>
    </styled.CellWrap>
  );
};

SelectCellComponent.propTypes = {
  selected: PropTypes.bool,
  activeFilter: teamShapes.filterTypes,
  userPrivilegeId: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  cancelBulk: PropTypes.func.isRequired,
  selectedMembersCount: PropTypes.number.isRequired,
};

SelectCellComponent.defaultProps = {
  selected: false,
};

export default SelectCellComponent;
