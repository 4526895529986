import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-width: ${(props) => props.maxWidth || null};
  background-color: white;
  border-right: ${(props) => props.theme.palette.border} solid 1px;

  &:last-child {
    border: none;
  }
`;

ColumnWrap.propTypes = {
  maxWidth: PropTypes.string,
};

export default ColumnWrap;
