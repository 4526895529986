import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
`;

class ScrollWrap extends React.PureComponent {
  scrollRef = React.createRef();

  state = {
    scrolled: false,
  };

   getSnapshotBeforeUpdate(prevProps, prevState) {
    // Capture the scroll position so we can adjust scroll later.
     const { current } = this.scrollRef;
     return current.scrollHeight - current.scrollTop;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reverse, children, isLoading } = this.props;
    const { children: prevChildren } = prevProps;
    const { current } = this.scrollRef;
    const { scrolled } = this.state;

    if (
      reverse &&
      prevChildren !== children &&
      scrolled === false &&
      isLoading === false
    ) {
      // Initial scroll to bottom when container is populated
      setTimeout(() => {
        current.scrollTop = current.scrollHeight;
      }, 300);
      this.setState({ scrolled: true });
    } else if (reverse && scrolled && isLoading === false) {
      // Scroll to bottom of new loaded content
      if (prevChildren[2].length < children[2].length){
        const bookmarkPosition = current.querySelector('#scroll__bookmark')
        .offsetTop;
        current.scrollTop = bookmarkPosition - 50;
      }
      // Keep scroll position when like/unlike and pin/unpin
      else if(prevChildren[2].length === children[2].length){
        if (snapshot !== null) {
          current.scrollTop = current.scrollHeight - snapshot;
        }
      }
    }
  }

  render() {
    const { children } = this.props;
    return <Wrap ref={this.scrollRef}>{children}</Wrap>;
  }
}

ScrollWrap.propTypes = {
  reverse: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ScrollWrap.defaultProps = {
  reverse: false,
  isLoading: false,
};

export default ScrollWrap;
