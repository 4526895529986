import { createSlice } from '@reduxjs/toolkit';

const requestTimeOffs = createSlice({
  name: 'requestTimeOffs',
  initialState: {
    data: [],
    loading: true,
    total: [],
  },
  reducers: {
    hydrateRequestTimeOffs: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      //console.log(`hydrateRequestTimeOffs: ${action.payload.length}`);
    },
    hydrateAllRequestTimeOffs: (state, action) => {
      state.total = action.payload;
      state.loading = false;
      //console.log(`hydrateAllRequestTimeOffs: ${action.payload.length}`);
    },
    appendAllTimeOffs: (state, action) => {
      state.total= state.total.concat(action.payload);
      state.loading = false;
      //console.log(`appendAllTimeOffs: ${action.payload.length}`);
    },
    appendRequestTimeOffs: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
      //console.log(`appendRequestTimeOffs: ${action.payload.length}`);
    },
    deletePendingRTO: (state, action) => {
      state.data = state.data.filter((rto) => rto.id !== action.payload);
    },
    resetTotalRTOs: (state, action) => {
      state.total = [];
    },
  },
});

export default requestTimeOffs;