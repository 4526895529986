import { createSlice } from '@reduxjs/toolkit';

import teamConstants from '../../constants';

const { statusTypes } = teamConstants;

const pendingUsersSlice = createSlice({
  name: 'pendingUsers',
  initialState: {
    users: [],
    page: 0,
    totalPages: 0,
    loading: false,
  },
  reducers: {
    requestPendingUsers: (state, action) => {
      state.loading = true;
    },
    receivePendingUsers: (state, action) => {
      state.users = action.payload.users;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendPendingUsers: (state, action) => {
      state.users = state.users.concat(action.payload.users);
      state.loading = false;
    },
    removePendingUser: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload.id
      );
      state.users.splice(memberIndex, 1);
    },
    removePendingUserWithId: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload
      );
      state.users.splice(memberIndex, 1);
    },
    resetPendingUsers: (state, action) => {
      state.users = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false;
    },

  },
});

export default pendingUsersSlice;
