import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { teamShapes } from '../../duck';
import TableComponent from '../TableComponent';
import UserCell from '../UserCell';
import StatusCell from '../StatusCell';
import ActionCell from '../ActionCell';
import SelectCell from '../SelectCell';
import SkillCell from '../SkillCell';

import { getUserInfoNonAdmin, } from 'helpers/api-calls/user-calls';

import { userStatusMessages } from './strings';

import styled from '../styled';

class UserRowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      skillInfo: '',
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { user, intl, locationId, skills, locationSettings, locationDepartments, singleTable, } = this.props;

    this._isMounted = true;
    
    if(user && user.owner_id && locationId) {
      getUserInfoNonAdmin(user.owner_id, locationId).then((response) => {
        const skill_ids = response.data.user.skill_ids;
        //const msg = `${user.first_name} ${user.last_name} has ${response.data.user.skill_ids.length} skills.`;
        //console.log(msg);
        const output = this.createSkillInfo(skill_ids);
        if(this._isMounted) {
          this.setState({skillInfo: output});
        }
      }).catch((error) =>{
         const msg = `UserRowComponent.componentDidMount: call getUserInfo failed. error: ${error}`;
         console.log(msg);
      });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { skills, locationDepartments, } = this.props;

    if(prevProps.skills !== skills) {

    }

  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  createSkillInfo = (skill_ids) => {
    const { skills, } = this.props;

    let skillInfo = '';
    let skillNames = '';
    let userSkills = [];
    if(skill_ids && skill_ids.length > 0 && skills && skills.data.length > 0) {
      for(let i=0; i<skill_ids.length; i++) {
        for(let j=0; j<skills.data.length; j++) {
          if(skill_ids[i] === skills.data[j].id) {
            userSkills.push(skills.data[j]);
          }
        }
      }
    }
    this.sortSkillContentWithNullValues(userSkills, true);
    for(let k=0; k<userSkills.length; k++) {
      skillNames += `${userSkills[k].skill_content}, `
    }

    if(skillNames.length > 2) {
      skillNames = skillNames.substring(0, skillNames.length -2); //remove last ','
    }
    //User skill ids may not match location skills, in this case userSkills.length = 0
    if(skill_ids && skill_ids.length > 0 && userSkills.length > 0) {
        skillInfo = (<FormattedMessage
            id="UserRowComponent.skills1"
            defaultMessage="{skills}"
            values={{ skills: skillNames }}
          />);
      }else {
        /*
        skillInfo = (<FormattedMessage
            id="UserRowComponent.noSkills1"
            defaultMessage="None Added"
          />);
        */
        skillInfo = '--';
      }
    
    return skillInfo;
  }

  sortDepartmentNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortSkillContentWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  createDepartmentInfo = () => {
    const { user, locationDepartments, } = this.props;
    const placeholders = '--';
    let userDepartments = [];
    let deptInfo = '';

    if(user && user.department_ids && user.department_ids.length > 0) {
      if(locationDepartments && locationDepartments.length > 0) {
        for(let i=0; i<user.department_ids.length; i++) {
          for(let j=0; j<locationDepartments.length; j++) {
            if(user.department_ids[i] === locationDepartments[j].id) {
              userDepartments.push(locationDepartments[j]);
            }
          }
        }
        this.sortDepartmentNameWithNullValues(userDepartments, true);
        for(let k=0; k<userDepartments.length; k++) {
          deptInfo += `${userDepartments[k].name}, `;
        }
        if(deptInfo.length > 2) {
          deptInfo = deptInfo.substring(0, deptInfo.length -2); //remove last ','
        }
        return deptInfo;
      }
    }

    return placeholders;
  };

  render () {
    const { user, intl, locationSettings, singleTable, } = this.props;
    const { skillInfo, } = this.state;
    const empty = '';

    if(singleTable) {
      return (
        <styled.TableRow>
          <styled.SelectCell>
            <SelectCell userPrivilegeId={user.id} />
          </styled.SelectCell>
          <styled.UserCell>
            <UserCell user={user} />
          </styled.UserCell>
          <styled.DeptCell>
            {user && user.status ? intl.formatMessage(userStatusMessages[user.status]): ''}
            {/*this.createDepartmentInfo()*/}
          </styled.DeptCell>
          <styled.SkillCell>{skillInfo}</styled.SkillCell>
          <td>
            <ActionCell
            userStatus={user.status}
            isAdmin={user.is_admin}
            userPrivilegeId={user.id}
            />
          </td>
        </styled.TableRow>
      );
    }

    return (
      <styled.TableRow>
        <styled.SelectCell>
          <SelectCell userPrivilegeId={user.id} />
        </styled.SelectCell>
        <styled.UserCell>
          <UserCell user={user} />
        </styled.UserCell>
        {locationSettings.cfg__departments === 1 ? <styled.DeptCell>
          {this.createDepartmentInfo()}
        </styled.DeptCell> : <styled.DeptCell>{empty}</styled.DeptCell>}
        {locationSettings.cfg__skill === 1 ? <styled.SkillCell>{skillInfo}</styled.SkillCell> : 
        <styled.SkillCell>{empty}</styled.SkillCell>}
        <td>
          <ActionCell
          userStatus={user.status}
          isAdmin={user.is_admin}
          userPrivilegeId={user.id}
          />
        </td>
      </styled.TableRow>
    );
  }

}

UserRowComponent.propTypes = {
  user: teamShapes.userShape.isRequired,
};

export default injectIntl(UserRowComponent);
