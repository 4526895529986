import currentUser from './slices';

const {
  requestUser,
  receiveUser,
  requestUserUpdate,
  updateUser,
  updateFirstName,
  updateLastName,
  updatePosition,
} = currentUser.actions;

export default {
  requestUser,
  receiveUser,
  requestUserUpdate,
  updateUser,
  updateFirstName,
  updateLastName,
  updatePosition,
};
