import styled from 'styled-components/macro';

import Badge from 'components/GeneralComponents/Badge';

const FilterWrap = styled.div`
  position: relative;
  display: inline-block;
`;

const FilterBadge = styled(Badge)`
  position: absolute;
  top: -6px;
  right: -1rem;
`;

const FilterLabel = styled.label`
  font-size: 0.88rem;
  color: ${({ theme }) => theme.palette.text};
  margin-left: 1.25rem;
  margin-bottom: 0;
`;

const FilterRadioBtn = styled.input.attrs(({ value, checked }) => ({
  type: 'radio',
  name: 'team-filter',
  value,
  checked: checked || false,
}))`
  margin-right: 5px;
`;

export default {
  FilterRadioBtn,
  FilterLabel,
  FilterWrap,
  FilterBadge,
};
