import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const ProfileThumbSection = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 515px;
  padding: 0 15px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const ProfileThumb = styled.span.attrs((props) => ({
  src: props.src,
}))`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
`;

const PendingUsersLink = styled(NavLink).attrs((props) => ({
  ...props,
}))`
  color: ${({ theme }) => theme.palette.primary[0]};
  font-size: 12px;
  margin-left: 0.2em;
`;

const PendingUsersDescription = styled.p`
  font-size: 12px;
  padding: 0 1.5em;
`;

const LoadingLayer = styled.div`
  display: flex;
  height: 150px;
  width: 100%;
  top: 45%;
  margin: auto;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
`;

export {
  LoadingLayer,
  ProfileThumb,
  ProfileThumbSection,
  PendingUsersLink,
  PendingUsersDescription,
};
