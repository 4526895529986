import { shape, oneOf, string, number, func, bool, arrayOf } from 'prop-types';

import teamConstants from './constants';

const { statusTypes, userActionTypes, bulkActionTypes } = teamConstants;

const userStatuses = oneOf([
  statusTypes.PENDING,
  statusTypes.ACTIVE,
  statusTypes.REJECTED,
  statusTypes.REMOVED,
]);

const userShape = shape({
  first_name: string.isRequired,
  owner_id: number.isRequired,
  is_admin: bool.isRequired,
  last_name: string.isRequired,
  position: string.isRequired,
  status: userStatuses.isRequired,
  profile_image_thumb_url: string.isRequired,
});

const usersShape = shape({
  users: arrayOf(userShape).isRequired,
  loading: bool.isRequired,
  page: number.isRequired,
});

const filtersShape = shape({
  active: shape({
    name: string.isRequired,
    count: number.isRequired,
  }),
  pending: shape({
    name: string.isRequired,
    count: number.isRequired,
  }),
  removed: shape({
    name: string.isRequired,
    count: number.isRequired,
  }),
});

const filterTypes = oneOf([
  statusTypes.ACTIVE,
  statusTypes.PENDING,
  statusTypes.REMOVED,
]);

const selectedActionTypes = oneOf(
  Object.values(userActionTypes).concat(Object.values(bulkActionTypes))
);

const actionHandlersShape = shape({
  [userActionTypes.PROMOTE]: func.isRequired,
  [userActionTypes.WITHDRAW]: func.isRequired,
  [userActionTypes.APPROVE]: func.isRequired,
  [userActionTypes.REMOVE]: func.isRequired,
  [userActionTypes.DENY]: func.isRequired,
  [userActionTypes.BLOCK]: func.isRequired,
  cancel: func.isRequired,
});

export default {
  filtersShape,
  filterTypes,
  usersShape,
  userShape,
  selectedActionTypes,
  actionHandlersShape,
};
