import styled from 'styled-components/macro';

import SelectDropdown from 'components/GeneralComponents/SelectDropdown/index';

const textColor = '#90959A';

const ChannelDropdown = styled(SelectDropdown).attrs((props) => ({
  handleChange: props.handleChange,
  toggled: props.toggled,
  options: props.options,
  apiCalled: true,
  loadOptions: () => true,
  currentElement: props.currentElement
    ? props.currentElement
    : props.options[0],
  classes: 'groups',
}))`
  width: 100%;

  .select-dropdown--mask {
    width: 100%;
  }

  .select-dropdown--list {
    width: 100%;
    max-width: auto;
  }

  .select-dropdown--list-item {
    width: 100%;
    text-align: center;
  }
`;

export default ChannelDropdown;
