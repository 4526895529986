import React from 'react';
import { checkForAuthentication } from 'helpers/cookies';
import { includes } from 'helpers/validation';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


import PageHeader from 'components/PageHeader/PageHeader';

const navigateToHomePage = () => {
};

const navigateToCalendarPage = () => {
};

const navigateToTeamPage = () => {
};

const navigateToTeamFeedPage = () => {
};

const navigateToSchedulerPage = () => {
};

const navigateToShiftsPage = () => {
};

const navigateToUserManagementPage = () => {
};

//const NavBar = ({ locationSettings: { cfg__group_chat } }) => {
const NavBar = ({ locationSettings, user }) => {
  const path = window.location.pathname;
  const showSchedulerTab = false; //user && (user.id === 15408||user.id === 7709 || user.id === 12799 || user.id === 14815 || user.id === 14415 || user.id === 12227) ? true : false;
  const showShiftsTab = false; //user && (user.id === 15408 || user.id === 7709 || user.id === 12799 || user.id === 14815 || user.id === 14415) ? true : false;
  const showUserManageTab = true;
  const showTeamTab = false;
  if (
    checkForAuthentication() &&
    !includes(path, 'login') &&
    !includes(path, 'sign-up') &&
    !includes(path, 'password')
  ) {
    return (
      <header className="header">
        <PageHeader authenticated />

        <ul className="page-header--lower-section">
          <li>
            <NavLink
              activeClassName="active-page"
              className="btn btn-nav"
              strict
              exact
              onClick={navigateToHomePage}
              to="/"
            >
              <img
                src="/icons/dashboard-icon.png"
                width="15"
                alt="Dashboard Icon"
              />
              <span className="btn-nav--text">
                <FormattedMessage
                  id="NavBar.dashboard"
                  defaultMessage="Dashboard"
                />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active-page"
              className="btn btn-nav"
              to="/calendar"
              onClick={navigateToCalendarPage}
            >
              <img
                src="/icons/calendar-icon.png"
                width="15"
                alt="Calendar Icon"
              />
              <span className="btn-nav--text">
                <FormattedMessage
                  id="NavBar.calendar"
                  defaultMessage="Calendar"
                />
              </span>
            </NavLink>
          </li>
          {showUserManageTab && <li>
            <NavLink
              activeClassName="active-page"
              className="btn btn-nav"
              to="/usermanagement"
              onClick={navigateToUserManagementPage}
            >
              <img src="/icons/team-icon.png" width="15" alt="Team Icon" />
              <span className="btn-nav--text">
                <FormattedMessage id="NavBar.userManagement" defaultMessage="User Management" />
              </span>
            </NavLink>
          </li>}
          {showTeamTab && <li>
            <NavLink
              activeClassName="active-page"
              className="btn btn-nav"
              to="/team"
              onClick={navigateToTeamPage}
            >
              <img src="/icons/team-icon.png" width="15" alt="Team Icon" />
              <span className="btn-nav--text">
                <FormattedMessage id="NavBar.team" defaultMessage="Team" />
              </span>
            </NavLink>
          </li>}
          {!locationSettings.cfg__group_chat || (
            <li>
              <NavLink
                activeClassName="active-page"
                className="btn btn-nav"
                to="/team-feed"
                onClick={navigateToTeamFeedPage}
              >
                <img
                  src="/icons/team-feed-icon.svg"
                  width="15"
                  alt="Team Feed Icon"
                />
                <span className="btn-nav--text">
                  <FormattedMessage
                    id="NavBar.teamFeed"
                    defaultMessage="Team Feed"
                  />
                </span>
              </NavLink>
            </li>
          )}
          {showSchedulerTab && <li>
              <NavLink
                activeClassName="active-page"
                className="btn btn-nav"
                to="/scheduler"
                onClick={navigateToSchedulerPage}
              >
                <img
                  src="/icons/team-feed-icon.svg"
                  width="15"
                  alt="Team Feed Icon"
                />
                <span className="btn-nav--text">
                  <FormattedMessage
                    id="NavBar.scheduler"
                    defaultMessage="Scheduler"
                  />
                </span>
              </NavLink>
            </li>}
          {showShiftsTab && <li>
              <NavLink
                activeClassName="active-page"
                className="btn btn-nav"
                to="/shift"
                onClick={navigateToShiftsPage}
              >
                <img
                  src="/icons/calendar-icon.png"
                  width="15"
                  alt="Team Feed Icon"
                />
                <span className="btn-nav--text">
                  <FormattedMessage
                    id="NavBar.shifts"
                    defaultMessage="Shift"
                  />
                </span>
              </NavLink>
            </li>}
        </ul>
      </header>
    );
  }
  if (
    !checkForAuthentication() &&
    (includes(path, 'login') ||
      includes(path, 'sign-up') ||
      includes(path, 'add-location'))
  ) {
    return <PageHeader authenticated={false} />;
  }
  return <PageHeader authenticated={false} />;
};

export default NavBar;
