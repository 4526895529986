import React from 'react';

import { obfuscatePhoneNumber } from 'helpers/formatting';
import Icon from 'components/GeneralComponents/Icon';

import './ExistingAccountComponent.scss';

const ExistingAccountComponent = ({ isChecked, user, value }) => {
  const CheckMark = () => {
    if (isChecked) {
      return (
        <div className="indicator indicator__checked">
          <span>
            <Icon color="#FFFFFF" type="checkmark" />
          </span>
        </div>
      );
    }
    return <div className="indicator" />;
  };
  return (
    <section className="existing-account">
      <div className="existing-account__indicator">
        <CheckMark />
      </div>
      <div className="existing-account__details">
        <p className="existing-account__name">
          {`${user.first_name} ${user.last_name}`}
        </p>
        <p className="existing-account__phone">{obfuscatePhoneNumber(value)}</p>
      </div>
    </section>
  );
};

export { ExistingAccountComponent };
