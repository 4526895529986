import { connect } from 'react-redux';
import TeamSearchComponent from './TeamSearchComponent';

import { teamActions, teamOperations } from '../../duck';

const mapStateToProps = ({
  teamReducer: { search },
  locationReducer: { location_code: locationId },
}) => {
  return {
    locationId,
    searchTerm: search.searchTerm,
    searchReturned: search.searchReturned,
  };
};

const mapDispatchToProps = (dispatch) => {
  const updateSearchTerm = (searchTerm, searchReturned, locationId) => {
    dispatch(teamActions.updateSearchTerm(searchTerm));

    if (searchTerm.length === 0 && searchReturned) {
      dispatch(teamActions.updateSearching(true));
      dispatch(teamActions.updateSearchReturned(false));
      dispatch(teamOperations.fetchTeamMetadata(locationId));
    }
  };

  const submitSearch = (locationId, searchTerm) => {
    dispatch(teamActions.updateSearching(true));
    dispatch(teamActions.updateSearchReturned(true));
    dispatch(teamOperations.fetchTeamMetadata(locationId, searchTerm));
  };

  return { updateSearchTerm, submitSearch };
};

const TeamSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSearchComponent);

export default TeamSearchContainer;
