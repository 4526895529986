import { connect } from 'react-redux';
import PostShiftComponent from './PostShiftComponent';
import { dashboardOperations } from '../Dashboard/duck';

const mapStateToProps = ({ appReducer, setupReducer, locationReducer}) => {
  const unsortedChannels = appReducer.channels.data.map((element) => ({
    text: element.channel_name,
    value: element.id,
    type: element.channel_type,
    member_count: element.member_count,
  }));

  const channels = [
    unsortedChannels.filter((channel) => channel.type === 'location_feed')[0],
    ...unsortedChannels.filter((channel) => channel.type !== 'location_feed'),
  ];

  const channelsLoading = appReducer.channels.loading;
  const vtoEnabled = setupReducer.setup_actions.meta.current_location.cfg_vto;
  const postOpenShiftEnabled =
    appReducer.location.settings.cfg__shift__post_open_shift;
  const locationId = locationReducer.location_code;
  const costCenterData = appReducer.location.costCenterData;
  const skills = appReducer.skills.data;
  const locationSettings = appReducer.location.settings;
  const userPrivileges = appReducer.users.data;
  const channelMembers = appReducer.users.data;
  const actionMenuEnabled = appReducer.location.settings.cfg__action_menu;

  return {
    channels,
    vtoEnabled,
    postOpenShiftEnabled,
    channelsLoading,
    costCenterData,
    locationId,
    skills,
    locationSettings,
    userPrivileges,
    channelMembers,
    actionMenuEnabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCostCenterData: (locationId) =>
    dispatch(dashboardOperations.getCostCenterData(locationId)),
  getLocationUsableSkills: (locationId) =>
    dispatch(dashboardOperations.getLocationUsableSkills(locationId)),
  /*
  getLocationEmployees: (locationId) =>
    dispatch(dashboardOperations.getLocationEmployees(locationId)),
  */
  fetchChannelMembers: (channelId) =>
    dispatch(dashboardOperations.fetchChannelMembers(channelId)),
});

const PostShiftContainer = connect(mapStateToProps,mapDispatchToProps)(PostShiftComponent);

export default PostShiftContainer;
