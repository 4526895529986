import React from 'react';
import styled from 'styled-components/macro';
import { ConfirmationSheet } from '@shyft/acorns';

import PageWrap from 'components/GeneralComponents/PageWrap';
import confirmation from './confirmation';
import elements from './elements';
import components from './components';
import { getUserId } from '../../helpers/cookies';

const BigWrap = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`;

const TeamFeedWrap = styled(PageWrap)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
`;
/*
const TeamFeed = ({
  active,
  clearActiveDelete,
  deletePost,
  deleteComment,
  udpateChannelContent,
  posts,
}) => {
  const deleteType =
    active.error.code && active.error.code !== 404
      ? 'error'
      : active.delete.type;
  const handleDelete = (id) => {
    const postIndex = posts.data.findIndex((post) => post.id === id);

    deletePost(id);

    if (postIndex === posts.data.length - 1 && posts.data.length >1) {
      udpateChannelContent(
        active.channel,
        posts.data[posts.data.length - 2].content
      );
    }
  };
  const deleteCallback = (deleteType === 'post' || deleteType === 'announcement') ? handleDelete : deleteComment;

  return (
    <BigWrap>
      <TeamFeedWrap direction="row" full>
        <components.Channels />
        <elements.ColumnWrap>
          {active.channel ? <components.Posts /> : null}
        </elements.ColumnWrap>
        {active.sidepane === 'comments' ? <components.Comments /> : null}
        {active.sidepane === 'read-receipts' ? (
          <components.ReadReceipts />
        ) : null}
        {active.sidepane === 'pins' ? <components.Pins /> : null}
      </TeamFeedWrap>
      <ConfirmationSheet
        bodyText={confirmation.bodyText(deleteType)}
        buttons={confirmation.buttons(
          [clearActiveDelete, deleteCallback],
          active
        )}
        visible={active.delete.id}
      />
    </BigWrap>
  );
};

export default TeamFeed;

*/

class TeamFeed extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state ={
      debug: false,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts,} = this.props;
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts,} = this.props;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { active, clearActiveDelete, deletePost, deleteComment, udpateChannelContent, posts,} = this.props;
    const deleteType = active.error.code && active.error.code !== 404 ? 'error' : active.delete.type;
    const handleDelete = (id) => {
      const postIndex = posts.data.findIndex((post) => post.id === id);

      deletePost(id);

      if (postIndex === posts.data.length - 1 && posts.data.length >1) {
        udpateChannelContent(
          active.channel,
          posts.data[posts.data.length - 2].content
        );
      }
    };

  const deleteCallback = (deleteType === 'post' || deleteType === 'announcement') ? handleDelete : deleteComment;

    return (
      <BigWrap>
        <TeamFeedWrap direction="row" full>
          <components.Channels />
          <elements.ColumnWrap>
            {active.channel ? <components.Posts /> : null}
          </elements.ColumnWrap>
          {active.sidepane === 'comments' ? <components.Comments /> : null}
          {active.sidepane === 'read-receipts' ? (
            <components.ReadReceipts />
          ) : null}
          {active.sidepane === 'pins' ? <components.Pins /> : null}
        </TeamFeedWrap>
        <ConfirmationSheet
          bodyText={confirmation.bodyText(deleteType)}
          buttons={confirmation.buttons(
            [clearActiveDelete, deleteCallback],
            active
          )}
          visible={active.delete.id}
        />
      </BigWrap>
    );
  }
}

TeamFeed.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default TeamFeed;
