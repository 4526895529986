import styled from 'styled-components/macro';
import { rgba } from 'polished';

const TeamSearchForm = styled.form`
  display: flex;

  @media (max-width: 720px) {
    padding-bottom: 1.5rem;
  }
`;

const TeamSearchInput = styled.input`
  min-width: 260px;
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 6px;
  transition: all 0.25s;
  background-image: ${() =>
    `url(${process.env.PUBLIC_URL}/icons/search-icon.svg)`};
  background-repeat: no-repeat;
  background-position: 8px 10px;
  padding-left: 30px;
  margin-right: 0.88rem;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary[0]};
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
    transition: all 0.25s;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.label};
    font-size: 0.88rem;
    font-weight: 400;
  }

  @media (max-width: 420px) {
    min-width: 200px;
  }
`;

export default {
  TeamSearchInput,
  TeamSearchForm,
};
