import { connect } from 'react-redux';

import { dashboardOperations } from '../duck';
import RecentRequestsComponent from './RecentRequestsComponent';

const mapStateToProps = (
  { dashboardDuckReducer: { pendingShifts, requestTimeOffs, managedRTOs, userPrivileges, skills }, setupReducer, appReducer, },
  ownProps
) => ({
  ...ownProps,
  pendingShifts: pendingShifts.data,
  locationConfig:
    setupReducer.setup_actions.meta.current_location.configuration,
  locationId:
    setupReducer.setup_actions.meta.current_location.id,
  requestTimeOffs: requestTimeOffs.data,
  totalRTOs: requestTimeOffs.total,
  managedRTOs: managedRTOs.data,
  userPrivileges: userPrivileges.data,
  user: setupReducer.setup_actions.meta.current_user,
  loading: pendingShifts.loading,
  shiftsWithApplicants: pendingShifts.shiftsWithApplicants,
  fetchFailed: pendingShifts.fetchFailed,
  userIsAdmin: setupReducer.setup_actions.meta.current_user.current_is_admin,
  locationSettings: appReducer.location.settings,
  skills: skills.data,
  channels: appReducer.channels.data,
  costCenterData: appReducer.location.costCenterData,
  departments: appReducer.departments.data,
  myDepartments: appReducer.departments.myDepartments,
});

const mapDispatchToProps = (dispatch) => ({
  approvePendingShift: (shift, configId) =>
    dispatch(dashboardOperations.approvePendingShift(shift, configId)),
  denyPendingShift: (shift, configId) =>
    dispatch(dashboardOperations.denyPendingShift(shift, configId)),
  fetchPendingShifts: () => dispatch(dashboardOperations.fetchPendingShifts()),
  fetchMorePendingShifts: (pageNum, perPageNum) =>
    dispatch(dashboardOperations.fetchMorePendingShifts(pageNum, perPageNum)),
  fetchPendingShiftSegments: (id) =>
    dispatch(dashboardOperations.fetchPendingShiftSegments(id)),
  approveRequestTimeOff: (rto) =>
    dispatch(dashboardOperations.approveRequestTimeOff(rto)),
  denyRequestTimeOff: (rto) =>
    dispatch(dashboardOperations.denyRequestTimeOff(rto)),
  fetchShiftApplicants: (id, userPrivileges) =>
    dispatch(dashboardOperations.fetchShiftApplicants(id, userPrivileges)),
  resetFetchFailedFlag: () => dispatch(dashboardOperations.resetFetchFailedFlag),

});

const RecentRequestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentRequestsComponent);

export default RecentRequestsContainer;
