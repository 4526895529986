import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';

import constants from '../../constants';
import styled from '../Header/styled';
import '../Header/Header.scss';

class Confirm extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state ={
      debug: false,
    };

    this._isMounted = false;
  }

  componentDidMount() {
   const { locationData,  locations, } = this.props;

   this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancel = (event) => {
  	const { notifyConfirm, } = this.props;
  	notifyConfirm(false);
  }

  handleConfirm = (event) => {
  	const { notifyConfirm, } = this.props;
  	notifyConfirm(true);
  }

  render () {
  	const { selectedAction, selectedUsers, } = this.props;
    const { userActionTypes } = constants;

  	//let winTop = `400px`; //`${(window.innerHeight - 612)/2}px`;
    //let winLeft = `600px`; //`${(window.innerWidth - 1200)/2}px`;
    //const winW = '400px';//`50%`;
    //const winH = `150px`; //94.9% viewport height

    let w = 400;
    let h = 205;
    let winTop = `${(window.innerHeight - h)/2}px`;
    let winLeft = `${(window.innerWidth - w)/2}px`;
    let winH = `${h}px`;
    let winW = `${w}px`;
    
    /*
    if(this.openRef) {
      const domNode = ReactDOM.findDOMNode(this.openRef.current);
      const output =domNode.getBoundingClientRect();
      if(output) {
        winTop = `${output.y + output.height}px`;
        winLeft = `${output.x}px`;
      }
    }
    */
    const nonAdmins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === false): [];
    const admins = selectedUsers ? selectedUsers.filter((user) => user.is_admin === true): [];

    const grantText = (<FormattedMessage
      id="RowConfirm.grant"
      defaultMessage="{number, plural, one {{number} user selected will be granted admin privileges} other {{number} users selected will be granted admin privileges}}" 
      values={{number: 1}}/>);
    const grantAlert = (<FormattedMessage
      id="RowConfirm.grantAlert"
      defaultMessage="{number, plural, one {{number} user selected is already admin} other {{number} users selected are already admins}}" 
      values={{number: 1}}/>);


    const withdrawText = (<FormattedMessage
      id="RowConfirm.withdraw"
      defaultMessage="{number} user selected will have admin privileges withdrawn" 
      values={{number: 1}}/>);

    const withdrawAlert = (<FormattedMessage
      id="RowConfirm.withdrawAlert"
      defaultMessage="{number, plural, one {{number} user selected does not have admin privileges} other {{number} users selected do not have admin privileges}}" 
      values={{number: 1}}/>);
    
    const removeText = (<FormattedMessage
      id="RowConfirm.remove"
      defaultMessage="{number, plural, one {{number} user selected will be removed from this location} other {{number} users selected will be removed from this location}}" 
      values={{number: 1}}/>);
    
    const removeAlert = (<FormattedMessage
      id="RowConfirm.removeAlert"
      defaultMessage="This action cannot be undone"
    />);
    
    const cancel = (<FormattedMessage
      id="RowConfirm.cancel"
      defaultMessage="Cancel"
    />);
    const confirm = (<FormattedMessage
      id="RowConfirm.confirm"
      defaultMessage="Confirm"
    />);
   

    let topLine = '';
    let bottomLine = '';
    switch(selectedAction.name) {
      case userActionTypes.PROMOTE:
          topLine = grantText;
          bottomLine = grantAlert;
        break;
      case userActionTypes.WITHDRAW:
          topLine = withdrawText;
          bottomLine = withdrawAlert;
        break;
      case userActionTypes.REMOVE:
          topLine = removeText;
          bottomLine = removeAlert;
        break;
      case userActionTypes.APPROVE:
        topLine = approveText;
        break;
      case userActionTypes.DENY:
        topLine = denyText;
        break;
    }

    if(selectedAction.name !== userActionTypes.REMOVE) {
      if(selectedAction.name === userActionTypes.PROMOTE || selectedAction.name === userActionTypes.WITHDRAW) {
      	return (
      		<styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
      		 	<label className="confirm__title">{selectedAction.body}</label>
      		 	<div className="confirm__label-wrap">
      		 		<div className="confirm__text-wrap">
      		 			<img className="confirm__img" src="/icons/checkmark-icon.svg"/>
      		 			<label className="confirm__text">{topLine}</label>
      		 		</div>
              {/*<div className="confirm__text-wrap">
      		 			<img className="confirm__img" src="/icons/exclaimation-mark-icon.svg"/>
      		 			<label className="confirm__text">{bottomLine}</label>
      		 		</div>*/}
            </div>
            <div className="confirm__button-wrap">
    	        <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
    	        <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
    	        </div>
          </styled.WinContainerConfirm>
      	);
      }else if(selectedAction.name === userActionTypes.APPROVE || selectedAction.name === userActionTypes.DENY) {
        return (
          <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
            <label className="confirm__title">{selectedAction.body}</label>
            <div className="confirm__label-wrap">
              <div className="confirm__text-wrap">
                {/*<img className="confirm__img" src="/icons/checkmark-icon.svg"/>*/}
                <label className="confirm__text">{topLine}</label>
              </div>
            </div>
            <div className="confirm__button-wrap">
              <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
              <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
              </div>
          </styled.WinContainerConfirm>
        );
      }
    }else {
      return (
         <styled.WinContainerConfirm top={winTop} left={winLeft} width={winW} height={winH}>
          <label className="confirm__title">{selectedAction.body}</label>
          <div className="confirm__label-wrap">
            <div className="confirm__text-wrap">
              <img className="confirm__img" src="/icons/alert-icon.svg"/>
              <label className="confirm__text">{topLine}</label>
            </div>
            <div className="confirm__text-wrap">
              <img className="confirm__img" src="/icons/warning.svg"/>
              <label className="confirm__text">{bottomLine}</label>
            </div>
          </div>
          <div className="confirm__button-wrap">
            <button className="confirm__button-cancel" onClick={this.handleCancel}>{cancel}</button>
            <button className="confirm__button" onClick={this.handleConfirm}>{confirm}</button>
            </div>
        </styled.WinContainerConfirm>
      );
    }
  }
}

Confirm.propTypes = {
  selectedItem: PropTypes.object.isRequired,
  selectedType: PropTypes.string.isRequired
};

export default injectIntl(Confirm);
