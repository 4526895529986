import React from 'react';
import { FormattedMessage } from 'react-intl';

import locationIconSvg from './assets/location-required-icon.svg';
import './IpWhitelistComponent.scss';

const IpWhitelistComponent = () => {
  return (
    <article className="dashboard">
      <article className="general-data--container general-data--container--request-location">
        <div className="ip-white-list-page">
          <img
            className="ip-whitelist-page__icon"
            src={locationIconSvg}
            alt="Pin icon inside circles"
          />
          <h2>
            <FormattedMessage
              id="IpWhitelistComponent.oops"
              defaultMessage="Oops! You're out of bounds."
            />
          </h2>
          <p className="ip-whitelist-page__status-text">
            <FormattedMessage
              id="IpWhitelistComponent.outsideStore"
              defaultMessage="It looks like you're trying to access the Shyft Manager Dashboard from outside of the store. Please note that the dashboard is only available on company devices."
            />
          </p>
        </div>
      </article>
    </article>
  );
};

export default IpWhitelistComponent;
