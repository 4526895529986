import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { validateEmail } from 'helpers/validation';
import { StartPasswordReset } from 'helpers/api-calls';

import './ForgotPasswordPage.scss';

class ForgotPasswordPagei18n extends React.Component {
  state = {
    email: {
      value: '',
      validity: false,
      showValidity: false,
      submitted: false,
    },
    error: {
      value: '',
      showError: false,
    },
  };

  messages = defineMessages({
    error1: {
      id: 'ForgotPasswordPage.error1',
      defaultMessage: 'There was an error! Please try again',
    },
    error2: {
      id: 'ForgotPasswordPage.error2',
      defaultMessage: "We couldn't find that email! Please try another one",
    },
    error3: {
      id: 'ForgotPasswordPage.error3',
      defaultMessage: 'Please enter a valid email',
    },
    email: {
      id: 'ForgotPasswordPage.email',
      defaultMessage: 'Email',
    },
  });

  _handleEmailChange = (e) => {
    const { value } = e.target;

    this.setState({
      email: Object.assign(this.state.email, {
        value,
        validity: validateEmail(value),
      }),
    });
  };

  _formSubmit = (e) => {
    e.preventDefault();

    if (this.state.email.validity) {
      this._resetPassword();
    } else {
      this.setState({
        email: Object.assign(this.state.email, {
          showValidity: true,
        }),
        error: Object.assign(this.state.error, {
          value: '',
          showError: false,
        }),
      });
    }
  };

  _resetPassword = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    StartPasswordReset(this.state.email.value).then(
      (response) => {
        if (response.status === 201) {
          this.setState({
            email: Object.assign(this.state.email, {
              submitted: true,
            }),
          });
        } else {
          this.setState({
            error: Object.assign(this.state.error, {
              value: formatMessage(this.messages.error1),
              showError: true,
            }),
          });
        }
      },
      (error) => {
        if (
          error.response.data.errors.email &&
          error.response.data.errors.email[0] === 'not found'
        ) {
          this.setState({
            error: Object.assign(this.state.error, {
              value: formatMessage(this.messages.error2),
              showError: true,
            }),
          });
        }
      }
    );
  };

  _resendEmail = (e) => {
    e.preventDefault();
    this._resetPassword();
  };

  _errorToShow = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    if (this.state.error.showError && this.state.error.value.length > 0) {
      return this.state.error.value;
    }
    if (this.state.email.showValidity) {
      return formatMessage(this.messages.error3);
    }
    return '';
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    if (this.state.email.submitted) {
      return (
        <article className="login">
          <div className="login__wrap">
            <h2 className="login__header">
              <FormattedMessage
                id="ForgotPasswordPage.successHeader"
                defaultMessage="Success!"
              />
            </h2>
            <section className="login__description-container">
              <p className="login__description">
                <FormattedMessage
                  id="ForgotPasswordPage.loginDescription"
                  defaultMessage="We have sent an email with a link to reset your password. It should show up within a minute or two."
                />
              </p>
              <span className="login__description inline">
                <FormattedMessage
                  id="ForgotPasswordPage.resendEmailPrompt"
                  defaultMessage="Didn't get an email?"
                />
              </span>
              <a className="login__link" href="" onClick={this._resendEmail}>
                <FormattedMessage
                  id="ForgotPasswordPage.resendEmailLink"
                  defaultMessage="Resend it"
                />
              </a>
            </section>
          </div>
        </article>
      );
    }
    return (
      <React.Fragment>
        <article className="login">
          <div className="login__wrap">
            <h2 className="login__header">
              <FormattedMessage
                id="ForgotPasswordPage.resetyourPassword"
                defaultMessage="Reset Your Password"
              />
            </h2>
            <form className="login__form" onSubmit={this._formSubmit}>
              <section className="login__form-group">
                <input
                  onChange={this._handleEmailChange}
                  name="email"
                  autoComplete="email"
                  placeholder={formatMessage(this.messages.email)}
                  className="login__input-email"
                />
              </section>

              <section className="login__form-action">
                <button
                  className="login__form-submit button button-primary"
                  type="submit"
                >
                  <FormattedMessage
                    id="ForgotPasswordPage.submitPasswordButton"
                    defaultMessage="Submit"
                  />
                </button>
              </section>

              <section className="login__form-errors">
                <p className="login__form-errors--primary-red">
                  {this._errorToShow()}
                </p>
              </section>
            </form>
          </div>
        </article>
      </React.Fragment>
    );
  }
}

const ForgotPasswordPage = injectIntl(ForgotPasswordPagei18n);

export { ForgotPasswordPage };
