import styled from 'styled-components/macro';
import { css } from 'styled-components';

import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import { lighten } from 'polished';

const WRAP_MODIFIERS = {
  active: ({ theme }) => css`
    background-color: ${theme.palette.border};
    cursor: default;

    &:hover {
      background-color: ${theme.palette.border};
    }
  `,
};
const MessageWrap = styled.div`
  position: relative;
  display: flex;
  padding: 1rem;
  background-color: white;

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.palette.border)};
  }

  ${applyStyleModifiers(WRAP_MODIFIERS)}
`;
MessageWrap.proptypes = {
  modifiers: styleModifierPropTypes(WRAP_MODIFIERS),
};

const Info = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.secondary};
  min-width: 0;
`;

export default {
  MessageWrap,
  Info,
};
