import { shape, string, number, arrayOf, bool } from 'prop-types';

const channelsDataShape = shape({
  id: number.isRequired,
  channel_name: string.isRequired,
  updated_at: string.isRequired,
  channel_type: string.isRequired,
  channel_latest_content: string.isRequired,
});

const channelsShape = shape({
  data: arrayOf(channelsDataShape).isRequired,
  loading: bool.isRequired,
});

export default {
  channelsShape,
  channelsDataShape,
};
