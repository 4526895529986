import { protectedAxiosInstance, axiosInstance } from 'helpers/api-calls';
import { BASE_URL, BASE_SUBDOMAIN_URL } from '../../constants';
import { createHeadersForApiCall, getUserId, getSessionUukey, createHeadersForPDFUploading, createHeadersForUploadImage } from '../cookies';
import { StatusCheck } from '../authentication';
import { getLocationCode, getLocaleCookie } from '../cookies';

/**
 * Upload scheduler file
 * @param { file } 
 *
 * @return { Object } uploading status
 *                   
 */
export function UploadSchedulerFile(file, location_id) {
  var formData = new FormData();
  formData.append('file', file);

  return fetch(`https://${BASE_URL}/api/integration/shifts/bulk_upsync?location_id=${location_id}&api_default_record_action=skip_or_create`, {
    headers: createHeadersForPDFUploading(),
    method: 'POST',
    body: formData,
  });

};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetLocationSchedules = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/schedules?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetLocationActionJobs = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/action_jobs?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets schedules on current location.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetActionJob = (location_id, uuid) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/action_jobs?uuid=${uuid}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      uuid,
      page: 1,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets shifts in a schedule.
 * 
 * 
 * @param  { String | Int } {location_id}
 */
export const GetShiftsInSchedule = (location_id, schedule_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/schedule_elements/can_admin?location_id=${location_id}&schedule_id=${schedule_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      schedule_id,
      page: 1,
      per_page: 1000,
      total: true,
    },
  });
};


export default {
  //unpinPost,
};

