import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

//import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
//import PageWrap from 'components/GeneralComponents/PageWrap';
//import Modal from 'components/GeneralComponents/Modal';
//import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
//import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import './Title.scss';

class Title extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
    };

    this.messages = defineMessages({
      placeholder: {
        id: 'Title.placeholder',
        defaultMessage: 'Search',
      },
    });

    this._isMounted = false;
  }

  componentDidMount() {
   
   this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchClicked = (event) => {
    alert('seach clicked');
  }

  handleQuestionClicked = (event) => {
    alert('question clicked');
  }

  handlePlusClicked = (event) => {
    alert('plus clicked');
  }


  render() {
    const { searchTerm, } = this.state;
    const { intl,  } = this.props;

    const employee = (<FormattedMessage
      id="Title.employee"
      defaultMessage="Employee"
    />);



    return (
      <div className="title__wrap">
        <p className="title__text">{employee}</p>
        <div className="title__button-group">
          <button className="title__search-button" onClick={this.handleSearchClicked}/ >
          <button className="title__question-button" onClick={this.handleQuestionClicked}/>
          <button className="title__plus-button" onClick={this.handlePlusClicked} />
        </div>
      </div>
    )
  }
}

Title.propTypes = {
  
};

export default injectIntl(Title);

