import { connect } from 'react-redux';
import { appActions } from 'App/duck';
import TeamFeed from './TeamFeedComponent';

import { teamFeedActions, teamFeedOperations } from './duck';

const mapStateToProps = ({
  teamFeedReducer: { active, posts, readReceipts },
}) => ({
  active,
  posts,
  readReceipts,
});

const mapDispatchToProps = (dispatch) => {
  const clearActiveDelete = () => {
    dispatch(teamFeedActions.clearActiveDelete());
    dispatch(teamFeedActions.updateActiveComment(0));
    dispatch(teamFeedActions.updateActiveError(false));
  };
  const deletePost = (postId) => {
    dispatch(teamFeedOperations.deletePost(postId));
  };
  const deleteComment = (commentId, post) => {
    dispatch(teamFeedOperations.deleteComment(commentId, post));
    // the line below is copied to operations.js line 148
    //dispatch(teamFeedActions.decrementPostCommentCount(postId));
  };
  const udpateChannelContent = (id, content) => {
    dispatch(appActions.updateLatestContent({ id, content }));
  };

  return {
    clearActiveDelete,
    deletePost,
    deleteComment,
    udpateChannelContent,
  };
};

const TeamFeedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamFeed);

export default TeamFeedContainer;
