import { createSlice } from '@reduxjs/toolkit';

//import teamConstants from '../../constants';

//const { statusTypes } = teamConstants;

const managersSlice = createSlice({
  name: 'managers',
  initialState: {
    users: [],
    page: 0,
    totalPages: 0,
    loading: false,
    lastAction: null,
    lastOperation: null,
  },
  reducers: {
    requestManagers: (state, action) => {
      state.loading = true;
    },
    receiveManagers: (state, action) => {
      state.users = action.payload.users;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendManagers: (state, action) => {
      //state.users = state.users.concat(action.payload.users);
      state.users = state.users.concat(action.payload.users);
      state.loading = false;
    },
    removeManager: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload.id
      );
      state.users.splice(memberIndex, 1);
    },
    selectManager: (state, action) => {
      const { payload } = action;

      state.selectedUsers.push(payload);
    },
    deselectManager: (state, action) => {
      const filteredArr = state.selectedUsers.filter(
        (userId) => userId !== action.payload
      );

      state.selectedUsers = filteredArr;
    },
    selectAllManagers: (state, action) => {
      state.selectedUsers = state.users.map((user) => user.id);
    },
    deselectAllManagers: (state, action) => {
      state.selectedUsers = [];
    },
    removeManagers: (state, action) => {
      action.payload.forEach((item) => {
        const memberIndex = state.users.findIndex(
          (member) => member.id === item
        );
        state.users.splice(memberIndex, 1);
      });
    },
    lastAction: (state, action) => {
      state.lastAction = action.payload;
    },
    resetManagers: (state, action) => {
      state.users = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false;
      state.lastAction = null;
    },
    lastOperation: (state, action) => {
      state.lastOperation = action.payload;
    },
    addSkillsToManager: (state, action) => {
      const privilegeIndex = state.users.findIndex(
        (priv) => priv.id === action.payload.id
      );
      if(privilegeIndex >= 0) {
        const newUsers = [...state.users];
        newUsers[privilegeIndex].user.skill_ids = action.payload.skill_ids;
        newUsers[privilegeIndex].email = action.payload.email;
        newUsers[privilegeIndex].phone_number = action.payload.phone_number;
        newUsers[privilegeIndex].position = action.payload.position;
        newUsers[privilegeIndex].first_name = action.payload.first_name;
        newUsers[privilegeIndex].last_name = action.payload.last_name;
        newUsers[privilegeIndex].badge_id = action.payload.badge_id;
        newUsers[privilegeIndex].profile_image_thumb_url = action.payload.profile_image_thumb_url;
        newUsers[privilegeIndex].is_admin = action.payload.is_admin;
        state.users = newUsers;
      }
      else {
        const msg = `User privilege for user ${action.payload.owner_id}  not find in state`;
        console.log(msg);
      }
    },
    addSkillIdToManager: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          //const newIds = newData[idx].skill_ids.concat(action.payload.id);
          //newData[idx].skill_ids = newIds;
          const newIds = newData[idx].user.skill_ids.concat(action.payload.id);
          newData[idx].user.skill_ids = newIds;
          state.users = newData;
        }
      }
    },
    removeSkillIdFromManager: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          //const idx1 = newData[idx].skill_ids.findIndex((id) => id === action.payload.id);
          const idx1 = newData[idx].user.skill_ids.findIndex((id) => id === action.payload.id);
          if(idx1 >= 0) {
            //newData[idx].skill_ids.splice(idx1, 1);
            newData[idx].user.skill_ids.splice(idx1, 1);
          }
          state.users = newData;
        }
      }
    },
    addDeptIdToManager: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          const newIds = newData[idx].department_ids.concat(action.payload.id);
          newData[idx].department_ids = newIds;
          state.users = newData;
        }
      }
    },
    removeDeptIdFromManager: (state, action) => {
      for(let i=0; i<action.payload.user_ids.length; i++) {
        const idx = state.users.findIndex((user) => user.owner_id === action.payload.user_ids[i]);
        if(idx >= 0) {
          const newData = [...state.users];
          const newIds = newData[idx].department_ids;
          const idx1 = newIds.findIndex((id) => id === action.payload.id);
          if(idx1 >= 0) {
            newIds.splice(idx1, 1);
          }
          newData[idx].department_ids = newIds;
          state.users = newData;
        }
      }
    },
    addChannelIdToManager: (state, action) => {
      const idx = state.users.findIndex((user) => user.owner_id === action.payload.userId);
      if(idx >= 0) {
        const newData = [...state.users];
        const newIds = newData[idx].user.channel_ids.concat(action.payload.id);
        newData[idx].user.channel_ids = newIds;
        state.users = newData;
      }
    },
    removeChannelIdFromManager: (state, action) => {
      const idx = state.users.findIndex((user) => user.owner_id === action.payload.userId);
      if(idx >= 0) {
        const newData = [...state.users];
        const newIds = newData[idx].user.channel_ids;
        const idx1 = newIds.findIndex((id) => id === action.payload.id);
        if(idx1 >= 0) {
          newIds.splice(idx1, 1);
        }
        newData[idx].user.channel_ids = newIds;
        state.users = newData;
      }
    },
  },
});

export default managersSlice;
