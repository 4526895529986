import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { teamShapes, teamConstants } from '../../duck';
import { default as Styled } from './styled';
import TeamSearch from '../TeamSearch';
import TeamFilters from '../TeamFilters';

class TeamHeaderComponent extends React.PureComponent {
  state = {
    staticSearchTerm: '',
    activeCount: '',
    pendingCount: '',
  };

  componentDidMount() {
    const { searchTerm, filters } = this.props;

    this.setState({
      staticSearchTerm: searchTerm,
      activeCount: filters.active.count,
      pendingCount: filters.pending.count,
    });
  }

  componentDidUpdate(prevProps) {
    const { searching, searchTerm, filters } = this.props;

  // Fixed bug WS-1223----------------------------------------
    // Prevents the SearchInfo text from updating the search term onchange
    /*if (prevProps.searching !== searching) {
      this.setState({
        staticSearchTerm: searchTerm,
      });
    }*/

    this.setState({
        staticSearchTerm: searchTerm,
      });
  //End of Fixed bug WS-1223------------------------------------
  
    if (prevProps.filters.active.count !== filters.active.count) {
      this.setState({
        activeCount: filters.active.count,
        pendingCount: filters.pending.count,
      });
    }
  }

  _handleSelectAll = () => {
    const { activeFilter, handleSelectAll, selectedAll } = this.props;
    const { bulkActionTypes, statusTypes } = teamConstants;

    switch (activeFilter) {
      case statusTypes.ACTIVE:
        handleSelectAll(selectedAll, bulkActionTypes.SELECT_ACTIVE_BULK);
        break;
      case statusTypes.PENDING:
        handleSelectAll(selectedAll, bulkActionTypes.PENDING_BULK);
        break;
      default:
        break;
    }
  };

  _renderSearchString = () => {
    const { staticSearchTerm, activeCount, pendingCount } = this.state;
    const { activeFilter, searchReturned } = this.props;

    if (activeFilter === teamConstants.statusTypes.REMOVED && searchReturned) {
      return (
        <Styled.SearchInfo>
          <FormattedMessage
            id="TeamHeaderComponent.removedTabSearchWarning"
            defaultMessage="The search function is currently only available for active and pending users."
          />
        </Styled.SearchInfo>
      );
    }

    if (searchReturned) {
      return (
        <Styled.SearchInfo>
          <FormattedMessage
            id="TeamHeaderComponent.searchMetadata"
            defaultMessage="We found {active} team members and {pending} team members with the keyword {searchTerm}"
            values={{
              active: (
                <Styled.SearchDetail>
                  {activeCount}&nbsp;
                  <FormattedMessage
                    id="TeamHeaderComponent.searchMetadataActiveLabel"
                    defaultMessage="active"
                  />
                </Styled.SearchDetail>
              ),
              pending: (
                <Styled.SearchDetail>
                  {pendingCount}&nbsp;
                  <FormattedMessage
                    id="TeamHeaderComponent.searchMetadataPendingLabel"
                    defaultMessage="pending"
                  />
                </Styled.SearchDetail>
              ),
              searchTerm: (
                <Styled.SearchDetail>"{staticSearchTerm}"</Styled.SearchDetail>
              ),
            }}
          />
        </Styled.SearchInfo>
      );
    }

    return null;
  };

  render() {
    const {
      searchReturned,
      clearSearch,
      locationId,
      selectedAll,
      activeFilter,
      visibleMembersCount,
    } = this.props;
    const { statusTypes } = teamConstants;
    const selectAllLabel = (
      <FormattedMessage
        id="TeamHeaderComponent.selectAll"
        defaultMessage="Select All"
      />
    );
    const SelectAll = (
      <Styled.SelectAll
        checked={selectedAll}
        label={selectAllLabel}
        onChange={this._handleSelectAll}
      />
    );

    return (
      <Styled.HeaderWrap>
        {searchReturned ? (
          <Styled.LeaveSearch
            onClick={() => {
              clearSearch(locationId);
            }}
          >
            <Styled.BackArrow />
            <FormattedMessage
              id="TeamHeaderComponent.exitSearchBtn"
              defaultMessage="Back to Team"
            />
          </Styled.LeaveSearch>
        ) : null}
        <Styled.HeaderRow>
          <TeamSearch />
          <TeamFilters />
        </Styled.HeaderRow>
        <Styled.HeaderRow>
          {activeFilter === statusTypes.REMOVED || visibleMembersCount === 0
            ? null
            : SelectAll}
        </Styled.HeaderRow>
        {this._renderSearchString()}
      </Styled.HeaderWrap>
    );
  }
}

TeamHeaderComponent.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  searchReturned: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  filters: teamShapes.filtersShape.isRequired,
  selectedAll: PropTypes.bool.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  activeFilter: teamShapes.filterTypes,
  visibleMembersCount: PropTypes.number.isRequired,
};

export default TeamHeaderComponent;
