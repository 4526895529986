import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

import './ComparisonCounter.scss';

class ComparisonCounter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainStartingNumber: 0,
      mainEndingNumber: props.mainNumber,
      comparisonPercentageStartingNumber: 0,
      comparisonPercentageEndingNumber: this.calculateComparison(
        props.mainNumber,
        props.comparisonNumber
      ),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      mainStartingNumber: this.state.mainEndingNumber,
      mainEndingNumber: props.mainNumber,
      comparisonPercentageStartingNumber: this.state
        .comparisonPercentageEndingNumber,
      comparisonPercentageEndingNumber: this.calculateComparison(
        props.mainNumber,
        props.comparisonNumber
      ),
    });
  }

  calculateComparison(mainNumber, comparisonNumber) {
    if (mainNumber === 0 && comparisonNumber > 0) {
      return -100;
    }
    if (mainNumber === 0 && comparisonNumber === 0) {
      return 0;
    }
    if (mainNumber > 0 && comparisonNumber === 0) {
      return 100;
    }
    if (mainNumber === comparisonNumber) {
      return 0;
    }
    return +((mainNumber / comparisonNumber) * 100 - 100).toFixed(1);
  }

  transitionCompleted = () => {
    return null;
  };

  render() {
    if (this.props.hideComparison) {
      return (
        <article className="comparison-counter">
          <section className="comparison-counter--main-number-container">
            <CountUp
              className="comparison-counter--main-number"
              start={this.state.mainStartingNumber}
              end={this.state.mainEndingNumber}
              duration={2}
              decimals={this.props.type === 'percentage' ? 1 : 0}
              suffix={this.props.type === 'percentage' ? '%' : ''}
              useEasing
              useGrouping
              onComplete={this.transitionCompleted}
            />
            <p className="comparison-counter--main-number-description">
              {this.props.mainDescription}
            </p>
          </section>
        </article>
      );
    }
    return (
      <article className="comparison-counter">
        <section className="comparison-counter--main-number-container">
          <CountUp
            className="comparison-counter--main-number"
            start={this.state.mainStartingNumber}
            end={this.state.mainEndingNumber}
            duration={2}
            decimals={this.props.type === 'percentage' ? 1 : 0}
            suffix={this.props.type === 'percentage' ? '%' : ''}
            useEasing
            useGrouping
            onComplete={this.transitionCompleted}
          />
          <p className="comparison-counter--main-number-description">
            {this.props.mainDescription}
          </p>
        </section>

        <section className="comparison-counter--comparison-number-container">
          <CountUp
            className="comparison-counter--comparison-number"
            decimals={1}
            start={this.state.comparisonPercentageStartingNumber}
            end={this.state.comparisonPercentageEndingNumber}
            prefix={
              this.state.comparisonPercentageEndingNumber > 0 ||
              this.state.comparisonPercentageStartingNumber > 0
                ? '+'
                : ''
            }
            suffix="%"
            duration={2.5}
            useEasing
            useGrouping
            onComplete={this.transitionCompleted}
          />
          <span className="comparison-counter--comparison-number-description">
            {this.props.comparisonDescription}
          </span>
        </section>
      </article>
    );
  }
}

ComparisonCounter.propTypes = {
  type: PropTypes.string,
  mainNumber: PropTypes.number.isRequired,
  mainDescription: PropTypes.string.isRequired,
  comparisonNumber: PropTypes.number.isRequired,
  comparisonDescription: PropTypes.string.isRequired,
};

ComparisonCounter.defaultTypes = {
  type: 'number',
};

export default ComparisonCounter;
