import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ size, color, className }) => {
  const spinnerClasses = classNames({
    'loading-spinner': true,
    'loading-spinner--small': size === 'small',
    'loading-spinner--medium': size === 'medium',
    'loading-spinner--large': size === 'large',
    'loading-spinner--red': color === 'red',
    'loading-spinner--blue': color === 'blue',
  });
  return <div className={`${spinnerClasses} ${className}`} />;
};

LoadingSpinner.propTypes = {
  color: PropTypes.oneOf(['blue', 'red']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

LoadingSpinner.defaultProps = {
  color: 'red',
  size: 'medium',
};

export { LoadingSpinner };
