import { createSlice } from '@reduxjs/toolkit';

const managedShifts = createSlice({
  name: 'managedShifts',
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    hydrateManagedShifts: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    createManagedShift: (state, action) => {
      state.data.unshift(action.payload);
    },
    requestManagedShifts: (state, action) => {
      state.loading = true;
    },
    updateManagedShifts: (state, action) => {
      for(let i=0; i<action.payload.length; i++) {
        const shiftIndex = state.data.findIndex(
          (shift) => shift.id === action.payload[i].id
        );
        if(shiftIndex <0) {
          state.data = state.data.concat(action.payload[i]);
          //console.log('add ' + action.payload[i].id);
        }
      }
      state.loading = false;
    },
  },
});

export default managedShifts;
