import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';

const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 20px;

  //justify-content: center;
  //align-items: center;

   &:hover {
    //background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    //background-image: url('/icons/check-mark.svg');
    //background-repeat: no-repeat;
    //background-position: 95% 50%;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }

  //border: 1px solid green;
`;

const LocationAvatar = styled.img`
  margin-left: 5px;
  width: 40px;
  height: 40px;
  //border: 1px solid black;
`;

const LocationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 40px;

  //border: 1px solid red;
`;
const LocationName = styled.div`
  @font-face {
  font-family: 'AvertaRegular';
  src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 300;
  color: white;
  background-color: none;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  
  width: 100%;
  //height: 50%;
  min-height: 20px;
  height: 20px;
  position: relative;
  margin-left: 0px;
  
  overflow-y: visible;
  overflow-x:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;

  //border: 1px solid yellow;
  


  &:first-child {
    //margin-top: 20px;
  }


  &:hover {
    //background-color: rgba(214,238,255,255);
    //box-shadow: 0px 0px 6px black;
    overflow:visible;
  }

  &:focus {
    //outline: none;
    //box-shadow: 0px 0px 6px
      //${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

const LocationCodeEmployee = styled.label`
  @font-face {
  font-family: 'AvertaLight';
  src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 300;
  color: rgba(199,199,204,255);
  background-color: none;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  width: 100%;
  //height: 50%;
  min-height: 20px;
  height: 20px;
  position: relative;
  
  overflow-y: visible;
  overflow-x:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;

  //border: 1px solid yellow;
`;

const ArrowButton = styled.img`
  margin-top: 13px;
  margin-left: 8px;
  //margin: auto;
  width: 15px;
  height: 15px;
  //border: 1px solid yellow;
`;


export default {
  LocationContainer,
  LocationAvatar,
  LocationContent,
  LocationName,
  LocationCodeEmployee,
  ArrowButton,
};
