import React from 'react';
import classNames from 'classnames';

import './Hamburger.scss';

class Hamburger extends React.PureComponent {
  state = { open: false };

  hamburgerRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  _handleClickOutside = (e) => {
    if (this.hamburgerRef && !this.hamburgerRef.current.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  _handleClick = (e) => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    const classes = classNames({
      hamburger: true,
      'hamburger--open': this.state.open,
    });

    return (
      <div
        className={classes}
        onClick={this._handleClick}
        ref={this.hamburgerRef}
      >
        <span />
        <span />
        <span />
      </div>
    );
  }
}

export default Hamburger;
