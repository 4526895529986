import React from 'react';
import { FormattedMessage } from 'react-intl';

import './PendingLocation.scss';


const PendingLocation = () => {
  return (
    <div className="pending-location__container">
      <h1>
        <FormattedMessage
          id="PendingLocation.waiting"
          defaultMessage="Hold tight! We're waiting for your manager to approve your request."
        />
      </h1>
      <p>
        <FormattedMessage
          id="PendingLocation.adminNotified"
          defaultMessage="This location requires approval to join. We've notified an admin of your request to join."
        />
      </p>
    </div>
  );
};

export { PendingLocation };
