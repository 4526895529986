import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouteComponent = (props) => {
  const { routeProps } = props;
  const { component: Component } = routeProps;

  return (
    <Route
      exact={routeProps.exact}
      path={routeProps.path}
      component={routeProps.component}
    />
  );
};

export default RouteComponent;

RouteComponent.propTypes = {
  routeProps: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.elementType,
  }).isRequired,
};
