import { connect } from 'react-redux';
import CostCenterComponent from './CostCenterComponent';

const mapStateToProps = ({ calendarReducer, locationReducer, appReducer }) => {
  const { locations, location_data } = locationReducer;
  const {
    loading,
    shifts,
    typeFilters,
    dayFilters,
    stateFilters,
  } = calendarReducer;
  const {tag_groups} = appReducer;

  return {
    location: locations.length > 1 ? location_data : locations[0],
    loading,
    costCenterData: appReducer.location.costCenterData,
  };
};

const CostCenterContainer = connect(mapStateToProps)(CostCenterComponent);

export default CostCenterContainer;