import axios from 'axios';
import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';
import { BASE_URL } from '../../constants';
import { getLocationCode, getLocaleCookie } from '../cookies';


/**
 * Gets all inner department for current location.
 *
 * @param  { String | Int } {location_id}
 */
export const GetLocationInnerDepartments = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/locations/${location_id}/inner_departments`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page: 500,
      total: true,
    },
  });
};

/**
 * Gets departments for current user.
 *
 * @param  { String | Int } {location_id}
 */
export const getMyDepartments = (location_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/departments/mine`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
    },
  });
};

/**
 * creata a department
 * @param  {String} {name
 * @param  {String} loaction_id
 * @param  {String} description}
*/
export const addDepartment = ({name, location_id, description}) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/departments`,
    method: 'POST',
    data: {
      department: {
        name,
        location_id,
        description,
      },
    },
  });

/**
 * add a user to the department
 * @param  {Number} {user privilege id
 * @param  {Number} department id
 * @param  {Bool} is main}
*/
export const addDepartmentMember = (userIds, department_id) =>
  protectedAxiosInstance.put(
    `api/customer/user_role/departments/${department_id}/assign_users?`,
    {
      department: { 
        add_user_ids: userIds,
      },
    },
  );

/**
 * remove a user from the department
 * @param  {Number} {user privilege id
 * @param  {Number} department id
 * @param  {Bool} is main}
*/
export const removeDepartmentMember = (userIds, department_id) =>
  protectedAxiosInstance.put(
    `api/customer/user_role/departments/${department_id}/assign_users?`,
    {
      department: { 
        remove_user_ids: userIds,
      },
    }
  );






