import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';

class OwnerCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { shift } = this.props;

    const ownerImageUrl = shift && shift.owner && shift.owner.profile_image ? 
            shift.owner.profile_image.thumb_url : '/icons/default-profile-thumb.svg';
    const ownerName = `${shift.owner.first_name} ${shift.owner.last_name}`;

    const startDateTime = moment(shift.start_at);
    const endDateTime = moment(shift.end_at);
    const diff = moment.duration(endDateTime.diff(startDateTime));
    const hours = parseInt(diff.asHours());
    

    const textHours = (<FormattedMessage
      id="OwnerCell.approved"
      defaultMessage="{value} hours"
      values={{value: hours}}
    />);

    
    const  bottomLine =     
          <styled.BottomLineWrap>
            <styled.BottomLineText>{shift.primary_job}</styled.BottomLineText>
            <styled.Dot/>
            <styled.BottomLineText>{shift.id}</styled.BottomLineText>
            <styled.Dot/>
            <styled.BottomLineText>{textHours}</styled.BottomLineText>
          </styled.BottomLineWrap>
  	return (
  		<styled.TopWrap>
        <styled.OwnerAvatar src={ownerImageUrl}/>
        <styled.OwnerInfoWrap>
          <styled.OwnerInfoTopLine>{ownerName}</styled.OwnerInfoTopLine>
          {/*<styled.OwnerInfoBottomLine>{importedDate}</styled.OwnerInfoBottomLine>*/}
          {bottomLine}
        </styled.OwnerInfoWrap>
      </styled.TopWrap>
  	);
  }
}


OwnerCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(OwnerCell);