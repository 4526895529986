import React from 'react';
import moment from 'moment';
import ComparisonCounter from '../../GeneralComponents/ComparisonCounter/ComparisonCounter';
import { formatDateRange } from 'helpers/date-time';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import './GeneralDataComponent.scss';

class GeneralDataComponenti18n extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kpis: {
        shiftsPosted: {
          thisWeek: 0,
          lastWeek: 0,
        },
        shiftsCovered: {
          thisWeek: 0,
          lastWeek: 0,
        },
        shiftsApprovalRate: {
          thisWeek: 0,
          lastWeek: 0,
        },
        totalEmployees: {
          thisWeek: 0,
          lastWeek: 0,
        },
      },
    };

    this.messages = defineMessages({
      shiftsPostedMain: {
        id: 'GeneralDataComponent.shiftsPostedMain',
        defaultMessage: 'Shifts Posted',
      },
      shiftsCoveredMain: {
        id: 'GeneralDataComponent.shiftsCoveredMain',
        defaultMessage: 'Shifts Covered',
      },
      shiftsApprovalMain: {
        id: 'GeneralDataComponent.shiftsApprovalMain',
        defaultMessage: 'Shift Approval Rate',
      },
      shiftsEmployeesMain: {
        id: 'GeneralDataComponent.shiftsEmployeesMain',
        defaultMessage: 'Employees on Shyft',
      },
      shiftsComparison: {
        id: 'GeneralDataComponent.shiftsComparison',
        defaultMessage: 'compared to the previous 7 days',
      },
    });
  }

  componentDidMount() {
    if (this.props.kpis !== null && this.props.kpis !== undefined) {
      this.setKpis(this.props.kpis);
    }
  }

  UNSAFE_componentWillReceiveProps({ kpis }) {
    if (kpis !== null && kpis !== undefined) {
      this.setKpis(kpis);
    }
  }

  setKpis = kpis => {
    this.setState({
      kpis: {
        shiftsPosted: kpis.shiftsPosted,
        shiftsCovered: kpis.shiftsCovered,
        shiftsApprovalRate: kpis.shiftsApproved,
        totalEmployees: {
          thisWeek: kpis.totalEmployees.thisWeek,
          lastWeek: kpis.totalEmployees.lastWeek,
        },
      },
    });
  };

  calculateApprovalRate = (shiftsCovered, shiftsApproved) => {
    if (shiftsCovered === 0 || shiftsApproved === 0) {
      return 0;
    }
    return +((shiftsApproved / shiftsCovered) * 100).toFixed(1);
  };

  render() {
    const { intl } = this.props;
    const dateRange = formatDateRange(
      moment()
        .endOf('day')
        .subtract(8, 'days'),
      moment()
        .endOf('day')
        .subtract(1, 'days')
    );
    const LoadingLayer = ({ loading }) => {
      if (loading) {
        return (
          <section className="general-data--loader">
            <img src="/loader.gif" height="125" width="125" />
          </section>
        );
      } else {
        return null;
      }
    };

    return (
      <article className="general-data--container">
        <LoadingLayer loading={this.props.loading} />
        <h3
          className="general-data--header"
          style={{
            width: '30%',
            margin: 'auto',
          }}
        >
          {dateRange}
        </h3>
        <a
          className="information-link"
          href="https://shyft.zendesk.com/hc/en-us/articles/360001157807-Manager-Dashboard-Homepage"
          target="_blank"
          rel="noreferrer noopener"
          style={{
            fontSize: '12px',
            float: 'right',
            marginTop: '-25px',
            color: '#90959a',
          }}
        >
          <FormattedMessage
            id="GeneralDataComponent.statsCalculated"
            defaultMessage="Learn how these stats are calculated"
          />
          <svg
            aria-hidden="true"
            data-prefix="fas"
            data-icon="external-link-alt"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="svg-inline--fa fa-external-link-alt fa-w-18 fa-2x"
            style={{
              height: '12px',
              marginLeft: '5px',
              marginRight: '10px',
              marginTop: '-3px',
            }}
          >
            <path
              fill="currentColor"
              d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"
            ></path>
          </svg>
        </a>
        <ComparisonCounter
          mainNumber={this.state.kpis.shiftsPosted.thisWeek}
          mainDescription={intl.formatMessage(this.messages.shiftsPostedMain)}
          comparisonNumber={this.state.kpis.shiftsPosted.lastWeek}
          comparisonDescription={intl.formatMessage(
            this.messages.shiftsComparison
          )}
        />
        <ComparisonCounter
          mainNumber={this.state.kpis.shiftsCovered.thisWeek}
          mainDescription={intl.formatMessage(this.messages.shiftsCoveredMain)}
          comparisonNumber={this.state.kpis.shiftsCovered.lastWeek}
          comparisonDescription={intl.formatMessage(
            this.messages.shiftsComparison
          )}
        />
        <ComparisonCounter
          type="percentage"
          mainNumber={this.state.kpis.shiftsApprovalRate.thisWeek}
          mainDescription={intl.formatMessage(this.messages.shiftsApprovalMain)}
          comparisonNumber={this.state.kpis.shiftsApprovalRate.lastWeek}
          comparisonDescription={intl.formatMessage(
            this.messages.shiftsComparison
          )}
        />
        <ComparisonCounter
          mainNumber={this.state.kpis.totalEmployees.thisWeek}
          mainDescription={intl.formatMessage(
            this.messages.shiftsEmployeesMain
          )}
          hideComparison={true}
          comparisonNumber={this.state.kpis.totalEmployees.lastWeek}
          comparisonDescription={intl.formatMessage(
            this.messages.shiftsComparison
          )}
        />
      </article>
    );
  }
}

const GeneralDataComponent = injectIntl(GeneralDataComponenti18n);
export { GeneralDataComponent, GeneralDataComponenti18n };
