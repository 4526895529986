import styled from 'styled-components/macro';

const Heading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  flex-grow: 1;
`;

export default Heading;
