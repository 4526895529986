import { combineReducers } from 'redux';
import { default as slices } from './slices';

export const searchReducer = slices.search.reducer;
export const filterReducer = slices.filter.reducer;
export const visibleMembers = slices.visibleMembers.reducer;
export const bottomSheet = slices.bottomSheet.reducer;

const reducer = combineReducers({
  search: searchReducer,
  filter: filterReducer,
  visibleMembers,
  bottomSheet,
});

export default reducer;
