import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import { default as Styled } from './styled';
import { default as TeamStyled } from '../styled';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'TeamSearchComponent.searchPlaceholder',
    defaultMessage: 'Search members',
  },
  search: {
    id: 'TeamSearchComponent.search',
    defaultMessage: 'Search',
  },
});

const TeamSearchComponent = ({
  locationId,
  searchTerm,
  searchReturned,
  updateSearchTerm,
  submitSearch,
  intl,
}) => {
  return (
    <Styled.TeamSearchForm
      onSubmit={(e) => {
        e.preventDefault();
        if (searchTerm) {
          submitSearch(locationId, searchTerm);
        }
      }}
    >
      <Styled.TeamSearchInput
        onChange={(e) => {
          updateSearchTerm(e.target.value, searchReturned, locationId);
        }}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        type="text"
        value={searchTerm}
      />
      <TeamStyled.TeamButton type="submit">
        {intl.formatMessage(messages.search)}
      </TeamStyled.TeamButton>
    </Styled.TeamSearchForm>
  );
};

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
TeamSearchComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchReturned: PropTypes.bool.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TeamSearchComponent);
