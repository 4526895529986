import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './Pinput.scss';

class Pinput extends React.Component {
  constructor() {
    super();

    this.inputs = [];
  }

  state = {
    index: 0,
    stage: 0,
    value: '',
  };

  componentDidMount() {
    this.inputs[0].focus();
  }

  _handleInput = (e) => {
    const target = e.target;
    const nextIndex = this._findCurrentIndex(e.target, this.inputs);
    const newValue = this.inputs.reduce(
      (acc, element, index) => acc + element.value,
      ''
    );

    if (this.inputs[nextIndex] && target.value) {
      this.inputs[nextIndex].focus();
    }

    this.setState({
      index: nextIndex,
      value: newValue,
    });
    this.props.inputChange(newValue);
  };

  _findCurrentIndex = (target, inputs) => {
    let index = 0;

    inputs.forEach((element, arrayIndex) => {
      if (element === target) {
        index = arrayIndex;
      }
    });

    return index + 1 === inputs.length ? index : index + 1;
  };

  render() {
    return (
      <section className="mobile-pin-input">
        <h3 className="mobile-pin-input__header">
          <FormattedMessage
            id="Pinput.enterPin"
            defaultMessage="Please enter your PIN"
          />
        </h3>
        <input
          maxLength="1"
          type="tel"
          className="mobile-pin-input__field"
          onChange={this._handleInput}
          ref={(input) => {
            this.inputs[0] = input;
          }}
        />

        <input
          maxLength="1"
          type="tel"
          className="mobile-pin-input__field"
          onChange={this._handleInput}
          ref={(input) => {
            this.inputs[1] = input;
          }}
        />

        <input
          maxLength="1"
          type="tel"
          className="mobile-pin-input__field"
          onChange={this._handleInput}
          ref={(input) => {
            this.inputs[2] = input;
          }}
        />

        <input
          maxLength="1"
          type="tel"
          className="mobile-pin-input__field"
          onChange={this._handleInput}
          ref={(input) => {
            this.inputs[3] = input;
          }}
        />
      </section>
    );
  }
}

export default Pinput;

Pinput.propTypes = {
  inputChange: PropTypes.func.isRequired,
};
