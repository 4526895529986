import React from 'react';
import PropTypes from 'prop-types';

import { appShapes } from 'App/duck';
import styled from './styled';
import blocksStyled from '../styled';
import Icon from './Icon';

const Channel = ({ active, data, handleClick }) => {
  const { channel_latest_content, channel_name, channel_type } = data;

  return (
    <blocksStyled.MessageWrap
      modifiers={active ? 'active' : null}
      onClick={() => {
        handleClick(data.id);
      }}
    >
      <Icon modifiers={channel_type} />
      <blocksStyled.Info>
        <styled.Name>{channel_name}</styled.Name>
        <styled.LatestContent>{channel_latest_content}</styled.LatestContent>
      </blocksStyled.Info>
    </blocksStyled.MessageWrap>
  );
};

Channel.propTypes = {
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  data: appShapes.channelsDataShape.isRequired,
};

export default Channel;
