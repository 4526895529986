import { defineMessages } from 'react-intl';

const messages = defineMessages({
  error1: {
    id: 'ChangePasswordPage.error1',
    defaultMessage:
      'Please enter a valid password. Your password must contain 1 uppercase, 1 lowercase, 1 special character and must be at least 8 characters long.',
  },
  error2: {
    id: 'ChangePasswordPage.error2',
    defaultMessage: 'Your passwords must match.',
  },
  error3: {
    id: 'ChangePasswordPage.error3',
    defaultMessage:
      'Wrong password reset URL! Please confirm the URL from the email or go back to the password reset page.',
  },
  error4: {
    id: 'ChangePasswordPage.error4',
    defaultMessage: 'Oops! There was an error on our side. Please try again.',
  },
  passwordPlaceholder: {
    id: 'ChangePasswordPage.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  confirmPasswordPlaceholder: {
    id: 'ChangePasswordPage.confirmPasswordPlaceholder',
    defaultMessage: 'Confirm Password',
  },
});

export default messages;
