import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '@shyft/acorns';


import addButton from '../assets/add-button.svg';
import addButtonGrayedOut from '../assets/plus-icon.svg';

const primaryColor = '#3EAEFF';
const darkTextColor = '#64676A';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;

const PlusSignContainer = styled.span`
  height: 32px;
  width: 32px;
  display: flex;
  align-content: center;
  border-radius: 50%;
  background-color: ${primaryColor};
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

const PlusSignContainerGrayedOut = styled.span`
  height: 32px;
  width: 32px;
  display: flex;
  align-content: center;
  border-radius: 50%;
  background-color: #ccc;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;




/*
const AddRowButton = styled.button.attrs((props) => ({
  onClick: props.onClick,
  type: 'button',
}))`
  color: ${darkTextColor};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

`;
*/


const AddRowButton = styled(Button).attrs(({ disabled, props }) => ({
  disabled,
}))`
  
  color: ${darkTextColor};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
    background: #ccc;
    border-color: #ccc;
  }

`;

const AddShiftRow = ({ onClick, previewing, disabled }) => {
  if (previewing) return null;
  
  return (
    <RowContainer>
      <AddRowButton onClick={onClick} disabled={disabled}>

         {disabled && <PlusSignContainerGrayedOut>
          <img src={addButtonGrayedOut} height="15" width="15" alt="Plus Sign" />
        </PlusSignContainerGrayedOut>}

        {! disabled && <PlusSignContainer>
          <img src={addButton} height="15" width="15" alt="Plus Sign" />
        </PlusSignContainer>}

        <FormattedMessage
          id="AddShiftRow.addAnotherRow"
          defaultMessage="Add Another Row"
        />
      </AddRowButton>
    </RowContainer>
  );
};

export default AddShiftRow;
