import React from 'react';
import PropTypes from 'prop-types';

import { teamShapes } from '../../duck';

import styles from './styled';
import BottomSheet from '../BottomSheet';
import ActionButtons from './components/ActionButtons';
import BodyText from './components/BodyText';

const TeamConfirmationComponent = ({
  visible,
  selectedAction,
  selectedUsers,
  actionHandlers,
  locationId,
  filters,
  receiveSelectedUsers,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <BottomSheet visible={visible}>
      <styles.Wrapper>
        <BodyText
          selectedAction={selectedAction}
          selectedUsers={selectedUsers}
          filters={filters}
        />
        <ActionButtons
          actionHandlers={actionHandlers}
          selectedAction={selectedAction}
          selectedUsers={selectedUsers}
          locationId={locationId}
          receiveSelectedUsers={receiveSelectedUsers}
        />
      </styles.Wrapper>
    </BottomSheet>
  );
};

TeamConfirmationComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedAction: teamShapes.selectedActionTypes,
  selectedUsers: PropTypes.arrayOf(teamShapes.userShape),
  actionHandlers: teamShapes.actionHandlersShape,
  locationId: PropTypes.number.isRequired,
  filters: teamShapes.filtersShape,
};

export default TeamConfirmationComponent;
