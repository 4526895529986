import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import messages from './SignUpPage.messages';
import { validateEmail, validatePasswordStrength } from 'helpers/validation';
import {
  signUpWithEmail,
  updateUserAttributes,
  signInAttempt,
  SignInConfirm,
  AddAuthHeaders,
} from 'helpers/api-calls';
import { apiCallError } from 'helpers/errors';
import LoginSecondStage from 'pages/Login/LoginSecondStage/LoginSecondStage';
import { setAuthenticationCookie, setUserCookie } from 'helpers/cookies';

import { ExistingAccountsComponent } from './components/ExistingAccountsComponent';
import { SignupComponent } from './components/SignupComponent';

import './SignupPage.scss';
import 'pages/Login/LoginPage.scss';

class SignupPageNoI18n extends React.Component {
  formField = {
    value: '',
    validity: false,
    showValidity: false,
  };

  first_name = null;

  last_name = null;

  email = null;

  password = null;

  passwordConfirmation = null;

  state = {
    first_name: Object.create(this.formField),
    last_name: Object.create(this.formField),
    email: Object.create(this.formField),
    password: Object.create(this.formField),
    passwordConfirmation: Object.create(this.formField),
    path: null,
    accountCreated: false,
    accountCreatedErrors: {
      value: '',
      showValidity: false,
    },
    honeyPotValidity: true,
    existingUsers: [],
    currentExistingUser: null,
  };

  _setFirstNameRef = (element) => {
    this.first_name = element;
  };

  _setLastNameRef = (element) => {
    this.last_name = element;
  };

  _setEmailRef = (element) => {
    this.email = element;
  };

  _setPasswordRef = (element) => {
    this.password = element;
  };

  _setPasswordConfirmationRef = (element) => {
    this.passwordConfirmation = element;
  };

  back = () => {
    this.setState({
      currentExistingUser: null,
    });
  };

  _signup = () => {
    const { intl, locale } = this.props;
    const {
      first_name,
      last_name,
      email,
      password,
      passwordConfirmation,
      accountCreatedErrors,
    } = this.state;
    signUpWithEmail({
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      password: password.value,
      password_confirmation: passwordConfirmation.value,
      locale,
    }).then(
      (data) => {
        if (data.statusText === 'Created') {
          this.setState({
            accountCreated: true,
          });
        } else {
          this.setState({
            accountCreatedErrors: Object.assign(accountCreatedErrors, {
              value: data.statusText,
              showValidity: true,
            }),
          });
        }
      },
      (error) => {
        let errorText;
        if (error.response.status === 422) {
          if (error.response.data.errors && error.response.data.errors.email) {
            errorText = intl.formatMessage(messages.emailTakenError);
          }
        }

        this.setState({
          accountCreatedErrors: Object.assign(accountCreatedErrors, {
            value: errorText || error.response.statusText,
            showValidity: true,
          }),
        });
      }
    );
  };

  _confirmAccount = () => {
    this._signup();
  };

  _updateEmail = (phone) => {
    signInAttempt(phone).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            currentExistingUser: phone,
          });
        }
      },
      (error) => {
        apiCallError(error);
      }
    );
  };

  _filterValidExistingUsers = (users) =>
    users.filter(
      (element) => !!element.phone_number && element.phone_number.length >= 10
    );

  _setExistingUserAccounts = (users) => {
    this.setState({
      existingUsers: this._filterValidExistingUsers(users),
    });
  };

  _submitForm = (e) => {
    e.preventDefault();

    if (this._validateForm()) {
      // api call

      this._signup();
    }


    return false;
  };

  _validateForm = () => {
    const {
      first_name,
      last_name,
      email,
      password,
      passwordConfirmation,
      accountCreatedErrors,
      honeyPotValidity,
    } = this.state;

    this.setState({
      first_name: Object.assign(first_name, {
        showValidity: !first_name.validity,
      }),
      last_name: Object.assign(last_name, {
        showValidity: !last_name.validity,
      }),
      email: Object.assign(email, {
        showValidity: !email.validity,
      }),
      password: Object.assign(password, {
        showValidity: !password.validity,
      }),
      passwordConfirmation: Object.assign(passwordConfirmation, {
        showValidity: !passwordConfirmation.validity,
      }),
      accountCreatedErrors: Object.assign(accountCreatedErrors, {
        showValidity: false,
      }),
    });
    return (
      honeyPotValidity &&
      first_name.validity &&
      last_name.validity &&
      email.validity &&
      password.validity &&
      passwordConfirmation.validity
    );
  };

  _nameChange = (e) => {
    const { name, value } = e.target;

    this.setState((state) => ({
      [name]: {
        ...state[name],
        value,
        validity: value.length > 0,
        showValidity: false,
      },
    }));
  };

  _emailChange = (e) => {
    const { value } = e.target;

    const trimed = value.trim();
    //const msg = `Trimed email: ${trimed}`;
    //console.log(msg);
    /*
    this.setState((state) => ({
      email: {
        ...state.email,
        value,
        validity: validateEmail(value),
        showValidity: false,
      },
    }));
    */
    this.setState((state) => ({
      email: {
        ...state.email,
        value: trimed,
        validity: validateEmail(trimed),
        showValidity: false,
      },
    }));
  };

  _passwordChange = (e) => {
    const { value } = e.target;
    const validity = validatePasswordStrength(value);

    this.setState((state) => ({
      password: {
        ...state.password,
        value,
        validity,
        showValidity: false,
      },
    }));
  };

  _passwordConfirmationChange = (e) => {
    const { value } = e.target;

    this.setState((state) => ({
      passwordConfirmation: {
        ...state.passwordConfirmation,
        value,
        validity: state.password.value === value,
        showValidity: false,
      },
    }));
  };

  _checkUserForLocations = (location) => {
    if (location && location.is_approved && location.location) {
      return location.location.id;
    }
    return false;
  };

  _handleHoneyPotChange = (validity) => {
    this.setState({
      honeyPotValidity: validity,
    });
  };

  _pinSubmit = (pin) => {
    const { intl } = this.props;
    const { currentExistingUser, email, password } = this.state;


    SignInConfirm(currentExistingUser, pin).then(
      (response) => {
        const headers = Object.assign(response.headers, {
          'Auth-Category': 'phone_number',
          'Auth-Target': currentExistingUser,
          location_id: this._checkUserForLocations(
            response.data.user.recent_user_privilege
          ),
        });

        setAuthenticationCookie(headers);
        AddAuthHeaders();
        setUserCookie(response.data.user);

        updateUserAttributes({
          email: email.value,
          password: password.value,
          user_id: response.data.user.id,
        }).then(() => {
          if (
            this._checkUserForLocations(
              response.data.user.recent_user_privilege
            )
          ) {
            this.setState({
              path: {
                pathname: '/',
              },
            });
          } else {
            this.setState({
              path: {
                pathname: '/add-location',
              },
            });
          }
        });
      },
      ({ response }) => {
        if (response.status === 401 || response.status === 403) {
          this.setState({
            mobilePinError: intl.formatMessage(messages.emailTakenError),
          });
        } else {
          this.setState({
            mobilePinError: intl.formatMessage(messages.wrongPinError2),
          });
        }
      }
    );
  };

  _routeToLogin = (e) => {
    e.preventDefault();
    this.setState({
      path: {
        pathname: '/login',
      },
    });
  };

  _cancelAccountMerge = (e) => {
    e.preventDefault();

    this.setState({
      currentExistingUser: null,
    });
  };

  render() {
    const {
      accountCreatedErrors,
      path,
      existingUsers,
      currentExistingUser,
      mobilePinError,
      accountCreated,
      email,
      password,
      first_name,
      last_name,
      passwordConfirmation,
    } = this.state;
    const accountCreationErrorText = accountCreatedErrors.showValidity
      ? accountCreatedErrors.value
      : '';

    if (path) {
      return <Redirect to={path} />;
    }
    if (accountCreated) {
      return (
        <React.Fragment>
          <article className="login">
            <div className="login__wrap">
              <h2 className="login__header">
                <FormattedMessage
                  id="SignupPage.successHeader"
                  defaultMessage="Success!"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="SignupPage.confirmationEmail"
                  defaultMessage="We've sent a confirmation email. Please follow the steps in the email to confirm your account."
                />
              </p>
            </div>
          </article>
        </React.Fragment>
      );
    }
    if (existingUsers.length > 0) {
      if (currentExistingUser) {
        return (
          <React.Fragment>
            <LoginSecondStage
              // back={this.back}
              type="phone"
              _pinSubmit={this._pinSubmit}
              mobilePinError={mobilePinError}
              phone={currentExistingUser}
              back={this._cancelAccountMerge}
              _backButtonText="Wrong account?"
            />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <ExistingAccountsComponent
            users={existingUsers}
            _signup={this._signup}
            _confirmAccount={this._confirmAccount}
            _updateEmail={this._updateEmail}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <SignupComponent
          _routeToLogin={this._routeToLogin}
          _emailChange={this._emailChange}
          _submitForm={this._submitForm}
          _nameChange={this._nameChange}
          _passwordChange={this._passwordChange}
          _passwordConfirmationChange={this._passwordConfirmationChange}
          _handleHoneyPotChange={this._handleHoneyPotChange}
          email={email}
          password={password}
          first_name={first_name}
          last_name={last_name}
          passwordConfirmation={passwordConfirmation}
          accountCreationErrorText={accountCreationErrorText}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ localeReducer }) => {
  return {
    locale: localeReducer.locale,
  };
};

const SignupPage = connect(mapStateToProps, null)(injectIntl(SignupPageNoI18n));

export { SignupPage, SignupPageNoI18n };
