import styled from 'styled-components/macro';

import { Link } from 'react-router-dom';
import { Button } from '@shyft/acorns';

const HeaderWrap = styled.div`
  padding: 1.5rem 3rem;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const BackSection = styled.div`
  display: flex;
`;

const BackLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text};

  :hover {
    color: ${({ theme }) => theme.palette.text};
  }
`;

const BackArrow = styled.span`
  display: inline-block;
  width: 8px;
  height: 12px;
  background-image: url(${process.env.PUBLIC_URL}/icons/back-arrow.svg);
  background-size: cover;
  position: relative;
  margin-right: 0.5rem;
`;

export const EditButton = styled(Button)``;

export default {
  EditButton,
  HeaderWrap,
  HeaderRow,
  BackLink,
  BackSection,
  BackArrow,
};
