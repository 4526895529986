import { connect } from 'react-redux';

//import PinsComponent from './pins-component';
import PinsComponent from './PinsComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';

const mapStateToProps = (state) => {
  const { teamFeedReducer } = state;

  return {
    pins: teamFeedSelectors.getPinnedPosts(teamFeedReducer),
    activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
  };
};
const mapDispatchToProps = (dispatch) => {
  //return {};
  const resetActiveSidePane = () => {
  	dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  return {
  	resetActiveSidePane,
  }
  
};

export default connect(mapStateToProps, mapDispatchToProps)(PinsComponent);
