import styled from 'styled-components/macro';
import blocks from '../../blocks';
import { rgba } from 'polished';
import submitIcon from '../../blocks/ActionButtons/assets/send.svg';



const CloseButton = styled.button`
 height: 20px;
 width: 70px;
 margin-left: 100px;
 margin-top: 0px;
 margin-bottom: 0px;
 //border 1px solid red;
`;

export default {
  CloseButton,
};
