const statusTypes = {
  APPROVED: 'approved',
  PENDING: 'pending',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  REMOVED: 'removed',
  WITHDRAWN: 'withdrawn',
  BLOCKED: 'blocked',
  POSTED: 'posted',
};

const userActionTypes = {
  APPROVE: 'APPROVE',
  REMOVE: 'REMOVE',
  PROMOTE: 'PROMOTE',
  WITHDRAW: 'WITHDRAW',
  BLOCK: 'BLOCK',
  DENY: 'DENY',
  SAVE_USER: 'SAVE_USER',
};

const bulkActionTypes = {
  ACTIVE_BULK: 'ACTIVE_BULK',
  PENDING_BULK: 'PENDING_BULK',
  SELECT_ACTIVE_BULK: 'SELECT_ACTIVE_BULK',
};

export default {
  statusTypes,
  userActionTypes,
  bulkActionTypes,
};
