import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  background-color: #efefef;
  padding: 1.15rem 1.5rem;
  flex-wrap: wrap;

  &:nth-child(even) {
    background-color: white;
  }
`;

export default Row;
