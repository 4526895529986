import { createSlice } from '@reduxjs/toolkit';

import { cleanUsersArr } from 'helpers/formatting';

const users = createSlice({
  name: 'users',
  initialState: {
    users: [],
    loading: true,
    loaded: false,
    page: 1,
    totalPages: 1,
    selectedUsers: [],
  },
  reducers: {
    requestMembers: (state, action) => {
      if (!state.loaded) {
        state.loading = true;
        state.page = action.payload;
      }
    },
    receiveMembers: (state, action) => {
      state.users = cleanUsersArr(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    updateMember: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload.id
      );
      state.users[memberIndex] = action.payload;
    },
    removeMember: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload
      );
      state.users.splice(memberIndex, 1);
    },
    selectMember: (state, action) => {
      state.selectedUsers.push(action.payload);
    },
    deselectMember: (state, action) => {
      const filteredArr = state.selectedUsers.filter(
        (userId) => userId !== action.payload
      );

      state.selectedUsers = filteredArr;
    },
  },
});

export default { users };
