import { createSlice } from '@reduxjs/toolkit';

import { cleanUsersArr } from 'helpers/formatting';
import teamConstants from './constants';

const { statusTypes } = teamConstants;

const search = createSlice({
  name: 'search',
  initialState: {
    searchTerm: '',
    searchReturned: false,
    searching: false,
  },
  reducers: {
    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    updateSearchReturned: (state, action) => {
      state.searchReturned = action.payload;
    },
    updateSearching: (state, action) => {
      state.searching = action.payload;
    },
  },
});

const filter = createSlice({
  name: 'filter',
  initialState: {
    activeFilter: statusTypes.ACTIVE,
    filters: {
      [statusTypes.ACTIVE]: {
        name: 'Active',
        count: 0,
      },
      [statusTypes.PENDING]: {
        name: 'Pending',
        count: 0,
      },
      [statusTypes.REMOVED]: {
        name: 'Removed',
        count: 0,
      },
    },
  },
  reducers: {
    updateActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    incrementCount: (state, action) => {
      state.filters[action.payload.filter].count += action.payload.amount;
    },
    decrementCount: (state, action) => {
      state.filters[action.payload.filter].count -= action.payload.amount;
    },
    recieveMetadata: (state, action) => {
      state.filters.active = {
        ...state.filters.active,
        count: action.payload.active.count,
      };
      state.filters.pending = {
        ...state.filters.pending,
        count: action.payload.pending.count,
      };
      state.filters.removed = {
        ...state.filters.removed,
        count: action.payload.removed.count,
      };
    },
  },
});

const visibleMembers = createSlice({
  name: 'visibleMembers',
  initialState: {
    users: [],
    loading: true,
    page: 1,
    totalPages: 1,
    selectedUsers: [],
  },
  reducers: {
    requestMembers: (state, action) => {
      state.loading = true;
      state.page = action.payload;
    },
    recieveMembers: (state, action) => {
      state.users = cleanUsersArr(action.payload.users);
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendMembers: (state, action) => {
      state.users = state.users.concat(cleanUsersArr(action.payload.users));
      state.page = action.payload.page;
      state.loading = false;
    },
    updateMember: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload.id
      );
      state.users[memberIndex] = action.payload;
    },
    removeMember: (state, action) => {
      const memberIndex = state.users.findIndex(
        (member) => member.id === action.payload
      );
      state.users.splice(memberIndex, 1);
    },
    selectMember: (state, action) => {
      const { payload } = action;

      state.selectedUsers.push(payload);
    },
    deselectMember: (state, action) => {
      const filteredArr = state.selectedUsers.filter(
        (userId) => userId !== action.payload
      );

      state.selectedUsers = filteredArr;
    },
    selectAllMembers: (state, action) => {
      state.selectedUsers = state.users.map((user) => user.id);
    },
    deselectAllMembers: (state, action) => {
      state.selectedUsers = [];
    },
    removeMembers: (state, action) => {
      action.payload.forEach((item) => {
        const memberIndex = state.users.findIndex(
          (member) => member.id === item
        );
        state.users.splice(memberIndex, 1);
      });
    },
  },
});

const bottomSheet = createSlice({
  name: 'bottomSheet',
  initialState: {
    visible: false,
    selectedAction: '',
    cancelled: false,
    lastUpdatedUser: '',
    lastAction: '',
    lastActionIds: [],
    lastFailedIds: [],
    clicked: false,
  },
  reducers: {
    updateVisibility: (state, action) => {
      state.visible = action.payload;
      if(action.payload) {
        state.cancelled = false;
      }
    },
    updateSelectedAction: (state, action) => {
      state.selectedAction = action.payload;
    },
    updateCancelled: (state, action) => {
      state.cancelled = action.payload;
    },
    updateLastUser: (state, action) => {
      state.lastUpdatedUser = action.payload;
    },
    updateLastAction: (state, action) => {
      state.lastAction = action.payload.name;
      state.lastActionIds = action.payload.ids;
    },
    updateLastError: (state, action) => {
      state.lastAction = action.payload.name;
      state.lastFailedIds = action.payload.ids;
    },
    updateButtonState: (state, action) => {
      state.clicked = action.payload;
    },
  },
});

export default {
  search,
  filter,
  visibleMembers,
  bottomSheet,
};
