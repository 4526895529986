import styled from 'styled-components/macro';

import { Button } from '@shyft/acorns';

const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-left: 1.25rem;
`;

export default ActionBtn;
