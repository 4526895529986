import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';



const FileName = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
  //border: 1px solid red;
`;

const ScheduleId = styled.p `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 11px;
  font-weight: 400;
  color: rgba(9,9,17,255);
  margin-bottom: 5px;
  //border: 1px solid red;
`;


export default {
  FileName,
  ScheduleId,
};
