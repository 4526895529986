const statusTypes = {
  PENDING: 'pending',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  REMOVED: 'removed',
};

const userActionTypes = {
  APPROVE: 'APPROVE',
  REMOVE: 'REMOVE',
  PROMOTE: 'PROMOTE',
  WITHDRAW: 'WITHDRAW',
  BLOCK: 'BLOCK',
  DENY: 'DENY',
  SAVE_USER: 'SAVE_USER',
};

export default {
  statusTypes,
  userActionTypes,
};
