import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { ProfileThumb } from '@shyft/acorns';

import { createFullName } from 'helpers/formatting';

import blocks from '../../blocks';
import elements from '../../elements';

import readCheckbox from './assets/read-checkbox.svg';
import unreadCheckbox from './assets/unread-checkbox.svg';


const useStyles = makeStyles({
  root: {},
  userWrap: {
    position: 'relative',
    display: 'flex',
    padding: '1rem',
    backgroundColor: 'white',

    '&:hover': {
      backgroundColor: '',
    },
  },
  listSeparator: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#4d4d4d',
    padding: '1rem 1rem 0 1rem',
  },
  avatarWrap: {
    width: '44px',
    marginRight: '1rem',
    flex: '0 0 44px',
  },

  detailsWrap: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  detailsPosition: {
    color: '#979797',
    fontSize: '14px',
  },

  closeButton: {
    height: '20px',
    width: '70px',
    marginLeft: '120px',
    marginTop: '0px',
    marginBottom: '0px',
  },
});

const UserDetails = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailsWrap}>
      <div>{createFullName(user)}</div>
      <div className={classes.detailsPosition}>{user.position || ''}</div>
    </div>
  );
};

const Avatar = ({ profileImage }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatarWrap}>
      <ProfileThumb thumbUrl={profileImage} width="44px" />
    </div>
  );
};

const StatusIndicator = ({ read }) => (
  <img src={read ? readCheckbox : unreadCheckbox} />
);

const UserLine = ({ user, readReceipt }) => {
  const classes = useStyles();

  return (
    <div className={classes.userWrap}>
      <Avatar profileImage={user.profile_image_thumb_url} />
      <UserDetails user={user} />
      <StatusIndicator read={readReceipt} />
    </div>
  );
};


const ReadReceiptsComponent = ({ receipts, activeSidePane, resetActiveSidePane }) => {
  const classes = useStyles();
  var requestClose = false;

  if(requestClose){
    requestClose = false;
    return null;
  }

  return (
    <elements.ColumnWrap>
      <blocks.Header>
        <FormattedMessage
          id="ReadReceipts.headerLabel"
          defaultMessage="Team Members"
        />
          <button className={classes.closeButton}
                  onClick={() => {
                    requestClose = true;
                    resetActiveSidePane();
            }}>
             <FormattedMessage id="ReadReceipts.close" defaultMessage="X Close"/>
          </button>
      </blocks.Header>
      <elements.ScrollWrap isLoading={false}>
        {!receipts.loading || <elements.Loading />}
        <div className={classes.listSeparator}>
          <div className={classes.listSeparator}>
            <FormattedMessage id="ReadReceipts.readLabel" defaultMessage="Read" />{' '}
            ({receipts.read.length})
          </div>
          {receipts.read.map((receipt) => (
            <UserLine user={receipt} readReceipt={true} />
          ))}
          
          <FormattedMessage
            id="ReadReceipts.unreadLabel"
            defaultMessage="Unread"
          />{' '}
          ({receipts.unread.length})
        </div>
        {receipts.unread.map((receipt) => (
          <UserLine user={receipt} />
        ))}

        {/*<div className={classes.listSeparator}>
          <FormattedMessage id="ReadReceipts.readLabel" defaultMessage="Read" />{' '}
          ({receipts.read.length})
        </div>*/}
        {/*receipts.read.map((receipt) => (
          <UserLine user={receipt} readReceipt={true} />
        ))*/}
      </elements.ScrollWrap>
    </elements.ColumnWrap>
  );
};

const handleClose = () => {
  
};

export default ReadReceiptsComponent;



/*
class ReadReceiptsComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={closePinPane: false,

    }
    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    //this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    const {receipts, activeSidePane,} = this.props;
    //const classes = useStyles();

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }

    return (
    <elements.ColumnWrap>
      <blocks.Header>
        <FormattedMessage
          id="ReadReceipts.headerLabel"
          defaultMessage="Team Members"
        />
        <styled.CloseButton
            onClick={this.handleClose}>
            <FormattedMessage id="ReadReceipts.close" defaultMessage="X Close"/>
          </styled.CloseButton>
      </blocks.Header>
      <elements.ScrollWrap isLoading={false}>
        {!receipts.loading || <elements.Loading />}
        <div className={classes.listSeparator}>
          <FormattedMessage
            id="ReadReceipts.unreadLabel"
            defaultMessage="Unread"
          />{' '}
          ({receipts.unread.length})
        </div>
        {receipts.unread.map((receipt) => (
          <UserLine user={receipt} />
        ))}

        <div className={classes.listSeparator}>
          <FormattedMessage id="ReadReceipts.readLabel" defaultMessage="Read" />{' '}
          ({receipts.read.length})
        </div>
        {receipts.read.map((receipt) => (
          <UserLine user={receipt} readReceipt={true} />
        ))}
      </elements.ScrollWrap>
    </elements.ColumnWrap>
    );
  }
}

ReadReceiptsComponent.propTypes = {
  //pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(ReadReceiptsComponent);
*/
