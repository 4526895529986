import { createSelector } from '@reduxjs/toolkit';

const managersSelector = (state) => state.managers.users;
const usersSelector = (state) => state.associates.users;
const currentUserIdSelector = (state) => state.currentUser.id;

const currentUser = createSelector(
  managersSelector,
  usersSelector,
  currentUserIdSelector,
  (managers, users, id) => {
    return users.concat(managers).filter((element) => element.id === id);
  }
);

export default currentUser;
