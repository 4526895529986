import React from 'react';
import PropTypes from 'prop-types';
import { ProfileThumb } from '@shyft/acorns';

import { commentFormat } from 'helpers/date-time';
import { teamFeedShapes } from '../../duck';
import ActionButtons from '../ActionButtons';
import styled from '../Post/styled';
import blocksStyled from '../styled';

class Comment extends React.PureComponent {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  render() {
    const { data, clickDelete, active } = this.props;
    const { hovered } = this.state;
    const { attachment, content, created_at, owner, allow_delete } = data;

    return (
      <blocksStyled.MessageWrap
        modifiers={active ? 'active' : null}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={() => {
          void 0;
        }}
      >
        <styled.AvatarWrap>
          <ProfileThumb thumbUrl={owner !== undefined ? owner.thumb_url : null} width="44px" />
        </styled.AvatarWrap>
        <blocksStyled.Info>
          <styled.Name>{owner !== undefined ? `${owner.first_name} ${owner.last_name}` : null}</styled.Name>
          <styled.Timestamp>{commentFormat(created_at)}</styled.Timestamp>
          <styled.Content>{content}</styled.Content>
          {!attachment || (
            attachment.object_instances.map((instance, index) =>(
                <styled.Image key={index} src={instance.gallery_url} />
            ))
          )}
          {hovered && allow_delete ? (
            <ActionButtons>
              <ActionButtons.Button onClick={clickDelete} modifiers="delete" />
            </ActionButtons>
          ) : null}
        </blocksStyled.Info>
      </blocksStyled.MessageWrap>
    );
  }
}

Comment.propTypes = {
  clickDelete: PropTypes.func.isRequired,
  data: teamFeedShapes.posts.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Comment;
