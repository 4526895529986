import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ type, color }) => {
  switch (type) {
    case 'downArrow':
      return (
        <span className={`icon ${type}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            height="17"
            viewBox="0 4 20 17"
            width="17"
          >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
            <path d="M0-.75h24v24H0z" fill="none" />
          </svg>
        </span>
      );

    case 'upArrow':
      return (
        <span className={`icon ${type}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            height="17"
            viewBox="0 4 20 17"
            width="17"
            className="__web-inspector-hide-shortcut__"
          >
            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </span>
      );

    case 'add':
      return (
        <span className={`icon ${type}`}>
          <svg
            fill={color}
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </span>
      );

    case 'remove':
      return (
        <span className={`icon ${type}`}>
          <svg
            fill={color}
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 13H5v-2h14v2z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </span>
      );

    case 'backArrow':
      return (
        <span className={`icon ${type}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10px"
            height="15px"
            viewBox="0 0 10 15"
            version="1.1"
          >
            <defs />
            <g
              id="Welcome"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g
                id="Final-Calendar"
                transform="translate(-120.000000, -219.000000)"
                stroke="#90959A"
                strokeWidth="3"
              >
                <g id="Group-14" transform="translate(85.000000, 180.000000)">
                  <g id="Group-5" transform="translate(37.000000, 40.000000)">
                    <polyline id="Path-24" points="6 1 0 6.80003667 6 12" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      );

    case 'forwardArrow':
      return (
        <span className={`icon ${type}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10px"
            height="15px"
            viewBox="0 0 10 15"
            version="1.1"
          >
            <defs />
            <g
              id="Welcome"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g
                id="Final-Calendar"
                transform="translate(-289.000000, -218.000000)"
                stroke="#90959A"
                strokeWidth="3"
              >
                <g id="Group-14" transform="translate(85.000000, 180.000000)">
                  <g id="Group-5" transform="translate(37.000000, 40.000000)">
                    <polyline
                      id="Path-24"
                      transform="translate(172.000000, 5.500000) scale(-1, 1) translate(-172.000000, -5.500000) "
                      points="175 0 169 5.80003667 175 11"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      );

    case 'checkmark':
      return (
        <svg
          className={`icon ${type}`}
          width="22px"
          height="16px"
          viewBox="0 0 22 16"
          version="1.1"
        >
          <g
            id="Employee-Dashboard-v4-includes-signin-sign-up-(finished)"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Create-user-profile_option2_empty"
              transform="translate(-637.000000, -422.000000)"
              fill={color}
              fillRule="nonzero"
            >
              <g id="checked" transform="translate(637.000000, 422.000000)">
                <path
                  d="M7.91498093,16 C7.46860681,16 7.02176863,15.8279133 6.68125174,15.4834793 L0.511162048,9.24713363 C-0.170387349,8.55826558 -0.170387349,7.44157807 0.511162048,6.75271003 C1.19271145,6.06384199 2.29753509,6.06384199 2.97908449,6.75271003 L7.91498093,11.7416093 L19.0209671,0.516572861 C19.7025165,-0.172086718 20.8075979,-0.172295182 21.4886833,0.516572861 C22.1704389,1.2054409 22.1704389,2.322389 21.4886833,3.01099646 L9.14896794,15.4834793 C8.80819324,15.8277048 8.3615613,16 7.91498093,16 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
      );

    default:
      throw 'This is not a valid type. Please enter a valid type.';
  }
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
