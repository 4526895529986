import styled from 'styled-components/macro';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { Button } from '@shyft/acorns';

import InvalidBatchStatusIcon from './assets/invalid-batch.svg';
import PencilIcon from './assets/pencil-icon.svg';

const textColor = '#90959A';
const borderColor = '#DEDEDE';

const green = '#26CE93';
const darkGrey = '#64676A';
export const breakpoints = {
  medSmall: '556px',
  med: '680px',
};

const BackLink = styled(Link)`
  font-size: 14px;

  color: ${textColor};

  :hover {
    color: ${textColor};
  }
`;

const BackArrow = styled.span`
  display: inline-block;
  width: 8px;
  height: 12px;
  background-image: url(${process.env.PUBLIC_URL}/icons/back-arrow.svg);
  background-size: cover;
  position: relative;
  margin-top: 6px;
  margin-right: 0.5rem;
`;

const Header = styled.h2`
  width: 100%;
  text-align: center;
`;

const RadioButtonLabel = styled.label`
  max-width: 200px;
`;

const FooterDescription = styled.p`
  color: ${darkGrey};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0;

  @media (max-width: ${breakpoints.med}) {
    font-size: 0.75rem;
  }
`;

const BatchStatusIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

const InvalidBatchStatusBadge = styled.img.attrs({
  src: InvalidBatchStatusIcon,
})`
  height: 25px;
  width: 25px;
  margin-right: 1rem;
`;

const ValidBadgePencil = styled.img.attrs({
  src: PencilIcon,
})`
  height: 12px;
  width: 12px;
`;

const ValidBatchStatusBadge = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${green};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const ActionBtn = styled(Button).attrs(({ disabled, modifiers }) => ({
  disabled,
  modifiers: ['small', 'primary'].concat(modifiers),
}))`
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  @media (max-width: ${breakpoints.med}) {
    font-size: ${rem('12px')};
    padding: 6px 12px;
    font-weight: 600;
  }

  @media (max-width: 609px) {
    display: block;
  }
`;

const EditBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-right: 1rem;

  @media (max-width: ${breakpoints.med}) {
    font-size: ${rem('12px')};
    padding: 6px 12px;
    font-weight: 600;
  }

  @media (max-width: 609px) {
    display: block;
  }
`;

const RowWrap = styled.div`
  background-color: white;
  flex: 1;
  padding: 1rem;
`;

const HeadWrap = styled.div`
  background-color: white;
  padding: 1rem;
`;

export {
  ActionBtn,
  EditBtn,
  BackLink,
  BackArrow,
  Header,
  RadioButtonLabel,
  BatchStatusIcon,
  FooterDescription,
  ValidBadgePencil,
  ValidBatchStatusBadge,
  InvalidBatchStatusBadge,
  RowWrap,
  HeadWrap,
};
