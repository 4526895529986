const moment = require('moment-range').extendMoment(require('moment'));
import 'helpers/findIndex-shim.js';

import { groupBy, first } from 'lodash';

import {
  DATE_RANGE_CHANGE,
  NEW_SHIFTS_LOADED,
  NEW_SHIFTS_LOADING,
  MORE_SHIFTS_LOADING,
  MORE_SHIFTS_LOADED,
  ADD_CALENDAR_TYPE_FILTER,
  DELETE_CALENDAR_TYPE_FILTER,
  ADD_CALENDAR_DAY_FILTER,
  DELETE_CALENDAR_DAY_FILTER,
  ADD_CALENDAR_STATE_FILTER,
  DELETE_CALENDAR_STATE_FILTER,
  CANCEL_LOADING,
} from '../actions/calendar-actions';

const findFilterIndex = (filter, array) => {
  return array.findIndex((element) => {
    return element == filter;
  });
};

const deleteIndexFromArray = (index, array) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const alignShifts = (shifts, start, end) => {
  return Array.from(moment.range(start, end).by('day')).map((day) => {
    return (
      shifts.find((shift) => moment(shift.start_at).isSame(day, 'day')) || {}
    );
  });
};

const hoursWorked = (shifts) =>
  shifts.reduce(
    (accum, value) =>
      accum + moment.range(value.start_at, value.end_at).diff('hours'),
    0
  );

export const calendarReducer = (
  state = {
    shifts: [],
    loading: false,
    currentDateRangeEnd: null,
    currentDateRangeStart: null,
    typeFilters: [],
    dayFilters: [],
    stateFilters: [],
    lastCount: 0,
  },
  action
) => {
  switch (action.type) {
    case DATE_RANGE_CHANGE:
      return {
        ...state,
        currentDateRangeEnd: action.to,
        currentDateRangeStart: action.from,
      };

    case NEW_SHIFTS_LOADING:
      return { ...state, loading: true };

    case NEW_SHIFTS_LOADED: {
      const { currentDateRangeStart: start, currentDateRangeEnd: end } = state;
      const shiftsByOwner = Object.values(
        groupBy(action.shifts, (shift) => shift.owner.id)
      ).map((shifts) => ({
        user: first(shifts).owner,
        schedules: alignShifts(shifts, start, end),
        hours: { scheduled: hoursWorked(shifts) },
      }));

      return {
        ...state,
        loading: false,
        shifts: action.shifts,
        calendarShifts: shiftsByOwner,
      };
    }

    case ADD_CALENDAR_TYPE_FILTER:
      return { ...state, typeFilters: [...state.typeFilters, action.filter] };

    case DELETE_CALENDAR_TYPE_FILTER: {
      const typeIndex = findFilterIndex(action.filter, state.typeFilters);
      if (typeIndex === -1) return state;

      return {
        ...state,
        typeFilters: deleteIndexFromArray(typeIndex, state.typeFilters),
      };
    }

    case ADD_CALENDAR_DAY_FILTER:
      return { ...state, dayFilters: [...state.dayFilters, action.filter] };

    case DELETE_CALENDAR_DAY_FILTER: {
      const dayIndex = findFilterIndex(action.filter, state.dayFilters);
      if (dayIndex === -1) return state;

      return {
        ...state,
        dayFilters: deleteIndexFromArray(dayIndex, state.dayFilters),
      };
    }

    case ADD_CALENDAR_STATE_FILTER:
      return { ...state, stateFilters: [...state.stateFilters, action.filter] };

    case DELETE_CALENDAR_STATE_FILTER: {
      const stateIndex = findFilterIndex(action.filter, state.stateFilters);
      if (stateIndex === -1) return state;

      return {
        ...state,
        stateFilters: deleteIndexFromArray(stateIndex, state.stateFilters),
      };
    }
    case MORE_SHIFTS_LOADING: {
      return { ...state, loading: true };
    }
    case CANCEL_LOADING: {
      return { ...state, loading: false };
    }
    case MORE_SHIFTS_LOADED: {
      const { shifts } = state;
    
      return {
        ...state,
        loading: false,
        shifts: shifts.concat(action.shifts),
        lastCount: action.shifts.length,
      }
    }

    default:
      return state;
  }
};
