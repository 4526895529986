import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

const modalRoot = document.getElementById('root');


class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    if(modalRoot && this.element){
      //this.element.setAttribute[(top, "800")];
      //this.element.setAttribute[(left, "1000")];
      modalRoot.appendChild(this.element);
    }
  }

  componentWillUnmount() {
    if(modalRoot && this.element){
      modalRoot.removeChild(this.element);
    }
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.element
    );
  }
}
export default injectIntl(Modal);
