import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from 'pages/Dashboard/styled';
import ShiftManagedRow from '../../../GeneralComponents/ShiftManagedRow/ShiftManagedRow';
import ManagedRTORow from '../../../GeneralComponents/ShiftManagedRow/ManagedRTORow';
import AbsentDataComponent from '../../../GeneralComponents/AbsentData/AbsentDataComponent';
import LocationSubscriber from '../../../GeneralComponents/LocationSubscriber';
import { createApiDateFormat } from 'helpers/date-time';

/*
const ManagementFeedComponent = ({
  intl,
  shifts,
  loading,
  fetchManagedShifts,
  managedRTOs,
  currentUser,
}) => {
  const messages = defineMessages({
    noManaged: {
      id: 'ManagementFeedComponent.noManaged',
      defaultMessage: 'There have been no managed requests in the past 7 days.',
    },
  });
  
  const renderShiftAndRTOs = () => {
    const mixed = [...shifts, ...managedRTOs];
    const managed = mixed.map((element) =>{
      if(element.days) {
        return (
          <ManagedRTORow
            key={element.id}
            rto={element}
            currentUser={currentUser}
            managedTime={element.managed_at}
          />
        );
      }else {
        return (
          <ShiftManagedRow
            key={element.id}
            id={element.id}
            type={element.name}
            postId={element.post_id}
            startAt={element.start_at}
            endAt={element.end_at}
            owner={element.owner}
            managedTime={element.manager_time}
            coverer={element.coverer}
            approver={element.approver}
            tradeStatus={element.trade_status}
            reason={element.content}
          />
        );
      }
    });
    return managed;
  }

  const renderShifts = () => {
    if (loading) {
      return (
        <styled.ShiftRequestsLoadingLayer>
          <img src="/loader.gif" height="75" width="75" alt="Loading" />
        </styled.ShiftRequestsLoadingLayer>
      );
    }

    if (shifts.length > 0) {

      const sortedShifts = [...shifts].sort((a, b) => {
        const dateA = moment(a.manager_time);
        const dateB = moment(b.manager_time);
        return dateB.diff(dateA);
      });

      //const managedShifts = shifts.map((element) => (
      const managedShifts = sortedShifts.map((element) => (
        <ShiftManagedRow
          key={element.id}
          id={element.id}
          type={element.name}
          postId={element.post_id}
          startAt={element.start_at}
          endAt={element.end_at}
          owner={element.owner}
          managedTime={element.manager_time}
          coverer={element.coverer}
          approver={element.approver}
          tradeStatus={element.trade_status}
          reason={element.content}
        />
      ));

      return managedShifts;
    }

    return (
      <AbsentDataComponent message={intl.formatMessage(messages.noManaged)} />
    );
  };

  const renderManagedRTOs = () => {
      let validRTOs = managedRTOs.filter((rto) => {
        const myDate = moment(rto.managed_at);
        const sevenDaysAgo = createApiDateFormat(moment().subtract(7, 'days'));
        const today = createApiDateFormat(moment());
        return myDate.isAfter(sevenDaysAgo) && myDate.isBefore(today) || 
                myDate.isSame(sevenDaysAgo) || myDate.isSame(today);
      });


     if (validRTOs.length > 0) {
      const sortedRTOs = validRTOs.sort(
        (a, b) => new Date(b.managed_at) - new Date(a.managed_at)
      );
      
      //const sortedRTOs = [...validRTOs].sort((a, b) => {
      //  const dateA = moment(a.managed_at);
      //  const dateB = moment(b.managed_at);
      //  return dateB.diff(dateA);
      //});
      
      //const managedShifts = shifts.map((element) => (
      const rtos = sortedRTOs.map((rto) => (
        <ManagedRTORow
          key={rto.id}
          rto={rto}
          currentUser={currentUser}
          managedTime={rto.managed_at}
        />
      ));

      return rtos;
    }

    
    //return (
     // <AbsentDataComponent message={intl.formatMessage(messages.noManaged)} />
    //);
    
    return (null);
  }

  return (
    <styled.ShiftRequestsFeed>
      <styled.ShiftRequestsHeader>
        <FormattedMessage
          id="ManagementFeedComponent.managerActivities"
          defaultMessage="Manager Activities"
        />
      </styled.ShiftRequestsHeader>

      <styled.ShiftRequestsFeedSection>
        {renderManagedRTOs()}
        {renderShifts()}
      </styled.ShiftRequestsFeedSection>
      <LocationSubscriber onChangeHandlers={[fetchManagedShifts]} />
    </styled.ShiftRequestsFeed>
  );
};

export default injectIntl(ManagementFeedComponent);

*/


class ManagementFeedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.messages = defineMessages({
      noManaged: {
        id: 'ManagementFeedComponent.noManaged',
        defaultMessage: 'There have been no managed requests in the past 7 days.',
      },
      
    });

    this._isMounted = false;
  }

  componentDidMount () {
    const { intl, shifts, loading, fetchManagedShifts, managedRTOs, currentUser, } = this.props;

    this._isMounted = true;

  }

  componentDidUpdate (prevProps) {
    const { intl, shifts, loading, fetchManagedShifts, managedRTOs, currentUser, } = this.props;

  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  renderShiftAndRTOs = () => {
    const mixed = [...shifts, ...managedRTOs];
    const managed = mixed.map((element) =>{
      if(element.days) {
        return (
          <ManagedRTORow
            key={element.id}
            rto={element}
            currentUser={currentUser}
            managedTime={element.managed_at}
          />
        );
      }else {
        return (
          <ShiftManagedRow
            key={element.id}
            id={element.id}
            type={element.name}
            postId={element.post_id}
            startAt={element.start_at}
            endAt={element.end_at}
            owner={element.owner}
            managedTime={element.manager_time}
            coverer={element.coverer}
            approver={element.approver}
            tradeStatus={element.trade_status}
            reason={element.content}
          />
        );
      }
    });
    return managed;
  }

  renderShifts = () => {
    const { intl, loading, shifts, } = this.props;

    if (loading) {
      return (
        <styled.ShiftRequestsLoadingLayer>
          <img src="/loader.gif" height="75" width="75" alt="Loading" />
        </styled.ShiftRequestsLoadingLayer>
      );
    }

    if (shifts.length > 0) {

      const sortedShifts = [...shifts].sort((a, b) => {
        const dateA = moment(a.manager_time);
        const dateB = moment(b.manager_time);
        return dateB.diff(dateA);
      });

      //const managedShifts = shifts.map((element) => (
      const managedShifts = sortedShifts.map((element) => (
        <ShiftManagedRow
          key={element.id}
          id={element.id}
          type={element.name}
          postId={element.post_id}
          startAt={element.start_at}
          endAt={element.end_at}
          owner={element.owner}
          managedTime={element.manager_time}
          coverer={element.coverer}
          approver={element.approver}
          tradeStatus={element.trade_status}
          reason={element.content}
        />
      ));

      return managedShifts;
    }

    return (
      <AbsentDataComponent message={intl.formatMessage(this.messages.noManaged)} />
    );
  };

   renderManagedRTOs = () => {
    const { intl, managedRTOs, currentUser, } = this.props;

      let validRTOs = managedRTOs.filter((rto) => {
        const myDate = moment(rto.managed_at);
        const sevenDaysAgo = createApiDateFormat(moment().subtract(7, 'days'));
        const today = createApiDateFormat(moment());
        return myDate.isAfter(sevenDaysAgo) && myDate.isBefore(today) || 
                myDate.isSame(sevenDaysAgo) || myDate.isSame(today);
      });


     if (validRTOs.length > 0) {
      const sortedRTOs = validRTOs.sort(
        (a, b) => new Date(b.managed_at) - new Date(a.managed_at)
      );
      
      //const sortedRTOs = [...validRTOs].sort((a, b) => {
       // const dateA = moment(a.managed_at);
       // const dateB = moment(b.managed_at);
       // return dateB.diff(dateA);
     // });
      
      //const managedShifts = shifts.map((element) => (
      const rtos = sortedRTOs.map((rto) => (
        <ManagedRTORow
          key={rto.id}
          rto={rto}
          currentUser={currentUser}
          managedTime={rto.managed_at}
        />
      ));

      return rtos;
    }

    
    //return (
      //<AbsentDataComponent message={intl.formatMessage(messages.noManaged)} />
    //);
    
    return (null);
  }

  render () {
    const { intl, shifts, loading, fetchManagedShifts, managedRTOs, currentUser, } = this.props;

    if(!this._isMounted) {
      return null;
    }
    return (
      <styled.ShiftRequestsFeed>
        <styled.ShiftRequestsHeader>
          <FormattedMessage
            id="ManagementFeedComponent.managerActivities"
            defaultMessage="Manager Activities"
          />
        </styled.ShiftRequestsHeader>

        <styled.ShiftRequestsFeedSection>
          {this.renderManagedRTOs()}
          {this.renderShifts()}
        </styled.ShiftRequestsFeedSection>
        <LocationSubscriber onChangeHandlers={[fetchManagedShifts]} />
      </styled.ShiftRequestsFeed>
    );
  }

}

export default injectIntl(ManagementFeedComponent);

