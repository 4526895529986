import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AbsentDataComponent from 'components/GeneralComponents/AbsentData/AbsentDataComponent';

import sharedStyles from 'pages/Dashboard/styled';
import * as styled from './styled';

class PendingUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: true,
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { pendingUsers, } = this.props;
    this._isMounted = true;
  }

  componentDidUpdate (prevProps, prevState) {
    const { pendingUsers, } = this.props;
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  _renderPendingUserImages = () => {
    const { users, pendingUsers, } = this.props;

    const imageArrayLengthMax = 12;
    const numberOfImagesAllowed =
      imageArrayLengthMax > pendingUsers.length ? pendingUsers.length : imageArrayLengthMax;

    const profilePictureArray = [];

    for (let i = 0; i < numberOfImagesAllowed; i++) {
      profilePictureArray.push(
        <styled.ProfileThumb>
          <img
            src={pendingUsers[i].profile_image_thumb_url}
            alt={`${pendingUsers[i].first_name} ${pendingUsers[i].last_name}`}
            height="33"
            width="33"
            style={{
              borderRadius: '50%',
            }}
          />
        </styled.ProfileThumb>
      );
    }

    return profilePictureArray;
  };

  _renderPendingUsersSection = () => {
    const { users, loading, pendingUsers, } = this.props;

    if (loading) {
      return (
        <styled.LoadingLayer>
          <img src="/loader.gif" height="75" width="75" />
        </styled.LoadingLayer>
      );
    }

    if (pendingUsers.length === 0) {
      return (
        <AbsentDataComponent>
          <FormattedMessage
            id="PendingUsers.noRequests"
            defaultMessage="There are no requests left"
          />
        </AbsentDataComponent>
      );
    }

    return (
      <React.Fragment>
        <styled.ProfileThumbSection>
          {this._renderPendingUserImages()}
        </styled.ProfileThumbSection>
        <styled.PendingUsersDescription>
          <FormattedMessage
            id="PendingUsers.pendingUsersDescription"
            defaultMessage="We noticed there are {users} users who joined this location and are waiting for your approval."
            values={{
              users: pendingUsers.length,
            }}
          />
        </styled.PendingUsersDescription>
        <styled.ProfileThumbSection>
          {/*<styled.PendingUsersLink to="/team?filter=pending">
            <FormattedMessage
              id="PendingUsers.seePendingUsers"
              defaultMessage="See Pending Users"
            />
          </styled.PendingUsersLink>*/}
          <styled.PendingUsersLink to="/usermanagement?filter=pending">
            <FormattedMessage
              id="PendingUsers.seePendingUsers"
              defaultMessage="See Pending Users"
            />
          </styled.PendingUsersLink>
        </styled.ProfileThumbSection>
      </React.Fragment>
    );
  };

  render() {
    return (
      <sharedStyles.ShiftRequestsFeed>
        <sharedStyles.ShiftRequestsHeader>
          <FormattedMessage
            id="PendingUsers.endingUsers"
            defaultMessage="Pending Users"
          />
        </sharedStyles.ShiftRequestsHeader>
        {this._renderPendingUsersSection()}
      </sharedStyles.ShiftRequestsFeed>
    );
  }
}

PendingUsers.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PendingUsers;
