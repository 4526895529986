import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './ButtonStyles';



class Confirmation extends React.PureComponent {
	constructor(props) {
    super(props);

    this.state = {
      hide: true,
    };


	  this._isMounted = false;
	  this.isBackButtonClicked = false;
  }

  componentDidMount () {
  	//const {shift, skills, locationSettings, fetchShiftApplicants, userPrivileges, } = this.props;
  	
  	this._isMounted = true;
  	//handle browser back button clicked
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentDidUpdate (prevProps) {
  	//const {shift, skills, locationSettings, userPrivileges, addApplicantJobTitle, } = this.props;
  	//const { selectedSort, sortNameA, sortTitleA, sortStatusA, applicantsSortByName, applicantsSortByJob, applicantsSortByStatus,} = this.state;

  }

  componentWillUnmount () {
  	this._isMounted = false;
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }


  handleCancelClick = (event) => {
  	const { closeWin, cancelUpload, } = this.props;

  	cancelUpload();
  	closeWin();
  	event.preventDefault();
  }

  handleConfirmClick = (event) => {
  	const { closeWin, confirmUpload, } = this.props;

  	confirmUpload();
  	closeWin();
  	event.preventDefault();
  }

  
  render () {
  	const {shift, skills, intl, locationSettings, } = this.props;

   	let winTop = `500px`; //`${(window.innerHeight - 612)/2}px`;
    let winLeft = `300px`; //`${(window.innerWidth - 1200)/2}px`;
    let winW = '600px';//`50%`;
    let winH = `120px`; //94.9% viewport height

    
   	return (
   		<styled.DetailedWinContainer top={winTop} left={winLeft} width={winW} height={winH}>
   			<styled.HeaderWrap>
	   			<styled.Caption>
	   				<FormattedMessage id="Confirmation.header"
		        	defaultMessage="Upload Selected File?"/>
		      </styled.Caption>
	      </styled.HeaderWrap>

   			<styled.ButtonWrap>
   				<styled.AButton 
   					onClick={this.handleCancelClick}>
   					<FormattedMessage
              id="Confirmation.cancel"
              defaultMessage="Cancel"
            />
   				</styled.AButton>
   				<styled.AButton 
   					onClick={this.handleConfirmClick}>
   					<FormattedMessage
              id="Confirmation.confirm"
              defaultMessage="Confirm"
            />
   				</styled.AButton>
   			</styled.ButtonWrap>
   		</styled.DetailedWinContainer>
   	);
  }

}


Confirmation.propTypes = {
  //locationConfig: PropTypes.shape({}).isRequired,
  //shift: dashboardShapes.shiftShape.isRequired,
  
};

export default injectIntl(Confirmation);
