import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import * as styled from './styled';


class CostCenter extends React.Component {
	constructor(props){
    super(props);
    this.state = {
                 	
                  };

   
    
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  handleMenuItemClick = (event) => {
  	const {tag, handleMenuItemClick} = this.props;
  	handleMenuItemClick(tag.tag_key, tag.tag_content);
  	event.preventDefault();
  }

  render() {
    const {tag, intl} = this.props;
    const {hide} = this.state;

    return(
    		<styled.CostCenterMenuItem onClick={this.handleMenuItemClick}>
	        <styled.CostCenterTagContent>
	        	{tag.tag_content}
	        </styled.CostCenterTagContent>
        </styled.CostCenterMenuItem>
    );
  }
}

CostCenter.propTypes={
  
}

export default injectIntl(CostCenter);