import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import CoverRequestString from 'components/GeneralComponents/CoverRequestString';

import { formatManagementTimeForFeed, formatManagementTimeForFeedNew, } from 'helpers/date-time';

import './ShiftManagedRow.scss';

class ManagedRTORow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      managedTime: this.props.managedTime,
      formattedManagementTime: formatManagementTimeForFeedNew(this.props.managedTime, this.props.intl.formatMessage).text,

      debug: false,
    };
  }

  componentDidMount() {
    const { managedTime, intl: { formatMessage }, rto, } = this.props;
    let timer = setInterval(this.tick, 1000);
    this.setState({
      timer,
      formattedManagementTime: formatManagementTimeForFeedNew(managedTime, formatMessage).text,
    });
  };

  componentDidUpdate (prevProps) {

  };

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  tick = () => {
    const { managedTime, intl: { formatMessage }, rto, } = this.props;
    const managedTimeObject = formatManagementTimeForFeedNew(managedTime, formatMessage);

    if (!managedTimeObject.needed) {
      clearInterval(this.state.timer);
    }

    this.setState({ formattedManagementTime: managedTimeObject.text });
  };

  _renderTradeStatus = () => {

    const { rto, currentUser, } = this.props;

    const approverName = `${rto.approver_first_name} ${rto.approver_last_name}`;

    if (rto.status === 'approved') {
      return (
        <FormattedMessage
          id="ManagedRTORow.approved"
          defaultMessage="Request time off approved by {approverName}"
          values={{
            approverName,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="ManagedRTORow.denied"
        defaultMessage="Request time off denied by {approverName}"
        values={{
          approverName,
        }}
      />
    );
  };

  render() {
    const { rto, currentUser, } = this.props;
    const { debug, formattedManagementTime} = this.state;

    const name = `${rto.first_name} ${rto.last_name}`;
    const approverName = `${rto.approver_first_name} ${rto.approver_last_name}`;
    const debugInfo = `Id: ${rto.id}`;

    if (rto.status === 'approved') {
      return (
        <article className="shift-request shift-request--managed">
          {debug && <label>{debugInfo}</label>}
          <h5 className="shift-request--outcome">
            {this._renderTradeStatus()}
            <span style={{ float: 'right' }}>
              {formattedManagementTime}
            </span>
          </h5>
          <span className="shift-request--timestamp" />
          <section className="shift-request--description">
            <p className="shift-request--description-text">
              <FormattedMessage
                id="ManagedRTORow.approvedText"
                defaultMessage="{name}'s request time off has been approved by {approverName}"
                values={{
                  name, approverName,
                }}
              />
            </p>
          </section>
        </article>
      );
    }

    return (
      <article className="shift-request shift-request--managed">
        {debug && <label>{debugInfo}</label>}
        <h5 className="shift-request--outcome">
          {this._renderTradeStatus()}
          <span style={{ float: 'right' }}>
            {formattedManagementTime}
          </span>
        </h5>
        <span className="shift-request--timestamp" />
        <section className="shift-request--description">
          <p className="shift-request--description-text">
            <FormattedMessage
              id="ManagedRTORow.deniedText"
              defaultMessage="{name}'s request time off has been denied by {approverName}"
              values={{
                name, approverName,
              }}
            />
          </p>
        </section>
      </article>
    );
  }
}

export default injectIntl(ManagedRTORow);

ManagedRTORow.propTypes = {
  rto: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};
