import { createSlice } from '@reduxjs/toolkit';

import teamConstants from '../../constants';

const { statusTypes } = teamConstants;

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    activeFilter: statusTypes.ACTIVE,
    filters: {
      [statusTypes.ACTIVE]: {
        name: 'Active',
        count: 0,
      },
      [statusTypes.PENDING]: {
        name: 'Pending',
        count: 0,
      },
      [statusTypes.REMOVED]: {
        name: 'Removed',
        count: 0,
      },
    },
  },
  reducers: {
    updateActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    incrementCount: (state, action) => {
      state.filters[action.payload.filter].count += action.payload.amount;
    },
    decrementCount: (state, action) => {
      state.filters[action.payload.filter].count -= action.payload.amount;
    },
    recieveMetadata: (state, action) => {
      state.filters.active = {
        ...state.filters.active,
        count: action.payload.active.count,
      };
      state.filters.pending = {
        ...state.filters.pending,
        count: action.payload.pending.count,
      };
      state.filters.removed = {
        ...state.filters.removed,
        count: action.payload.removed.count,
      };
    },
  },
});

export default filtersSlice;
