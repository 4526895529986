export const ROLES = {
  LOGGED_OUT: {
    key: 'logged_out',
  },
  USER: {
    key: 'user',
  },
  ADMIN: {
    key: 'admin',
  },
};

// checks to see if user is a manager
const isManager = ({ current_user_privilege }) =>
  current_user_privilege.is_admin;

/**
 * Main function for determining user role on the web app. Refer to top of file
 * for possible roles
 * @param  { Object } appState - Full appState in order to find setupActions
 * @param  { Object } _setAppState - Function that updates top level appState, need it
 * just in case setupActions is not present
 */
export const FindRole = (data) => {
  if (!data.meta) {
    // logged out
    return ROLES.LOGGED_OUT.key;
  }

  if (data.meta.current_user_privilege && isManager(data.meta)) {
    // no redirect
    return ROLES.ADMIN.key;
  }

  // normal user logged in
  return ROLES.USER.key;
};
