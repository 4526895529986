import { connect } from 'react-redux';
import PendingUsersComponent from './PendingUsersComponent';

const mapStateToProps = ({ globalReducer , appReducer, }) => {
  return {
    loading: globalReducer.Users.loading,
    users: globalReducer.Users.users,
    pendingUsers: appReducer.users.pendingUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const PendingUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingUsersComponent);

export default PendingUsersContainer;
