import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';

class StatusCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { schedule } = this.props;

    const inProgress = (<FormattedMessage
      id="StatusCell.inProgress"
      defaultMessage="In progress" 
    />);

    const completed = (<FormattedMessage
      id="StatusCell.completed"
      defaultMessage="Completed" 
    />);

    const failed = (<FormattedMessage
      id="StatusCell.failed"
      defaultMessage="Failed" 
    />);
    
    if(schedule && schedule.status === 'queued' || schedule.status === 'running' 
      || schedule.status === 'existing' || schedule.status === 'default') {
    	return (
        <styled.StatusProgress>
          <styled.ProgressSpan/>
    		  <styled.ProgressText>{inProgress}</styled.ProgressText>
        </styled.StatusProgress>
    	);
    }else if(schedule && schedule.status === 'succeeded') {
      return (
        <styled.StatusCompleted>
          <styled.CompletedSpan/>
          <styled.CompletedText>{completed}</styled.CompletedText>
        </styled.StatusCompleted>
      );
    }else if(schedule && schedule.status === 'failed') {
      return (
        <styled.StatusFailed>
          <styled.FailedSpan/>
          <styled.FailedText>{failed}</styled.FailedText>
        </styled.StatusFailed>
      );
    }else {
      return null;
    }
  }
}


StatusCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(StatusCell);