import { formatOnlyProps } from 'helpers/formatting';

const params = {
  getPosts: (channel_id, page) => ({
    channel_id,
    page,
    per_page: 10,
    total: true,
    order: 'created_at_desc',
    'response[only]': formatOnlyProps(`
      id,
      post_type,
      content,
      comments_count,
      created_at,
      attachment,
      final_allow_delete,
      final_allow_comment,
      final_allow_like,
      owner[
        first_name,
        last_name,
        thumb_url
      ],
      owner_id,
      commented,
      likes_count,
      liked,
      pinned,
      url_meta,
      attached_files_blobs,
      attachment[object_instances[thumb_url,gallery_url,original_url,full_url]]],
    `),
    'response[nested]': 1,
  }),
  getComments: (post_id, page) => ({
    post_id,
    page,
    per_page: 500,
    total: true,
    order: 'created_at_desc',
    'response[only]': formatOnlyProps(`
      id,
      content,
      created_at,
      allow_delete,
      owner[
        first_name,
        last_name,
        thumb_url
      ],
    `),
    'response[nested]': 1,
  }),
};

export default {
  params,
};
