import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { getUserId, purgeAuthenticationCookie } from '../helpers/cookies';
import { thumbnailImageCheck } from '../helpers/formatting';
import { GetEmployeeDetails } from '../helpers/api-calls';
import Hamburger from 'components/GeneralComponents/Hamburger/Hamburger';
import { datastore } from '../helpers/general-data';
import { Redirect } from 'react-router-dom';

import '../stylesheets/components/UserDropdown.scss';

const MobileNavWrap = styled.div`
  display: none;

  @media (max-width: 680px) {
    display: block;
  }
`;

export default class UserDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      multipleLocations: false,
    };
  }

  componentDidMount() {
    const user = datastore.getEmployeeData();
    const userId = getUserId();
    if (!userId) return;

    if (user.user) {
      this.setState({
        avatarUrl: user.user
          ? thumbnailImageCheck(user.user.profile_image)
          : '',
      });
    } else {
      GetEmployeeDetails(userId).then((data) => {
        this.setState({
          avatarUrl: data.user
            ? thumbnailImageCheck(data.user.profile_image)
            : '',
        });
        datastore.setEmployeeData(data);
      });
    }
  }

  dropdownClick = () => {
    this.setState({ open: !this.state.open });
  };

  dropdownMouseEnter = () => {
    this.setState({ open: true });
  };

  dropdownMouseLeave = () => {
    this.setState({ open: false });
  };

  _addLocation = () => {
    this.setState({
      path: '/add-location',
    });
  };

  logout() {
    purgeAuthenticationCookie();
    window.location.pathname = '/';
  }

  render() {
    const { path } = this.state;
    const { authenticated } = this.props;
    if (path) {
      return <Redirect to={path} />;
    }

    if (authenticated) {
      return (
        <div
          className="user-dropdown--container"
          // onMouseLeave={this.dropdownMouseLeave}
        >
          <button
            className="user-dropdown--button"
            onClick={this.dropdownClick}
            // onMouseEnter={this.dropdownMouseEnter}
          >
            {/* <img src={this.state.avatarUrl} alt="Profile Avatar" /> */}
            <Hamburger />
          </button>
          <ul
            className={`user-dropdown--list${this.state.open ? ' open' : ''}`}
          >
            <li className="user-dropdown--list-arrow" />
            <MobileNavWrap>
              <li className="user-dropdown--list-element">
                <NavLink
                  activeClassName="active-page"
                  className={`user-dropdown--link`}
                  strict
                  exact
                  to={`/`}
                >
                  <FormattedMessage
                    id="UserDropdown.dashboardLink"
                    defaultMessage="Dashboard"
                    description="Link to Dashboard page"
                  />
                </NavLink>
              </li>
              <li className="user-dropdown--list-element">
                <NavLink
                  activeClassName="active-page"
                  className={`user-dropdown--link`}
                  strict
                  exact
                  to={`/calendar`}
                >
                  <FormattedMessage
                    id="UserDropdown.calendarLink"
                    defaultMessage="Calendar"
                    description="Link to Calendar page"
                  />
                </NavLink>
              </li>
              <li className="user-dropdown--list-element">
                <NavLink
                  activeClassName="active-page"
                  className={`user-dropdown--link`}
                  strict
                  exact
                  to={`/team`}
                >
                  <FormattedMessage
                    id="UserDropdown.teamLink"
                    defaultMessage="Team"
                    description="Link to Team page"
                  />
                </NavLink>
              </li>
            </MobileNavWrap>
            <li className="user-dropdown--list-element">
              <a
                className="user-dropdown--link"
                href="#"
                onClick={this._addLocation}
              >
                <FormattedMessage
                  id="UserDropdown.addLocationLink"
                  defaultMessage="Add Location"
                  description="Link to Add Location page"
                />
              </a>
            </li>
            <li className="user-dropdown--list-element">
              <a className="user-dropdown--link" href="#" onClick={this.logout}>
                <FormattedMessage
                  id="UserDropdown.logoutLink"
                  defaultMessage="Log Out"
                  description="Link to log out user"
                />
              </a>
            </li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}
