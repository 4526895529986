import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


 /* 

const UserAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 13px;
  height: auto;
  margin-right: 0.25rem;
  margin-top: 4px;

  //border: 1px solid red;
`;

const Text = styled.p`
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0;
  flex: 1;

  ${applyStyleModifiers({
    large: () => `
      font-size: ${rem('14px')};
      color: inherit;
    `,
  })}
  //border: 1px solid green;
`;



const RequestText = styled.p`
  font-size: ${rem('14px')};
  //color: ${({ theme }) => theme.palette.label};
  color: black;
  margin-left: 10px;
  flex: 1;
  display: block;
  //white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
 
  //border: 1px solid green;
`;

const TopWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 70%;
  //border: 1px solid black;

`;




const HeaderText = styled.p `
  //font-size: 30px;
  width: 380px;
  margin-left: 370px;
  margin-bottom: 0px;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  //border: 1px solid red;
`;

const SubmitText = styled.p `
  //width: 380px;
  margin-left: 370px;
  margin-top: 0px;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: rgba(147,147,147,255);
  //border: 1px solid red;
`;




const Element = styled.div `
  width: ${(props) => props.width || '120px'};
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 15px;
  //border: 1px solid blue;

  &: last-child {
    margin-right: 50px;
  }
`;



const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 200px;
  margin-bottom: 0.5rem;
  //font-size: 24px;
  align-items: center;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;

  &: first-child {
    margin-left: 300px;
  }

  &: last-child {
    width: 300px;
    margin-left: 30px;
    margin-right: 300px;
  }

  &: focus {
    outline: none;
  }
`;
*/

const FileNameWrap = styled.div `
  display: flex;
  flex-direction: column;
  //border: 1px solid blue;
`;

const FileName = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
  //border: 1px solid red;
`;

const ScheduleId = styled.p `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 11px;
  font-weight: 400;
  color: rgba(9,9,17,255);
  margin-bottom: 5px;
  //border: 1px solid red;
`;


export default {
  FileNameWrap,
  FileName,
  ScheduleId,
};
