import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
import { getUserId, createHeadersForApiCall } from '../cookies';
import { BASE_URL, BASE_SUBDOMAIN_URL } from '../../constants';
import { StatusCheck } from '../authentication';
/**
 * Searches for existing user after signup
 * @param  {} first_name
 * @param  {} last_name
 */
export const UserSearch = (first_name, last_name) =>
  protectedAxiosInstance.request({
    url: `/api/customer/user_role/users/search`,
    params: {
      first_name_like: first_name,
      last_name_like: last_name,
      per_page: 50,
      page: 1,
    },
  });

/**
 * GetEmployeeDetails - get the details of an employee
 *
 * @param  {String | Int} id - employee's user id
 * @return {Object} Employee object
 */
export function GetEmployeeDetails(id) {
  return fetch(`https://${BASE_SUBDOMAIN_URL}/api/organization/users/${id}`, {
    headers: createHeadersForApiCall(),
  })
    .then((response) => response.json())
    .then((data) => data);
}

/**
 * GetAllEmployees - get all the employees for one location
 *
 * @param  {String | Int} location_id - the id of the location
 * @return {Array} Employee objects (privileges)
 */
export const GetAllEmployees = (location_id) => {
  return fetch(
    `https://${BASE_SUBDOMAIN_URL}/api/organization/user_privileges?location_id=${location_id}`,
    {
      headers: createHeadersForApiCall(),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => data);
};

/**
 * GetEmployeePrivilege - get the privileges for one employee
 *
 * @param  {String | Int} user_id - the id of the user
 * @param  {String | Int} location_id - the id of the location
 * @return {Object} employee privilege object
 */
export const GetEmployeePrivilege = (user_id, location_id) => {
  return fetch(
    `https://${BASE_SUBDOMAIN_URL}/api/organization/user_privileges?user_id=${user_id}&location_id=${location_id}`,
    {
      headers: createHeadersForApiCall(),
    }
  ).then((response) => {
    StatusCheck(response.status);
    return response.json();
  });
};

/**
 * UpdateEmployeePrivilege - update the employee's privileges
 *
 * @param  {String | Int} id - the employee's privilege_id
 * @param  {Object} details - object of details that are to be updated
 * @return {Object} success/error object
 */
export function UpdateEmployeePrivilege(id, details) {
  const body = {
    user_privilege: details,
  };
  return fetch(
    `https://${BASE_SUBDOMAIN_URL}/api/organization/user_privileges/${id}`,
    {
      method: 'PUT',
      headers: createHeadersForApiCall(),
      body: JSON.stringify(body),
    }
  ).then((response) => {
    if (response.ok) {
      return { success: true };
    }
    return { success: false };
  });
}

/**
 * EmployeePrivilege - Updates the employee's privileges
 *
 * @param  {String | Int} id     id of the employee
 * @param  {type} status description
 * @return {type}        description
 */
export function EmployeePrivilege(id, status) {
  const body = {
    user_id: getUserId(),
  };
  return fetch(`//${BASE_URL}/api/user_privileges/${id}/${status}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Method': 'pass_verification',
      'Session-Token': 1333,
      Accept: 'application/vnd.Expresso.v5',
      Authorization:
        'Token token="b0b8ea419a31b9340b4c8bc646282f51", nonce="def"',
      'Access-Control-Allow-Origin': '*',
    },
    // credentials: 'include',
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json();
  });
  //   .then(data => data);
}

export function UpdateEmployeeDetails(id, details) {
  return protectedAxiosInstance.request({
    method: 'PUT',
    url: `/api/customer/user_role/users/${id}`,
    data: {
      user: details,
    },
  });
}

export function DeleteEmployee(id) {
  return fetch(`/api/organization/user_privileges/${id}`, {
    method: 'DELETE',
    headers: createHeadersForApiCall(),
  }).then((response) => {
    if (response.ok) {
      return { success: true };
    }
    return { success: false };
  });
}

/**
 * GetAllEmployees - get all the employees for one location
 *
 * @param  {String | Int} location_id - the id of the location
 * @param {function} succeededCallback - callback function on succeeded
 8 @param {function} failedCallback - callback function on failed
 * @return {Array} Employee objects (privileges)
 */
export const GetAllEmployeesWithCallback = (location_id, succeededCallback, failedCallback) => {
  return fetch(
    `https://${BASE_SUBDOMAIN_URL}/api/organization/user_privileges?location_id=${location_id}`,
    {
      headers: createHeadersForApiCall(),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => data);
};
