import styled from 'styled-components/macro';

const RowGroup = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 4rem;
`;

const Separator = styled.hr`
  margin: 0 3rem;
  margin-bottom: 2rem;
  border-top: 1px solid ${({ theme }) => theme.palette.border};
`;

const HeaderRow = styled.tr`
  color: $headerColor;
  background-color: lighten($lightFontColor, 25%);
  text-align: left;
`;

const HeaderItem = styled.th`
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  &:first-child {
    
  }
  &:nth-child(2) {
    padding-left: 30px;
  }
`;

export default {
  Separator,
  RowGroup,
  HeaderRow,
  HeaderItem,
};
