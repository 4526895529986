import { createSlice } from '@reduxjs/toolkit';

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    data: [],
    loading: true,

  },
  reducers: {
    hydrateSchedules: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      //console.log('loading = false');
    },
    requestSchedules: (state, action) => {
      state.loading = true;
      //console.log('loading = true');
    },
   
  },
});

export default schedulesSlice;
