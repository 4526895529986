import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';

import { Button } from '@shyft/acorns';

const ActionBtnWrap = styled.div`
  flex: 0;
  margin-left: 1rem;
`;

const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 92px;
  margin-bottom: 0.5rem;
`;

const ViewMoreButton = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['plain'].concat(modifiers),
}))`
  font-size: ${rem('12px')};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text};
  display: inline;
  background-color: transparent;
  margin-left: 0.25rem;
`;

const ViewLessButton = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['plain'].concat(modifiers),
}))`
  font-size: ${rem('12px')};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 3px;
  margin-top: 1rem;
  padding: 0.75rem;

  &:hover {
    background-color: white;
  }
`;


const ViewDetailsButton = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 105px;
  margin-top: 55px;
  //margin-left: 20px;

  &:focus {
    outline: none;
  }
  @media (max-width: 800px) {
    max-width: 80px;
  }
`;
const ViewDetailsBtnWrap = styled.div`
  flex: 0;
  margin-left: 1rem;
  position: absolute;
  top: 0px;
  left: 295px;
  //border: 1px solid blue;

  @media (max-width: 800px) {
    left: 225px;
    max-width: 80px;
  }
`;
const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;
export { 
  ActionBtnWrap, 
  ActionBtn, 
  ViewLessButton, 
  ViewMoreButton,
  ViewDetailsButton,
  ViewDetailsBtnWrap,
  Overlay,
 };
