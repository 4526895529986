import React from 'react';

import './AbsentDataComponent.scss';

const AbsentDataComponent = ({ message, children }) => {
  return (
    <section className="absent-data--container">
      <p className="absent-data--text">{message || children}</p>
    </section>
  );
};

export default AbsentDataComponent;
