import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Redirect } from 'react-router-dom';
import { validatePasswordStrength } from 'helpers/validation';
import { getQueryStringParameter } from 'helpers/browser-interactions';
import { ConfirmPasswordReset } from 'helpers/api-calls';
import messages from './messages';

import './ChangePasswordPage.scss';

class ChangePasswordPageI18n extends React.Component {
  state = {
    token: '',
    firstPassword: {
      value: '',
      validity: false,
      errorMessage: '',
    },
    secondPassword: {
      value: '',
      validity: false,
      errorMessage: '',
    },
    successful: false,
    error: false,
    errorMessage: '',
    path: null,
  };

  componentDidMount() {
    if (getQueryStringParameter('reset_password_token')) {
      this.setState({
        token: getQueryStringParameter('reset_password_token'),
      });
    } else {
      this.setState({
        path: {
          pathname: '/login',
        },
      });
    }
  }

  login = () => {
    this.setState({
      path: {
        pathname: '/login',
      },
    });
  };

  _handleFirstPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({
      firstPassword: {
        value,
        validity: validatePasswordStrength(value),
        errorMessage: '',
      },
    });
  };

  _handleSecondPasswordChange = (e) => {
    const { value } = e.target;
    this.setState({
      secondPassword: {
        value,
        validity: this.state.firstPassword.value === value,
        errorMessage: '',
      },
    });
  };

  _validateForm = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    let validity = true;
    if (!this.state.firstPassword.validity) {
      validity = false;
      this.setState({
        firstPassword: Object.assign(this.state.firstPassword, {
          errorMessage: formatMessage(messages.error1),
        }),
      });
    }

    if (!this.state.secondPassword.validity) {
      validity = false;
      this.setState({
        secondPassword: Object.assign(this.state.secondPassword, {
          errorMessage: formatMessage(messages.error2),
        }),
      });
    }

    return validity;
  };

  _formSubmit = (e) => {
    e.preventDefault();
    const {
      intl: { formatMessage },
    } = this.props;

    this.setState({
      error: false,
      errorMessage: '',
    });

    if (this._validateForm()) {
      ConfirmPasswordReset(
        this.state.firstPassword.value,
        this.state.secondPassword.value,
        this.state.token
      ).then(
        (response) => {
          console.log(response);
          if (response.status === 204) {
            this.setState({
              successful: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.setState({
              error: true,
              errorMessage: formatMessage(messages.error3),
            });
          } else {
            this.setState({
              error: true,
              errorMessage: formatMessage(messages.error4),
            });
          }
        }
      );
    }
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    const FirstPasswordErrorMessage = () => {
      if (this.state.firstPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.firstPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const SecondPasswordErrorMessage = () => {
      if (this.state.secondPassword.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.secondPassword.errorMessage}
          </p>
        );
      }
      return null;
    };

    const ApiErrorMessage = () => {
      if (this.state.error && this.state.errorMessage.length > 0) {
        return (
          <p className="login__form-errors--primary-red">
            {this.state.errorMessage}
          </p>
        );
      }
      return null;
    };

    if (this.state.path) {
      return <Redirect to={this.state.path} />;
    }
    if (this.state.successful) {
      return (
        <React.Fragment>
          <article className="login">
            <div className="login__wrap">
              <div className="confirmation-container">
                <h1 className="login__header">
                  <FormattedMessage
                    id="ChangePasswordPage.successHeader"
                    defaultMessage="Success!"
                  />
                </h1>
                <p className="confirmation-description">
                  <FormattedMessage
                    id="ChangePasswordPage.successMessage"
                    defaultMessage="Your password has been changed! Now please"
                  />
                  {''}
                  <a href="" className="login__link" onClick={this.login}>
                    <FormattedMessage
                      id="ChangePasswordPage.loginUrl"
                      defaultMessage="login"
                    />
                  </a>
                </p>
              </div>
            </div>
          </article>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <article className="login">
          <div className="login__wrap">
            <h2 className="login__header">
              <FormattedMessage
                id="ChangePasswordPage.enterPassword"
                defaultMessage="Enter your new password"
              />
            </h2>
            <form className="login__form" onSubmit={this._formSubmit}>
              <section className="login__form-group">
                <input
                  onChange={this._handleFirstPasswordChange}
                  type="password"
                  name="password"
                  placeholder={formatMessage(messages.passwordPlaceholder)}
                  className="login__input-password"
                />
              </section>

              <section className="login__form-group">
                <input
                  onChange={this._handleSecondPasswordChange}
                  type="password"
                  name="password_confirmation"
                  placeholder={formatMessage(
                    messages.confirmPasswordPlaceholder
                  )}
                  className="login__input-password"
                />
              </section>

              <section className="login__form-action">
                <button
                  className="login__form-submit button button-primary"
                  type="submit"
                >
                  <FormattedMessage
                    id="ChangePasswordPage.submitBtn"
                    defaultMessage="Submit"
                  />
                </button>
              </section>

              <section className="login__form-errors">
                <FirstPasswordErrorMessage />
                <SecondPasswordErrorMessage />
                <ApiErrorMessage />
              </section>
            </form>
          </div>
        </article>
      </React.Fragment>
    );
  }
}

const ChangePasswordPage = injectIntl(ChangePasswordPageI18n);

export { ChangePasswordPage };
