import axios from 'axios';
import { default as teamCalls } from 'helpers/api-calls/team-calls';
import { default as actions } from './actions';

function getCurrentUser(locationId, employeeId) {
  return function(dispatch) {
    dispatch(actions.requestUser(employeeId));

    teamCalls.getTeamMember(locationId, employeeId).then(
      (response) => {
        const { user_privilege } = response.data;
        const user = {
          ownerId: user_privilege.owner_id,
          firstName: user_privilege.first_name,
          lastName: user_privilege.last_name,
          position: user_privilege.position,
          isAdmin: user_privilege.is_admin,
          phone: user_privilege.phone_number,
          email: user_privilege.email,
          profilePicture: user_privilege.profile_image_thumb_url,
          badge_id: user_privilege.badge_id,
          department_ids: user_privilege.department_ids,
          skill_ids: user_privilege.user.skill_ids,
        };

        return dispatch(actions.receiveUser(user));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function updateUser(id, locationId, attributes) {
  return function(dispatch) {
    dispatch(actions.requestUserUpdate());

    teamCalls.updateTeamMember(id, locationId, attributes).then((response) => {
      const { user_privilege } = response.data;
      const user = {
        firstName: user_privilege.first_name,
        lastName: user_privilege.last_name,
        position: user_privilege.position,
        isAdmin: user_privilege.is_admin,
        phone: user_privilege.phone_number,
        email: user_privilege.email,
        profilePicture: user_privilege.profile_image_thumb_url,
        badge_id: user_privilege.badge_id,
      };

      dispatch(actions.updateUser(user));
    });
  };
}

function updateCurrentEmployeePrivilege(id, ownerId, attributes) {
  // check whether or not to call apis
  const apisToCall = [];
  const attributesForUserPosition = {
    position: attributes.position,
  };
  const attributesForUserBadge = {
    badge_id: attributes.badge_id,
  };

  const attributesForUserPositionAndBadge = {
    position: attributes.position,
    badge_id: attributes.badge_id,
  };

  const attributesForUser = {
    first_name: attributes.first_name,
    last_name: attributes.last_name,
  };

  if (attributes.position !== null && attributes.badge_id === null)
    apisToCall.push(
      teamCalls.putUserPrivilege.apply(this, [id, attributesForUserPosition])
    );
  if (attributes.badge_id !== null && attributes.position === null)
    apisToCall.push(
      teamCalls.putUserPrivilege.apply(this, [id, attributesForUserBadge])
    );
  if(attributes.position !== null && attributes.badge_id !== null) 
    apisToCall.push(
      teamCalls.putUserPrivilege.apply(this, [id, attributesForUserPositionAndBadge])
    );
  if (attributes.first_name || attributes.last_name)
    apisToCall.push(
      teamCalls.putUserAttributes.apply(this, [ownerId, attributesForUser])
    );

  return function(dispatch) {
    axios.all(apisToCall).then((results) => {
      const user = results.reduce((obj, element) => {
        if (element.data.user) {
          obj.firstName = element.data.user.first_name;
          obj.lastName = element.data.user.last_name;
        }

        if (element.data.user_privilege) {
          obj.position = element.data.user_privilege.position;
          obj.isAdmin = element.data.user_privilege.is_admin;
          obj.phone = element.data.user_privilege.phone_number;
          obj.email = element.data.user_privilege.email;
          obj.profilePicture =
            element.data.user_privilege.profile_image_thumb_url;
          obj.badge_id = element.data.user_privilege.badge_id;
        }

        return obj;
      }, {});

      dispatch(actions.updateUser(user));
    });
  };
}

function updateUserAvatar(id, ownerId) {
  return function(dispatch) {
    teamCalls.removeUserAvatar(ownerId).then((results) => { 
      if(results.ok) {
        const user = {
          profilePicture: null,
        };
        dispatch(actions.updateUser(user));
      }
    }).catch((error) => {
      console.log(error);
    });
  }  
}

function resetCurrentUser() {
  return function(dispatch) {
    const user = {
      firstName: null,
      lastName: null,
      position: null,
      isAdmin: null,
      phone: null,
      email: null,
      profilePicture: null,
      badge_id: null,
    };

    dispatch(actions.updateUser(user));
  };
}

export default {
  updateUser,
  getCurrentUser,
  resetCurrentUser,
  updateCurrentEmployeePrivilege,
  updateUserAvatar,
};
