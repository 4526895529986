import { connect } from 'react-redux';

import { teamActions, teamSelectors, teamOperations } from '../../duck';
import SelectCellComponent from './SelectCellComponent';

const mapStateToProps = (
  {
    teamReducer: {
      visibleMembers,
      filter: { activeFilter },
    },
  },
  { userPrivilegeId }
) => ({
  selected: teamSelectors
    .selectedUsers(visibleMembers)
    .some((user) => user.id === userPrivilegeId),
  activeFilter,
  selectedMembersCount: visibleMembers.selectedUsers.length,
});

const mapDispatchToProps = (dispatch) => ({
  handleSelect: (userPrivilegeId, selected, selectedAction) => {
    if (selected) {
      dispatch(teamActions.deselectMember(userPrivilegeId));
    } else {
      dispatch(teamActions.updateSelectedAction(selectedAction));
      dispatch(teamActions.updateVisibility(true));
      dispatch(teamActions.selectMember(userPrivilegeId));
    }
  },
  cancelBulk: () => {
    dispatch(teamOperations.cancelBottomSheet());
  },
});

const SelectCellContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCellComponent);

export default SelectCellContainer;
