import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

import TimePicker from '../TimePicker/TimePicker';
import { DataNames } from '../../constants';
import './DateTimePicker.scss';

class DateTimePicker extends React.Component {
  DatePicker = React.createRef();

  yesterday = Datetime.moment().subtract(1, 'day');

  _validDate = (current) => current.isAfter(this.yesterday);

  render() {
    const { _updateState, _toggleDatePicker, date, dates, locale } = this.props;
    return (
      <div className="dt-picker">
        <Datetime
          className="dt-picker__date-picker"
          closeOnTab={false}
          input={false}
          isValidDate={this._validDate}
          onChange={(day) => _updateState(DataNames.DATE, day)}
          timeFormat={false}
          value={date}
          locale={locale}
        />
        <div className="dt-picker__time-picker">
          <TimePicker
            _updateState={_updateState}
            _toggleDatePicker={_toggleDatePicker}
            date={date}
            dates={dates}
            locale={locale}
          />
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  _toggleDatePicker: PropTypes.func.isRequired,
  _updateState: PropTypes.func.isRequired,
  date: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
};

export default DateTimePicker;
