import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import CoverRequestString from 'components/GeneralComponents/CoverRequestString';

import { formatManagementTimeForFeed, formatManagementTimeForFeedNew, } from 'helpers/date-time';

import './ShiftManagedRow.scss';

class ShiftManagedRow extends React.Component {
  state = {
    debug: false,

    timer: null,
    managedTime: this.props.managedTime,
    formattedManagementTime: formatManagementTimeForFeedNew(
      this.props.managedTime,
      this.props.intl.formatMessage
    ).text,
  };

  componentDidMount() {
    const {
      managedTime,
      intl: { formatMessage },
      id,
    } = this.props;
    let timer = setInterval(this.tick, 1000);
    this.setState({
      timer,
      formattedManagementTime: formatManagementTimeForFeedNew(
        managedTime,
        formatMessage
      ).text,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  tick = () => {
    const {
      managedTime,
      intl: { formatMessage },
    } = this.props;
    const managedTimeObject = formatManagementTimeForFeedNew(
      managedTime,
      formatMessage
    );

    if (!managedTimeObject.needed) {
      clearInterval(this.state.timer);
    }

    this.setState({ formattedManagementTime: managedTimeObject.text });
  };

  _renderTradeStatus = () => {
    const { approver, tradeStatus } = this.props;
    const approverName = approver ? `${approver.first_name} ${approver.last_name}`: '';

    if (tradeStatus === 'approved') {
      return (
        <FormattedMessage
          id="ShiftManagedRow.approved"
          defaultMessage="Approved by {approverName}"
          values={{
            approverName,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="ShiftManagedRow.denied"
        defaultMessage="Denied by {approverName}"
        values={{
          approverName,
        }}
      />
    );
  };

  render() {
    const { id, owner, coverer, startAt, endAt, type, managedTime } = this.props;
    const { debug, } = this.state;

    const myManagedTime = moment(managedTime);
    const debugInfo = `id: ${id}`;

    return (
      <article className="shift-request shift-request--managed">
        {debug && <label>{debugInfo}</label>}
        <h5 className="shift-request--outcome">
          {this._renderTradeStatus()}
          <span style={{ float: 'right' }}>
            {this.state.formattedManagementTime}
          {/*myManagedTime.format('YYYY-MM-DD hh:mm A')*/}
          </span>
        </h5>
        <span className="shift-request--timestamp" />
        <section className="shift-request--description">
          <p className="shift-request--description-text">
            <CoverRequestString
              owner={owner}
              type={type}
              coverer={coverer}
              startAt={startAt}
              endAt={endAt}
            />
          </p>
        </section>
      </article>
    );
  }
}

export default injectIntl(ShiftManagedRow);

const defaultProfile = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
}).isRequired;

ShiftManagedRow.defaultProps = {
  reason: '',
};

const intlShape = PropTypes.shape({ formatMessage: PropTypes.func.isRequired });
ShiftManagedRow.propTypes = {
  id: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  owner: defaultProfile,
  coverer: defaultProfile,
  approver: defaultProfile,
  reason: PropTypes.string,
  tradeStatus: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
};
