import postsSlice from './posts';
import commentsSlice from './comments';
import activeSlice from './active';
import readReceiptsSlice from './read-receipts';

export default {
  postsSlice,
  commentsSlice,
  activeSlice,
  readReceiptsSlice,
};
