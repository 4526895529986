import React from 'react';
import PropTypes from 'prop-types';

class HoneyPotInput extends React.Component {
  input = React.createRef();

  state = {
    valid: true,
    cssInput: '',
    jsInput: '',
  };

  componentDidMount() {
    this.input.style.position = 'absolute';
    this.input.style.left = '-999999px';
    this.input.style.height = '0';
    this.input.style.padding = '0';
  }

  checkValidity = () => {
    var { handleChange } = this.props;
    var { cssInput, jsInput } = this.state;

    this.setState(
      {
        valid: cssInput.length === 0 && jsInput === 0,
      },
      handleChange(cssInput.length === 0 && jsInput === 0)
    );
  };

  handleCssInputChange = (e) => {
    this.setState(
      {
        cssInput: e.target.value,
      },
      this.checkValidity()
    );
  };

  handleJsInputChange = (e) => {
    this.setState(
      {
        jsInput: e.target.value,
      },
      this.checkValidity()
    );
  };

  render() {
    return (
      <React.Fragment>
        <input
          type="text"
          autoComplete="nope"
          tabIndex="-1"
          name="phone_from_home"
          onChange={this.handleCssInputChange}
          style={{
            position: 'absolute',
            left: '-999999px',
            height: '0',
            padding: '0',
          }}
        />

        <input
          type="text"
          tabIndex="-1"
          autoComplete="nope"
          name="phone_from_ip"
          onChange={this.handleJsInputChange}
          ref={(input) => (this.input = input)}
        />
      </React.Fragment>
    );
  }
}

export default HoneyPotInput;

HoneyPotInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
};
