import React from 'react';
import styled from 'styled-components';

import elements from '../../elements';

const Wrap = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border};
  display: flex;
  min-height: 3.5rem;
`;

const Header = ({ children, icon }) => (
  <Wrap>
    <elements.Heading>{children}</elements.Heading>
    {icon}
  </Wrap>
);

Header.defaultProps = {
  children: [],
  icon: null,
};

export default Header;
