import { createSlice } from '@reduxjs/toolkit';

const managedRTOs = createSlice({
  name: 'managedRTO',
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    hydrateMamagedRequestTimeOffs: (state, action) => {
      //state.data = action.payload.filter((rto) => rto.status !== 'requested');
      state.data = action.payload;
      state.loading = false;
      //console.log(`hydrateMamagedRequestTimeOffs: ${action.payload.length}`);
    },
    appendManagedRequestTimeOffs: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
      //console.log(`appendManagedRequestTimeOffs: ${action.payload.length}`);
    },
    createManagedRTO: (state, action) => {
      state.data.unshift(action.payload);
    },
  },
});

export default managedRTOs;