import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './styled';



class ProgressBar extends React.Component {
	constructor(props) {
    super(props);

    this.state ={
      selectedUsers: [],
      selectedAction: '',
      remainingUsers: [],
      bulkUsers: 0,
      bulkInProgress: false,
      selectedAllUsers: false,
      processedUsers: [],
    };
    this.wrapRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount () {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount () {
  	this._isMounted = false;
  }

  render () {
  	const { percentage, lastAction, } = this.props;
  	const displayText = `${Math.round(percentage)}%`;

  	const pleaseWait = (
	      <FormattedMessage
	        id="ProgressBar.pleaseWait"
	        defaultMessage="Please wait..."
	      />
	    );
  	const processing = (
	      <FormattedMessage
	        id="ProgressBar.processing"
	        defaultMessage="We are processing selected users"
	      />
	    );
    
  	return (
  		<styled.Wrap ref={this.wrapRef}>
  			<styled.PleaseWait>{pleaseWait}</styled.PleaseWait>
  			<styled.Processing>{processing}</styled.Processing>
  			<styled.Percent>{displayText}</styled.Percent>
  			<styled.Frame>
  				<styled.ProgressBar style={{width: percentage/100 * 400}}/>
  			</styled.Frame>
  		</styled.Wrap>
  	);
  }

}

ProgressBar.propTypes = {
  //visibleMembers: teamShapes.usersShape.isRequired,
  //locationId: PropTypes.string.isRequired,
  
};

//export default ProgressBar;
export default injectIntl(ProgressBar);