import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthenticationContainer from 'containers/AuthenticationContainer';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import { createBrowserHistory } from 'history';

import { SignupConfirmationPage } from 'pages/SignupConfirmationPage';
import { SignupPage } from 'pages/SignUp';
import { LoginPage } from 'pages/Login';
import { ForgotPasswordPage } from 'pages/ForgotPassword';
import { ChangePasswordPage } from 'pages/ChangePassword';
import TeamContainer from 'pages/Team/TeamContainer';
import TeamProfileContainer from 'pages/TeamProfile/TeamProfileContainer';
import TeamFeed from 'pages/TeamFeed';
import DashboardContainer from 'pages/Dashboard/DashboardContainer';
import CalendarContainer from 'pages/Calendar/CalendarContainer';
import FooterContainer from 'containers/FooterContainer';
import PostShiftContainer from 'pages/PostShift/PostShiftContainer';
import { AddLocationPageCode } from 'pages/AddLocation/AddLocationPageCode';
import { AdminClaimPage } from 'pages/AdminClaim/AdminClaimPage';
import SchedulerContainer from 'pages/Scheduler/SchedulerContainer';
import ShiftsContainer from 'pages/Shift/ShiftContainer';
import UserManagementContainer from 'pages/UserManagement/UserManagementContainer';
import { MainTheme, GlobalStyle } from '@shyft/acorns';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import 'stylesheets/all.scss';
import './App.scss';
import Navbar from 'components/Navbar/NavbarContainer';
import NavPanel from 'components/NavPanel/NavPanelContainer';
import IntlContainer from './IntlContainer';
import { PUBNUB_SUBSCRIBE_KEY } from '../constants';

//const pubNubClient = new PubNub({ subscribeKey: PUBNUB_SUBSCRIBE_KEY });
const pubNubClient = new PubNub({ subscribeKey: PUBNUB_SUBSCRIBE_KEY, restore: true, keepAlive: true });
const showNavBar = true;
const history = createBrowserHistory();
const mainBodyClassName = showNavBar ? 'main-body' : 'main-body-row';
const App = ({ store }) => (
  <Provider store={store}>
    <PubNubProvider client={pubNubClient}>
      <ThemeProvider theme={MainTheme}>
        <IntlContainer>
          <Router>
            <main className={mainBodyClassName}>
              <GlobalStyle />
              { showNavBar ? <Navbar history={history} /> :
              <NavPanel history={history} />}
              <Switch>
                <Route path="/sign-up" component={SignupPage} />
                <Route
                  path="/users/confirmation"
                  component={SignupConfirmationPage}
                />
                <Route path="/login" component={LoginPage} />
                <Route path="/password-reset" component={ForgotPasswordPage} />
                <Route
                  path="/users/password/edit"
                  component={ChangePasswordPage}
                />
                <Route path="/add-location" component={AddLocationPageCode} />
                <Route
                  path="/admin_activation/:activation_code"
                  component={AdminClaimPage}
                />
                <AuthenticationContainer
                  path="/"
                  exact
                  component={DashboardContainer}
                  routeProps={{
                    exact: true,
                    path: '/',
                    component: DashboardContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/index"
                  exact
                  component={DashboardContainer}
                  routeProps={{
                    exact: true,
                    path: '/index',
                    component: DashboardContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/calendar"
                  exact
                  component={CalendarContainer}
                  routeProps={{
                    exact: true,
                    path: '/calendar',
                    component: CalendarContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/calendar/post"
                  exact
                  component={PostShiftContainer}
                  routeProps={{
                    exact: true,
                    path: '/calendar/post',
                    component: PostShiftContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/usermanagement"
                  exact
                  component={UserManagementContainer}
                  routeProps={{
                    exact: true,
                    path: '/usermanagement',
                    component: UserManagementContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/team"
                  exact
                  component={TeamContainer}
                  routeProps={{
                    exact: true,
                    path: '/team',
                    component: TeamContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/team/:id"
                  exact
                  component={TeamProfileContainer}
                  routeProps={{
                    exact: true,
                    path: '/team/:id',
                    component: TeamProfileContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/team-feed"
                  exact
                  component={TeamFeed}
                  routeProps={{
                    exact: true,
                    path: '/team-feed',
                    component: TeamFeed,
                  }}
                />
                <AuthenticationContainer
                  path="/scheduler"
                  exact
                  component={SchedulerContainer}
                  routeProps={{
                    exact: true,
                    path: '/scheduler',
                    component: SchedulerContainer,
                  }}
                />
                <AuthenticationContainer
                  path="/shift"
                  exact
                  component={ShiftsContainer}
                  routeProps={{
                    exact: true,
                    path: '/shift',
                    component: ShiftsContainer,
                  }}
                />
              </Switch>
              <FooterContainer />
            </main>
          </Router>
        </IntlContainer>
      </ThemeProvider>
    </PubNubProvider>
  </Provider>
);

export default App;
