import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  pendingShifts: slices.pendingShifts.reducer,
  managedShifts: slices.managedShifts.reducer,
  requestTimeOffs: slices.requestTimeOffs.reducer,
  managedRTOs: slices.managedRTOs.reducer,
  userPrivileges: slices.userPrivileges.reducer,
  skills: slices.skills.reducer,
};

const reducer = combineReducers({
  pendingShifts: reducers.pendingShifts,
  managedShifts: reducers.managedShifts,
  requestTimeOffs: reducers.requestTimeOffs,
  managedRTOs: reducers.managedRTOs,
  userPrivileges: reducers.userPrivileges,
  skills: reducers.skills,
});

export default reducer;
