import React from 'react';
import PropTypes from 'prop-types';

import {
  AdminClaimComponent,
  AdminPendingComponent,
} from './AdminClaimComponent';
import { ClaimAdministrativePrivileges } from 'helpers/api-calls';

/**
 * This encapsulates both the admin claim component along with
 * the pending admin claim component. Also states.
 */
class AdminClaimContainer extends React.Component {
  state = {
    pending: false,
  };

  _claimAdministrativePrivileges = () => {
    const { config } = this.props;
    const { current_user_privilege } = config;


    ClaimAdministrativePrivileges(
      current_user_privilege.location.id,
      current_user_privilege.email
    ).then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        this.setState({
          pending: true,
        });
      }
    });
  };

  render() {
    // switch statement based on step of process
    const { config } = this.props;
    const { pending } = this.state;
    const { current_user_privilege } = config;

    if (current_user_privilege.admin_claim_pending || pending) {
      return <AdminPendingComponent />;
    } else {
      return (
        <AdminClaimComponent
          userPrivilege={current_user_privilege}
          claim={this._claimAdministrativePrivileges}
        />
      );
    }
  }
}

export { AdminClaimContainer };
