import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';


import constants from '../../../constants';
import styled from './styled';

class UserCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: false,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { user } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { user } = this.props;
    const { debug } = this.state;
    const { statusTypes } = constants;

    const userImageUrl = user && user.profile_image_thumb_url ? 
            user.profile_image_thumb_url : '/icons/default-profile-thumb.svg';
    const userName = user ? `${user.first_name} ${user.last_name}` : '';
    const approvedBadge = '/icons/verified-badge-icon.svg';
    const placeholder = '';

    const allowProfile =
    user.status !== statusTypes.REMOVED &&
    user.status !== statusTypes.REJECTED &&
    user.status !== statusTypes.WITHDRAWN &&
    user.status !== statusTypes.BLOCKED;
 
    let  bottomLine = null;    
    if(user) {
      if(user.position && !user.badge_id) {
        bottomLine = (<styled.BottomLineTextOneItem>{user.position}</styled.BottomLineTextOneItem>);
      }else if(!user.position && user.badge_id) {
        bottomLine = (<styled.BottomLineTextOneItem>{user.badge_id}</styled.BottomLineTextOneItem>);
      }else if(!user.position && !user.badge_id){
        bottomLine = (<styled.BottomLineTextOneItem>{placeholder}</styled.BottomLineTextOneItem>)  
      }else {
         bottomLine = (    
          <styled.BottomLineWrap>
            <styled.BottomLineText>{user.position}</styled.BottomLineText>
            <styled.Dot/>
            <styled.BottomLineText>{user.badge_id}</styled.BottomLineText>
          </styled.BottomLineWrap>);
      }
    }

  	return (
  		<styled.TopWrap>
        <styled.OwnerAvatar src={userImageUrl}/>
        <styled.OwnerInfoWrap>
          <styled.OwnerInfoTopLine>
          {allowProfile ? <styled.ProfileLink to={`/team/${user.id}`}>{userName}</styled.ProfileLink> : <styled.UserName>{userName}</styled.UserName>}
          {user && user.is_admin && <styled.ApprovedBadge src={approvedBadge}/>}
          </styled.OwnerInfoTopLine>
          {debug ? user.owner_id : bottomLine}
        </styled.OwnerInfoWrap>
      </styled.TopWrap>
  	);
  }
}


UserCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(UserCell);