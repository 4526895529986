import { createSlice } from '@reduxjs/toolkit';

import { keyBy, partition } from 'lodash';

const readReceiptsSlice = createSlice({
  name: 'readReceipts',
  initialState: {
    read: [],
    unread: [],
    loading: true,
  },
  reducers: {
    requestReadReceipts: (state) => {
      state.loading = true;
    },
    updateReadReceipts: (state, action) => {
      const { read_receipts, channel_members } = action.payload;
      const byUser = keyBy(read_receipts, (r) => r.user_id);
      const [read, unread] = partition(channel_members, (u) => byUser[u.id]);

      state.read = read;
      state.unread = unread;
      state.loading = false;
    },
    clearReadReceipts: (state) => {
      state.read = [];
      state.unread = [];
    },
  },
});

export default readReceiptsSlice;
