import React from 'react';
import PropTypes from 'prop-types';

class EmailConfirmation extends React.Component {
  password = null;

  setPasswordRef = (element) => {
    this.password = element;
  };

  componentDidMount() {
    this.password.focus();
  }

  render() {
    return (
      <React.Fragment>
        <section className="login__form-group">
          <input
            onChange={this.props.emailChange}
            defaultValue={this.props.value}
            name="email"
            placeholder="Email"
            className="login__input-email"
            disabled
          />
        </section>

        <section className="login__form-group">
          <input
            ref={this.setPasswordRef}
            onChange={this.props.passwordChange}
            name="password"
            type="password"
            placeholder="Password"
            className="login__input-email"
          />
        </section>
      </React.Fragment>
    );
  }
}

export default EmailConfirmation;

EmailConfirmation.propTypes = {
  value: PropTypes.string.isRequired,
  validity: PropTypes.bool.isRequired,
  inputChange: PropTypes.func.isRequired,
};
