import React from 'react';
import { FormattedMessage } from 'react-intl';

const PostShiftHeader = ({ type, posted, posting, previewing }) => {
  if (posted) {
    return (
      <FormattedMessage
        id="PostShiftHeader.postConfirmation"
        defaultMessage="Post Confirmation"
      />
    );
  }

  if (posting) {
    return (
      <FormattedMessage
        id="PostShiftHeader.postInProgress"
        defaultMessage="Post in Progress"
      />
    );
  }

  if (previewing) {
    return (
      <FormattedMessage
        id="PostShiftHeader.previewPost"
        defaultMessage="Preview Post"
      />
    );
  }

  if (type === 'open_shift') {
    return (
      <FormattedMessage
        id="PostShiftHeader.postOpenShift"
        defaultMessage="Post Open Shift"
      />
    );
  }

  if (type === 'shift_vto') {
    return (
      <FormattedMessage
        id="PostShiftHeader.postVTO"
        defaultMessage="Post Voluntary Time Off"
      />
    );
  }

  return null;
};

export default PostShiftHeader;
