import { createSlice } from '@reduxjs/toolkit';

const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    data: [],
    loading: true,
    page: 1,
    totalPages: 1,
  },
  reducers: {
    hydrateLocationChannels: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    requestChannels: (state, action) => {
      state.loading = true;
    },
    /*
    appendChannel: (state, action) => {
      state.data = state.data.concat(action.payload);
      state.loading = false;
    },
    removeChannel: (state, action) => {
      const idx = state.data.findIndex((channel) =>  channel.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        newData.splice(idx, 1);
        state.data = newData;
      }
    },
    */
  },
});

export default channelsSlice;