import React from 'react';
import { connect } from 'react-redux';

import ReadReceiptsComponent from './ReadReceiptsComponent';

import {
  teamFeedActions,
  teamFeedOperations,
  teamFeedSelectors,
} from '../../duck';


const mapStateToProps = ({ teamFeedReducer }) => ({
  	receipts: teamFeedReducer.readReceipts,
  	activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
});

const mapDispatchToProps = (dispatch) => {
	const resetActiveSidePane = () => {
  		dispatch(teamFeedActions.updateActiveSidePane(''));
  	};
  	
  	return {
  		resetActiveSidePane,
  	}
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadReceiptsComponent);
