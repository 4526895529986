import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

//import Shiffy from './assets/shiffy-business.svg';
import Shiffy from './assets/Master-Shyft-Logo.svg';

import './AdminClaimComponent.scss';

const AdminClaimComponent = ({ userPrivilege, claim }) => (
  <article className="general-modal">
    <header className="general-modal__header">
      {/*<span className="general-modal__header-logo">
        <img src={Shiffy} className="general-modal__shiffy" />
      </span>*/}
      <img src={Shiffy} className="general-modal__logo" />
      <h3 className="general-modal__header-text">
        <FormattedMessage
          id="AdminClaimComponent.almostThere"
          defaultMessage="You're almost there!"
        />
      </h3>
    </header>

    <section className="general-modal__body">
      <p className="general-modal__description">
        <FormattedMessage
          id="AdminClaimComponent.adminPriv"
          defaultMessage="In order to access these features, you need to claim admin privileges."
        />
        <br />
        <FormattedMessage
          id="AdminClaimComponent.verifyEmail"
          defaultMessage="Please verify that the email below is correct."
        />
      </p>
      <br />
      <h5 className="general-modal__description">{userPrivilege.email}</h5>
    </section>

    <footer className="general-modal__action-section">
      <button
        type="button"
        onClick={claim}
        className="button button-primary general-modal__action-button"
      >
        <FormattedMessage
          id="AdminClaimComponent.continue"
          defaultMessage="Continue"
        />
      </button>
    </footer>
  </article>
);

export { AdminClaimComponent };
