import React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { default as Styled } from './styled';
import { FormattedMessage } from 'react-intl';

const TeamProfileDetailsComponent = ({ user }) => {
  const formatPhoneNumber = (number) => {
    if (number.substring(0, 1) === '1') {
      const phoneNumber = parsePhoneNumberFromString(`+${number}`);

      return `+1 ${phoneNumber.format('NATIONAL')}`;
    }

    return number;
  };

  return (
    <Styled.DetailsWrap>
      <Styled.DetailsRow>
        <Styled.DetailsHeader>
          <FormattedMessage
            id="TeamProfileComponent.contactInfo"
            defaultMessage="Contact Info"
          />
        </Styled.DetailsHeader>
      </Styled.DetailsRow>

      <Styled.DetailsRow>
        <Styled.Detail>
          {user.attributes && user.attributes.email
            ? user.attributes.email
            : ''}
        </Styled.Detail>
        <Styled.Detail>
          {user.attributes && user.attributes.phone
            ? formatPhoneNumber(user.attributes.phone)
            : ''}
        </Styled.Detail>
      </Styled.DetailsRow>
    </Styled.DetailsWrap>
  );
};

export default TeamProfileDetailsComponent;
