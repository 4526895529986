import axios from 'axios';
import feedCalls from 'helpers/api-calls/feed-calls';
import actions from './actions';
import constants from './constants';
import { GetLocationChannels, } from 'helpers/api-calls/user-calls';
import { appActions } from 'App/duck';


const { params } = constants;

const fetchPosts = (channel_id) => (dispatch) => {
  dispatch(actions.requestPosts(1));

  return feedCalls.getPosts(params.getPosts(channel_id, 1)).then((response) => {
    const {
      data: { posts, meta },
    } = response;

    dispatch(
      actions.hydratePosts({
        data: posts,
        total_pages: meta.pagination.total_pages,
      })
    );
  });
};

const fetchMorePosts = (channel_id, page) => (dispatch) => {
  dispatch(actions.requestPosts(page));

  return feedCalls
    .getPosts(params.getPosts(channel_id, page))
    .then((response) => {
      dispatch(actions.updatePosts(response.data.posts));
    });
};

const fetchReadReceipts = (post_id, channel_id) => (dispatch) => {
  dispatch(actions.requestReadReceipts());

  axios
    .all([
      feedCalls.getReadReceipts(post_id),
      feedCalls.getChannelMembers(channel_id),
    ])
    .then(
      axios.spread((receipts, members) => {
        dispatch(
          actions.updateReadReceipts({
            read_receipts: receipts.data.receipts,
            channel_members: members.data.users,
          })
        );
      })
    );
};

const fetchSubscriptions = (channel_id) => (dispatch) => {
  feedCalls.getSubscriptions(channel_id).then((response) =>{
    dispatch(actions.updateActiveChannelAdmin(response.data.subscriptions[0]));
    dispatch(actions.updateActiveChannelSubscriptions(response.data.subscriptions[0]));
  }).catch((error) =>{
    console.log(error);
  })
};

const createPost = (post) => (dispatch) =>
  feedCalls.createPost(post).then((response) => {
    dispatch(actions.createPost(response.data.post));
  });

const createComment = (content, source_id) => (dispatch) =>
  feedCalls.createComment(content, source_id).then((response) => {
    dispatch(actions.createComment(response.comment));
    dispatch(actions.incrementPostCommentCount({postId: source_id, commentId: 0}));
  })
  .catch((error) =>{
      console.log(error);
  });

const deletePost = (postId) => (dispatch) =>
  feedCalls
    .deletePost(postId)
    .then(() => {
      dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
      dispatch(actions.deletePost(postId));
      dispatch(actions.clearActiveDelete());
      dispatch(actions.updateActiveSidePane(''));
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;

      if (status === 404) {
        // Silently succeed deleting already deleted post
        dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
        dispatch(actions.deletePost(postId));
        dispatch(actions.clearActiveDelete());
        dispatch(actions.updateActiveSidePane(''));
      } else {
        dispatch(
          actions.updateActiveError({
            code: error.response.status,
            action: actions.deletePost.toString(),
          })
        );
      }
    });

const updateDeletePost = (postId) => (dispatch) => {
  //dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
  dispatch(actions.deletePost(postId));
  dispatch(actions.clearActiveDelete());
  //dispatch(actions.updateActiveSidePane(''));
};

const updateDeleteActivePost = (postId) => (dispatch) => {
  dispatch(actions.updateActivePost({id: 0, channel_name: ''}));
  dispatch(actions.deletePost(postId));
  dispatch(actions.clearActiveDelete());
  dispatch(actions.updateActiveSidePane(''));
};


const pinPost = (post_id) => (dispatch) => {
  return feedCalls.pinPost(post_id).then(() => {
    dispatch(actions.updatePost({ id: post_id, pinned: true }));
  });
};

const updatePinPost = (post_id) => (dispatch) => {
  dispatch(actions.updatePost({ id: post_id, pinned: true }));
};

const unpinPost = (post_id) => (dispatch) => {
  return feedCalls.unpinPost(post_id).then(() => {
    dispatch(actions.updatePost({ id: post_id, pinned: false }));
  });
};

const updateUnpinPost = (post_id) => (dispatch) => {
  dispatch(actions.updatePost({ id: post_id, pinned: false })); 
};

const likePost = (post_id) => (dispatch) => {
  return feedCalls.likePost(post_id).then(() => {
    dispatch(actions.incrementLikeCount(post_id));
  }).catch((error) =>{
    console.log(`likePost: error: ${error}`);
  });
};

const updateLikePost = (post_id) => (dispatch) =>{
  dispatch(actions.incrementLikeCount(post_id));
}

const unlikePost = (post_id) => (dispatch) => {
  return feedCalls.unlikePost(post_id).then(() => {
    dispatch(actions.decrementLikeCount(post_id));
  }).catch((error) =>{
     console.log(`unlikePost: error: ${error}`);
  });
};
const updateUnlikePost = (post_id) => (dispatch) => {
  dispatch(actions.decrementLikeCount(post_id));
};
const fetchComments = (post_id) => (dispatch) => {
  dispatch(actions.requestComments(1));

  return feedCalls
    .getComments(params.getComments(post_id, 1))
    .then((response) => {
      const {
        data: { comments, meta },
      } = response;

      dispatch(
        actions.hydrateComments({
          data: comments,
          total_pages: meta.pagination.total_pages,
        })
      );
    });
};

const updateAddComment = (post_id, comment_id) => (dispatch) => {
  dispatch(actions.incrementPostCommentCount({postId: post_id, commentId: comment_id}));
  dispatch(actions.updateAddedComments(comment_id));
};

const updateDeleteComment = (post_id, comment_id) => (dispatch) => {
  dispatch(actions.updateActiveComment(0));
  dispatch(actions.deleteComment(comment_id));
  dispatch(actions.decrementPostCommentCount({postId: post_id, commentId: comment_id}));
  dispatch(actions.updateDeletedComments(comment_id));
  dispatch(actions.clearActiveDelete());
};
const fetchMoreComments = (post_id, page) => (dispatch) => {
  dispatch(actions.requestComments(page));

  return feedCalls
    .getComments(params.getComments(post_id, page))
    .then((response) => {
      dispatch(actions.updateComments(response.data.comments));
    });
};

const deleteComment = (comment_id, post) => (dispatch) =>
  feedCalls
    .deleteComment(comment_id)
    .then(() => {
      
      dispatch(actions.updateActiveComment(0));
      dispatch(actions.deleteComment(comment_id));
      //the line below is copied from TeamFeedContainer.js line 26
      dispatch(actions.decrementPostCommentCount({postId: post.id, commentId: comment_id}));
      dispatch(actions.updateDeletedComments(comment_id));
      dispatch(actions.clearActiveDelete());
      //dispatch(actions.updateActiveSidePane(''));

      //Don't use this function here, it is for update comments sync with mobile
      //updateDeleteComment(post.id, comment_id);
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;

      if (status === 404) {
        // Silently succeed deleting already deleted comment
        console.log(' error 404');
        dispatch(actions.updateActiveComment(0));
        dispatch(actions.deleteComment(comment_id));
        dispatch(actions.clearActiveDelete());
        //dispatch(actions.updateActiveSidePane(''));
      } else {
        dispatch(
          actions.updateActiveError({
            code: error.response.status,
            action: actions.deleteComment.toString(),
          })
        );
      }
    });

const getLocationChannels = (locationId) => (dispatch) => {
  GetLocationChannels(locationId).then(({data}) =>{
    dispatch(appActions.hydrateChannels(data.channels));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationChannels returns error = ${error}`;
    console.log(msg);
  });
};

export default {
  fetchPosts,
  fetchMorePosts,
  deletePost,
  updateDeletePost,
  updateDeleteActivePost,
  fetchComments,
  fetchMoreComments,
  deleteComment,
  likePost,
  updateLikePost,
  unlikePost,
  updateUnlikePost,
  pinPost,
  updatePinPost,
  unpinPost,
  updateUnpinPost,
  fetchReadReceipts,
  createPost,
  createComment,
  updateAddComment,
  updateDeleteComment,
  fetchSubscriptions,
  getLocationChannels,
};
