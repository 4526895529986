import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { formatForWeekPicker } from '../../../helpers/date-time';
import Icon from '../../GeneralComponents/Icon/index';

import './WeekPickerComponent.scss';

const WeekPickerComponent = ({
  back,
  forward,
  currentDateRangeEnd,
  currentDateRangeStart,
}) => (
  <div className="week-picker">
    <span className="week-picker--left-arrow">
      <button
        type="button"
        className="week-picker--back-button week-picker--button"
        onClick={back}
      >
        <Icon type="backArrow" />
      </button>
    </span>
    <span className="week-picker--current-date-range">
      {`${formatForWeekPicker(
        moment(currentDateRangeStart),
        moment(currentDateRangeEnd)
      )}`}
    </span>
    <span className="week-picker--right-arrow">
      <button
        type="button"
        className="week-picker--forward-button week-picker--button"
        onClick={forward}
      >
        <Icon type="forwardArrow" />
      </button>
    </span>
  </div>
);

export default WeekPickerComponent;

WeekPickerComponent.propTypes = {
  back: PropTypes.func.isRequired,
  forward: PropTypes.func.isRequired,
};
