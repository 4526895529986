import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import elements from '../../elements';
import blocks from '../../blocks';
import styled from './styled';


class PinsComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={closePinPane: false,

    }
    this.handleClose = this.handleClose.bind(this);

    this.closePinPane = false;
  }

  handleClose(event) {
    const {resetActiveSidePane} = this.props;

    this.setState({closePinPane: false});
    this.closePinPane = true;
    resetActiveSidePane();
    event.preventDefault();
  }

  render () {
    const {pins, activeSidePane,} = this.props;

    if(this.closePinPane){
      this.closePinPane = false;
      return null;
    }

    return (
      <elements.ColumnWrap>
        <blocks.Header>
          <FormattedMessage id="Pins.headerLabel" defaultMessage="Pins" />
          <styled.CloseButton
            onClick={this.handleClose}>
            <FormattedMessage id="Pins.close" defaultMessage="X Close"/>
          </styled.CloseButton>
        </blocks.Header>
        <elements.ScrollWrap isLoading={pins.loading}>
          {pins.data.map((pin) => (
          <blocks.Post
            key={pin.id}
            clickPost={() => {}}
            clickComment={() => {}}
            clickDelete={() => {}}
            clickLike={() => {}}
            clickPin={undefined}
            active={false}
            data={{
              ...pin,
              post_type: -1,
              comments_count: 0,
              final_allow_delete: false,
              final_allow_like: false,
              final_allow_comment: false, // Fixed bug WS-1276
            }}
          />
        ))}
        {!pins.loading || <elements.Loading />}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );

  }
}

PinsComponent.propTypes = {
  pins: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(PinsComponent);

