import moment from 'moment';
import axios from 'axios';


import { GetLocationSchedules, GetShiftsInSchedule, } from 'helpers/api-calls/schedule-calls';
import { createApiDateFormat } from 'helpers/date-time';
import { LoadFirstPagePendingShifts, LoadMorePendingShifts, } from 'helpers/api-calls/shift-calls';

import actions from './actions';
import { appActions } from 'App/duck';

const fetchAllShifts = (location_id) => (dispatch) => {
  dispatch(actions.requestShifts(true));
  return LoadFirstPagePendingShifts(location_id).then(({ data }) => {
    dispatch(actions.hydrateShifts(data.schedule_elements));
  }).catch((error) => {
    console.log(error);
  });
};

const fetchScheduleShifts = (location_id, schedule_id) => (dispatch) => {
  dispatch(actions.requestShifts(true));
  return GetShiftsInSchedule(location_id, schedule_id).then(({ data }) => {
    dispatch(actions.hydrateScheduleShifts(data.schedule_elements));
  }).catch((error) => {
    console.log(error);
  });
};


export default {
  fetchAllShifts,
  fetchScheduleShifts,
};
