import styled from 'styled-components/macro';

import { rem } from 'polished';

const Name = styled.h4`
  font-size: ${rem('14px')};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 0;
  padding: 0;
`;

const LatestContent = styled.p`
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default {
  Name,
  LatestContent,
};
