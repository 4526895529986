import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ShiftRowComponent from './ShiftRowComponent';

import './ShiftTableComponent.scss';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`shyft-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

class ShiftTableComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      filesToUpload: [],
      upload: false,
    };


    this._isMounted = false;
  }

  componentDidMount() {
    const { locationId, getLocationSchedules,  getLocationActionJobs, loading, actionJobs, } = this.props;

    this._isMounted = true;
    if(locationId) {
      //getLocationSchedules(locationId);
      //getLocationActionJobs(locationId);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, schedules, loading, actionJobs, } = this.props;
    
  }

  createHeaders = () => {
    const myBox = <input type="checkbox"/>
    const employee = (<FormattedMessage
                id="TableComponent.employee"
                defaultMessage="Employee" />);
    const shiftDetails = (<FormattedMessage
                id="TableComponent.shiftDetails"
                defaultMessage="ShiftDetails" />);
    const status = (<FormattedMessage
                id="TableComponent.status"
                defaultMessage="Status" />);
    const date = (<FormattedMessage
                id="TableComponent.date"
                defaultMessage="Date" />);
    const time = (<FormattedMessage
                id="TableComponent.time"
                defaultMessage="Time" />);
    const addtional = (<FormattedMessage
                id="TableComponent.addtional"
                defaultMessage="Addtional" />);
    let headerItems = [myBox, employee, shiftDetails, status, date, time, addtional];
    
    const Headers = () => {
      const headers = headerItems.map((item, index) => {
        return (
          <th key={index} className="shift-table__header-title">
            {item}
          </th>
        );
      });
      return headers;
    };

    return (
      <thead>
        <tr className="shift-table__headers">
          <Headers />
        </tr>
      </thead>
    );
  };



  render() {
    const { shifts, loading, } = this.props;
    
    const shiftRows = shifts.map((shift) => (
        <ShiftRowComponent
          key={shift.id} 
          shift={shift}
        />
    ));
    
    return (
      <section className="shift-table__wrap">
        <LoadingLayer loading={loading} />
        <table className={`shift-table ${loading ? 'loading' : ''}`}>
          {this.createHeaders()}
          <tbody className="shift-table__body">
            {shiftRows}
          </tbody>
        </table>
      </section>
    );
  }
}

ShiftTableComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(ShiftTableComponent);
