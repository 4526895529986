import styled from 'styled-components/macro';
import { rem } from 'polished';
import { rgba } from 'polished';
import deleteIcon from '../../blocks/ActionButtons/assets/delete-icon.svg';
import attachIcon from '../../blocks/ActionButtons/assets/attach.svg';
import submitIcon from '../../blocks/ActionButtons/assets/send.svg';

const PostInputForm = styled.form`
  //width: 818px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  //padding-top: 5px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  border-left: none;
  border-right: none;
  position relative;


  /* this is working too
  ${({noAttached}) => noAttached && `
    min-height: 80px;
    max-height: 80px;
  `}
  */
   ${({notChrome}) => notChrome && `
    min-height: 80px;
    max-height: 80px;
  `}
  
  

   @media (max-width: 870px) {
    //border: 1px solid red;
    max-width: 500px;
  }
`;



const PostInput = styled.textarea`
  //max-width: 1000px;
  //width: 800px;
  //max-height: 200px;
  max-height: 40px;
  min-height: 40px;
  height: 40px;
  //line-height: 45px;
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  transition: all 0.25s;
  background-repeat: no-repeat;
  background-position: 8px 10px;
  padding-left: 12px;
  margin-right: 0.88rem;
  margin-left: 0.88rem;
  margin-bottom: 0px;
  overflow-y: hidden;
  border: none;
  resize: none;
  outline: none;
  font-size: 0.85rem;
  font-weight: 300;
  //font-size: ${rem('13px')};


  &:focus {
    //border-color: ${({ theme }) => theme.palette.primary[0]};
    border: none;
    resize: none;
    outline: none;
    //box-shadow: 0px 0px 6px
      //${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
    //transition: all 0.25s;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.label};
    font-size: 0.9rem;
    font-weight: 400;
    padding-top: 0px; // To aligh cursor and text
    //padding-top: 3px;//original
    //line-height: 60px;
  }

  @media (max-width: 870px) {
    max-width: 482px;
    //background-color: lightblue;
  }
`;

const PostButton = styled.button`
  font-size: 0.88rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary[0]};
  background-color: white;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border-radius: 6px;
  padding: 5px 8px;
  cursor: pointer;
  max-width: 100px;
  max-height: 30px;
  width: 100px;
  height: 30px;
  position: relative;
  //margin-bottom: 0.5rem;
  //margin-left: 5.0rem;


  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.palette.primary[0]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;
  
  const CheckBoxDiv = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 0px;
  margin-top: 2px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[1]};

   @media (max-width: 720px) {
    padding-bottom: 1.5rem;
  }
`;

  const Label = styled.label`
    width: 200px;
    height: 25px;
    position: relative;
    margin-top: 0.2rem;
    margin-left: 0.1rem;
    margin-bottom: 0;
    //border: 1px solid ${({ theme }) => theme.palette.primary[1]};
    font-size: 0.88rem;
    color: rgb(127,127,131);
    
  `;

  const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    transition: all 150ms;
    position: relative;
    //margin-top: 2px;
    margin-left: 20px;
    margin-right: 0.4rem;
    margin-bottom: 0;
    order: 0;
`;

  const ChooseFile = styled.input.attrs({type: 'file'})`
    display: none;
    //background-color: ${({ theme }) => theme.palette.primary[0]};
    //color: white;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    border-radius: 6px;
    margin-top: 0;
    margin-left: 200px;
    margin-bottom: 0.6rem;
    width: 615px;
    max-width: 615px;
    height: 28px;
    position: relative;
    order: 1;

    @media (max-width: 420px) {
      min-width: 200px;
    }
    
  `;

 const ChooseFileLabel = styled.label`
    display: inline-block;
    cursor: pointer;
    text-align: left;
    font-size: 0.88rem;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary[0]};
    //background-color: white;
    background-position: center;
    background-image: url("${attachIcon}");
    background-size: 60%; /* 100 To fill the dimensions of the button */
    background-repeat: no-repeat;
    //padding: 5px 8px;
    //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    //border-radius: 6px;
    //margin-top: 6px;
    //margin-left: 550px;
    //margin-left: 70%;
    margin-bottom: 0rem;
    width: 38px;
    height: 35px;

    position: absolute;
    right: 2.2rem;
    bottom: 0.1rem;
    
    //font-size: ${rem('14px')};
    //font-family: ${({ theme }) => theme.fonts.secondary};
    //font-weight: bold;
    //color: ${({ theme }) => theme.palette.label};

    &:hover {
      //color: white;
      //background-color: ${({ theme }) => theme.palette.primary[0]};

      //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
      //border-radius: 4px;
      outline: none;
      //box-shadow: 0px 0px 6px
      //${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
      box-shadow: 0 2px 10px 2px rgba(195,192,192,.5);
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
    }

    @media (max-width: 870px) {
      margin-left: 238px;
    }
  `;
  const PreviewFrame = styled.div`
    /*
    max-width: 1000px;
    max-height: 600px;
    width: 600px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    text-align: left;
    //padding: 4px 8px;
    //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    //border: 1px solid ${({ theme }) => theme.palette.border};
    //border-radius: 6px;
    //margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    //margin-bottom: 1rem;
    //float: left;


    &:hover {
      //border: 2px solid ${({ theme }) => theme.palette.primary[0]};
    }

    @media (max-width: 420px) {
      min-width: 200px;
    }
    */

  //width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  //padding-top: 5px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  border-left: none;
  border-right: none;
  position relative;



  /* working but don't need
  ${({noAttached}) => noAttached && `
    min-height: 80px;
    max-height: 80px;
  `}
  

   ${({notChrome}) => notChrome && `
    min-height: 80px;
    max-height: 80px;
  `}
  */

   @media (max-width: 870px) {
    //border: 1px solid red;
    max-width: 500px;
  }
  `;
  const PreviewImage = styled.img`
    width: 50px;
    height: 50px;
    display: block;
    border: 1px solid ${({ theme }) => theme.palette.primary[0]};
    //border: 1px solid ${({ theme }) => theme.palette.border};
    border-radius: 6px;
    margin-top: 0.2rem;
    margin-left: 0.5rem;
    margin-right: 0rem;
    margin-bottom: 5px;
    position: relative;
    float: left;

    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.primary[0]};
    }
  `;

  const PreviewInput = styled.textarea`
    max-width: 1000px;
    max-height: 200px;
    width: 600px;
    height: 100px;
    //overflow-y: scroll; // the outline will be shown
    padding: 4px 8px;
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    border: none;
    resize: none;
    outline: none;

    @media (max-width: 420px) {
    min-width: 200px;
  }
  `;

  const RemoveButton = styled.button `
    height: 25px;
    min-width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("${deleteIcon}");
    background-size: 16px 20px;

    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.primary[1]};
      border-radius: 2px;
    }
  `;

  const SubmitButton = styled.button `
    height: 35px;
    width: 35px;
    background-repeat: no-repeat;
    background-image: url("${submitIcon}");
    background-position: center;
    background-size: 65%; /* 100 To fill the dimensions of the button */
    margin-top: 6px;
    //margin-left: 5px;
    //position: relative;
    position: absolute;
    right: 0.1rem;
    bottom: 0.1rem;

    &:hover {
      //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
      //border-radius: 2px;

      outline: none;
      //box-shadow: 0px 0px 6px
      //${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
      box-shadow: 0 2px 10px 2px rgba(195,192,192,.5);
    }
  `;

  const ButtonsDiv = styled.div`
  //width: 815px; /* element will not resize if specifying width */
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 0px;
  margin-top: 0px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[1]};
  border: 1px solid rgb(238,238,238);
  background-color: rgb(248,248,248);



  @media (max-width: 870px) {
    max-width: 500px;
    //background-color: red;
  }
`;

  const ImagesDiv = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;
  `;
  const Warning = styled.h1`
  height: 60px;
  min-height: 60px;
  justify-content: center;
  text-align: center;
  
  //padding-top: 5px;
  //border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border: 1px solid ${({ theme }) => theme.palette.border};
  //border-radius: 6px;
  //border-left: none;
  //border-right: none;
  position relative;
  margin-bottom: 0px;
  background-color: rgb(219,225,234);
  font-size: 1.88rem;
  font-weight: 300;
  `;
  const PDFContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 120px;
    overflow-y: scroll;
  `;
  const PDFWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 300px;
    max-width: 400px
    //height: 40px;
    border-radius: 5px;

    border: 1px solid rgba(199,199,204,255);
  `;
  const PDFIcon = styled.img`
    padding-left: 10px;
  `;
  const PDFProps = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
  `;
  const PDFName = styled.label`
    width: 200px;
    overflow: hidden;
  `;
  const PDFSizeWrap = styled.div`
    display: flex;
    flex-direction: row;
  `;
  const PDFSize = styled.label`
    color: rgba(199,199,204,255);
  `;
  const Circle = styled.span`
    display: inline-block;
    height: 5px;
    width: 5px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    border: 3px solid rgba(199,199,204,255);
  `;

export default {
  PostInputForm, 
  PostInput,
  PostButton,
  Label,
  CheckBox,
  ChooseFile,
  ChooseFileLabel,
  PreviewFrame,
  PreviewImage,
  PreviewInput,
  RemoveButton,
  SubmitButton,
  ButtonsDiv,
  CheckBoxDiv,
  ImagesDiv,
  Warning,
  PDFContainer,
  PDFWrap,
  PDFIcon,
  PDFProps,
  PDFName,
  PDFSizeWrap,
  PDFSize,
  Circle,
};
