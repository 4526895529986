import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';
import { ConfirmAdministrativePrivileges } from 'helpers/api-calls';
import { checkForAuthentication } from 'helpers/cookies';

import Shiffy from 'components/GeneralComponents/AdminClaimComponent/AdminClaimComponent/assets/shiffy-business.svg';

import 'components/GeneralComponents/AdminClaimComponent/AdminClaimComponent/AdminClaimComponent.scss';
import './AdminClaimPage.scss';

/**
 * This encapsulates both the admin claim component along with
 * the pending admin claim component. Also states.
 */
class AdminClaimPage extends React.Component {
  state = {
    loading: true,
    path: null,
  };

  componentDidMount() {
    this._claimAdministrativePrivileges();
  }

  _claimAdministrativePrivileges = () => {
    const { match } = this.props;

    if (!match.params.activation_code) {
      this.setState({
        path: '/login',
      });
    }

    ConfirmAdministrativePrivileges(match.params.activation_code).then(
      (response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          this.setState({
            loading: false,
          });
        }
      }
    );
  };

  render() {
    // switch statement based on step of process
    const { loading, path } = this.state;
    const authenticated = checkForAuthentication();

    const NextStepLink = () => {
      if (authenticated) {
        return (
          <Link
            to="/"
            className="button button-primary__inverse general-modal__action-button"
          >
            <FormattedMessage
              id="AdminClaimPage.goHome"
              defaultMessage="Go Home"
            />
          </Link>
        );
      } else {
        return (
          <Link
            to="/login"
            className="button button-primary__inverse general-modal__action-button"
          >
            <FormattedMessage
              id="AdminClaimPage.login"
              defaultMessage="Log In"
            />
          </Link>
        );
      }
    };

    if (loading) {
      return (
        <div className="loading">
          <LoadingSpinner size="large" color="blue" />
        </div>
      );
    } else if (path) {
      return <Redirect to={path} />;
    } else {
      return (
        <article className="general-modal">
          <header className="general-modal__header">
            <h3 className="general-modal__header-text">
              <FormattedMessage
                id="AdminClaimPage.success1"
                defaultMessage="Success! You are now an admin."
              />
            </h3>
          </header>

          <section className="general-modal__body">
            <p className="general-modal__description">
              <FormattedMessage
                id="AdminClaimPage.success2"
                defaultMessage="You've successfully claimed admin privileges."
              />
            </p>
            <br />
          </section>

          <footer className="general-modal__action-section">
            <NextStepLink />
          </footer>
        </article>
      );
    }
  }
}

export { AdminClaimPage };
