import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { injectIntl, defineMessages } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getUserId } from '../../helpers/cookies';

import TableComponent from 'components/GeneralComponents/TableComponent/TableComponent';
import FilterDataContainer from 'components/CalendarComponents/FilterContainer/FilterDataContainer';

import { createCsvRowData, createCsvRowData2, } from 'helpers/formatting';
import {
  CalendarCsvHeaders,
  CalendarCsvHeaders1,
  CalendarTableHeaders,
  CalendarTableHeaders1,
  CalendarTableHeaders2,
  CalendarShiftTypes,
} from 'config';
import { shiftDuration, createCsvDateFormat } from '../../helpers/date-time';
import { CALENDAR_SHIFTS_PER_PAGE, MAX_LOADING_TIMES } from 'constants/index';

import './CalendarPage.scss';

const sha1 = require('js-sha1');

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);

    this.pubnub = props.pubnub;
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.messages = defineMessages({
      notApplicable: {
        id: 'CalendarPage',
        defaultMessage: 'N/A',
        description: "Placeholder when data for column doesn't exist",
      },
    });

    this.state = {
      //headers: CalendarTableHeaders1,
      headers: null,
      //csvHeaders: CalendarCsvHeaders,
      csvHeaders: null,
      skills: null,
      rowData: null,
      deletedGroupId: null,
      timer: null,
    };

    this._isMounted = false;
    this.pageIndex = 1;
    this.stopLoading = false;
    this.noChangeCount = 0;
    this.dayFiltersChanged = false;
    this.stateFiltersChanged = false;
    this.timeOutCount = 0;
  }

  componentDidMount () {
    const { shifts, locationSettings, skills, locationId, getLocationUsableSkills, stateFilters, } = this.props;

    this._isMounted = true;
    this.pubnubSubscribe();

    const myTimer = setInterval(this.tick, 1000); //unit: ms, 1000 = 1 second
    this.setState({timer: myTimer});

    if(locationSettings) {
      if(locationSettings.cfg__skill === 1) {
        this.setState({headers: CalendarTableHeaders1});
        this.setState({csvHeaders: CalendarCsvHeaders1});
      }else {
        this.setState({headers: CalendarTableHeaders2});
        this.setState({csvHeaders: CalendarCsvHeaders});
      }
    }else {
      this.setState({headers: CalendarTableHeaders2});
      this.setState({csvHeaders: CalendarCsvHeaders});
    }

    if(locationId){
      getLocationUsableSkills(locationId);
    }

    if(skills && skills.length > 0) {
      if(this._isMounted) {
         this.setState({skills: skills});
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { shifts, loading, skills, getLocationUsableSkills, stateFilters, typeFilters,
            dayFilters, getMoreCalendarShifts, dateStart, dateEnd, lastCount, locationId, } = this.props;
    const { deletedGroupId, } = this.state;

    if(prevProps.skills !== skills) {
      if(skills && skills.length > 0) {
        if(this._isMounted) {
           this.setState({skills: skills});
        }
      }
    }

    if(prevProps.stateFilters !== stateFilters) {
      this.stateFiltersChanged = true;
    }

    if(prevProps.dayFilters !== dayFilters) {
      this.dayFiltersChanged = true;
    }

    if(prevProps.typeFilters !== typeFilters) {
      if(!prevProps.typeFilters.includes('shift_vto') && typeFilters.includes('shift_vto')) {
       
      }
    }

    let msg = '';
    if(prevProps.shifts !== shifts) {
      this.timeOutCount = 0;
      if(this.dayFiltersChanged) {
        this.dayFiltersChanged = false;
        return;
      }
      if(this.stateFiltersChanged) {
        this.stateFiltersChanged = false;
        return;
      }
      if(shifts.length > prevProps.shifts.length) {
        this.noChangeCount = 0;
        msg = `Calendar: loaded ${shifts.length} shifts.`;
      }else {
        this.noChangeCount += 1;
        //for loading each page, max updating times are 4, including after changed location
        if(this.noChangeCount > 6) {
          this.stopLoading = true;
          msg = `Calendar: Number of shifts no increasing for ${this.noChangeCount} times. Stop loading.`;
        }else {
          if(shifts.length === prevProps.shifts.length) {
            msg = `Calendar: loaded page ${this.pageIndex}. Shifts updated, number of shifts is ${shifts.length} for ${this.noChangeCount} times.`;
          }else {
            msg = `Calendar: current shifts less than previous shifts, location changed or filter changed.`;
          }
        }
      }
      console.log(msg);
    }

    if(prevProps.loading !== loading) {
      this.timeOutCount = 0;
      if(prevProps.loading && !loading) {
        if(!this.stopLoading && this.pageIndex < MAX_LOADING_TIMES) {
          if(dateStart && dateEnd) {
            this.pageIndex += 1;
            msg = `Calendar: There might be more shifts to load. Next page: ${this.pageIndex}.`;
            getMoreCalendarShifts(dateStart, dateEnd, this.pageIndex, CALENDAR_SHIFTS_PER_PAGE);
          }
        }else {
          msg = `Calendar: last page ${this.pageIndex}. Loading stopped.`;
          if(this.pageIndex === MAX_LOADING_TIMES) {
            msg = `Calendar: Max loading times ${MAX_LOADING_TIMES} reached. Stop loading`;
          }
        }
        console.log(msg);
      }
    }

    if(prevProps.lastCount !== lastCount) {
      this.timeOutCount = 0;
      if(prevProps.lastCount === CALENDAR_SHIFTS_PER_PAGE && lastCount < CALENDAR_SHIFTS_PER_PAGE) {
        console.log('Calendar: No more shifts to load. Stop loading.');
        this.stopLoading = true;
      }
    }

  }

  componentWillUnmount () {
    const { timer } = this.state;
    this._isMounted = false;
    
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });

    if(timer) {
      clearInterval(this.state.timer);
    }
  }

  
  pubnubSubscribe = () => {

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'shift' &&
          event.message.crud === 'UPDATE'
        ) {
          console.log(event);
        }
        if(
          event.message.receiver_id == getUserId() &&
          event.message.event === 'channel' &&
          event.message.crud === 'DELETE'
          ) {
          const msg = `Calendar page: Group (channel) ${event.message.tree_id} has been deleted.`;
          console.log(msg);
          this.setState({deletedGroupId: event.message.tree_id});
        }
      },
    });
  };

  tick = () => {
    const { loading } = this.props;
    let msg = '';
    if(loading) {
      this.timeOutCount += 1;
      if(this.timeOutCount >= 10) {
        this.timeOutCount = 0;
        msg = `Calendar: Network no response for 10 seconds.`;
      }else {
        msg = `Calendar: time out count: ${this.timeOutCount}.`;
      }
      //console.log(msg);
    }else {
      this.timeOutCount = 0;
    }
  }

  findSkills = (skill_ids) => {
    const { skills, } = this.props;
    let textSkills = '';
    if(skill_ids && skill_ids.length > 0) {
      if(skills && skills.length > 0) {
        for(let i=0; i<skill_ids.length; i++) {
          for(let j=0; j<skills.length; j++) {
            if(skill_ids[i] === skills[j].id) {
              textSkills += `${skills[j].skill_content}, `;
            }
          }
        }
      }
    }
    return textSkills;
  }

  setCsvHeaders = () => {
    const { intl } = this.props;

    return this.state.csvHeaders.reduce((header, element, index) => {
      if (index + 1 === this.state.csvHeaders.length) {
        return (header += intl.formatMessage(element.title) + '\n');
      } else {
        return (header += intl.formatMessage(element.title) + ', ');
      }
    }, '');
  };

  setRowString = (rows, headers) => {
    const { intl } = this.props;
    const notApplicableString = intl.formatMessage(this.messages.notApplicable);

    return Papa.unparse(
      rows.map((element) => {
        return element.map((element, index) => {
          switch (headers[index].type) {
            case 'user':
              return element &&
                element.first_name.length > 0 &&
                element.last_name.length
                ? `${element.first_name.trim()} ${element.last_name.trim()}`
                : notApplicableString;

            case 'date':
              return element
                ? createCsvDateFormat(element)
                : notApplicableString;

            case 'time':
              return element
                ? createCsvDateFormat(element)
                : notApplicableString;

            case 'duration':
              return shiftDuration(element[0], element[1]);

            case 'enum':
              if (element === 'shift') {
                return intl.formatMessage(CalendarShiftTypes.teamShift);
              }

              if (element === 'open_shift') {
                return intl.formatMessage(CalendarShiftTypes.openShift);
              }

              if (element === 'shift_vto') {
                return intl.formatMessage(CalendarShiftTypes.vto);
              }

              return (
                element.charAt(0).toUpperCase() + element.slice(1)
              ).replace(/_/g, ' ');
            case 'skills':
              return element ? this.findSkills(element) : ' '
              break
            default:
              return `${element}`;
          }
        });
      })
    );
  };

  exportData = (from, to) => {
    if (this.props.shifts.length > 0) {
      const csvContent = `data:text/csv;charset=utf-8,${this.setCsvHeaders()}${this.setRowString(
        createCsvRowData2(
          //this.props.shifts,
          this.state.rowData,
          this.state.csvHeaders,
          this.props.location
        ),
        this.state.csvHeaders
        // do NOT remove this next line or else
      )}`.replace(/#/g, '');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute(
        'download',
        `${moment(from).format('YYYY-MM-DD')}-${moment(to).format(
          'YYYY-MM-DD'
        )}.csv`
      );

      document.body.appendChild(link); // Required for FF

      if (navigator.msSaveBlob) {
        const blob = new Blob(
          `${this.setCsvHeaders()}${this.setRowString(
            createCsvRowData(
              this.props.shifts,
              this.state.csvHeaders,
              this.props.location
            ),
            this.state.csvHeaders
          )}`,
          { type: 'text/plain;charset=utf-8;' }
        );
        return navigator.msSaveOrOpenBlob(
          blob,
          `${moment(from).format('YYYY-MM-DD')}-${moment(to).format(
            'YYYY-MM-DD'
          )}.csv`
        );
      } else {
        return link.click();
      }
    }
  };

  translateHeaders = () => {
    const { intl } = this.props;
    const { headers } = this.state;

    if(headers) {
      return headers.map((header) => ({
        ...header,
        title: intl.formatMessage(header.title),
      }));
    }else {
      return null;
    }
   
  };

  updateRowData = (rowData) => {
    if(this._isMounted) {
       this.setState({rowData: rowData});
    }
  };

  dateRangeChanged = (start, end) => {
    this.pageIndex = 1;
    this.stopLoading = false;
    this.noChangeCount = 0;
  }


  render() {
    const { shifts, loading, locationSettings, stateFilters, } = this.props;
    const { skills, deletedGroupId, stopLoadingLayer } = this.state;
    return (
      <article className="calendar">
        <FilterDataContainer 
          exportData={this.exportData}
          dateRangeChanged={this.dateRangeChanged}
        />
        <TableComponent
          rows={shifts}
          loading={loading}
          headers={this.translateHeaders()}
          locationSettings={locationSettings}
          skills={skills}
          stateFilters={stateFilters}
          updateRowData={this.updateRowData}
          deletedGroupId={deletedGroupId}
        />
      </article>
    );
  }
}

export default injectIntl(CalendarPage);

CalendarPage.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  loading: PropTypes.bool,
  pubnub: PropTypes.object.isRequired,
};

CalendarPage.defaultProps = {
  headers: [],
  rows: [],
  loading: false,
};
