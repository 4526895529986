import axios from 'axios';
import { axiosInstance, protectedAxiosInstance } from 'helpers/api-calls';
import { formatOnlyProps } from 'helpers/formatting';
import { BASE_URL } from '../../constants';
import { getLocationCode, getLocaleCookie } from '../cookies';

 /**
 * Gets all request time offs for the user's current location.
 * This will be used more and more often for checking whether
 * a user needs certain features in the app.
 * @param  { String | Int } {location_id}
 */
export const GetLocationRequestTimeOffs = ({location_id, page, per_page}) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/can_admin?location_id=${location_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      location_id,
      per_page,
      page,
      total: true,
    },
  });
};

/**
 * Approve a request time off
 * 
 * @param  { String | Int } {rto_id}
 */
export const ApproveRequestTimeOff = (rto_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/${rto_id}/approve?response[put_return_resource]=true`, 
    method: 'PUT',
    headers: {
      Locale: locale,
    },
    params: {
      rto_id,
    },
  });
};

/**
 * Deny a request time off
 * 
 * @param  { String | Int } {rto_id}
 */
export const DenyRequestTimeOff = (rto_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/request_time_offs/${rto_id}/deny?response[put_return_resource]=true`, 
    method: 'PUT',
    headers: {
      Locale: locale,
    },
    params: {
      rto_id,
    },
  });
};

/**
 * Get user's request time off balance
 * 
 * @param  { String | Int } {user_privilege_id}
 */
export const GetUserRequestTimeOffBalance = (user_privilege_id, rto_id) => {
  const locale = getLocaleCookie();

  return protectedAxiosInstance.request({
    url: `/api/customer/user_role/user_privileges/${user_privilege_id}/rto_balances?rto_id=${rto_id}`,
    method: 'GET',
    headers: {
      Locale: locale,
    },
    params: {
      user_privilege_id,
      rto_id,
    },
  });
};






