import { protectedAxiosInstance } from 'helpers/api-calls';

import { shiftCallKeys, CALENDAR_SHIFTS_PER_PAGE } from 'constants/index';
import { getLocationCode, getLocaleCookie } from '../cookies';
import { createApiDateFormat } from '../date-time';

export const loadCalendarShifts = (from, to) => {
  const location_id = getLocationCode('location_id');
  const locale = getLocaleCookie();
  return protectedAxiosInstance.request({
    method: 'GET',
    url: '/api/organization/schedule_elements/can_admin',
    params: {
      page: 1,
      //per_page: 2500,
      per_page: CALENDAR_SHIFTS_PER_PAGE,
      start_after: createApiDateFormat(from),
      start_before: createApiDateFormat(to),
      order: 'start_at_asc',
      location_id,
      locale,
      name_is: [
        'shift',
        'open_shift',
        'shift_pickup',
        'shift_inventory',
        'shift_vto',
      ],
      status_is: ['pending', 'covered', 'approved', 'rejected', 'posted'],
      'response[only]': shiftCallKeys,
    },
  });
};

export const loadMoreCalendarShifts = (from, to, pageNum, perPageNum) => {
  const location_id = getLocationCode('location_id');
  const locale = getLocaleCookie();
  return protectedAxiosInstance.request({
    method: 'GET',
    url: '/api/organization/schedule_elements/can_admin',
    params: {
      page: pageNum,
      per_page: perPageNum,
      start_after: createApiDateFormat(from),
      start_before: createApiDateFormat(to),
      order: 'start_at_asc',
      location_id,
      locale,
      name_is: [
        'shift',
        'open_shift',
        'shift_pickup',
        'shift_inventory',
        'shift_vto',
      ],
      status_is: ['pending', 'covered', 'approved', 'rejected', 'posted'],
      'response[only]': shiftCallKeys,
    },
  });
};
