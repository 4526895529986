import { connect } from 'react-redux';
import RoleComponent from 'components/AuthenticationComponents/RoleComponent';

import { userOperations } from '_data/modules/Users';
import { appOperations } from 'App/duck';
import { appActions} from 'App/duck';
import { loadSetupActions } from '../actions/dashboard-actions';

const mapStateToProps = ({
  locationReducer,
  setupReducer,
  globalReducer,
  appReducer,
}) => ({
  locationId: locationReducer.location_code,
  setupActions: setupReducer.setup_actions,
  setupActionsLoading: setupReducer.setup_actions_loading,
  pendingUsersLoaded: globalReducer.Users.loaded,
  locationSettingsLoading: appReducer.location.loading,
  allUsers: appReducer.allUsers.users,
});
/*
const mapDispatchToProps = (dispatch) => ({
  loadRequiredApis(location_id, pendingUsersLoaded) {
    if (!pendingUsersLoaded)
      dispatch(userOperations.fetchPendingUsers(location_id));
    dispatch(loadSetupActions(location_id));
  },
  fetchLocation(user_privilege_id) {
    dispatch(appOperations.fetchLocation(user_privilege_id));
  },
  fetchChannels(location_id) {
    dispatch(appOperations.fetchChannels(location_id));
  },
  fetchAllUsers(location_id, pageNo, numPerPage) {
    dispatch(appOperations.fetchAllUsers(location_id, pageNo, numPerPage));
  },

});
*/
const mapDispatchToProps = (dispatch) => {
  const loadRequiredApis = (location_id, pendingUsersLoaded) => {
    if (!pendingUsersLoaded)
      dispatch(userOperations.fetchPendingUsers(location_id));
    dispatch(loadSetupActions(location_id));
  };

  const fetchLocation = (user_privilege_id) => {
    dispatch(appOperations.fetchLocation(user_privilege_id));
  };

  const fetchChannels = (location_id) => {
    dispatch(appOperations.fetchChannels(location_id));
  }

  const fetchAllUsers = (location_id, pageNo, numPerPage) => {
    dispatch(appOperations.fetchAllUsers(location_id, pageNo, numPerPage));
  };

  const resetManagers = () => {
    dispatch(appActions.resetManagers(true));
  };

  const resetAssociates = () => {
    dispatch(appActions.resetAssociates(true));
  };

  const resetRemovedUsers = () => {
    dispatch(appActions.resetRemovedUsers(true));
  };

  const resetPendingUsers = () => {
    dispatch(appActions.resetPendingUsers(true));
  };

  const resetAllUsers = () => {
    dispatch(appActions.resetAllUsers(true));
  };

  return {
    loadRequiredApis,
    fetchLocation,
    fetchChannels,
    fetchAllUsers,
    resetManagers,
    resetAssociates,
    resetRemovedUsers,
    resetPendingUsers,
    resetAllUsers,
  }
};

const AuthenticationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleComponent);

export default AuthenticationContainer;
