import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

const InfiniteScroll = ({ isLoading, onEnter, bottomOffset }) => {
  if (!isLoading) {
    return <Waypoint onEnter={onEnter} bottomOffset={bottomOffset} />;
  }

  return null;
};

InfiniteScroll.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  bottomOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InfiniteScroll.defaultProps = {
  bottomOffset: 0,
};

export default InfiniteScroll;
