import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { appShapes } from 'App/duck';

import blocks from '../../blocks';
import elements from '../../elements';

class ChannelsComponent extends React.Component {
  componentDidMount() {
    const { active, channels, setActiveChannel } = this.props;
    if(active){
      if (channels && !channels.loading && channels.data.length > 0){
        let i;
        let findChannel;
        for(i=0; i<channels.data.length; i++){
          if(channels.data[i].id === active){
            findChannel = channels.data[i];
            break;
          }
        }
        if(findChannel) {
          setActiveChannel(findChannel.id);
        }else{
          setActiveChannel(channels.data[0].id);
        }

      }
    }else{
      setActiveChannel(channels && channels.data.length > 0 ? channels.data[0].id : 0);
    }

  }

  componentDidUpdate(prevProps) {
    const { active, channels, setActiveChannel } = this.props;

    if (prevProps.channels !== channels && channels.data.length > 0) {
      setActiveChannel(active ? active : channels.data[0].id);
      //setActiveChannel(channels.data[0].id);
    } else if (channels.data.length === 0) {
      // User is awaiting location approval so there are no channels
      setActiveChannel(0);
    }
  }

  render() {
    const { channels, active, setActiveChannel } = this.props;

    return (
      <elements.ColumnWrap maxWidth="360px">
        <blocks.Header>
          <FormattedMessage id="Channels.headerLabel" defaultMessage="Groups" />
        </blocks.Header>
        <elements.ScrollWrap>
          {channels.loading ||
            channels.data.map((channel) => (
              <blocks.Channel
                key={channel.id}
                active={channel.id === active}
                data={channel}
                handleClick={setActiveChannel}
              />
            ))}
        </elements.ScrollWrap>
      </elements.ColumnWrap>
    );
  }
}

ChannelsComponent.propTypes = {
  setActiveChannel: PropTypes.func.isRequired,
  channels: appShapes.channelsShape.isRequired,
  active: PropTypes.number.isRequired,
};

export default ChannelsComponent;
