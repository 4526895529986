import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

import locationIcon from './location-feed-icon.svg';
import customIcon from './custom-feed-icon.svg';

const MODIFIER_CONFIG = {
  custom_feed: () => css`
    background-image: url("${customIcon}");
  `,
  location_feed: () => css`
    background-image: url("${locationIcon}");
  `,
  region_feed: () => css`
    background-image: url("${customIcon}");
  `,
};

const Icon = styled.div`
  width: 38px;
  height: 38px;
  margin-right: 1rem;
  flex: 0 0 38px;
  /* background-size: 38px; */

  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

Icon.propTypes = {
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
};

export default Icon;
