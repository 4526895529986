import React from 'react';

import Logo from '../logo';
import UserDropdown from '../UserDropdown';
import LocationDropdown from '../../containers/LocationDropdown';

import './PageHeader.scss';

export default function({ authenticated }) {
  const UserProfileDropdown = () => {
    if (authenticated) {
      return <UserDropdown authenticated />;
    }

    return null;
  };

  const UserLocationDropdown = () => {
    if (authenticated) {
      return <LocationDropdown />;
    }

    return null;
  };

  return (
    <nav className="page-header">
      <section className="page-header--upper-section">
        <Logo />
        <UserLocationDropdown />
        <UserProfileDropdown />
      </section>
    </nav>
  );
}
