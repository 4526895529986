import pendingShifts from './pendingShifts';
import managedShifts from './managedShifts';
import requestTimeOffs from './requestTimeOffs';
import managedRTOs from './managedRTOs';
import userPrivileges from './userPrivileges';
import skills from './skills';

export default {
  pendingShifts,
  managedShifts,
  requestTimeOffs,
  managedRTOs,
  userPrivileges,
  skills,
};
