import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  RadioGroup,
  RadioOption,
} from 'components/GeneralComponents/RadioGroup';
import { ExistingAccountComponent } from './components/ExistingAccountComponent/ExistingAccountComponent';

import './ExistingAccountsComponent.scss';

class ExistingAccountsComponent extends React.PureComponent {
  state = {
    currentPhoneNumber: this.props.users[0].phone_number,
  };

  _handleChange = (event) => {
    this.setState({
      currentPhoneNumber: event.target.value,
    });
  };

  _renderAccounts = () =>
    this.props.users.map((element, index) => (
      <RadioOption
        key={index}
        name="existingAccounts"
        value={element.phone_number}
        onChange={this._handleChange}
      >
        <ExistingAccountComponent user={element} value={element.phone_number} />
      </RadioOption>
    ));

  render() {
    return (
      <article className="existing-accounts__container">
        <h2 className="existing-accounts__header">
          <FormattedMessage
            id="ExistingAccountsComponent.foundAccounts"
            defaultMessage="It looks like there are some existing accounts associated with your information."
          />
        </h2>
        <h4 className="existing-accounts__sub-header">
          <FormattedMessage
            id="ExistingAccountsComponent.matchPhoneNumber"
            defaultMessage="Do any of these phone numbers match yours? If so, we'll verify it and add your email to the existing account."
          />
        </h4>
        <RadioGroup
          name="existingAccounts"
          value={this.state.currentPhoneNumber}
          onChange={this._handleChange}
        >
          {this._renderAccounts()}
        </RadioGroup>
        <section className="form-actions">
          <button
            onClick={(e) => {
              e.preventDefault();
              this.props._signup();
            }}
            className="button button-primary button-inverse signup-button"
            type="button"
          >
            <FormattedMessage
              id="ExistingAccountsComponent.notMyPhoneNumbers"
              defaultMessage="I Don't See My Number"
            />
          </button>
          <button
            className="button button-primary"
            onClick={() => {
              this.props._updateEmail(this.state.currentPhoneNumber);
            }}
          >
            <FormattedMessage
              id="ExistingAccountsComponent.submit"
              defaultMessage="Submit"
            />
          </button>
        </section>
      </article>
    );
  }
}

export { ExistingAccountsComponent };

ExistingAccountsComponent.propTypes = {
  users: PropTypes.array.isRequired,
  _signup: PropTypes.func.isRequired,
};
