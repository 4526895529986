export const apiCallError = (error) => {
  if (error.response) {
    console.log(error.response.status);
  } else if (error.request) {
    console.log('Network error');
  } else {
    console.log(error.message);
  }
  console.log('Request config error');
};

export const isEmpty = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
