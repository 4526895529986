import styled from 'styled-components/macro';

const SectionColumn = styled.section`
  /* display: flex;
  justify-content: flex-start;
  flex-direction: column; */
  flex-grow: 1;
  flex-basis: 0;

  &:first-child {
    margin-right: 0.5rem;
  }

  &:last-child {
    margin-left: 0.5rem;
  }
`;

const FeedSection = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1170px;
  margin: 0 1rem;
  background-color: white;
  border-radius: 5px;
`;

const ShiftRequestsFeed = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin-bottom: 1rem;

  background-color: white;
  border-radius: 5px;
`;

const ShiftRequestsHeader = styled.h3`
  padding-bottom: 1rem;
  margin: 0;
  padding: 0;
`;

const ShiftRequestsFeedSection = styled.section`
  /* display: flex; */
  /* flex-direction: column; */
  /* min-width: 535px; */
  padding: 1rem;

  @media (max-width: 946px) {
    display: block;
    margin-bottom: 1rem;
  }

  @media (max-width: 640px) {
    display: flex;
    min-width: 280px;
  }
`;

const ShiftRequestsLoadingLayer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  top: 45%;
  margin: auto;

  > img {
    display: block;
    margin: auto;
    margin-top: 45px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 3rem;
  margin-right: 3rem;
`;

const BodyText = styled.p`
  margin-bottom: 0;
`;

const ActionWrap = styled.div`
  margin: 0 1.5rem;
  display: flex;
  min-width:320px
`;

export default {
  FeedSection,
  SectionColumn,
  ShiftRequestsFeed,
  ShiftRequestsHeader,
  ShiftRequestsLoadingLayer,
  ShiftRequestsFeedSection,
  Wrapper,
  BodyText,
  ActionWrap,
};
