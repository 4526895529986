import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const TableWrap = styled.section `
  //top: ${(props) => props.top || '0px'};
  //left: ${(props) => props.left || '120px'};
  //width: ${(props) => props.width || '1200px'};
  height: ${(props) => props.height || '430px'};
  position: relative;
  width: 100%;
  display: block;
  //height: 430px;
  overflow-y: scroll;
  //border: 1px solid blue;
`;

const WinContainerPhoneEmail = styled.div `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  min-height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  overflow: scroll;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border: 1px solid blue;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const WinContainer = styled.div `
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '120px'};
  width: ${(props) => props.width || '1200px'};
  min-height: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  z-index: 2;
  overflow: scroll;
  border-radius: 5px;
  //justify-content: center;
  //text-align: center;
  //border: 1px solid blue;

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const WinContainerRight = styled.section `
  top: ${(props) => props.top || '0px'};
  //left: ${(props) => props.left || '120px'};
  right: ${(props) => props.right || '20px'};
  minWidth: ${(props) => props.width || '1200px'};
  minHeight: ${(props) => props.height || '612px'};
  position: absolute;
  background-color: white; //rgba(242,242,247,255);
  box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  border-radius: 8px;
  z-index: 2;
  padding-right: 10px;
  //overflow: scroll;
  //border: 1px solid green;
  /*
  @media (max-width: $date-time-sm) {
    padding: 1.5rem;
  }
  */

  @media (max-width: 800px) {
    left: 50px;
    min-width: 600px;
  }
  
`;

const Overlay = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  //rgba(198,198,201,255)
`;

const OverlayTransparent = styled.div `
  position: fixed; /* Sit on top of the page content */
  display: ${(props) => props.display || 'none'};
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0); /* transparent background */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;


export default {
  TableWrap,
  WinContainerPhoneEmail,
  WinContainer,
  WinContainerRight,
  Overlay,
  OverlayTransparent,
};
