import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import './HeaderComponent.scss';

class HeaderComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
    };

    this.messages = defineMessages({
      placeholder: {
        id: 'HeaderComponent.search',
        defaultMessage: 'Search',
      },
    });

    this._isMounted = false;
  }

  componentDidMount() {
   
   this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearchChanged = (event) => {
    event.preventDefault();
    this.setState({searchTerm: event.target.value});
  }

  handleImportClose = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleAddFilterClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleHideFilterClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleClearAllClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleSortClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  handleRefreshClick = (event) => {
    event.preventDefault();
    alert('clicked')
  }

  render() {
    const { searchTerm, } = this.state;
    const { getLocationEmployeesNew, intl, } = this.props;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;
    
    const schedule = (<FormattedMessage
      id="ShiftHeaderComponent.schedule"
      defaultMessage="Schedule"
    />);
    const sortBy = (<FormattedMessage
      id="ShiftHeaderComponent.sortBy"
      defaultMessage="Sort by"
    />);

    const dateText = (<FormattedMessage
      id="ShiftHeaderComponent.date"
      defaultMessage="Date"
    />);

    const results = (<FormattedMessage
      id="ShiftHeaderComponent.results"
      defaultMessage="{number} results" 
      values={{number: 5678}}/>);

    const importId = (<FormattedMessage
      id="ShiftHeaderComponent.importId"
      defaultMessage="Import ID is"
    />);

    const addFilters = (<FormattedMessage
      id="ShiftHeaderComponent.addFilters"
      defaultMessage="Add Filters"
    />);

    const hideFilters = (<FormattedMessage
      id="ShiftHeaderComponent.hideFilters"
      defaultMessage="Hide Filters"
    />);

    const clearAll = (<FormattedMessage
      id="ShiftHeaderComponent.clearAll"
      defaultMessage="Clear All"
    />);


    return (
      <div className="shift-page-header__top-wrap">
        <div className="shift-page-header__wrap">
          <div className="shift-page-header__row-1">
            <label className="shift-page-header__schedule-text">{schedule}</label>
            <button className="shift-page-header__schedule-arrow-button"/>
            <label className="shift-page-header__results-text">{results}</label>
          </div>
          <div className="shift-page-header__row-2">
            <input className="shift-page-header__search-box"
              onChange={this.handleSearchChanged}
              type="text"
              placeholder={intl.formatMessage(this.messages.placeholder)}
              value={searchTerm}
            />
            <div className="shift-page-header__import-id" onClick={this.handleImportClose}>{importId}
              <label className="shift-page-header__import-id-value">1008</label>
              <button />
            </div>
            <button className="shift-page-header__add-filters" onClick={this.handleAddFilterClick}>{addFilters}</button>
            <button className="shift-page-header__hide-filters" onClick={this.handleHideFilterClick}>{hideFilters}</button>
            <button className="shift-page-header__clear-all" onClick={this.handleClearAllClick}>{clearAll}</button>
            <div className="shift-page-header__sort-drop-down">{sortBy}
              <label className="shift-page-header__date-text">{dateText}</label>
              <button className="shift-page-header__arrow-button"/>
            </div>
            <button className="shift-page-header__sort-button" onClick={this.handleSortClick}/>
            <button className="shift-page-header__refresh-button" onClick={this.handleRefreshClick} />
          </div>
        </div> 
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default injectIntl(HeaderComponent);

