import { createSlice } from '@reduxjs/toolkit';

//import teamConstants from '../../constants';

//const { statusTypes } = teamConstants;

const removedUsersSlice = createSlice({
  name: 'removedUsers',
  initialState: {
    users: [],
    page: 0,
    totalPages: 0,
    loading: false,
  },
  reducers: {
    requestRemovedUsers: (state, action) => {
      state.loading = true;
    },
    receiveRemovedUsers: (state, action) => {
      state.users = action.payload.users;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    },
    appendRemovedUsers: (state, action) => {
      state.users = state.users.concat(action.payload.users);
      state.page = action.payload.page;
      state.loading = false;
    },
    resetRemovedUsers: (state, action) => {
      state.users = [];
      state.page = 0
      state.totalPages = 0;
      state.loading = false
    },

  },
});

export default removedUsersSlice;
