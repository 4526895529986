import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import styled from './styled';
import previousIconUrl from '../../blocks/ActionButtons/assets/button-previous.svg';
import nextIconUrl from '../../blocks/ActionButtons/assets/button-next.svg';



class ImageWin extends React.PureComponent {
 constructor(props) {
    super(props);
    this.state = {
        hide: false,
        enlarged: false,
        parent: '',
        localCurrentIdx: -1,
        hovered: [],
        message: '',
        hoveredBigImg: false,

    };


    this.handleClose = this.handleClose.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
     this.onMouseEnterBig = this.onMouseEnterBig.bind(this);
    this.onMouseLeaveBig = this.onMouseLeaveBig.bind(this);
    this.handlePictureClick = this.handlePictureClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
    
 }

 componentDidMount(){
    const{selected, allPictures, replacedElement, currentIdx} = this.props;
    this.setState({parent: replacedElement});
    this.setState({localCurrentIdx: currentIdx});

 }

 componentWillUnmount(){
    
 }

handleClose(event){
  this.setState({hide: !this.state.hide});
  
  //callback function from parent
  this.props.closeWin && this.props.closeWin(event);
  event.preventDefault();
}

handlePrevious(event){
  if(this.state.localCurrentIdx > 0){
    this.setState({localCurrentIdx: this.state.localCurrentIdx-1});
    let {hovered} = this.state;
    for(let i=0; i<hovered.length; i++){
      hovered[i]=false;
    }
    hovered[this.state.localCurrentIdx-1] = true;
    this.setState({hovered: hovered});
  }
  event.preventDefault();
}

handleNext(event){
  const {allPictures} = this.props;
  
  if(this.state.localCurrentIdx < allPictures.length-1){
    this.setState({localCurrentIdx: this.state.localCurrentIdx+1});
    let {hovered} = this.state;
    for(let i=0; i<hovered.length; i++){
      hovered[i]=false;
    }
    hovered[this.state.localCurrentIdx+1] = true;
    this.setState({hovered: hovered});
  }
  event.preventDefault();
}

onMouseEnterBig(event){
  this.setState({hoveredBigImg: true});
  event.preventDefault();
}

onMouseLeaveBig(event){
  this.setState({hoveredBigImg: false});
  event.preventDefault();
}

handlePictureClick(event){
  this.setState({enlarged: !this.state.enlarged});
  event.preventDefault();
}

onMouseEnter(event){

  let currentUrl=event.currentTarget.currentSrc;
  let idx = this.findSelected(currentUrl);
  let {hovered} = this.state;
  for(let i=0; i<hovered.length; i++){
      hovered[i]=false;
  }
  hovered[idx] = true;
  this.setState({hovered: hovered});
  this.setState({message: "enter"});
  event.preventDefault();

}

onMouseLeave(event){

  let currentUrl=event.currentTarget.currentSrc;
  let idx = this.findSelected(currentUrl);
  let {hovered} = this.state;
  hovered[idx] = false;
  this.setState({hovered: hovered});
  this.setState({message: "leave"});
  event.preventDefault();

}

onMouseClick(event) {
  const {allPictures} = this.props;

  let currentUrl=event.currentTarget.currentSrc;
  let idx = this.findSelected(currentUrl);
  
  if(idx >= 0 && idx < allPictures.length){
    this.setState({localCurrentIdx: idx});
  }
  event.preventDefault();
}

findSelected = (picTofind) =>{
  const {allPictures} = this.props;
  
  for(var i=0; i<allPictures.length; i++){
    if(allPictures[i].thumb_url===picTofind || 
      allPictures[i].gallery_url===picTofind ||
      allPictures[i].original_url===picTofind ||
      allPictures[i].full_url===picTofind){
      return i;
    }
  }
    return -1
}


render() {
    const {currentIdx, allPictures, replacedElement} = this.props;
    const {enlarged, hovered} = this.state;
    let returnElement = <div>{replacedElement}</div>;
    let winMarginTop = `${(window.innerHeight - 612)/2}px`;
    let winMarginLeft = `${(window.innerWidth - 1200)/2}px`;

    
    const divStyle = {
      //top: "0px",
      //left: "120px",
      top: winMarginTop,
      left: winMarginLeft,
      width: "1200px",
      height: "612px",
      position: "Absolute",
      backgroundColor: "white",
      boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)"
      //backgroundColor: "#fff"
      //padding: "2px"
    }
    const divPageWrap = {
      width: "1200px",
      height: "50px",
      backgroundColor: "white",
      display: "Flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: "center"
    }
    const closeButton = {
      top: "0px",
      left: "10px",
      width: "75px",
      height: "35px",
      position: "Relative",
      color: "Black",
      backgroundColor: "white",
      textAlign: "center",
      justifyContent: "center",
      //border: "1px solid black",
      borderRadius: "6px",
      boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)"
    }
    const divImageButtons = {
      width: "1200px",
      height: "500px",
      backgroundColor: "white",
      display: "Flex",
      flexDirection: "row",
      justifyContent: "center | spaceEvenly",
      alignItems: "center",
      margin: "auto",
      //border: "1px solid red",
      boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)"
    }
    const divBigImage = {
      top: "0px",
      left: "0px",
      //maxWidth: "800px",
      //maxHeight: "500px",
      width: "1080px", //to keep aspect ratio
      height: "500px", // keep aspect ratio
      display: "Flex",
      //flexDirection: "row",
      justifyContent: "center | spaceEvenly",
      alignItems: "center",
      position: "relative",
      //border: "1px solid red"
    }
    const previousButton = {
      top: "0px",
      left: "75px",
      width: "60px",
      height: "60px",
      position: "Relative",
      //border: "1px solid black",
      //borderRadius: "6px"
      borderRadius: "50%",
      //backgroundImage: 'url(' + previousIconUrl + ')',
      backgroundImage: `url(${previousIconUrl})`,
      //alignItem: "center"
      //resizeMode: "cover"
      backgroundPosition: "center",
      backgroundSize: "stretch",
      backgroundRepeat: "no-repeat",
      boxShadow: "0 2px 10px 2px rgba(195,192,192,.5)",
      zIndex: "2"
    }
    const previousButtonDisabled = {
      top: "0px",
      left: "75px",
      width: "60px",
      height: "60px",
      position: "Relative",
      borderRadius: "50%",
      boxShadow: "0 2px 10px 2px rgba(195,192,192,.5)"
    }
    const nextButton = {
      top: "280px",
      left: "1075px",
      width: "60px",
      height: "60px",
      position: "Absolute",
      //border: "1px solid black",
      //borderRadius: "6px"
      borderRadius: "50%",
      //backgroundImage: 'url(' + previousIconUrl + ')',
      backgroundImage: `url(${nextIconUrl})`,
      //alignItem: "center"
      //resizeMode: "cover"
      backgroundPosition: "center",
      backgroundSize: "stretch",
      backgroundRepeat: "no-repeat",
      boxShadow: "0 2px 10px 2px rgba(195,192,192,.5)"
    }
    const nextButtonDisabled = {
      top: "280px",
      left: "1075px",
      width: "60px",
      height: "60px",
      position: "Absolute",
      borderRadius: "50%",
      boxShadow: "0 2px 10px 2px rgba(195,192,192,.5)"
    }

    const bigImage = {
      top: "0px",
      left: "150px",
      maxWidth: "800px",
      maxHeight: "500px",
      //width: "auto", //to keep aspect ratio
      //height: "500px", // keep aspect ratio
      //position: "Relative",
      //borderRadius: "4px",
      
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    }
    const bigImageHovered = {
      top: "0px",
      left: "150px",
      maxWidth: "800px",
      maxHeight: "500px",
      //width: "auto", //to keep aspect ratio
      //height: "500px", // keep aspect ratio
      //position: "Relative",
      border: "1px solid blue",
      borderRadius: "4px",

      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    }
    const bigImageClicked = {
      top: "0px",
      left: "150px",
      width: "900px",
      height: "500px",
      position: "Relative"
    }
    const smallImage = {
      width: "50px",
      height: "50px",
      border: "1px solid blue",
      borderRadius: "6px",
      marginLeft: "0.3rem",
      marginRight: "0.3rem"
      //padding: "5px 8px"
    }
    const hoveredImage = {
      width: "50px",
      height: "50px",
      border: "2px solid red",
      borderRadius: "6px",
      marginLeft: "0.3rem",
      marginRight: "0.3rem"
    }
    const divSmallImages = {
      width: "1200px",
      height: "60px",
      backgroundColor: "white",
      display: "Flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px 5px",
      boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)"
    }
    const h1Style = {
      top: "0",
      left: "50px",
      width: "1000px",
      color: "white",
      backgroundColor: "DodgerBlue",
      //padding: "10px",
      fontFamily: "Arial",
      position: "Relative"
    };

    if(this.state.hide){
      return replacedElement;
    }
    return (
        
        
        <div style={divStyle}>
          <div style={divPageWrap}>
             <button style={closeButton} 
                  onClick={this.handleClose}>
                  <FormattedMessage id="ImageWin.close"
                  defaultMessage="X Close"/>
                  </button>
            {/*<h1 style={h1Style}>{this.state.localCurrentIdx} mouse {this.state.message}</h1>*/}
          </div>
          <div style={divImageButtons}>
            <div>
            {(allPictures.length >= 1 && this.state.localCurrentIdx > 0) ? 
            <button style={previousButton}
                      onClick={this.handlePrevious}/>: <button style={previousButtonDisabled}>X</button>}
            </div>

            <div style={divBigImage}>
            {this.state.localCurrentIdx >= 0 && 
            <img style={this.state.hoveredBigImg ? bigImageHovered : bigImage} src={allPictures[this.state.localCurrentIdx].full_url} 
                      onMouseEnter={this.onMouseEnterBig} 
                      onMouseLeave={this.onMouseLeaveBig} 
                      onClick={this.handlePictureClick} 
                      enlarged={enlarged}/>} 
            </div>          
          
            <div>
            {(allPictures.length >= 2 && this.state.localCurrentIdx <= allPictures.length-2 )? 
            <button style={nextButton} 
                      onClick={this.handleNext}/> : <button style={nextButtonDisabled}>X</button>}
            </div>
          </div>
          
          <div style={divSmallImages}>
          {allPictures.map((picture, index) => (
            <div>
              <img  key={index} 
                    onMouseEnter={this.onMouseEnter} 
                    onMouseLeave={this.onMouseLeave} 
                    onClick={this.onMouseClick}
                    style={this.state.hovered[index] ? hoveredImage : smallImage} src={picture.thumb_url}
              />
            </div>
          ))}
          </div>
          {/*<h1 style={h1Style}>{this.state.localCurrentIdx} mouse {this.state.message}</h1>*/}
        </div>
        
     );
  }
}

ImageWin.PropTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string, 
};

ImageWin.defaultProps = {
  className: '',
};
export default injectIntl(ImageWin);

