import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const primaryBlue = '#3eaeff';

const FilterContainer = styled.section`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1462px;
  margin-left: auto;
  margin-right: auto;
`;

const UpperSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const LowerSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const LowerSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const PostShiftLink = styled(NavLink).attrs((props) => ({
  to: props.to,
}))`
  padding: 6px 15px;
  font-weight: 600;
  font-size: 14px;
  background-color: ${primaryBlue};
  color: white;
  border: 1px solid ${primaryBlue};
  border-radius: 20px;
  max-height: 32px;
  margin-right: 8px;

  &:hover {
    background-color: white;
    color: ${primaryBlue};
  }
`;

export {
  UpperSection,
  LowerSection,
  LeftSection,
  RightSection,
  LowerSectionColumn,
  FilterContainer,
  PostShiftLink,
};
