import { checkForAuthentication } from './cookies';

export const Auth = {
  isAuthenticated: checkForAuthentication(),
  numberOfManageableLocations: 0,
  locationsHaveBeenCalled: false,
  authenticate(cb, ...args) {
    this.isAuthenticated = true;
    if (cb) {
      cb.apply(this, args);
    }
  },
  signout(cb, ...args) {
    this.isAuthenticated = false;
    if (cb) {
      cb.apply(this, args);
    }
  },
};

/**
 * This checks the response and redirects to the login page if
 * logged out (403 code) and then if not, returns an error if 401
 * otherwise will parse to json if the json method works. If not,
 * returns a "success" status.
 *
 * @param {Object} response - The response object from API call
 */
export const AuthenticationCheck = (response) => {
  if (response.status === 401) {
    Auth.signout();
    return { status: 'error', reason: 'unauthorized' };
  }

  if (response.status === 403) {
    Auth.signout();
  } else {
    try {
      return response.json();
    } catch (e) {
      return { status: 'success' };
    }
  }
};

export const updateManagementData = (numberOfLocations) => {
  Auth.numberOfManageableLocations = numberOfLocations;
  Auth.locationsHaveBeenCalled = true;
};

export const ManagementCheck = () => {
  if (Auth.numberOfManageableLocations === 0 && Auth.locationsHaveBeenCalled) {
    return false;
  }

  if (Auth.numberOfManageableLocations > 0) {
    return true;
  }

  if (Auth.numberOfManageableLocations === 0 && !Auth.locationsHaveBeenCalled) {
    return false;
  }
};

export const StatusCheck = (status, cb) => {
  if (status === 401 || status === 403) {
    Auth.signout(cb);
  } else {
    Auth.authenticate(cb);
  }
};
