import moment from 'moment';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';

import {
  LoadFirstPagePendingShifts,
  LoadMorePendingShifts,
  ApproveShift,
  RejectShift,
  getShiftSegments,
  LoadManagedShifts,
  getShiftApplicants,
  PickApplicant,
  DenyApplicant,
} from 'helpers/api-calls/shift-calls';
import {getLocationCostCenterData, GetLocationCostCenterData} from 'helpers/api-calls/location-calls';
import {
  GetLocationRequestTimeOffs, 
  ApproveRequestTimeOff, 
  DenyRequestTimeOff} from 'helpers/api-calls/rto-calls';
import { GetAllEmployees } from 'helpers/api-calls/employee-interaction-calls';
import { GetLocationUsableSkills, } from 'helpers/api-calls/skill-calls';
import { getChannelMembers, } from 'helpers/api-calls/user-calls';
import { GetLocationInnerDepartments, getMyDepartments, } from 'helpers/api-calls/department-calls';
import teamCalls from 'helpers/api-calls/team-calls';
import { createApiDateFormat } from 'helpers/date-time';

import actions from './actions';
import { appActions } from 'App/duck';

const fetchPendingShifts = () => (dispatch) => {
  dispatch(actions.requestPendingShifts);

  return LoadFirstPagePendingShifts().then(({ data }) => {
    dispatch(actions.hydratePendingShifts(data.schedule_elements));
  });
};

const fetchMorePendingShifts = (pageNum, perPageNum) => (dispatch) => {
  //dispatch(actions.requestPendingShifts);
  return LoadMorePendingShifts(pageNum, perPageNum).then(({ data }) => {
    dispatch(actions.addMorePendingShifts(data.schedule_elements));
  });
};

const fetchPendingShiftSegments = (id) => (dispatch) =>
  getShiftSegments(id).then(({ data }) => {
    dispatch(actions.updatePendingShiftSegments(data.segments));
  });

const fetchManagedShifts = () => (dispatch) => {
  const sevenDaysAgo = createApiDateFormat(moment().subtract(7, 'days'));
  const today = createApiDateFormat(moment());

  dispatch(actions.requestManagedShifts());

  return LoadManagedShifts(sevenDaysAgo, today).then(({ data }) => {
    dispatch(actions.hydrateManagedShifts(data.schedule_elements));
  });
};

const approvePendingShift = (shift, configId) => (dispatch) =>
  ApproveShift(shift.id, configId).then((response) => {
    dispatch(actions.updateShiftStatus({id: shift.id, status: 'approved'}));
    dispatch(actions.deletePendingShift(shift.id));
    dispatch(actions.createManagedShift(response.data.schedule_element));
  });

const denyPendingShift = (shift) => (dispatch) =>
  RejectShift(shift.id).then((response) => {
    dispatch(actions.updateShiftStatus({id: shift.id, status: 'rejected'}));
    dispatch(actions.deletePendingShift(shift.id));
    dispatch(actions.createManagedShift(response.data.schedule_element));
  });

const handleShiftUpdate = (shift) => (dispatch) => {
  const { trade_status } = shift;

  switch (trade_status) {
    case 'pending':
      dispatch(actions.createPendingShift(shift));
      break;

    case 'approved':
    case 'rejected':
      dispatch(actions.createManagedShift(shift));
      break;

    default:
      break;
  }
};

const getCostCenterData = (locationId) => (dispatch) =>
  GetLocationCostCenterData(locationId).then(({ data }) => {
    dispatch(appActions.updateCostCenterData(data));
    //console.log(data);
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationCostCenterData returns error = ${error}`;
    console.log(msg);
  });

const getRequestTimeOffs = (locationId, pageNo, numPerPage) => (dispatch) => {
   const params = {
    location_id: locationId,
    page: pageNo,
    per_page: numPerPage,
  };
  GetLocationRequestTimeOffs(params).then(({data}) =>{
    const noDuplicated = uniqBy(data.request_time_offs, 'id');
    const managed = noDuplicated.filter((rto) => rto.status !== 'requested');
    const pending = noDuplicated.filter((rto) => rto.status === 'requested');
    console.log(`page number: ${pageNo}`);
    if(pageNo === 1) {
      dispatch(actions.hydrateAllRequestTimeOffs(noDuplicated));
      dispatch(actions.hydrateRequestTimeOffs(pending));
      dispatch(actions.hydrateMamagedRequestTimeOffs(managed));
    }else {
      dispatch(actions.appendAllTimeOffs(noDuplicated));
      dispatch(actions.appendRequestTimeOffs(pending));
      dispatch(actions.appendManagedRequestTimeOffs(managed));
    }
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationRequestTimeOffs returns error = ${error}`;
    console.log(msg);
  });
};

const approveRequestTimeOff = (rto_id) => (dispatch) =>
  ApproveRequestTimeOff(rto_id).then((response) => {
    dispatch(actions.deletePendingRTO(rto_id));
    dispatch(actions.createManagedRTO(response.data.request_time_off));
  }).catch((error) =>{
    const msg = `${rto_id}: ApproveRequestTimeOff returns error = ${error}`;
    console.log(msg);
  });

const denyRequestTimeOff = (rto_id) => (dispatch) =>
  DenyRequestTimeOff(rto_id).then((response) => {
    dispatch(actions.deletePendingRTO(rto_id));
    dispatch(actions.createManagedRTO(response.data.request_time_off));
  }).catch((error) =>{
    const msg = `${rto_id}: DenyRequestTimeOff returns error = ${error}`;
    console.log(msg);
  });

const getLocationEmployees = (locationId) => (dispatch) => 
  GetAllEmployees(locationId).then((data) => {
    dispatch(actions.hydrateEmployees(data.user_privileges));
    dispatch(appActions.hydrateUsers(data.user_privileges));
  }).catch((error) =>{
    const msg = `${locationId}: GetAllEmployees returns error = ${error}`;
    console.log(msg);
  });

const fetchShiftApplicants = (shiftId, userPrivileges) => (dispatch) =>
  getShiftApplicants(shiftId).then(({ data }) => {
    for(let i=0; i<data.applicants.length; i++) {
      if(userPrivileges) {
        let info = '';
        if(userPrivileges.length > 0) {
          const idx = userPrivileges.findIndex(
            (item) => item.owner_id === data.applicants[i].user.id
          );
          if(idx >= 0) {
            const privilege = userPrivileges[idx];
            info = `Applicant ${data.applicants[i].user.id} job title changed from ${data.applicants[i].user.current_position} to ${privilege.position}.`;
            console.log(info);
            data.applicants[i].user.current_position = privilege.position;
          }else {
            info = `shift ${shiftId}, applicant ${data.applicants[i].user.id} user provilege not found.`;
            console.log(info);
          }
        }
      }
    }
    dispatch(actions.updateShiftApplicants(data.applicants));
  }).catch((error) =>{
    const msg = `${shiftId}: getShiftApplicants returns error = ${error}`;
    console.log(msg);
    dispatch(actions.failedToGetApplicants());
  });

const approveApplicant = (shiftId, applicantId) => (dispatch) => 
  PickApplicant(shiftId, applicantId).then(({ data }) => {
    dispatch(actions.updateShiftApplicantStatus({
      shiftId: shiftId, 
      applicantId: applicantId, 
      status: 'picked'}));
    //dispatch(actions.updateShiftApproved(shiftId))
  }).catch ((error) =>{
    console.log(error);
  });


const denyApplicant = (shiftId, applicantId) => (dispatch) =>
  DenyApplicant(shiftId, applicantId).then(({ data }) => {
    dispatch(actions.updateShiftApplicantStatus({
      shiftId: shiftId, 
      applicantId: applicantId, 
      status: 'denied'}));
  }).catch((error) =>{
    console.log(error);
  });
const autoDenyApplicant = (shiftId, applicantId) => (dispatch) =>
  dispatch(actions.updateShiftApplicantStatus({
      shiftId: shiftId, 
      applicantId: applicantId, 
      status: 'denied'}));

const resetFetchFailedFlag = () => (dispatch) =>
  dispatch(actions.resetFetchFailed());

const getLocationUsableSkills = (locationId) => (dispatch) => 
  GetLocationUsableSkills(locationId).then(({data}) =>{
    dispatch(actions.hydrateSkills(data.skills));
    dispatch(appActions.hydrateSkills(data.skills));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationUsableSkills returns error = ${error}`;
    console.log(msg);
  });

const fetchChannelMembers = (channelId) => (dispatch) => 
  getChannelMembers(channelId).then((data) => {
    dispatch(appActions.hydrateChannelMembers(data.data.users));
    //console.log(data);
  }).catch((error) =>{
    const msg = `${channelId}: getChannelMembers returns error = ${error}`;
    console.log(msg);
  });

const getLocationInnerDepartments = (locationId) => (dispatch) => 
  GetLocationInnerDepartments(locationId).then(({data}) =>{
    dispatch(appActions.hydrateDepartments(data.departments));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationInnerDepartments returns error = ${error}`;
    console.log(msg);
  });
const getLocationMyDepartments = (locationId) => (dispatch) => 
  getMyDepartments(locationId).then(({data}) =>{
    dispatch(appActions.hydrateMyDepartments(data.departments));
  }).catch((error) =>{
    const msg = `${locationId}: getLocationMyDepartments returns error = ${error}`;
    console.log(msg);
  });

const fetchAllUsers = (params) => (dispatch) => {
  const managerConfig = {
    ...params,
    filter: 'active',
    per_page: 1000,
    admin_status: true,
    page: 1,
  };
  const associateConfig = {
    ...params,
    filter: 'active',
    per_page: 3000,
    admin_status: false,
  };

  const config = {
    ...params,
    filter: 'active',
    admin_status: true,
  };

  dispatch(actions.requestMembers(associateConfig.page));
  const start = moment();
  let msg = '';
  let visibleMembers = [];
 
  return axios
    .all([
      teamCalls.getTeamMembers(managerConfig),
      teamCalls.getTeamMembers(associateConfig),
    ])
    .then(
      axios.spread((managers, associates) => {
        const { user_privileges: managersList } = managers.data;
        const {user_privileges: associatesList, meta: { pagination },} = associates.data;
        const visibleMembers = managersList.concat(associatesList);
        /*
        for(let i=0; i<visibleMembers.length; i++) {
          msg = `Location: ${params.location_id} ${i}. ${visibleMembers[i].owner_id}`;
          console.log(msg);
        }
        */
        const payload = {
          users: visibleMembers,
          page: pagination.current_page,
          totalPages: pagination.total_pages,
        };

        dispatch(actions.hydrateEmployees(visibleMembers));
        dispatch(appActions.hydrateUsers(visibleMembers));
        //dispatch(actions.cleanUsers(payload));
        const end = moment();
        const diff = moment.duration(end.diff(start))
        const ms = parseInt(diff.asMilliseconds());
        msg = `fetch ${visibleMembers.length} users took ${ms} ms.`;
        console.log(msg);
      })
    ).catch((error) =>{
      const msg = `${params.locationId}: fetchAllUsers returns error = ${error}`;
      console.log(msg);
    });
    
};

export default {
  fetchPendingShifts,
  fetchMorePendingShifts,
  approvePendingShift,
  denyPendingShift,
  fetchPendingShiftSegments,
  fetchManagedShifts,
  handleShiftUpdate,
  getCostCenterData,
  getRequestTimeOffs,
  approveRequestTimeOff,
  denyRequestTimeOff,
  getLocationEmployees,
  fetchShiftApplicants,
  approveApplicant,
  denyApplicant,
  resetFetchFailedFlag,
  autoDenyApplicant,
  getLocationUsableSkills,
  fetchChannelMembers,
  getLocationInnerDepartments,
  getLocationMyDepartments,
  fetchAllUsers,
};
