import { connect } from 'react-redux';
import { loadDashboardData } from '../../../actions/dashboard-actions';
import { GeneralDataComponent } from './GeneralDataComponent';

/**
 * Shifts Posted - shifts_running_num
 * Shifts Covered - shifts_running_net_covered_num
 * Shift Approval Rate - shifts_running_manager_approve_rate (parse percentage)
 * Employees - take out comparison counter
 *
 * Shift running is taking the start_at key to find data for the KPIs
 */
const convertPercentageStringToNumber = (percentage) => {
  if (percentage === 'n/a') {
    return 0;
  }
  return Number(percentage.replace('%', ''));
};

const mapStateToProps = ({ dashboardReducer, locationReducer }) => {
  if (dashboardReducer.loading) {
    return {
      loading: dashboardReducer.loading,
      kpis: null,
    };
  }
  if (dashboardReducer.firstSeven.shifts_running) {
    return {
      locationId: locationReducer.location_code,
      kpis: {
        shiftsPosted: {
          thisWeek:
            dashboardReducer.firstSeven.shifts_running
              .shifts_running_all_posted_num,
          lastWeek:
            dashboardReducer.prevSeven.shifts_running
              .shifts_running_all_posted_num,
        },
        shiftsCovered: {
          thisWeek:
            dashboardReducer.firstSeven.shifts_running
              .shifts_running_net_covered_num,
          lastWeek:
            dashboardReducer.prevSeven.shifts_running
              .shifts_running_net_covered_num,
        },
        shiftsApproved: {
          thisWeek: convertPercentageStringToNumber(
            dashboardReducer.firstSeven.shifts_running
              .shifts_running_manager_approve_rate
          ),
          lastWeek: convertPercentageStringToNumber(
            dashboardReducer.prevSeven.shifts_running
              .shifts_running_manager_approve_rate
          ),
        },
        totalEmployees: {
          thisWeek: dashboardReducer.firstSeven.users_num,
          lastWeek: dashboardReducer.prevSeven.users_num,
        },
      },
      loading: false,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      dispatch(loadDashboardData());
    },
  };
};

const GeneralDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralDataComponent);

export default GeneralDataContainer;
