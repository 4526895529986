import slices from './slices';

export default {
  ...slices.pendingShifts.actions,
  ...slices.managedShifts.actions,
  ...slices.requestTimeOffs.actions,
  ...slices.managedRTOs.actions,
  ...slices.userPrivileges.actions,
  ...slices.skills.actions,
};
