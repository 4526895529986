import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

//import Shiffy from './assets/shiffy-business.svg';
import Shiffy from './assets/Master-Shyft-Logo.svg';
import './AdminClaimComponent.scss';

const AdminPendingComponent = () => (
  <article className="general-modal">
    <header className="general-modal__header">
      {/*<span className="general-modal__header-logo">
        <img src={Shiffy} className="general-modal__shiffy" />
      </span>*/}
      <img src={Shiffy} className="general-modal__logo" />
      <h3 className="general-modal__header-text">
        <FormattedMessage
          id="AdminPendingComponent.almostThere"
          defaultMessage="Almost there!"
        />
      </h3>
    </header>

    <section className="general-modal__body">
      <p className="general-modal__description">
        <FormattedMessage
          id="AdminPendingComponent.checkInbox"
          defaultMessage="We've received your admin claim request. Please check your inbox and
        follow the link to verify."
        />
        <br />
        <br />
        <FormattedMessage
          id="AdminPendingComponent.receiveEmail"
          defaultMessage="Didn't receive an email? Please contact us at support@myshyft.com."
        />
        <br />
        <br />
        <FormattedMessage
          id="AdminPendingComponent.verifyEmail"
          defaultMessage="Please note that we manually verify all personal email addresses used to claim an admin account. We appreciate your patience and will send your verification email as soon as possible."
        />
      </p>
      <br />
    </section>

    <footer className="general-modal__action-section">
      <a
        href="https://shyft.zendesk.com/hc/en-us/requests/new"
        className="button button-primary__inverse general-modal__action-button"
      >
        <FormattedMessage
          id="AdminPendingComponent.contactUs"
          defaultMessage="Contact Us"
        />
      </a>
    </footer>
  </article>
);

export { AdminPendingComponent };
