import { connect } from 'react-redux';

import { teamOperations, teamSelectors } from '../../duck';
import TeamTableComponent from './TeamTableComponent';

const mapStateToProps = ({
  teamReducer: {
    visibleMembers,
    filter: { activeFilter },
  },
  appReducer,
  locationReducer,
}) => ({
  visibleMembers,
  activeFilter,
  skills: appReducer.skills,
  locationSettings: appReducer.location.settings,
  locationId: locationReducer.location_code,
  locationDepartments: appReducer.departments.data,
});

const mapDispatchToProps = (dispatch) => ({
  getLocationUsableSkills: (locationId) =>
    dispatch(teamOperations.getLocationUsableSkills(locationId)),
});

const TeamTableContainer = connect(mapStateToProps, mapDispatchToProps)(TeamTableComponent);

export default TeamTableContainer;
