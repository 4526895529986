import filtersSlice from './filters';
import managersSlice from './managers';
import associatesSlice from './associates';
import removedUsersSlice from './removedUsers';
import pendingUsersSlice from './pendingUsers';
import channelMembersSlice from './channelMembers';
import channelsSlice from './channels';
import allUsersSlice from './allUsers';



export default {
  filtersSlice,
  managersSlice,
  associatesSlice,
  removedUsersSlice,
  pendingUsersSlice,
  channelMembersSlice,
  channelsSlice,
  allUsersSlice,
};
