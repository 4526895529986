import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';

const minHeight = '90px';

const slideIn = keyframes`
  from {
    transform: translateY(${minHeight});
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;
const slideOut = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }

  to {
    transform: translateY(${minHeight});
    opacity: 0;
  }
`;
const Wrapper = styled.div`
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index:2;
  display: flex;

  min-height: ${minHeight};

  background-color: white;
  box-shadow: 0 0 12px ${({ theme: { palette } }) => palette.border};

  animation: ${({ visible }) => (visible ? slideIn : slideOut)} 0.4s;
`;

export default function BottomSheet({ visible, children }) {
  return <Wrapper visible={visible}>{children}</Wrapper>;
}

BottomSheet.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
