import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getLocaleCookie } from 'helpers/cookies';
import { getUserInfoNonAdmin } from 'helpers/api-calls/user-calls';

import {
  shiftDuration,
  isNextDay,
  formatTimeWithTimezone,
  formatDateWithDayAndTimezone,
} from '../../../../../helpers/date-time';

import { DEFAULT_PROFILE_IMAGE_URL } from '../../../../../constants';

import './TableCell.scss';

export class UserCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
     coverer: null,
    };
    this._isMounted = false;
  }
  componentDidMount () {
    const {user, locationId, stateFilters, index, } = this.props;

    this._isMounted = true;


    if(user && user.id && user.first_name === '') {
      getUserInfoNonAdmin(user.id, locationId).then((response) => {
        this.handleSucceded(user.id, locationId, response.data);
      }).catch((error) =>{
          if(user.last_name.length === 2 && user.last_name.includes('#')) {
            const coverer = {id: 0, first_name: '', last_name: '', profile_image: {}};
            coverer.id = user.id;
            coverer.first_name = '-';
            coverer.last_name = '-';
            coverer.profile_image = '/icons/default-profile-thumb.svg';
            if(this._isMounted) {
              this.setState({coverer: coverer});
            }
          }
         const msg = `UserCell.componentDidMount: call getUserInfo failed. error: ${error}`;
         console.log(msg);
      });
    }
  };

  componentDidUpdate (prevProps, prevState) {
    const {coverer, } = this.state;
    const { user, locationId, stateFilters, index, } = this.props;

    if(prevState.coverer !== coverer) {
      if(coverer) {
        this.setState({coverer: coverer});
      }
    }

    if(prevProps.user !== user) {
      if(user && user.id && user.first_name === '') {
        getUserInfoNonAdmin(user.id, locationId).then((response) => {
          this.handleSucceded(user.id, locationId, response.data);
        }).catch((error) =>{
          if(user.last_name.length === 2 && user.last_name.includes('#')) {
            const coverer = {id: 0, first_name: '', last_name: '', profile_image: {}};
            coverer.id = user.id;
            coverer.first_name = '-';
            coverer.last_name = '-';
            coverer.profile_image = '/icons/default-profile-thumb.svg';
            if(this._isMounted) {
              this.setState({coverer: coverer});
            }
          }
          const msg = `TableCell.componentDidMount: call getUserInfo failed. error: ${error}`;
          console.log(msg);
        });
      }else {
        if(index === 4) {
          if(this._isMounted) {
            this.setState({coverer: user});
          }
        }
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  handleSucceded = (userId, locationId, data) => {
    const coverer = {id: 0, first_name: '', last_name: '', profile_image: {}};
    coverer.id = data.user.id;
    coverer.first_name = data.user.first_name;
    coverer.last_name = data.user.last_name;
    coverer.profile_image = data.user.profile_image;
    if(this._isMounted) {
      this.setState({coverer: coverer});
    }
  };
  
  render () {
    const { user, locationId} = this.props;
    const { coverer, } = this.state;
    if(user) {
      if(coverer) {
        return (
          <td className="shyft-table--cell user-cell">
            <UserAvatar
              image={
                coverer.profile_image
                  ? coverer.profile_image.thumb_url
                  //: 'https://s3.amazonaws.com/shyftassets/avatar1.png'
                  : '/icons/default-profile-thumb.svg'
              }
            />
            <div className="shyft-table--text-block">
              <p className="user-cell--main-text">
                {coverer.first_name} {coverer.last_name}
              </p>
            </div>
          </td>
        );
      }
    
      return (
        <td className="shyft-table--cell user-cell">
          <UserAvatar
            image={
              user.profile_image
                ? user.profile_image.thumb_url
                //: 'https://s3.amazonaws.com/shyftassets/avatar1.png'
                : '/icons/default-profile-thumb.svg'
            }
          />
          <div className="shyft-table--text-block">
            <p className="user-cell--main-text">
              {user.first_name} {user.last_name}
            </p>
          </div>
        </td>
      );
    }else {
      return (
        <td className="shyft-table--cell user-cell">
          <div className="shyft-table-cell--no-data" />
        </td>
      );
    }
  }
}

UserCell.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    profile_image: PropTypes.shape({
      avatar_url: PropTypes.string,
    }),
  }),
};



export class UserAvatar extends React.PureComponent {
  userThumbnailImgRef = React.createRef();

  onErrorHandler = () => {
    this.userThumbnailImgRef.current.src = '/icons/default-profile-thumb.svg';
  };

  render() {
    const { image } = this.props;

    return (
      <span className="user-cell--avatar">
        <img
          src={image}
          height="30"
          width="30"
          onError={this.onErrorHandler}
          ref={this.userThumbnailImgRef}
        />
      </span>
    );
  }
}

UserAvatar.propTypes = {
  image: PropTypes.string,
};

UserAvatar.defaultProps = {
  image: DEFAULT_PROFILE_IMAGE_URL,
};

export const DateCell = ({ date }) => (
  <td className="shyft-table--cell">
    <p className="shyft-table--text-block">
      {formatDateWithDayAndTimezone(date)}
    </p>
  </td>
);

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
};

export const DurationCell = ({ dates }) => {
  const hours = shiftDuration(dates[0], dates[1]);

  return (
    <td className="shyft-table--cell">
      <p className="shyft-table--text-block">
        {hours}&nbsp;
        <FormattedMessage
          id="TableCell.hours"
          defaultMessage={`{hours, plural,
            one {hour}
            other {hours}
          }`}
          values={{
            hours,
          }}
        />
      </p>
    </td>
  );
};

DurationCell.propTypes = {
  dates: PropTypes.array.isRequired,
};

export const StatusCell = ({ status }) => (
  <td className="shyft-table--cell">
    <p className="shyft-table--text-block">{status}</p>
  </td>
);

StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
};

export const NotesCell = ({ notes }) => (
  <td className="shyft-table--cell">
    <p className="shyft-table--text-block">{notes}</p>
  </td>
);

NotesCell.propTypes = {
  notes: PropTypes.string.isRequired,
};


export const TimeCell = ({ date }) => {
  if (Array.isArray(date)) {
    const nextDayText = (
      <FormattedMessage id="General.nextDay" defaultMessage="(next day)" />
    );
    return (
      <td className="shyft-table--cell">
        <p className="shyft-table--text-block">
          {formatTimeWithTimezone(date[1])}
          &nbsp;
          {isNextDay(date[0], date[1]) ? nextDayText : ''}
        </p>
      </td>
    );
  } else {
    return (
      <td className="shyft-table--cell">
        <p className="shyft-table--text-block">
          {formatTimeWithTimezone(date)}
        </p>
      </td>
    );
  }
};

export const ChannelCell = ({ channel }) => (
  <td className="shyft-table--cell">
    <p className="shyft-table--text-block">{channel}</p>
  </td>
);

ChannelCell.propTypes = {
  channel: PropTypes.string.isRequired,
};

export const TimeRangeCell = ({start, end}) => {
  const locale = getLocaleCookie();
  let startTime = null;
  let endTime = null;
  if(locale) {
    if(locale !== 'fr') {
      startTime = moment(start).format('h:mma');
      endTime = moment(end).format('h:mma');
    }else {
      startTime = moment(start).format('HH:mm');
      endTime = moment(end).format('HH:mm');
    }
  }else {
    startTime = moment(start).format('h:mma');
    endTime = moment(end).format('h:mma');
  }
  const start1 = startTime.slice(0, startTime.length-1);
  const end1 = endTime.slice(0, endTime.length-1);
  const timeRange = `${start1} - ${end1}`;
  return (
    <td className="shyft-table--cell">
      <p className="shyft-table--text-block">
        {timeRange}
      </p>
    </td>
  );
};

TimeRangeCell.propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
};

export const SkillsCell = ({userSkills, locationUsableSkills}) => {
  let skillNames = '';
  if(userSkills && userSkills.length > 0 && locationUsableSkills && locationUsableSkills.length > 0) {
    for(let i=0; i<userSkills.length; i++) {
      for(let j=0; j<locationUsableSkills.length; j++) {
        if(userSkills[i] === locationUsableSkills[j].id) {
          skillNames += `${locationUsableSkills[j].skill_content},`
        }
      }
    }
  }
  return (
    <td className="shyft-table--cell" title={skillNames}>
      <p className="shyft-table--text-block">
        {skillNames}
      </p>
    </td>
  );
};

SkillsCell.propTypes = {
  userSkills: PropTypes.array.isRequired,
  locationUsableSkills: PropTypes.array.isRequired
};

