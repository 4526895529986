import Channel from './Channel';
import Header from './Header';
import Post from './Post';
import Comment from './Comment';

export default {
  Channel,
  Header,
  Post,
  Comment,
};
