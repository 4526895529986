import { defineMessages } from 'react-intl';

const placeholderStrings = defineMessages({
  firstName: {
    id: 'TeamProfile.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'TeamProfile.lastName',
    defaultMessage: 'Last Name',
  },
  position: {
    id: 'TeamProfile.position',
    defaultMessage: 'Position',
  },
  badge: {
    id: 'TeamProfile.badge',
    defaultMessage: 'Employee ID',
  },
});

export { placeholderStrings };
