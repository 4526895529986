import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import TableComponent from '../TableComponent';

export const CellWrap = styled(TableComponent.Cell)`
  max-width: 275px;
  flex: 4;

  p {
    font-size: 0.88rem;
    margin-bottom: 0;
  }

  @media (max-width: 650px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

export const ThumbWrap = styled.div`
  margin-right: 0.75rem;
`;
export const InfoWrap = styled.div`
  flex: 1;
  padding-top: 4px;
  max-width: 80%;

  p {
    font-size: 0.88rem;
    margin-bottom: 0;
  }
`;

export const UserName = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  font-weight: 600;
  line-height: 0.88rem;
`;

export const UserPosition = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProfileLink = styled(Link)`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 4px;
  max-width: 80%;

  p {
    font-size: 0.88rem;
    margin-bottom: 0;
  }
`;
