import Channels from './Channels/ChannelsContainer';
import Posts from './Posts/PostsContainer';
import Comments from './Comments/CommentsContainer';
import ReadReceipts from './ReadReceipts/ReadReceiptsContainer';
import Pins from './Pins/pins-container';

export default {
  Channels,
  Posts,
  Comments,
  ReadReceipts,
  Pins,
};
