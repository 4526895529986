import { createSlice } from '@reduxjs/toolkit';

const skillsSlice = createSlice({
  name: 'skills',
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    hydrateSkills: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    addUsersToSkill: (state, action) => {
      const idx = state.data.findIndex((skill) => skill.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        const newIds = newData[idx].user_ids.concat(action.payload.user_ids);
        newData[idx].user_ids = newIds;
        state.data = newData;
      }
    },
    removeUsersFromSkill: (state, action) => {
      const idx = state.data.findIndex((skill) => skill.id === action.payload.id);
      if(idx >= 0) {
        const newData = [...state.data];
        let arrIds = newData[idx].user_ids;
        for(let i=0; i< action.payload.user_ids.length; i++) {
          const newIds = arrIds.filter((id) => id !== action.payload.user_ids[i])
          arrIds = newIds;
        }
        newData[idx].user_ids = arrIds;
        state.data = newData;
      }
    },
  },
  
  
});

export default skillsSlice;
