import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PeriodButton.scss';

class PeriodButton extends React.PureComponent {
  _handleClick = (e) => {
    e.preventDefault();
    const period = e.target.name;

    this.setState({ active: period });
    this.props._handlePeriodClick(period);
  };

  render() {
    const amClass = classNames({
      'period-btn': true,
      'period-btn--am': true,
      'period-btn--active': this.props.active === 'am',
    });
    const pmClass = classNames({
      'period-btn': true,
      'period-btn--pm': true,
      'period-btn--active': this.props.active === 'pm',
    });

    return (
      <React.Fragment>
        <button
          className={amClass}
          name="am"
          onClick={this._handleClick}
          tabIndex="-1"
        >
          AM
        </button>
        <button
          className={pmClass}
          name="pm"
          onClick={this._handleClick}
          tabIndex="-1"
        >
          PM
        </button>
      </React.Fragment>
    );
  }
}

PeriodButton.propTypes = {
  _handlePeriodClick: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};

export default PeriodButton;
