import { createSlice } from '@reduxjs/toolkit';

const currentUser = createSlice({
  name: 'currentUser',
  initialState: {
    loading: false,
    id: '',
    attributes: {
      ownerId: null,
      isAdmin: false,
      firstName: null,
      lastName: null,
      position: null,
      email: null,
      phone: null,
      profilePicture: null,
      badge_id: null,
    },
  },

  reducers: {
    requestUser: (state, action) => {
      state.loading = true;
      state.id = action.payload;
      state.attributes = {
        ownerId: null,
        isAdmin: null,
        firstName: null,
        lastName: null,
        position: null,
        email: null,
        phone: null,
        profilePicture: null,
        badge_id: null,
      };
    },

    receiveUser: (state, action) => {
      state.loading = false;
      state.attributes = {
        ...action.payload,
      };
    },

    requestUserUpdate: (state) => {
      state.loading = true;
    },

    updateUser: (state, action) => {
      const { payload } = action;
      const newUser = {};

      if (payload.firstName) newUser.firstName = payload.firstName;
      if (payload.lastName) newUser.lastName = payload.lastName;
      if (payload.position !== undefined) newUser.position = payload.position;
      if (payload.badge_id !== undefined) newUser.badge_id = payload.badge_id;
      newUser.profilePicture = payload.profilePicture;
      state.attributes = {
        ...state.attributes,
        ...newUser,
      };
    },
  },
});

export default currentUser;
