import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';

class StatusCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { user } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { user } = this.props;

    const active = (<FormattedMessage
      id="StatusCell.active"
      defaultMessage="Active" 
    />);

    const pending = (<FormattedMessage
      id="StatusCell.pending"
      defaultMessage="Pending" 
    />);

    const terminated = (<FormattedMessage
      id="StatusCell.terminated"
      defaultMessage="Terminated" 
    />);
    
    if(user && user.status === 'active') {
    	return (
        <styled.StatusActive>
          <styled.ActiveSpan/>
    		  <styled.ActiveText>{active}</styled.ActiveText>
        </styled.StatusActive>
    	);
    }else if(user && user.status === 'pending') {
      return (
        <styled.StatusPending>
          <styled.PendingSpan/>
          <styled.PendingText>{pending}</styled.PendingText>
        </styled.StatusPending>
      );
    }else if((user && user.status === 'removed' )|| (user && user.status === 'rejected') || (user && user.status === 'withdrawn')) {
      return (
        <styled.StatusFailed>
          <styled.FailedSpan/>
          <styled.FailedText>{terminated}</styled.FailedText>
        </styled.StatusFailed>
      );
    }else {
      return null;
    }
  }
}


StatusCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(StatusCell);