import React from 'react';
import { connect } from 'react-redux';
import { PubNubConsumer } from 'pubnub-react';

import {
  teamFeedOperations,
  teamFeedSelectors,
  teamFeedActions,
} from '../../duck';

import CommentsComponent from './CommentsComponent';

const mapStateToProps = ({ teamFeedReducer, teamFeedReducer: { active }, appReducer, }) => ({
  comments: teamFeedSelectors.getOrderedComments(teamFeedReducer),
  active,
  activePost: teamFeedSelectors.getActivePost(teamFeedReducer),
  activeSidePane: teamFeedSelectors.getActiveSidePane(teamFeedReducer),
  allowCreatePost: appReducer.location.settings.cfg__group_chat__post__create,
});

const mapDispatchToProps = (dispatch) => {
  const fetchComments = (post_id) => {
    dispatch(teamFeedOperations.fetchComments(post_id));
  };

  const fetchMoreComments = (channel_id, page) => {
    dispatch(teamFeedOperations.fetchMoreComments(channel_id, page));
  };

  const setActiveComment = (id) => {
    dispatch(teamFeedActions.updateActiveComment(id));
  };

  const setActiveDelete = ({ id, type }) => {
    dispatch(teamFeedActions.updateActiveDelete({ id, type }));
  };

  const createComment = (content, source_id) => {
    dispatch(teamFeedOperations.createComment(content, source_id));
  };

  const resetActiveSidePane = () => {
    dispatch(teamFeedActions.updateActiveSidePane(''));
  };

  return {
    fetchComments,
    fetchMoreComments,
    setActiveDelete,
    setActiveComment,
    createComment,
    resetActiveSidePane,
  };
};

/*
const CommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsComponent);
*/


const CommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { fetchComments,
                fetchMoreComments,
                setActiveDelete,
                setActiveComment,
                createComment,
                resetActiveSidePane,
                comments,
                active,
                activePost,
                activeSidePane,
                allowCreatePost,
                } = props;

        return (
          <CommentsComponent
            pubnub={client}
            fetchComments={fetchComments}
            fetchMoreComments={fetchMoreComments}
            setActiveDelete={setActiveDelete}
            setActiveComment={setActiveComment}
            createComment={createComment}
            resetActiveSidePane={resetActiveSidePane}
            comments={comments}
            active={active}
            activePost={activePost}
            activeSidePane={activeSidePane}
            allowCreatePost={allowCreatePost}
          />
        );
      }}
    </PubNubConsumer>
  );
});

export default CommentsContainer;
