import { connect } from 'react-redux';
import SchedulerComponent from './SchedulerComponent';

//import { teamOperations, teamUtils, teamActions } from './duck';
import { appActions } from 'App/duck';
import { userOperations } from '_data/modules/Users';
import { dashboardOperations } from '../Dashboard/duck';

const mapStateToProps = ({
  teamReducer: {
    visibleMembers,
    bottomSheet,
    search: { searchTerm, searching },
    filter: { activeFilter, filters },
  },
  //locationReducer: { location_code: locationId },
  locationReducer,
  appReducer,
}) => {
  return {
    locationId: locationReducer.location_code,
    actionJobs: appReducer.actionJobs.data,
  };
};

const mapDispatchToProps = (dispatch) => {

 const  getPendingUsers = (locationId) => {
    dispatch(userOperations.fetchPendingUsers(locationId));
 };


 const getLocationEmployeesNew = (locationId) => {
    const params = {
    locationId: locationId,
    page: 1,
  };
    dispatch(dashboardOperations.fetchAllUsers(params));
  };
  

  return { 
    getPendingUsers,
    getLocationEmployeesNew,
  };
};

const SchedulerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerComponent);

export default SchedulerContainer;
