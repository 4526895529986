import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import OwnerCell from './Cell/OwnerCell';
import StatusCell from './Cell/StatusCell';
import DetailsCell from './Cell/DetailsCell';

import './ShiftTableComponent.scss';
import styled from './styled';

class ShiftRowComponent extends React.Component {
	constructor(props) {
    super(props);

    this.state ={
      debug: true,
      
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { schedule, actionJobs, } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {
    const { schedule, actionJobs, } = this.props;

    if(prevProps.schedule !== schedule) {
      //console.log('updated');
    }

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render() {
    const { shift } = this.props;
    const shiftName = shift && shift.name ? shift.name: '';
    const shiftId = shift && shift.id ? shift.id : 0;

    const shiftIdText = (<FormattedMessage
                    id="SchedulerComponent.id"
                    defaultMessage="Schedule ID: {id}" 
                    values={{id: shiftId}}/>);
  	return (
  		<tr className="shift-row__row">
        <td className="shift-row__checkbox">
          <input type="checkbox" />
        </td>
        <td className="shift-row__owner-cell">
          <OwnerCell 
            shift={shift}
          />
        </td>
        <td>
          <DetailsCell
            shift={shift}
          />
        </td>
        <td className="shift-row__status-cell">
          <StatusCell 
            shift={shift}
          />
        </td>
        <td className="shift-row__total-cell">402</td>
        <td className="shift-row__succeeded-cell">398</td>
        <td className="shift-row__failed-cell">12</td>
        <td>...</td>
      </tr>
  	);
  }

}

ShiftRowComponent.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(ShiftRowComponent);

