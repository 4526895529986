import React from 'react';

import { teamShapes } from '../../duck';

import UserRow from '../UserRow';
import { default as Styled } from './styled';
import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`user-table--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

const SingleTable = ({ visibleMembers, locationId, skills, locationSettings, locationDepartments, }) => {
  const userRows = visibleMembers.users.map((user) => (
    <UserRow 
    key={user.owner_id} 
    user={user} 
    locationId={locationId} 
    skills={skills} 
    locationSettings={locationSettings}
    singleTable={true}
    />
  ));
  /*
  return (
    <Styled.RowGroup>
      {visibleMembers.loading ? <LoadingSpinner /> : userRows}
    </Styled.RowGroup>
  );
  */
  return (
    <table className="user-table">
      {/*!managers.loading && createHeaders()*/}
      <tbody>
         {visibleMembers.loading ? <LoadingLayer loading={visibleMembers.loading} /> : userRows}
      </tbody>
    </table>
  );
};

SingleTable.propTypes = {
  visibleMembers: teamShapes.usersShape,
};

export default SingleTable;
