import { connect } from 'react-redux';

import { teamOperations, teamActions } from '../../duck';

import ActionCellComponent from './ActionCellComponent';

const mapStateToProps = ({ setupReducer }) => {
  let currentUserPrivilegeId = null;

  if (
    setupReducer.setup_actions &&
    setupReducer.setup_actions.meta &&
    setupReducer.setup_actions.meta.current_user_privilege
  ) {
    currentUserPrivilegeId =
      setupReducer.setup_actions.meta.current_user_privilege.id;
  }

  return {
    currentUserPrivilegeId,
  };
};

const mapDispatchToProps = (dispatch) => {
  const handleAdminStatus = (user_privilege_id, is_admin) => {
    dispatch(teamOperations.updateAdminStatus(user_privilege_id, is_admin));
  };

  const handleApprove = (user_privilege_id) => {
    dispatch(teamOperations.approveUser(user_privilege_id));
  };

  const handleDeny = (user_privilege_id) => {
    dispatch(teamOperations.denyUser(user_privilege_id));
  };

  const handleBlock = (user_privilege_id) => {
    dispatch(teamOperations.blockUser(user_privilege_id));
  };

  const handleConfirmation = (user_privilege_id, selectedAction) => {
    dispatch(teamActions.updateVisibility(true));
    dispatch(teamActions.selectMember(user_privilege_id));
    dispatch(teamActions.updateSelectedAction(selectedAction));
  };

  return {
    handleAdminStatus,
    handleApprove,
    handleDeny,
    handleBlock,
    handleConfirmation,
  };
};

const ActionCellContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionCellComponent);

export default ActionCellContainer;
