import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../helpers/api-calls/feed-calls';
import UploadComponent from './components/Upload/UploadComponent';
import HeaderComponent from './components/Header/HeaderComponent';
import TableContainer from './components/TableComponents/TableContainer';
import constants from './constants';
import './SchedulerComponent.scss';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class SchedulerComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      filesToUpload: [],
      upload: false,
      actionJobs: [],
      searchTerm: '',
      selectedSort: null,
      ascending: true,
    };

    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleButtonClick = this.handleButtonClick.bind(this);

    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const { locationId, getLocationEmployeesNew, actionJobs, } = this.props;

    getLocationEmployeesNew(locationId);

  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, actionJobs, } = this.props;
    
  }
  
  ReceiveUploadClick = () => {
    this.setState({upload: true});
  }

  handleFileUploaded = (status, info) => {
    if(status) {
      this.setState({upload: false});
    }else {
      console.log(info);
      this.setState({upload: false});
    }
  }

  notifyActionJobChanged = (jobs) => {
    const { actionJobs, } = this.state;
    this.setState({actionJobs: jobs});
  }

  notifySearchTermChanged = (term) => {
    const { searchTerm, } = this.state;
    this.setState({searchTerm: term});
  }

  notifySortTypeChanged = (sort) => {
    const { selectedSort, } = this.props;
    this.setState({selectedSort: sort});
  }

  notifySortOrderChanged = (value) => {
    const { ascending, } = this.state;
    this.setState({ascending: value});
  }

  render() {
    const { upload, actionJobs, searchTerm, selectedSort, ascending, } = this.state;
    const { locationId, getLocationEmployeesNew } = this.props;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;

    const titleText = (<FormattedMessage
      id="SchedulerComponent.imports"
      defaultMessage="Imports" 
    />);

    return (
      <div className="scheduler__wrap">
        <p className="scheduler__title">{titleText}</p>
        {!upload && <HeaderComponent
          ReceiveUploadClick={this.ReceiveUploadClick}
          actionJobs={actionJobs}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifySortTypeChanged={this.notifySortTypeChanged}
          notifySortOrderChanged={this.notifySortOrderChanged}
        />}
        {!upload && <TableContainer 
          notifyActionJobChanged={this.notifyActionJobChanged}
          searchTerm={searchTerm}
          selectedSort={selectedSort}
          ascending={ascending}
        />}
        <div className="scheduler__main-body">
          {upload && <UploadComponent
            locationId={locationId}
            getLocationEmployeesNew={getLocationEmployeesNew}
            handleFileUploaded={this.handleFileUploaded}
          />}
        </div>
      </div>
    );
  }
}

SchedulerComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default SchedulerComponent;
