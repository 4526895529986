import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { SearchLocation, LocationJoin } from 'helpers/api-calls';
import { addLocation } from 'actions/dashboard-actions';
import { setLocationCookie } from 'helpers/cookies';
import backArrow from './assets/back-arrow.svg';
import LocationContainer from './LocationContainer/LocationContainer';

import './AddLocationPageCode.scss';

/**
 * I need a split page - there will be two routes
 * and then we can search with location codes
 * or store names
 */
class AddLocation extends React.Component {
  state = {
    locationCode: '',
    isValid: false,
    showError: false,
    location: {
      isLoading: false,
      hasBeenCalled: false,
      data: null,
    },
    joinError: '',
    page: null,
    locationsCalled: false,
  };

  messages = defineMessages({
    notAuthError: {
      id: 'AddLocationPageCode.notAuthError',
      defaultMessage: "Oops! You're not authorized to join this location.",
    },
    ourSideError: {
      id: 'AddLocationPageCode.ourSideError',
      defaultMessage:
        'Something went wrong while processing the information. Please try again.',
    },
    alreadyMemberError: {
      id: 'AddLocationPageCode.alreadyMemberError',
      defaultMessage:
        "It looks like you're already a member of this location! If you're having trouble accessing the location, please contact us at support@myshyft.com. Otherwise, happy Shyfting!",
    },
    codeLengthError: {
      id: 'AddLocationPageCode.codeLengthError',
      defaultMessage: 'Location Codes must be 6-10 characters long!',
    },
    locationCodePlaceholder: {
      id: 'AddLocationPageCode.locationCodePlaceholder',
      defaultMessage: 'Location code',
    },
  });

  componentDidUpdate(prevProps) {
    const { locationLength } = this.props;

    if (
      prevProps.locationLength + 1 === locationLength &&
      prevProps.locationsCalled
    ) {
      this._redirect();
    }
  }

  back = (e) => {
    e.preventDefault();
    this._redirect();
  };

  _handleLocationCodeChange = (e) => {
    const { value } = e.target;

    this.setState({
      locationCode: value,
      isValid: value.length >= 6 && value.length <= 10,
      showError: false,
    });
  };

  _handleSubmitLocationCode = (e) => {
    const { isValid } = this.state;
    e.preventDefault();

    if (isValid) {
      this._submitLocationCode();
    } else {
      this.setState({
        showError: true,
      });
    }
  };

  _submitLocationCode = () => {
    const { location, locationCode } = this.state;
    this.setState({
      location: Object.assign(location, {
        isLoading: true,
      }),
      joinError: '',
    });
    // call api
    SearchLocation(locationCode).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: response.data.location,
            }),
          });
        }

        if (response.status === 404) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        }
      },
      ({ response }) => {
        if (response && response.status === 404) {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        } else {
          this.setState({
            location: Object.assign(location, {
              hasBeenCalled: true,
              isLoading: false,
              data: null,
            }),
          });
        }
      }
    );
  };

  _confirmLocation = () => {
    const { intl } = this.props;
    const { location } = this.state;
    // call api to join location

    this.setState({
      location: Object.assign(location, {
        isLoading: true,
      }),
      joinError: '',
    });

    LocationJoin(location.data.id).then(
      (response) => {
        if (response.status === 201 || response.status === 200) {
          setLocationCookie(location.data.id);
          this.props.addedLocation(location.data.id, this._redirect);
        }
      },
      ({ response }) => {
        this.setState({
          location: Object.assign(location, {
            isLoading: false,
          }),
        });

        if (response.status === 401 || response.status === 403) {
          this.setState({
            joinError: intl.formatMessage(this.messages.notAuthError),
          });
        }

        // 422 means "unprocessable entity", so it will provide
        // the "entity" that is wrong, and then provide what is
        // wrong with the entity
        if (response.status === 500 || response.status === 422) {
          this.setState({
            joinError: intl.formatMessage(this.messages.ourSideError),
          });
        }

        if (response.status === 422) {
          if (response.data.errors.user[0] === 'You are already a team member at this location') {
            this.setState({
              joinError: intl.formatMessage(this.messages.alreadyMemberError),
            });
          }
        }
      }
    );
  };

  _redirect = () => {
    this.setState({
      page: '/',
    });
  };

  _renderInputFormErrorDescription = () => {
    const { intl } = this.props;
    const { showError } = this.state;

    if (showError) {
      return intl.formatMessage(this.messages.codeLengthError);
    }

    return '';
  };

  render() {
    const { locationsLength, intl } = this.props;
    const { showError, page, location, joinError } = this.state;
    const inputFormErrorClassName = classNames({
      'location-page--error': showError,
    });

    const BackButton = () => {
      if (locationsLength > 0) {
        return (
          <span
            role="button"
            tabIndex={0}
            className="location-page__back-button"
            onClick={this.back}
            onKeyDown={this.back}
          >
            <img src={backArrow} width="45px" height="28px" alt="Back arrow" />
          </span>
        );
      } else {
        return null;
      }
    };

    if (page) {
      return <Redirect to={page} from="/add-location" />;
    }
    return (
      <React.Fragment>
        <article className="location-page">
          <section className="location-page__row">
            <BackButton />
            <h2 className="location-page__header">
              <FormattedMessage
                id="AddLocationPageCode.enterCode"
                defaultMessage="Enter Your Location Code"
              />
            </h2>
          </section>

          <form
            className="location-page__row location-page__form"
            onSubmit={this._handleSubmitLocationCode}
          >
            <section className="login__form-group location-page__form-group">
              <input
                onChange={this._handleLocationCodeChange}
                name="location_code"
                placeholder={intl.formatMessage(
                  this.messages.locationCodePlaceholder
                )}
                className={`location-page__input-location-code ${inputFormErrorClassName}`}
              />
              <p className="location__input-error">
                {this._renderInputFormErrorDescription()}
              </p>
            </section>

            <section className="location__form-action">
              <button
                className="location__form-submit button button-primary"
                type="submit"
              >
                <FormattedMessage
                  id="AddLocationPageCode.searchBtn"
                  defaultMessage="Search"
                />
              </button>
            </section>
          </form>

          <LocationContainer
            isLoading={location.isLoading}
            hasBeenCalled={location.hasBeenCalled}
            location={location.data}
            confirm={this._confirmLocation}
            joinError={joinError}
          />
        </article>
      </React.Fragment>
    );
  }
}

const AddLocationi18n = injectIntl(AddLocation);

const mapStateToProps = ({ locationReducer }) => {
  return {
    locationLength: locationReducer.locations.length,
    locationsCalled: locationReducer.locations_called,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addedLocation: (locationCode) => {
      dispatch(addLocation(locationCode));
    },
  };
};

const AddLocationPageCode = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLocationi18n);

export { AddLocationPageCode, AddLocationi18n, AddLocation };
