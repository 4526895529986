import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';

import App from 'App';
import ls from 'helpers/localStorage';
import { APP_VERSION } from './constants';
import configureStore from './store/configureStore';
import './index.scss';

const Raven = require('raven-js');

let url;
let environment;

if (window.location.host === 'manager.myshyft.com') {
  url = 'https://6b4ef60f9b7f4e308e693c818955d09b@sentry.io/258478';
  environment = 'production';
} else if (window.location.host.indexOf('test') !== -1) {
  url = 'https://bdea1422e47b4dd8a014c7a5701f988e@sentry.io/262458';
  environment = 'test';
}

Raven.config(url, {
  release: APP_VERSION,
  environment,
}).install();

const store = configureStore();

// Write state to localStorage everytime the store updates
store.subscribe(
  throttle(() => {
    ls.saveState({
      appReducer: store.getState().appReducer,
    });
  }, 1000)
);

ReactDOM.render(
  React.createElement(App, { store }),
  document.getElementById('root')
);
