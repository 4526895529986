import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import {
  formatTermsLinkWithLocale,
  formatPrivacyLinkWithLocale,
} from 'helpers/formatting';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LanguageSelector } from '@shyft/acorns';
import { APP_VERSION } from '../constants';

import { updateUserAttributes } from 'helpers/api-calls';
import {
  setLocaleCookie,
  getUidCookie,
  getLocationCode,
} from 'helpers/cookies';

const LanguageSelectorWrap = styled.div`
  display: inline-block;
  border-right: 1px solid #ababab;
  margin-right: 10px;
  padding-right: 15px;

  button {
    font-size: 12px;
  }
`;

class Footer extends React.PureComponent {
  contactUsButtonClick = () => {
  };

  termsButtonClick = () => {
  };

  privacyPolicyButtonClick = () => {
  };

  _handleLanguageChange = (locale) => {
    const { onLocaleChange, loadSetupActions } = this.props;
    const locationId = getLocationCode();

    setLocaleCookie(locale);
    moment.locale(locale);
    onLocaleChange(locale);

    if (locationId) {
      // User is auth'd
      loadSetupActions(locationId);
      updateUserAttributes({
        user_id: getUidCookie(),
        locale,
      });
    }

     //if language changes, make sure filter=pending removed.
    if(window.location.href.indexOf('filter=pending') > -1) {
      const backToPath = "/usermanagement";
      window.history.replaceState(null, '', backToPath);
    }
  };

  render() {
    const { setupActions, supportedLocales, locale } = this.props;
    const termsLink = formatTermsLinkWithLocale(setupActions, locale);
    const privacyLink = formatPrivacyLinkWithLocale(setupActions, locale);

    return (
      <footer className="footer">
        <section className="footer-detail--container">
          <span className="footer-detail--link">
            <LanguageSelectorWrap>
              <LanguageSelector
                _languageSelectHandler={this._handleLanguageChange}
                languagesArray={supportedLocales}
                currentLang={locale}
              />
            </LanguageSelectorWrap>
            <span className="footer-detail--link-ind">{`v${APP_VERSION}`}</span>
            <a
              className="footer-detail--link-ind"
              rel="noopener noreferrer"
              target="_blank"
              href={privacyLink}
              onClick={this.privacyPolicyButtonClick}
            >
              <FormattedMessage
                id="Footer.privacyPolicy"
                defaultMessage="Privacy Policy"
              />
            </a>
            <a
              className="footer-detail--link-ind"
              rel="noopener noreferrer"
              target="_blank"
              href={termsLink}
              onClick={this.termsButtonClick}
            >
              <FormattedMessage
                id="Footer.terms"
                defaultMessage="Terms of Service"
              />
            </a>
            {/* <a
              className="footer-detail--link-ind"
              href="https://shyft.zendesk.com/hc/en-us/requests/new"
              onClick={this.contactUsButtonClick}
            >
              Contact Us
            </a> */}
            <span className="footer-detail--link-ind">
              &copy;
              <FormattedDate value={Date.now()} year="numeric" />
              &nbsp;
              <FormattedMessage
                id="Footer.copyright"
                defaultMessage="All Rights Reserved. Shyft Technologies Inc."
              />
            </span>
          </span>
        </section>
      </footer>
    );
  }
}

export default Footer;
