import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TimeInput extends React.PureComponent {
  timeInput = React.createRef();

  _blurTimeInput = () => {
    this.timeInput.current.blur();
  };

  _focusTimeInput = () => {
    this.timeInput.current.focus();
  };

  render() {
    const {
      _handleBlur,
      _handleChange,
      name,
      min,
      max,
      value,
      error,
      defaultValue,
    } = this.props;
    const classes = classNames({
      'time-picker__time-input': true,
      'time-picker__time-input--error': error,
    });

    return (
      <input
        className={classes}
        onBlur={_handleBlur}
        onChange={_handleChange}
        name={name}
        min={min}
        max={max}
        type="number"
        value={value}
        ref={this.timeInput}
        defaultValue={defaultValue}
      />
    );
  }
}

TimeInput.propTypes = {
  _handleBlur: PropTypes.func.isRequired,
  _handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TimeInput;
