import React from 'react';
import { PubNubConsumer } from 'pubnub-react';
import { connect } from 'react-redux';
import TeamComponent from './TeamComponent';

import { teamOperations, teamUtils, teamActions } from './duck';
import { appActions } from 'App/duck';
import { userOperations } from '_data/modules/Users';
import { dashboardOperations } from '../Dashboard/duck';

const mapStateToProps = ({
  teamReducer: {
    visibleMembers,
    bottomSheet,
    search: { searchTerm, searching },
    filter: { activeFilter, filters },
  },
  locationReducer: { location_code: locationId },
  appReducer,
}) => {
  return {
    visibleMembers,
    locationId,
    searchTerm,
    searching,
    activeFilter,
    filters,
    selectedAll:
      visibleMembers.users.length === visibleMembers.selectedUsers.length &&
      visibleMembers.selectedUsers.length > 0,
    bottomSheetVisible: bottomSheet.visible,
    bottomSheetCancelled: bottomSheet.cancelled,
    lastUpdatedUser: bottomSheet.lastUpdatedUser,
    lastAction: bottomSheet.lastAction,
    lastActionIds: bottomSheet.lastActionIds,
    lastFailedIds: bottomSheet.lastFailedIds,
    bulkButtonClicked: bottomSheet.clicked,
    departments: appReducer.departments.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const determineFetch = (activeFilter, params) => {
    dispatch(teamUtils.determineFetch(activeFilter, params));
  };

  const fetchTeamMetadata = (locationId, searchTerm) => {
    dispatch(teamOperations.fetchTeamMetadata(locationId, searchTerm));
  };

  const updateFilter = (filter) => {
    dispatch(teamActions.updateActiveFilter(filter));
  };

  const handlePromote = (userPrivilegeId) => {
    dispatch(teamOperations.updateAdminStatus(userPrivilegeId, true));
  };

  const handleWithdraw = (userPrivilegeId) => {
    dispatch(teamOperations.updateAdminStatus(userPrivilegeId, false));
  };

  const handleApprove = (userPrivilegeId, locationId) => {
    dispatch(teamOperations.approveUser(userPrivilegeId, locationId));
  };

  const handleDeny = (userPrivilegeId, locationId) => {
    dispatch(teamOperations.denyUser(userPrivilegeId, locationId));
  };

 const  getPendingUsers = (locationId) => {
    dispatch(userOperations.fetchPendingUsers(locationId));
 };

 const getLocationInnerDepartments = (locationId) => {
    dispatch(teamOperations.getLocationInnerDepartments(locationId));
 }

 const getLocationEmployees = (locationId) => {
    dispatch(dashboardOperations.getLocationEmployees(locationId));
 }
  

  return { 
    fetchTeamMetadata, 
    determineFetch, 
    updateFilter, 
    handlePromote, 
    handleWithdraw, 
    handleApprove, 
    handleDeny,
    getPendingUsers,
    getLocationInnerDepartments,
    getLocationEmployees,
    };
};
/*
const TeamContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamComponent);
*/

const TeamContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  return (
    <PubNubConsumer>
      {(client) => {
        const { visibleMembers,
                locationId,
                searchTerm,
                searching,
                activeFilter,
                filters,
                selectedAll,
                bottomSheetVisible,
                bottomSheetCancelled,
                lastUpdatedUser,
                lastAction,
                lastActionIds,
                lastFailedIds,
                bulkButtonClicked,
                departments,

                fetchTeamMetadata, 
                determineFetch, 
                updateFilter, 
                handlePromote, 
                handleWithdraw, 
                handleApprove, 
                handleDeny,
                getPendingUsers,
                getLocationInnerDepartments,
                getLocationEmployees,
              } = props;

        return (
          <TeamComponent
            pubnub={client}
            visibleMembers={visibleMembers}
            locationId={locationId}
            searchTerm={searchTerm}
            searching={searching}
            activeFilter={activeFilter}
            filters={filters}
            selectedAll={selectedAll}
            bottomSheetVisible={bottomSheetVisible}
            bottomSheetCancelled={bottomSheetCancelled}
            lastUpdatedUser={lastUpdatedUser}
            lastAction={lastAction}
            lastActionIds={lastActionIds}
            lastFailedIds={lastFailedIds}
            bulkButtonClicked={bulkButtonClicked}
            departments={departments}
            fetchTeamMetadata={fetchTeamMetadata}
            determineFetch={determineFetch}
            updateFilter={updateFilter}
            handlePromote={handlePromote}
            handleWithdraw={handleWithdraw}
            handleApprove={handleApprove}
            handleDeny={handleDeny}
            getPendingUsers={getPendingUsers}
            getLocationInnerDepartments={getLocationInnerDepartments}
            getLocationEmployees={getLocationEmployees}
          />
        );
      }}
    </PubNubConsumer>
  );
});


export default TeamContainer;
