import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import styled from './styled';

class DetailsCell extends React.PureComponent {
	 constructor(props) {
    super(props);

    this.state ={
      debug: true,
     
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const { schedule } = this.props;

  	this._isMounted = true;
  }

  componentDidUpdate (prevProps) {

  }

  componentWillUnmount () {

  	this._isMounted = false;
  }

  render () {
    const { shift } = this.props;

    const totalShifts = shift.available_shifts_covered + shift.available_shifts_left + shift.available_shifts_pending;
    const approved = shift.current_applicants_approved_user_ids.length;
    const applied = shift.current_applicants_pending_user_ids.length;

    const openShift = (<FormattedMessage
      id="DetailsCell.openShift"
      defaultMessage="Open Shift" 
    />);

    const teamShift = (<FormattedMessage
      id="DetailsCell.teamShift"
      defaultMessage="Team Shift" 
    />);

    const vto = (<FormattedMessage
      id="DetailsCell.vto"
      defaultMessage="Shift VTO" 
    />);

    const textOpenShifts = (<FormattedMessage
      id="OwnerCell.numOpenShifts"
      defaultMessage="{value} open shifts"
      values={{value: totalShifts}}
    />);

    const textApplied = (<FormattedMessage
      id="OwnerCell.applied"
      defaultMessage="{value} applied"
      values={{value: applied}}
    />);

    const textApproved = (<FormattedMessage
      id="OwnerCell.approved1"
      defaultMessage="{value} approved"
      values={{value: applied}}
    />);
    
    let shiftType = '';
    switch(shift.name) {
    case 'open_shift':
      shiftType = openShift;
      break;
    case 'shift':
      shiftType = teamShift;
      break;
    case 'shift_vto':
      shiftType = vto;
      break;
    }

    let bottomLine = null;
    if(shift.name === 'open_shift') {
      bottomLine =     
          <styled.BottomLineWrap>
            <styled.BottomLineText>{textOpenShifts}</styled.BottomLineText>
            <styled.Dot/>
            <styled.BottomLineText>{textApplied}</styled.BottomLineText>
            <styled.Dot/>
            <styled.BottomLineText>{textApproved}</styled.BottomLineText>
          </styled.BottomLineWrap>
    }else if(shift.name === 'shift') {

    }else if(shift.name === 'shift_vto') {

    }else {

    }

    const primaryJob = shift && shift.primary_job ? shift.primary_job : '';
    
    return (
      <styled.DetailsWrap>
        <styled.ScheduleIcon />
        <styled.ContentWrap>
          <styled.TopLineWrap>
            <styled.TopLineText>
            {shiftType}
            </styled.TopLineText>
            <styled.Dot />
            <styled.TopLineText>
              {primaryJob}
            </styled.TopLineText>
          </styled.TopLineWrap>
          {bottomLine}
        </styled.ContentWrap>
      </styled.DetailsWrap>
    );
    
  }
}


DetailsCell.propTypes = {
  //locationId: PropTypes.string.isRequired,
};

export default injectIntl(DetailsCell);