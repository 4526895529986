import styled from 'styled-components';

import { LoadingSpinner } from 'components/GeneralComponents/LoadingSpinner';

const Loading = styled(LoadingSpinner).attrs(() => ({
  size: 'small',
  color: 'blue',
}))`
  margin-top: 1rem;
`;

export default Loading;
