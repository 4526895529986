import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';

import ScheduleIconPath from '../../../assets/schedule-icon.svg';


 /* 

const UserAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 13px;
  height: auto;
  margin-right: 0.25rem;
  margin-top: 4px;

  //border: 1px solid red;
`;

const Text = styled.p`
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.palette.label};
  margin-bottom: 0;
  flex: 1;

  ${applyStyleModifiers({
    large: () => `
      font-size: ${rem('14px')};
      color: inherit;
    `,
  })}
  //border: 1px solid green;
`;



const RequestText = styled.p`
  font-size: ${rem('14px')};
  //color: ${({ theme }) => theme.palette.label};
  color: black;
  margin-left: 10px;
  flex: 1;
  display: block;
  //white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
 
  //border: 1px solid green;
`;

const TopWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 70%;
  //border: 1px solid black;

`;




const HeaderText = styled.p `
  //font-size: 30px;
  width: 380px;
  margin-left: 370px;
  margin-bottom: 0px;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  //border: 1px solid red;
`;

const SubmitText = styled.p `
  //width: 380px;
  margin-left: 370px;
  margin-top: 0px;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: rgba(147,147,147,255);
  //border: 1px solid red;
`;




const Element = styled.div `
  width: ${(props) => props.width || '120px'};
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 15px;
  //border: 1px solid blue;

  &: last-child {
    margin-right: 50px;
  }
`;



const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['x-small'].concat(modifiers),
}))`
  width: 200px;
  margin-bottom: 0.5rem;
  //font-size: 24px;
  align-items: center;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;

  &: first-child {
    margin-left: 300px;
  }

  &: last-child {
    width: 300px;
    margin-left: 30px;
    margin-right: 300px;
  }

  &: focus {
    outline: none;
  }
`;
*/

const TopWrap = styled.div `
  display: flex;
  flex-direction: row;
  //border: 1px solid blue;

`;

const OwnerAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const OwnerInfoWrap = styled.div `
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  //border: 1px solid green;
`;

const OwnerInfoTopLine = styled.p `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  //border: 1px solid red;
`;

const OwnerInfoBottomLine = styled.p `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 12px;
  font-weight: 400;
  //border: 1px solid red;
`;

const StatusProgress = styled.div `
  display: flex;
  flex-direction: row;
  width: 120px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,238,214,255);
  border: 1px solid rgba(255,142,0,255);
`;
const ProgressSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,142,0,255);
`;

const ProgressText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
`;

const StatusCompleted = styled.div `
  display: flex;
  flex-direction: row;
  width: 120px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(242,242,247,255);
  border: 1px solid rgba(120,127,135,255);
`;
const CompletedSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(108,114,121,255);
`;

const CompletedText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
`;

const StatusFailed = styled.div `
  display: flex;
  flex-direction: row;
  width: 120px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,227,229,255);
  border: 1px solid rgba(255,41,71,255);
`;
const FailedSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,41,71,255);
`;

const FailedText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
`;

const DetailsWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  //border: 1px solid blue;
`;

const ScheduleIcon = styled.img.attrs({
  src: ScheduleIconPath,
})`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  //border: 1px solid red;
`;

const TopLineWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  //border: 1px solid blue;
`;

const BottomLineWrap = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  //border: 1px solid red;
`;

const TopLineText = styled.p `
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  //border: 1px solid red;
`;

const Dot = styled.span `
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  //border: 1px solid green;
`;

const BottomLineText = styled.p `
  @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
  }
  font-family: 'AvertaLight';
  font-size: 10px;
  font-weight: 400;
  margin: auto;
  //padding-left: 5px;
  //border: 1px solid red;
`;


export default {
  TopWrap,
  OwnerAvatar,
  OwnerInfoWrap,
  OwnerInfoTopLine,
  OwnerInfoBottomLine,
  StatusProgress,
  ProgressSpan,
  ProgressText,
  StatusCompleted,
  CompletedSpan,
  CompletedText,
  StatusFailed,
  FailedSpan,
  FailedText,
  DetailsWrap,
  ScheduleIcon,
  ContentWrap,
  TopLineWrap,
  BottomLineWrap,
  TopLineText,
  Dot,
  BottomLineText,
};
