import React from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import { teamShapes } from './duck';
import TeamHeader from './components/TeamHeader';
import TeamTable from './components/TeamTable';
import TeamConfirmation from './components/TeamConfirmation';
import { getUserId } from '../../helpers/cookies';
import ProgressBar from './ProgressBar';
import styled from './styled';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const sha1 = require('js-sha1');

class TeamComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pubnub = props.pubnub;
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.state ={
      selectedUsers: [],
      selectedAction: '',
      remainingUsers: [],
      bulkUsers: 0,
      bulkInProgress: false,
      selectedAllUsers: false,
      processedUsers: [],
    };

    this._isMounted = false;
  }

  componentDidMount() {
    const {
      fetchTeamMetadata,
      determineFetch,
      activeFilter,
      updateFilter,
      filters,
      searchTerm: search_term,
      locationId: location_id,
      getPendingUsers,
      getLocationInnerDepartments,
      departments,
      getLocationEmployees,
    } = this.props;

    const filter = getParameterByName('filter');

    this.pubnubSubscribe();

    fetchTeamMetadata(location_id, search_term);
    getLocationInnerDepartments(location_id);
    //getLocationEmployees(location_id);

    if (filter) {
      updateFilter(filter);
    } else {
      determineFetch(activeFilter, {
        location_id,
        page: 1,
        search_term,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activeFilter,
      filters,
      searching,
      locationId: location_id,
      searchTerm: search_term,
      selectedAll,
      determineFetch,
      visibleMembers: { loading, users, page, totalPages },
      bottomSheetVisible,
      bottomSheetCancelled,
      handlePromote, 
      handleWithdraw,
      handleApprove,
      handleDeny,
      lastUpdatedUser,
      lastAction,
      lastActionIds,
      lastFailedIds,
      bulkButtonClicked,
      fetchTeamMetadata,
    } = this.props;

    const { selectedUsers, selectedAction, remainingUsers, bulkUsers, selectedAllUsers, processedUsers, } = this.state;

    // Fetches new users when new filter is selected
    if (
      prevProps.activeFilter !== activeFilter ||
      prevProps.searching !== searching ||
      (prevProps.locationId !== location_id && !loading)
    ) {
      determineFetch(activeFilter, {
        location_id,
        page: 1,
        per_page: 25,
        search_term,
      });
      fetchTeamMetadata(location_id, search_term);
    }
    
    if(prevProps.bottomSheetVisible !== bottomSheetVisible) {
      if(prevProps.bottomSheetVisible && !bottomSheetVisible) {
        if(!bottomSheetCancelled) {
          
        }
      }
    }
    
    if(prevProps.lastUpdatedUser !== lastUpdatedUser) {
      if(selectedUsers && selectedUsers.length > 1) {
        if(lastUpdatedUser === selectedUsers[0]) {
          for(let i=1; i<selectedUsers.length; i++) {
            if(selectedAction === 'WITHDRAW') {
              handleWithdraw(selectedUsers[i]);
            }
            if(selectedAction === 'PROMOTE') {
              handlePromote(selectedUsers[i]);
            }
          }
        }  
      }
    }
    
    if(prevProps.lastActionIds !== lastActionIds) {
      let processed = [...processedUsers];
      processed = processed.concat(lastActionIds);
      this.setState({processedUsers: processed});

      if(processed.length > selectedUsers.length) {
        //If this happens, bulkButtonClicked event must missed!
        this.setState({processedUsers: []});
        this.setState({bulkInProgress: false});
      }

    }

    if(prevProps.lastFailedIds !== lastFailedIds) {
      if(lastAction === 'approve') {
        //handleApprove(lastFailedIds,location_id); //Don't call again! endless loop
      }else {
        //handleDeny(lastFailedIds, locationId_id); //Don't call again! endless loop
      }
    }

    if(prevProps.bulkButtonClicked !== bulkButtonClicked) {
      if(bulkButtonClicked) {
        this.setState({bulkInProgress: true});
      }else {
        this.setState({bulkInProgress: false});
        if(remainingUsers && remainingUsers.length > 0) {
          let bulkMax = [];
          if(remainingUsers.length > bulkUsers) {
            bulkMax = remainingUsers.slice(0, bulkUsers);
            const usersTohandle = remainingUsers.slice(bulkUsers);
            this.setState({remainingUsers: usersTohandle});
          }else {
            bulkMax = remainingUsers;
            this.setState({remainingUsers: []});
          }
          if(lastAction === 'approve') {
            handleApprove(bulkMax,location_id);
          }else {
            handleDeny(bulkMax, location_id);
          }
        }else {
          this.setState({processedUsers: []});
          if(selectedAllUsers && users.length === 0) {
            if(filters[activeFilter].count > 0) {
              determineFetch(activeFilter, {
                location_id,
                page: 1,
                per_page: 25,
                search_term,
              });
              fetchTeamMetadata(location_id, search_term);
            }
          }
        }
      }
    }

    if(prevProps.visibleMembers.users !== users) {
      if(prevProps.visibleMembers.users && prevProps.visibleMembers.users.length > 0) {
        if(users.length < prevProps.visibleMembers.users.length && selectedAction === 'ACTIVE_BULK' && activeFilter !== 'removed') {
          determineFetch(activeFilter, {
            location_id,
            page: 1,
            per_page: selectedUsers.length,
            search_term,
          });
        }
        if(users.length !== prevProps.visibleMembers.users.length) {
          fetchTeamMetadata(location_id, search_term);
        }
      }
    }
  }

  componentWillUnmount() {
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
  }

  pubnubSubscribe = () => {
    const { handleShiftUpdate } = this.props;

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'shift' &&
          event.message.crud === 'UPDATE'
        ) {
          console.log(event);
        }
      },
    });
  };

  handleOnEnter = () => {
    const {
      visibleMembers: { page, totalPages },
      determineFetch,
      activeFilter,
      selectedAll,
      locationId: location_id,
      searchTerm: search_term,
      fetchTeamMetadata,
    } = this.props;

    if (page < totalPages) {
      determineFetch(activeFilter, {
        location_id,
        page: page + 1,
        search_term,
        selectedAll,
      });
      fetchTeamMetadata(location_id, search_term);
    }
  };

  receiveSelectedUsers = (users, action, bulkUsers) => {
    const { handlePromote, handleWithdraw, visibleMembers} = this.props;
    const isSelectAction = action.indexOf('SELECT_') >= 0;
    this.setState({selectedUsers: users});
    if(visibleMembers.users.length === users.length) {
      this.setState({selectedAllUsers: true});
    }
    if(!isSelectAction) {
      switch(action) {
        case 'WITHDRAW':
          this.setState({selectedAction: 'WITHDRAW'});
          break;
        case 'PROMOTE':
          this.setState({selectedAction: 'PROMOTE'});
          break;
        case 'PENDING_BULK':
          this.setState({selectedAction: 'PENDING_BULK', bulkUsers: bulkUsers});
          if(users.length > bulkUsers) {
            const usersTohandle = users.slice(bulkUsers);
            this.setState({remainingUsers: usersTohandle});
          }
          break;
      }
    }else {
      switch(action) {
        case 'SELECT_ACTIVE_BULK':
          this.setState({selectedAction: 'ACTIVE_BULK'});
          break;
      }
    }
  };


  render() {
    const { visibleMembers: { loading, page }, lastAction, } = this.props;
    const { bulkInProgress, remainingUsers, selectedUsers, processedUsers, } = this.state;
    const percentage = (processedUsers.length/selectedUsers.length) *100
    
    if (bulkInProgress) {
      return (
          <PageWrap>
            <ProgressBar percentage={percentage} lastAction={lastAction}/>
          </PageWrap> 
      );
    }
    
    return (
      <PageWrap>
        <TeamHeader />
        <TeamTable />
        <TeamConfirmation 
          receiveSelectedUsers={this.receiveSelectedUsers} 
        />
        <InfiniteScroll
          isLoading={loading && page === 1}
          onEnter={this.handleOnEnter}
          bottomOffset="-70%"
        />
      </PageWrap>
    );
  }
}

TeamComponent.propTypes = {
  visibleMembers: teamShapes.usersShape.isRequired,
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default TeamComponent;
