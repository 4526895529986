import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  formatTimeWithTimezone,
  formatDateWithTimezone,
} from 'helpers/date-time';

const CoverRequestString = ({ owner, coverer, startAt, endAt, type }) => {
  const covererName = coverer ? `${coverer.first_name} ${coverer.last_name}`: null;
  const ownerName = `${owner.first_name} ${owner.last_name}`;
  const startTime = formatTimeWithTimezone(startAt);
  const endTime = formatTimeWithTimezone(endAt);
  const date = formatDateWithTimezone(startAt);

  if (type === 'shift_vto') {
    return (
      <FormattedMessage
        id="CoverRequestString.vtoString"
        defaultMessage="{covererName} has volunteered to take time off from {startTime} - {endTime} on {date}"
        values={{
          covererName: <b>{covererName}</b>,
          startTime: <b>{startTime}</b>,
          endTime: <b>{endTime}</b>,
          date: <b>{date}</b>,
        }}
      />
    );
  }

  if (owner.position) {
    return (
      <FormattedMessage
        id="CoverRequestString.noPosition"
        defaultMessage="{covererName} wants to cover {ownerName} shift as a {position} from {startTime} - {endTime} on {date}"
        values={{
          covererName: <b>{covererName}</b>,
          ownerName: <b>{ownerName}'s</b>,
          position: <b>{owner.position}</b>,
          startTime: <b>{startTime}</b>,
          endTime: <b>{endTime}</b>,
          date: <b>{date}</b>,
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="CoverRequestString.withPosition"
      defaultMessage="{covererName} wants to cover {ownerName} shift from {startTime} - {endTime} on {date}"
      values={{
        covererName: <b>{covererName}</b>,
        ownerName: <b>{ownerName}'s</b>,
        startTime: <b>{startTime}</b>,
        endTime: <b>{endTime}</b>,
        date: <b>{date}</b>,
      }}
    />
  );
};

CoverRequestString.propTypes = {
  owner: PropTypes.shape({}),
  coverer: PropTypes.shape({}),
  startAt: PropTypes.string,
  endAt: PropTypes.string,
};

export default CoverRequestString;
