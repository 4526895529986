import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  posts: slices.postsSlice.reducer,
  comments: slices.commentsSlice.reducer,
  active: slices.activeSlice.reducer,
  readReceipts: slices.readReceiptsSlice.reducer,
};

const reducer = combineReducers({
  posts: reducers.posts,
  comments: reducers.comments,
  active: reducers.active,
  readReceipts: reducers.readReceipts,
});

export default reducer;
