import { connect } from 'react-redux';
import Footer from 'components/Footer';
import { localeChanged, loadSetupActions } from 'actions/dashboard-actions';

const mapStateToProps = ({ setupReducer, localeReducer }) => {
  return {
    setupActions: setupReducer.setup_actions,
    locale: localeReducer.locale,
    supportedLocales: localeReducer.supportedLocales,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLocaleChange: (locale) => {
      dispatch(localeChanged(locale));
    },
    loadSetupActions(id) {
      dispatch(loadSetupActions(id));
    },
  };
};

const FooterContainer = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default FooterContainer;
