import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Row = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: flex-start;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  position: relative; // for view details button position
  //border: 1px solid green;
`;

Row.propTypes = {
  marginBottom: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
};

Row.defaultProps = {
  marginBottom: '0',
  direction: 'row',
};

export default Row;
