import { connect } from 'react-redux';

import { dashboardOperations } from '../duck';
import ApplicantRow from './ApplicantRow';


const mapStateToProps = ({ dashboardDuckReducer, setupReducer,  }) => {
  return {
    shifts: dashboardDuckReducer.pendingShifts.data,
    locationConfig:
      setupReducer.setup_actions.meta.current_location.configuration,
    userImgUrl: 
      setupReducer.setup_actions.meta.current_user.gallery_url,
    userInfo: 
    setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user: null,
    userPrivileges: dashboardDuckReducer.userPrivileges.data,
    user: setupReducer.setup_actions.meta.current_user,

  };
};

const mapDispatchToProps = (dispatch) => {

  const fetchShiftApplicants = (id, userPrivileges) => {
    dispatch(dashboardOperations.fetchShiftApplicants(id, userPrivileges));
  };

  const handleUpdateApplicantStatus = (shiftId, applicantId, approve) => {
    if(approve){
        dispatch(dashboardOperations.approveApplicant(shiftId, applicantId));
    }else{
        dispatch(dashboardOperations.denyApplicant(shiftId, applicantId));
    }
  };

  const denyChildShift = (shift) => {
    dispatch(dashboardOperations.denyPendingShift(shift));
  };

  const approveChildShift = (shift) => {
    dispatch(dashboardOperations.approvePendingShift(shift));
  };

  return {
    fetchShiftApplicants,
    handleUpdateApplicantStatus,
    denyChildShift,
    approveChildShift,
  };
  
};
const ApplicantRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantRow);

export default ApplicantRowContainer;