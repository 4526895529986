import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavbarComponent from './NavbarComponent';
/*
const mapStateToProps = ({ appReducer: { location } }) => ({
  locationSettings: location.settings,
});
*/
const mapStateToProps = ({ appReducer, setupReducer }) => {
  return {
    locationSettings: appReducer.location.settings,
    user: setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user : null,
    
  }
};

const NavbarContainer = withRouter(connect(mapStateToProps)(NavbarComponent));

export default NavbarContainer;
