import { connect } from 'react-redux';

import { default as actions } from '../../duck/actions';
import TeamFiltersComponent from './TeamFiltersComponent';

const mapStateToProps = ({ teamReducer: { filter, visibleMembers } }) => {
  return {
    activeFilter: filter.activeFilter,
    filters: filter.filters,
    loading: visibleMembers.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  const updateFilter = (filter) => {
    dispatch(actions.updateActiveFilter(filter));
  };

  return { updateFilter };
};

const TeamFiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamFiltersComponent);

export default TeamFiltersContainer;
