import styled from 'styled-components/macro';
import { rgba } from 'polished';

const TeamButton = styled.button`
  font-size: 0.88rem;
  font-weight: 600;
  background-color: ${({ theme }) => theme.palette.primary[0]};
  color: white;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.primary[0]};
  border-radius: 6px;
  padding: 5px 8px;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.palette.primary[0]};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 6px
      ${({ theme }) => rgba(theme.palette.primary[0], 0.33)};
  }
`;

const TableRow = styled.tr`
  height: 75px;
  //border: 1px solid red;
`;
const SelectCell = styled.td`
  //border: 1px solid blue;
`;
const UserCell = styled.td`
  min-width: 250px;
  padding-right: 10px;
  //border: 1px solid blue;
`;
const DeptCell = styled.td`
  min-width: 300px;
  padding-right: 20px;
  //border: 1px solid blue;
`;
const SkillCell = styled.td`
  min-width: 320px;
  padding-right: 20px;
  //border: 1px solid blue;
`;

export default {
  TeamButton,
  TableRow,
  SelectCell,
  UserCell,
  DeptCell,
  SkillCell,
};
