import axios from 'axios';
import moment from 'moment';

import teamCalls from 'helpers/api-calls/team-calls';
import { GetLocationUsableSkills, } from 'helpers/api-calls/skill-calls';
import { GetLocationInnerDepartments, } from 'helpers/api-calls/department-calls';
import actions from './actions';
import constants from './constants';
import utils from './utils';
import { appActions } from 'App/duck';

const { statusTypes } = constants;

/**
 * Gets all users with a status of ACTIVE and inserts them in state
 *
 * @param  {Object} params
 * @param  {number} params.location_id
 * @param  {number} params.page
 * @param  {string} [params.search_term]
 */
const fetchActive = (params) => (dispatch) => {
  const managerConfig = {
    ...params,
    filter: statusTypes.ACTIVE,
    per_page: 100,
    admin_status: true,
    page: 1,
  };
  const associateConfig = {
    ...params,
    filter: statusTypes.ACTIVE,
    per_page: 15,
    admin_status: false,
  };

  if (params.page > 1) {
    dispatch(actions.requestMembers(associateConfig.page));

    return teamCalls.getTeamMembers(associateConfig).then((response) => {
      utils.appendMembersCb(response, dispatch);

      if (params.selectedAll) {
        dispatch(actions.selectAllMembers());
      }
    });
  }

  dispatch(actions.requestMembers(associateConfig.page));
  const start = moment();
  return axios
    .all([
      teamCalls.getTeamMembers(managerConfig),
      teamCalls.getTeamMembers(associateConfig),
    ])
    .then(
      axios.spread((managers, associates) => {
        const { user_privileges: managersList } = managers.data;
        const {
          user_privileges: associatesList,
          meta: { pagination },
        } = associates.data;
        const visibleMembers = managersList.concat(associatesList);
        const payload = {
          users: visibleMembers,
          page: pagination.current_page,
          totalPages: pagination.total_pages,
        };

        dispatch(actions.recieveMembers(payload));
        const end = moment();
        const diff = moment.duration(end.diff(start))
        const ms = parseInt(diff.asMilliseconds());
        const msg = `getTeamMembers took ${ms} ms.`;
        console.log(msg);
      })
    );
};

/**
 * Gets all users with a status of PENDING and inserts them in state
 *
 * @param  {Object} params
 * @param  {number} params.location_id
 * @param  {number} params.page
 * @param  {string} [params.search_term]
 */
const fetchPending = (params) => (dispatch) => {
  const getTeamMembersParams = {
    ...params,
    filter: statusTypes.PENDING,
  };

  if (params.page > 1) {
    return teamCalls.getTeamMembers(getTeamMembersParams).then((response) => {
      utils.appendMembersCb(response, dispatch);

      if (params.selectedAll) {
        dispatch(actions.selectAllMembers());
      }
    });
  }

  dispatch(actions.requestMembers(params.page));

  return teamCalls.getTeamMembers(getTeamMembersParams).then((response) => {
    utils.recieveMembersCb(response, dispatch);
  });
};

/**
 * Gets all users with a status of REMOVED and inserts them in state
 *
 * @param  {Object} params
 * @param  {number} params.location_id
 * @param  {number} params.page
 * @param  {string} [params.search_term]
 */
const fetchRemoved = (params) => (dispatch) => {
  if (params.page > 1) {
    return teamCalls.getRemovedTeamMembers({ ...params }).then((response) => {
      utils.appendMembersCb(response, dispatch);
      /*
      const users = response.data.user_privileges;
      if(users && users.length > 0) {
        for(let i=0; i<users.length; i++) {
          if(users[i].first_name === 'Peter193' || users[i].first_name === 'Peter194' || users[i].first_name === 'Peter195') {
            if(users[i].status === 'rejected') {
              const msg = `${users[i].first_name} ${users[i].last_name} status: ${users[i].status}`;
              console.log(msg)
            }
          }
        }
      }
      */
      if (params.selectedAll) {
        dispatch(actions.selectAllMembers());
      }
    });
  }

  dispatch(actions.requestMembers(params.page));

  return teamCalls.getRemovedTeamMembers({ ...params }).then((response) => {
    utils.recieveMembersCb(response, dispatch);
  });
};

/**
 * Gets count of users with ACTIVE, and PENDING statuses and sets state
 *
 * @param  {number} location_id
 * @param  {string} [search_term]
 */
const fetchTeamMetadata = (locationId, searchTerm) => (dispatch) =>
  axios
    .all([
      teamCalls.getTeamMetadata(locationId, searchTerm, true),
      teamCalls.getTeamMetadata(locationId, searchTerm, null, true),
    ])
    .then(
      axios.spread((active, pending) => {
        const {
          data: {
            meta: {
              pagination: { total_count: activeCount },
            },
          },
        } = active;
        const {
          data: {
            meta: {
              pagination: { total_count: pendingCount },
            },
          },
        } = pending;
        const payload = {
          [statusTypes.ACTIVE]: { count: activeCount },
          [statusTypes.PENDING]: { count: pendingCount },
          [statusTypes.REMOVED]: { count: 0 },
        };

        dispatch(actions.recieveMetadata(payload));
      })
    );

/**
 * Can withdraw admin priv. from a user or promote them and updates user data in state
 *
 * @param  {Number} userPrivilegeId
 * @param  {Boolean} is_admin true to promote and false to demote
 */
const updateAdminStatus = (userPrivilegeId, is_admin) => (dispatch) => {
  if (Array.isArray(userPrivilegeId)) {
    return teamCalls.putUserPrivilege(userPrivilegeId[0], { is_admin }).then((response) => {
      dispatch(actions.updateVisibility(false));
      dispatch(actions.updateMember(response.data.user_privilege));
      dispatch(actions.deselectMember(userPrivilegeId[0]));
      dispatch(actions.updateLastUser(userPrivilegeId[0]));
    });
  }

  return teamCalls.putUserPrivilege(userPrivilegeId, { is_admin }).then((response) => {
    dispatch(actions.updateVisibility(false));
    dispatch(actions.updateMember(response.data.user_privilege));
    dispatch(actions.deselectMember(userPrivilegeId));
    dispatch(actions.updateLastUser(userPrivilegeId));
  });
}

/**
 * Approves pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {Number} userPrivilegeId
 */
const approveUser = (userPrivilegeId, locationId) => (dispatch) => {
  if ((Array.isArray(userPrivilegeId)) && userPrivilegeId.length > 1) {
    dispatch(actions.updateButtonState(true));
    const start = moment();
    return teamCalls.bulkApprove(userPrivilegeId, locationId).then(() => {
      dispatch(actions.updateVisibility(false));
      dispatch(actions.removeMembers(userPrivilegeId));
      dispatch(
        actions.decrementCount({
          filter: statusTypes.PENDING,
          amount: userPrivilegeId.length,
        })
      );
      dispatch(actions.updateLastAction({name: 'approve', ids: userPrivilegeId}));
      dispatch(actions.updateButtonState(false));
      dispatch(appActions.removeMembers(userPrivilegeId));
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const s = `Approving ${userPrivilegeId.length} users took ${ms} ms.`;
      console.log(s);
    }).catch((error) => {
      dispatch(actions.updateLastError({name: 'approve', ids: userPrivilegeId}));
      dispatch(actions.updateButtonState(false));
      const msg = `Team operation approveUser error: ${error}`;
      console.log(msg);
    });
  }

  return teamCalls
    .putUserPrivilege(userPrivilegeId, { is_approved: true })
    .then((response) => {
      dispatch(actions.updateVisibility(false));
      dispatch(actions.removeMember(response.data.user_privilege.id));
      dispatch(
        actions.decrementCount({
          filter: statusTypes.PENDING,
          amount: 1,
        })
      );
    });
};

/**
 * Denies pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {Number} userPrivilegeId
 * @param  {Number} locationId
 */
const denyUser = (userPrivilegeId, locationId) => (dispatch) => {
  if ((Array.isArray(userPrivilegeId)) && userPrivilegeId.length > 1 ){
    dispatch(actions.updateButtonState(true));
    const start = moment();
    return teamCalls.bulkDeny(userPrivilegeId, locationId).then(() => {
      dispatch(actions.updateVisibility(false));
      dispatch(actions.removeMembers(userPrivilegeId));
      dispatch(
        actions.decrementCount({
          filter: statusTypes.PENDING,
          amount: userPrivilegeId.length,
        })
      );
      dispatch(actions.updateLastAction({name: 'deny', ids: userPrivilegeId}));
      dispatch(actions.updateButtonState(false));
      dispatch(appActions.removeMembers(userPrivilegeId));
      const end = moment();
      const diff = moment.duration(end.diff(start))
      const ms = parseInt(diff.asMilliseconds());
      const s = `Denying ${userPrivilegeId.length} users took ${ms} ms.`;
      console.log(s);
    }).catch((error) => {
      dispatch(actions.updateLastError({name: 'deny', ids: userPrivilegeId}));
      dispatch(actions.updateButtonState(false));
      const msg = `Team operation denyUser error: ${error}`;
      console.log(msg);
    });
  }

  return teamCalls.deleteUserPrivilege(userPrivilegeId, 'decline').then(() => {
    dispatch(actions.updateVisibility(false));
    dispatch(actions.removeMember(userPrivilegeId));
    dispatch(
      actions.decrementCount({
        filter: statusTypes.PENDING,
        amount: 1,
      })
    );
  });
};

/**
 * Blocks user from joining location and updates user data in state
 *
 * @param  {Number} userPrivilegeId
 */
const blockUser = (userPrivilegeId) => (dispatch) =>
  teamCalls.deleteUserPrivilege(userPrivilegeId, 'block').then(() => {
    dispatch(actions.updateVisibility(false));
    dispatch(actions.updateMember(userPrivilegeId));
    dispatch(actions.updateVisibility(false));
  });

/**
 * Removes user from location and removes them from view
 *
 * @param  {Number} userPrivilegeId
 * @param  {Number} locationId
 */
const removeUser = (userPrivilegeId, locationId) => (dispatch) => {
  
  //if (Array.isArray(userPrivilegeId)) {
    return teamCalls.bulkRemove(userPrivilegeId, locationId).then(() => {
      dispatch(actions.updateVisibility(false));
      dispatch(actions.removeMembers(userPrivilegeId));
    });
  //}
  /*
  return teamCalls.deleteUserPrivilege(userPrivilegeId, 'remove').then(() => {
    dispatch(actions.updateVisibility(false));
    dispatch(actions.removeMember(userPrivilegeId));
  });
  */
};

/**
 * Updates visibility of bottom sheet to hidden and then erases all members
 * from selectedUsers array
 */
const cancelBottomSheet = () => (dispatch) => {
  dispatch(actions.updateVisibility(false));
  dispatch(actions.deselectAllMembers());
  dispatch(actions.updateCancelled(true));
};



const getLocationUsableSkills = (locationId) => (dispatch) => 
  GetLocationUsableSkills(locationId).then(({data}) =>{
    //dispatch(actions.hydrateSkills(data.skills));
    dispatch(appActions.hydrateSkills(data.skills));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationUsableSkills returns error = ${error}`;
    console.log(msg);
  });

const getLocationInnerDepartments = (locationId) => (dispatch) => 
  GetLocationInnerDepartments(locationId).then(({data}) =>{
    dispatch(appActions.hydrateDepartments(data.departments));
  }).catch((error) =>{
    const msg = `${locationId}: GetLocationInnerDepartments returns error = ${error}`;
    console.log(msg);
  });

export default {
  cancelBottomSheet,
  fetchTeamMetadata,
  fetchActive,
  fetchPending,
  fetchRemoved,
  updateAdminStatus,
  approveUser,
  denyUser,
  blockUser,
  removeUser,
  getLocationUsableSkills,
  getLocationInnerDepartments,
};
