import React from 'react';
import PropTypes from 'prop-types';

import styles from './styled';
import BottomSheet from 'pages/Team/components/BottomSheet';

import styled from 'styled-components/macro';

import { FormattedMessage } from 'react-intl';
import { Button } from '@shyft/acorns';

const ActionBtn = styled(Button).attrs(({ modifiers }) => ({
  modifiers: ['small'].concat(modifiers),
}))`
  margin-left: 1.25rem;
`;

const ProfileConfirmationComponent = ({ visible, confirm, cancel }) => {
  if (!visible) {
    return null;
  }

  return (
    <BottomSheet visible={visible}>
      <styles.Wrapper>
        <styles.BodyText>
          <FormattedMessage
            id="BottomSheet.saveUserProfile"
            defaultMessage="Save these changes?"
          />
        </styles.BodyText>
        <styles.ActionWrap>
          <ActionBtn modifiers={['danger']} onClick={cancel}>
            <FormattedMessage
              id="confirmButton.cancelProfile"
              defaultMessage="Cancel"
            />
          </ActionBtn>
          <ActionBtn modifiers={['success']} onClick={confirm}>
            <FormattedMessage
              id="confirmButton.confirmProfile"
              defaultMessage="Confirm"
            />
          </ActionBtn>
        </styles.ActionWrap>
      </styles.Wrapper>
    </BottomSheet>
  );
};

export default ProfileConfirmationComponent;

ProfileConfirmationComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
