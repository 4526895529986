import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ReactDOM from 'react-dom';
import uniqBy from 'lodash/uniqBy';

import Modal from 'components/GeneralComponents/Modal';
import { getUserId } from '../../helpers/cookies';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Title from './components/Title/Title';
import Category from './components/Category/Category';
import Summary from './components/Summary/Summary';
import Header from './components/Header/HeaderContainer';
import UserTable from './components/UserTable/UserTable';
import ProgressBar from './ProgressBar';
import constants from './constants';
import { MAX_BULK_USERS, USERS_PER_PAGE, MAX_USERS_REMOVE_FROM_GROUP, } from './constants';

import './UserManagementComponent.scss';
import styled from './styled';

const LoadingLayer = ({ loading }) => {
  return (
    <div className={`manager--loading-layer ${loading ? 'show' : ''}`}>
      <img src="/loader.gif" alt="Loading gif" />
    </div>
  );
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const sha1 = require('js-sha1');

class UserManagementComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    const { categoryTypes } = constants;
    const { departments, } = this.props;

    this.pubnub = props.pubnub;
    this.sha1 = `user_event.${sha1(`user.${getUserId()}`)}`;

    this.state ={
      debug: true,
      //totalUsers: [],
      selectedType: categoryTypes.LOCATION,
      selectedIndex: 0,
      selectedItem: null,
      filteredUsers: [],
      categoryItems: [],
      departmentItems: [],
      selectedDeptId: -1,
      selectedSkillId: -1,
      selectedUsers: [],
      locationInfo: '',
      userActionDone: false,
      timer: null,
      tableSize: null,
      actionUsers: [],
      processedUsers: [],
      actionInProgress: false,
      requestedOperation: null,
      lastCreatedChannel: null,
      lastCreatedDept: null,
      lastCreatedSkill: null,
      selectedChannel: null,
      selectedDepartment: null,
      selectedSkill: null,
      searchTerm: '',
      selectedSort: null,
      ascending: true,
      popupOpened: false,
      processingUserId: 0,
    };

    this._isMounted = false;
    this.singleUser = false;
    this.actionType = '';
    this.calledSearch = false;
    this.apiCalled = false;
    this.managerPageIndex = 1;
    this.associatePageIndex = 1;
    this.pendingUserPageIndex = 1;
    this.removedUserPageIndex = 1;
    this.allUserPageIndex = 1;
    this.lastDoneTask = null;
  }

  componentDidMount() {
    const { locationId, locationData, searchTerm, fetchMetadata, fetchActiveUsers, fetchRemovedUsers,
            fetchPendingUsers, getLocationInnerDepartments, getLocationUsableSkills, channels, 
            requestChannelMembers, fetchChannelMembers, getLocationChannels, locationSettings,  
            managers, associates, pendingUsers, removedUsers, allUsers, handleGetUserInformation,
            resetManagers, resetAssociates, resetRemovedUsers, resetPendingUsers, } = this.props;

    this._isMounted = true;

    const filter = getParameterByName('filter');

    if(filter === 'pending') {
      const pending = (<FormattedMessage
        id="UserManagementComponent.pending"
        defaultMessage="Pending"
      />); 
      const itemPending = { name: pending, value: pendingUsers.length}; 
      this.summaryItemSelected(1, itemPending);
    }else {
      const isNumber = /^\d+$/.test(filter);
      if(isNumber) {
        const id = Number(filter);
        const changedUser = allUsers.filter((user) => user.id === id);
        if(changedUser && changedUser.length > 0) {
          if(locationId) {
            handleGetUserInformation(changedUser[0], locationId);
          }
        }
      }
    }

    this.pubnubSubscribe();
    console.log('UM: pubnub subscribed');

    //Following functions should not be here because componentDidMout called two times.
    /*
    if(locationId) {
      resetManagers();
      resetAssociates();
      resetRemovedUsers();
      resetPendingUsers();
      fetchMetadata(locationId, searchTerm);
      fetchActiveUsers(locationId, 1, USERS_PER_PAGE);
      console.log('called fetchActiveUsers');
      fetchRemovedUsers(locationId, 1, USERS_PER_PAGE);
      fetchPendingUsers(locationId, 1, USERS_PER_PAGE);
      getLocationInnerDepartments(locationId);
      getLocationUsableSkills(locationId);
      getLocationChannels(locationId);
    }
    */

    this.updateUI();
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, 
            locationData,
            fetchMetadata,
            fetchActiveUsers, 
            fetchRemovedUsers,
            fetchPendingUsers,
            fetchManagers, 
            fetchAssociates,
            fetchAllUsers,
            getLocationInnerDepartments,
            getLocationUsableSkills,
            getLocationChannels,
            managers,
            managersCurrentPage,
            managersTotalPages, 
            associates, 
            associatesCurrentPage,
            associatesTotalPages,
            removedUsers, 
            removedUsersCurrentPage,
            removedUsersTotalPages,
            pendingUsers,
            allUsers,
            departments,
            channels,
            skills,
            requestChannelMembers,
            fetchChannelMembers,
            channelMembers,
            channelData,
            lastAction,
            handleApprove,
            handleDeny,
            handlePromote,
            handleWithdraw,
            handleRemove,
            lastOperation,
            handleAddUserToGroup,
            handleAddUserToDepartment,
            handleRemoveUserFromGroup,
            handleGetUserInformation,
            resetManagers, 
            resetAssociates, 
            resetRemovedUsers, 
            resetPendingUsers,
            resetAllUsers,
            error,
          } = this.props;
    const { selectedType, selectedItem, selectedIndex, selectedUsers, processedUsers, searchTerm,
            lastCreatedChannel, lastCreatedDept, selectedChannel, selectedDepartment, filteredUsers, selectedSort, ascending, selectedDeptId, } = this.state;
    const { categoryTypes, userActionTypes, operationTypes, } = constants;

    if(!this.apiCalled) {
      if(locationId) {
        //resetManagers();
        //resetAssociates();
        //resetRemovedUsers();
        //resetPendingUsers();
        //resetAllUsers();

        /*
        fetchMetadata(locationId, searchTerm);
        fetchActiveUsers(locationId, this.managerPageIndex, USERS_PER_PAGE);
        this.managerPageIndex += 1;
        this.associatePageIndex += 1;
        fetchRemovedUsers(locationId, this.removedUserPageIndex, USERS_PER_PAGE);
        this.removedUserPageIndex += 1;
        fetchPendingUsers(locationId, this.pendingUserPageIndex, USERS_PER_PAGE);
        this.pendingUserPageIndex += 1;
        */

        //fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
        //this.allUserPageIndex += 1;
        getLocationInnerDepartments(locationId);
        getLocationUsableSkills(locationId);
        //getLocationChannels(locationId);
      }
      this.apiCalled = true;
    }

    if(prevProps.managers !== managers) {
      this.updateUI();
      if(prevProps.managers) {
        if(managers.length - prevProps.managers.length === USERS_PER_PAGE) {
          //fetchManagers(locationId, this.managerPageIndex, USERS_PER_PAGE);
          //this.managerPageIndex += 1;
        }
      }
    }
    
    if(prevProps.associates !== associates) {
      this.updateUI();
      if(prevProps.associates) {
        if(associates.length - prevProps.associates.length === USERS_PER_PAGE) {
          //fetchAssociates(locationId, this.associatePageIndex, USERS_PER_PAGE);
          //this.associatePageIndex += 1;
        }
      }
    }
    
    if(prevProps.removedUsers !== removedUsers) {
      this.updateUI();
      if(prevProps.removedUsers) {
        if(removedUsers.length - prevProps.removedUsers.length === USERS_PER_PAGE) {
          fetchRemovedUsers(locationId, this.removedUserPageIndex, USERS_PER_PAGE);
          this.removedUserPageIndex += 1;
        }
      }
    }

    if(prevProps.allUsers !== allUsers) {
      this.updateUI();
      /*
      if(prevProps.allUsers) {
        if(allUsers.length - prevProps.allUsers.length === USERS_PER_PAGE) {
          fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
          this.allUserPageIndex += 1;
        }
      }
      */
    }
    
    if(prevProps.pendingUsers !== pendingUsers) {
      this.updateUI();
    }

    if(prevProps.departments !== departments) {
      this.updateUI();
      if(departments && departments.length > 0) {
        if(selectedDeptId < 0) {
          this.setState({selectedDeptId: departments[0].id});
        }
        if(lastCreatedDept) {
          const idx = departments.findIndex((dept) => dept.name === lastCreatedDept.name)
          if(idx >= 0) {
            this.setState({userActionDone: true});
            this.startTimer(10000);
          }
        }
      }
    }

    if(prevProps.skills !== skills) {
      this.updateUI();
      if(skills && skills.length > 0) {
        this.setState({selectedSkillId: skills[0].id});
      }
    }

    if(prevProps.channels !== channels) {
      this.updateUI();
      /*
      if(channels && channels.length > 0) {
        requestChannelMembers();
        for(let i=0; i<channels.length; i++) {
          fetchChannelMembers(channels[i].id, channels[i].channel_name);
          if(lastCreatedChannel) {
            if(channels[i].channel_name === lastCreatedChannel.name) {
              this.setState({userActionDone: true});
              this.startTimer(10000);
            }
          }
        }
      }
      */
    }

    if(prevProps.lastAction !== lastAction) {
      if(lastAction && lastAction.ids && lastAction.ids.length > 0) {
        let processed = [...processedUsers];
        const copy = [...selectedUsers];
        if(lastAction.name !== userActionTypes.REMOVE && 
          lastAction.name !== operationTypes.ASSIGN_USERS_TO_DEPT && lastAction.name !== operationTypes.REMOVE_USERS_FROM_DEPT &&
          lastAction.name !== operationTypes.ASSIGN_USERS_TO_SKILL && lastAction.name !== operationTypes.REMOVE_USERS_FROM_SKILL) {
          processed = processed.concat(lastAction.ids[0]);
          this.setState({processedUsers: processed});
          let idx = -1;
          if(lastAction.name !== operationTypes.ASSIGN_USERS_TO_GROUP && lastAction.name !== operationTypes.REMOVE_USERS_FROM_GROUP) {
            idx = selectedUsers.findIndex((user) => user.id === lastAction.ids[0]);
          }else {
            idx = selectedUsers.findIndex((user) => user.owner_id === lastAction.ids[0]);
          }
          if(idx >= 0) {
            copy.splice(idx, 1);
            this.setState({selectedUsers: copy});
          }
        }else {
          processed = processed.concat(lastAction.ids);
          this.setState({processedUsers: processed});
          copy.splice(0, lastAction.ids.length);
          this.setState({selectedUsers: copy});
        }
        
        if(copy.length > 0) {
          let adminIds = [];
          let nonAdminIds = [];
          let allIds = [];
          for(let i=0; i<copy.length; i++) {
            if(copy[i].is_admin) {
              adminIds.push(copy[i].id);
            }else {
              nonAdminIds.push(copy[i].id);
            }
          }
          const nonAdmins = copy ? copy.filter((user) => user && user.is_admin === false): [];
          const admins = copy ? copy.filter((user) => user && user.is_admin === true): [];
          switch(lastAction.name) {
            case userActionTypes.APPROVE:
              allIds = adminIds.concat(nonAdminIds);
              if(allIds.length > 0) {
                handleApprove(allIds);
              }
              break
          case userActionTypes.DENY:
            if(copy.length > 0) {
              handleDeny(copy, locationId);
            }
            break;
          case userActionTypes.PROMOTE:
            if(nonAdminIds.length > 0) {
              this.setState({processingUserId: nonAdminIds[0]});
              handlePromote(nonAdmins);
            }else {
              this.setState({userActionDone: true});
              this.startTimer(10000);
            }
            break;
          case userActionTypes.WITHDRAW:
            if(adminIds.length > 0) {
              this.setState({processingUserId: adminIds[0]});
              handleWithdraw(admins);
            }else {
              this.setState({userActionDone: true});
              this.StartTimer(10000);
            }
            break;
          case userActionTypes.REMOVE:
            this.bulkRemoveUsers(admins, nonAdmins)
            break;
          case operationTypes.ASSIGN_USERS_TO_GROUP:
            /*
            const ids = copy.map((user) => {
              return user.owner_id
            })
            */
            handleAddUserToGroup(selectedChannel.id, copy);
            break;
          case operationTypes.REMOVE_USERS_FROM_GROUP:
            /*
            const ids1 = copy.map((user) => {
              return user.owner_id
            })
            */
            //handleRemoveUserFromGroup(selectedChannel.id, ids1);
            this.bulkRemoveUsersFromGroup(selectedChannel.id, copy);
            break;
          case operationTypes.ASSIGN_USERS_TO_DEPT:
            const userIds = copy.map((user) => {
              return user.owner_id
            })
            if(userIds && userIds.length > 0) {
              handleAddUserToDepartment(userIds, selectedDepartment.id);
            }
            break;
          }
        }else {
          if(lastAction.name === operationTypes.ASSIGN_USERS_TO_GROUP || lastAction.name === operationTypes.REMOVE_USERS_FROM_GROUP) {
            //fetchChannelMembers(selectedChannel.id, selectedChannel.channel_name);
          }
          this.setState({
            userActionDone: true, 
            actionInProgress: false,
            selectedUsers: [],
          });
          this.updateUI();
          this.startTimer(10000);
          this.singleUser = false;
          this.actionType = '';
        }
      }
    }

    if(prevProps.channelData !== channelData) {
      this.updateUI();
      if(selectedChannel && channelData && channelData.length > 0) {
        //this.updateGroupMembers(selectedChannel.id);
      }
    }

    if(prevState.selectedUsers !== selectedUsers) {
      if(selectedUsers && selectedUsers.length === 1) {
        if(this.singleUser) {
          this.notifyActionRequested(true, this.actionType);
        }
      }
    }

    if(prevState.filteredUsers !== filteredUsers) {
      if(filteredUsers.length > 0) {
        if(searchTerm && searchTerm.length > 0) {
          if(!this.calledSearch) {
            this.calledSearch = true;
            this.notifySearchTermChanged(searchTerm);
          }
        }
      }
    }

    if(prevState.searchTerm !== searchTerm) {
      this.calledSearch = false;
      if(prevState.searchTerm) {
        if(prevState.searchTerm.length > 0) {
          if(searchTerm && searchTerm.length > 0) {
            if(searchTerm.toLowerCase().includes(prevState.searchTerm.toLowerCase())) {
              const output = this.filterUsers(selectedType, selectedItem, selectedIndex);
              this.setState({filteredUsers: output});
            }
          }
        }
      }
    }

    if(prevState.selectedType !== selectedType) {
      this.setState({searchTerm: ''});
    }

    if(prevProps.error !== error) {
      if(error) {
        this.setState({
          userActionDone: true, 
          actionInProgress: false,
          selectedUsers: [],
        });
        this.updateUI();
        this.singleUser = false;
        this.actionType = '';
      }
    }
  }

  componentWillUnmount () {
    const { resetManagers, resetAssociates, resetRemovedUsers, resetPendingUsers, } = this.props;
    this._isMounted = false;
    clearInterval(this.state.timer);
    //resetManagers();
    //resetAssociates();
    //resetRemovedUsers();
    //resetPendingUsers();
    this.pubnub.removeListener();
    this.pubnub.unsubscribe({
      channels: [this.sha1],
    });
  }

  tick = () => {
    if(this._isMounted) {
      this.setState({userActionDone: false});
    }
  }

  pubnubSubscribe = () => {
    const { locationId, allUsers, handleGetUserInformation, } = this.props;
    
    this.pubnub.addListener({
      message: (event) => {
        if (
          event.message.receiver_id == getUserId() &&
          event.message.event === 'user_privilege' &&
          event.message.crud === 'UPDATE'
        ) {
          //updateUser(event.message);
          const changedUser = allUsers.filter((user) => user.id === event.message.node_id);
          if(changedUser && changedUser.length > 0) {
            if(locationId) {
              handleGetUserInformation(changedUser[0], locationId);
            }
          }
        }
      },
    });

    this.pubnub.subscribe({
      channels: [this.sha1],
    });
  };

  startTimer = (timeOut) => {
    const myTimer = setInterval(this.tick, timeOut); //unit: ms
    if(this._isMounted) {
      this.setState({timer: myTimer});
    }
  }

  updateGroupMembers = (channelId) => {
    const { channelData, } = this.props;
    const { filteredUsers, } = this.state;
    
    const foundChannels = channelData.filter((channel) => channel.id === channelId);
    if(foundChannels && foundChannels.length > 0) {
      const members = foundChannels[0].members;
      let users = [];
      if(members && members.length > 0) {
        const copy = [...filteredUsers];
        for(let i=0; i<members.length; i++) {
          const idx = filteredUsers.findIndex((user) => user.owner_id === members[i].id);
          if(idx >=0) {
            users.push(filteredUsers[idx]);
          }
        }
        this.setState({filteredUsers: users});
      }
    }
  }

  updateUI = () => {
    const { selectedType, selectedItem, selectedIndex, searchTerm, selectedSort, ascending, } = this.state;
    let output = [];
    if(searchTerm) {
      let output = this.filterUsers(selectedType, selectedItem, selectedIndex);
      if(output.length > 0) {
        const foundUsers = this.searchUsers(searchTerm, output);
        if(foundUsers && foundUsers.length > 0) {
          this.setState({filteredUsers: foundUsers});
        }else {
          this.setState({filteredUsers: []});
        }
      }else {
        output = this.filterUsers(selectedType, selectedItem, selectedIndex);
        this.setState({filteredUsers: output});
      }
    }else {
      output = this.filterUsers(selectedType, selectedItem, selectedIndex);
      //this.setState({filteredUsers: output});
      if(output && selectedSort) {
        const sorted = this.sortUsers(output, selectedSort.name, ascending);
        this.setState({filteredUsers: sorted});
      }
    }
    
    //const output = this.filterUsers(selectedType, selectedItem, selectedIndex);
    //this.setState({filteredUsers: output});

    const categories = this.createCategoryItems();
    this.setState({categoryItems: categories});
    const info = this.createLocationInfo();
    this.setState({locationInfo: info});
  }

  createCategoryItems = () => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, locationId, } = this.props;
    const { filteredUsers, } = this.state;
    const { operationTypes } = constants;
    let output = [];
    const all = (<FormattedMessage
      id="UserManagementComponent.all"
      defaultMessage="All"
    />);
    const active = (<FormattedMessage
      id="UserManagementComponent.active"
      defaultMessage="Active"
    />);
    const pending = (<FormattedMessage
      id="UserManagementComponent.pending"
      defaultMessage="Pending"
    />);      
    const terminated = (<FormattedMessage
      id="UserManagementComponent.terminated"
      defaultMessage="Terminated"
    />);

    const temp = managers.concat(associates);
    const teamUsers = temp.filter((user) => user && user.status !== 'pending');
    const allUsers = teamUsers.concat(pendingUsers, removedUsers);
    const temp1 = teamUsers.filter(user => user && user.status === 'active');
    //const pendingUsers = teamUsers.filter(user => user.status === 'pending');
    const activeUsers = uniqBy(temp1, 'id'); // Remove duplicated

    let locationItems = [];
    //WS-1953: remove "All"
    //const objAll = { name: all, value: allUsers.length};
    //locationItems.push(objAll);
    const objActive = { name: active, value: activeUsers.length};
    locationItems.push(objActive);
    const objPending = { name: pending, value: pendingUsers.length};
    locationItems.push(objPending);
    const objTerminated = { name: terminated, value: removedUsers.length};
    locationItems.push(objTerminated);

    const objLocation = {id: 'location', items: locationItems};
    output.push(objLocation); 

    if(departments && departments.length > 0) {
      let sortedDepts = [...departments];
      this.sortDeptNameWithNullValues(sortedDepts);

      let departmentItems = [];
      for(let i=0; i<sortedDepts.length; i++) {
        let deptUsers = [];
        if(activeUsers.length > 0) {
          for(let j=0; j<sortedDepts[i].user_ids.length; j++) {
            const found = activeUsers.filter((user) => user.owner_id === sortedDepts[i].user_ids[j]);
            if(found && found.length > 0) {
              deptUsers.push(found[0]);
            }
          }
        }
        //const dept = { name: sortedDepts[i].name, value: sortedDepts[i].user_ids.length};
        const dept = { name: sortedDepts[i].name, value: deptUsers.length, id: sortedDepts[i].id};
        departmentItems.push(dept);
      }
      const objDept = {id: 'department', items: departmentItems};
      output.push(objDept);
    }

    if(channels && channels.length > 0) {
      let sortedChannels = [...channels];
      this.sortChannelNameWithNullValues(sortedChannels, true);

      let channelItems = [];
      for(let j=0; j<sortedChannels.length; j++) {
        let chl = { name: '', value: 0, id: 0};
        
        const members = this.getChannelMembers(sortedChannels[j].id);
        if(members.length > 0) {
          chl = { name: sortedChannels[j].channel_name, value: members.length, id: sortedChannels[j].id};
          channelItems.push(chl);
        }else {
          chl = { name: sortedChannels[j].channel_name, value: sortedChannels[j].member_count, id: sortedChannels[j].id};
          if(this.lastDoneTask && this.lastDoneTask.name === operationTypes.CREATE_GROUP) {
            channelItems.push(chl);
          }
        }
      }
      //return chls;
      const objChannel = {id: 'channel', items: channelItems};
      output.push(objChannel);
    }

    if(skills && skills.length > 0) {
      let sortedSkills = [...skills];
      this.sortSkillNameWithNullValues(sortedSkills, true);

      let skillItems = [];
      for(let k=0; k<sortedSkills.length; k++) {
        const skillUsers = this.getSkillUsersInLocation(sortedSkills[k]);
        //const skl = { name: skills[i].skill_content, value: skills[i].user_ids.length};
        const skl = { name: sortedSkills[k].skill_content, value: skillUsers.length};
        skillItems.push(skl);
      }
      const objSkill = {id: 'skill', items: skillItems};
      output.push(objSkill);
    }
      
    return output;
  }

  getSkillUsersInLocation = (skill) => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, } = this.props;
    const temp = managers.concat(associates);
    const totalUsers = uniqBy(temp, 'id'); //Remove duplicated
    let skillUsers = [];
    if(skill) {
      for(let i=0; i<skill.user_ids.length; i++) {
        for(let j=0; j<totalUsers.length; j++) {
          if(skill.user_ids[i] === totalUsers[j].owner_id) {
            skillUsers.push(totalUsers[j]);
          }
        }
      }
    }

    return skillUsers;

  }

  getChannelMembers = (channelId) => {
    const { managers, associates, removedUsers, pendingUsers, channels, channelData, } = this.props;
    const activeUsers = managers.concat(associates);
    const temp = activeUsers.concat(pendingUsers, removedUsers);
    const total = uniqBy(temp, 'id'); //Remove duplicates
  
    const usersHaveId = total.filter((user) =>  user.user && user.user.channel_ids && user.user.channel_ids.includes(channelId));
    if(usersHaveId && usersHaveId.length > 0) {
      const valid = usersHaveId.filter((item) => item.status === 'active');
      if(valid && valid.length > 0) {
        return valid;
      }
    }
    return [];
  }

  createSummaryElements = () => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, skills, } = this.props;
    const { selectedType, categoryItems, } = this.state;
    const { categoryTypes } = constants;

    if(selectedType === categoryTypes.LOCATION) {
      const idxL = categoryItems.findIndex((el) => el.id === 'location');
      if(idxL >= 0) {
        return categoryItems[idxL].items;
      }
    }else if(selectedType === categoryTypes.DEPARTMENTS) {
      if(departments && departments.length > 0) {
        const idxD = categoryItems.findIndex((el) => el.id === 'department');
        if(idxD >= 0) {
          return categoryItems[idxD].items;
        }
      }
      return null;
    }else if(selectedType === categoryTypes.GROUPS) {
      if(channels && channels.length > 0) {
        const idxC = categoryItems.findIndex((el) => el.id === 'channel');
        if(idxC >= 0) {
          return categoryItems[idxC].items;
        }
      }
      return null;
    }else if(selectedType === categoryTypes.SKILLS) {
      if(skills && skills.length > 0) {
        const idxS = categoryItems.findIndex((el) => el.id === 'skill');
        if(idxS >= 0) {
          return categoryItems[idxS].items;
        }
      }
      return null;
    }

  };

  categorySelected = (selectedType) => {
    const { departments, channels, skills, } = this.props;
    const { selectedItem, categoryItems, selectedIndex, } = this.state;
    const { categoryTypes } = constants;

    this.setState({selectedType: selectedType});
    this.setState({selectedUsers: []});
    switch(selectedType) {
    case categoryTypes.LOCATION:
      if(categoryItems && categoryItems.length > 0) {
        this.setState({selectedItem: categoryItems[0]});
        const output = this.filterUsers(selectedType, categoryItems[0], selectedIndex);
        this.setState({filteredUsers: output});
      }
      break;
    case categoryTypes.DEPARTMENTS:
      let output1 = [];
      if(departments && departments.length > 0) {
        this.setState({selectedItem: departments[0]});
        output1 = this.filterUsers(selectedType, departments[0], selectedIndex);
        this.setState({filteredUsers: output1});
      }else {
        output1 = this.filterUsers(selectedType, selectedItem, selectedIndex);
        this.setState({filteredUsers: output1});
      }
      break;
    case categoryTypes.GROUPS:
      let output2 = [];
      if(channels && channels.length > 0) {
        this.setState({selectedItem: channels[0]});
        output2 = this.filterUsers(selectedType, channels[0], selectedIndex);
        this.setState({filteredUsers: output2});
      }else {
        output1 = this.filterUsers(selectedType, selectedItem, selectedIndex);
        this.setState({filteredUsers: output1});
      }
      break;
    case categoryTypes.SKILLS:
      let output3 = [];
      if(skills && skills.length > 0) {
        const obj = {
          name: skills[0].skill_content,
          value: skills[0].user_ids.length,
        }
        //this.setState({selectedItem: skills[0]});
        this.setState({selectedItem: obj});
        output3 = this.filterUsers(selectedType, skills[0], selectedIndex);
        this.setState({filteredUsers: output3});
      }else {
        output3 = this.filterUsers(selectedType, selectedItem, selectedIndex);
        this.setState({filteredUsers: output3});
      }
      break;
    }

  };

  summaryItemSelected =(index, item) => {
    const { locationId, managers, associates, removedUsers, pendingUsers, departments, skills, } = this.props;
    const { selectedType, } = this.state;
    const { categoryTypes } = constants;
    /*
    if(setIndex) {
      this.setState({selectedItem: item, selectedIndex: index});
    }else {
      this.setState({selectedItem: item});
    }
    */
    this.setState({selectedItem: item, selectedIndex: index});
    this.setState({selectedUsers: []});
    const output = this.filterUsers(selectedType, item, index);
    this.setState({filteredUsers: output});

    switch(selectedType) {
      case categoryTypes.DEPARTMENTS:
        for(let i=0; i<departments.length; i++) {
          if(departments[i].id === item.id) {
            this.setState({selectedDeptId: departments[i].id});
            break;
          }
        }
        break;
      case categoryTypes.SKILLS:
        for(let i=0; i<skills.length; i++) {
          if(skills[i].skill_content === item.name) {
            this.setState({selectedSkillId: skills[i].id});
            break;
          }
        }
        break;
    }
  };

  filterUsers = (type, item, index) => {
    const { managers, associates, removedUsers, pendingUsers, departments, channels, channelData, skills, } = this.props;
    const { categoryTypes } = constants;
    const activeUsers = managers.concat(associates);

    let total = [];
    if(type === categoryTypes.LOCATION) {
      total = activeUsers.concat(pendingUsers, removedUsers);
    }else {
      total = activeUsers.concat(pendingUsers);
    }

    const noDuplicated = uniqBy(total, 'id'); // Remove duplicates
    total = noDuplicated;

    if(!type) {
      return total;
    }
    //WS-1953: removed "All"
    /*
    if(!item) {
      return total;
    }
    */

    let filtered = [];
    switch(type) {
      case categoryTypes.LOCATION:
        if(index === 0) {
          filtered = total.filter((user) => user && user.status === 'active');
        }else if(index === 1) {
          filtered = total.filter((user) => user && user.status === 'pending');
        }else if(index === 2) {
          filtered = removedUsers;
        }
        break;
      case categoryTypes.DEPARTMENTS:
        if(departments && departments.length > 0 && item) { 
          const foundDepts = departments.filter((dept) => dept.id === item.id);
          if(foundDepts && foundDepts.length > 0) {
            let deptUsers = []; 
            for(let i=0; i<foundDepts[0].user_ids.length; i++) {
              const found = total.filter((user) => user.owner_id === foundDepts[0].user_ids[i]);
              if(found && found.length > 0) {
                deptUsers.push(found[0]);
              }
            }
            filtered = deptUsers;
          }
        }else {
          filtered = total;
        }
        break;
      case categoryTypes.GROUPS:
        if(channels && channels.length > 0 && item) {
          const foundChannels = channels.filter((channel) => channel.id === item.id)
          if(foundChannels && foundChannels.length > 0) {
            /*
            let channelUsers = [];
            const foundChannelData = channelData.filter((data) => data.id === item.id);
            if(foundChannelData && foundChannelData.length > 0) {
              //filtered = foundChannelData[0].members;
              for(let i=0; i<foundChannelData[0].members.length; i++) {
                const foundUsers = total.filter((user) => user.owner_id === foundChannelData[0].members[i].id);
                if(foundUsers && foundUsers.length > 0) {
                  channelUsers.push(foundUsers[0]);
                }
              }
            }
            filtered =  channelUsers;
            */
            const usersHaveId = total.filter((user) => user.user &&  user.user.channel_ids && user.user.channel_ids.includes(item.id));
            if(usersHaveId && usersHaveId.length > 0) {
              filtered = usersHaveId;
            }
          }
        }else {
          filtered = total;
        }
        break;
      case categoryTypes.SKILLS:
        if(skills && skills.length > 0 && item) {
          const idx1 = skills.findIndex((skill) => skill.skill_content === item.name 
                || skill.skill_content === item.skill_content)
          if(idx1 >= 0) {
            const skill = skills[idx1];
            let skillUsers = [];
            for(let i=0; i<total.length; i++) {
              for(let j=0; j<skill.user_ids.length; j++) {
                if(total[i].owner_id === skill.user_ids[j]) {
                  skillUsers.push(total[i]);
                }
              }
            }
            filtered = skillUsers;
          }
        }else {
          filtered = total;
        }
        break;
    }
    return filtered;
  };

  notifyUserSelected = (user, selected) => {
    const { selectedUsers, } = this.state;
    if(selected) {
      const idx = selectedUsers.findIndex((item) => item.owner_id === user.owner_id);
      if(idx < 0) {
        const copy = [...selectedUsers];
        copy.push(user);
        this.setState({selectedUsers: copy});
      }
    }else {
      const idx1 = selectedUsers.findIndex((item) => item.owner_id === user.owner_id);
      if(idx1 >= 0) {
        const copy1 = [...selectedUsers];
        copy1.splice(idx1, 1);
        this.setState({selectedUsers: copy1});
      }
    }
  }

  notifyAllUserSelected = (selected) => {
    const { filteredUsers, } = this.state;
    if(selected) {
      this.setState({selectedUsers: filteredUsers})
    }else {
      this.setState({selectedUsers: []});
    }
  }

  notifyActionRequested = (request, actionType, user) => {
    const { handleApprove, handleDeny, handlePromote, handleWithdraw, handleRemove, locationId, } = this.props;
    const { selectedUsers, } = this.state;
    const { userActionTypes } = constants;

    this.setState({userActionDone: false, processedUsers: [], actionInProgress: false});

    if(!request) {
      this.setState({selectedUsers: []});
      return;
    }

    //Action from table user row, the user check box could be checked/not checked
    if(user) {
      const copy = [];
      if(selectedUsers.length === 0) {
        copy.push(user);
        this.setState({selectedUsers: copy});
        this.singleUser = true;
        this.actionType = actionType;
        return;
      }else {
        const actionPersons = selectedUsers.filter((person) => person.id === user.id);
        if(actionPersons && actionPersons.length === 1) {
          copy.push(actionPersons[0]);
          this.setState({selectedUsers: copy});
          this.singleUser = true;
          this.actionType = actionType;
          return;
        }
      }
    }

    let adminIds = [];
    let nonAdminIds = [];
    let allIds = [];
    for(let i=0; i<selectedUsers.length; i++) {
      if(selectedUsers[i].is_admin) {
        adminIds.push(selectedUsers[i].id);
      }else {
        nonAdminIds.push(selectedUsers[i].id);
      }
    }

    const nonAdmins = selectedUsers ? selectedUsers.filter((user) => user && user.is_admin === false): [];
    const admins = selectedUsers ? selectedUsers.filter((user) => user && user.is_admin === true): [];

    switch(actionType) {
      case userActionTypes.APPROVE:
        /*
        this.setState({actionUsers: nonAdmins});
        if(nonAdminIds.length > 0) {
          handleApprove(nonAdminIds);
          this.setState({actionInProgress: true});
        }
        */
        allIds = adminIds.concat(nonAdminIds);
        this.setState({actionUsers: selectedUsers});
        if(allIds.length > 0) {
          handleApprove(allIds);
          if(allIds.length > 1) {
            this.setState({actionInProgress: true});
          }
        }
        break;
      case userActionTypes.DENY:
        /*
        this.setState({actionUsers: nonAdmins});
        if(nonAdminIds.length > 0) {
          handleDeny(nonAdmins);
          this.setState({actionInProgress: true});
        }
        */
        this.setState({actionUsers: selectedUsers});
        if(selectedUsers.length > 0) {
          handleDeny(selectedUsers, locationId);
          if(selectedUsers.length > 1) {
            this.setState({actionInProgress: true});
          }
        }
        break;
      case userActionTypes.PROMOTE:
        if(selectedUsers.length > nonAdmins.length) {
          this.setState({selectedUsers: nonAdmins});
        }
        this.setState({actionUsers: nonAdmins});
        if(nonAdminIds.length > 0) {
          this.setState({processingUserId: nonAdminIds[0]});
          handlePromote(nonAdmins);
          this.setState({actionInProgress: true});
        }
        break;
      case userActionTypes.WITHDRAW:
        if(selectedUsers.length > admins.length) {
          this.setState({selectedUsers: admins})
        }
        this.setState({actionUsers: admins});
        if(adminIds.length > 0) {
          this.setState({processingUserId: adminIds[0]});
          handleWithdraw(admins);
          this.setState({actionInProgress: true});
        }
        break;
      case userActionTypes.ADD:
        console.log('action: add');
        break;
      case userActionTypes.REMOVE:
        this.setState({actionUsers: selectedUsers});
        if(admins.length > 0 || nonAdmins.length > 0) {
          this.bulkRemoveUsers(admins, nonAdmins);
        }
        break;
      case userActionTypes.OPEN_MORE:
        console.log('action: open more');
        break;
    }
  }

  bulkRemoveUsers = (admins, nonAdmins) => {
    const { handleApprove, handleDeny, handlePromote, handleWithdraw, handleRemove, locationId, } = this.props;

    let bulkAdmins = [];
    let bulkNonAdmins = [];
    if(admins.length > MAX_BULK_USERS) {
      bulkAdmins = admins.slice(0, MAX_BULK_USERS);
      const toProcess = admins.slice(MAX_BULK_USERS);
    }else {
      bulkAdmins = admins;
    }

    if(nonAdmins.length > MAX_BULK_USERS) {
      bulkNonAdmins = nonAdmins.slice(0, MAX_BULK_USERS);
      const toProcess = nonAdmins.slice(MAX_BULK_USERS);
    }else {
      bulkNonAdmins = nonAdmins;
    }

    this.setState({actionInProgress: true});
    handleRemove(bulkAdmins, bulkNonAdmins, locationId);
  }

  bulkRemoveUsersFromGroup = (channelId,  users) => {
    const { handleRemoveUserFromGroup, locationId, channelData, } = this.props;
    //const channelMembers = this.getChannelMembers(channelId);
    /*
    if(userIds.length > MAX_USERS_REMOVE_FROM_GROUP) {
      const bulkIds = userIds.slice(0, MAX_USERS_REMOVE_FROM_GROUP);
      const toProcess = userIds.slice(MAX_USERS_REMOVE_FROM_GROUP);
      handleRemoveUserFromGroup(channelId, bulkIds);
    }else {
      handleRemoveUserFromGroup(channelId, userIds);
    }
    */
    const members = this.getChannelMembers(channelId);
    handleRemoveUserFromGroup(channelId, users, members.length);
  }

  createLocationInfo = () => {
    const { managers, associates, removedUsers, locationData, pendingUsers, } = this.props;
    const all = managers.concat(associates, pendingUsers, removedUsers);
    //const info = `${locationData.location_name} #${all.length}`;
    const info = `${locationData.location_name}`; //Fixing for WS-1959
    return info; 
  }

  renderStatus = (lastAction) => {
    const { tableSize, actionUsers, lastCreatedChannel,  lastCreatedDept, lastCreatedSkill, processedUsers, } = this.state;
    const { userActionTypes, operationTypes, } = constants;

    let winTop = '500px';
    let winLeft = '300px';
    winTop = `${(window.innerHeight - 100)}px`;
    winLeft = `${(window.innerWidth)/2}px`;

    const numUsers = actionUsers ? actionUsers.length : 0;
    const channelName = lastCreatedChannel ? lastCreatedChannel.name : '';
    const deptName = lastCreatedDept ? lastCreatedDept.name : '';
    const skillName = lastCreatedSkill ? lastCreatedSkill.name : '';
    //const numUsersAdded = processedUsers ? processedUsers.length : '';

    const promoteText = (<FormattedMessage
      id="UserManagementComponent.promote"
      defaultMessage="{number} users successfully granted admin privileges"
      values={{number: numUsers}}
    />);
    const withdrawText = (<FormattedMessage
      id="UserManagementComponent.withdraw"
      defaultMessage="Admin privileges withdrawn for {number} users"
      values={{number: numUsers}}
    />);
    
    const removeText = (<FormattedMessage
      id="UserManagementComponent.remove"
      defaultMessage="{number} users successfully removed from this location"
      values={{number: numUsers}}
    />);

    const approveText = (<FormattedMessage
      id="UserManagementComponent.approve"
      defaultMessage="{number} users approved"
      values={{number: numUsers}}
    />);

    const denyText = (<FormattedMessage
      id="UserManagementComponent.deny"
      defaultMessage="{number} users denied"
      values={{number: numUsers}}
    />);

    const createdGroup = (<FormattedMessage
      id="UserManagementComponent.createGroup"
      defaultMessage="Group {name} successfully created"
      values={{name: channelName}}
    />);

    const createdDept = (<FormattedMessage
      id="UserManagementComponent.createDept"
      defaultMessage="Department {name} successfully created"
      values={{name: deptName}}
    />);

    const createdSkill = (<FormattedMessage
      id="UserManagementComponent.createSkill"
      defaultMessage="Skill {name} successfully created"
      values={{name: skillName}}
    />);

    const addedToGroup = (<FormattedMessage
      id="UserManagementComponent.addedToGroup"
      defaultMessage="{number, plural, one {{number} user successfully added to group} other {{number} users successfully added to group}}" 
      values={{number: numUsers}}/>); 
    const removedFromGroup = (<FormattedMessage
      id="UserManagementComponent.removedFromGroup"
      defaultMessage="{number, plural, one {{number} user successfully removed from group} other {{number} users successfully removed from group}}" 
      values={{number: numUsers}}/>); 
    const addedToDept = (<FormattedMessage
      id="UserManagementComponent.addedToDept"
      defaultMessage="{number, plural, one {{number} user successfully added to department} other {{number} users successfully added to department}}" 
      values={{number: numUsers}}/>); 
    const removeFromDept = (<FormattedMessage
      id="UserManagementComponent.removeFromDept"
      defaultMessage="{number, plural, one {{number} user successfully removed from department} other {{number} users successfully removed from department}}" 
      values={{number: numUsers}}/>); 
    const addToSkill = (<FormattedMessage
      id="UserManagementComponent.addToSkill"
      defaultMessage="{number, plural, one {{number} user successfully added to the skill} other {{number} users successfully added to the skill}}" 
      values={{number: numUsers}}/>); 
    const removeFromSkill = (<FormattedMessage
      id="UserManagementComponent.removeFromSkill"
      defaultMessage="{number, plural, one {{number} user successfully removed from the skill} other {{number} users successfully removed from the skill}}" 
      values={{number: numUsers}}/>); 

    let displayText = null;
    if(lastAction) {
      switch(lastAction.name) {
        case userActionTypes.APPROVE:
          displayText = approveText;
        break;
        case userActionTypes.DENY:
          displayText = denyText;
        break;
        case userActionTypes.PROMOTE:
          displayText = promoteText;
        break;
        case userActionTypes.WITHDRAW:
          displayText = withdrawText;
        break;
        case userActionTypes.REMOVE:
          displayText = removeText;
        break;
        case operationTypes.CREATE_GROUP:
          displayText = createdGroup;
        break;
        case operationTypes.CREATE_DEPARTMENT:
          displayText = createdDept;
        break;
      case operationTypes.CREATE_SKILL:
          displayText = createdSkill;
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
          displayText = addedToGroup;
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
          displayText = removedFromGroup;
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        displayText = addedToDept;
        break;
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        displayText = removeFromDept;
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        displayText = addToSkill;
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        displayText = removeFromSkill;
        break;

      }
    }

    return (
      <styled.WinContainer top={winTop} left={winLeft}>
        <div className="status__wrap">
         <label className="status__text">{displayText}</label>
        </div>
      </styled.WinContainer>
    );
  }

  notifyActionDone = (tableSize) => {
    this.setState({tableSize: tableSize, actionInProgress: false});
  }

  notifyOperationRequested = (operation) => {
    const { operationTypes, } = constants;
    this.lastDoneTask = operation;
    switch(operation.name) {
      case operationTypes.CREATE_DEPARTMENT:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.ASSIGN_USERS_TO_DEPT:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.REMOVE_USERS_FROM_DEPT:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.CREATE_GROUP:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.ASSIGN_USERS_TO_GROUP:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.REMOVE_USERS_FROM_GROUP:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.CREATE_SKILL:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.ASSIGN_USERS_TO_SKILL:
        this.setState({requestedOperation: operation.name});
        break;
      case operationTypes.REMOVE_USERS_FROM_SKILL:
        this.setState({requestedOperation: operation.name});
        break;
    }
  }

  notifyConfirmOperation = (confirmed, object) => {
    const { locationId, handleCreateGroup, handleCreateDepartment, handleCreateSkill, handleAddUserToGroup, 
            handleRemoveUserFromGroup, handleAddUserToDepartment, handleRemoveUserFromDepartment, 
            handleAssignUsersToSkill, handleRemoveUsersFromSkill, channelData, } = this.props;
    const { selectedUsers, } = this.state;
    const { userActionTypes, operationTypes, } = constants;

    this.setState({userActionDone: false, processedUsers: [], actionInProgress: false});

    let admins = [];
    let members = [];

    if(confirmed) {
      switch(object.operation) {
        case operationTypes.CREATE_DEPARTMENT:
          if(object && object.name) {
            handleCreateDepartment(object.name, locationId, object.description);
            const newDept = {name: object.name, description: object.description};
            this.setState({lastCreatedDept: newDept, userActionDone: false});
          }
          break;
        case operationTypes.ASSIGN_USERS_TO_DEPT:
          if(object && object.users && object.department) {
            if(selectedUsers.length > object.users.length) {
              //Associate can only be in one department, associates in 1 department have been removed in object.users
              this.setState({selectedUsers: object.users});
            }
            for(let i=0; i<object.users.length; i++) {
              if(object.users[i].is_admin) {
                admins.push(object.users[i]);
              }else {
                members.push(object.users[i]);
              }
            }
            if(admins.length > 0) {
              handleAddUserToDepartment(admins, object.department.id);
            }
            if(members.length > 0) {
              handleAddUserToDepartment(members, object.department.id);
            }
            //handleAddUserToDepartment(object.users, object.department.id);
            this.setState({selectedDepartment: object.department, actionUsers: object.users});
          }
          break;
        case operationTypes.REMOVE_USERS_FROM_DEPT:
          if(object && object.users && object.department) {
            const ids = object.users.map((user) => {
                return user.owner_id
              })
            for(let i=0; i<object.users.length; i++) {
              if(object.users[i].is_admin) {
                admins.push(object.users[i]);
              }else {
                members.push(object.users[i]);
              }
            }
            if(admins.length > 0) {
              handleRemoveUserFromDepartment(admins, object.department.id);
            }
            if(members.length > 0) {
              handleRemoveUserFromDepartment(members, object.department.id);
            }
            //handleRemoveUserFromDepartment(object.users, object.department.id);
            this.setState({selectedDepartment: object.department, actionUsers: object.users});
          }
          break;
        case operationTypes.CREATE_GROUP:
          if(object && object.name) {
            handleCreateGroup(object.name, locationId, object.description);
            const newChannel = {name: object.name, description: object.description};
            this.setState({lastCreatedChannel: newChannel, userActionDone: false});
          }
          break;
        case operationTypes.ASSIGN_USERS_TO_GROUP:
          if(object && object.channel && object.users) {
            const groupParams = {
              channel: object.channel,
              location_id: locationId,
              users: object.users,
              description: object.description,
            }
            /*
            const idsAdd = object.users.map((user) => {
              return user.owner_id
            })
            */
            this.setState({selectedChannel: object.channel, selectedUsers: object.users, actionUsers: object.users});
            if(object.users.length >= 10) {
              this.setState({actionInProgress: true});
            }
            handleAddUserToGroup(object.channel.id, object.users);
          }
          break;
        case operationTypes.REMOVE_USERS_FROM_GROUP:
          if(object && object.channel && object.users) {
            //since group creator is always first member in the group. if remove group creator first and then
            // other members, there will be api error 404. So reversed array to remove other members first.
            const reversed = object.users.reverse();
            /*
            const removeIds = reversed.map((user) => {
              return user.owner_id
            })
            */
            //this.setState({selectedChannel: object.channel, selectedUsers: object.users, actionUsers: object.users});
            this.setState({selectedChannel: object.channel, selectedUsers: reversed, actionUsers: reversed});
            if(object.users.length >= 10) {
              this.setState({actionInProgress: true});
            }
            if(object && object.channel && object.users) {
              this.bulkRemoveUsersFromGroup(object.channel.id, reversed);
            }
          }
          break;
        case operationTypes.CREATE_SKILL:
          if(object && object.name) {
            handleCreateSkill(object.name, locationId, object.description);
            const newSkill = {name: object.name, description: object.description};
            this.setState({lastCreatedSkill: newSkill, userActionDone: false});
          }
          break;
        case operationTypes.ASSIGN_USERS_TO_SKILL:
          if(object && object.users && object.skill) {
            //let admins = [];
            //let members = [];
            for(let i=0; i<object.users.length; i++) {
              if(object.users[i].is_admin) {
                admins.push(object.users[i]);
              }else {
                members.push(object.users[i]);
              }
            }
            this.setState({selectedSkill: object.skill, selectedUsers: object.users, actionUsers: object.users});
            if(admins.length > 0) {
              handleAssignUsersToSkill(admins, locationId, object.skill);
            }
            if(members.length > 0) {
              handleAssignUsersToSkill(members, locationId, object.skill);
            }
          }
          break;
        case operationTypes.REMOVE_USERS_FROM_SKILL:
          if(object && object.users && object.skill) {
            let admins1 = [];
            let members1 = [];
            for(let j=0; j<object.users.length; j++) {
              if(object.users[j].is_admin) {
                admins1.push(object.users[j]);
              }else {
                members1.push(object.users[j]);
              }
            }
            this.setState({selectedSkill: object.skill, selectedUsers: object.users, actionUsers: object.users});
            //handleRemoveUsersFromSkill(removeSkillIds, locationId, object.skill);
            if(admins1.length > 0) {
              handleRemoveUsersFromSkill(admins1, locationId, object.skill);
            }
            if(members1.length > 0) {
              handleRemoveUsersFromSkill(members1, locationId, object.skill);
            }
          }
          break;
      }
    }else {
      //UI Enhancements 17
      //this.setState({selectedUsers: []});
    }

    //after operation done
    this.setState({requestedOperation: null});
  }

  notifySearchTermChanged = (searchTerm) => {
    const { filteredUsers, selectedType, selectedItem, selectedIndex, } = this.state;
    this.setState({searchTerm: searchTerm});
    if(filteredUsers.length > 0) {
      const foundUsers = this.searchUsers(searchTerm, filteredUsers);
      if(foundUsers && foundUsers.length > 0) {
        this.setState({filteredUsers: foundUsers});
      }else {
        this.setState({filteredUsers: []});
      }
    }else {
      const output = this.filterUsers(selectedType, selectedItem, selectedIndex);
      this.setState({filteredUsers: output});
    }
  }


  searchUsers = (term, users) => {
    const { selectedType, selectedItem, selectedIndex, } = this.state;
    const { sortTypes, } = constants;
    let results = [];
    let isName = false;
    let isPosition = false;
    let isUserRole = false;
    let isStatus = false;
    if(term && term.length > 0) {
      const term1 = term.toLowerCase();
      if(term1.includes(' ')) {
        const strings = term1.split(' ');
        if(strings && strings.length === 2) {
          const fullName = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[0]) &&
            user.last_name && user.last_name.toLowerCase().includes(strings[1]));
          if(fullName && fullName.length > 0) {
            results = fullName;
            isName = true;
          }else {
            const fullName1 = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(strings[1]) &&
              user.last_name && user.last_name.toLowerCase().includes(strings[0]));
            if(fullName1 && fullName1.length > 0) {
              results = fullName1;
              isName = true;
            }else {
              const foundInPosition = users.filter((user) =>user.position && 
                (user.position.toLowerCase().includes(strings[0]) || user.position.toLowerCase().includes(strings[1])));
              if(foundInPosition && foundInPosition.length > 0) {
                results = foundInPosition;
                isPosition = true;
              }
              const foundInPosition1 = users.filter((user) =>user.position && 
                (user.position.toLowerCase().includes(strings[1]) || user.position.toLowerCase().includes(strings[0])));
              if(foundInPosition && foundInPosition.length > 0) {
                results = results.concat(foundInPosition1);
                isPosition = true;
              }
              if(foundInPosition && foundInPosition.length === 0 && foundInPosition1 && foundInPosition1.length === 0) {
                if(strings[0] === 'team' && strings[1] === 'member') {
                  results = users.filter((user) =>user.status && !user.is_admin);
                  isUserRole = true;
                }else if(strings[1] === 'team' && strings[0] === 'member') {
                  results = users.filter((user) =>user.status && user.is_admin);
                  isUserRole = true;
                }
              }
            }
          }
        }else if(strings && strings.length > 2) {
          //Possiblly this is position
          let contains1Ward = [];
          let contains2Wards = [];
          let contains3Wards = [];
          for(let i=0; i<strings.length; i++) {
            const found = users.filter((user) =>user.position && user.position.toLowerCase().includes(strings[i]));
            contains1Ward = contains1Ward.concat(found);
          }
          if(contains1Ward.length > 0) {
            if(strings.length <= 3) {
              contains2Wards = contains1Ward.filter((user) => user.position && user.position.toLowerCase().includes(strings[1]));
              contains3Wards = contains2Wards.filter((user) => user.position && user.position.toLowerCase().includes(strings[2]));
            }
            if(contains3Wards && contains3Wards.length > 0) {
              results = contains3Wards;
            }else if(contains2Wards && contains2Wards.length > 0) {
              results = contains2Wards;
            }else {
              results = contains1Ward;
            }
            isPosition = true;
          }
        }
      }else {
        const firstNameContains = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
        if(firstNameContains && firstNameContains.length > 0) {
          results = firstNameContains;
          isName = true;
          const alsoInLastName = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if(alsoInLastName && alsoInLastName.length > 0) {
            results = results.concat(alsoInLastName);
          }
          const alsoInPosition = users.filter((user) => user.position && user.position.toLowerCase().includes(term1));
          if(alsoInPosition && alsoInPosition.length > 0) {
            results = results.concat(alsoInPosition);
            isPosition = true;
          }
          if(term1.toLowerCase() === 'admin') {
            results = users.filter((user) =>user.status && user.is_admin);
            isName = false;
            isUserRole = true;
            isPosition = false;
          }
        }else {
          const lastNameContains = users.filter((user) => user.last_name && user.last_name.toLowerCase().includes(term1));
          if(lastNameContains && lastNameContains.length > 0) {
            results = lastNameContains;
            isName = true;
            const alsoInFirstName = users.filter((user) => user.first_name && user.first_name.toLowerCase().includes(term1));
            if(alsoInFirstName && alsoInFirstName.length > 0) {
              results = results.concat(alsoInFirstName);
            }
            if(term1.toLowerCase() === 'admin') {
              results = users.filter((user) =>user.status && user.is_admin);
              isName = false;
              isUserRole = true;
            }
          }else {
            const positionContains = users.filter((user) =>user.position && user.position.toLowerCase().includes(term1));
            if(positionContains && positionContains.length > 0) {
              results = positionContains
              isPosition = true;
            }else {
              const statusContains = users.filter((user) =>user.status && user.status.toLowerCase().includes(term1));
              if(statusContains && statusContains.length > 0) {
                results = statusContains;
                isStatus = true;
              }else {
                if(term1.toLowerCase() === 'admin') {
                  results = users.filter((user) =>user.status && user.is_admin);
                  isUserRole = true;
                }else if(term1.toLowerCase().includes('team') || term1.toLowerCase().includes('member')) {
                  results = users.filter((user) =>user.status && !user.is_admin);
                  isUserRole = true;
                }else {
                  if(term1.toLowerCase() === 'terminated') {
                    const terminated = users.filter((user) =>user.status && user.status !== 'active' && user.status !== 'pending');
                    if(terminated && terminated.length > 0) {
                      results = terminated;
                      isUserRole = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      const found = uniqBy(results, 'id'); // Remove duplicated
      let type = '';
      if(isName) {
        type = sortTypes.FIRST_NAME;
      }
      if(isPosition) {
        type = sortTypes.POSITION;
      }
      if(isStatus) {
        type = sortTypes.STATUS;
      }
      if(isUserRole) {
        type = sortTypes.USER_ROLE;
      }
      if(isName && isPosition) {
        type = sortTypes.FIRST_NAME;
      }
      const sorted = this.sortUsers(found, type, true);
      return sorted;
    }else {
      const output = this.filterUsers(selectedType, selectedItem, selectedIndex);
      return output;
    }
  }

  notifyRefreshRequested = () => {
    const {fetchManagers, fetchAssociates, fetchPendingUsers, locationId, managersCurrentPage, associatesCurrentPage, loadingAssociates, 
            loadingManagers, loadingRemovedUsers, loadingChannelMembers, fetchAllUsers, } = this.props;

    const loading = loadingAssociates || loadingManagers || loadingRemovedUsers || loadingChannelMembers;
    if(loading) {
      return;
    }
    //fetchManagers(locationId, this.managerPageIndex, USERS_PER_PAGE);
    //fetchAssociates(locationId, this.associatePageIndex, USERS_PER_PAGE);
    //fetchPendingUsers(locationId, this.pendingUserPageIndex, USERS_PER_PAGE);
    fetchAllUsers(locationId, this.allUserPageIndex, USERS_PER_PAGE);
  }

  notifySortTypeChanged = (type) => {
    const { filteredUsers, ascending, } = this.state;
    const { sortTypes, } = constants;

    this.setState({selectedSort: type})

    if(filteredUsers && type) {
      const output = this.sortUsers(filteredUsers, type.name, ascending);
      this.setState({filteredUsers: output});
    }
  }

  notifySortOrderChanged = (value) => {
    const { selectedSort, filteredUsers, } = this.state;

    this.setState({ascending: value});

    if(filteredUsers && selectedSort) {
      const output = this.sortUsers(filteredUsers, selectedSort.name, value);
      this.setState({filteredUsers: output});
    }
  }

  notifyDropDownOpened = (opened) => {
    this.setState({popupOpened: opened});
  }

  sortUsers = (users, sortType, ascending) => {
    const { sortTypes, } = constants;

    let arrToSort = [...users];    
    switch(sortType) {
      case sortTypes.POSITION:
        this.sortJobTitleWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.FIRST_NAME:
        this.sortFirstNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.LAST_NAME:
        this.sortLastNameWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.STATUS:
        this.sortStatusWithNullValues(arrToSort, ascending);
        break;
      case sortTypes.USER_ROLE:
        //this.sortUserRoleWithNullValues(arrToSort, ascending);
        const admins = arrToSort.filter((user) => user.is_admin === true);
        const members = arrToSort.filter((user) => user.is_admin === false);
        this.sortFirstNameWithNullValues(admins, true);
        this.sortFirstNameWithNullValues(members, true);
        if(ascending) {
          arrToSort = admins.concat(members);
        }else {
          arrToSort = members.concat(admins);
        }
        break;
    }
    return arrToSort;
  }

  sortFirstNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.first_name === b.first_name) // identical? return 0
        return 0;
      else if (a.first_name === null)  // a is null? last 
        return 1;
      else if (b.first_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.first_name.localeCompare(b.first_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortLastNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.last_name === b.last_name) // identical? return 0
        return 0;
      else if (a.last_name === null)  // a is null? last 
        return 1;
      else if (b.last_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.last_name.localeCompare(b.last_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortJobTitleWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.position === b.position) // identical? return 0
        return 0;
      else if (a.position === null)  // a is null? last 
        return 1;
      else if (b.position === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.position.localeCompare(b.position) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortStatusWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.status === b.status) // identical? return 0
        return 0;
      else if (a.status === null)  // a is null? last 
        return 1;
      else if (b.status === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.status.localeCompare(b.status) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortUserRoleWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.is_admin === b.is_admin) // identical? return 0
        return 0;
      else if (a.is_admin === null)  // a is null? last 
        return 1;
      else if (b.is_admin === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return (b.is_admin - a.is_admin) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortChannelNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.channel_name === b.channel_name) // identical? return 0
        return 0;
      else if (a.channel_name === null)  // a is null? last 
        return 1;
      else if (b.channel_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.channel_name.localeCompare(b.channel_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortDeptNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.name === b.name) // identical? return 0
        return 0;
      else if (a.name === null)  // a is null? last 
        return 1;
      else if (b.name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.name.localeCompare(b.name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  sortSkillNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.skill_content === b.skill_content) // identical? return 0
        return 0;
      else if (a.skill_content === null)  // a is null? last 
        return 1;
      else if (b.skill_content === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.skill_content.localeCompare(b.skill_content) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  render() {
    const { locationId, locationData,  locations, managers, associates, removedUsers, pendingUsers, loadingManagers, 
            loadingAssociates, loadingRemovedUsers, loadingChannelMembers, departments, skills, channels, channelData, 
            notifyUserSelected, lastAction, handleChangeLocation, locationSettings, handleGetUserInformation, } = this.props;
    const { selectedType, selectedItem, filteredUsers, selectedDeptId, selectedSkillId, 
            locationInfo, selectedUsers, userActionDone, processedUsers, actionInProgress, actionUsers, 
            requestedOperation, selectedIndex, popupOpened, processingUserId, } = this.state;
    
    const loading = loadingAssociates || loadingManagers || loadingRemovedUsers || loadingChannelMembers;
    /*
    if (loading) {
      return (
        <div className="LoadingLayer">
          <img src="/loader.gif" height="75" width="75" />
        </div>
      );
    }
    */
    const allUsers = managers.concat(associates, pendingUsers, removedUsers);
    const msg = `location: ${locationId}, managers: ${managers.length}, associates: ${associates.length}, removed: ${removedUsers.length}, total: ${allUsers.length}`;
    const summaryItems = this.createSummaryElements();
    const percentage = actionUsers.length > 0 && actionUsers.length >= processedUsers.length ? (processedUsers.length/actionUsers.length) *100 : 0;
    
    if (actionInProgress) {
      return (
        <ProgressBar percentage={percentage}/>
      );
    }
    
    return (
       <div className="management__wrap">
        {/*<LoadingLayer loading={actionInProgress} />*/}
        <LoadingLayer loading={loading} />
        {/*<Title />*/}
        <Category 
          categorySelected={this.categorySelected}
          notifyOperationRequested={this.notifyOperationRequested}
          locationSettings={locationSettings}
          notifyDropDownOpened={this.notifyDropDownOpened}
          selectedType={selectedType}
        />
        <Summary
          loading={loading}
          summaryItems={summaryItems}
          selectedType={selectedType}
          parentSelectedIndex={selectedIndex}
          selectedUsers={selectedUsers}
          channels={channels}
          departments={departments}
          summaryItemSelected={this.summaryItemSelected}
        />
        <Header 
          locationSettings={locationSettings}
          managers={managers}
          associates={associates}
          removedUsers={removedUsers}
          pendingUsers={pendingUsers}
          selectedType={selectedType}
          selectedItem={selectedItem}
          locationId={locationId}
          locationInfo={locationInfo}
          locationData={locationData}
          locations={locations}
          filteredUsers={filteredUsers}
          selectedUsers={selectedUsers}
          requestedOperation={requestedOperation}
          channels={channels}
          channelData={channelData}
          departments={departments}
          skills={skills}
          handleChangeLocation={handleChangeLocation}
          notifyActionRequested={this.notifyActionRequested}
          notifyOperationRequested={this.notifyOperationRequested}
          notifyConfirmOperation={this.notifyConfirmOperation}
          notifySearchTermChanged={this.notifySearchTermChanged}
          notifyRefreshRequested={this.notifyRefreshRequested}
          notifySortTypeChanged={this.notifySortTypeChanged}
          notifySortOrderChanged={this.notifySortOrderChanged}
          notifyDropDownOpened={this.notifyDropDownOpened}
        />
        <UserTable
          locationSettings={locationSettings}
          loading={loading}
          locationId={locationId}
          totalUsers={allUsers}
          departments={departments}
          skills={skills}
          channels={channels}
          //channelData={channelData}
          filteredUsers={filteredUsers}
          selectedDeptId={selectedDeptId}
          selectedType={selectedType}
          selectedItem={selectedItem}
          selectedSkillId={selectedSkillId}
          selectedIndex={selectedIndex}
          notifyUserSelected={this.notifyUserSelected}
          notifyAllUserSelected={this.notifyAllUserSelected}
          selectedUsers={selectedUsers}
          lastAction={lastAction}
          notifyActionDone={this.notifyActionDone}
          processedUsers={processedUsers}
          handleGetUserInformation={handleGetUserInformation}
          notifyActionRequested={this.notifyActionRequested}
          popupOpened={popupOpened}
          processingUserId={processingUserId}
        />
        {userActionDone && <Modal>
          {this.renderStatus(lastAction)}
        </Modal>}
      </div>
    );

     
  }
}

UserManagementComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
  associates: PropTypes.array.isRequired,
  removedUsers: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  skills: PropTypes.array.isRequired,
  channels: PropTypes.array.isRequired,
  channelData: PropTypes.array.isRequired,
};

export default UserManagementComponent;
