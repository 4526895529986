import { connect } from 'react-redux';

import {
  teamOperations,
  teamConstants,
  teamSelectors,
  teamActions,
} from '../../duck';
import TeamConfirmationComponent from './TeamConfirmationComponent';

const { userActionTypes, bulkActionTypes } = teamConstants;

const mapsStateToProps = ({
  teamReducer: {
    bottomSheet,
    visibleMembers,
    filter: { filters },
  },
  locationReducer: { location_code: locationId },
}) => {
  return {
    visible: bottomSheet.visible,
    selectedAction: bottomSheet.selectedAction,
    selectedUsers: teamSelectors.selectedUsers(visibleMembers),
    locationId,
    filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  const handlePromote = (userPrivilegeId) => {
    dispatch(teamOperations.updateAdminStatus(userPrivilegeId, true));
  };
  const handleWithdraw = (userPrivilegeId) => {
    dispatch(teamOperations.updateAdminStatus(userPrivilegeId, false));
  };
  const handleApprove = (userPrivilegeId, locationId) => {
    dispatch(teamOperations.approveUser(userPrivilegeId, locationId));
  };
  const handleDeny = (userPrivilegeId, locationId) => {
    dispatch(teamOperations.denyUser(userPrivilegeId, locationId));
  };
  const handleBlock = (userPrivilegeId) => {
    dispatch(teamOperations.blockUser(userPrivilegeId));
  };
  const handleCancel = () => {
    dispatch(teamOperations.cancelBottomSheet());
  };
  const handleRemove = (userPrivilegeId, locationId) => {
    dispatch(teamOperations.removeUser(userPrivilegeId, locationId));
  };
  const handleBulk = (nextAction) => {
    dispatch(teamActions.updateSelectedAction(nextAction));
  };

  const actionHandlers = {
    [userActionTypes.PROMOTE]: handlePromote,
    [userActionTypes.WITHDRAW]: handleWithdraw,
    [userActionTypes.APPROVE]: handleApprove,
    [userActionTypes.REMOVE]: handleRemove,
    [userActionTypes.DENY]: handleDeny,
    [userActionTypes.BLOCK]: handleBlock,
    [bulkActionTypes.SELECT_ACTIVE_BULK]: handleBulk,
    [bulkActionTypes.ACTIVE_BULK]: handleRemove,
    [bulkActionTypes.PENDING_BULK]: [handleDeny, handleApprove],
    cancel: handleCancel,
  };

  return { actionHandlers };
};

const TeamConfirmationContainer = connect(
  mapsStateToProps,
  mapDispatchToProps
)(TeamConfirmationComponent);

export default TeamConfirmationContainer;
