import styled from 'styled-components/macro';

import TableComponent from '../TableComponent';

const StatusCell = styled(TableComponent.Cell)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.palette.text};
  font-weight: 600;
  text-transform: capitalize;

  @media (max-width: 650px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

export default StatusCell;
