import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../../../helpers/api-calls/feed-calls';
import './SummaryComponent.scss';

class SummaryComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      searchTerm: '',
    };

    this.messages = defineMessages({
      placeholder: {
        id: 'SummaryComponent.placeholder',
        defaultMessage: 'Search',
      },
    });

    this._isMounted = false;
  }

  componentDidMount() {
   
   this._isMounted = true;

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { searchTerm, } = this.state;
    const { getLocationEmployeesNew, intl, } = this.props;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;
    
    const all = (<FormattedMessage
      id="SummaryComponent.all"
      defaultMessage="All"
    />);

    const calledOff = (<FormattedMessage
      id="SummaryComponent.calledOff"
      defaultMessage="Called Off"
    />);

    const onShift = (<FormattedMessage
      id="SummaryComponent.onShift"
      defaultMessage="On Shift"
    />);

    const startSoon = (<FormattedMessage
      id="SummaryComponent.startSoon"
      defaultMessage="Starting Soon"
    />);

    const completed = (<FormattedMessage
      id="SummaryComponent.completed"
      defaultMessage="Completed"
    />);
    

    return (
      <div className="summary__wrap">
        <div className="summary__param-wrap">
          <p className="summary__param-name">88</p>
          <p className="summary__param-value">{all}</p>
        </div>
         <div className="summary__param-wrap">
          <p className="summary__param-name">5</p>
          <p className="summary__param-value">{calledOff}</p>
        </div>
        <div className="summary__param-wrap">
          <p className="summary__param-name">23</p>
          <p className="summary__param-value">On Shift</p>
        </div>
        <div className="summary__param-wrap">
          <p className="summary__param-name">36</p>
          <p className="summary__param-value">{startSoon}</p>
        </div>
         <div className="summary__param-wrap">
          <p className="summary__param-name">25</p>
          <p className="summary__param-value">{completed}</p>
        </div>
      </div>
    );
  }
}

SummaryComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default injectIntl(SummaryComponent);

