import { connect } from 'react-redux';
import TeamProfileHeaderComponent from './TeamProfileHeaderComponent';

const mapStateToProps = ({ currentUserReducer, locationReducer , appReducer, teamProfileReducer}) => {
  return {
    locationId: locationReducer.location_code,
    //user: currentUserReducer,
    user: teamProfileReducer,
    userPrivileges: appReducer.users.data,
  };
};

const TeamProfileContainer = connect(mapStateToProps)(
  TeamProfileHeaderComponent
);

export default TeamProfileContainer;
