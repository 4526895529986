import styled from 'styled-components/macro';

import { rem } from 'polished';
import { rgba } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers'; 
import { Button } from '@shyft/acorns';


const TopWrap = styled.div `
  display: flex;
  flex-direction: row;
  //border: 1px solid blue;

`;

const OwnerAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const OwnerInfoWrap = styled.div `
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 15px;
  //border: 1px solid green;
`;

const OwnerInfoTopLine = styled.p `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
  }
  font-family: 'AvertaBold';
  font-size: 14px;
  font-weight: 600;
  //border: 1px solid red;
`;

const OwnerInfoBottomLine = styled.p `
  margin-top: 0;
  margin-bottom: 0;
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 12px;
  font-weight: 400;
  //border: 1px solid red;
`;

const StatusProgress = styled.div `
  display: flex;
  flex-direction: row;
  //width: 120px;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,238,214,255);
  border: 1px solid rgba(255,142,0,255);
`;
const ProgressSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,142,0,255);
`;

const ProgressText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
  margin-right: 10px;
`;

const StatusCompleted = styled.div `
  display: flex;
  flex-direction: row;
  //width: 120px;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(242,242,247,255);
  border: 1px solid rgba(120,127,135,255);
`;
const CompletedSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(108,114,121,255);
`;

const CompletedText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
  margin-right: 10px;
`;

const StatusFailed = styled.div `
  display: flex;
  flex-direction: row;
  //width: 120px;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255,227,229,255);
  border: 1px solid rgba(255,41,71,255);
`;
const FailedSpan = styled.span `
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 8px;
  background-color: rgba(255,41,71,255);
`;

const FailedText = styled.p `
  @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 4px;
  margin-right: 10px;
`;

const ApprovedBadge = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: -4px;
  margin-left: 3px;
  //border: 1px solid red;
`;


export default {
  TopWrap,
  OwnerAvatar,
  OwnerInfoWrap,
  OwnerInfoTopLine,
  OwnerInfoBottomLine,
  StatusProgress,
  ProgressSpan,
  ProgressText,
  StatusCompleted,
  CompletedSpan,
  CompletedText,
  StatusFailed,
  FailedSpan,
  FailedText,
  ApprovedBadge,
};
