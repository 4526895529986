import { validateForSSO } from 'helpers/validation';

export const SETUP_ACTIONS = {
  NO_LOCATIONS: {
    key: 'no_locations',
  },
  PENDING_LOCATION: {
    key: 'pending_location',
  },
  SSO: {
    key: 'sso',
  },
  GEOFENCE: {
    key: 'geofence',
  },
  IP_BLOCKED: {
    key: 'ip_blocked',
  },
  ADMIN_CLAIM: {
    key: 'admin_claim',
  },
};

// checks for no locations
const noLocations = ({ recent_user_privilege }) => !recent_user_privilege;

export const DetermineSetup = (data) => {
  const setupNeeded = [];

  if (noLocations(data.meta.current_user)) {
    setupNeeded.push(SETUP_ACTIONS.NO_LOCATIONS.key);
  }

  if (validateForSSO(data.setup_actions)) {
    setupNeeded.push(SETUP_ACTIONS.SSO.key);
  }

  if (data.meta.current_location && data.meta.current_location.ip_blocked) {
    setupNeeded.push(SETUP_ACTIONS.IP_BLOCKED.key);
  }

  if (data.meta.current_location && data.meta.current_location.fence_radius) {
    setupNeeded.push(SETUP_ACTIONS.GEOFENCE.key);
  }

  if (
    data.meta.current_user_privilege &&
    !data.meta.current_user_privilege.is_admin
  ) {
    setupNeeded.push(SETUP_ACTIONS.ADMIN_CLAIM.key);
  }

  if (
    data.meta.current_user_privilege &&
    !data.meta.current_user_privilege.is_approved
  ) {
    setupNeeded.push(SETUP_ACTIONS.PENDING_LOCATION.key);
  }

  return setupNeeded;
};
