import styled from 'styled-components/macro';

import Text from './Text';
import ProfileImages from './ProfileImages';
import Row from './Row';
import Icon from './Icon';
import Segment from './Segment';
import Reason from './Reason';
import {
  ActionBtn,
  ActionBtnWrap,
  ViewLessButton,
  ViewMoreButton,
  ViewDetailsButton,
  ViewDetailsBtnWrap,
  Overlay,
} from './ActionButton';

const DateTimeWrap = styled.div`
  margin-right: 1rem;

  * {
    float: left;
  }
`;

const ShiftRequest = styled.div`
  padding: 1.5rem;
  border: 1px solid white;
  transition: all 0.15s;
  white-space: pre-line;

  &:hover {
    background-color: #eaecee73;
    border: 1px solid ${({ theme }) => theme.palette.border};
    border-radius: 8px;
    transition: all 0.2s;
  }
`;


ShiftRequest.Text = Text;
ShiftRequest.ProfileImages = ProfileImages;
ShiftRequest.Row = Row;
ShiftRequest.Icon = Icon;
ShiftRequest.Segment = Segment;
ShiftRequest.DateTimeWrap = DateTimeWrap;
ShiftRequest.ActionBtnWrap = ActionBtnWrap;
ShiftRequest.ActionBtn = ActionBtn;
ShiftRequest.ViewLessButton = ViewLessButton;
ShiftRequest.ViewMoreButton = ViewMoreButton;
ShiftRequest.ViewDetailsButton = ViewDetailsButton;
ShiftRequest.ViewDetailsBtnWrap = ViewDetailsBtnWrap;
ShiftRequest.Reason = Reason;
ShiftRequest.Overlay = Overlay;

export default ShiftRequest;
