import styled, { css } from 'styled-components/macro';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';

import commentIcon from './assets/comment-icon.svg';
import deleteIcon from './assets/delete-icon.svg';
import readReceiptsIcon from './assets/read-receipts-icon.svg';
import heartIcon from './assets/heart.svg';
import heartedIcon from './assets/hearted.svg';
import pinIcon from './assets/pin.svg';
import pinnedIcon from './assets/pinned.svg';

const BUTTON_MODS = {
  comment: () => css`
      background-image: url("${commentIcon}");
      background-size: 20px;
    `,
  delete: () => css`
      background-image: url("${deleteIcon}");
      background-size: 16px 20px;
    `,
  readReceipts: () => css`
      background-image: url("${readReceiptsIcon}");
      background-size: 20px;
  `,
  like: () => css`
      background-image: url("${heartIcon}");
      background-size: 20px;
  `,
  liked: () => css`
      background-image: url("${heartedIcon}");
      background-size: 20px;
  `,
  pin: () => css`
      background-image: url("${pinIcon}");
      background-size: 20px;
  `,
  pinned: () => css`
      background-image: url("${pinnedIcon}");
      background-size: 20px;
  `,
};
const Button = styled.button`
  height: 33px;
  min-width: 45px;
  border-right: 1px solid ${({ theme }) => theme.palette.border};
  background-repeat: no-repeat;
  background-position: center;

  &:last-child {
    border-right: none;
  }

  ${applyStyleModifiers(BUTTON_MODS)}
`;

Button.propTypes = {
  modifiers: styleModifierPropTypes(BUTTON_MODS),
};

const ActionButtons = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 6px;
  background-color: white;
  height: 33px;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

ActionButtons.Button = Button;

export default ActionButtons;
