import React from 'react';

const Logo = () => {
  return (
    <a className="logo-container" href="/">
      {/*<img src="/shyft-workforce-logo.svg" alt="Shyft Logo" />*/}
      
      <img src="/shyft-workforce-logo-black-text.svg" alt="Shyft Logo" />
    </a>
  );
};

export default Logo;
