import { connect } from 'react-redux';

import { teamActions, teamOperations } from '../../duck';
import TeamHeaderComponent from './TeamHeaderComponent';

const mapStateToProps = ({
  teamReducer: {
    search,
    filter,
    visibleMembers: { users, selectedUsers },
  },
  locationReducer: { location_code: locationId },
}) => {
  return {
    searchTerm: search.searchTerm,
    searchReturned: search.searchReturned,
    searching: search.searching,
    filters: filter.filters,
    activeFilter: filter.activeFilter,
    locationId,
    visibleMembersCount: users.length,
    selectedAll:
      users.length === selectedUsers.length && selectedUsers.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  const clearSearch = (locationId) => {
    dispatch(teamActions.updateSearchReturned(false));
    dispatch(teamActions.updateSearching(true));
    dispatch(teamActions.updateSearchTerm(''));
    dispatch(teamOperations.fetchTeamMetadata(locationId));
  };
  const handleSelectAll = (selectedAll, selectedAction) => {
    if (selectedAll) {
      dispatch(teamActions.updateVisibility(false));
      dispatch(teamActions.deselectAllMembers());
    } else {
      dispatch(teamActions.updateVisibility(true));
      dispatch(teamActions.updateSelectedAction(selectedAction));
      dispatch(teamActions.selectAllMembers());
    }
  };

  return { clearSearch, handleSelectAll };
};

const TeamHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamHeaderComponent);

export default TeamHeaderContainer;
