import styled from 'styled-components/macro';

const smallBreakPoint = '400px';
const midBreakPoint = '780px';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 3rem;
  margin-right: 3rem;
  flex-wrap: wrap;

  @media (max-width: ${smallBreakPoint}) {
    margin: 0 0.5rem;
  }
`;

const BodyText = styled.p`
  margin-bottom: 0;
  display: flex;
  flex: 5;
  min-width: 320px;

  @media (max-width: ${midBreakPoint}) {
    padding: 1rem;
    margin: 0;
  }
`;

const ActionWrap = styled.div`
  margin: 0 1.5rem;
  display: flex;
  flex: 1;
  min-width: 320px;

  @media (max-width: ${midBreakPoint}) {
    margin: 0;
    padding: 1rem 0;
  }
`;

export default {
  Wrapper,
  BodyText,
  ActionWrap,
};
