import { connect } from 'react-redux';
import { dashboardOperations } from '../../../../pages/Dashboard/duck';
import ManagementFeedComponent from './ManagementFeedComponent';

const mapStateToProps = ({ dashboardDuckReducer: { managedShifts, managedRTOs }, setupReducer }) => ({
  loading: managedShifts.loading,
  shifts: managedShifts.data,
  managedRTOs: managedRTOs.data,
  currentUser: setupReducer.setup_actions.meta.current_user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchManagedShifts: () => {
    dispatch(dashboardOperations.fetchManagedShifts());
  },
});

const ManagementFeedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementFeedComponent);

export default ManagementFeedContainer;
