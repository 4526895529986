import { connect } from 'react-redux';
import ScheduleTableComponent from './TableComponent';

//import { teamOperations, teamUtils, teamActions } from './duck';
import { appActions } from 'App/duck';
import { userOperations } from '_data/modules/Users';
import { scheduleOperations } from '../../duck';


const mapStateToProps = ({ setupReducer, locationReducer, appReducer, scheduleReducer }) => ({
  locationConfig:
    setupReducer.setup_actions.meta.current_location.configuration,
  location: 
    locationReducer.locations.length > 1 ? locationReducer.location_data : locationReducer.locations[0],
  locationId: locationReducer.location_code,
  departments: appReducer.departments.data,
  myDepartments: appReducer.departments.myDepartments,
  schedules: scheduleReducer.schedules.data,
  loading: scheduleReducer.schedules.loading,
  actionJobs: appReducer.actionJobs.data,
});

const mapDispatchToProps = (dispatch) => {

  const getLocationSchedules = (locationId) => {
    dispatch(scheduleOperations.fetchLocationSchedules(locationId));
  }

  const getLocationActionJobs = (locationId) => {
    dispatch(scheduleOperations.fetchLocationActionJobs(locationId));
  }

  

  return { 
    getLocationSchedules,
    getLocationActionJobs,
  };
};

const TableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleTableComponent);

export default TableContainer;
