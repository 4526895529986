import { connect } from 'react-redux';

import { dashboardOperations } from '../duck';
import LastToApprove from './LastToApprove';


const mapStateToProps = ({ dashboardDuckReducer, setupReducer, locationReducer, }) => {
  return {
    shifts: dashboardDuckReducer.pendingShifts.data,
    locationConfig:
      setupReducer.setup_actions.meta.current_location.configuration,
    userImgUrl: 
      setupReducer.setup_actions.meta.current_user.gallery_url,
    userInfo: 
    setupReducer.setup_actions.meta ? setupReducer.setup_actions.meta.current_user: null,
    locationId: locationReducer.location_code,
  };
};

const mapDispatchToProps = (dispatch) => {

  const fetchShiftApplicants = (id, userPrivileges) => {
    dispatch(dashboardOperations.fetchShiftApplicants(id, userPrivileges));
  };

  const handleUpdateApplicantStatusForAutoDeny = (shiftId, applicantId, approve) => {
    if(approve){
        dispatch(dashboardOperations.approveApplicant(shiftId, applicantId));
    }else{
        dispatch(dashboardOperations.autoDenyApplicant(shiftId, applicantId));
    }
  };

  const denyChildShift = (shift) => {
    dispatch(dashboardOperations.denyPendingShift(shift));
  };

  const approveChildShift = (shift) => {
    dispatch(dashboardOperations.approvePendingShift(shift));
  };

  return {
    fetchShiftApplicants,
    handleUpdateApplicantStatusForAutoDeny,
    denyChildShift,
    approveChildShift,
  };
  
};
const LastToApproveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LastToApprove);

export default LastToApproveContainer;