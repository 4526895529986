

const sortTypes = {
  DATE: 'DATE',
  FILE_NAME: 'FILE_NAME',
  SHIFT_COUNT: 'SHIFT_COUNT'
};


export default {
  sortTypes,
};
