import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InfiniteScroll from 'components/GeneralComponents/InfiniteScroll';
import PageWrap from 'components/GeneralComponents/PageWrap';
import Modal from 'components/GeneralComponents/Modal';
import { UploadSchedulerFile, } from '../../helpers/api-calls/schedule-calls';
import {UploadImage, UploadPDF, } from '../../helpers/api-calls/feed-calls';
import SummaryComponent from './components/Summary/SummaryComponent';
import HeaderComponent from './components/Header/HeaderComponent';
import TableComponent from './components/TableComponents/ShiftTableComponent';
import './ShiftComponent.scss';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class ShiftsComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      debug: true,
      filesToUpload: [],
      upload: false,
    };

    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleButtonClick = this.handleButtonClick.bind(this);

    this.fileInput = React.createRef(null);

    this._isMounted = false;
  }

  componentDidMount() {
    const {
      locationId,
      getLocationEmployeesNew,
      getLocationShifts,
      getLocationActionJobs,
      actionJobs,
    } = this.props;

    this._isMounted = true;

    if(locationId) {
      getLocationEmployeesNew(locationId);
      getLocationShifts(locationId);
      getLocationActionJobs(locationId);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId, actionJobs, getScheduleShifts, shifts, scheduleShifts, } = this.props;

    if(prevProps.actionJobs !== actionJobs) {
      if(actionJobs && actionJobs.length > 1) {
        getScheduleShifts(locationId, actionJobs[1].schedule_id);
      }
    }

    if(prevProps.shifts !== shifts) {
      console.log('got');
    }

    if(prevProps.scheduleShifts !== scheduleShifts) {
      console.log('got');
    }
    
  }
  
  ReceiveUploadClick = () => {
    this.setState({upload: true});
  }

  handleFileUploaded = (status, info) => {
    if(status) {
      this.setState({upload: false});
    }else {
      console.log(info);
      this.setState({upload: false});
    }
  }


  render() {
    const { upload } = this.state;
    const { getLocationEmployeesNew, shifts, scheduleShifts, actionJobs, } = this.props;

    const submit = moment();
    const submittedTime = `${submit.format('MMM DD, YYYY')}`;

    const titleText = (<FormattedMessage
      id="ShiftsComponent.imports"
      defaultMessage="Imports/Shifts" 
    />);

    return (
      <div className="shifts__wrap">
        <p className="shifts__title">{titleText}</p>
        <SummaryComponent />
        <HeaderComponent />
        <TableComponent
          shifts={shifts}
          scheduleShifts={scheduleShifts}
          actionJobs={actionJobs}
        />
      </div>
    );
  }
}

ShiftsComponent.propTypes = {
  locationId: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searching: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
  determineFetch: PropTypes.func.isRequired,
  fetchTeamMetadata: PropTypes.func.isRequired,
  selectedAll: PropTypes.bool.isRequired,
};

export default ShiftsComponent;
