export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const ROOT_API_URL = process.env.REACT_APP_ROOT_API_URL;
export const API_SERVER_VERSION = process.env.REACT_APP_BACKEND_VERSION;
export const BASE_SUBDOMAIN_URL = process.env.REACT_APP_BASE_SUBDOMAIN_URL;
export const BASE_COUNTERPART_URL = process.env.REACT_APP_BASE_COUNTERPART_URL;
export const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
export const DEFAULT_PROFILE_IMAGE_URL =
  //'https://s3.amazonaws.com/shyftassets/avatar1.png';
  '/icons/default-profile-thumb.svg';
