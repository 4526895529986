import axios from 'axios';

import { default as userCalls } from 'helpers/api-calls/team-calls';
import { getLocationCode } from 'helpers/cookies';
import { default as actions } from './actions';
import { default as constants } from './constants';
import { appActions } from 'App/duck';

const { statusTypes } = constants;

/**
 * Gets all users with a status of PENDING and inserts them in state
 *
 * @param  {Object} params
 * @param  {number} params.location_id
 * @param  {number} params.page
 * @param  {string} [params.search_term]
 */
const fetchPendingUsers = (params) => (dispatch) => {
  const getTeamMembersParams = {
    page: 1,
    per_page: 1000,
    location_id: getLocationCode(),
    filter: statusTypes.PENDING,
  };

  dispatch(actions.requestMembers(params.page));

  return userCalls.getTeamMembers(getTeamMembersParams).then((response) => {
    // utils.recieveMembersCb(response, dispatch);
    dispatch(actions.receiveMembers(response.data.user_privileges));
    dispatch(appActions.hydratePendingUsers(response.data.user_privileges));
  });
};

/**
 * Approves pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {Number} userPrivilegeId
 */
const approveUser = (userPrivilegeId) => (dispatch) => {
  return userCalls
    .putUserPrivilege(userPrivilegeId, { is_approved: true })
    .then((response) => {
      dispatch(actions.removeMember(response.data.user_privilege.id));
      dispatch(
        actions.decrementCount({
          filter: statusTypes.PENDING,
          amount: 1,
        })
      );
    });
};

/**
 * Denies pending user request to join location
 * then removes user from view and updates pending count
 *
 * @param  {Number} userPrivilegeId
 */
const denyUser = (userPrivilegeId) => (dispatch) =>
  userCalls.deleteUserPrivilege(userPrivilegeId, 'decline').then(() => {
    dispatch(actions.removeMember(userPrivilegeId));
    dispatch(
      actions.decrementCount({
        filter: statusTypes.PENDING,
        amount: 1,
      })
    );
  });

/**
 * Blocks user from joining location and updates user data in state
 *
 * @param  {Number} userPrivilegeId
 */
const blockUser = (userPrivilegeId) => (dispatch) =>
  userCalls.deleteUserPrivilege(userPrivilegeId, 'block').then(() => {
    dispatch(actions.updateMember(userPrivilegeId));
    dispatch(actions.updateVisibility(false));
  });

export default {
  fetchPendingUsers,
  approveUser,
  denyUser,
  blockUser,
};
