import { combineReducers } from 'redux';

import slices from './slices';

export const reducers = {
  schedules: slices.schedulesSlice.reducer,
  
};

const reducer = combineReducers({
  schedules: reducers.schedules,
  
});

export default reducer;
