import styled from 'styled-components/macro';

const $vertPad = '2px';
const $horPad = '4px';

const Badge = styled.span`
  background-color: ${({ theme }) => theme.palette.primary[0]};
  color: white;
  border-radius: 54%;
  font-size: 0.7rem;
  min-width: 1rem;
  /* height: 1rem; */
  text-align: center;
  line-height: 0.8rem;
  padding-top: ${$vertPad};
  padding-bottom: ${$vertPad};
  padding-left: ${$horPad};
  padding-right: ${$horPad};
`;

export default Badge;
