import React from 'react';
import PropTypes from 'prop-types';
import Pinput from './Pinput/Pinput';
import { parsePhoneNumber } from 'libphonenumber-js';

import { TextInput } from '@shyft/acorns';

const PhoneConfirmation = (props) => {
  const { value } = props;
  const phoneNumber = parsePhoneNumber(`+${value}`);

  return (
    <React.Fragment>
      <TextInput
        disabled
        value={phoneNumber.formatNational()}
        icon="./icons/phone-icon.svg"
        onChange={() => {}}
      />

      <section className="login__form-pin-input">
        <Pinput inputChange={props._pinChange} />
      </section>
    </React.Fragment>
  );
};

export default PhoneConfirmation;

PhoneConfirmation.propTypes = {
  value: PropTypes.string.isRequired,
  validity: PropTypes.bool.isRequired,
  _pinChange: PropTypes.func.isRequired,
};
