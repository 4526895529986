import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { teamShapes, teamConstants } from '../../duck';

import ActionBtn from '../common/ActionButton';
import { default as Styled } from './styled';

const { statusTypes, userActionTypes } = teamConstants;

const ActionCellComponent = ({
  userStatus,
  isAdmin,
  userPrivilegeId,
  currentUserPrivilegeId,
  handleAdminStatus,
  handleApprove,
  handleDeny,
  handleBlock,
  handleConfirmation,
}) => {
  const buttons = [];

  //if (currentUserPrivilegeId == userPrivilegeId) return null;

  if (currentUserPrivilegeId == userPrivilegeId) {
    //placeholder, for other culumns alignment
    buttons.push(
        <button/>,
        <button/>
      );
    return <Styled.Cell>{buttons}</Styled.Cell>;
  };

  switch (userStatus) {
    case statusTypes.ACTIVE:
      if (isAdmin) {
        buttons.push(
          <ActionBtn
            key="wp"
            onClick={() => {
              handleConfirmation(userPrivilegeId, userActionTypes.WITHDRAW);
            }}
          >
            <FormattedMessage
              id="ActionCell.widthdrawPrivilegesBtn"
              defaultMessage="Withdraw Privileges"
            />
          </ActionBtn>,
          <ActionBtn
            key="r"
            modifiers={['danger']}
            onClick={() => {
              handleConfirmation(userPrivilegeId, userActionTypes.REMOVE);
            }}
          >
            <FormattedMessage
              id="ActionCell.removeBtn"
              defaultMessage="Remove"
            />
          </ActionBtn>
        );
      } else {
        buttons.push(
          <ActionBtn
            key="p"
            onClick={() => {
              handleConfirmation(userPrivilegeId, userActionTypes.PROMOTE);
            }}
          >
            <FormattedMessage
              id="ActionCell.promoteBtn"
              defaultMessage="Promote"
            />
          </ActionBtn>,
          <ActionBtn
            key="r"
            modifiers={['danger']}
            onClick={() => {
              handleConfirmation(userPrivilegeId, userActionTypes.REMOVE);
            }}
          >
            <FormattedMessage
              id="ActionCell.removeBtn"
              defaultMessage="Remove"
            />
          </ActionBtn>
        );
      }
      break;

    case statusTypes.PENDING:
      buttons.push(
        <ActionBtn
          key="d"
          modifiers={['danger']}
          onClick={() => {
            handleDeny(userPrivilegeId);
          }}
        >
          <FormattedMessage id="ActionCell.denyBtn" defaultMessage="Deny" />
        </ActionBtn>,
        <ActionBtn
          key="a"
          modifiers={['success']}
          onClick={() => {
            handleApprove(userPrivilegeId);
          }}
        >
          <FormattedMessage
            id="ActionCell.approveBtn"
            defaultMessage="Approve"
          />
        </ActionBtn>
      );
      break;
    case statusTypes.REMOVED:
      // buttons.push(
      //   <ActionBtn
      //     key="b"
      //     modifiers={['danger']}
      //     onClick={() => { handleBlock(userPrivilegeId) }}
      //   >
      //     <FormattedMessage
      //       id="ActionCell.blockBtn"
      //       defaultMessage="Block"
      //     />
      //   </ActionBtn>
      // );
      break;
    default:
      break;
  }

  return <Styled.Cell>{buttons}</Styled.Cell>;
};

ActionCellComponent.propTypes = {
  userStatus: teamShapes.filterTypes.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  userPrivilegeId: PropTypes.number.isRequired,
  handleAdminStatus: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleDeny: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
};

export default ActionCellComponent;
