import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import styled from './styled';

class PDFComponent extends React.Component {
	constructor(props){
		super(props);

	}

	render () {
		const {pdf} = this.props;
		const sizeText = `${Math.round(pdf.size)} KB`;
		return(
			<styled.PDFWrap>
				<styled.PDFIcon 
					src='/icons/pdf-icon.svg'>
				</styled.PDFIcon>
				<styled.PDFProps>
					<styled.PDFName>{pdf.name}</styled.PDFName>
					<styled.PDFSizeWrap>
						<styled.PDFSize>PDF</styled.PDFSize>
						<styled.Circle/>
						<styled.PDFSize>{sizeText}</styled.PDFSize>
					</styled.PDFSizeWrap>
				</styled.PDFProps>
			</styled.PDFWrap>
		);
	}
}

PDFComponent.propTypes = {
  pubnub: PropTypes.object.isRequired,
};

export default injectIntl(PDFComponent);