import React from 'react';
import styled from 'styled-components/macro';

const borderColor = `#DEDEDE`;
const backgroundColor = '#3EAEFF';

const Wrapper = styled.ul`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

const Item = styled.li`
  display: block;
  height: 48px;
  position: relative;
  margin-right: 1.5rem;
`;

const RadioButtonLabel = styled.label`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  cursor: pointer;
`;

const RadioButtonLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const RadioButtonOuterCircle = styled.div`
  border-radius: 50%;
  border: 1px solid ${borderColor};
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  transition: background-color 250ms ease, border-color 250ms ease;
`;

const RadioButtonInnerCircle = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;

  transition: height 250ms ease, width 250ms ease;
`;

const RadioButton = styled.input`
  display: none;

  :checked ~ ${RadioButtonOuterCircle} {
    border-color: ${backgroundColor};
    background-color: ${backgroundColor};

    ${RadioButtonInnerCircle} {
      height: 6px;
      width: 6px;
    }
  }
`;

const RadioLabelText = styled.span`
  display: flex;
  font-size: 12px;
  color: #64676a;
  font-weight: 600;
`;

const RadioLabelDescription = styled.p`
  font-size: 14px;
  color: #90959a;
`;

const RadioButtonGroup = ({ name, options, onChange, currentValue }) => {
  const MappedRadioButton = ({ value, label, description }) => {
    return (
      <Item>
        <RadioButtonLabel>
          <RadioButtonLabelRow>
            <RadioButton
              type="radio"
              name={name}
              value={value}
              onChange={onChange}
              checked={value === currentValue}
            />

            <RadioButtonOuterCircle>
              <RadioButtonInnerCircle />
            </RadioButtonOuterCircle>

            <RadioLabelText>{label}</RadioLabelText>
          </RadioButtonLabelRow>

          <RadioButtonLabelRow>
            <RadioLabelDescription>{description}</RadioLabelDescription>
          </RadioButtonLabelRow>
        </RadioButtonLabel>
      </Item>
    );
  };

  const renderRadioButtons = () => {
    return options.map((element, index) => {
      return (
        <MappedRadioButton
          key={index}
          value={element.value}
          label={element.label}
          description={element.description}
        />
      );
    });
  };
  return <Wrapper>{renderRadioButtons()}</Wrapper>;
};

export default RadioButtonGroup;
