import React from 'react';
import PropTypes from 'prop-types';

import { teamShapes, teamSelectors, teamConstants } from '../../duck';

import TableComponent from '../TableComponent';
import MultipleTables from './MultipleTables';
import SingleTable from './SingleTable';

class TeamTableComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state ={
      skills: [],
      visibleMembers: [],
    };

    this._isMounted = false;
  }

  componentDidMount () {
    const { user, intl, locationId, skills, getLocationUsableSkills, locationSettings, locationDepartments,} = this.props;
    this._isMounted = true;

    if(locationId) {
      getLocationUsableSkills(locationId);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { skills, visibleMembers, } = this.props;

    if(prevProps.skills !== skills) {
      if(skills && skills.length > 0) {
        if(this._isMounted) {
           this.setState({skills: skills});
        }
      }
    }

    if(prevProps.visibleMembers !== visibleMembers) {
      if(this._isMounted) {
        this.setState({visibleMembers: visibleMembers});
      }
    }

  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  sortNameWithNullValues = (arr, ascending) => {
    // default to ascending
    if (typeof(ascending) === "undefined")
      ascending = true;

    const multiplier = ascending ? 1 : -1;

    const sorter = function(a, b) {
      if (a.first_name === b.first_name) // identical? return 0
        return 0;
      else if (a.first_name === null)  // a is null? last 
        return 1;
      else if (b.first_name === null)  // b is null? last
        return -1;
      else {// compare, negate if descending
        return a.first_name.localeCompare(b.first_name) * multiplier;
      }                  
    }
    return arr.sort(sorter);
  }

  renderRows = () => {
    const { activeFilter, visibleMembers, locationId, skills, locationSettings, locationDepartments, } = this.props;
    const { statusTypes } = teamConstants;

    if (activeFilter === statusTypes.ACTIVE) {
      const managers = teamSelectors.managers(visibleMembers);
      const associates = teamSelectors.associates(visibleMembers);
      
      this.sortNameWithNullValues(managers.users, true);
      this.sortNameWithNullValues(associates.users, true);

      return <MultipleTables 
              managers={managers} 
              associates={associates}
              ocationId={locationId} 
              skills={skills} 
              locationId={locationId}
              locationSettings={locationSettings}
              locationDepartments={locationDepartments}
              />;
    }

    return <SingleTable 
              visibleMembers={visibleMembers} 
              locationId={locationId} 
              skills={skills}
              locationSettings={locationSettings}
              locationDepartments={locationDepartments}
              />;
  };

  render() {
    return <TableComponent>{this.renderRows()}</TableComponent>;
  }
}

TeamTableComponent.propTypes = {
  visibleMembers: teamShapes.usersShape.isRequired,
  activeFilter: PropTypes.string.isRequired,
};

export default TeamTableComponent;
