import { loadCalendarShifts } from '../helpers/api-calls';

export const DATE_RANGE_CHANGE = 'DATE_RANGE_CHANGE';
export const NEW_SHIFTS_LOADED = 'NEW_SHIFTS_LOADED';
export const MORE_SHIFTS_LOADED = 'MORE_SHIFTS_LOADED';
export const NEW_SHIFTS_LOADING = 'NEW_SHIFTS_LOADING';
export const MORE_SHIFTS_LOADING = 'MORE_SHIFTS_LOADING';
export const ADD_CALENDAR_TYPE_FILTER = 'ADD_CALENDAR_TYPE_FILTER';
export const DELETE_CALENDAR_TYPE_FILTER = 'DELETE_CALENDAR_TYPE_FILTER';
export const ADD_CALENDAR_DAY_FILTER = 'ADD_CALENDAR_DAY_FILTER';
export const DELETE_CALENDAR_DAY_FILTER = 'DELETE_CALENDAR_DAY_FILTER';
export const ADD_CALENDAR_STATE_FILTER = 'ADD_CALENDAR_STATE_FILTER';
export const DELETE_CALENDAR_STATE_FILTER = 'DELETE_CALENDAR_STATE_FILTER';
export const CANCEL_LOADING = 'CANCEL_LOADING';
export const dateRangeChanged = (from, to) => {
  return { type: DATE_RANGE_CHANGE, from, to };
};

export const newShiftsLoading = () => {
  return { type: NEW_SHIFTS_LOADING };
};

export const newShiftsLoaded = (shifts) => {
  return { type: NEW_SHIFTS_LOADED, shifts };
};

export const moreShiftsLoading = () => {
  return { type: MORE_SHIFTS_LOADING };
};

export const moreShiftsLoaded = (shifts) => {
  return { type: MORE_SHIFTS_LOADED, shifts };
};

export const cancelShiftsLoading = () => {
  return { type: CANCEL_LOADING };
};

export const addTypeFilter = (filter) => {
  return { type: ADD_CALENDAR_TYPE_FILTER, filter };
};

export const deleteTypeFilter = (filter) => {
  return { type: DELETE_CALENDAR_TYPE_FILTER, filter };
};

export const addDayFilter = (filter) => {
  return { type: ADD_CALENDAR_DAY_FILTER, filter };
};

export const deleteDayFilter = (filter) => {
  return { type: DELETE_CALENDAR_DAY_FILTER, filter };
};

export const addStateFilter = (filter) => {
  return { type: ADD_CALENDAR_STATE_FILTER, filter };
};

export const deleteStateFilter = (filter) => {
  return { type: DELETE_CALENDAR_STATE_FILTER, filter };
};

export const changeDateRange = (from, to) => async (dispatch) => {
  dispatch(dateRangeChanged(from, to));
  dispatch(newShiftsLoading(from, to));

  const data = await loadCalendarShifts(from, to);

  if (data.data && data.data.schedule_elements) {
    dispatch(newShiftsLoaded(data.data.schedule_elements));
  } else {
    dispatch(newShiftsLoaded({ data: { data: { schedule_elements: [] } } }));
  }
};
