import moment from 'moment';
import axios from 'axios';


import { GetLocationSchedules, GetLocationActionJobs, } from 'helpers/api-calls/schedule-calls';
import { createApiDateFormat } from 'helpers/date-time';

import actions from './actions';
import { appActions } from 'App/duck';

const fetchLocationSchedules = (location_id) => (dispatch) => {
  dispatch(actions.requestSchedules(true));
  return GetLocationSchedules(location_id).then(({ data }) => {
    dispatch(actions.hydrateSchedules(data.schedules));
  }).catch((error) => {
    const msg = `fetchLocationSchedules failed. error: ${error}`
    console.log(msg);
  });
};

const fetchLocationActionJobs = (location_id) => (dispatch) => {
  dispatch(appActions.requestActionJobs(true));
  return GetLocationActionJobs(location_id).then(({ data }) => {
    dispatch(appActions.hydrateActionJobs(data.action_jobs));
  }).catch((error) => {
    const msg = `fetchLocationActionJobs failed. error: ${error}`
    console.log(msg);
  });
};


export default {
  fetchLocationSchedules,
  fetchLocationActionJobs,
};
